import { FC, useEffect, useState } from 'react';
import { BarChart, XAxis, YAxis, Legend, Label, Bar, Tooltip } from 'recharts';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { paletteC50 as colors } from '../shared/colorPalettes';
import { XYChartType } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { useChartOpacity } from './helpers/chartingHelpers';

function CustomTooltip(props: any) {
  const { active } = props;

  if (active) {
    const payload = props.payload[0].payload;
    const keys = Object.keys(payload);
    const values = Object.values(payload);

    return (
      <div>
        {keys.map((key, index) => (
          <div key={index}>
            {' '}
            {key}: {values[index]}
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}
const XYStackedBarChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(
    () => setReportConvertedData(DataConverters.XY_STACKED_BAR_CHART(props.reportRawData)),
    [props.reportRawData],
  );

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const headers = reportConvertedData.headers;
  const chartDataSeries: any[] = reportConvertedData.rows;
  const barValues = headers.slice(1);

  const bars = barValues.map((bar, index) => (
    <Bar
      key={bar + '-' + index}
      dataKey={bar}
      stackId={props.XYChartType === XYChartType.XY_STACKED_BAR ? 'stackId' : null}
      fill={colors[index]}
      onClick={event => props.onElementClick(event[`${bar}rowcol`])(event)}
      fillOpacity={opacity[bar] || 1}
    />
  ));

  const isInverted = props.isInverted;
  return (
    <BarChart
      data={chartDataSeries}
      layout={isInverted ? 'vertical' : 'horizontal'}
      width={props.width}
      height={props.height}
      margin={{
        left: 50,
        bottom: 50,
      }}
    >
      {bars}

      <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />

      <Legend
        align='left'
        onMouseEnter={onChartEnter(reportConvertedData.headers)}
        onMouseLeave={onChartLeave}
      />

      {isInverted ? (
        <YAxis
          reversed
          domain={['auto', 'auto']}
          type='number'
          dataKey={`${headers[0]}`}
          name={`${headers[0]}`}
        >
          <Label value={`${headers[0]}`} offset={0} position='bottom' />
        </YAxis>
      ) : (
        <YAxis />
      )}

      {isInverted ? (
        <XAxis type='number' domain={['auto', 'auto']} />
      ) : (
        <XAxis
          domain={['auto', 'auto']}
          type='number'
          dataKey={`${headers[0]}`}
          name={`${headers[0]}`}
        >
          <Label value={`${headers[0]}`} offset={0} position='bottom' />
        </XAxis>
      )}
    </BarChart>
  );
};

export default XYStackedBarChartViz;
