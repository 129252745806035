import {
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Slider,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Flavor } from '../../../model/flavor';
import { ScenarioSet } from '../../../model/scenariosConfig';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { NotificationLevel } from '../../../shared/constants';
import { getDateContextStringWithUnderscore, hasFlavor } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import CharacteristicAccordion from './CharacteristicAccordion';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import {
  getCheckboxFormProps,
  getSliderFormProps,
  getTextFieldFormProps,
} from './characteristicDrawerFormHelpers';
import { CharacteristicDrawerTab } from './characteristicDrawerTabs';
import CharacteristicDropdown from './CharacteristicDropdown';
import CharacteristicExpandAll from './CharacteristicExpandAll';
import { getAccordionIds } from './characteristicExpandedAccordion';
import CharacteristicOtherDropdown from './CharacteristicOtherDropdown';
import SmoothingMethodInput from './SmoothingMethodInput';

interface BaseProps {
  formProps: FormikProps<CharacteristicDrawerForm>;
}

const mapStateToProps = (state: AppState) => ({
  defaultDateContext: state.user.selectedDateContext,
  hasFlavor: hasFlavor(state),
});
const connector = connect(mapStateToProps, undefined);
type Props = ConnectedProps<typeof connector> & BaseProps;

const RiskComputation: FC<Props> = props => {
  const contexts = getDateContextStringWithUnderscore(
    { type: 'default' },
    props.defaultDateContext,
  );
  const initialFormValues = useSelector(resolveInitialFormValues);

  const [sets, setSets] = useState<ScenarioSet[]>();
  const getCheckboxProps = getCheckboxFormProps(props.formProps, initialFormValues);
  const getTextFieldProps = getTextFieldFormProps(props.formProps, initialFormValues);
  const getSliderProps = getSliderFormProps(props.formProps, initialFormValues);
  useEffect(() => {
    if (!contexts) return;
    axios
      .get<ScenarioSet[]>(`${baseUrl}api/pvalueScenarioSetNames`, {
        params: { date: contexts },
      })
      .then(response => {
        setSets(response.data);
      })
      .catch((error: Error) =>
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to load scenario data from server: ${error.message}`,
        ),
      );
  }, [contexts]);

  return (
    <div>
      <CharacteristicExpandAll
        accordionIds={getAccordionIds(CharacteristicDrawerTab.RISK_COMPUTATION)}
      />
      <CharacteristicAccordion summary='Attribute Mapping' accordionId='9'>
        <TextField
          label='Input Mapping Header'
          className='full-width-container'
          {...getTextFieldProps('inputMappingHeader')}
        />
        <TextField
          label='Cube Attribute'
          className='full-width-container'
          {...getTextFieldProps('cubeAttribute')}
        />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='VaR Settings' accordionId='10'>
        <CharacteristicOtherDropdown
          label='Confidence Level'
          id='confidenceLevel'
          formProps={props.formProps}
          textFieldProps={{
            InputProps: {
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            },
            type: 'number',
          }}
        />

        <SmoothingMethodInput formProps={props.formProps} />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='VaR Attribution Settings' accordionId='11'>
        <TextField
          label='Parent Scenario'
          className='full-width-container'
          {...getTextFieldProps('parentScenario')}
        />
        <TextField
          label='Set Name'
          className='full-width-container'
          {...getTextFieldProps('setName')}
        />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Stressed VaR Settings' accordionId='12'>
        <div className='side-by-side full-width-container'>
          <TextField
            label='Starting Index'
            className='small'
            type='number'
            inputProps={{ min: 0, max: 10000, step: 1 }}
            {...getTextFieldProps('startingIndex')}
          />
          <Slider
            className='big'
            {...getSliderProps('startingIndex')}
            onChange={(_event, value) => {
              props.formProps.setFieldValue('startingIndex', value, false);
            }}
          />
        </div>
        <div className='side-by-side full-width-container'>
          <TextField
            type='number'
            label='Range'
            className='small'
            inputProps={{ min: 0, max: 10000, step: 1 }}
            {...getTextFieldProps('range')}
          />
          <Slider
            className='big'
            {...getSliderProps('range')}
            onChange={(_event, value) => {
              props.formProps.setFieldValue('range', value, false);
            }}
          />
        </div>
        <TextField
          label='Window Increment'
          className='full-width-container'
          type='number'
          {...getTextFieldProps('windowIncrement')}
        />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Backtesting Settings' accordionId='13'>
        <CharacteristicDropdown
          id='pValueScenarioSet'
          items={sets?.reduce((arr, item) => [...arr, { value: item.name, key: item.name }], [])}
          formProps={props.formProps}
          textFieldProps={{
            label: 'P-Value',
            className: 'full-width-container',
            disabled: !sets,
            InputProps: {
              endAdornment: sets ? null : (
                <CircularProgress style={{ marginRight: '10px', transform: 'scale(0.3)' }} />
              ),
            },
          }}
        />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Time Analysis Settings' accordionId='14'>
        <CharacteristicDropdown
          id='aggregationMethod'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Aggregation Method',
            className: 'full-width-container',
          }}
        />
        <CharacteristicDropdown
          id='riskMeasure'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Risk Measure',
            className: 'full-width-container',
          }}
        />
      </CharacteristicAccordion>

      {props.hasFlavor(Flavor.FRTB) && (
        <CharacteristicAccordion summary='FRTB Settings' accordionId='15'>
          <TextField
            label='DRC Cube Name'
            className='full-width-container'
            {...getTextFieldProps('drcCubeName')}
          />
        </CharacteristicAccordion>
      )}

      {props.hasFlavor(Flavor.SACCR) && (
        <CharacteristicAccordion summary='SACCR Settings' accordionId='16'>
          <FormControlLabel
            label='Is Deal Allocation User Defined Field'
            className='full-width-container'
            {...getCheckboxProps('isDealAllocationUserDefinedField')}
          />
        </CharacteristicAccordion>
      )}

      <CharacteristicAccordion summary='Other' accordionId='17'>
        <FormControlLabel
          label='Active Measures'
          {...getCheckboxProps('activeMeasure')}
          className='full-width-container'
        />
        <TextField
          label='Currency Key'
          className='full-width-container'
          {...getTextFieldProps('currencyKey')}
        />
        <TextField
          label='Scale Key'
          className='full-width-container'
          {...getTextFieldProps('scaleKey')}
        />
        <TextField
          label='Scale Cube Attribute'
          className='full-width-container'
          {...getTextFieldProps('scaleCubeAttribute')}
        />
        <TextField
          label='Ancestor Value'
          className='full-width-container'
          {...getTextFieldProps('ancestorValue')}
        />
        <TextField
          label='Base Key'
          className='full-width-container'
          {...getTextFieldProps('baseKey')}
        />
        <TextField
          label='Base Hierarchy File'
          className='full-width-container'
          {...getTextFieldProps('baseHierarchyFile')}
        />
        <TextField
          multiline
          rows={4}
          // rowsMax allows textbox to keep expanding if the rows go past the default 4 rows
          rowsMax={Infinity}
          label='Portfolio Hierarchy Historic Value Map'
          className='full-width-container'
          {...getTextFieldProps('portfolioHierarchyHistoricValueMap')}
        />
        <TextField
          multiline
          rows={4}
          rowsMax={Infinity}
          label='Data Entry Component Values'
          className='full-width-container'
          {...getTextFieldProps('dataEntryComponentValues')}
        />
      </CharacteristicAccordion>
    </div>
  );
};

export default connector(RiskComputation);
