import { Typography } from '@material-ui/core';
import { ReportColumn, ReportDatum, ReportTable } from 'algo-react-dataviz';
import { FC } from 'react';
import {
  CommonStats,
  compareByKey,
  getCategoryLabel,
  PartitionStats,
  ReportServerStats,
  ServerEnable,
  WAClusterInfo,
  WAServerCategory,
  WAServerInfo,
} from './model';

interface Props {
  remoteConsoleInfo: WAClusterInfo;
  toggleServers: (servers: ServerEnable[]) => void;
  setSelectedServers: (selectedServers: WAServerInfo[]) => void;
  selectedServers: WAServerInfo[];
}

// this determines which keys to display from cluster info
// columns are displayed in the same order of keysToHeader as well
// TODO: figure out which keys to display
const keysToHeader: Partial<
  {
    [k in
      | keyof WAServerInfo
      | keyof CommonStats
      | keyof PartitionStats
      | keyof ReportServerStats]: string;
  }
> = {
  serviceName: 'Server Name',
  statusHRef: 'Location',
  address: 'Address',
  statusPort: 'Status Port',
  port: 'Port',
  lastUpdate: 'Last Update',
  'Max Memory (K)': 'Max Memory (K)',
  'Peak Memory Used (K)': 'Peak Memory Used (K)',
  'JMX Port': 'JMX Port',
  '% Memory Used': 'Memory Used K (%)',
  Enabled: 'Enabled',
  '# Pending Cache Messages': '# Pending Cache Messages',
  'Portfolio timeout': 'Portfolio timeout',
  '# Slices': '# Slices',
  '# DataSets': '# DataSets',
  '# Pending Slices': '# Pending Slices',
  '# Pending Slice Updates': '# Pending Slice Updates',
  Refresh: 'Refresh',
  '# Pending': '# Pending',
  '# Reports': '# Reports',
  '# Trees': '# Trees',
  '# References': '# References',
  '# Pending Refreshes': '# Pending Refreshes',
  '# Pending Updates': '# Pending Updates',
  'Report timeout': 'Report timeout',
};

const RemoteConsoleClusterInfo: FC<Props> = props =>
  props.remoteConsoleInfo ? (
    <div>
      {Object.entries(props.remoteConsoleInfo)
        .sort(compareByKey)
        .map(([category, serverInfos]: [WAServerCategory, WAServerInfo[]]) => {
          const sortedServerInfos = serverInfos?.sort((l, r) =>
            l.serviceName < r.serviceName ? -1 : 1,
          );

          // grabs columns in which keys exist in cluster
          const columns: ReportColumn[] = [
            {
              id: 'selected',
              header: 'Selected',
              getLabel: (datum: ReportDatum) =>
                props.selectedServers.some(cluster => cluster.serviceName === datum.id)
                  ? 'true'
                  : 'false',
              onCheckboxChange: (datum, check) => {
                props.setSelectedServers(
                  check
                    ? [
                        serverInfos.find(cluster => cluster.serviceName === datum.id),
                        ...props.selectedServers,
                      ]
                    : props.selectedServers.filter(
                        serverInfo => serverInfo.serviceName !== datum.id,
                      ),
                );
              },
            },
            ...Object.entries(keysToHeader).reduce(
              (rest, [key, header]) => [
                ...rest,
                ...(sortedServerInfos.some(cluster =>
                  Object.keys({ ...cluster, ...cluster.stats }).includes(key),
                )
                  ? [
                      {
                        id: key,
                        header,
                        getLabel: (datum: ReportDatum) => datum.chars[key],
                        ...(key === 'Enabled'
                          ? {
                              onCheckboxChange: () => {},
                            }
                          : {}),
                      },
                    ]
                  : []),
              ],
              [],
            ),
          ];

          // create row entries
          const data: ReportDatum[] = sortedServerInfos.reduce((rest, curr) => {
            const { stats, ...flattenedInfo } = { ...curr, ...curr.stats };
            const chars = Object.entries(flattenedInfo).reduce((previous, [key, value]) => {
              const displayValue =
                key === '% Memory Used'
                  ? `${flattenedInfo['Memory Used (K)']} (${Math.round(Number(value))}%)`
                  : value;

              return {
                ...previous,
                ...(keysToHeader[key] ? { [key]: displayValue } : {}),
              };
            }, {});
            return [...rest, { id: curr.serviceName, chars }];
          }, []);

          return (
            <div key={category}>
              <Typography variant='h1' className='remote-console-category'>
                {getCategoryLabel(category)}
              </Typography>
              <div className='remote-console-data'>
                <ReportTable id={category} simpleReportProps={{ columns, data }} />
              </div>
            </div>
          );
        })}
    </div>
  ) : null;

export default RemoteConsoleClusterInfo;
