import { Link, Typography } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { FC } from 'react';
import './quick-action.scss';

export interface Props {
  label: string;
  icon?: SvgIconComponent;
  onClick: () => void;
  disabled?: boolean;
  caption?: string;
}

const QuickAction: FC<Props> = props => (
  <Link
    className='quick-action'
    component='button'
    onClick={props.onClick}
    disabled={props.disabled}
    color={props.disabled ? 'initial' : 'primary'}
    underline={props.disabled ? 'none' : 'hover'}
  >
    {props.icon && <props.icon className='quick-action-icon' onClick={props.onClick} />}

    <div className='quick-action-content'>
      <span>{props.label}</span>

      {props.caption && (
        <Typography className='quick-action-caption' onClick={props.onClick}>
          {props.caption}
        </Typography>
      )}
    </div>
  </Link>
);

export default QuickAction;
