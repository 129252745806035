import { IconButton } from '@material-ui/core';
import { Star as StarIcon, StarBorderOutlined as StarBorderOutlinedIcon } from '@material-ui/icons';

const FavoritesIcon = ({ isActive, onClick }: { isActive: boolean; onClick: Function }) => {
  return (
    <IconButton onClick={onClick(isActive)} aria-label='Toggle Favorites' aria-checked={isActive}>
      {isActive ? (
        <StarIcon style={{ height: '18px', fontSize: '18px', color: '#00677F' }} />
      ) : (
        <StarBorderOutlinedIcon style={{ height: '18px', fontSize: '18px', color: '#00677F' }} />
      )}
    </IconButton>
  );
};

export default FavoritesIcon;
