import { TextField } from '@material-ui/core';
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import useCustomGroupingState from '../context/useCustomGroupingState';

interface Props {
  initialName: string;
}

const GroupingTreeRowRenameField: FC<Props> = ({ initialName }) => {
  const { confirmRename, cancelRename } = useCustomGroupingState();
  const [nameValue, setNameValue] = useState(initialName);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setNameValue(event.target.value);
  }, []);

  const onBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    confirmRename(nameValue);
  }, [confirmRename, nameValue]);

  const onKeyDown: KeyboardEventHandler<HTMLElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        confirmRename(nameValue);
        event.stopPropagation();
      } else if (event.key === 'Escape') {
        cancelRename();
        event.stopPropagation();
      }
    },
    [confirmRename, cancelRename, nameValue],
  );

  useEffect(() => {
    // Hacky way to wait for ref to mount
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <TextField
      className='grouping-tree-row-rename-field'
      InputProps={{ className: 'grouping-tree-row-rename-field-input' }}
      value={nameValue}
      onFocus={e => {
        e.target.select();
      }}
      {...{ onChange, onBlur, inputRef, onKeyDown }}
    />
  );
};

export default GroupingTreeRowRenameField;
