import { Button } from '@material-ui/core';
import { DropdownActionButton } from 'algo-react-dataviz';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openFolderDrawer as _openFolderDrawer } from '../../redux/ActionCreators';
import { useAppSelector } from '../../redux/configureStore';
import { creatingNewReport, DesignerSource } from '../../redux/designer/panel/designerSource';
import { addAndSaveReport, saveReport } from '../../redux/ReportActionCreators';
import { setWorkspaceReport } from '../../redux/WorkspaceActionCreators';
import { ADHOC_REPORT_FOLDER, DESIGNER_SEQUENCE_ID, DrawerType } from '../../shared/constants';

const DesignerPanelReportButton: FC = () => {
  const dispatch = useDispatch();
  const designerSource = useAppSelector(state => state.reportDesigner.panelControl.source);

  const sourceSequenceId = useAppSelector(
    state => state.reportDesigner.panelControl.sourceSequenceId,
  );
  const reportDefinitionPath = useAppSelector(
    state => state.report.reportDefinition?.[DESIGNER_SEQUENCE_ID]?.path,
  );
  const designerWorkspaceReport = useAppSelector(
    state => state.reportDesigner.designerWorkspacePayload,
  );
  const allowSave = useAppSelector(
    state =>
      designerSource === DesignerSource.OPEN_DETACHED ||
      state.report.reportData?.[DESIGNER_SEQUENCE_ID] !== undefined,
  );

  const onClick = useCallback(() => {
    // we only want to set hasChanges when a workspacePayload exists
    if (sourceSequenceId) {
      dispatch(
        setWorkspaceReport({
          ...designerWorkspaceReport,
          sequenceId: sourceSequenceId,
          hasChanges: true,
        }),
      );
    }

    // when creating a new adhoc report, hasChanges will be added
    dispatch(addAndSaveReport());
  }, [dispatch, designerWorkspaceReport, sourceSequenceId]);

  const openFolderDrawer = useCallback(() => {
    dispatch(
      _openFolderDrawer(DrawerType.SAVE_REPORT, {
        selectedElements: undefined,
        sequenceId: DESIGNER_SEQUENCE_ID,
      }),
    );
  }, [dispatch]);

  const onSaveReportClick = useCallback(() => {
    // If the report has a defined path already, we can save immediately.
    // Otherwise, we have to open the drawer so the user can choose the path.
    if (reportDefinitionPath && !reportDefinitionPath.startsWith(ADHOC_REPORT_FOLDER)) {
      dispatch(saveReport(reportDefinitionPath, DESIGNER_SEQUENCE_ID, 'put'));
    } else {
      openFolderDrawer();
    }
  }, [dispatch, reportDefinitionPath, openFolderDrawer]);

  return (
    <DropdownActionButton
      buttonComponent={
        <Button
          className='button-left'
          color='primary'
          variant='contained'
          disableElevation
          {...{ onClick }}
        >
          {creatingNewReport(designerSource) ? 'Add' : 'Update'} Report
        </Button>
      }
      menuButtonProps={[
        {
          label: 'Save Report',
          onClick: onSaveReportClick,
          disabled: !allowSave,
        },
        {
          label: 'Save as New',
          onClick: openFolderDrawer,
          disabled: !allowSave,
        },
      ]}
    />
  );
};

export default DesignerPanelReportButton;
