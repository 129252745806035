import { Sorting } from '@devexpress/dx-react-grid';
import { ReportRequestStatus, ReportUpdateHighlightMode } from 'algo-react-dataviz';
import React, { FC } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { connect, ConnectedProps } from 'react-redux';
import { visualizationImpls } from '../../charting/visualizationImpls';
import { updateElementSelection } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import {
  applyCellEdit,
  sendReportUpdateMessage,
  setComplicationSelection,
  setExpandedRowIds,
  setDxTableColumnWidths,
  updateAndSendPrecision,
  updateAndSendSortDefinition,
} from '../../redux/ReportActionCreators';
import {
  CHARACTERISTIC_CHAR_ID,
  DESIGNER_SEQUENCE_ID,
  RowField,
  XYChartName2Type,
} from '../../shared/constants';
import { DataConverters, extractLeafColHeaders } from '../../shared/dataConverters';
import { OnElementClick, PendingRequest, ReportProps, Sort, UISlot } from '../../shared/dataTypes';
import {
  extractWorkspacePayloadAttr,
  getSelectedVisualization,
  isChartInverted,
  nextRequestId,
} from '../../shared/utils';
import ContextMenu from '../context-menu/ContextMenu';
import { ReportContext } from './reportContext';

interface BaseProps {
  context: ReportContext;
  sequenceId: number;
  fetchDetailList: (sequenceId?: any, parentSelectedElements?: any) => void;
  height?: number; // Passed in by ResponsiveContainer
  width?: number; // Passed in by ResponsiveContainer
}

const mapStateToProps = (state: AppState, props: BaseProps) => {
  const sourcePendingRequests =
    state.report.reportData?.[state.reportDesigner.panelControl.sourceSequenceId]?.pendingRequests;
  let pendingRequests: { [requestId: string]: PendingRequest };

  if (
    props.sequenceId === DESIGNER_SEQUENCE_ID &&
    sourcePendingRequests &&
    Object.keys(sourcePendingRequests).length > 0
  )
    // This report is in the designer and there is a pending request on the source report.
    pendingRequests = sourcePendingRequests;
  else pendingRequests = state.report?.reportData?.[props.sequenceId]?.pendingRequests;
  return {
    pendingRequests,
    pendingRequestsMeta: state.report?.reportData?.[props.sequenceId]?.pendingRequestsMeta,
    reportDefinition: state.report.reportDefinition?.[props.sequenceId],
    reportRawData: state.report.reportData?.[props.sequenceId]?.raw,
    detailList: extractWorkspacePayloadAttr('detailList', props.sequenceId, state),
    selectedElements: extractWorkspacePayloadAttr('selectedElements', props.sequenceId, state),
  };
};

const mapDispatchToProps = {
  applyCellEdit,
  sendReportUpdateMessage,
  setComplicationSelection,
  setExpandedRowIds,
  setDxTableColumnWidths,
  updateAndSendSortDefinition,
  updateAndSendPrecision,
  updateElementSelection,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const Visualization: FC<Props> = React.memo(
  props => {
    const reportVisualizationType = getSelectedVisualization(props.reportDefinition);

    if (!visualizationImpls[reportVisualizationType]) {
      return (
        <div style={{ color: 'red' }}>Invalid visualization type: {reportVisualizationType}</div>
      );
    }

    const onElementClick: OnElementClick = elementName => event =>
      props.updateElementSelection(
        elementName,
        props.sequenceId,
        event.button === 0,
        event.ctrlKey || event.metaKey,
      );

    const benchAgainstPriorDay = (
      slotDetails: UISlot,
      menuSelection: string,
      rowColSelection: string[],
    ) => {
      const requestId = `seq-${props.sequenceId}-req-${nextRequestId()}`;

      props.setComplicationSelection(
        props.sequenceId,
        slotDetails.complicationDetails.id,
        `${menuSelection}||${rowColSelection}`,
      );

      props.sendReportUpdateMessage(
        { sequenceId: props.sequenceId, requestId, selectedElements: rowColSelection },
        ReportUpdateHighlightMode.REPORT_CHANGE,
        ReportRequestStatus.REGENERATING,
      );
    };

    const onSortingChange = (value: Sorting[]) => {
      const shouldResetSorting =
        value?.[0]?.columnName === props.reportDefinition.sort?.[0]?.columnHash.toString() &&
        value?.[0]?.direction === 'asc';

      let newSortDefinition: Sort[];

      if (!props.reportDefinition.sort?.[0]?.columnHash || !shouldResetSorting) {
        // this is the case where there was no sorting, or if there was sorting, it does not need reset (just switching direction)
        newSortDefinition = value.map(val => {
          const columnHashes = props.reportRawData.data.children[0].payload.map(value => {
            return value[RowField.COL_HASH];
          });

          return extractLeafColHeaders(props.reportRawData.headers)
            .map((header, index) => ({
              charId: header.props?.columnKeyValue,
              direction: val.direction,
              type: 'basic',
              columnHash: columnHashes[index],
              modifier:
                props.reportDefinition.chars.find(
                  c =>
                    c.charId === header.props?.columnKeyValue &&
                    c.modifier === header.props?.modifier,
                )?.modifier || 0,
            }))
            .find(h => h.columnHash.toString() === val.columnName);
        });
      } else {
        // this is the case where user clicks on an ascending sorted column which effectively will remove sorting
        newSortDefinition = [];
      }

      props.updateAndSendSortDefinition(props.sequenceId, newSortDefinition);
    };

    const fetchDetailList = {
      card: props.fetchDetailList,
      designer: noop,
    }[props.context];

    const cellIsAwaitingEditResponse = (rowIndex: number, colIndex: number) =>
      props.pendingRequests &&
      Object.keys(props.pendingRequests).some(
        key =>
          props.pendingRequests[key].highlightMode === 'CELL_CHANGE' &&
          props.pendingRequests[key].colIndex === colIndex &&
          props.pendingRequests[key].rowIndex === rowIndex,
      );

    const reportProps: ReportProps = {
      applyCellEdit: {
        card: props.applyCellEdit,
      }[props.context],
      bubble: reportVisualizationType === 'BUBBLE_CHART',
      cellIsAwaitingEditResponse,
      columnWidths: props.reportDefinition.columnWidths,
      expandedRowIds: props.reportDefinition.expandedRowIds,
      fetchDetailList,
      height: reportVisualizationType === 'DX_TABLE' ? undefined : props.height,
      isInverted: isChartInverted(props.reportDefinition),
      onElementClick: {
        card: onElementClick,
        designer: () => () => {},
      }[props.context],
      onSortingChange,
      pendingRequests: props.pendingRequests,
      reportRawData: { ...props.reportRawData, chars: props.reportDefinition.chars },
      selectedElements: props.selectedElements,
      sequenceId: props.sequenceId,
      setDxTableColumnWidths: props.setDxTableColumnWidths,
      setExpandedRowIds: props.setExpandedRowIds,
      showGrandTotalRow: props.reportDefinition.settings?.showGrandTotalRow,
      showNumberOfBuckets: props.reportDefinition.settings?.showNumberOfBuckets,
      showSortingControls:
        props.reportDefinition?.horizontalChars?.length === 1 &&
        props.reportDefinition?.horizontalChars[0].layerId === CHARACTERISTIC_CHAR_ID,
      sort: props.reportDefinition.sort,
      stacked:
        reportVisualizationType === 'STACKED_BAR_CHART' ||
        reportVisualizationType === 'STACKED_BRUSH_BAR_CHART',
      style: { height: reportVisualizationType === 'DX_TABLE' ? '100%' : undefined },
      updatePrecision:
        props.context === ReportContext.CARD
          ? update => props.updateAndSendPrecision(props.sequenceId, update)
          : undefined,
      width: props.width,
      XYChartType:
        XYChartName2Type[reportVisualizationType] >= 0
          ? XYChartName2Type[reportVisualizationType]
          : null,
    };

    const VizualizationComponent = visualizationImpls[reportVisualizationType];

    return (
      <ContextMenuTrigger
        id={`${props.sequenceId}`}
        holdToDisplay={-1}
        attributes={{ style: { height: '100%' } }}
      >
        <ContextMenu
          id={`${props.sequenceId}`}
          sequenceId={props.sequenceId}
          fetchDetailList={fetchDetailList}
          showDetailList={!props.detailList}
          benchAgainstPriorDay={benchAgainstPriorDay}
          data={DataConverters.TABLE(props.reportRawData)}
          contextMenuSlots={props.reportDefinition.contextMenuComplications || []}
        />
        <VizualizationComponent {...reportProps} />
      </ContextMenuTrigger>
    );
  },

  /**
   * SD-1636
   * When this function returns true, it's telling React.memo not to re-render the Vizualization
   * component. We want this to happen when the potential re-render is a result of a change to
   * the chars. There is not going to be anything new to show the user in the report when the chars
   * change, regardless of whether Auto-Generate is on. When the report is done generating, that's
   * when Vizualization needs to re-render.
   */
  (prevProps, nextProps) => {
    if (nextProps.sequenceId !== DESIGNER_SEQUENCE_ID) return false;

    const prevChars = prevProps.reportDefinition.chars.map(c => c.charId);
    const nextChars = nextProps.reportDefinition.chars.map(c => c.charId);
    return (
      prevChars.some(c => !nextChars.includes(c)) || nextChars.some(c => !prevChars.includes(c))
    );
  },
);

const noop = () => {};

export default connector(Visualization);
