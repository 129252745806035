import { FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import { CharacteristicDrawerType } from '../../../shared/constants';
import { CharacteristicDrawerForm } from './characteristicDrawerForm';
import { validateSingleField } from './characteristicDrawerFormHelpers';
import { CharacteristicDrawerTab } from './characteristicDrawerTabs';
import Description from './Description';
import Properties from './Properties';
import RiskComputation from './RiskComputation';

interface Props {
  currentTab: CharacteristicDrawerTab;
  formProps: FormikProps<CharacteristicDrawerForm>;
  characteristicDrawerType: CharacteristicDrawerType;
}

const CharacteristicDrawerTabContent: FC<Props> = props => {
  const { characteristicDrawerType, formProps } = props;

  const [validateAll, setValidateAll] = useState(false);

  const charToCopy = useSelector((state: AppState) => state.metadata.charToCopy);

  useEffect(() => {
    characteristicDrawerType === CharacteristicDrawerType.COPY && setValidateAll(true);
  }, [characteristicDrawerType]);

  useEffect(() => {
    if (validateAll) {
      setValidateAll(false);
      formProps.setValues(charToCopy, false);
      validateSingleField('colName', charToCopy.colName, formProps, true, charToCopy);
      validateSingleField('valueKey', charToCopy.colName, formProps, true, charToCopy);
    }
  }, [validateAll, formProps, charToCopy]);

  switch (props.currentTab) {
    case CharacteristicDrawerTab.PROPERTIES:
      return <Properties {...{ formProps, characteristicDrawerType }} />;
    case CharacteristicDrawerTab.RISK_COMPUTATION:
      return <RiskComputation {...{ formProps }} />;
    case CharacteristicDrawerTab.DESCRIPTION:
      return <Description {...{ formProps }} />;
  }
};

export default CharacteristicDrawerTabContent;
