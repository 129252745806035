import { Chip, FormControlLabel, Menu, MenuItem, Switch } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import { FC, useRef, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { toggleReportLiveStatus } from '../../redux/ActionCreators';
import { goToParent } from '../../redux/WorkspaceActionCreators';
import './bottom-tag-row.scss';

interface BaseProps {
  detailList: boolean;
  drillThrough: boolean;
  live: boolean;
  sequenceId: number;
  actualParentSelectedElements: string[];
}

const mapDispatchToProps = { toggleReportLiveStatus };

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const BottomTagRow: FC<Props> = props => {
  const dispatch = useDispatch();
  const [hovering, setHovering] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const timer = useRef(null);

  const isHovering = hovering || anchorEl;

  return (
    <div className='bottom-tag-row margin-horiz hide-in-pdf'>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          display: 'grid',
          gridAutoFlow: 'column',
          alignItems: 'center',
          gridAutoColumns: 'max-content',
          gridColumnGap: isHovering ? '5px' : '0px',
        }}
      >
        {props.detailList && (
          <Chip
            style={{ ...CHIP_STYLE, background: '#e8daff', color: '#6929c4' }}
            label={isHovering ? 'Detail List' : ' '}
          />
        )}
        {props.drillThrough && (
          <>
            <Chip
              style={{ ...CHIP_STYLE, background: '#bae6ff', color: '#00539a' }}
              label={isHovering ? 'Drill Through' : ' '}
              onMouseEnter={e => {
                if (timer.current) {
                  clearTimeout(timer.current);
                }
                setAnchorEl(e.currentTarget);
              }}
              onMouseLeave={() => {
                timer.current = setTimeout(() => {
                  setAnchorEl(null);
                }, 1500);
              }}
            />
            <Menu
              {...{ anchorEl }}
              open={!!anchorEl}
              MenuListProps={{ onMouseLeave: () => setAnchorEl(null) }}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <MenuItem
                onClick={() => {
                  dispatch(goToParent(props.sequenceId));
                  setHovering(false);
                  setAnchorEl(null);
                }}
              >
                Go to Parent
              </MenuItem>
            </Menu>
          </>
        )}
        {(props.drillThrough || props.detailList) && (
          <>
            {isHovering ? (
              <FormControlLabel
                label={props.live ? 'Live' : 'Non-Live'}
                id='liveToggle'
                style={{ marginBottom: '0px' }}
                control={
                  <Switch
                    size='small'
                    color='default'
                    checked={props.live}
                    onChange={() =>
                      props.toggleReportLiveStatus(
                        props.sequenceId,
                        props.live,
                        props.actualParentSelectedElements,
                      )
                    }
                  />
                }
              />
            ) : (
              <Chip style={{ ...CHIP_STYLE, background: '#9ef0f0' }} />
            )}
          </>
        )}
      </div>
      <DragIndicator className='dragHandle' />
      <div />
    </div>
  );
};

export const CHIP_STYLE = { marginRight: '4px', height: '1.5rem' };

export default connector(BottomTagRow);
