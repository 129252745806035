import { createContext, useContext } from 'react';
import { CustomGrouping } from '../../../model/custom-grouping/customGrouping';
import GroupingNodeController from '../../../model/custom-grouping/groupingNodeController';
import { GroupingStrategyType } from '../../../model/custom-grouping/groupingStrategy';
import {
  createTrivialErrors,
  GroupingTreeErrors,
} from '../../../model/custom-grouping/groupingTreeErrors';
import { StrategyDesignerViewState } from '../grouping-strategy-designer/viewState';
import { CustomGroupingInfoState } from '../info-view/useCustomGroupingInfoState';

export interface CustomGroupingStateProps<
  ViewState extends StrategyDesignerViewState = StrategyDesignerViewState
> {
  grouping: CustomGrouping | null;
  hasModelController: boolean;
  modelController: GroupingNodeController | null;
  groupingTreeErrors: GroupingTreeErrors;
  isTreeValid: boolean;
  updateGroupingTree: (
    operation: (modelController: GroupingNodeController) => GroupingNodeController,
  ) => void;
  createNode: (strategyType: GroupingStrategyType) => void;

  expandedIds: string[];
  setExpandedIds: (ids: string[]) => void;

  selectedIds: string[];
  setSelectedId: (id: string) => void;
  toggleId: (id: string) => void;

  copiedNodes: GroupingNodeController[];
  copyNodes: (nodes: GroupingNodeController[]) => void;

  intendedRenameNodeId: string | null;
  intendRename: () => void;
  confirmRename: (intendedName: string) => void;
  cancelRename: () => void;

  infoState: CustomGroupingInfoState | null;

  designerStrategyType: GroupingStrategyType | null;
  hasDesignerViewState: boolean;
  isDesignerViewStateSynced: boolean;
  designerViewState: ViewState | null;
  updateDesignerViewState: (operation: (viewState: ViewState) => ViewState) => void;

  allowApply: () => boolean;
  allowCreate: () => boolean;
  allowUpdate: () => boolean;
  allowDelete: () => boolean;
  createCustomGrouping: () => Promise<boolean>;
  updateCustomGrouping: () => Promise<boolean>;
  deleteCustomGrouping: () => Promise<boolean>;
}

const createTrivialProps = (): CustomGroupingStateProps => ({
  grouping: null,
  hasModelController: false,
  modelController: null,
  groupingTreeErrors: createTrivialErrors(),
  isTreeValid: false,
  updateGroupingTree: _operation => {},
  createNode: _strategyType => {},
  expandedIds: [],
  setExpandedIds: _ids => {},
  selectedIds: [],
  setSelectedId: _id => {},
  toggleId: _id => {},
  copiedNodes: [],
  copyNodes: _nodes => {},
  intendedRenameNodeId: null,
  intendRename: () => {},
  confirmRename: () => {},
  cancelRename: () => {},
  infoState: null,
  designerStrategyType: null,
  hasDesignerViewState: false,
  isDesignerViewStateSynced: true,
  designerViewState: null,
  updateDesignerViewState: _operation => {},
  allowApply: () => false,
  allowCreate: () => false,
  allowUpdate: () => false,
  allowDelete: () => false,
  createCustomGrouping: () => Promise.resolve(false),
  updateCustomGrouping: () => Promise.resolve(false),
  deleteCustomGrouping: () => Promise.resolve(false),
});

export const CustomGroupingStateContext = createContext<CustomGroupingStateProps>(
  createTrivialProps(),
);

const useCustomGroupingState = <
  ViewState extends StrategyDesignerViewState = StrategyDesignerViewState
>() => useContext(CustomGroupingStateContext) as CustomGroupingStateProps<ViewState>;

export default useCustomGroupingState;
