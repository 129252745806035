import {
  createDefaultConfig,
  ScenariosConfig,
  TIMESTEP_ID_ALL,
  TIMESTEP_ID_DEFAULT,
} from '../model/scenariosConfig';
import { ScenariosConfigAction } from './ActionCreators';
import * as ActionTypes from './ActionTypes';

type SetReportDesignerScenarioConfig = {
  type: typeof ActionTypes.SET_REPORT_DESIGNER_SCENARIO_CONFIG;
  payload: ScenariosConfig;
};

type CopyScenariosConfig = {
  type: typeof ActionTypes.COPY_SCENARIOS_CONFIG;
  payload: ScenariosConfig;
};

type Action = ScenariosConfigAction | SetReportDesignerScenarioConfig | CopyScenariosConfig;

export const scenariosConfig = (
  state: ScenariosConfig = createDefaultConfig(),
  action: Action,
): ScenariosConfig => {
  switch (action.type) {
    case ActionTypes.SET_REPORT_DESIGNER_SCENARIO_CONFIG:
      return action.payload;
    case ActionTypes.SET_SCENARIO_CONFIG_PROPERTY:
      switch (action.payload.propertyName) {
        case 'sets':
          return { ...state, sets: action.payload.propertyValue };

        case 'scenarios':
          if (state.scenarios.includes('-1') && action.payload.propertyValue.find(v => v !== '-1'))
            // All was checked but now the user is checking something else.
            return {
              ...state,
              scenarios: action.payload.propertyValue.filter(v => v !== '-1'),
            };
          else if (!state.scenarios.includes('-1') && action.payload.propertyValue.includes('-1'))
            // All wasn't checked but now it is
            return { ...state, scenarios: ['-1'] };
          else return { ...state, scenarios: action.payload.propertyValue };

        case 'timesteps':
          const payloadTimesteps = action.payload.propertyValue;

          if (
            state.timesteps.includes(TIMESTEP_ID_DEFAULT) &&
            payloadTimesteps.find(v => v !== TIMESTEP_ID_DEFAULT) !== undefined
          )
            // Default was checked but now the user is checking something else.
            return { ...state, timesteps: payloadTimesteps.filter(v => v !== TIMESTEP_ID_DEFAULT) };
          else if (
            !state.timesteps.includes(TIMESTEP_ID_DEFAULT) &&
            payloadTimesteps.includes(TIMESTEP_ID_DEFAULT)
          )
            // Default wasn't checked but now it is
            return { ...state, timesteps: [TIMESTEP_ID_DEFAULT] };
          else if (
            state.timesteps.includes(TIMESTEP_ID_ALL) &&
            payloadTimesteps.find(v => v !== TIMESTEP_ID_ALL) !== undefined
          )
            // All was checked but now the user is checking something else.
            return { ...state, timesteps: payloadTimesteps.filter(v => v !== TIMESTEP_ID_ALL) };
          else if (
            !state.timesteps.includes(TIMESTEP_ID_ALL) &&
            payloadTimesteps.includes(TIMESTEP_ID_ALL)
          )
            // All wasn't checked but now it is
            return { ...state, timesteps: [TIMESTEP_ID_ALL] };
          else return { ...state, timesteps: payloadTimesteps };

        default:
          return { ...state, [action.payload.propertyName]: action.payload.propertyValue };
      }

    case ActionTypes.COPY_SCENARIOS_CONFIG:
      return { ...(action.payload || createDefaultConfig()) };

    default:
      return state;
  }
};
