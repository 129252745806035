import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { createInstance, Piral, SetComponent } from 'piral-core';
import { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { MainPilet } from './pilets/MainPilet';
import * as serviceWorker from './serviceWorker';

const instance = createInstance({
  requestPilets() {
    return new Promise(resolve =>
      resolve([
        {
          name: 'report-dates',
          version: '1.0.0',
          link: 'http://localhost:1001/$pilet-api/0/index.js',
          requireRef: 'pr_mypilet',
        },
      ]),
    );
  },
  availablePilets: [MainPilet],
});

ReactDOM.render(
  <Piral instance={instance}>
    <SetComponent name='Layout' component={props => props.children as ReactElement<any, any>} />
  </Piral>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
