import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ReportErrorBoundary: FC<FallbackProps> = props => (
  <div style={{ color: 'red' }}>
    Something went wrong rendering this visualization: {props.error.message}
  </div>
);

export default ReportErrorBoundary;
