import { Reducer } from 'redux';
import { ProgressAction, ProgressActionType } from './actionCreators';
import { ProgressState } from './state';

const reducer: Reducer<ProgressState, ProgressAction> = (state = {}, action): ProgressState => {
  switch (action.type) {
    case ProgressActionType.UPDATE_PROGRESS: {
      const { sequenceId, progress } = action.payload;
      return { ...state, [sequenceId]: progress };
    }
    case ProgressActionType.END_PROGRESS: {
      const { [action.payload]: removedProgress, ...remainingProgress } = state;
      return remainingProgress;
    }
    case ProgressActionType.CHANGE_SEQUENCE_ID: {
      return {
        ...state,
        [action.payload.sequenceId]: state[action.payload.oldSequenceId],
        [action.payload.oldSequenceId]: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
