import { AuthConsumer } from './AuthProvider';

export const Callback = () => {
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback();
        return <span>loading</span>;
      }}
    </AuthConsumer>
  );
};
