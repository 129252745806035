import { DrawerHeader } from 'algo-react-dataviz';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import { getMetadataSandbox } from '../../../redux/metadata/selectors';
import { METADATA_SEQUENCE_ID } from '../../../shared/constants';
import SandboxChip from '../../sandboxes/SandboxChip';

interface Props {
  headerText: string;
  onXClick: () => void;
}

const CharacteristicDrawerHeader: FC<Props> = props => {
  const sandbox = useSelector(getMetadataSandbox);
  const dateContext = useSelector(
    (state: AppState) => state.report.reportDefinition?.[METADATA_SEQUENCE_ID]?.dateContext,
  );

  return (
    <DrawerHeader headerText={props.headerText} onXClick={props.onXClick}>
      {sandbox && <SandboxChip sequenceId={METADATA_SEQUENCE_ID} {...{ sandbox, dateContext }} />}
    </DrawerHeader>
  );
};

export default CharacteristicDrawerHeader;
