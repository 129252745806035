import { DatePickerDay, DatePickerMonth } from 'algo-react-dataviz';
import moment, { Moment } from 'moment';
import { FC, useState } from 'react';
import { CalendarDay, DayPickerRangeController, FocusedInputShape } from 'react-dates';

interface Props {
  dates: [string, string];
  onDatesChange: (dates: [string, string]) => void;
  initDate?: string;
  runExists: (m: Moment) => boolean;
}

const DateRangePicker: FC<Props> = props => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>();

  return (
    <DayPickerRangeController
      startDate={props.dates?.[0] ? moment(props.dates?.[0]) : undefined}
      endDate={props.dates?.[1] ? moment(props.dates?.[1]) : undefined}
      onDatesChange={e =>
        props.onDatesChange([e.startDate?.format('YYYY-MM-DD'), e.endDate?.format('YYYY-MM-DD')])
      }
      focusedInput={focusedInput || 'startDate'}
      onFocusChange={newFocusedInput => setFocusedInput(newFocusedInput)}
      numberOfMonths={1}
      initialVisibleMonth={() => moment(props.initDate)}
      renderDayContents={m => <DatePickerDay moment={m} runExists={props.runExists(m)} />}
      renderCalendarDay={p => {
        // Workaround for react-dates bug: if there's no end date, then there shouldn't be any
        // before-hovered-end dates.
        let newModifiers = p.modifiers;
        if (!props.dates?.[1]) newModifiers?.delete('before-hovered-end');
        // End workaround

        return <CalendarDay {...p} daySize={24} modifiers={newModifiers} />;
      }}
      hideKeyboardShortcutsPanel
      renderMonthElement={p => (
        <DatePickerMonth
          {...p}
          currentDate={props.dates?.[0]?.length === 10 ? props.dates?.[0] : props.initDate}
        />
      )}
    />
  );
};

export default DateRangePicker;
