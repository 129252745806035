import { CharacteristicDrawerForm } from '../../../metadata/characteristic-drawer/characteristicDrawerForm';

export enum EditorInterfaceType {
  AGGREGATOR = 'Aggregator',
  CELL_COMPUTER = 'CellComputer',
  VALUE_COMPUTER = 'ValueComputer',
}

export type EditorSuperclasses = {
  [interfaceType in EditorInterfaceType]: string[];
};

export const getFormikKey = (
  editorInterfaceType: EditorInterfaceType,
): keyof CharacteristicDrawerForm =>
  ({
    [EditorInterfaceType.CELL_COMPUTER]: 'postProcessor' as keyof CharacteristicDrawerForm,
    [EditorInterfaceType.VALUE_COMPUTER]: 'computer' as keyof CharacteristicDrawerForm,
    [EditorInterfaceType.AGGREGATOR]: 'reportAggregator' as keyof CharacteristicDrawerForm,
  }[editorInterfaceType]);

export const getDefaultPackageName = (editorInterfaceType: EditorInterfaceType): string =>
  ({
    [EditorInterfaceType.CELL_COMPUTER]: 'dynamic.postprocessors',
    [EditorInterfaceType.VALUE_COMPUTER]: 'dynamic.computers',
    [EditorInterfaceType.AGGREGATOR]: 'dynamic.aggregators',
  }[editorInterfaceType]);

export interface ClassIdentifiers {
  packageName: string;
  className: string;
}

export const extractClassIdentifiers = (interfaceValue: string): ClassIdentifiers => {
  const values = interfaceValue.split('.');
  return {
    packageName: values.slice(0, values.length - 1).join('.'),
    className: values[values.length - 1],
  };
};
