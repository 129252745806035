import { FormControl, InputLabel, Select } from '@material-ui/core';
import { FC } from 'react';
import { OptionTypes } from '../../../shared/dataTypes';
import MenuItems from '../ui-elements/MenuItems';

interface Props {
  label: string;
  value: OptionTypes;
  onChange: (e: React.ChangeEvent<{ value: string }>) => void;
  menuItems?: { id: OptionTypes; name: string }[];
  disabled?: boolean;
}

const DesignerPanelHeaderSelect: FC<Props> = props => (
  <div style={{ backgroundColor: '#fff', padding: '8px' }}>
    <FormControl style={{ width: '100%' }} disabled={props.disabled}>
      <InputLabel>{props.label}</InputLabel>
      <Select value={props.value} onChange={props.onChange}>
        {MenuItems(
          props.menuItems?.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        )}
      </Select>
    </FormControl>
  </div>
);

export default DesignerPanelHeaderSelect;
