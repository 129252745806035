import { mapData } from 'algo-react-dataviz';
import axios from 'axios';
import { Column, TreeList } from 'devextreme-react/tree-list';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { requestSetExpandedAdminPortfolios } from '../../../redux/ActionCreators';
import { setPortfolioPermission } from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';
import { Node, NodeWithParentId } from '../../../shared/dataTypes';
import { getPortfoliosAtLevel } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';

const mapStateToProps = (state: AppState) => ({
  dateContext: state.user.selectedDateContext,
  permissions: state.admin.portfolioPermissions?.[state.admin.selectedGroup],
  expandedAdminPortfolios: state.user.userInfo.userPreferences.expandedAdminPortfolios,
});
const mapDispatchToProps = { setPortfolioPermission, requestSetExpandedAdminPortfolios };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const PortfolioPermissions: FC<Props> = props => {
  const [data, setData] = useState<NodeWithParentId[]>();

  const { dateContext: { date, id } = {} } = props;
  useEffect(() => {
    axios
      .get<Node<string[]>>(`${baseUrl}api/adminPortfolioHierarchy`, {
        params: { date, id },
      })
      .then(response => setData(mapData(response.data[0], 0)));
  }, [date, id]);
  return (
    <TreeList
      dataSource={data}
      dataStructure='plain'
      keyExpr='id'
      parentIdExpr='parentId'
      selection={{ mode: 'multiple' }}
      onSelectedRowKeysChange={(keys: string[]) =>
        props.setPortfolioPermission(
          keys.map(k => ({ path: k, folder: data.some(d => d.id === k && d.type === 'FOLDER') })),
        )
      }
      showColumnHeaders={false}
      selectedRowKeys={props.permissions}
      expandedRowKeys={
        props.expandedAdminPortfolios || (data && getPortfoliosAtLevel(data[0], 2)) || []
      }
      onExpandedRowKeysChange={props.requestSetExpandedAdminPortfolios}
    >
      <Column dataField='name' />
    </TreeList>
  );
};

export default connector(PortfolioPermissions);
