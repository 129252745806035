import { Typography } from '@material-ui/core';
import { FC, useCallback } from 'react';
import { useAppSelector } from '../../../../redux/configureStore';
import { getDrawerStatus } from '../../../../redux/custom-grouping/selectors';
import { CustomGroupingDrawerStatus } from '../../../../redux/custom-grouping/state';
import { Characteristic } from '../../../../shared/dataTypes';
import useDebouncedState from '../../../../shared/useDebouncedState';
import { supportsDiscreteBreakpoints } from '../../../../shared/utils';
import DiscreteBreakpoints from '../../../designer-panel/DiscreteBreakpoints';
import useCustomGroupingState from '../../context/useCustomGroupingState';
import { CharacteristicStrategyViewState } from '../viewState';
import './characteristic-strategy-designer.scss';
import CustomGroupingCharacteristicDropdown from './CustomGroupingCharacteristicDropdown';

const SEARCH_DEBOUNCE_DELAY = 400;

interface Props {
  allChars: Characteristic[];
}

const CharacteristicStrategyDesigner: FC<Props> = props => {
  const readOnly = useAppSelector(getDrawerStatus) === CustomGroupingDrawerStatus.VIEW;

  const {
    designerViewState: { characteristicId, breakpoints },
    updateDesignerViewState,
  } = useCustomGroupingState<CharacteristicStrategyViewState>();

  const selectedCharacteristic =
    characteristicId !== null
      ? props.allChars.find(char => char.charId === characteristicId)
      : null;

  const showBreakpoints = !!selectedCharacteristic && !!breakpoints;

  const {
    state: searchTerm,
    debouncedState: debouncedSearchTerm,
    setState: setSearchTerm,
  } = useDebouncedState(selectedCharacteristic?.name ?? '', SEARCH_DEBOUNCE_DELAY);

  const setSelectedChar = useCallback(
    ({ charId, dataType }: Characteristic) =>
      updateDesignerViewState(viewState => ({
        ...viewState,
        characteristicId: charId,

        // If we've changed characteristics, we need to reset breakpoints.
        ...(viewState.characteristicId !== charId
          ? {
              breakpoints: supportsDiscreteBreakpoints(dataType) ? [] : null,
            }
          : {}),
      })),
    [updateDesignerViewState],
  );

  const setBreakpoints = useCallback(
    (breakpoints: string[]) => {
      updateDesignerViewState(viewState => ({
        ...viewState,
        breakpoints,
      }));
    },
    [updateDesignerViewState],
  );

  return (
    <div className='gsd-content gsd-characteristic'>
      <Typography className='gsd-body-header'>Select a characteristic for your grouping</Typography>
      <CustomGroupingCharacteristicDropdown
        allChars={props.allChars}
        {...{
          searchTerm,
          debouncedSearchTerm,
          setSearchTerm,
          setSelectedChar,
          readOnly,
        }}
      />
      {showBreakpoints && (
        <DiscreteBreakpoints
          {...{ breakpoints }}
          onAddBreakpoint={() => {
            if (readOnly) return;
            return setBreakpoints([...breakpoints, '']);
          }}
          onChangeBreakpoint={(index, value) => {
            if (readOnly) return;
            setBreakpoints([
              ...breakpoints.slice(0, index),
              value,
              ...breakpoints.slice(index + 1),
            ]);
          }}
          onDeleteBreakpoint={index => {
            if (readOnly) return;
            setBreakpoints([...breakpoints.slice(0, index), ...breakpoints.slice(index + 1)]);
          }}
        />
      )}
    </div>
  );
};
export default CharacteristicStrategyDesigner;
