import { Popover } from '@material-ui/core';
import { FC, MouseEventHandler, useCallback, useState } from 'react';
import { ValidationStatus, validationStatuses } from '../../../shared/validation/model';
import './validation-legend.scss';
import ValidationIndicator from './ValidationIndicator';
import ValidationLegendItem from './ValidationLegendItem';

interface Props {
  status: ValidationStatus;
}
const ValidationLegend: FC<Props> = ({ status }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen: MouseEventHandler<HTMLElement> = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose: MouseEventHandler<HTMLElement> = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const createLegendItem = useCallback(
    (status: ValidationStatus) => <ValidationLegendItem key={status} {...{ status }} />,
    [],
  );

  return (
    <div
      className='validation-legend'
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <ValidationIndicator className='validation-legend-anchor' {...{ status }} />

      <Popover
        className='validation-legend-popover'
        open={!!anchorEl}
        {...{ anchorEl }}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className='validation-legend-popover-content'>
          {validationStatuses.map(createLegendItem)}
        </div>
      </Popover>
    </div>
  );
};

export default ValidationLegend;
