import { IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ControlBar } from 'algo-react-dataviz';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { getMetadataSandbox, hasMetadataReport } from '../../redux/metadata/selectors';
import { METADATA_SEQUENCE_ID, NotificationLevel } from '../../shared/constants';
import SandboxChip from '../sandboxes/SandboxChip';
import { baseUrl } from '../shared/environment';
import AddCharacteristicDrawer from './characteristic-drawer/AddCharacteristicDrawer';
import './metadata-header.scss';
import MetadataMenu from './MetadataMenu';

const MetadataHeader: FC = () => {
  const sandbox = useSelector(getMetadataSandbox);
  const dateContext = useSelector(
    (state: AppState) => state.report.reportDefinition?.[METADATA_SEQUENCE_ID]?.dateContext,
  );
  const hasMetadata = useSelector(hasMetadataReport);
  const dispatch = useDispatch();

  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [hasWritePermission, setHasWritePermission] = useState(false);

  useEffect(() => {
    axios
      .get<boolean>(`${baseUrl}api/canWriteMetadata`)
      .then(({ data }) => setHasWritePermission(data))
      .catch(({ message }: Error) => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to retrieve metadata write permission: ${message}`,
          ),
        );
      });
  }, [dispatch]);

  return (
    <div className='metadata-header'>
      <MetadataMenu />

      <Typography className='metadata-header-title' variant='h1'>
        Metadata Viewer
      </Typography>

      <div className='metadata-header-spacer' />

      {sandbox !== null && (
        <div className='metadata-header-sandbox-chip'>
          <SandboxChip
            action='close'
            sequenceId={METADATA_SEQUENCE_ID}
            {...{ sandbox, dateContext }}
          />
        </div>
      )}

      <ControlBar disabled={!hasMetadata} />

      {hasWritePermission && (
        <div className='button-container'>
          <IconButton onClick={() => setAddDrawerOpen(true)}>
            <AddIcon color='primary' />
          </IconButton>
          <AddCharacteristicDrawer open={addDrawerOpen} close={() => setAddDrawerOpen(false)} />
        </div>
      )}
    </div>
  );
};

export default MetadataHeader;
