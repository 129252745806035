import { FC, useEffect, useState } from 'react';
import { Bar, BarChart, Brush, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { paletteT50 as colors } from '../shared/colorPalettes';
import { legendWrapperStyles } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { useChartOpacity } from './helpers/chartingHelpers';

const BrushedBarChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  const [startIndex, setStartIndex] = useState<number>();
  const [endIndex, setEndIndex] = useState<number>();

  useEffect(() => setReportConvertedData(DataConverters.BRUSH_BAR_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const barChartData = reportConvertedData.rows;

  // If this is a stacked bar chart then assign each bar the same  stack id.
  // This will result in the bars being stacked.
  const stackId = props.stacked ? 'id' : null;

  const barCharts = reportConvertedData.headers.map((el, colorIndex) => (
    <Bar
      stackId={stackId}
      key={el}
      dataKey={el}
      fill={colors[colorIndex]}
      onClick={event => props.onElementClick(event[`${el}rowcol`])(event)}
      fillOpacity={opacity[el] || 1}
    />
  ));

  return (
    <BarChart
      stackOffset='sign'
      width={props.width}
      height={props.height}
      data={barChartData}
      margin={{ left: 30 }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey={reportConvertedData.groupingHeader.name} />
      <YAxis />
      <Tooltip formatter={(value, name, props) => props.payload[`${name}Formatted`]} />
      <Legend
        layout='horizontal'
        align='center'
        verticalAlign='bottom'
        wrapperStyle={legendWrapperStyles.horizontal}
        onMouseEnter={onChartEnter(reportConvertedData.headers)}
        onMouseLeave={onChartLeave}
      />
      <Brush
        dataKey={reportConvertedData.groupingHeader.name}
        height={30}
        stroke='#8884d8'
        startIndex={startIndex}
        endIndex={endIndex}
        // @ts-ignore
        // This is a bug with the types in recharts: https://github.com/recharts/recharts/issues/2480
        // The ts-ignore can be removed when recharts fixes the bug.
        onChange={({ startIndex, endIndex }) => {
          setStartIndex(startIndex);
          setEndIndex(endIndex);
        }}
      />
      {barCharts}
    </BarChart>
  );
};

export default BrushedBarChartViz;
