import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainComponent from '../main/Main';
import { isOidcClientEnabled } from '../shared/environment';
import AuthProvider from './routing/AuthProvider';
import { Routes } from './routing/Routes';

const SecuritySelector: FC = () => {
  if (isOidcClientEnabled) {
    return (
      <AuthProvider>
        <BrowserRouter children={Routes} basename={'/'} />
      </AuthProvider>
    );
  } else {
    // NATIVE
    return <MainComponent oidcLogout={() => {}} />;
  }
};

export default SecuritySelector;
