import { Switch, Typography } from '@material-ui/core';
import { FolderOpenOutlined } from '@material-ui/icons';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { requestSetFolderPermission } from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';
import { AccessType, FolderType } from '../../../shared/constants';

interface BaseProps {
  list: string[];
  folderType: FolderType;
  allPermissionsProperty: 'reportPermissions' | 'sandboxPermissions' | 'workspacePermissions';
}

const mapStateToProps = (state: AppState, props: BaseProps) => ({
  permissions:
    state.admin.allPermissions?.[props.allPermissionsProperty][state.admin.selectedGroup]
      ?.folderAccess,
});
const mapDispatchToProps = { requestSetFolderPermission };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const AccessPermissionsList: FC<Props> = props => (
  <table style={{ width: '100%' }}>
    <thead>
      <tr>
        <th style={{ position: 'sticky', top: 0, zIndex: 99 }} />
        <th style={{ position: 'sticky', top: 0, zIndex: 99 }}>
          <Typography color='primary' align='center'>
            Read
          </Typography>
        </th>
        <th style={{ position: 'sticky', top: 0, zIndex: 99 }}>
          <Typography color='primary' align='center'>
            Write
          </Typography>
        </th>
      </tr>
    </thead>
    <tbody>
      {props.list.sort().map((folderName, i) => (
        <tr key={i}>
          <td style={{ verticalAlign: 'middle' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FolderOpenOutlined style={{ margin: '0px 5px' }} />
              <Typography>{decodeURIComponent(folderName)}</Typography>
            </div>
          </td>
          {Object.values(AccessType).map((accessType, j) => {
            const checked =
              props.permissions?.[decodeURIComponent(folderName)]?.[accessType.toLowerCase()] ||
              false;
            return (
              <td key={j} style={{ textAlign: 'center' }}>
                <Switch
                  color='primary'
                  checked={checked}
                  onChange={e =>
                    props.requestSetFolderPermission({
                      folderType: props.folderType,
                      accessType,
                      folderName: decodeURIComponent(folderName),
                      value: !checked,
                    })
                  }
                />
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  </table>
);

export default connector(AccessPermissionsList);
