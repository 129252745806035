import { CharacteristicDrawerForm } from '../../components/metadata/characteristic-drawer/characteristicDrawerForm';
import {
  AccordionId,
  CharacteristicExpandedAccordion,
} from '../../components/metadata/characteristic-drawer/characteristicExpandedAccordion';
import { EntityTypes } from '../../model/metadata/entityTypes';
import { Sandbox } from '../../shared/dataTypes';

export interface MetadataState {
  sandbox: Sandbox | null;

  selectedChar?: string;
  charToCopy?: CharacteristicDrawerForm;
  entityTypes?: EntityTypes;

  accordionsExpanded: CharacteristicExpandedAccordion;

  // columnVisibilities: ColumnVisibilities; // TODO: SD-1893
  // filters: MetadataFilter[]; // TODO: SD-1894
}

export const createDefaultState = (): MetadataState => ({
  sandbox: null,
  accordionsExpanded: createDefaultAccordionState(),

  // columnVisibilities: createDefaultVisibilities(), // TODO: SD-1893
  // filters: [], // TODO: SD-1894
});

const DEFAULT_EXPANDED_ACCORDIONS: AccordionId[] = ['1', '2'];
const createDefaultAccordionState = (): CharacteristicExpandedAccordion =>
  Object.assign(
    {},
    ...DEFAULT_EXPANDED_ACCORDIONS.map(id => ({
      [id]: true,
    })),
  );
