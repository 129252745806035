import { Typography } from '@material-ui/core';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar, loadAllChars } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { closeDrawer, setSaveModalOpen } from '../../../redux/custom-grouping/actions';
import { isSaveModalOpen } from '../../../redux/custom-grouping/selectors';
import { NotificationLevel } from '../../../shared/constants';
import MainModal from '../../main/MainModal';
import useCustomGroupingState from '../context/useCustomGroupingState';
import CustomGroupingInfoView from '../info-view/CustomGroupingInfoView';
import './custom-grouping-save-modal.scss';

const CustomGroupingSaveModal: FC = () => {
  const dispatch = useDispatch();
  const { allowCreate, createCustomGrouping } = useCustomGroupingState();
  const saveModalOpen = useAppSelector(isSaveModalOpen);

  const closeModal = useCallback(() => {
    dispatch(setSaveModalOpen(false));
  }, [dispatch]);

  const onConfirm = useCallback(async () => {
    const isSuccessful = await createCustomGrouping();
    if (isSuccessful) {
      closeModal();
      dispatch(closeDrawer());
      dispatch(loadAllChars());
      dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Custom grouping saved successfully'));
    }
  }, [dispatch, createCustomGrouping, closeModal]);

  return (
    <MainModal
      open={saveModalOpen}
      headerText='Save Custom Grouping as'
      confirmButtonText='Save'
      onRequestSubmit={onConfirm}
      onRequestClose={closeModal}
      confirmButtonDisabled={!allowCreate()}
    >
      <div className='custom-grouping-save-modal'>
        <Typography>
          This will save your Custom Grouping in the Characteristics list in the Report Designer.
          You can apply it to other reports.
        </Typography>
        <CustomGroupingInfoView isSaving />
      </div>
    </MainModal>
  );
};

export default CustomGroupingSaveModal;
