import {
  DashboardRounded as DashboardIcon,
  EditRounded as EditIcon,
  StarRounded as StarIcon,
} from '@material-ui/icons';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { openFolderDrawer } from '../../../redux/ActionCreators';
import { openCreateDrawer } from '../../../redux/custom-grouping/actions';
import { openNewReport } from '../../../redux/ReportActionCreators';
import { intendChangeWorkspace } from '../../../redux/ui/actionCreators';
import { ChangeWorkspaceModalStatus } from '../../../redux/ui/changeWorkspaceModalStatus';
import { DrawerType } from '../../../shared/constants';
import {
  AddchartRounded as AddchartIcon,
  DashboardCustomizeRounded as DashboardCustomizeIcon,
} from '../../mui-v5-icons';
import QuickActionGroup from '../../quick-action-group/QuickActionGroup';
import { CUSTOM_GROUPING_DEBUG } from '../../shared/environment';

const mapDispatchToProps = {
  intendChangeWorkspace,
  openNewReport,
  openFolderDrawer,
  openCreateDrawer,
};

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const OpenQuickActions: FC<Props> = props => (
  <QuickActionGroup
    label='Start'
    quickActions={[
      ...(CUSTOM_GROUPING_DEBUG
        ? [
            {
              label: 'Create Custom Grouping',
              icon: StarIcon,
              onClick: () => props.openCreateDrawer(),
            },
          ]
        : []),

      {
        label: 'Create a new workspace',
        icon: DashboardCustomizeIcon,
        onClick: () => props.intendChangeWorkspace(ChangeWorkspaceModalStatus.CREATE_NEW),
      },
      {
        label: 'Create a new report',
        icon: AddchartIcon,
        onClick: () => props.openNewReport(),
      },
      {
        label: 'Open a saved workspace',
        icon: DashboardIcon,
        onClick: () => props.intendChangeWorkspace(ChangeWorkspaceModalStatus.OPEN),
      },
      {
        label: 'Edit a saved report',
        icon: EditIcon,
        onClick: () => props.openFolderDrawer(DrawerType.OPEN_REPORT_IN_DESIGNER),
      },
    ]}
  />
);

export default connector(OpenQuickActions);
