import { FC, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { paletteT50 as colors } from '../shared/colorPalettes';
import { legendWrapperStyles } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { useChartOpacity } from './helpers/chartingHelpers';

const BarChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.BAR_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const barChartData = reportConvertedData.rows;

  // If this is a stacked bar chart then assign each bar the same  stack id.
  // This will result in the bars being stacked.
  const stackId = props.stacked ? 'id' : null;

  const barCharts = reportConvertedData.headers.map((el, colorIndex) => (
    <Bar
      stackId={stackId}
      key={el}
      dataKey={el}
      fill={colors[colorIndex]}
      onClick={event => props.onElementClick(event[`${el}rowcol`])(event)}
      fillOpacity={opacity[el] || 1}
    />
  ));

  const isInverted = props.isInverted;

  const xAxisProps: XAxisProps = isInverted
    ? { type: 'number' }
    : { dataKey: reportConvertedData.groupingHeader.name };
  const yAxisProps: YAxisProps = isInverted
    ? { dataKey: reportConvertedData.groupingHeader.name, type: 'category' }
    : {};

  return (
    <BarChart
      stackOffset='sign'
      layout={isInverted ? 'vertical' : 'horizontal'}
      width={props.width}
      height={props.height}
      data={barChartData}
      margin={{ left: 30 }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis {...xAxisProps} />
      <YAxis {...yAxisProps} />
      <Tooltip formatter={(value, name, props) => props.payload[`${name}Formatted`]} />
      <Legend
        layout='horizontal'
        align='center'
        verticalAlign='bottom'
        wrapperStyle={legendWrapperStyles.horizontal}
        onMouseEnter={onChartEnter(reportConvertedData.headers)}
        onMouseLeave={onChartLeave}
      />
      {barCharts}
    </BarChart>
  );
};

export default BarChartViz;
