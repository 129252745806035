import { Typography } from '@material-ui/core';
import { FC } from 'react';
import './quick-action-group.scss';
import QuickAction, { Props as QuickActionProps } from './QuickAction';

interface Props {
  label: string;
  quickActions: QuickActionProps[];
}

const QuickActionGroup: FC<Props> = props => (
  <div className='quick-action-group'>
    <Typography className='quick-action-group-label' gutterBottom>
      {props.label}
    </Typography>

    <div className='quick-action-group-content'>
      {props.quickActions.map((qaProps: QuickActionProps) => (
        <QuickAction {...qaProps} key={qaProps.label} />
      ))}
    </div>
  </div>
);

export default QuickActionGroup;
