import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { DrawerFooter, DrawerHeader, RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeProxyDrawer, enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { NotificationLevel } from '../../../shared/constants';
import {
  Fund,
  InstrumentOrFund,
  ProxyConfig,
  ProxyContext,
  ServerInstrument,
} from '../../../shared/dataTypes';
import { getDateContextString, getSelectedSandbox, useDebounce } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import ResizableDrawerWrapper from '../ResizableDrawerWrapper';
import InstrumentSelector from './InstrumentSelector';
import './proxy-drawer.scss';
import ProxySelector from './ProxySelector';
import SelectedInstrument from './SelectedInstrument';

const mapStateToProps = (state: AppState) => ({
  open: state.drawers.proxyDrawer.open,
  positionToEdit: state.drawers.proxyDrawer.positionToEdit,
  dateContextType:
    state.report.reportDefinition[state.drawers.proxyDrawer.sequenceId]?.dateContext.type,
  dateContextString: getDateContextString(
    state.report.reportDefinition[state.drawers.proxyDrawer.sequenceId]?.dateContext,
    state.user.selectedDateContext,
  ),
  sandbox: getSelectedSandbox(state, state.drawers.proxyDrawer.sequenceId),
});

const mapDispatchToProps = { closeProxyDrawer, enqueueSnackbar };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ProxyDrawer: FC<Props> = props => {
  const [positionText, setPositionText] = useState('');
  const [positionsAnchorEl, setPositionsAnchorEl] = useState<null | HTMLElement>(null);
  const [positions, setPositions] = useState<ProxyConfig[]>([]);
  const [selectedPosition, setSelectedPosition] = useState<ProxyConfig>();

  // Variable to refer to when determining if any changes have been made
  const [initialInstruments, setInitialInstruments] = useState<InstrumentOrFund[]>([]);

  const [selectedInstruments, setSelectedInstruments] = useState<InstrumentOrFund[]>([]);

  const [loading, setLoading] = useState(false);
  const [positionsLoading, setPositionsLoading] = useState(false);

  const debouncedPositionText = useDebounce(positionText, 500);

  // Clear local state on drawer close
  // Conditional inclusion of this component by its parent would obviate this, but would also
  // remove the drawer open/close animations.
  const { open } = props;
  useEffect(() => {
    if (!open) {
      setPositionText('');
      setPositions([]);
      setSelectedPosition(undefined);
      setInitialInstruments([]);
      setSelectedInstruments([]);
    }
  }, [open]);

  // If we're opening a position to edit, get data about that position
  const { positionToEdit, sandbox, dateContextString, dateContextType, enqueueSnackbar } = props;
  useEffect(() => {
    if (positionToEdit) {
      setLoading(true);
      getProxyData(
        'proxyConfig',
        '',
        { sandbox, dateContextString, dateContextType },
        enqueueSnackbar,
        positionToEdit,
      )
        .then(response => {
          setSelectedPosition(response.data);
          setInitialInstruments(response.data.instruments);
          setSelectedInstruments(response.data.instruments);
        })
        .finally(() => setLoading(false));
    }
  }, [positionToEdit, sandbox, dateContextString, dateContextType, enqueueSnackbar]);

  // Debounced proxyPositions call
  useEffect(() => {
    if (debouncedPositionText && open) {
      getProxyData(
        'proxyPositions',
        debouncedPositionText,
        { sandbox, dateContextString, dateContextType },
        enqueueSnackbar,
      )
        .then(response => setPositions(response.data.filter((_x, i) => i < 500)))
        .finally(() => setPositionsLoading(false));
    } else setPositions([]);
  }, [debouncedPositionText, open, sandbox, dateContextString, dateContextType, enqueueSnackbar]);

  const handlePositionTextChange = (newText: string) => {
    setPositionsLoading(true);
    setPositionText(newText);
    setSelectedPosition(undefined);
  };

  const handleInstrumentWeightChange = (instrumentId: string, newWeight: number) => {
    setSelectedInstruments(
      selectedInstruments.map(s =>
        s.id === instrumentId ? { ...s, instrumentWeightPct: newWeight } : s,
      ),
    );
  };

  const onApplyClick = () => {
    const {
      hashBucketBucket,
      hashBucketPortfolio,
      hierarchyId,
      positionDate,
      positionId,
      positionUUID,
    } = selectedPosition;

    const proxyConfig: ProxyConfig = {
      proxyName: selectedPosition.proxyName,
      sandboxPath: props.sandbox.path,
      privateSandbox: props.sandbox.prvSandbox,
      instruments: selectedInstruments.map(s => ({
        ...s,
        instrumentId: s.id,
        instrumentName: s.name,
      })),
      hashBucketBucket,
      hashBucketPortfolio,
      hierarchyId,
      positionDate,
      positionId,
      positionUUID,
    };

    setLoading(true);

    axios
      .put(`${baseUrl}api/proxyConfig`, proxyConfig, {
        params: { dateType: props.dateContextType, dateContexts: props.dateContextString },
      })
      .then(() => {
        props.closeProxyDrawer();
        props.enqueueSnackbar(NotificationLevel.SUCCESS, 'Proxy saved successfully.');
      })
      .catch(() => props.enqueueSnackbar(NotificationLevel.ERROR, 'Error applying proxy changes.'))
      .finally(() => setLoading(false));
  };

  const handleClearPosition = () => {
    setPositionText('');
    setSelectedPosition(undefined);
  };

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: props.open,
        className: 'right-drawer proxy-drawer',
      }}
      id='ProxyDrawer'
    >
      <DrawerHeader
        headerText={`Proxy Position for ${decodeURIComponent(props.sandbox?.path)}`}
        onXClick={props.closeProxyDrawer}
      />

      {loading && (
        <div className='loading-indicator-container'>
          <CircularProgress />
        </div>
      )}

      {props.open && (
        <div className='proxy-drawer-content'>
          <ProxySelector
            label='Proxy this Position...'
            placeholder='Search for a position'
            value={selectedPosition?.proxyName || positionText}
            onChange={e => handlePositionTextChange(e.target.value)}
            onClear={props.positionToEdit ? undefined : handleClearPosition}
            anchorEl={positionText ? positionsAnchorEl : null}
            setAnchorEl={setPositionsAnchorEl}
            disabled={!!props.positionToEdit}
          >
            {positionsLoading ? (
              <CircularProgress />
            ) : (
              <div className='positions'>
                {positions.map((position, i) => (
                  <React.Fragment key={i}>
                    {[position.path.split('/').slice(-1)[0], position.proxyName].map(
                      (displayText, j) => (
                        <Tooltip
                          key={j}
                          title={`${position.path}/${position.proxyName}`}
                          onClick={() => {
                            setSelectedPosition(position);
                            setPositionsAnchorEl(null);
                          }}
                        >
                          <Typography variant='body2'>{displayText}</Typography>
                        </Tooltip>
                      ),
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </ProxySelector>

          <InstrumentSelector
            label='with these Instruments...'
            placeholder='Search for an instrument'
            disabled={!props.positionToEdit && !selectedPosition}
            {...{ selectedInstruments }}
            sandbox={props.sandbox}
            dateContextString={props.dateContextString}
            dateContextType={props.dateContextType}
            onChange={(instrument, checked) =>
              setSelectedInstruments(
                checked
                  ? [...selectedInstruments, { ...instrument, instrumentWeightPct: 100 }]
                  : selectedInstruments.filter(s => s.id !== instrument.id),
              )
            }
          />

          {selectedInstruments?.map((s, i) => (
            <SelectedInstrument
              key={i}
              instrumentName={s.name || s.id}
              instrumentWeightPct={s.instrumentWeightPct}
              onWeightChange={e => handleInstrumentWeightChange(s.id, Number(e.target.value))}
              onRemoveClick={() =>
                setSelectedInstruments(selectedInstruments.filter(r => r.id !== s.id))
              }
            />
          ))}
        </div>
      )}

      <DrawerFooter
        applyButtonText='Apply'
        onApplyClick={onApplyClick}
        onCancelClick={props.closeProxyDrawer}
        applyDisabled={areInstrumentsSame(initialInstruments, selectedInstruments)}
      >
        <RoundedButton
          color='secondary'
          onClick={() => setSelectedInstruments([])}
          disabled={selectedInstruments.length === 0}
        >
          Clear Proxy
        </RoundedButton>
      </DrawerFooter>
    </ResizableDrawerWrapper>
  );
};

type EndpointReturnTypes = {
  proxyPositions: ProxyConfig[];
  proxyInstruments: (ServerInstrument | Fund)[];
  proxyConfig: ProxyConfig;
};

export async function getProxyData<T extends keyof EndpointReturnTypes>(
  endpoint: T,
  filter: string,
  proxyContext: ProxyContext,
  enqueueSnackbar: (type: NotificationLevel, message: string) => void,
  proxyName?: string,
) {
  if (proxyContext.sandbox && proxyContext.dateContextString && proxyContext.dateContextType)
    try {
      return axios.get<EndpointReturnTypes[T]>(`${baseUrl}api/${endpoint}`, {
        params: {
          sandboxPath: proxyContext.sandbox.path,
          isPrivateSandbox: proxyContext.sandbox.prvSandbox,
          filter,
          dateType: proxyContext.dateContextType,
          dateContexts: proxyContext.dateContextString,
          proxyName,
          includeFunds: proxyContext.includeFunds,
        },
      });
    } catch (e) {
      const errorMessage = `Warning: unable to retrieve ${endpoint}`;
      enqueueSnackbar(NotificationLevel.ERROR, errorMessage);
      throw new Error(`${errorMessage}: ${e}`);
    }
}

const areInstrumentsSame = (a: InstrumentOrFund[], b: InstrumentOrFund[]): boolean => {
  if (a.length !== b.length) return false;

  const aSorted = [...a].sort(instrumentCompare);
  const bSorted = [...b].sort(instrumentCompare);

  for (let i = 0; i < aSorted.length; i++) {
    if (!isInstrumentEqual(aSorted[i], bSorted[i])) return false;
  }

  return true;
};

const instrumentCompare = (a: InstrumentOrFund, b: InstrumentOrFund) => (a.id > b.id ? 1 : -1);

const isInstrumentEqual = (a: InstrumentOrFund, b: InstrumentOrFund) =>
  a.id === b.id && a.instrumentWeightPct === b.instrumentWeightPct;

export default connector(ProxyDrawer);
