import { CloudDownloadRounded, CloudUploadRounded } from '@material-ui/icons';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { openFolderDrawer } from '../../../redux/ActionCreators';
import {
  setSelectedReportImportFile,
  setSelectedWorkspaceImportFile,
} from '../../../redux/WorkspaceActionCreators';
import { DrawerType } from '../../../shared/constants';
import createUploadTrigger from '../../../shared/createUploadTrigger';
import QuickActionGroup from '../../quick-action-group/QuickActionGroup';

const mapDispatchToProps = {
  setSelectedWorkspaceImportFile,
  setSelectedReportImportFile,
  openFolderDrawer,
};

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const TransferQuickActions: FC<Props> = props => {
  const uploadWorkspaceFile = createUploadTrigger(file => {
    props.setSelectedWorkspaceImportFile(file);
    props.openFolderDrawer(DrawerType.IMPORT_WORKSPACE_TO_FOLDER);
  });
  const uploadReportFile = createUploadTrigger(file => {
    props.setSelectedReportImportFile(file);
    props.openFolderDrawer(DrawerType.IMPORT_REPORT_TO_FOLDER);
  });

  return (
    <QuickActionGroup
      label='Manage'
      quickActions={[
        {
          label: 'Import workspaces',
          icon: CloudUploadRounded,
          onClick: uploadWorkspaceFile,
        },
        {
          label: 'Import reports',
          icon: CloudUploadRounded,
          onClick: uploadReportFile,
        },
        {
          label: 'Export workspaces',
          icon: CloudDownloadRounded,
          onClick: () => props.openFolderDrawer(DrawerType.EXPORT_WORKSPACES),
        },
        {
          label: 'Export reports',
          icon: CloudDownloadRounded,
          onClick: () => props.openFolderDrawer(DrawerType.EXPORT_REPORTS),
        },
      ]}
    />
  );
};

export default connector(TransferQuickActions);
