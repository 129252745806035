import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import { setIsAboutOpen } from '../../redux/ui/actionCreators';
import {
  capitalizeFirstLetter,
  clientUuid,
  getAboutCustomContent,
  getAboutCustomName,
  processAboutCustomName,
} from '../../shared/utils';

const mapStateToProps = (state: AppState) => ({
  isAboutOpen: state.ui.isAboutOpen,
  aboutContent: state.user.userInfo.serverConfigs?.buildInfo,
  aboutDevContent: state.user.userInfo.serverConfigs?.devBuildInfo,
  customName: getAboutCustomName(state),
  customContent: getAboutCustomContent(state),
});

const mapDispatchToProps = { setIsAboutOpen };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const AboutDialog: FC<Props> = props => (
  <Dialog
    open={props.isAboutOpen && props.aboutContent !== null}
    onClose={() => props.setIsAboutOpen(false)}
    disableBackdropClick
  >
    <DialogTitle>About</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {props.aboutContent?.stadium && (
          <>
            <b>Stadium:</b>
            <br />
            &nbsp; Version: {props.aboutContent?.stadium?.version}
            <br />
            &nbsp; Build time: {props.aboutContent?.stadium?.time}
            <br />
            <b>{processAboutCustomName(props.customName)}:</b>
            <br />
            {Object.entries(props.customContent).map(([key, value]) => (
              <React.Fragment {...{ key }}>
                &nbsp; {capitalizeFirstLetter(key)}: {value}
                <br />
              </React.Fragment>
            ))}
            <b>IWA:</b>
            <br />
            &nbsp; Version: {props.aboutContent?.iwa?.version}
            <br />
            <br />
            <b>Client UUID:</b> {clientUuid}
            <br />
            <br />
            {props.aboutDevContent && (
              <>
                <b>Dev build info:</b>
                <br />
                {Object.keys(props.aboutDevContent).map(k => {
                  return (
                    <React.Fragment key={k}>
                      &nbsp;
                      {props.aboutDevContent[k].startsWith('http') ? (
                        <>
                          {k}:{' '}
                          <a
                            style={{ textDecoration: 'none' }}
                            target='_blank'
                            rel='noreferrer'
                            href={props.aboutDevContent[k]}
                          >
                            {/[^/]*$/.exec(props.aboutDevContent[k])[0]}
                          </a>
                        </>
                      ) : (
                        <>
                          {k}: {props.aboutDevContent[k]}
                        </>
                      )}
                      <br />
                    </React.Fragment>
                  );
                })}
                <br />
                <br />
              </>
            )}
            <>
              Licensed Materials - Property of SS&amp;C Technologies, Inc. © Copyright SS&amp;C
              Technologies, Inc. 2012, 2022. SS&amp;C, Algo™ and Algorithmics™ are trademarks or
              registered trademarks of SS&amp;C. Java and all Java-based trademarks and logos are
              trademarks or registered trademarks of Oracle and/or its affiliates. Other product and
              service names might be trademarks of SS&amp;C or other companies. This Software is
              licensed under the terms of the license agreement agreed to by Client and SS&amp;C
              Technologies, Inc. By using the Software, you agree to these terms.
            </>
          </>
        )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <RoundedButton
        variant='contained'
        color='primary'
        onClick={() => props.setIsAboutOpen(false)}
      >
        OK
      </RoundedButton>
    </DialogActions>
  </Dialog>
);

export default connector(AboutDialog);
