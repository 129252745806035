import { FC, ReactNode } from 'react';

export interface Props {
  children: ReactNode;
}

const createMarkupComponent = (styleName: string): FC<Props> => ({ children: content }) => (
  <span className={`markup-${styleName}`}>{content}</span>
);

export const CodeMarkup = createMarkupComponent('code');
export const HighlightMarkup = createMarkupComponent('highlight');
export const StrongMarkup = createMarkupComponent('strong');
