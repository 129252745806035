import { Typography } from '@material-ui/core';
import { FC } from 'react';

interface Props {
  name: string;
}

const SettingsHeader: FC<Props> = props => (
  <Typography
    variant='h2'
    style={{
      paddingBottom: '12px',
    }}
  >
    {props.name}
  </Typography>
);

export default SettingsHeader;
