import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import { FC, useState } from 'react';

interface Props {
  open: boolean;
  onUpdateClick: (newPassword: string) => void;
  onCancelClick: () => void;
}

const ResetPasswordDialog: FC<Props> = props => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const disabled = confirmPassword !== newPassword;

  return (
    <Dialog open={props.open} onClose={props.onCancelClick}>
      <DialogTitle>Reset User Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Updating a user's current password will take immediate effect.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          margin='dense'
          variant='outlined'
          label='New Password'
          placeholder='Enter new password...'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <TextField
          fullWidth
          margin='dense'
          variant='outlined'
          label='Re-Type Password'
          placeholder='Re-Type new password...'
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          error={disabled}
          helperText={disabled ? 'Password does not match' : ''}
        />
      </DialogContent>
      <DialogActions>
        <RoundedButton onClick={props.onCancelClick} color='primary' variant='outlined'>
          Cancel
        </RoundedButton>
        <RoundedButton
          disabled={disabled || newPassword.trim() === ''}
          onClick={() => {
            props.onUpdateClick(newPassword);
          }}
          color='primary'
          variant='contained'
        >
          Update Password
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
