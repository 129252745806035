import { Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { DESIGNER_SEQUENCE_ID, NotificationLevel } from '../../../shared/constants';
import { getDateContextStringWithUnderscore, isAwa } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import './benchmarks-panel.scss';
import SingleBenchmarkPanel from './SingleBenchmarkPanel';

interface BaseProps {
  hidden: boolean;
}
const mapStateToProps = (state: AppState) => ({
  benchmarks: state.reportDesigner.benchmarks,
  isAwa: isAwa(state),
  type: state.report.reportDefinition[DESIGNER_SEQUENCE_ID].dateContext.type,
  contexts: getDateContextStringWithUnderscore(
    state.report.reportDefinition[DESIGNER_SEQUENCE_ID].dateContext,
    state.user.selectedDateContext,
  ),
});

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const BenchmarksPanel: FC<Props> = ({ isAwa, benchmarks, hidden, contexts, type }) => {
  const [benchmarkSets, setBenchmarkSets] = useState<string[]>([]);
  const [showBenchmark1, setShowBenchmark1] = useState<boolean>(true);

  useEffect(() => {
    if (!hidden) {
      if (isAwa) {
        axios
          .get<string[]>(`${baseUrl}api/benchmarkSets`, {
            params: { dateType: type, contexts: contexts },
          })
          .then(response => {
            setBenchmarkSets(response.data);
          })
          .catch((error: Error) =>
            enqueueSnackbar(
              NotificationLevel.ERROR,
              `Failed to load benchmark sets from server: ${error.message}`,
            ),
          );
      }
    }
  }, [hidden, isAwa, contexts, type]);

  return (
    <div className='benchmarks-panel' hidden={hidden}>
      <div
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          marginTop: '25px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h2'>Benchmark Options</Typography>
        <ToggleButtonGroup value={['Benchmark 1', 'Benchmark 2']} exclusive>
          <ToggleButton
            value='Benchmark 1'
            selected={showBenchmark1}
            onClick={() => setShowBenchmark1(true)}
          >
            Benchmark 1
          </ToggleButton>
          <ToggleButton
            value='Benchmark 2'
            selected={!showBenchmark1}
            onClick={() => setShowBenchmark1(false)}
          >
            Benchmark 2
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {showBenchmark1 && (
        <SingleBenchmarkPanel
          hidden={hidden}
          index={0}
          benchmark={benchmarks[0]}
          benchmarkSets={benchmarkSets}
        />
      )}
      {!showBenchmark1 && (
        <SingleBenchmarkPanel
          hidden={hidden}
          index={1}
          benchmark={benchmarks[1]}
          benchmarkSets={benchmarkSets}
        />
      )}
    </div>
  );
};

export default connector(BenchmarksPanel);
