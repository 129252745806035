import { FC } from 'react';
import { AuthConsumer } from './AuthProvider';

export const LogoutCallback: FC = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback();
      return <span>loading</span>;
    }}
  </AuthConsumer>
);
