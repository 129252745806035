import { Route, Switch } from 'react-router-dom';
import MainComponent from '../../main/Main';
import { uriLoginCallback, uriLogout, uriLogoutCallback, uriRoot, uriSilentRenew } from './AuthCfg';
import { Callback } from './Callback';
import { Logout } from './Logout';
import { LogoutCallback } from './LogoutCallback';
import { PrivateRoute } from './PrivateRoute';
import { SilentRenew } from './SilentRenew';

export const Routes = (
  <Switch>
    <Route exact={true} path={uriLoginCallback.path} component={Callback} />
    <Route exact={true} path={uriLogout.path} component={Logout} />
    <Route exact={true} path={uriLogoutCallback.path} component={LogoutCallback} />
    <Route exact={true} path={uriSilentRenew.path} component={SilentRenew} />
    {<PrivateRoute path={uriRoot.path} component={MainComponent} />}
  </Switch>
);
