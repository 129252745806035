import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar, setDefaultPortfolioSelection } from '../../redux/ActionCreators';
import { AppState, useAppSelector } from '../../redux/configureStore';
import { setWorkspacePortfolioSelection } from '../../redux/WorkspaceActionCreators';
import PortfolioDrawer from './PortfolioDrawer';
import {
  getSelectedPortfolios,
  processPortfolioTreeSelection,
  useGetPortfolios,
} from './portfolioDrawerHelpers';

interface BaseProps {
  open: boolean;
  close: () => void;
}

const mapStateToProps = (state: AppState) => ({
  defaultDateContext: state.user.selectedDateContext,
  ...getSelectedPortfolios(state, null),
});
const mapDispatchToProps = {
  setWorkspacePortfolioSelection,
  setDefaultPortfolioSelection,
  enqueueSnackbar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const WorkspacePortfolioDrawer: FC<Props> = props => {
  const defaultPortfolioSelection = useAppSelector(
    state => state.user.userInfo.userPreferences.defaultPortfolioSelection ?? {},
  );

  const {
    portfolioHierarchy,
    adHocPortfolios,
    benchmarkPortfolios,
    selectedKeys,
    setSelectedKeys,
    selectedAdHocKeys,
    setSelectedAdHocKeys,
    selectedBenchmarkKeys,
    setSelectedBenchmarkKeys,
    getOptions,
  } = useGetPortfolios(
    { type: 'asOf', dates: [props.defaultDateContext] },
    props.defaultDateContext,
    props.enqueueSnackbar,
    props.open,
    props.selectedPortfolioNodeIds,
    props.selectedAdHocPortfolioNames,
    props.selectedBenchmarkPortfolioNames,
  );

  return (
    <PortfolioDrawer
      headerText='Select Your Portfolio'
      drawerOpen={props.open}
      closeDrawer={props.close}
      checkboxes
      onApplyClick={() => {
        // transform selectedKeys based on portfolioHierarchy
        const processedSelectedKeys = processPortfolioTreeSelection(
          selectedKeys,
          portfolioHierarchy,
        );

        props.setWorkspacePortfolioSelection({
          selectedPortfolioNodeIds: processedSelectedKeys,
          selectedAdHocPortfolioNames: selectedAdHocKeys,
          selectedBenchmarkPortfolioNames: selectedBenchmarkKeys,
        });
        props.close();
      }}
      setAsDefault={() => {
        props.setDefaultPortfolioSelection({
          selectedPortfolioNodeIds: processPortfolioTreeSelection(selectedKeys, portfolioHierarchy),
          selectedAdHocPortfolioNames: selectedAdHocKeys,
          selectedBenchmarkPortfolioNames: selectedBenchmarkKeys,
        });
      }}
      resetToDefault={() => {
        const {
          selectedPortfolioNodeIds,
          selectedAdHocPortfolioNames,
          selectedBenchmarkPortfolioNames,
        } = defaultPortfolioSelection;

        setSelectedKeys(selectedPortfolioNodeIds ?? []);
        setSelectedAdHocKeys(selectedAdHocPortfolioNames ?? []);
        setSelectedBenchmarkKeys(selectedBenchmarkPortfolioNames ?? []);
      }}
      resetToDefaultDisabled={
        defaultPortfolioSelection.selectedPortfolioNodeIds?.length === 0 &&
        defaultPortfolioSelection.selectedAdHocPortfolioNames?.length === 0 &&
        defaultPortfolioSelection.selectedBenchmarkPortfolioNames?.length === 0
      }
      dateContext={{ type: 'asOf', dates: [props.defaultDateContext] }}
      getOptions={getOptions}
      portfolioHierarchy={portfolioHierarchy}
      adHocPortfolios={adHocPortfolios}
      benchmarkPortfolios={benchmarkPortfolios}
      selectedKeys={selectedKeys}
      setSelectedKeys={setSelectedKeys}
      selectedAdHocKeys={selectedAdHocKeys}
      setSelectedAdHocKeys={setSelectedAdHocKeys}
      selectedBenchmarkKeys={selectedBenchmarkKeys}
      setSelectedBenchmarkKeys={setSelectedBenchmarkKeys}
      className='right-drawer'
    />
  );
};

export default connector(WorkspacePortfolioDrawer);
