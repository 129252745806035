import { FC } from 'react';
import { AuthConsumer } from './AuthProvider';

export const SilentRenew: FC = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <span>loading</span>;
    }}
  </AuthConsumer>
);
