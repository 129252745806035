import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { FC, useState } from 'react';

interface Props {
  label: string;
  rename: () => void;
  delete: () => void;
}

const AdHocPortfolioDrawerItem: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hover, setHover] = useState(false);

  const menuSelect = (f: () => void) => () => {
    f();
    setAnchorEl(null);
  };

  return (
    <div className='item' onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
      {props.label}
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreHoriz style={{ color: !hover && 'white' }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        {/* Commenting out rename functionality until it can be implemented server-side */}
        {/* <MenuItem onClick={menuSelect(props.rename)}>Rename</MenuItem> */}
        <MenuItem onClick={menuSelect(props.delete)}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

export default AdHocPortfolioDrawerItem;
