import { Switch } from '@material-ui/core';
import { UISlot } from '../../shared/dataTypes';
import DivSlot from './DivSlot';
import { MultiSelectDropdownSlot, SingleSelectDropdownSlot } from './DropdownSlots';

function SlotComponent(props: any) {
  const slotDetails: UISlot = props.slotDetails;
  const controlType = slotDetails.controlType;

  const textBefore = slotDetails.complicationDetails.textBefore;
  const textAfter = slotDetails.complicationDetails.textAfter;

  if (!slotDetails.enabled) {
    return null;
  }

  switch (controlType) {
    case 'MULTI_DROPDOWN':
      return (
        <>
          {textBefore && textBefore.length > 0 ? (
            <div
              style={{
                display: 'inline-block',
                marginRight: '6px',
                marginLeft: '6px',
              }}
            >
              {textBefore}
            </div>
          ) : (
            ''
          )}
          <MultiSelectDropdownSlot slotDetails={slotDetails} onChange={props.onChange} />
          {textAfter && textAfter.length > 0 ? (
            <div style={{ display: 'inline-block', marginLeft: '6px' }}>{textAfter}</div>
          ) : (
            ''
          )}
        </>
      );
    case 'SINGLE_DROPDOWN':
      if (slotDetails.complicationDetails.type === 'ChartLayoutComplication') {
        return (
          <>
            {textBefore && textBefore.length > 0 ? (
              <div
                style={{
                  display: 'inline-block',
                  marginRight: '6px',
                  marginLeft: '6px',
                }}
              >
                {textBefore}
              </div>
            ) : (
              ''
            )}
            <Switch
              color='primary'
              size='small'
              checked={slotDetails.complicationDetails.selectedOption === 'VERTICAL'}
              onChange={e => {
                props.onChange(
                  slotDetails,
                  e.target.checked ? 'selectedOption,VERTICAL' : 'selectedOption,HORIZONTAL',
                );
              }}
            />
            {textAfter && textAfter.length > 0 ? (
              <div style={{ display: 'inline-block', marginLeft: '6px' }}>{textAfter}</div>
            ) : (
              ''
            )}
          </>
        );
      } else {
        return (
          <>
            {textBefore && textBefore.length > 0 ? (
              <div
                style={{
                  display: 'inline-block',
                  marginRight: '6px',
                  marginLeft: '6px',
                }}
              >
                {textBefore}
              </div>
            ) : (
              ''
            )}
            <SingleSelectDropdownSlot slotDetails={slotDetails} onChange={props.onChange} />
            {textAfter && textAfter.length > 0 ? (
              <div style={{ display: 'inline-block', marginLeft: '6px' }}>{textAfter}</div>
            ) : (
              ''
            )}
          </>
        );
      }
    case 'TEXT':
      return <DivSlot slotDetails={slotDetails} />;
    default:
      return null;
  }
}

export default SlotComponent;
