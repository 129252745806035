import { FC } from 'react';
import { useAppSelector } from '../../../redux/configureStore';
import { showCustomGroupingInfo } from '../../../redux/custom-grouping/selectors';
import GroupingStrategyDesigner from '../grouping-strategy-designer/GroupingStrategyDesigner';
import GroupingTreeViewContainer from '../grouping-tree-view/Container';
import CustomGroupingInfoView from '../info-view/CustomGroupingInfoView';
import CustomGroupingActionBar from './CustomGroupingActionBar';

const CustomGroupingDrawerContent: FC = () => {
  const showInfo = useAppSelector(showCustomGroupingInfo);

  return (
    <div className='custom-grouping-drawer-content'>
      {showInfo && <CustomGroupingInfoView />}
      <CustomGroupingActionBar />
      <GroupingTreeViewContainer />
      <GroupingStrategyDesigner />
    </div>
  );
};

export default CustomGroupingDrawerContent;
