import { CircularProgress, FormControlLabel, Paper, TextField } from '@material-ui/core';
import axios from 'axios';
import { FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { setAccordionsExpanded } from '../../../redux/metadata/actions';
import { CharacteristicDrawerType, NotificationLevel } from '../../../shared/constants';
import { EditorInterfaceType } from '../../editor/ascript/model/editorInterfaceType';
import OpenEditorButton from '../../editor/ascript/OpenEditorButton';
import { baseUrl } from '../../shared/environment';
import SwitchAccordion from '../../shared/SwitchAccordion';
import AggregationInput from './AggregationInput';
import CharacteristicAccordion from './CharacteristicAccordion';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import {
  CharacteristicDrawerStatus,
  getCheckboxFormProps,
  getTextFieldFormProps,
} from './characteristicDrawerFormHelpers';
import { CharacteristicDrawerTab } from './characteristicDrawerTabs';
import CharacteristicDropdown from './CharacteristicDropdown';
import CharacteristicExpandAll from './CharacteristicExpandAll';
import { getAccordionIds } from './characteristicExpandedAccordion';
import CharacteristicOtherDropdown from './CharacteristicOtherDropdown';
import ChooserVisibility from './ChooserVisibility';
import ColumnClassesInput from './ColumnClassesInput';

interface Props {
  formProps: FormikProps<CharacteristicDrawerForm>;
  characteristicDrawerType: CharacteristicDrawerType;
}

const Properties: FC<Props> = props => {
  const [valueKeyDisabled, setValueKeyDisabled] = useState(false);
  const [valueKeyManuallyChanged, setValueKeyManuallyChanged] = useState(false);
  const entityTypes = useAppSelector(state => state.metadata.entityTypes);
  const initialFormValues = useAppSelector(resolveInitialFormValues);
  const sandbox = useAppSelector(state => state.metadata.sandbox?.path);

  const dispatch = useDispatch();
  const { characteristicDrawerType } = props;
  useEffect(() => {
    characteristicDrawerType === CharacteristicDrawerType.ADD &&
      dispatch(setAccordionsExpanded({ 1: true, 2: true }));
  }, [dispatch, characteristicDrawerType]);

  const getTextFieldProps = getTextFieldFormProps(props.formProps, initialFormValues);
  const getCheckboxProps = getCheckboxFormProps(props.formProps, initialFormValues);

  const setDynamicValueKey = () => {
    if (props.formProps.values.colName !== '') {
      setValueKeyDisabled(true);
      axios
        .get<string>(`${baseUrl}api/getValueKey`, {
          params: { name: props.formProps.values.colName, sandbox },
        })
        .then(({ data }) => props.formProps.setFieldValue('valueKey', data))
        .catch(() =>
          dispatch(
            enqueueSnackbar(
              NotificationLevel.ERROR,
              'An error occurred while generating the value key.',
            ),
          ),
        )
        .finally(() => setValueKeyDisabled(false));
    }
  };

  return (
    <div>
      <CharacteristicExpandAll accordionIds={getAccordionIds(CharacteristicDrawerTab.PROPERTIES)} />
      <CharacteristicAccordion summary='Name' accordionId='1'>
        <TextField
          label='Name'
          {...getTextFieldProps(
            'colName',
            () =>
              props.formProps.values.dynamic && !valueKeyManuallyChanged && setDynamicValueKey(),
          )}
          className='full-width-container'
          autoFocus={characteristicDrawerType === CharacteristicDrawerType.ADD}
        />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Value Key' accordionId='2'>
        <CharacteristicDropdown
          id='entityType'
          formProps={props.formProps}
          items={entityTypes}
          textFieldProps={{
            label: 'Entity Type',
            className: 'full-width-container',
            disabled: !entityTypes,
            InputProps: {
              endAdornment: entityTypes ? null : (
                <CircularProgress style={{ marginRight: '10px', transform: 'scale(0.3)' }} />
              ),
            },
          }}
        />
        <div className='side-by-side full-width-container'>
          <TextField
            label='Value Key'
            {...getTextFieldProps('valueKey', () => setValueKeyManuallyChanged(true))}
            disabled={
              props.characteristicDrawerType === CharacteristicDrawerType.EDIT || valueKeyDisabled
            }
          />
          <FormControlLabel
            label='Dynamic'
            {...getCheckboxProps(
              'dynamic',
              checked => checked && !valueKeyManuallyChanged && setDynamicValueKey(),
            )}
            disabled={
              props.characteristicDrawerType === CharacteristicDrawerType.EDIT ||
              props.formProps.status === CharacteristicDrawerStatus.VALIDATING
            }
          />
        </div>

        <TextField label='Key Translation' {...getTextFieldProps('keyTranslation')} />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Format' accordionId='3'>
        <CharacteristicDropdown
          id='colType'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Type',
            className: 'full-width-container',
          }}
        />

        <CharacteristicOtherDropdown
          label='Format String'
          id='numberFormatString'
          formProps={props.formProps}
        />

        <TextField
          label='Divisor'
          {...getTextFieldProps('divisor')}
          className='full-width-container'
          type='number'
        />
        <CharacteristicDropdown
          id='justification'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Justification',
            className: 'full-width-container',
          }}
        />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Definition' accordionId='4'>
        <div className='side-by-side full-width-container'>
          <TextField label='Computer' {...getTextFieldProps('computer')} />
          <OpenEditorButton
            interfaceType={EditorInterfaceType.VALUE_COMPUTER}
            formProps={props.formProps}
          />
        </div>
        <div className='side-by-side full-width-container'>
          <AggregationInput formProps={props.formProps} />
          <OpenEditorButton
            interfaceType={EditorInterfaceType.AGGREGATOR}
            formProps={props.formProps}
          />
        </div>

        <div className='side-by-side full-width-container'>
          <TextField label='Post-Processor' {...getTextFieldProps('postProcessor')} />
          <OpenEditorButton
            interfaceType={EditorInterfaceType.CELL_COMPUTER}
            formProps={props.formProps}
          />
        </div>
      </CharacteristicAccordion>

      <Paper className='single-control MuiAccordion-root'>
        <FormControlLabel
          labelPlacement='start'
          label='Scalable'
          {...getCheckboxProps('scalable')}
        />
      </Paper>

      <CharacteristicAccordion summary='Multi-Currency' accordionId='5'>
        <div className='side-by-side full-width-container'>
          <FormControlLabel
            label='Currency Sensitive'
            {...getCheckboxProps('ccySensitive')}
            className='full-width-container'
          />
          <FormControlLabel
            label='Show in Report Currency'
            {...getCheckboxProps('showInReportCcy')}
          />
        </div>
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Benchmark' accordionId='6'>
        <TextField
          label='Benchmark Name'
          {...getTextFieldProps('benchName')}
          className='full-width-container'
        />
        <TextField
          label='Difference Name'
          {...getTextFieldProps('diffName')}
          className='full-width-container'
        />
        <CharacteristicDropdown
          id='benchmarkCopy'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Benchmark Copy',
            className: 'full-width-container',
          }}
        />
        <CharacteristicDropdown
          id='benchmarkVisibility'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Benchmark Visibility',
            className: 'full-width-container',
          }}
        />

        <ChooserVisibility
          value={props.formProps.values.colChooserVisibility}
          onChange={v => props.formProps.setFieldValue('colChooserVisibility', v, false)}
        />
      </CharacteristicAccordion>

      <SwitchAccordion
        summary='Editable'
        expanded={props.formProps.values.editable}
        setExpanded={expanded => props.formProps.setFieldValue('editable', expanded, false)}
      >
        <CharacteristicDropdown
          id='editability'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Editable Details',
            className: 'full-width-container',
          }}
        />

        <TextField
          label='Edit Processor'
          {...getTextFieldProps('editProcessorClass')}
          className='full-width-container'
        />

        <CharacteristicDropdown
          id='autoFillType'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Auto Fill',
            className: 'full-width-container',
          }}
        />
      </SwitchAccordion>

      <CharacteristicAccordion summary='Filter &amp; Sort' accordionId='7'>
        <CharacteristicOtherDropdown
          label='Report Formatter'
          id='reportFormatter'
          formProps={props.formProps}
          displayRealValue
        />

        <TextField
          label='Grouping Class'
          {...getTextFieldProps('breakdownClass')}
          className='full-width-container'
        />
        <CharacteristicDropdown
          id='bucketOrderDescending'
          formProps={props.formProps}
          textFieldProps={{
            label: 'Bucket Order',
            className: 'full-width-container',
          }}
        />
        <TextField
          label='Filter Items'
          {...getTextFieldProps('predefinedFilter')}
          className='full-width-container'
        />
      </CharacteristicAccordion>

      <CharacteristicAccordion summary='Other' accordionId='8'>
        <FormControlLabel
          label='Visible Column'
          {...getCheckboxProps('visibleColumn')}
          className='full-width-container'
        />

        <ColumnClassesInput formProps={props.formProps} />

        <TextField
          label='Related Entities'
          {...getTextFieldProps('relatedEntities')}
          className='full-width-container'
        />

        <CharacteristicOtherDropdown
          label='Data Entry Component'
          id='dataEntryComponent'
          formProps={props.formProps}
          displayRealValue
        />
      </CharacteristicAccordion>
    </div>
  );
};

export default Properties;
