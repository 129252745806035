import { RoundedButton } from 'algo-react-dataviz';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar, setDefaultPortfolioSelection } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import {
  removeReportPortfolioNodes,
  setReportPortfolioNodes,
} from '../../redux/ReportActionCreators';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import PortfolioDrawer from '../drawers/PortfolioDrawer';
import {
  getSelectedPortfolios,
  processPortfolioTreeSelection,
  useGetPortfolios,
} from '../drawers/portfolioDrawerHelpers';

interface BaseProps {
  drawerOpen: boolean;
  closeDrawer: () => void;
}

const mapStateToProps = (state: AppState) => ({
  dateContext: state.report.reportDefinition[DESIGNER_SEQUENCE_ID]?.dateContext,
  defaultDateContext: state.user.selectedDateContext,
  defaultPortfolioSelection: state.user.userInfo.userPreferences.defaultPortfolioSelection ?? {},
  ...getSelectedPortfolios(state, DESIGNER_SEQUENCE_ID),
});
const mapDispatchToProps = {
  setReportPortfolioNodes,
  removeReportPortfolioNodes,
  setDefaultPortfolioSelection,
  enqueueSnackbar,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const DesignerPortfolioDrawer: FC<Props> = props => {
  const {
    portfolioHierarchy,
    adHocPortfolios,
    benchmarkPortfolios,
    selectedKeys,
    setSelectedKeys,
    selectedAdHocKeys,
    setSelectedAdHocKeys,
    selectedBenchmarkKeys,
    setSelectedBenchmarkKeys,
    getOptions,
  } = useGetPortfolios(
    props.dateContext,
    props.defaultDateContext,
    props.enqueueSnackbar,
    props.drawerOpen,
    props.selectedPortfolioNodeIds,
    props.selectedAdHocPortfolioNames,
    props.selectedBenchmarkPortfolioNames,
  );

  return (
    <PortfolioDrawer
      headerText='Report Portfolios'
      drawerOpen={props.drawerOpen}
      closeDrawer={props.closeDrawer}
      checkboxes
      onApplyClick={() => {
        // transform selectedKeys based on portfolioHierarchy
        const processedSelectedKeys = processPortfolioTreeSelection(
          selectedKeys,
          portfolioHierarchy,
        );

        props.setReportPortfolioNodes(DESIGNER_SEQUENCE_ID, {
          selectedPortfolioNodeIds: processedSelectedKeys,
          selectedAdHocPortfolioNames: selectedAdHocKeys,
          selectedBenchmarkPortfolioNames: selectedBenchmarkKeys,
        });
        props.closeDrawer();
      }}
      setAsDefault={() => {
        props.setDefaultPortfolioSelection({
          selectedPortfolioNodeIds: processPortfolioTreeSelection(selectedKeys, portfolioHierarchy),
          selectedAdHocPortfolioNames: selectedAdHocKeys,
          selectedBenchmarkPortfolioNames: selectedBenchmarkKeys,
        });
      }}
      resetToDefault={() => {
        const {
          selectedPortfolioNodeIds,
          selectedAdHocPortfolioNames,
          selectedBenchmarkPortfolioNames,
        } = props.defaultPortfolioSelection;

        setSelectedKeys(selectedPortfolioNodeIds ?? []);
        setSelectedAdHocKeys(selectedAdHocPortfolioNames ?? []);
        setSelectedBenchmarkKeys(selectedBenchmarkPortfolioNames ?? []);
      }}
      resetToDefaultDisabled={
        props.defaultPortfolioSelection.selectedPortfolioNodeIds?.length >= 1 ||
        props.defaultPortfolioSelection.selectedAdHocPortfolioNames?.length >= 1 ||
        props.defaultPortfolioSelection.selectedBenchmarkPortfolioNames?.length >= 1
      }
      dateContext={props.dateContext}
      getOptions={getOptions}
      portfolioHierarchy={portfolioHierarchy}
      adHocPortfolios={adHocPortfolios}
      benchmarkPortfolios={benchmarkPortfolios}
      selectedKeys={selectedKeys}
      setSelectedKeys={setSelectedKeys}
      selectedAdHocKeys={selectedAdHocKeys}
      setSelectedAdHocKeys={setSelectedAdHocKeys}
      selectedBenchmarkKeys={selectedBenchmarkKeys}
      setSelectedBenchmarkKeys={setSelectedBenchmarkKeys}
      className='right-drawer'
      extraButton={
        <RoundedButton
          color='primary'
          onClick={() => {
            props.removeReportPortfolioNodes(DESIGNER_SEQUENCE_ID);
            props.closeDrawer();
          }}
        >
          Restore Default
        </RoundedButton>
      }
    />
  );
};

export default connector(DesignerPortfolioDrawer);
