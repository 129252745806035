import { CascadingMenuItem, CascadingMenuRoot } from 'algo-react-dataviz';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { DESIGNER_SEQUENCE_ID, NotificationLevel } from '../../shared/constants';
import MainModal from '../main/MainModal';
import { baseUrl } from '../shared/environment';
import EditTimeBucketModal, { TimeGrouping } from './EditTimeBucketModal';
import { GrouperProps } from './GrouperModal';

export interface CustomTimeBucket {
  name: string;
  isActive: boolean;
  buckets: TimeGrouping[];
}

export interface TimeBucketConfig {
  all: boolean;
  oneTimeBucket: boolean;
  predefined?: string;
  customTimeBuckets?: CustomTimeBucket[];
}

const defaultTimeBucketConfig: TimeBucketConfig = {
  all: true,
  oneTimeBucket: false,
  customTimeBuckets: [],
};

const mapStateToProps = (state: AppState) => ({
  reportDateContext: state.report.reportDefinition[DESIGNER_SEQUENCE_ID].dateContext,
  selectedDateContext: state.user.selectedDateContext,
  scenarioSets: state.reportDesigner.scenariosConfig.sets,
});

const mapDispatchToProps = {
  enqueueSnackbar,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & GrouperProps<TimeBucketConfig>;

const TimeBucketGrouperModal: FC<Props> = props => {
  const { selectedDateContext, reportDateContext, enqueueSnackbar, scenarioSets } = props;
  const [predefinedTimeBuckets, setPredefinedTimeBuckets] = useState<string[]>([]);
  const [timePoints, setTimePoints] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [closeRequested, setCloseRequested] = useState(false);
  const [customBucketEdit, setCustomBucketEdit] = useState<CustomTimeBucket>();
  const [confirmDeleteGroupingOpen, setConfirmDeleteGroupingOpen] = useState(false);
  const [customBuckets, setCustomBuckets] = useState<CustomTimeBucket[]>(
    props.initialValue?.customTimeBuckets || [],
  );

  useEffect(() => {
    if (closeRequested && !showModal && !confirmDeleteGroupingOpen) {
      props.onClose();
    }
  });

  useEffect(() => {
    let dateContext = selectedDateContext;

    if (reportDateContext.type === 'asOf') {
      dateContext = { date: reportDateContext.dates[0].date, id: reportDateContext.dates[0].id };
    } else if (reportDateContext.type === 'between') {
      dateContext = { date: reportDateContext.dates[0].date, id: '' };
    } else if (reportDateContext.type === 'specific') {
      dateContext = { date: reportDateContext.dates[0].date, id: reportDateContext.dates[0].id };
    }

    axios
      .get<string[]>(`${baseUrl}api/timeBucketGrouperData`, {
        params: {
          date: dateContext.date,
          id: dateContext.id ? dateContext.id : '',
          scenarioSets: scenarioSets.toString(),
        },
      })
      .then(r => {
        if (r.data && r.data['PredefinedTimeBuckets']) {
          setPredefinedTimeBuckets(r.data['PredefinedTimeBuckets']);
        }
        if (r.data && r.data['TimePoints']) {
          setTimePoints(r.data['TimePoints']);
        }
      })
      .catch((error: Error) => {
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to retrieve default predefined time buckets: ${error.message}`,
        );
      });
  }, [enqueueSnackbar, reportDateContext, selectedDateContext, scenarioSets]);

  const timeBucketSettings: TimeBucketConfig = props.initialValue || defaultTimeBucketConfig;

  const allTimebucketsClick = () => {
    props.onApplyClick({
      ...timeBucketSettings,
      all: true,
      oneTimeBucket: false,
      predefined: null,
      customTimeBuckets: timeBucketSettings.customTimeBuckets.map(customTimeBucket => ({
        ...customTimeBucket,
        isActive: false,
      })),
    });
  };

  const oneTimebucketClick = () => {
    props.onApplyClick({
      ...timeBucketSettings,
      all: false,
      oneTimeBucket: true,
      predefined: null,
      customTimeBuckets: timeBucketSettings.customTimeBuckets.map(customTimeBucket => ({
        ...customTimeBucket,
        isActive: false,
      })),
    });
  };

  const predefinedTimeBucketClick = (selection: string) => {
    props.onApplyClick({
      ...timeBucketSettings,
      all: false,
      oneTimeBucket: false,
      predefined: selection,
      customTimeBuckets: timeBucketSettings.customTimeBuckets.map(customTimeBucket => ({
        ...customTimeBucket,
        isActive: false,
      })),
    });
  };

  const getPredefinedBucketMenuItems = () => {
    let items = [];
    predefinedTimeBuckets.map(value =>
      items.push({
        id: 'predefined-' + value,
        text: value,
        onClick: () => predefinedTimeBucketClick(value),
        selected: props.initialValue?.predefined === value,
      }),
    );

    return items;
  };

  const getCustomBucketMenuItems = (timeBucket: CustomTimeBucket) => {
    let items = [];
    items.push({
      id: 'apply',
      text: 'Apply',
      onClick: () => {
        props.onApplyClick({
          ...timeBucketSettings,
          all: false,
          oneTimeBucket: false,
          predefined: null,
          customTimeBuckets: customBuckets.map((b, i) => {
            return { ...b, isActive: i === customBuckets.indexOf(timeBucket) };
          }),
        });
      },
    });
    items.push({
      id: 'edit',
      text: 'Edit',
      onClick: () => {
        setCustomBucketEdit(timeBucket);
        setShowModal(true);
      },
    });
    items.push({
      id: 'delete',
      text: 'Delete',
      onClick: () => {
        setCustomBucketEdit(timeBucket);
        setConfirmDeleteGroupingOpen(true);
      },
    });

    return items;
  };

  const nextGroupName = () => {
    const groupingNumbers = customBuckets.map(bucket => {
      const match = bucket.name.match(/^Custom Group ([0-9]+).*$/);
      return match ? Number(match[1]) : 0;
    });

    const nextNum = groupingNumbers.length > 0 ? Math.max(...groupingNumbers) + 1 : 1;

    return `Custom Group ${nextNum}`;
  };

  const getCustomBucketGroups = () => {
    let items = [];
    items.push({
      id: 'createNew',
      text: 'Create New',
      onClick: () => {
        setCustomBucketEdit(null);
        setShowModal(true);
      },
    });
    customBuckets.forEach(bucket => {
      items.push({
        id: bucket.name,
        text: bucket.name,
        selected: bucket.isActive,
        children: getCustomBucketMenuItems(bucket),
      });
    });
    return items;
  };

  const timeBucketMenu: CascadingMenuItem[] = [
    {
      id: 'All',
      text: 'All',
      onClick: allTimebucketsClick,
      selected: timeBucketSettings.all,
    },
    {
      id: 'oneTimeBucket',
      text: 'One Time Bucket',
      onClick: oneTimebucketClick,
      selected: props.initialValue?.oneTimeBucket,
    },
    {
      id: 'predefined',
      text: 'Predefined',
      disabled: getPredefinedBucketMenuItems().length === 0,
      children: getPredefinedBucketMenuItems(),
      selected: predefinedTimeBuckets.includes(props.initialValue?.predefined),
    },
    {
      id: 'custom',
      text: 'Custom (User Defined)',
      children: getCustomBucketGroups(),
      selected: customBuckets.findIndex(bucket => bucket.isActive) !== -1,
    },
  ];

  return (
    <>
      {showModal && (
        <EditTimeBucketModal
          customBucketEdit={customBucketEdit}
          defaultName={nextGroupName()}
          timePoints={timePoints}
          data={props.initialValue}
          onDeleteClick={() => {
            setCustomBuckets(customBuckets.splice(customBuckets.indexOf(customBucketEdit), 1));
            allTimebucketsClick();
          }}
          onApplyClick={(saveAsNew: boolean, groupName: string, timeGroupings: TimeGrouping[]) => {
            const idx = customBuckets.indexOf(customBucketEdit);

            let newCustomBuckets: CustomTimeBucket[] = [];
            if (customBucketEdit === null || saveAsNew) {
              newCustomBuckets = customBuckets.map(bucket => {
                return { ...bucket, isActive: false };
              });
              newCustomBuckets = newCustomBuckets.concat({
                name: groupName,
                isActive: true,
                buckets: timeGroupings,
              });
            } else {
              newCustomBuckets = customBuckets.map((b, i) => {
                return i !== idx ? b : { ...b, name: groupName, buckets: timeGroupings };
              });
            }

            props.onApplyClick({
              ...timeBucketSettings,
              all: false,
              oneTimeBucket: false,
              predefined: null,
              customTimeBuckets: newCustomBuckets,
            });
          }}
          onCancelClick={props.onCancelClick}
        />
      )}

      <MainModal
        open={confirmDeleteGroupingOpen}
        onRequestSubmit={() => {
          const deletedWasActive = customBuckets[customBuckets.indexOf(customBucketEdit)].isActive;
          setCustomBuckets(customBuckets.splice(customBuckets.indexOf(customBucketEdit), 1));
          if (deletedWasActive) {
            // The deleted group was active. Make 'All' the selection.
            allTimebucketsClick();
          }
          setConfirmDeleteGroupingOpen(false);
        }}
        onRequestClose={() => {
          setConfirmDeleteGroupingOpen(false);
        }}
        headerText='Delete Group'
        cancelButtonText='No'
      >
        Are you sure you want to delete this custom group? This action cannot be undone.
      </MainModal>

      <CascadingMenuRoot
        anchorEl={props.anchor}
        setAnchorEl={anchor => setCloseRequested(!anchor)}
        items={timeBucketMenu}
      />
    </>
  );
};

export default connector(TimeBucketGrouperModal);
