import { MenuItem } from '@material-ui/core';
import { FC } from 'react';

export interface ActionBarDropdownItemProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const ActionBarDropdownItem: FC<ActionBarDropdownItemProps> = ({ label, onClick, disabled }) => (
  <MenuItem {...{ onClick, disabled }}>{label}</MenuItem>
);

export default ActionBarDropdownItem;
