import { DrawerFooter } from 'algo-react-dataviz';
import React, { FC, useContext, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  closeFolderDrawer,
  exportDrawerItem,
  setSelectedWorkspaceAsDefault,
} from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { openSelectedReportAsDetached, saveReport } from '../../redux/ReportActionCreators';
import { setOverwriteConfirmationModalStatus } from '../../redux/ui/actionCreators';
import { OverwriteConfirmationStatus } from '../../redux/ui/overwriteConfirmationStatus';
import {
  addSelectedReportToWorkspace,
  appendSelectedWorkspace,
  fetchSelectedWorkspace,
  importReportsIntoSelectedFolder,
  importWorkspacesIntoSelectedFolder,
  replaceReportInWorkspace,
  saveWorkspace,
} from '../../redux/WorkspaceActionCreators';
import { DrawerType, NodeType } from '../../shared/constants';
import { ImportToFolderData, Node } from '../../shared/dataTypes';
import { searchDataForPath } from '../../shared/utils';
import { SaveAsNameContext } from './FolderDrawer';
import ImportConflictModal from './ImportConflictModal';
import OverwriteConfirmationModal from './OverwriteConfirmationModal';

interface BaseProps {
  type: DrawerType;
  data: Node<string>;
}

const mapStateToProps = (state: AppState) => ({ folderDrawer: state.drawers.folderDrawer });

const mapDispatchToProps = {
  closeFolderDrawer,
  addSelectedReportToWorkspace,
  replaceReportInWorkspace,
  openSelectedReportAsDetached,
  importReportsIntoSelectedFolder,
  importWorkspacesIntoSelectedFolder,
  fetchSelectedWorkspace,
  saveReport,
  saveWorkspace,
  appendSelectedWorkspace,
  setOverwriteConfirmationModalStatus,
  exportDrawerItem,
  setSelectedWorkspaceAsDefault,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const FolderDrawerFooter: FC<Props> = props => {
  const [showImportConflictModal, setShowImportConflictModal] = useState(false);
  const [importData, setImportData] = useState<ImportToFolderData>();
  const saveAsName = encodeURIComponent(useContext(SaveAsNameContext).saveAsName);
  const onApplyClick = () => {
    switch (props.type) {
      case DrawerType.OPEN_REPORT_IN_WORKSPACE:
        props.addSelectedReportToWorkspace();
        break;

      case DrawerType.OPEN_REPORT_IN_DESIGNER:
        props.openSelectedReportAsDetached();
        break;

      case DrawerType.REPLACE_REPORT:
        props.replaceReportInWorkspace();
        break;

      case DrawerType.OPEN_WORKSPACE:
        props.fetchSelectedWorkspace();
        break;

      case DrawerType.SAVE_REPORT:
        const reportSavePath = `${props.folderDrawer.selectedFolderItem.props.path}/${saveAsName}`;
        if (searchDataForPath(props.data, reportSavePath)) {
          props.setOverwriteConfirmationModalStatus(OverwriteConfirmationStatus.SAVE_REPORT);
        } else {
          props.closeFolderDrawer();
          props.saveReport(reportSavePath, props.folderDrawer.sequenceId, 'post');
        }
        break;

      case DrawerType.SAVE_WORKSPACE:
        const workspaceSavePath = `${props.folderDrawer.selectedFolderItem.props.path}/${saveAsName}`;
        if (searchDataForPath(props.data, workspaceSavePath)) {
          props.setOverwriteConfirmationModalStatus(OverwriteConfirmationStatus.SAVE_WORKSPACE);
        } else {
          props.closeFolderDrawer();
          props.saveWorkspace(workspaceSavePath, 'post');
        }
        break;

      case DrawerType.APPEND_WORKSPACE:
        props.appendSelectedWorkspace();
        break;

      case DrawerType.IMPORT_REPORT_TO_FOLDER:
        props.importReportsIntoSelectedFolder(displayImportConflictModal, props.closeFolderDrawer);
        break;

      case DrawerType.IMPORT_WORKSPACE_TO_FOLDER:
        props.importWorkspacesIntoSelectedFolder(
          displayImportConflictModal,
          props.closeFolderDrawer,
        );
        break;

      case DrawerType.EXPORT_REPORTS:
      case DrawerType.EXPORT_WORKSPACES:
        props.exportDrawerItem();
        break;

      case DrawerType.SELECT_DEFAULT_WORKSPACE:
        props.setSelectedWorkspaceAsDefault();
        break;

      default:
        break;
    }

    // Don't toggle drawer for imports here. That will be controlled by the code which does the importing.
    [
      DrawerType.IMPORT_REPORT_TO_FOLDER,
      DrawerType.IMPORT_WORKSPACE_TO_FOLDER,
      DrawerType.SAVE_WORKSPACE,
      DrawerType.SAVE_REPORT,
      DrawerType.REPLACE_REPORT,
    ].includes(props.type) || props.closeFolderDrawer();
  };

  const displayImportConflictModal = (data: ImportToFolderData) => {
    setImportData(data);
    setShowImportConflictModal(true);
  };

  const allowApply = () => {
    const { applyButtonDisabled, selectedFolderItem: item } = props.folderDrawer;

    if (applyButtonDisabled || !item) {
      return false;
    }

    // Only importing drawers can target the root node
    if (
      ![DrawerType.IMPORT_REPORT_TO_FOLDER, DrawerType.IMPORT_WORKSPACE_TO_FOLDER].includes(
        props.type,
      ) &&
      item.name === '/'
    ) {
      return false;
    }

    // Report-opening drawers must target a report (NodeType.TEMPLATE)
    if (
      [DrawerType.OPEN_REPORT_IN_WORKSPACE, DrawerType.OPEN_REPORT_IN_DESIGNER].includes(
        props.type,
      ) &&
      ![NodeType.REPORT, NodeType.TEMPLATE].includes(item.type)
    ) {
      return false;
    }

    // Workspace-opening drawers and the default workspace drawer must target a workspace
    if (
      [
        DrawerType.OPEN_WORKSPACE,
        DrawerType.APPEND_WORKSPACE,
        DrawerType.SELECT_DEFAULT_WORKSPACE,
      ].includes(props.type) &&
      item.type !== NodeType.WORKSPACE
    ) {
      return false;
    }

    // Saving drawers must target a folder, & have a valid name
    if (
      [DrawerType.SAVE_REPORT, DrawerType.SAVE_WORKSPACE].includes(props.type) &&
      (item.type !== NodeType.FOLDER || !saveAsName?.trim())
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <OverwriteConfirmationModal />
      <DrawerFooter
        applyButtonText={getApplyLabel(props.type)}
        onApplyClick={onApplyClick}
        onCancelClick={props.closeFolderDrawer}
        applyDisabled={!allowApply()}
      />
      <ImportConflictModal
        open={showImportConflictModal}
        importTarget={props.folderDrawer.selectedFolderItem?.props.path}
        importType={props.type}
        headerText='Import Conflicts'
        onBackdropClick={() => setShowImportConflictModal(false)}
        importData={importData}
        onCloseRequest={props.closeFolderDrawer}
      />
    </>
  );
};

const getApplyLabel = (type: DrawerType) => {
  switch (type) {
    case DrawerType.OPEN_REPORT_IN_WORKSPACE:
    case DrawerType.OPEN_REPORT_IN_DESIGNER:
    case DrawerType.REPLACE_REPORT:
    case DrawerType.OPEN_WORKSPACE:
      return 'Open';
    case DrawerType.SAVE_REPORT:
    case DrawerType.SAVE_WORKSPACE:
      return 'Save';
    case DrawerType.APPEND_WORKSPACE:
      return 'Append';
    case DrawerType.IMPORT_REPORT_TO_FOLDER:
    case DrawerType.IMPORT_WORKSPACE_TO_FOLDER:
      return 'Import';
    case DrawerType.EXPORT_REPORTS:
    case DrawerType.EXPORT_WORKSPACES:
      return 'Export';
    case DrawerType.SELECT_DEFAULT_WORKSPACE:
      return 'Select';
  }
};

export default connector(FolderDrawerFooter);
