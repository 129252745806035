import { Typography } from '@material-ui/core';
import { DrawerFooter, DrawerHeader } from 'algo-react-dataviz';
import { FC, ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import { DateContext, SelectedCounterparty, SelectedEntities } from '../../../shared/dataTypes';
import UnfoldAccordion from '../../shared/UnfoldAccordion';
import ResizableDrawerWrapper from '../ResizableDrawerWrapper';
import Counterparty from './Counterparty';
import './entity-selection-drawer.scss';
import OwnEntity from './OwnEntity';

interface BaseProps {
  open: boolean;
  close: () => void;
  onApplyClick: (selectedEntities: SelectedEntities) => void;
  initialSelectedEntities?: SelectedEntities;
  extraButton?: ReactElement;
  dateContext?: DateContext;
}

const mapStateToProps = (state: AppState, props: BaseProps) => ({
  isDateContextValid:
    !!state.user.selectedDateContext || (props.dateContext && props.dateContext.type !== 'default'),
});
const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const EntitySelectionDrawer: FC<Props> = props => {
  const [selectedCounterparties, setSelectedCounterparties] = useState<SelectedCounterparty[]>([]);
  const [selectedOwnEntities, setSelectedOwnEntities] = useState<string[]>([]);

  // Populate/depopulate state on open/close
  const { open, initialSelectedEntities } = props;
  useEffect(() => {
    if (open && initialSelectedEntities) {
      setSelectedCounterparties(initialSelectedEntities.selectedCounterparties);
      setSelectedOwnEntities(initialSelectedEntities.selectedOwnEntities);
    } else {
      setSelectedCounterparties([]);
      setSelectedOwnEntities([]);
    }
  }, [open, initialSelectedEntities]);

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        open: props.open,
        anchor: 'right',
        className: 'right-drawer entity-selection-drawer',
        onClose: props.close,
      }}
      id='EntitySelectionDrawer'
    >
      <DrawerHeader headerText='Entity Selector' onXClick={props.close} />

      <div className='content'>
        {props.isDateContextValid ? (
          <>
            <UnfoldAccordion defaultExpanded summary='Counterparty'>
              <Counterparty
                selectedCounterparties={selectedCounterparties}
                setSelectedCounterparties={setSelectedCounterparties}
                dateContext={props.dateContext}
              />
            </UnfoldAccordion>
            <UnfoldAccordion defaultExpanded summary='Own Entity'>
              <OwnEntity
                selectedOwnEntities={selectedOwnEntities}
                setSelectedOwnEntities={setSelectedOwnEntities}
                dateContext={props.dateContext}
              />
            </UnfoldAccordion>
          </>
        ) : (
          <Typography className='no-date-context'>
            Please select a date context to show available entities.
          </Typography>
        )}
      </div>

      <DrawerFooter
        applyButtonText='Apply'
        onApplyClick={() => props.onApplyClick({ selectedCounterparties, selectedOwnEntities })}
        onCancelClick={props.close}
      >
        {props.extraButton}
      </DrawerFooter>
    </ResizableDrawerWrapper>
  );
};

export default connector(EntitySelectionDrawer);
