import { CircularProgress } from '@material-ui/core';
import { Component } from 'react';
import { DrawerType } from '../../shared/constants';
import { Node } from '../../shared/dataTypes';
import TreeFolderList from './TreeFolderList';

interface Props {
  data: { data: Node<string>; isLoading: boolean; errMess: string };
  type: DrawerType;
  loadData: () => void;
}

class FolderList extends Component<Props> {
  render() {
    if (this.props.data.errMess) {
      return <div style={{ flexGrow: 1, padding: '10px' }}>{this.props.data.errMess}</div>;
    } else {
      return this.props.data.isLoading ? (
        <div style={{ flexGrow: 1, marginTop: '10px', width: '100%', textAlign: 'center' }}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <TreeFolderList
          folderStructure={this.props.data?.data}
          caption='Folders'
          drawerType={this.props.type}
          menu={true}
          loadData={this.props.loadData}
        />
      );
    }
  }
}

export default FolderList;
