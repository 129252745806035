import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { FC, MouseEventHandler, ReactNode } from 'react';

export interface ControlledTextInputProps {
  label?: ReactNode;
  value: string | null;
  onClick: MouseEventHandler;
  onAdornmentClick?: MouseEventHandler;
  disabled?: boolean;
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
}

const ControlledTextInput: FC<ControlledTextInputProps> = ({
  label,
  value,
  onClick,
  onAdornmentClick,
  disabled,
  variant,
  size,
}) => (
  <TextField
    {...{
      label,
      value,
      onClick,
      disabled,
      variant,
      size,
    }}
    InputProps={{
      readOnly: true,
      endAdornment:
        !onAdornmentClick || value === null ? null : (
          <InputAdornment position='end'>
            <IconButton onClick={onAdornmentClick} style={{ padding: 4 }}>
              <ClearIcon fontSize='small' />
            </IconButton>
          </InputAdornment>
        ),
    }}
  />
);

export default ControlledTextInput;
