import * as ActionTypes from './ActionTypes';

export const sidePanel = (state = { open: false, reportDetails: null, positions: [] }, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_SIDE_PANEL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
