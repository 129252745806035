import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { FC } from 'react';

interface Props {
  value: number;
  onChange: (value: number) => void;
}

const options = [
  { label: 'Benchmark', value: 8 },
  { label: 'Benchmark 2', value: 4 },
  { label: 'Diff', value: 2 },
  { label: 'Diff 2', value: 1 },
];

const ChooserVisibility: FC<Props> = props => {
  return (
    <div className='chooser-visibility-container'>
      <Typography>Always Show in Chooser</Typography>
      {options.map(option => (
        <FormControlLabel
          key={option.value}
          label={option.label}
          control={
            <Checkbox
              color='primary'
              checked={!!(props.value & option.value)}
              onChange={e =>
                props.onChange(
                  e.target.checked ? props.value + option.value : props.value - option.value,
                )
              }
            />
          }
        />
      ))}
    </div>
  );
};

export default ChooserVisibility;
