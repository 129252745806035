import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import { FC, useState } from 'react';
import { Sandboxes } from '../../shared/dataTypes';
import { buildSandboxesFromTree } from '../../shared/utils';
import './sandbox.scss';

interface Props {
  isOpen?: boolean;
  onSubmit?: Function;
  sandboxes: Sandboxes;
  onClose?: any;
}

const RenameSandboxModal: FC<Props> = props => {
  const [name, setName] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setName(event.target.value);
  };
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    const sandboxesFromTree =
      props.sandboxes.public.children?.map(buildSandboxesFromTree).flat(Infinity) || [];
    if (!sandboxesFromTree.includes(name) && !props.sandboxes.private.includes(name)) {
      props.onSubmit(name);
      setName('');
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} className='rename-sandbox-modal'>
      <DialogTitle>Rename Sandbox</DialogTitle>
      <DialogContent>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <TextField
            error={error}
            id='standard-basic'
            label='Sandbox Name'
            helperText={error ? 'Sandbox Exists' : ''}
            value={name}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <RoundedButton
          variant='contained'
          color='primary'
          disabled={!!error}
          onClick={handleSubmit}
        >
          Save
        </RoundedButton>
        <RoundedButton variant='outlined' color='primary' onClick={props.onClose}>
          Cancel
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

export default RenameSandboxModal;
