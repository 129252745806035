import { IconButton } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarOutlined from '@material-ui/icons/StarOutlined';
import { Modifier } from 'algo-react-dataviz';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import {
  updateFavoriteCharacteristic,
  updateFavoriteCustomGrouping,
} from '../../redux/UserProfileActionCreators';

const mapStateToProps = (state: AppState) => ({
  favoriteCharacteristics: state.user.userInfo.userPreferences?.favoriteCharacteristics,
  favoriteCustomGroupings: state.user.userInfo.userPreferences?.favoriteCustomGroupings,
});

const mapDispatchToProps = { updateFavoriteCharacteristic, updateFavoriteCustomGrouping };

interface BaseProps {
  hover: boolean;
  id: number;
  name?: string;
  modifier: Modifier;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

export const draggableIconStyles = { height: '14px', width: '15px' };

const FavouriteButton: FC<Props> = props => {
  const selected = props.name
    ? props.favoriteCustomGroupings?.includes(props.name)
    : props.favoriteCharacteristics?.some(
        c => c.charId === props.id && c.modifier === props.modifier,
      );

  const onClick = () => {
    props.name
      ? props.updateFavoriteCustomGrouping(props.name, !selected)
      : props.updateFavoriteCharacteristic(props.id, props.modifier, !selected);
  };

  return (
    <IconButton aria-label='Favorite' onClick={onClick} size='small'>
      {selected ? (
        <Star style={{ ...draggableIconStyles, color: '#0077c8' }} />
      ) : (
        <StarOutlined style={{ ...draggableIconStyles, color: props.hover ? '#555550' : '#fff' }} />
      )}
    </IconButton>
  );
};

export default connector(FavouriteButton);
