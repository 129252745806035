import { Button, IconButton, Paper, Typography } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import { FC } from 'react';
import './discrete-breakpoint.scss';
interface Props {
  breakpoints: string[];
  onAddBreakpoint: () => void;
  onChangeBreakpoint: (index: number, value: string) => void;
  onDeleteBreakpoint: (index: number) => void;
}

const DiscreteBreakpoints: FC<Props> = props => {
  const hasBreakpoints = () => props.breakpoints?.find(value => value.trim().length > 0);

  return (
    <Paper className='breakpoints-paper'>
      <Typography variant='h3' className='breakpoints-header'>
        {hasBreakpoints() ? 'Breakpoints' : 'No Breakpoints'}
      </Typography>
      <div className='breakpoints-list'>
        {props.breakpoints.map((breakpoint, index) => (
          <div key={index} className='breakpoints-list-item'>
            <input
              value={breakpoint}
              onChange={e => props.onChangeBreakpoint(index, e.target.value)}
            />
            <IconButton
              size='small'
              onClick={() => props.onDeleteBreakpoint(index)}
              disabled={false}
            >
              <RemoveCircleOutline fontSize='small' color='secondary' />
            </IconButton>
          </div>
        ))}
        <Button className='breakpoints-list-item' onClick={props.onAddBreakpoint}>
          Click to add breakpoint
        </Button>
      </div>
    </Paper>
  );
};
export default DiscreteBreakpoints;
