import { useCallback, useMemo } from 'react';
import { aScriptEditorLanguages, EditorLanguage } from '../editorLanguage';
import useEditorModel, { EditorModel } from '../useEditorModel';
import useLanguageValidation, { LanguageValidationApi } from '../useLanguageValidation';
import useKeyCompletion from './completion-providers/useKeyCompletion';

// export interface AScriptEditorStateProps {
// initialContents?: string;
// }

export interface AScriptEditorState {
  editorModel: EditorModel<EditorLanguage.ASCRIPT>;
  // initialContents: string;
  validation: LanguageValidationApi;
  reset: () => void;
}

const useAScriptState = (): AScriptEditorState => {
  const editorModel = useEditorModel(aScriptEditorLanguages);
  const isInitialized = editorModel.isInitialized();
  useKeyCompletion(editorModel.id, EditorLanguage.ASCRIPT);

  const validation = useLanguageValidation({
    editorModel,
    language: EditorLanguage.ASCRIPT,
  });

  const reset = useCallback(() => {
    if (isInitialized) {
      // editorModel.api.setValue(EditorLanguage.ASCRIPT, '');
      validation.resetValidation();
    }
  }, [isInitialized, validation]);

  return useMemo(
    () => ({
      editorModel,
      // initialContents,
      validation,
      reset,
    }),
    [editorModel, validation, reset],
  );
};

export default useAScriptState;
