import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { FullDateContextItem } from 'algo-react-dataviz';
import { FC, useContext, useState } from 'react';
import { useAppSelector } from '../../../redux/configureStore';
import { getDrawerDateContext } from './PositionDrawer';
import { PositionDrawerContext } from './reducer';

const PositionDrawerDatePicker: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { state, dispatch } = useContext(PositionDrawerContext);

  const reportDateContext = useAppSelector(
    reduxState =>
      reduxState.report.reportDefinition[reduxState.drawers.positionDrawer.sequenceId]?.dateContext,
  );

  const currentDateContext = useAppSelector(reduxState => getDrawerDateContext(reduxState, state));

  const isCurrentDate = (date: FullDateContextItem) =>
    date.date === currentDateContext.date &&
    ((!date.id && !currentDateContext.id) || date.id === currentDateContext.id);

  return (
    reportDateContext?.type !== 'default' &&
    reportDateContext?.dates.length > 1 && (
      <>
        <Tooltip title='Context/Date'>
          <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
            <CalendarToday />
          </IconButton>
        </Tooltip>

        <Menu open={!!anchorEl} {...{ anchorEl }} onClose={() => setAnchorEl(null)}>
          {reportDateContext.dates.map(f => {
            const dateId = `${f.date} ${f.id}`;

            return (
              <MenuItem
                value={dateId}
                key={dateId}
                selected={isCurrentDate(f)}
                onClick={() => {
                  dispatch({ type: 'changeDateContext', date: f });
                  setAnchorEl(null);
                }}
              >
                {dateId}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    )
  );
};

export default PositionDrawerDatePicker;
