import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../redux/ActionCreators';
import { NotificationLevel } from './constants';

export interface ClipboardApi {
  isAvailable: () => boolean;
  inProgress: boolean;
  copy: (text: string) => Promise<void>;
  paste: () => Promise<void>;
}

const DEFAULT_ON_PASTE = (_text: string) => {};
const useClipboard = (onPaste: (text: string) => void = DEFAULT_ON_PASTE): ClipboardApi => {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  // Clipboard functionality is available only in secure contexts
  const isAvailable = useCallback(() => !!navigator.clipboard, []);
  const onDisabledRequest = useCallback(() => {
    dispatch(
      enqueueSnackbar(NotificationLevel.WARN, 'The clipboard is not enabled in this context'),
    );
  }, [dispatch]);

  const copy = useCallback(
    async (text: string) => {
      if (!isAvailable()) {
        onDisabledRequest();
        return;
      }

      if (inProgress) {
        return;
      }

      setInProgress(true);
      navigator.clipboard
        .writeText(text)
        .then(() => {
          dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Copied successfully'));
        })
        .catch(() => {
          dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Unable to write to clipboard'));
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [dispatch, inProgress, isAvailable, onDisabledRequest],
  );

  const paste = useCallback(async () => {
    if (!isAvailable()) {
      onDisabledRequest();
      return;
    }

    if (inProgress) {
      return;
    }

    setInProgress(true);
    navigator.clipboard
      .readText()
      .then(onPaste)
      .catch(() => {
        dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Unable to read from clipboard'));
      })
      .finally(() => {
        setInProgress(false);
      });
  }, [dispatch, inProgress, isAvailable, onDisabledRequest, onPaste]);

  return useMemo(
    () => ({
      isAvailable,
      inProgress,
      copy,
      paste,
    }),
    [isAvailable, inProgress, copy, paste],
  );
};

export default useClipboard;
