import { TextField } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import { getTextFieldFormProps } from './characteristicDrawerFormHelpers';

interface Props {
  formProps: FormikProps<CharacteristicDrawerForm>;
}

const Description: FC<Props> = props => {
  const initialFormValues = useSelector(resolveInitialFormValues);

  return (
    <div className='description-container'>
      <TextField
        multiline
        rows={20}
        rowsMax={Infinity}
        label='Enter a description'
        className='full-width-container'
        {...getTextFieldFormProps(props.formProps, initialFormValues)('description')}
      />
    </div>
  );
};

export default Description;
