import { createObject } from '../shared/utils';

export enum EditorLanguage {
  ASCRIPT = 'aScript',
  FOLIA = 'folia',
  JAVA = 'java',
}

export const allEditorLanguages = [
  EditorLanguage.ASCRIPT,
  EditorLanguage.FOLIA,
  EditorLanguage.JAVA,
];

export const getLabel = (language: EditorLanguage) =>
  ({
    [EditorLanguage.ASCRIPT]: 'aScript',
    [EditorLanguage.FOLIA]: 'Folia',
    [EditorLanguage.JAVA]: 'Java',
  }[language]);

export type EditorContents<Language extends EditorLanguage = EditorLanguage> = {
  [language in Language]?: string;
};

export const createDefaultContents = <Language extends EditorLanguage = EditorLanguage>(
  languages: Language[] = allEditorLanguages as Language[],
): EditorContents<Language> =>
  createObject({
    items: languages,
    createValue: () => '',
  });

export type AScriptEditorLanguage = EditorLanguage.ASCRIPT | EditorLanguage.JAVA;
export const DEFAULT_ASCRIPT_EDITOR_LANGUAGE: AScriptEditorLanguage = EditorLanguage.ASCRIPT;
export const aScriptEditorLanguages: AScriptEditorLanguage[] = [
  EditorLanguage.ASCRIPT,
  EditorLanguage.JAVA,
];

export const createDefaultAScriptContents = (): EditorContents =>
  createDefaultContents(aScriptEditorLanguages);
