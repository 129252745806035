import { FC } from 'react';
import activePieIcon from '../assets/icons/active_pie_chart.svg';
import areaIcon from '../assets/icons/area_chart.svg';
import brushBarIcon from '../assets/icons/brush_bar_chart.svg';
import bulletIcon from '../assets/icons/bullet_chart.svg';
import composedIcon from '../assets/icons/composed_chart.svg';
import mixedBarIcon from '../assets/icons/mixed_bar_chart.svg';
import scatterIcon from '../assets/icons/scatter_plot_chart.svg';
import stackedBarIcon from '../assets/icons/stacked_bar_chart.svg';
import stackedLineIcon from '../assets/icons/stacked_line_chart-24px.svg';
import treeMapIcon from '../assets/icons/tree_map_chart.svg';
import BarChart from '@material-ui/icons/BarChart';
import PieChartOutlined from '@material-ui/icons/PieChartOutlined';
// import Public from '@material-ui/icons/Public';
import TableChartOutlined from '@material-ui/icons/TableChartOutlined';

interface Props {
  icon: string;
}

const iconProps = {
  style: { marginRight: '5px', marginLeft: '5px', fill: '#565656', width: '16px', height: '16px' },
};

const VisualizationIcon: FC<Props> = props => (
  <img
    src={props.icon}
    style={{ ...iconProps.style, width: '16px', fill: '#565656' }}
    alt='visualization'
  />
);

export const visualizationIcons = {
  TABLE: <TableChartOutlined {...iconProps} />,
  DX_TABLE: <TableChartOutlined {...iconProps} />,
  // MAP_CHART: <Public {...iconProps} />,
  BAR_CHART: <BarChart {...iconProps} />,
  STACKED_BAR_CHART: <VisualizationIcon icon={stackedBarIcon} />,
  MIXED_BAR_CHART: <VisualizationIcon icon={mixedBarIcon} />,
  BRUSH_BAR_CHART: <VisualizationIcon icon={brushBarIcon} />,
  STACKED_BRUSH_BAR_CHART: <VisualizationIcon icon={brushBarIcon} />,
  LINE_GRAPH: <VisualizationIcon icon={stackedLineIcon} />,
  PIE_CHART: <PieChartOutlined {...iconProps} />,
  AREA_CHART: <VisualizationIcon icon={areaIcon} />,
  ACTIVE_PIE_CHART: <VisualizationIcon icon={activePieIcon} />,
  TWO_LEVEL_PIE_CHART: <PieChartOutlined {...iconProps} />,
  TREE_MAP: <VisualizationIcon icon={treeMapIcon} />,
  SCATTER_CHART: <VisualizationIcon icon={scatterIcon} />,
  BUBBLE_CHART: <VisualizationIcon icon={scatterIcon} />,
  COMPOSED_CHART: <VisualizationIcon icon={composedIcon} />,
  RADIAL_BAR_CHART: <PieChartOutlined {...iconProps} />,
  BULLET_GRAPH: <VisualizationIcon icon={bulletIcon} />,
};
