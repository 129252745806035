import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import { FC } from 'react';

interface Props {
  reloadPortfolios: () => void;
  itemToRename: string;
  clearItem: () => void;
}

const RenameAdHocPortfolioDialog: FC<Props> = props => (
  <Dialog
    open={!!props.itemToRename}
    onClose={props.clearItem}
    className='ad-hoc-portfolio-drawer-dialog'
  >
    <DialogTitle>Rename Portfolio</DialogTitle>

    <DialogContent>
      <TextField fullWidth />
    </DialogContent>

    <DialogActions>
      <RoundedButton variant='outlined' autoFocus onClick={props.clearItem} color='primary'>
        Cancel
      </RoundedButton>
      <RoundedButton variant='contained' onClick={() => {}} color='primary'>
        OK
      </RoundedButton>
    </DialogActions>
  </Dialog>
);

export default RenameAdHocPortfolioDialog;
