import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { regenerateIfAuto, setNull, setSelectedFilterOptions } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { getFilter } from '../../../redux/report/selectors';
import CheckboxDropdown from '../../../shared/CheckboxDropdown';
import { ALL_CHECKBOX_SELECTION_ID, NULLS_CHECKBOX_SELECTION_ID } from '../../../shared/constants';
import {
  Characteristic,
  DataSourceItemForDx,
  FilterOptions,
  OptionDisplayMap,
} from '../../../shared/dataTypes';
import SetNotCheckbox from '../ui-elements/form/SetNotCheckbox';

interface Props {
  char: Characteristic;
  options: OptionDisplayMap[];
  setFilterOptions: (filterOptions: FilterOptions) => void;
}

const CheckboxFilter: FC<Props> = props => {
  const dispatch = useDispatch();

  const filter = useAppSelector(getFilter)(props.char.charId);

  const dataType = props.char.dataType;

  const { options } = props;
  const { selectedOptions } = filter;
  const dataSource = useMemo(() => {
    if (options) {
      const parent: DataSourceItemForDx[] = [
        { id: ALL_CHECKBOX_SELECTION_ID, name: 'Select All', expanded: true, parentId: '-root' },
      ];

      const children: DataSourceItemForDx[] = options.map(o => ({
        id: o.id,
        name: dataType === 'DATE' ? new Date(o.display).toLocaleDateString() : o.display,
        parentId: ALL_CHECKBOX_SELECTION_ID,
        selected: selectedOptions?.includes(o.id),
      }));

      const nulls: DataSourceItemForDx = {
        id: NULLS_CHECKBOX_SELECTION_ID,
        name: '(Nulls)',
        parentId: ALL_CHECKBOX_SELECTION_ID,
        selected: filter.null || false,
      };

      return parent.concat(children).concat(nulls);
    } else {
      return [];
    }
  }, [filter.null, dataType, selectedOptions, options]);

  return (
    <div
      className='checkbox-filter'
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px',
      }}
    >
      <CheckboxDropdown
        dataSource={dataSource}
        onItemSelectionChanged={e => {
          dispatch(
            setSelectedFilterOptions(
              props.char.charId,
              e.filter(id => id !== NULLS_CHECKBOX_SELECTION_ID),
            ),
          );
          dispatch(
            setNull(
              props.char.charId,
              e.some(id => id === NULLS_CHECKBOX_SELECTION_ID),
            ),
          );
          dispatch(regenerateIfAuto());
        }}
      />
      <div style={{ height: '100%', marginTop: '9px', display: 'flex', justifyContent: 'center' }}>
        <SetNotCheckbox charId={props.char.charId} setFilterOptions={props.setFilterOptions} />
      </div>
    </div>
  );
};

export default CheckboxFilter;
