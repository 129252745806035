import { LIGHT_BORDER, RoundedButton } from 'algo-react-dataviz';
import { FC, useCallback } from 'react';
import { Optional } from '../../../shared/dataTypes';

export interface ButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  primaryButtons: ButtonProps[];
  cancelButton: Optional<ButtonProps, 'label'>;
}

// TODO: This is a sloppy copy/paste re-implementation of the
// ARC component to allow for more primary buttons.
const DrawerFooter: FC<Props> = ({ primaryButtons, cancelButton, children }) => {
  const createButton = useCallback(
    ({ label, onClick, disabled }: ButtonProps) => (
      <RoundedButton key={label} variant='contained' color='primary' {...{ onClick, disabled }}>
        {label}
      </RoundedButton>
    ),
    [],
  );

  return (
    <div
      style={{
        height: '48px',
        minHeight: '48px',
        borderTop: LIGHT_BORDER,
        backgroundColor: '#fff',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <div style={{ marginRight: '1em' }}>{children}</div>
      <RoundedButton
        onClick={cancelButton.onClick}
        variant='outlined'
        color='primary'
        disabled={cancelButton.disabled}
      >
        {cancelButton.label ?? 'Cancel'}
      </RoundedButton>

      {primaryButtons.map(createButton)}
    </div>
  );
};

export default DrawerFooter;
