import { Tooltip } from '@material-ui/core';
import { SingleDateRunPicker } from 'algo-react-dataviz';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDateContexts } from '../../redux/ActionCreators';
import { isAwa } from '../../shared/utils';
import { getRunExists } from './selectors';
import useDateContexts from './useDateContexts';
import useWorkspaceDateContext from './useWorkspaceDateContext';

interface Props {
  hideLabel?: boolean;
}

const WorkspaceDatePicker: FC<Props> = props => {
  const { workspaceDateContext, updateDateContext } = useWorkspaceDateContext();
  const { dateContexts, defaultDate } = useDateContexts();
  const showRuns = useSelector(isAwa);
  const runExists = useSelector(getRunExists);
  const dispatch = useDispatch();
  const disabled =
    !Object.keys(dateContexts || {}).length && !Object.keys(workspaceDateContext || {}).length;
  return (
    <Tooltip title={disabled ? 'No date context available' : ''}>
      <div>
        <SingleDateRunPicker
          selectedDateContext={workspaceDateContext}
          onClick={() => dispatch(fetchDateContexts())}
          onApply={({ date, id }) => updateDateContext({ date, id })}
          hideLabel={props.hideLabel}
          {...{ dateContexts, defaultDate, showRuns, runExists, disabled }}
        />
      </div>
    </Tooltip>
  );
};

export default WorkspaceDatePicker;
