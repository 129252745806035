import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/configureStore';

const useDateContexts = () => {
  const dateContexts = useSelector(
    (state: AppState) => state.user.userInfo.serverConfigs?.dateContexts ?? null,
  );

  const defaultDate = useSelector(
    (state: AppState) => state.user.selectedDateContext?.date ?? null,
  );

  return useMemo(
    () => ({
      dateContexts,
      defaultDate,
    }),
    [dateContexts, defaultDate],
  );
};

export default useDateContexts;
