import { CircularProgress } from '@material-ui/core';
import Editor, { OnMount } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { EditorContents, EditorLanguage } from '../../lang/editorLanguage';
import { EditorModel } from '../../lang/useEditorModel';
import { LANGUAGES_WITH_VALIDATION, LanguageValidationApi } from '../../lang/useLanguageValidation';

interface Props<Language extends EditorLanguage = EditorLanguage> {
  editorModel: EditorModel<Language>;
  validation: { [L in Language]?: LanguageValidationApi };
  initialContents: EditorContents<Language>;
  currentLanguage: Language;

  hasEditPermission?: boolean;
  editorOptions?: editor.IStandaloneEditorConstructionOptions;

  showLoadingIndicator?: boolean;
  height?: string;
}

const CodeEditor = <Language extends EditorLanguage = EditorLanguage>({
  editorModel,
  validation,
  initialContents,
  currentLanguage,
  hasEditPermission = true,
  editorOptions = {},
  showLoadingIndicator = true,
  height,
}: Props<Language>): JSX.Element => {
  const handleEditorDidMount: OnMount = (_editor, monaco) => {
    editorModel.initialize(monaco, initialContents);
  };

  return (
    <Editor
      {...{ height }}
      loading={showLoadingIndicator ? <CircularProgress size='5rem' /> : null}
      language={currentLanguage}
      path={editorModel.getPath(currentLanguage)}
      onMount={handleEditorDidMount}
      onChange={value => {
        if (LANGUAGES_WITH_VALIDATION.includes(currentLanguage)) {
          validation[currentLanguage].onChange(value);
        }
      }}
      keepCurrentModel
      options={{
        readOnly: !hasEditPermission,

        cursorBlinking: 'phase',
        wordWrap: 'off',

        minimap: {
          enabled: false,
        },

        wordBasedSuggestions: false,
        suggest: {
          preview: true,
        },

        ...editorOptions,
      }}
    />
  );
};

export default CodeEditor;
