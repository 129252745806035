import { ReportTable } from 'algo-react-dataviz';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { updateMetadataSort } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { setSelectedChar } from '../../../redux/metadata/actions';
import {
  getMetadataSort,
  getSortCreator,
  hasMetadataReport,
} from '../../../redux/metadata/selectors';
import { METADATA_SEQUENCE_ID } from '../../../shared/constants';
import LoadingFallback from './LoadingFallback';

const mapStateToProps = (state: AppState) => ({
  reportRawData: state.report.reportData[METADATA_SEQUENCE_ID]?.raw,
  selectedChar: state.metadata.selectedChar,
  sort: getMetadataSort(state),
  createSort: getSortCreator(state),
  hasMetadataReport: hasMetadataReport(state),
});
const mapDispatchToProps = { setSelectedChar, updateMetadataSort };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const METADATA_TABLE_ID = 'metadata';
const MetadataTable: FC<Props> = props =>
  !props.hasMetadataReport ? (
    <LoadingFallback />
  ) : (
    <ReportTable
      charProp='chars'
      searchChars={['10000']}
      id={METADATA_TABLE_ID}
      reportProps={props.reportRawData}
      onSortColumn={columnId => props.updateMetadataSort(props.createSort(columnId))}
      onDatumClick={({ id }) => props.setSelectedChar(`${id}`)}
      currentSort={props.sort}
    />
  );

export default connector(MetadataTable);
