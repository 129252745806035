import { IconButton, InputAdornment, Popover, TextField, Typography } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import { Column, TreeList } from 'devextreme-react/tree-list';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Entities } from '../../../model/entities';
import { AppState } from '../../../redux/configureStore';
import { CounterpartyItem, DateContext, SelectedCounterparty } from '../../../shared/dataTypes';
import { getDateContextStringWithUnderscore, useDebounce } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';

type BaseProps = {
  selectedCounterparties: SelectedCounterparty[];
  setSelectedCounterparties: (selected: SelectedCounterparty[]) => void;
  dateContext?: DateContext;
};

const mapStateToProps = (state: AppState) => ({ userDateContext: state.user.selectedDateContext });
const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const Counterparty: FC<Props> = props => {
  const [searchResults, setSearchResults] = useState<CounterpartyItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { dateContext, userDateContext } = props;
  useEffect(() => {
    if (debouncedSearchTerm)
      axios
        .get<Entities>(`${baseUrl}api/entities`, {
          params: {
            dateType: dateContext?.type || 'default',
            dateContexts:
              !dateContext || dateContext.type === 'default'
                ? userDateContext.date
                : getDateContextStringWithUnderscore(dateContext),
            filter: debouncedSearchTerm,
          },
        })
        .then(response =>
          setSearchResults(
            Object.entries(response.data)
              .reduce<CounterpartyItem[]>((acc, cur) => {
                const parentId = cur[0] === 'N/A Link Parent' ? UNGROUPED_ENTITIES : cur[0];
                return acc.concat([{ id: parentId }, ...cur[1].map(e => ({ id: e, parentId }))]);
              }, [])
              .sort((a, b) => {
                // Put Ungrouped Entities at the bottom and alphabetize the rest
                if (a.id === UNGROUPED_ENTITIES) return 1;
                if (b.id === UNGROUPED_ENTITIES) return -1;
                else return a.id.localeCompare(b.id);
              }),
          ),
        );
  }, [debouncedSearchTerm, dateContext, userDateContext]);

  return (
    <div className='counterparty'>
      <TextField
        variant='outlined'
        size='small'
        fullWidth
        placeholder='Search for a counterparty'
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onClick={e => !anchorEl && setAnchorEl(e.currentTarget)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search style={{ color: '#999' }} />
            </InputAdornment>
          ),
        }}
      />

      <Popover
        className='counterparty-popover'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableAutoFocus
        disableEnforceFocus
      >
        {searchResults.length > 0 ? (
          <TreeList
            dataSource={searchResults}
            dataStructure='plain'
            keyExpr='id'
            parentIdExpr='parentId'
            selection={{ mode: 'multiple' }}
            autoExpandAll
            showColumnHeaders={false}
            selectedRowKeys={props.selectedCounterparties.map(s => s.id)}
            onSelectedRowKeysChange={(keys: string[]) =>
              props.setSelectedCounterparties(
                keys
                  .filter(k => k !== UNGROUPED_ENTITIES)
                  .map(k => ({ id: k, group: searchResults.some(s => s.id === k && !s.parentId) }))
                  // Hack that's necessary because this function gets called when dataSource changes and
                  // not just when the user clicks on a checkbox.
                  .concat(
                    props.selectedCounterparties.filter(
                      c => !searchResults.some(s => s.id === c.id),
                    ),
                  ),
              )
            }
          >
            <Column dataField='id' />
          </TreeList>
        ) : (
          <Typography className='no-matches'>No matches</Typography>
        )}
      </Popover>

      {props.selectedCounterparties.length === 0 && (
        <div className='none-selected'>
          <Typography className='item-text' variant='caption'>
            No counterparties selected.
          </Typography>
        </div>
      )}

      {props.selectedCounterparties.length > 0 && (
        <>
          <div className='selected'>
            <Typography>Selected</Typography>
            <RoundedButton color='primary' onClick={() => props.setSelectedCounterparties([])}>
              Clear All
            </RoundedButton>
          </div>

          {props.selectedCounterparties.map((selectedCounterparty, i) => (
            <div key={i} className='selected list-item'>
              <Typography variant='body2' className='item-text'>
                {selectedCounterparty.id}
              </Typography>
              <IconButton
                onClick={() =>
                  props.setSelectedCounterparties(
                    props.selectedCounterparties.filter(c => selectedCounterparty.id !== c.id),
                  )
                }
              >
                <Close color='primary' fontSize='small' />
              </IconButton>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const UNGROUPED_ENTITIES = 'Ungrouped Entities';

export default connector(Counterparty);
