import { FC } from 'react';
import { ValidationStatus } from '../../../shared/validation/model';
import { Circle as CircleIcon } from '../../mui-v5-icons';

interface Props {
  className?: string;
  status: ValidationStatus;
}

const ValidationIndicator: FC<Props> = ({ className = '', status }) => (
  <CircleIcon className={`validation-icon ${className} ${status}`} />
);

export default ValidationIndicator;
