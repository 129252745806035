import { CircularProgress, TextField } from '@material-ui/core';
import axios from 'axios';
import { FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { NotificationLevel } from '../../../shared/constants';
import { baseUrl } from '../../shared/environment';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import { getTextFieldFormProps } from './characteristicDrawerFormHelpers';
import CharacteristicDropdown from './CharacteristicDropdown';

enum SmoothingMethodology {
  QUANTILE = 'Quantile',
  TRIANGLE = 'Triangle',
  GAUSSIAN = 'Gaussian',
  HARRELL_DAVIS = 'Harrel Davis ',
  FLEXIBLE_KERNEL = 'Flexible Kernel ',
  USER_DEFINED = 'User Defined Weight ',
}

interface BaseProps {
  formProps: FormikProps<CharacteristicDrawerForm>;
}
const mapDispatchToProps = { enqueueSnackbar };

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const SmoothingMethodInput: FC<Props> = props => {
  const initialFormValues = useSelector(resolveInitialFormValues);
  const [userDefinedValues, setUserDefinedValues] = useState<string[]>();
  const { enqueueSnackbar } = props;
  useEffect(() => {
    axios
      .get<string[]>(`${baseUrl}api/userDefinedScenarioWeights`)
      .then(response =>
        setUserDefinedValues(response.data && response.data.length > 0 ? response.data : null),
      )
      .catch(error =>
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to load scenario data from server: ${error.message}`,
        ),
      );
  }, [enqueueSnackbar]);
  const smoothingMethodology = props.formProps.values.smoothingMethodology;
  return (
    <div>
      <CharacteristicDropdown
        id='smoothingMethodology'
        formProps={props.formProps}
        textFieldProps={{
          label: 'Smoothing Methodology',
          className: 'full-width-container',
          onChange: e => {
            // we want different default values for the same key numScenarios depending on smoothingmethodology
            switch (e.target.value) {
              case SmoothingMethodology.TRIANGLE:
              case SmoothingMethodology.GAUSSIAN:
                props.formProps.setFieldValue('numScenarios', 5, false);
                break;
              case SmoothingMethodology.FLEXIBLE_KERNEL:
                props.formProps.setFieldValue('numScenarios', 100, false);
                break;
            }
            props.formProps.setFieldValue('smoothingMethodology', e.target.value, false);
          },
        }}
      />
      {(smoothingMethodology === SmoothingMethodology.TRIANGLE ||
        smoothingMethodology === SmoothingMethodology.FLEXIBLE_KERNEL) && (
        <TextField
          label={SmoothingMethodology.TRIANGLE ? '+/- Scenarios' : 'Scenarios'}
          className='full-width-container'
          {...getTextFieldFormProps(props.formProps, initialFormValues)('numScenarios')}
          type='number'
        />
      )}
      {smoothingMethodology === SmoothingMethodology.GAUSSIAN && (
        <div className='side-by-side full-width-container'>
          <TextField
            label='+/- Scenarios'
            {...getTextFieldFormProps(props.formProps, initialFormValues)('numScenarios')}
            type='number'
          />
          <TextField
            label='Standard Deviation'
            {...getTextFieldFormProps(props.formProps, initialFormValues)('numStdDeviations')}
            type='number'
          />
        </div>
      )}

      {smoothingMethodology === SmoothingMethodology.USER_DEFINED && (
        <CharacteristicDropdown
          id='userDefinedWeight'
          formProps={props.formProps}
          items={userDefinedValues?.reduce((arr, item) => [...arr, { value: item, key: item }], [])}
          textFieldProps={{
            label: userDefinedValues === null ? 'None Available' : '',
            className: 'full-width-container',
            disabled: !userDefinedValues,
            InputProps: {
              endAdornment:
                userDefinedValues === undefined ? (
                  <CircularProgress style={{ marginRight: '10px', transform: 'scale(0.3)' }} />
                ) : null,
            },
          }}
        />
      )}
    </div>
  );
};

export default connector(SmoothingMethodInput);
