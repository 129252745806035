import { CircularProgress } from '@material-ui/core';
import { DrawerHeader, RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppState, useAppSelector } from '../../../redux/configureStore';
import { closePositionDrawer } from '../../../redux/position-drawer/actions';
import {
  getDefaultContext,
  getPositionDrawerReportContext,
} from '../../../redux/position-drawer/selectors';
import { NotificationLevel } from '../../../shared/constants';
import { getDateContextString } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import ResizableDrawerWrapper from '../ResizableDrawerWrapper';
import AddInstruments from './AddInstruments';
import InstrumentsStepHeaderButtons from './InstrumentsStepHeaderButtons';
import './position-drawer.scss';
import PositionDrawerDatePicker from './PositionDrawerDatePicker';
import PositionDrawerFooter from './PositionDrawerFooter';
import {
  defaultState,
  PositionDrawerContext,
  PositionDrawerLocalState,
  PositionInstrument,
  reducer,
} from './reducer';
import SelectPortfolioOrBenchmark from './SelectPortfolioOrBenchmark';

type Step = 'portfolios' | 'instruments';

const PositionDrawer: FC = () => {
  const [step, setStep] = useState<Step>('portfolios');
  const [state, localDispatch] = useReducer(reducer, defaultState);
  const [positionContentsLoading, setPositionContentsLoading] = useState(false);

  const { date, id } = useAppSelector(reduxState => getDrawerDateContext(reduxState, state)) || {};

  const reportDateContextDates = useAppSelector(state =>
    getDateContextString(
      { type: 'asOf', dates: [getPositionDrawerReportContext(state) || { date: undefined }] },
      getDefaultContext(state),
    ),
  );

  const reportDateContextType = useAppSelector(
    state =>
      state.report.reportDefinition[state.drawers.positionDrawer.sequenceId]?.dateContext.type,
  );

  const { open, sandbox, mode, positionToEdit } = useAppSelector(
    state => state.drawers.positionDrawer,
  );

  const { name, type } = positionToEdit || {};

  const dispatch = useDispatch();

  const dispatchEnqueueSnackbar = useCallback(
    (type, message) => dispatch(enqueueSnackbar(type, message)),
    [dispatch],
  );

  // Fetch position data to edit
  const { path } = sandbox || {};
  useEffect(() => {
    if (open && mode === 'edit') {
      setPositionContentsLoading(true);
      localDispatch({
        type: 'setSelectedPortfolioOrBenchmark',
        selectedPortfolioOrBenchmark: {
          type: type === 'book' ? 'portfolio' : type,
          id: name,
        },
      });
      setStep('instruments');

      axios
        .get<(PositionInstrument & { instrumentId: string })[]>(`${baseUrl}api/positionContents`, {
          params: {
            id: name,
            dateType: reportDateContextType,
            dateContexts: reportDateContextDates,
            sandboxPath: path,
          },
        })
        .then(({ data }) =>
          localDispatch({
            type: 'setSelectedInstruments',
            instruments: data.map(d => ({ ...d, id: d.isFund ? d.id : d.instrumentId })),
          }),
        )
        .catch(e =>
          dispatchEnqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load position contents: ${e.message}`,
          ),
        )
        .finally(() => setPositionContentsLoading(false));
    }
  }, [
    open,
    mode,
    name,
    type,
    date,
    id,
    reportDateContextType,
    reportDateContextDates,
    path,
    dispatchEnqueueSnackbar,
  ]);

  // Clear state on drawer close
  useEffect(() => {
    if (!open) {
      localDispatch({ type: 'clearState' });
      setStep('portfolios');
    }
  }, [open]);

  const applyDisabled =
    step === 'portfolios'
      ? !state.selectedPortfolioOrBenchmark
      : state.selectedInstruments.some(
          s => !s.positionUnits && !s.fundUnits && !s.marketValue && !s.weight && !s.fundWeight,
        );

  return (
    <ResizableDrawerWrapper
      id='PositionDrawer'
      drawerProps={{ anchor: 'right', open, className: 'right-drawer position-drawer' }}
    >
      <PositionDrawerContext.Provider value={{ state, dispatch: localDispatch }}>
        <DrawerHeader
          headerText={`Add Positions to ${sandbox?.path}`}
          onXClick={() => dispatch(closePositionDrawer())}
        >
          {step === 'portfolios' ? (
            <PositionDrawerDatePicker />
          ) : (
            <InstrumentsStepHeaderButtons
              onDuplicateClick={() => {
                setStep('portfolios');
                localDispatch({
                  type: 'setSelectedPortfolioOrBenchmark',
                  selectedPortfolioOrBenchmark: undefined,
                });
              }}
            />
          )}
        </DrawerHeader>

        {open && (
          <div className='content-container'>
            {positionContentsLoading ? (
              <CircularProgress />
            ) : step === 'portfolios' ? (
              // The key is to reload portfolios and benchmarks when date context changes
              <SelectPortfolioOrBenchmark key={`${date} ${id}`} />
            ) : (
              <AddInstruments {...{ sandbox }} />
            )}
          </div>
        )}
      </PositionDrawerContext.Provider>

      <PositionDrawerFooter
        applyButtonText={step === 'portfolios' ? 'Next' : mode === 'edit' ? 'Save' : 'Add'}
        onDeleteClick={
          mode === 'edit' && step === 'instruments'
            ? () => localDispatch({ type: 'setSelectedInstruments', instruments: [] })
            : undefined
        }
        onCancelClick={() => dispatch(closePositionDrawer())}
        onBackClick={() => setStep('portfolios')}
        onApplyClick={() => {
          if (step === 'portfolios') {
            setStep('instruments');
          }
          if (step === 'instruments') {
            axios
              .post(`${baseUrl}api/addPositions`, {
                ...state,
                sandboxPath: sandbox.path,
                dateContext: { date, id },
                editMode: mode === 'edit',
              })
              .then(() =>
                dispatch(
                  enqueueSnackbar(NotificationLevel.SUCCESS, 'Position saved successfully.'),
                ),
              )
              .catch(() =>
                dispatch(
                  enqueueSnackbar(
                    NotificationLevel.ERROR,
                    'An error occurred when saving the position.',
                  ),
                ),
              );
            dispatch(closePositionDrawer());
          }
        }}
        backDisabled={step === 'portfolios'}
        {...{ applyDisabled }}
      >
        <RoundedButton>Click here</RoundedButton>
      </PositionDrawerFooter>
    </ResizableDrawerWrapper>
  );
};

export const getDrawerDateContext = (reduxState: AppState, localState: PositionDrawerLocalState) =>
  localState.dateContext || getPositionDrawerReportContext(reduxState);

export default PositionDrawer;
