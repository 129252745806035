import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFilterOptions, setNull, setSelectedFilterOptions } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { getFilter } from '../../../redux/report/selectors';
import {
  Characteristic,
  FilterOptions,
  FilterType,
  OptionDisplayMap,
} from '../../../shared/dataTypes';
import CheckboxFilter from './CheckboxFilter';
import EqualsFilter from './EqualsFilter';
import './filter-form.scss';
import MinMaxFilter from './MinMaxFilter';

interface Props {
  char: Characteristic;
}

const getFilterComponent = (filterType: FilterType) =>
  ({
    ['EQUALS' as FilterType]: EqualsFilter,
    ['LIST' as FilterType]: CheckboxFilter,
    ['MINMAX' as FilterType]: MinMaxFilter,
  }[filterType]);

const FilterForm: FC<Props> = props => {
  const dispatch = useDispatch();
  const filter = useAppSelector(getFilter)(props.char.charId);
  const [filterOptions, setFilterOptions] = useState<FilterOptions | null>(null);
  const { type } = filter || {};
  const FilterComponent = getFilterComponent(type);

  const { char } = props;
  const { charId } = char;
  const options: OptionDisplayMap[] = useMemo(
    () =>
      filterOptions?.[charId]?.map((o: any) => {
        // Reconstruct the time value from the armanta date so that it is in the local timezone.
        return o.id.Time
          ? {
              id: new Date(o.id.YYYY, o.id.MM - 1, o.id.DD).getTime(),
              display: new Date(o.id.YYYY, o.id.MM - 1, o.id.DD).getTime(),
            }
          : o;
      }),
    [filterOptions, charId],
  );

  useEffect(() => {
    type && dispatch(getFilterOptions(setFilterOptions));
  }, [dispatch, type]);

  return (
    <div className='filter-form'>
      {type ? (
        <FilterComponent
          char={props.char}
          options={options}
          setFilterOptions={filterOptions => {
            setFilterOptions(filterOptions);
            // If selectedOptions is undefined, that means the user has not yet
            // checked or unchecked anything. Everything should be checked by default.
            if (!filter.selectedOptions && filterOptions?.[props.char.charId]) {
              dispatch(
                setSelectedFilterOptions(
                  props.char.charId,
                  filterOptions[props.char.charId].map(value => value.id),
                ),
              );
            }
            if (type === 'LIST' && !filter.selectedOptions) {
              dispatch(setNull(props.char.charId, true));
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default FilterForm;
