import { Checkbox, Select, Switch, TextField, Typography } from '@material-ui/core';
import { FC, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Settings } from '../../../model/settings';
import { setSetting } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import MenuItems, { Item } from '../ui-elements/MenuItems';

interface BaseProps {
  name: string;
  id: keyof Settings;
  type: 'switch' | 'checkbox' | 'numeric' | 'dropdown';
  items?: Item[]; // for type === 'dropdown'
  children?: ReactNode;
  disabled?: boolean;
  customSettings?: Settings;
}
const mapStateToProps = (state: AppState, baseProps: BaseProps) => ({
  settings: baseProps.customSettings ?? state.reportDesigner.settings,
});
const mapDispatchToProps = { setSetting };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const Setting: FC<Props> = props => (
  <div>
    <div
      style={{
        height: '48px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography>{props.name}</Typography>
      {props.type === 'switch' && (
        <Switch
          color='primary'
          checked={Boolean(props.settings[props.id])}
          onChange={e => props.setSetting(props.id, e.target.checked)}
          disabled={props.disabled}
        />
      )}
      {props.type === 'checkbox' && (
        <Checkbox
          color='primary'
          checked={Boolean(props.settings[props.id])}
          onChange={e => props.setSetting(props.id, e.target.checked)}
          disabled={props.disabled}
        />
      )}
      {props.type === 'numeric' && (
        <TextField
          type='number'
          variant='outlined'
          InputProps={{ style: { height: '32px', width: '10ch' } }}
          inputProps={{ min: 0 }}
          value={props.settings[props.id]}
          onChange={e =>
            props.setSetting(props.id, e.target.value === '' ? '' : Number(e.target.value))
          }
          disabled={props.disabled}
        />
      )}
      {props.type === 'dropdown' && (
        <Select
          variant='outlined'
          value={props.settings[props.id] ?? ''}
          onChange={e => {
            props.setSetting(props.id, e.target.value as string | number);
          }}
          style={{ width: '35%', height: '40px', marginTop: '5px' }}
          disabled={props.disabled}
        >
          {MenuItems(props.items)}
        </Select>
      )}
    </div>

    {props.settings[props.id] ? props.children : <></>}
  </div>
);

export default connector(Setting);
