import { OidcMetadata, UserManagerSettings } from 'oidc-client';
import { parse } from 'uri-js';
import { toBool } from '../../shared/environment';

//////////////////////////////////////////////////////////////////////
// PLEASE ... when making changes to this file:                     //
//  - update backstage/src/main/env/.env.ui                         //
//  - update backstage/src/main/jib/usr/local/algo/stadium/.env.ui  //
//////////////////////////////////////////////////////////////////////

const orDefault = (val: string, def: string): string => {
  return null === val || undefined === val ? def : val;
};

const getProp = (s: string): string => {
  if (window as any) {
    if ((window as any)._env_) {
      if ((window as any)._env_[s]) {
        return (window as any)._env_[s];
      }
    }
  }
  return process.env[s];
};

const REACT_APP_OIDC_CLIENT_AUTHORITY = 'REACT_APP_OIDC_CLIENT_AUTHORITY';
const REACT_APP_OIDC_CLIENT_CLIENT_ID = 'REACT_APP_OIDC_CLIENT_CLIENT_ID';
const REACT_APP_OIDC_CLIENT_REDIRECT_URI = 'REACT_APP_OIDC_CLIENT_REDIRECT_URI';
const REACT_APP_OIDC_CLIENT_AUTOMATIC_SILENT_RENEW = 'REACT_APP_OIDC_CLIENT_AUTOMATIC_SILENT_RENEW';
const REACT_APP_OIDC_CLIENT_LOAD_USER_INFO = 'REACT_APP_OIDC_CLIENT_LOAD_USER_INFO';
const REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI = 'REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI';
const REACT_APP_OIDC_CLIENT_POST_LOGOUT_REDIRECT_URI =
  'REACT_APP_OIDC_CLIENT_POST_LOGOUT_REDIRECT_URI';
const REACT_APP_OIDC_CLIENT_RESPONSE_TYPE = 'REACT_APP_OIDC_CLIENT_RESPONSE_TYPE';
const REACT_APP_OIDC_CLIENT_SCOPE = 'REACT_APP_OIDC_CLIENT_SCOPE';

const REACT_APP_OIDC_CLIENT_META_ISSUER = 'REACT_APP_OIDC_CLIENT_META_ISSUER';
const REACT_APP_OIDC_CLIENT_META_JWKS_URI = 'REACT_APP_OIDC_CLIENT_META_JWKS_URI';
const REACT_APP_OIDC_CLIENT_META_AUTHORIZATION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_AUTHORIZATION_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_TOKEN_ENDPOINT = 'REACT_APP_OIDC_CLIENT_META_TOKEN_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_USERINFO_ENDPOINT = 'REACT_APP_OIDC_CLIENT_META_USERINFO_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_END_SESSION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_END_SESSION_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_CHECK_SESSION_IFRAME =
  'REACT_APP_OIDC_CLIENT_META_CHECK_SESSION_IFRAME';
const REACT_APP_OIDC_CLIENT_META_REVOCATION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_REVOCATION_ENDPOINT';
const REACT_APP_OIDC_CLIENT_META_INTROSPECTION_ENDPOINT =
  'REACT_APP_OIDC_CLIENT_META_INTROSPECTION_ENDPOINT';

const REACT_APP_OIDC_CLIENT_JWT_USER_ID = 'REACT_APP_OIDC_CLIENT_JWT_USER_ID';

const REACT_APP_OIDC_CLIENT_LOGOUT = 'REACT_APP_OIDC_CLIENT_LOGOUT';
const REACT_APP_OIDC_CLIENT_LOGOUT_CALLBACK = 'REACT_APP_OIDC_CLIENT_LOGOUT_CALLBACK';
const REACT_APP_OIDC_CLIENT_SILENT_RENEW = 'REACT_APP_OIDC_CLIENT_SILENT_RENEW';
const REACT_APP_OIDC_CLIENT_ROOT = 'REACT_APP_OIDC_CLIENT_ROOT';

const uriOrDefault = (str: string, dfault: string) => {
  try {
    return parse(getProp(str));
  } catch (err) {
    return parse(dfault);
  }
};

export const uriLoginCallback = uriOrDefault(REACT_APP_OIDC_CLIENT_REDIRECT_URI, '/signin-oidc');
export const uriLogout = uriOrDefault(REACT_APP_OIDC_CLIENT_LOGOUT, '/logout');
export const uriLogoutCallback = uriOrDefault(
  REACT_APP_OIDC_CLIENT_LOGOUT_CALLBACK,
  '/logout/callback',
);
export const uriSilentRenew = uriOrDefault(REACT_APP_OIDC_CLIENT_SILENT_RENEW, '/silentrenew');
export const uriRoot = uriOrDefault(REACT_APP_OIDC_CLIENT_ROOT, '/');

const IDENTITY_CONFIG: UserManagerSettings = {
  authority: getProp(REACT_APP_OIDC_CLIENT_AUTHORITY),
  client_id: getProp(REACT_APP_OIDC_CLIENT_CLIENT_ID),
  redirect_uri: getProp(REACT_APP_OIDC_CLIENT_REDIRECT_URI),
  automaticSilentRenew: toBool(
    orDefault(getProp(REACT_APP_OIDC_CLIENT_AUTOMATIC_SILENT_RENEW), 'true'),
  ),
  loadUserInfo: toBool(orDefault(getProp(REACT_APP_OIDC_CLIENT_LOAD_USER_INFO), 'true')),
  silent_redirect_uri: getProp(REACT_APP_OIDC_CLIENT_SILENT_REDIRECT_URI),
  post_logout_redirect_uri: getProp(REACT_APP_OIDC_CLIENT_POST_LOGOUT_REDIRECT_URI),
  response_type: getProp(REACT_APP_OIDC_CLIENT_RESPONSE_TYPE),
  scope: getProp(REACT_APP_OIDC_CLIENT_SCOPE),
};

const _METADATA_OIDC: Partial<OidcMetadata> = {
  issuer: getProp(REACT_APP_OIDC_CLIENT_META_ISSUER),
  jwks_uri: getProp(REACT_APP_OIDC_CLIENT_META_JWKS_URI),
  authorization_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_AUTHORIZATION_ENDPOINT),
  token_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_TOKEN_ENDPOINT),
  userinfo_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_USERINFO_ENDPOINT),
  end_session_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_END_SESSION_ENDPOINT),
  check_session_iframe: getProp(REACT_APP_OIDC_CLIENT_META_CHECK_SESSION_IFRAME),
  revocation_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_REVOCATION_ENDPOINT),
  introspection_endpoint: getProp(REACT_APP_OIDC_CLIENT_META_INTROSPECTION_ENDPOINT),
};

let hasMetaDataCfg: boolean = false;
for (const [key, value] of Object.entries(_METADATA_OIDC)) {
  if (!(undefined === value || null == value) && null !== key) {
    hasMetaDataCfg = true;
    break;
  }
}

const METADATA_OIDC: Partial<OidcMetadata> = hasMetaDataCfg ? _METADATA_OIDC : null;

let ums: UserManagerSettings = null;
if (hasMetaDataCfg) {
  ums = {
    ...IDENTITY_CONFIG,
    metadata: {
      ...METADATA_OIDC,
    },
  };
} else {
  ums = {
    ...IDENTITY_CONFIG,
  };
}

export const JWT_USER_ID: string = getProp(REACT_APP_OIDC_CLIENT_JWT_USER_ID) || 'sub';
export const AUTH_CONFIG: UserManagerSettings = ums;
