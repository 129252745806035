import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Block, Refresh } from '@material-ui/icons';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import { cancelReportGeneration } from '../../redux/ReportActionCreators';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import {
  ParentToChildRelationship,
  ReportDefinition,
  Sandbox,
  UISlot,
} from '../../shared/dataTypes';
import { getDateContextDisplayString, lookupLatestPendingRequest } from '../../shared/utils';
import SandboxChip from '../sandboxes/SandboxChip';
import { ReportContext } from './reportContext';
import ReportMenu from './ReportMenu';

interface BaseProps {
  sequenceId: number;
  reportDefinition: ReportDefinition;
  sandbox?: Sandbox | null;
  detailList: boolean;
  drillThrough: boolean;
  context: ReportContext;
  regenerate: () => void;
  updateReport: (slotDetails: UISlot, changedValue: string) => void;
  handleCloseReport: () => void;
}

const mapDispatchToProps = {
  cancelReportGeneration,
};

const mapStateToProps = (state: AppState, props: BaseProps) => ({
  pendingRequests: state.report?.reportData?.[props.sequenceId]?.pendingRequests,
  parentDateContext:
    state.report?.reportDefinition[
      getRootParentSequenceId(props.sequenceId, state.workspace.parentToChildRelationship)
    ]?.dateContext,

  disconnected: state.report.disconnected,
});

const getRootParentSequenceId = (
  sequenceId: number,
  parentToChildRelationship: ParentToChildRelationship,
): number => {
  let rootParentSequenceId = sequenceId;
  let parentSeqId = findParentSequenceId(sequenceId, parentToChildRelationship);

  while (parentSeqId !== DESIGNER_SEQUENCE_ID) {
    rootParentSequenceId = parentSeqId;
    parentSeqId = findParentSequenceId(parentSeqId, parentToChildRelationship);
  }

  return rootParentSequenceId;
};

const findParentSequenceId = (
  childSequenceId: number,
  parentToChildRelationship: ParentToChildRelationship,
): number => {
  for (const [key, value] of Object.entries(parentToChildRelationship)) {
    if (value.includes(childSequenceId)) {
      return Number(key);
    }
  }

  return DESIGNER_SEQUENCE_ID;
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const TopUiSlotRow: FC<Props> = props => {
  const isDrillThroughInheritingCCY =
    props.drillThrough &&
    props.reportDefinition?.settings?.drillThroughInheritance &&
    props.reportDefinition?.settings?.reportingCurrency;

  const dateContext = props.drillThrough
    ? props.parentDateContext
    : props.reportDefinition?.dateContext;

  const [isCancellable, setIsCancellable] = useState(false);
  const pendingRequestStatus = lookupLatestPendingRequest(props.pendingRequests);

  useEffect(() => {
    if (pendingRequestStatus) {
      const timer = setTimeout(() => {
        setIsCancellable(true);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setIsCancellable(false);
    }
  }, [pendingRequestStatus]);

  return props.reportDefinition ? (
    <div
      className='margin-horiz'
      style={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem', alignItems: 'center' }}
    >
      <Tooltip title={getTooltipTitle(props.reportDefinition)}>
        <Typography variant='h2' style={{ marginLeft: '12px' }}>
          {props.detailList ? 'Detail list' : props.reportDefinition.reportTitle}
        </Typography>
      </Tooltip>

      {/* The tooltip is always centered on the element; this div squeezes the previous
      element so that the tooltip is centered on the actual text and not the empty space
      to the right. */}
      <div style={{ flex: 1 }} />

      {props.context === ReportContext.DESIGNER && props.sandbox?.path && (
        <SandboxChip sandbox={props.sandbox} sequenceId={props.sequenceId} {...{ dateContext }} />
      )}
      {props.reportDefinition.currency &&
        props.reportDefinition.currency !== 'default' &&
        !isDrillThroughInheritingCCY && (
          <Tooltip title={props.reportDefinition.currency} className='hide-in-pdf'>
            <div style={{ ...dotStyle, backgroundColor: '#9cdbd9' }} />
          </Tooltip>
        )}
      {dateContext && dateContext.type !== 'default' && (
        <Tooltip title={getDateContextDisplayString(dateContext)} className='hide-in-pdf'>
          <div style={{ ...dotStyle, backgroundColor: '#0097a9' }} />
        </Tooltip>
      )}
      {(props.reportDefinition.selectedPortfolioNodeIds ||
        props.reportDefinition.selectedBenchmarkPortfolioNames ||
        props.reportDefinition.selectedAdHocPortfolioNames) && (
        <Tooltip title='Portfolios Changed' className='hide-in-pdf'>
          <div style={{ ...dotStyle, backgroundColor: 'MediumSlateBlue' }} />
        </Tooltip>
      )}
      {props.reportDefinition.selectedEntities && (
        <Tooltip title='Entities Changed' className='hide-in-pdf'>
          <div style={{ ...dotStyle, backgroundColor: '#ee7b68' }} />
        </Tooltip>
      )}
      <IconButton
        className='hide-in-pdf'
        onClick={() =>
          pendingRequestStatus
            ? isCancellable && props.cancelReportGeneration(props.sequenceId)
            : props.regenerate()
        }
        style={{ height: '26px', width: '26px' }}
        size='small'
        title={pendingRequestStatus ? 'Cancel' : 'Refresh'}
        disabled={
          (pendingRequestStatus && !isCancellable) || (!pendingRequestStatus && props.disconnected)
        }
      >
        {pendingRequestStatus ? (
          isCancellable && <Block fontSize='small' />
        ) : (
          <Refresh fontSize='small' />
        )}
      </IconButton>
      {props.context === ReportContext.CARD && (
        <ReportMenu
          sequenceId={props.sequenceId}
          detailList={props.detailList}
          drillThrough={props.drillThrough}
          regenerate={props.regenerate}
          updateReport={props.updateReport}
          handleCloseReport={props.handleCloseReport}
        />
      )}
    </div>
  ) : null;
};

const dotStyle: CSSProperties = { height: '16px', width: '16px', borderRadius: '8px' };

const getTooltipTitle = (def: ReportDefinition) =>
  !def.path || def.path.startsWith('@@adhoc_reports@@') ? 'Adhoc' : def.path;

export default connector(TopUiSlotRow);
