import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import {
  fetchUserComponentPermission,
  getErrorMessage,
  setGlobalOfflineFlag,
} from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { isAdminOpen, isDesignerOpen } from '../../redux/ui/selectors';
import {
  fetchManageContextsPermissions,
  fetchUserInfo,
  loginNative,
  logout,
  restoreLogin,
} from '../../redux/UserProfileActionCreators';
import { clearWorkspace } from '../../redux/WorkspaceActionCreators';
import RemoteConsoleWindow from '../remote-console/RemoteConsoleWindow';
import useUrlDateContext from '../report-dates/useUrlDateContext';
import {
  baseUrl,
  devPass,
  devUser,
  idleCountdownInSeconds,
  idleTimeoutInMinutes,
  isOidcClientEnabled,
  isPortalEnabled,
  refreshPermissionsSeconds,
} from '../shared/environment';
import AboutDialog from './AboutDialog';
import ChangePasswordPrompt from './ChangePasswordPrompt';
import Header from './Header';
import Login from './Login';
import MainModal from './MainModal';
import NavRail from './NavRail/NavRail';
import Notifier from './Notifier';
import PageContent from './PageContent';
import useUrlCurrency from './useUrlCurrency';
import useWorkspaceLanding from './useWorkspaceLanding';

const mapStateToProps = (state: AppState) => ({
  userLoggedIn: state.user.loggedIn,
  sessionExpiredWarningToggle: state.user.sessionExpiredWarningToggle,
  headerOpen: isDesignerOpen(state) || isAdminOpen(state),
  userName: state.user.username,
});

const mapDispatchToProps = {
  clearWorkspace,
  setGlobalOfflineFlag,
  login: loginNative,
  logout,
  restoreLogin,
  fetchUserInfo,
};

interface MainProps {
  oidcLogout: () => void;
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & MainProps;

const MainComponent: React.FC<Props> = props => {
  const logoutTimer = useRef<NodeJS.Timeout | null>(null);
  const [timeLeft, setTimeLeft] = useState(idleCountdownInSeconds);
  const [logoutModalIsVisible, setLogoutModalIsVisible] = useState(false);
  const [changePasswordModalIsVisble, setChangePasswordModalIsVisble] = useState(false);
  const [idleModalIsVisible, setIdleModalIsVisible] = useState(false);
  const toggleLogoutModal = () => setLogoutModalIsVisible(!logoutModalIsVisible);
  const toggleChangePasswordModal = () =>
    setChangePasswordModalIsVisble(!changePasswordModalIsVisble);

  const dispatch = useDispatch();
  const { userLoggedIn } = props;
  useEffect(() => {
    if (userLoggedIn) {
      dispatch(fetchManageContextsPermissions());
      dispatch(fetchUserComponentPermission());
      const interval = setInterval(() => {
        dispatch(fetchUserComponentPermission());
      }, refreshPermissionsSeconds * 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, userLoggedIn]);

  const confirmLogout = () => {
    if (props.userLoggedIn) {
      props.clearWorkspace();
      props.logout();
      props.oidcLogout();
      setLogoutModalIsVisible(false);
      setIdleModalIsVisible(false);
      logoutTimer && clearTimeout(logoutTimer.current);
    }
  };

  const handleOnIdle = () => {
    if (props.userLoggedIn) {
      setTimeLeft(idleCountdownInSeconds);
      logoutTimer.current = setInterval(() => {
        setTimeLeft(timeLeft => {
          if (timeLeft <= 1) {
            confirmLogout();
            return idleCountdownInSeconds;
          } else {
            return timeLeft - 1;
          }
        });
      }, 1000);
      axios
        .put(`${baseUrl}api/userInactive`, {
          user: props.userName,
          idleTimeoutInMinutes,
        })
        .catch(error => console.error(`Failed to log userInactive: ${getErrorMessage(error)}`));
      setIdleModalIsVisible(true);
    }
  };

  // prompt warning to user about logout
  useIdleTimer({
    timeout: 1000 * 60 * idleTimeoutInMinutes,
    onIdle: handleOnIdle,
    debounce: 2500,
  });

  // logout user if still idle after countdown time
  useIdleTimer({
    timeout: 1000 * 60 * idleTimeoutInMinutes + idleCountdownInSeconds * 1000,
    onIdle: confirmLogout,
    debounce: 2500,
  });

  const { restoreLogin, fetchUserInfo } = props;
  useEffect(() => {
    if (isPortalEnabled) {
      fetchUserInfo(true, undefined, true);
    } else if (isOidcClientEnabled) {
      // the "SecuritySelector" prevents from getting here
      //   if the user is not logged in
    } else {
      restoreLogin();
    }
  }, [restoreLogin, fetchUserInfo]);

  const { login } = props;
  useEffect(() => devUser && devPass && login(devUser, devPass), [login]);

  useWorkspaceLanding();
  useUrlDateContext();
  useUrlCurrency();

  return (
    <>
      <Notifier />

      <MainModal
        open={idleModalIsVisible}
        onRequestClose={() => {
          confirmLogout();
        }}
        onRequestSubmit={() => {
          setIdleModalIsVisible(false);
          clearInterval(logoutTimer.current);

          axios
            .put(`${baseUrl}api/userInactiveCancel`, {
              user: props.userName,
            })
            .catch(error =>
              console.error(`Failed to log userInactiveCancel: ${getErrorMessage(error)}`),
            );
        }}
        headerText='No activity'
        confirmButtonText='Continue'
        cancelButtonText='Logout'
      >
        Are you still there? This session will expire in {timeLeft} seconds.
      </MainModal>

      <ChangePasswordPrompt
        open={changePasswordModalIsVisble}
        closeDialog={() => setChangePasswordModalIsVisble(false)}
        dialogTitle='Change Password'
      />

      <MainModal
        open={logoutModalIsVisible}
        onRequestClose={toggleLogoutModal}
        onRequestSubmit={confirmLogout}
        headerText='Logout'
      >
        Are you sure you want to logout?
      </MainModal>

      <Dialog open={props.sessionExpiredWarningToggle}>
        <DialogTitle id='alert-dialog-title'>Session Expired or Invalid</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Your session has expired or is invalid. You will be automatically logged out.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <RoundedButton onClick={confirmLogout} color='primary'>
            OK
          </RoundedButton>
        </DialogActions>
      </Dialog>

      {!props.userLoggedIn ? (
        <Login {...{ login }} />
      ) : (
        <>
          <Header
            handleChangePassword={toggleChangePasswordModal}
            handleLogout={toggleLogoutModal}
            isOpen={props.headerOpen}
            loggedIn
          />
          <NavRail />

          <PageContent />

          <AboutDialog />

          <RemoteConsoleWindow />
        </>
      )}
    </>
  );
};

export default connector(MainComponent);
