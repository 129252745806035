import { Typography } from '@material-ui/core';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isAdHoc } from '../../../model/custom-grouping/customGrouping';
import { loadAllChars } from '../../../redux/ActionCreators';
import { removeCustomGroupingFromReports } from '../../../redux/custom-grouping/actionCreators';
import { closeDrawer } from '../../../redux/custom-grouping/actions';
import MainModal from '../../main/MainModal';
import useCustomGroupingState from '../context/useCustomGroupingState';
import './custom-grouping-delete-modal.scss';

interface Props {
  open: boolean;
  closeModal: () => void;
}

const CustomGroupingDeleteModal: FC<Props> = ({ open, closeModal }) => {
  const dispatch = useDispatch();
  const {
    deleteCustomGrouping,
    allowDelete,
    hasModelController,
    grouping,
  } = useCustomGroupingState();

  const onConfirm = useCallback(async () => {
    if (isAdHoc(grouping)) {
      dispatch(closeDrawer());
      dispatch(removeCustomGroupingFromReports(grouping));
    } else {
      deleteCustomGrouping().then(isSuccessful => {
        if (isSuccessful) {
          dispatch(closeDrawer());
          dispatch(loadAllChars());
          dispatch(removeCustomGroupingFromReports(grouping));
        }
      });
    }
  }, [dispatch, deleteCustomGrouping, grouping]);

  return (
    <MainModal
      {...{ open }}
      headerText='Delete Custom Grouping'
      confirmButtonText='Delete'
      onRequestSubmit={onConfirm}
      onRequestClose={closeModal}
      confirmButtonDisabled={!hasModelController || !allowDelete()}
    >
      <div className='custom-grouping-delete-modal'>
        <Typography>
          {`Custom Grouping ${grouping?.name || ''} will be deleted. Do you want to continue?`}
        </Typography>
      </div>
    </MainModal>
  );
};

export default CustomGroupingDeleteModal;
