import { IconButton, Tooltip, useScrollTrigger } from '@material-ui/core';
import {
  BarChartRounded,
  DashboardRounded,
  PermDataSetting,
  SupervisorAccountRounded,
} from '@material-ui/icons';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar, generateMetadataReport } from '../../../redux/ActionCreators';
import { AppState, useAppSelector } from '../../../redux/configureStore';
import { openNewReport } from '../../../redux/ReportActionCreators';
import { openPage, promptCloseDesigner } from '../../../redux/ui/actionCreators';
import { ContentPage } from '../../../redux/ui/contentPage';
import { getActivePage } from '../../../redux/ui/selectors';
import { fetchManageContextsPermissions } from '../../../redux/UserProfileActionCreators';
import { removeReportFromWorkspace } from '../../../redux/WorkspaceActionCreators';
import { METADATA_SEQUENCE_ID } from '../../../shared/constants';
import CustomGroupingDrawer from '../../custom-grouping/drawer/CustomGroupingDrawer';
import FolderDrawer from '../../drawers/FolderDrawer';
import AscriptEditorModal from '../../editor/ascript/AscriptEditorModal';
import { defaultMetadataChars } from '../../metadata/characteristic-drawer/characteristicDrawerFormHelpers';
import CopyCharacteristicDrawer from '../../metadata/characteristic-drawer/CopyCharacteristicDrawer';
import EditCharacteristicDrawer from '../../metadata/characteristic-drawer/EditCharacteristicDrawer';
import ChangeWorkspaceModal from './ChangeWorkspaceModal';
import CloseDesignerModal from './CloseDesignerModal';
import './nav-rail.scss';

const mapDispatchToProps = {
  openNewReport,
  fetchManageContextsPermissions,
  promptCloseDesigner,
  openPage,
  generateMetadataReport,
  removeReportFromWorkspace,
  enqueueSnackbar,
};

const mapStateToProps = (state: AppState) => ({
  activePage: getActivePage(state),
  closeDesignerModalOpen: state.ui.closeDesignerModalOpen,
  currencies: state.user.userInfo.serverConfigs?.currencies,
  selectedMetadataViewerFields: state.user.userInfo.userPreferences?.selectedMetadataViewerFields,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const NavRail: React.FC<Props> = props => {
  const trigger = useScrollTrigger();

  const adminPermissions = useAppSelector(state => state.user.userComponentPermission.allowAdmin);
  const readMetadataPermission = useAppSelector(
    state => state.user.userComponentPermission.allowViewMetadata,
  );

  const openPage = (targetPage: ContentPage) => {
    if (targetPage === props.activePage) {
      return;
    }
    if (props.activePage === ContentPage.DESIGNER) {
      props.promptCloseDesigner(targetPage);
      return;
    } else if (props.activePage === ContentPage.METADATA) {
      props.removeReportFromWorkspace(METADATA_SEQUENCE_ID);
    }

    if (targetPage === ContentPage.DESIGNER) {
      props.openNewReport(false);
    }

    props.openPage(targetPage);
  };

  const getButtonColor = (page: ContentPage) =>
    props.activePage === page ? 'primary' : 'disabled';

  return (
    <>
      <div
        className='nav-rail-app-bar'
        // we want sidebar to be behind cancel modal
        // 112px is height of both topbars
        style={{
          // we check if confirmcancel is open because to ensure
          // sidebar doesn't scroll while it's open, a potential endcase
          top:
            trigger && props.activePage === ContentPage.WORKSPACE && !props.closeDesignerModalOpen
              ? '0px'
              : '56px',
        }}
        aria-label='Menu'
      >
        <div className='nav-rail-container'>
          <div className='nav-rail-spacer' />

          <Tooltip title='Workspace'>
            <IconButton
              className='icon-button'
              aria-label='Workspace'
              onClick={() => {
                openPage(ContentPage.WORKSPACE);
              }}
            >
              <DashboardRounded color={getButtonColor(ContentPage.WORKSPACE)} />
            </IconButton>
          </Tooltip>
          {/*
            if currencies isn't loaded and you navigate to the designer
            the app will error, thus the props.currencies check
            you can reproduce this by quickly entering designer as soon as you login
            */}
          {props.currencies && (
            <Tooltip title='Report Designer'>
              <IconButton
                className='icon-button'
                aria-label='Report Designer'
                onClick={() => {
                  openPage(ContentPage.DESIGNER);
                }}
              >
                <BarChartRounded color={getButtonColor(ContentPage.DESIGNER)} />
              </IconButton>
            </Tooltip>
          )}

          {adminPermissions && (
            <Tooltip title='Admin'>
              <IconButton
                className='icon-button'
                aria-label='Admin'
                onClick={() => {
                  openPage(ContentPage.ADMIN);
                }}
              >
                <SupervisorAccountRounded color={getButtonColor(ContentPage.ADMIN)} />
              </IconButton>
            </Tooltip>
          )}

          {readMetadataPermission && (
            <Tooltip title='Metadata'>
              <IconButton
                className='icon-button'
                aria-label='Metadata'
                onClick={() => {
                  if (props.activePage !== ContentPage.METADATA) {
                    props.generateMetadataReport(
                      props.selectedMetadataViewerFields || defaultMetadataChars,
                    );
                    openPage(ContentPage.METADATA);
                  }
                }}
              >
                <PermDataSetting color={getButtonColor(ContentPage.METADATA)} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>

      <ChangeWorkspaceModal />
      <CloseDesignerModal />
      <AscriptEditorModal />

      <FolderDrawer />
      <EditCharacteristicDrawer />
      <CopyCharacteristicDrawer />
      <CustomGroupingDrawer />
    </>
  );
};

export default connector(NavRail);
