import { FC, useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Characteristic } from '../../../shared/dataTypes';
import { AVAILABLE_CHARS } from './beautifulDndHelpers';
import CharacteristicDraggable from './CharacteristicDraggable';

const MAX_CHARS_IN_STATE = 300;
const SCROLL_INCREMENT = 200;

type Props = {
  chars: Characteristic[];
  searchTerm: string;
  isFavFilterActive: boolean;
  selectedClass: string;
};

const CharacteristicsMenu: FC<Props> = props => {
  // Reset array position whenever search/filter/class changes
  useEffect(() => {
    setArrayPos(0);
  }, [props.searchTerm, props.isFavFilterActive, props.selectedClass]);

  const [arrayPos, setArrayPos] = useState(0);

  const inStateChars = props.chars.filter(
    (_c, i) => i >= arrayPos && i < arrayPos + MAX_CHARS_IN_STATE,
  );

  const handleScrollAtTop = () => {
    if (arrayPos === 0) {
      // There are no more chars above to load. Do nothing.
    } else {
      // If inStateChars[0] does not exist, it means we are in an intermediate render that
      // will throw an error if we try to run the below code, and another render with
      // existing inStateChars[0] is about to happen.
      if (inStateChars[0]) {
        const selector = `[data-rbd-draggable-id='${inStateChars[0].draggableId}']`;

        window.setTimeout(() => {
          setArrayPos(Math.max(arrayPos - SCROLL_INCREMENT, 0));

          document.querySelector(selector).scrollIntoView();
          // Reset some UI elements that become slightly off as a result of the previous line
          document.getElementById('report-designer-header').scrollIntoView();
        }, 100);
      }
    }
  };

  const handleScrollAtBottom = () => {
    if (arrayPos + MAX_CHARS_IN_STATE >= props.chars.length) {
      // There are no more chars below to load. Do nothing.
    } else {
      const selector = `[data-rbd-draggable-id='${
        inStateChars[inStateChars.length - 1].draggableId
      }']`;

      window.setTimeout(() => {
        setArrayPos(Math.min(arrayPos + SCROLL_INCREMENT, props.chars.length - MAX_CHARS_IN_STATE));

        document.querySelector(selector).scrollIntoView(false);
      }, 100);
    }
  };

  return (
    <div
      style={{ flex: 1, overflow: 'auto' }}
      onScroll={e => {
        const el = e.target as HTMLElement;
        if (el.scrollTop === 0) handleScrollAtTop();
        else if (el.scrollTop + el.clientHeight === el.scrollHeight) handleScrollAtBottom();
      }}
    >
      <Droppable droppableId={AVAILABLE_CHARS} isDropDisabled={true}>
        {provided => (
          <div style={{ padding: '8px' }} {...provided.droppableProps} ref={provided.innerRef}>
            {inStateChars.map((char, index) => (
              <CharacteristicDraggable
                key={char.draggableId}
                char={char}
                index={arrayPos + index}
                menuMode={true}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default CharacteristicsMenu;
