import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { ValidationStatus } from '../../../shared/validation/model';
import ValidationIndicator from './ValidationIndicator';

interface Props {
  status: ValidationStatus;
}

const getLabel = (() => {
  const labels = {
    [ValidationStatus.PRISTINE]: 'Nothing defined',
    [ValidationStatus.WAITING]: 'Waiting on user',
    [ValidationStatus.IN_PROGRESS]: 'Waiting on server',
    [ValidationStatus.VALID]: 'Valid syntax',
    [ValidationStatus.INVALID]: 'Invalid syntax',
  };

  return (status: ValidationStatus) => labels[status];
})();

const ValidationLegendItem: FC<Props> = ({ status }) => (
  <div className='validation-legend-item'>
    <ValidationIndicator {...{ status }} />
    <Typography className='validation-legend-item-label'>{getLabel(status)}</Typography>
  </div>
);

export default ValidationLegendItem;
