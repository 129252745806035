import { Container, Typography } from '@material-ui/core';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import './home-page.scss';
import HomeSettings from './HomeSettings';
import OpenQuickActions from './open-quick-actions/OpenQuickActions';
import TransferQuickActions from './transfer-quick-actions/TransferQuickActions';

const mapStateToProps = (state: AppState) => ({
  firstName: state.user?.userInfo?.firstname,
});

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector>;

const HomePage: FC<Props> = props => (
  <div className='home-page'>
    <Container className='home-container' maxWidth='md'>
      <div style={{ width: 1, height: '1rem', flexShrink: 0 }} />

      <div className='home-header'>
        <Typography className='home-header-title' variant='h1' gutterBottom>
          Welcome, {props.firstName}!
        </Typography>
        <Typography className='home-header-caption'>What would you like to do today?</Typography>
      </div>

      <hr className='home-rule' />

      <div className='home-content'>
        <OpenQuickActions />
        <TransferQuickActions />
        <HomeSettings />
        {/* <RecentWorkspaces /> */}
      </div>
    </Container>
  </div>
);

export default connector(HomePage);
