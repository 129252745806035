import { Reducer } from 'redux';
import { MetadataAction, MetadataActionType } from './actions';
import { createDefaultState, MetadataState } from './state';

const reducer: Reducer<MetadataState, MetadataAction> = (state = createDefaultState(), action) => {
  switch (action.type) {
    case MetadataActionType.SET_SELECTED_CHAR: {
      return { ...state, selectedChar: action.payload };
    }

    case MetadataActionType.SET_CHAR_TO_COPY: {
      return { ...state, charToCopy: action.payload };
    }

    case MetadataActionType.SET_ENTITY_TYPES: {
      return { ...state, entityTypes: action.payload };
    }

    case MetadataActionType.SET_ACCORDION_EXPANDED: {
      return {
        ...state,
        accordionsExpanded: {
          ...state.accordionsExpanded,
          [action.payload.accordionId]: action.payload.expanded,
        },
      };
    }
    case MetadataActionType.SET_ACCORDIONS_EXPANDED: {
      return {
        ...state,
        accordionsExpanded: {
          ...state.accordionsExpanded,
          ...action.payload,
        },
      };
    }

    case MetadataActionType.SET_SANDBOX: {
      return {
        ...state,
        sandbox: action.payload,
      };
    }

    default: {
      return state;
    }

    /** TODO: WIP for SD-1893 */

    // case MetadataActionType.SET_COLUMN_VISIBILITY: {
    //   const { field, visible } = action.payload;

    //   return {
    //     ...state,
    //     columnVisibilities: {
    //       ...state.columnVisibilities,
    //       [field]: visible,
    //     },
    //   };
    // }

    // case MetadataActionType.RESET_COLUMN_VISIBILITIES: {
    //   return {
    //     ...state,
    //     columnVisibilities: createDefaultVisibilities(),
    //   };
    // }

    /** TODO: WIP for 1894 */

    // case MetadataActionType.ADD_FILTER: {
    //   return {
    //     ...state,
    //     filters: [...state.filters, action.payload],
    //   };
    // }

    // case MetadataActionType.CLEAR_FILTERS: {
    //   return {
    //     ...state,
    //     filters: [],
    //   };
    // }
  }
};

export default reducer;
