import { Tab, Tabs } from '@material-ui/core';
import { DrawerFooter, DrawerHeader } from 'algo-react-dataviz';
import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Flavor } from '../../../model/flavor';
import { AppState } from '../../../redux/configureStore';
import {
  updateReportDefinitionBenchmarks,
  updateReportDefinitionScenariosConfig,
  updateReportDefinitionSettings,
} from '../../../redux/ReportActionCreators';
import { Benchmark } from '../../../shared/dataTypes';
import { hasAnyFlavor, isAwa } from '../../../shared/utils';
import ResizableDrawerWrapper from '../../drawers/ResizableDrawerWrapper';
import BenchmarksPanel from '../benchmarks-panel/BenchmarksPanel';
import ScenariosPanel from '../scenarios-panel/ScenariosPanel';
import SettingsPanel from '../settings-panel/SettingsPanel';
import { getLabel, OptionsTab } from './optionsTab';

interface BaseProps {
  drawerOpen: boolean;
  closeDrawer: () => void;
}

const mapStateToProps = (state: AppState) => ({
  isAwa: isAwa(state),
  hasAnyFlavor: hasAnyFlavor(state),
  canApply: canApply(state),
});

const mapDispatchToProps = {
  updateReportDefinitionScenariosConfig,
  updateReportDefinitionSettings,
  updateReportDefinitionBenchmarks,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const canApply = (state: AppState) =>
  validateBenchmarkSettings(state.reportDesigner.benchmarks[0]) &&
  validateBenchmarkSettings(state.reportDesigner.benchmarks[1]);

const validateBenchmarkSettings = (benchmark: Benchmark) =>
  !(
    (benchmark.dataSetType === 'custom' && !benchmark.dataSetPortfolio) ||
    (benchmark.reportDateType === 'relative' && !benchmark.relativeDate) ||
    (benchmark.reportDateType === 'custom' && !benchmark.dateContextOption.date) ||
    (benchmark.sandboxType === 'setSandbox' && !benchmark.sandbox)
  );

const OptionsDrawer: FC<Props> = props => {
  const tabs = [
    ...(props.isAwa && props.hasAnyFlavor(Flavor.MR, Flavor.ALMLR, Flavor.FRTB)
      ? [OptionsTab.SCENARIOS]
      : []),
    OptionsTab.SETTINGS,
    OptionsTab.BENCHMARKS,
  ];

  const [tab, setTab] = useState(tabs[0]);

  const createTabComponent = (tab: OptionsTab) => (
    <Tab
      style={{
        minWidth: 'inherit',
      }}
      value={tab}
      key={tab}
      label={getLabel(tab)}
    />
  );

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: props.drawerOpen,
        onClose: props.closeDrawer,
        className: 'right-drawer',
      }}
      id='OptionsDrawer'
    >
      <DrawerHeader headerText='Report Options' onXClick={props.closeDrawer} />

      <Tabs
        value={tab}
        variant='fullWidth'
        onChange={(_e, newValue) => setTab(newValue as OptionsTab)}
        indicatorColor='primary'
      >
        {tabs.map(createTabComponent)}
      </Tabs>

      <ScenariosPanel hidden={tab !== OptionsTab.SCENARIOS} />
      <SettingsPanel hidden={tab !== OptionsTab.SETTINGS} />
      <BenchmarksPanel hidden={tab !== OptionsTab.BENCHMARKS} />

      <DrawerFooter
        applyButtonText='Apply'
        applyDisabled={!props.canApply}
        onApplyClick={() => {
          props.updateReportDefinitionScenariosConfig();
          props.updateReportDefinitionSettings();
          props.updateReportDefinitionBenchmarks();
          props.closeDrawer();
        }}
        onCancelClick={props.closeDrawer}
      />
    </ResizableDrawerWrapper>
  );
};

export default connector(OptionsDrawer);
