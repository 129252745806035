import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import { FC, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSave: (name: string) => void;
  title: string;
  placeholder?: string;
}

const FilePrompt: FC<Props> = props => {
  const [name, setName] = useState(props.placeholder ?? '');

  const { open, placeholder } = props;
  useEffect(() => {
    setName((open && placeholder) || '');
  }, [open, placeholder]);

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
        <TextField
          label='Name'
          autoFocus
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions style={{ width: '300px' }}>
        <RoundedButton onClick={props.handleClose} color='primary' variant='outlined'>
          Cancel
        </RoundedButton>
        <RoundedButton
          disabled={name.length === 0}
          type='submit'
          color='primary'
          variant='contained'
          onClick={() => props.handleSave(name)}
        >
          Save
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

export default FilePrompt;
