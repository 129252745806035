import { RadioGroup, Select, Slider, TextField, Tooltip, Typography } from '@material-ui/core';
import { LIGHT_BORDER } from 'algo-react-dataviz';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Flavor } from '../../../model/flavor';
import {
  DEFAULT_LIQUIDITY_HORIZON,
  DEFAULT_PERIOD,
  DEFAULT_RISK_FACTOR_CATEGORY,
  DEFAULT_RISK_FACTOR_SET,
  getLiquidityHorizonOptions,
  getPeriodOptions,
  getRiskFactorCategoryOptions,
  getRiskFactorSetOptions,
  ScenarioSet,
  SmoothingMethodology,
  TIMESTEP_ID_ALL,
  TIMESTEP_ID_DEFAULT,
  VarMethodology,
} from '../../../model/scenariosConfig';
import { enqueueSnackbar, setScenarioConfigProperty } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { getDisableCustomSettings } from '../../../redux/designer/panel/selectors';
import CheckboxDropdown from '../../../shared/CheckboxDropdown';
import {
  DESIGNER_SEQUENCE_ID,
  DRILLTHROUGH_SETTING_TOOLTIP,
  NotificationLevel,
} from '../../../shared/constants';
import { DataSourceItemForDx } from '../../../shared/dataTypes';
import RadioOption from '../../../shared/RadioOption';
import {
  getCustomGroupingValue,
  getDateContextStringWithUnderscore,
  hasAnyFlavor,
  hasFlavor,
  validateScenarioInputsMsg,
} from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import SettingsGroup from '../settings-panel/SettingGroup';
import MenuItems from '../ui-elements/MenuItems';
import ReportSettingSpinner from '../ui-elements/ReportSettingSpinner';
import './scenarios-panel.scss';
import ScenariosDropdown from './ScenariosDropdown';

const noUserDefinedSmoothingMethodologyMessage = 'none available';
const SELECT_ALL_TIMESTEP_ID = '-999999';
interface BaseProps {
  hidden: boolean;
}

const mapStateToProps = (state: AppState) => {
  const disableCustomSettings = getDisableCustomSettings(state);

  return {
    scenariosConfig: disableCustomSettings
      ? getCustomGroupingValue(state, DESIGNER_SEQUENCE_ID, 'scenariosConfig')
      : state.reportDesigner.scenariosConfig,
    dateContext: getCustomGroupingValue(state, DESIGNER_SEQUENCE_ID, 'dateContext'),
    defaultDateContext: state.user.selectedDateContext,
    hasFlavor: hasFlavor(state),
    hasAnyFlavor: hasAnyFlavor(state),
    disableCustomSettings: disableCustomSettings,
  };
};

const mapDispatchToProps = { setScenarioConfigProperty, enqueueSnackbar };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const ScenariosPanel: FC<Props> = ({
  hidden,
  dateContext,
  defaultDateContext,
  scenariosConfig,
  hasFlavor,
  hasAnyFlavor,
  setScenarioConfigProperty,
  disableCustomSettings,
}) => {
  const [sets, setSets] = useState<ScenarioSet[] | null>(null);
  const [varMethodologies, setVarMethodologies] = useState<VarMethodology[] | null>(null);
  const [userDefinedValues, setUserDefinedValues] = useState<string[] | null>(null);
  const contexts = getDateContextStringWithUnderscore(dateContext, defaultDateContext);

  const { type } = dateContext;
  useEffect(() => {
    if (!hidden) {
      // User has just made this panel visible. Load scenario data from server.

      if (contexts && sets === null)
        axios
          .get<ScenarioSet[]>(`${baseUrl}api/scenarioSets`, {
            params: { dateType: type, contexts: contexts },
          })
          .then(response => {
            setSets(response.data);
            const defaultScenarioSet = response.data.find(scenarioSet => scenarioSet.isDefault)?.id;
            if (scenariosConfig.sets.length === 0 && defaultScenarioSet) {
              setScenarioConfigProperty('sets', [defaultScenarioSet]);
            }
          })
          .catch((error: Error) =>
            enqueueSnackbar(
              NotificationLevel.ERROR,
              `Failed to load scenario data from server: ${error.message}`,
            ),
          );
    }
  }, [hidden, contexts, type, setScenarioConfigProperty, scenariosConfig.sets.length, sets]);

  useEffect(() => {
    if (!hidden && varMethodologies === null)
      axios
        .get<VarMethodology[]>(`${baseUrl}api/varMethodologies`)
        .then(response => setVarMethodologies(response.data))
        .catch((error: Error) =>
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load scenario data from server: ${error.message}`,
          ),
        );
  }, [varMethodologies, hidden]);

  useEffect(() => {
    if (!hidden && userDefinedValues === null)
      axios
        .get<string[]>(`${baseUrl}api/userDefinedScenarioWeights`)
        .then(response => {
          if (!response.data || response.data.length === 0) {
            setUserDefinedValues([noUserDefinedSmoothingMethodologyMessage]);
          } else {
            setUserDefinedValues(response.data);
          }
        })
        .catch((error: Error) => {
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load scenario data from server: ${error.message}`,
          );
        });
  }, [userDefinedValues, hidden]);

  const getConfidenceLevel = () =>
    // If 'Other' is selected, return the value of that text field. Else, radio option.
    scenariosConfig.confidenceLevelRadioOption === -1
      ? scenariosConfig.confidenceLevelOther
      : scenariosConfig.confidenceLevelRadioOption;

  const confidenceLevelNumValidateMsg = validateScenarioInputsMsg(getConfidenceLevel(), 0, 100);

  const smoothingMethodologyInputValidateMsg = validateScenarioInputsMsg(
    scenariosConfig.smoothingMethodologyInput,
    0,
    10000,
  );

  const flexibleKernelInputValidateMsg = validateScenarioInputsMsg(
    scenariosConfig.flexibleKernelInput,
    0,
    10000,
  );

  const standardDeviationInputValidateMsg = validateScenarioInputsMsg(
    scenariosConfig.stdDevInput,
    1,
    100,
  );

  const marginalVarInputValidateMsg = validateScenarioInputsMsg(
    scenariosConfig.marginalVar,
    -100,
    100,
  );

  const startingIndexValueValidateMsg = validateScenarioInputsMsg(
    scenariosConfig.startingIndex,
    0,
    10000,
  );

  const rangeValidateMsg = validateScenarioInputsMsg(scenariosConfig.range, 0, 10000);

  const windowIncrementValidateMsg = validateScenarioInputsMsg(
    scenariosConfig.windowIncrement,
    0,
    10000,
  );

  return (
    <div hidden={hidden} className='scenarios-panel'>
      <SettingsGroup hidden={!hasAnyFlavor(Flavor.MR, Flavor.ALMLR)}>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div>
            <Typography variant='h3'>Scenario Set</Typography>
            <CheckboxDropdown
              dataSource={getScenarioSetDataSource(sets, scenariosConfig.sets)}
              onItemSelectionChanged={(e: string[]) => {
                if (!disableCustomSettings) setScenarioConfigProperty('sets', e);
              }}
              textFieldProps={{ disabled: disableCustomSettings }}
            />
          </div>
        </Tooltip>

        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div>
            <Typography variant='h3' style={{ marginTop: '22px' }}>
              Scenario
            </Typography>
            <CheckboxDropdown
              dataSource={getScenariosDataSource(
                sets,
                scenariosConfig.sets,
                scenariosConfig.scenarios,
              )}
              onItemSelectionChanged={(e: string[]) => {
                if (!disableCustomSettings) setScenarioConfigProperty('scenarios', e);
              }}
              textFieldProps={{ disabled: disableCustomSettings }}
            />
          </div>
        </Tooltip>

        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div>
            <Typography variant='h3' style={{ marginTop: '22px' }}>
              Timestep
            </Typography>
            <CheckboxDropdown
              dataSource={getTimestepDataSource(
                sets,
                scenariosConfig.sets,
                scenariosConfig.timesteps,
              )}
              onItemSelectionChanged={(e: string[]) => {
                if (!disableCustomSettings)
                  setScenarioConfigProperty(
                    'timesteps',
                    e.filter(ts => ts !== SELECT_ALL_TIMESTEP_ID),
                  );
              }}
              textFieldProps={{ disabled: disableCustomSettings }}
            />
          </div>
        </Tooltip>
      </SettingsGroup>

      {/* FRTB-specific settings */}
      <SettingsGroup name='FRTB IMA Scenarios' hidden={!hasFlavor(Flavor.FRTB)}>
        <ScenariosDropdown
          name='Risk Factor Category'
          id='riskFactorCategory'
          items={getRiskFactorCategoryOptions()}
          defaultValue={DEFAULT_RISK_FACTOR_CATEGORY}
          scenariosConfig={scenariosConfig}
          disabled={disableCustomSettings}
        />

        <ScenariosDropdown
          name='Liquidity Horizon'
          id='liquidityHorizon'
          items={getLiquidityHorizonOptions()}
          defaultValue={DEFAULT_LIQUIDITY_HORIZON}
          scenariosConfig={scenariosConfig}
          disabled={disableCustomSettings}
        />

        <ScenariosDropdown
          name='Period'
          id='period'
          items={getPeriodOptions()}
          defaultValue={DEFAULT_PERIOD}
          scenariosConfig={scenariosConfig}
          disabled={disableCustomSettings}
        />

        <ScenariosDropdown
          name='Risk Factor Set'
          id='riskFactorSet'
          items={getRiskFactorSetOptions()}
          defaultValue={DEFAULT_RISK_FACTOR_SET}
          scenariosConfig={scenariosConfig}
          disabled={disableCustomSettings}
        />
      </SettingsGroup>

      <SettingsGroup name='VaR Methodology'>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <Select
            variant='outlined'
            value={varMethodologies?.length ? scenariosConfig.varMethodology : ''}
            onChange={(e: React.ChangeEvent<{ value: string }>) =>
              setScenarioConfigProperty('varMethodology', e.target.value)
            }
            disabled={disableCustomSettings}
            style={{
              width: '100%',
              height: '40px',
              marginTop: '5px',
            }}
          >
            {varMethodologies === null ? (
              <ReportSettingSpinner />
            ) : (
              MenuItems(
                varMethodologies.map(({ id, name }) => ({
                  value: id,
                  label: name,
                })),
              )
            )}
          </Select>
        </Tooltip>
      </SettingsGroup>

      <SettingsGroup name='Confidence Level'>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <RadioGroup
            aria-label='position'
            name='position'
            value={scenariosConfig.confidenceLevelRadioOption}
            onChange={event =>
              setScenarioConfigProperty('confidenceLevelRadioOption', Number(event.target.value))
            }
          >
            <RadioOption
              value={90}
              label='90%'
              style={{ marginBottom: 0 }}
              disabled={disableCustomSettings}
            />
            <RadioOption
              value={95}
              label='95%'
              style={{ marginBottom: 0 }}
              disabled={disableCustomSettings}
            />
            <RadioOption
              value={97.5}
              label='97.5%'
              style={{ marginBottom: 0 }}
              disabled={disableCustomSettings}
            />
            <RadioOption
              value={99}
              label='99%'
              style={{ marginBottom: 0 }}
              disabled={disableCustomSettings}
            />
            <RadioOption value={-1} label='Other' disabled={disableCustomSettings} />
            <div style={{ display: 'flex' }}>
              <TextField
                style={{ height: '40px' }}
                inputProps={{ min: 0, max: 100, step: 0.1 }}
                variant='outlined'
                type='number'
                size='small'
                defaultValue={scenariosConfig.confidenceLevelOther}
                error={confidenceLevelNumValidateMsg !== null}
                label={confidenceLevelNumValidateMsg}
                onBlur={event =>
                  setScenarioConfigProperty('confidenceLevelOther', Number(event.target.value))
                }
                onSelect={() => setScenarioConfigProperty('confidenceLevelRadioOption', -1)}
                disabled={disableCustomSettings}
              />
              <Typography style={{ margin: '0.5em' }}>%</Typography>
            </div>
          </RadioGroup>
        </Tooltip>
      </SettingsGroup>

      <SettingsGroup name='Smoothing Methodology' style={{ borderBottom: 'none' }}>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <Select
            variant='outlined'
            value={scenariosConfig.smoothingMethodology}
            onChange={(e: React.ChangeEvent<{ value: SmoothingMethodology }>) =>
              setScenarioConfigProperty('smoothingMethodology', e.target.value)
            }
            style={{
              width: '100%',
              height: '40px',
              marginTop: '5px',
            }}
            disabled={disableCustomSettings}
          >
            {MenuItems([
              { value: SmoothingMethodology.QUANTILE, label: 'Quantile' },
              { value: SmoothingMethodology.TRIANGLE, label: 'Triangle' },
              { value: SmoothingMethodology.GAUSSIAN, label: 'Gaussian' },
              { value: SmoothingMethodology.HARREL_DAVIS, label: 'Harrell Davis' },
              { value: SmoothingMethodology.FLEXIBLE_KERNEL, label: 'Flexible Kernel' },
              { value: SmoothingMethodology.USER_DEFINED_WEIGHT, label: 'User Defined' },
            ])}
          </Select>
        </Tooltip>
      </SettingsGroup>

      <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            alignItems: 'center',
            gridAutoColumns: 'min-content',
            gap: '10px',
            borderBottom: LIGHT_BORDER,
            paddingBottom: [
              SmoothingMethodology.TRIANGLE,
              SmoothingMethodology.GAUSSIAN,
              SmoothingMethodology.FLEXIBLE_KERNEL,
              SmoothingMethodology.USER_DEFINED_WEIGHT,
            ].includes(scenariosConfig.smoothingMethodology)
              ? '22px'
              : 0,
          }}
        >
          {(scenariosConfig.smoothingMethodology === SmoothingMethodology.TRIANGLE ||
            scenariosConfig.smoothingMethodology === SmoothingMethodology.GAUSSIAN) && (
            <>
              <Typography style={{ whiteSpace: 'nowrap' }}>+ / -</Typography>
              <TextField
                inputProps={{ min: 0, max: 10000, step: 1 }}
                style={{ height: '40px', width: '60px' }}
                variant='outlined'
                type='number'
                size='small'
                error={smoothingMethodologyInputValidateMsg !== null}
                label={smoothingMethodologyInputValidateMsg}
                defaultValue={scenariosConfig.smoothingMethodologyInput}
                onBlur={event =>
                  setScenarioConfigProperty(
                    'smoothingMethodologyInput',
                    parseInt(event.target.value),
                  )
                }
                disabled={disableCustomSettings}
              />
              <Typography style={{ paddingLeft: '3px' }}>
                scenarios
                {scenariosConfig.smoothingMethodology === SmoothingMethodology.GAUSSIAN && ' at'}
              </Typography>
            </>
          )}

          {scenariosConfig.smoothingMethodology === SmoothingMethodology.GAUSSIAN && (
            <>
              <TextField
                style={{
                  width: standardDeviationInputValidateMsg ? '96px' : '86px',
                  height: '40px',
                }}
                inputProps={{ min: 1, max: 100, step: 1 }}
                variant='outlined'
                type='number'
                error={standardDeviationInputValidateMsg !== null}
                label={standardDeviationInputValidateMsg}
                size='small'
                defaultValue={scenariosConfig.stdDevInput}
                onBlur={event =>
                  setScenarioConfigProperty('stdDevInput', parseInt(event.target.value))
                }
                disabled={disableCustomSettings}
              />
              <Typography style={{ paddingLeft: '12px' }}>
                {scenariosConfig.smoothingMethodology === SmoothingMethodology.GAUSSIAN &&
                  '  standard deviation'}
              </Typography>
            </>
          )}

          {scenariosConfig.smoothingMethodology === SmoothingMethodology.FLEXIBLE_KERNEL && (
            <>
              <TextField
                style={{ width: '94px', height: '40px' }}
                inputProps={{ min: 0, max: 10000, step: 1 }}
                variant='outlined'
                size='small'
                type='number'
                error={flexibleKernelInputValidateMsg !== null}
                label={flexibleKernelInputValidateMsg}
                defaultValue={scenariosConfig.flexibleKernelInput}
                onBlur={event =>
                  setScenarioConfigProperty('flexibleKernelInput', parseInt(event.target.value))
                }
                disabled={disableCustomSettings}
              />
              <Typography style={{ paddingLeft: '6px' }}>
                {scenariosConfig.smoothingMethodology === SmoothingMethodology.FLEXIBLE_KERNEL &&
                  'scenarios'}
              </Typography>
            </>
          )}

          {scenariosConfig.smoothingMethodology === SmoothingMethodology.USER_DEFINED_WEIGHT && (
            <>
              <Select
                variant='outlined'
                style={{ minWidth: '220px', height: '40px' }}
                inputProps={{ width: 0 }}
                disabled={
                  disableCustomSettings ||
                  userDefinedValues === null ||
                  userDefinedValues[0] === noUserDefinedSmoothingMethodologyMessage
                }
                value={
                  userDefinedValues?.[0] === noUserDefinedSmoothingMethodologyMessage
                    ? noUserDefinedSmoothingMethodologyMessage
                    : scenariosConfig.userDefinedDropdownOption
                }
                onChange={(e: React.ChangeEvent<{ value: string }>) =>
                  setScenarioConfigProperty('userDefinedDropdownOption', e.target.value)
                }
              >
                {userDefinedValues === null ? (
                  <ReportSettingSpinner />
                ) : (
                  MenuItems(userDefinedValues)
                )}
              </Select>
            </>
          )}
        </div>
      </Tooltip>
      <SettingsGroup name='VaR Decomposition'>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <RadioGroup
            aria-label='position'
            name='position'
            value={scenariosConfig.varDecompRadioOption}
            onChange={event =>
              setScenarioConfigProperty(
                'varDecompRadioOption',
                event.target.value as 'root' | 'parent',
              )
            }
          >
            <RadioOption
              value='root'
              label='from Root Portfolio'
              disabled={disableCustomSettings}
            />
            <RadioOption
              value='parent'
              label='from Parent Portfolio'
              disabled={disableCustomSettings}
            />
          </RadioGroup>
        </Tooltip>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h3'>Marginal VaR</Typography>
            <TextField
              style={{ height: '40px', marginLeft: '10px' }}
              inputProps={{ min: 0, max: 100, step: 0.1 }}
              variant='outlined'
              type='number'
              size='small'
              defaultValue={scenariosConfig.marginalVar}
              error={marginalVarInputValidateMsg !== null}
              label={marginalVarInputValidateMsg}
              onBlur={e => setScenarioConfigProperty('marginalVar', Number(e.target.value))}
              disabled={disableCustomSettings}
            />
            <Typography style={{ padding: '5px' }}>%</Typography>
          </div>
        </Tooltip>
      </SettingsGroup>

      <SettingsGroup
        name='Stressed VaR'
        hidden={!hasAnyFlavor(Flavor.MR, Flavor.ALMLR)}
        style={{
          borderBottom: 'none',
        }}
      >
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div>
            <Typography variant='body2'>Starting Index</Typography>
            <TextField
              inputProps={{ min: 0, max: 10000, step: 1 }}
              style={{ height: '40px', width: '94px' }}
              variant='outlined'
              size='small'
              type='number'
              value={scenariosConfig.startingIndex}
              error={startingIndexValueValidateMsg !== null}
              label={startingIndexValueValidateMsg}
              onChange={e => setScenarioConfigProperty('startingIndex', Number(e.target.value))}
              disabled={disableCustomSettings}
            />

            <Slider
              style={{ marginLeft: '7px' }}
              max={10000}
              value={scenariosConfig.startingIndex}
              onChange={(_e: any, newValue: number) =>
                setScenarioConfigProperty('startingIndex', newValue)
              }
              aria-labelledby='input-slider'
              disabled={disableCustomSettings}
            />
          </div>
        </Tooltip>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div>
            <Typography variant='body2'>Range</Typography>
            <TextField
              inputProps={{ min: 0, max: 10000, step: 1 }}
              style={{ width: '94px', height: '40px' }}
              variant='outlined'
              size='small'
              type='number'
              value={scenariosConfig.range}
              error={rangeValidateMsg !== null}
              label={rangeValidateMsg}
              onChange={e => setScenarioConfigProperty('range', Number(e.target.value))}
              disabled={disableCustomSettings}
            />

            <Slider
              style={{ marginLeft: '7px' }}
              max={10000}
              value={scenariosConfig.range}
              onChange={(_e: any, newValue: number) => setScenarioConfigProperty('range', newValue)}
              aria-labelledby='input-slider'
              disabled={disableCustomSettings}
            />
          </div>
        </Tooltip>
        <Tooltip title={disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div>
            <Typography variant='body2'>Window Increment</Typography>
            <TextField
              error={windowIncrementValidateMsg !== null}
              label={windowIncrementValidateMsg}
              inputProps={{ min: 0, max: 10000, step: 1 }}
              type='number'
              style={{ width: '94px', height: '40px' }}
              variant='outlined'
              size='small'
              defaultValue={scenariosConfig.windowIncrement}
              onBlur={e => setScenarioConfigProperty('windowIncrement', Number(e.target.value))}
              disabled={disableCustomSettings}
            />
          </div>
        </Tooltip>
      </SettingsGroup>
    </div>
  );
};

const getScenarioSetDataSource = (
  sets: ScenarioSet[],
  selectedSets: string[],
): DataSourceItemForDx<string>[] => {
  if (sets === null) return null;
  if (sets.length === 0) return [];

  const dataSourceItems = sets.map(scenarioSet => {
    return {
      id: scenarioSet.id,
      name: scenarioSet.name,
      parentId: scenarioSet.isVirtual ? 'Virtual' : 'Standard',
      selected: selectedSets.includes(scenarioSet.id),
      expanded: true,
      hasItems: false,
    };
  });

  return [
    {
      id: 'Standard',
      name: 'Standard',
      parentId: '-root',
      hasItems: dataSourceItems.some(scenarioSet => scenarioSet.parentId === 'Standard'),
      expanded: true,
    },
    {
      id: 'Virtual',
      name: 'Virtual',
      parentId: '-root',
      hasItems: dataSourceItems.some(scenarioSet => scenarioSet.parentId === 'Virtual'),
    },
    ...dataSourceItems,
  ];
};

const getScenariosDataSource = (
  sets: ScenarioSet[],
  selectedSets: string[],
  selectedScenarios: string[],
): DataSourceItemForDx<string>[] =>
  sets === null
    ? null
    : selectedSets.length === 0
    ? []
    : [
        ...[
          {
            id: `${-1}`,
            name: 'All',
            parentId: '-root',
            selected: selectedScenarios.includes('-1'),
            hasItems: false,
          },
        ],
        ...sets
          .filter(set => selectedSets.includes(set.id))
          .flatMap(set =>
            set.scenarios
              .map(({ name, id }) => ({
                id: `${set.id}|${id}`,
                name,
                parentId: set.id,
                selected: selectedScenarios.includes(`${set.id}|${id}`),
                hasItems: false,
              }))
              .concat([
                {
                  id: set.id,
                  name: set.name,
                  parentId: '-root',
                  selected: selectedScenarios.includes(set.id),
                  hasItems: true,
                },
              ]),
          ),
      ];

const getTimestepDataSource = (
  sets: ScenarioSet[],
  selectedSets: string[],
  selectedTimesteps: string[],
): DataSourceItemForDx<string>[] =>
  sets === null
    ? null
    : selectedSets.length === 0
    ? []
    : sets
        .filter(set => selectedSets.includes(set.id))
        .flatMap(set =>
          set.timesteps.map(({ id, name }) => ({
            id,
            name,
            parentId:
              id === TIMESTEP_ID_DEFAULT || id === TIMESTEP_ID_ALL
                ? '-root'
                : SELECT_ALL_TIMESTEP_ID,
            expanded: undefined,
            selected: selectedTimesteps.includes(id),
            hasItems: false,
          })),
        )
        .filter((e1, i, a) => i === a.findIndex(e2 => e1.id === e2.id))
        .concat({
          id: SELECT_ALL_TIMESTEP_ID,
          name: 'Select All',
          parentId: '-root',
          expanded: true,
          selected: false,
          hasItems: true,
        });

export default connector(ScenariosPanel);
