import { DragIndicator } from '@material-ui/icons';
import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import contentCopyIcon from '../../assets/icons/content_copy-24px.svg';
import { AppState } from '../../redux/configureStore';
import { Sandbox } from '../../shared/dataTypes';
import { ReportContext } from './reportContext';
import StatusTags from './StatusTags';

interface BaseProps {
  context: ReportContext;
  detailList: boolean;
  drillThrough: boolean;
  sandbox: Sandbox;
  sequenceId: number;
}

const mapStateToProps = (state: AppState, props: BaseProps) => {
  const { errMessage, stackTrace } = state.report.reportData?.[props.sequenceId]?.raw || {};
  return { errMessage, stackTrace };
};

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const TopTagRow: FC<Props> = props => {
  const [successfulCopyToClipboardMessage, setSuccessfulCopyToClipboardMessage] = useState('');

  const copyErrorToClipboard = () => {
    let textToCopy = props.errMessage;
    if (props.stackTrace) textToCopy += `\n${props.stackTrace}`;

    navigator.clipboard.writeText(textToCopy);
    setSuccessfulCopyToClipboardMessage('Copied to clipboard');
    window.setTimeout(() => setSuccessfulCopyToClipboardMessage(''), 3000);
  };

  return (
    <div
      className='margin-horiz hide-in-pdf'
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        marginTop: '-0.5rem',
        marginBottom: '0.25rem',
      }}
    >
      <StatusTags
        detailList={props.detailList}
        drillThrough={props.drillThrough}
        sandbox={props.context === ReportContext.DESIGNER ? null : props.sandbox}
        sequenceId={props.sequenceId}
      />
      {props.context === ReportContext.CARD && <DragIndicator className='dragHandle hide-in-pdf' />}
      {props.errMessage ? (
        navigator.clipboard && (
          <div style={{ textAlign: 'right', marginRight: '2rem' }}>
            <button
              onClick={copyErrorToClipboard}
              style={{ background: 'none', border: 'none', padding: 0 }}
            >
              <img src={contentCopyIcon} alt='Copy to clipboard' />
            </button>
            <div>{successfulCopyToClipboardMessage}</div>
          </div>
        )
      ) : (
        <div />
      )}
    </div>
  );
};

export default connector(TopTagRow);
