import { TextField } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import {
  charOptionsToLabels,
  getTextFieldFormProps,
  isValueInDropdown,
} from './characteristicDrawerFormHelpers';
import CharacteristicDropdown from './CharacteristicDropdown';

interface Props {
  formProps: FormikProps<CharacteristicDrawerForm>;
}
// not using dropdown other component because the server wants two states for this
const AggregationInput: FC<Props> = props => {
  const initialFormValues = useSelector(resolveInitialFormValues);

  const reportAggregationTypeProps = getTextFieldFormProps(
    props.formProps,
    initialFormValues,
  )('reportAggregationType');
  const reportAggregatorProps = getTextFieldFormProps(
    props.formProps,
    initialFormValues,
  )('reportAggregator');
  const valueIsInDropdown = isValueInDropdown(
    'reportAggregationType',
    reportAggregationTypeProps.value,
  );

  return (
    <div className='aggregation-input'>
      <CharacteristicDropdown
        id={'reportAggregationType'}
        formProps={props.formProps}
        items={[...charOptionsToLabels['reportAggregationType'], { value: 'Other', key: 'Other' }]}
        textFieldProps={{
          onChange: e => {
            if (e.target.value === 'Other') {
              props.formProps.setFieldValue('reportAggregationType', undefined, false);
              props.formProps.setFieldValue('reportAggregator', '', false);
            } else {
              // the backend must have one key or the other, thus we set to undefined
              props.formProps.setFieldValue('reportAggregator', undefined, false);
              reportAggregationTypeProps.onChange(e);
            }
          },
          value: valueIsInDropdown ? `${reportAggregationTypeProps.value}` : 'Other',
          label: 'Report Aggregation Type',
        }}
      />
      <TextField
        label={'Report Aggregation'}
        {...reportAggregatorProps}
        onChange={e => {
          // the backend must have one key or the other, thus we set to undefined
          props.formProps.setFieldValue('reportAggregationType', undefined, false);
          reportAggregatorProps.onChange(e);
        }}
      />
    </div>
  );
};

export default AggregationInput;
