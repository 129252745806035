import { TextFieldProps } from '@material-ui/core';
import { TreeDropdown } from 'algo-react-dataviz';
import { FC } from 'react';
import { DataSourceItemForDx } from './dataTypes';

interface Props {
  dataSource: DataSourceItemForDx[];
  onItemSelectionChanged: (items: (string | number)[]) => void;
  textFieldProps?: TextFieldProps;
}

const CheckboxDropdown: FC<Props> = props => (
  <TreeDropdown
    dataSource={props.dataSource}
    onItemSelectionChanged={props.onItemSelectionChanged}
    textFieldProps={props.textFieldProps}
  />
);

export default CheckboxDropdown;
