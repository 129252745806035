import { Typography } from '@material-ui/core';

export default function AscriptHelpRight() {
  return (
    <div className='ascript-help-right'>
      <title />
      <h3>
        <div id='Overview'>
          <b>Writing aScript</b>
        </div>
      </h3>
      <p>
        The SS&amp;C Workspace Analyzer scripting syntax is both simple and powerful. &nbsp;It
        allows you to declare and use variables, perform loops, and execute conditionals. aScript
        can be used to generate value computers, aggregators and cell computers. Most sections of
        this help apply when writing any type of aScript though there are specific sections on
        aggregators and cell computers.
        <br />
        <br />
        <div id='Structure'>
          <b>Structure </b>
        </div>
        <b>
          <br />
          <br />
        </b>
        Scripts are collections of statements<b>. </b>A statement can either be a simple statement
        like an assignment&nbsp;or several statements together inside of curly braces. Each simple
        statement must be terminated with a semicolon. There is one exception to this: &nbsp;A
        return statement does not need to be terminated with a semicolon. &nbsp;In fact, there is a
        form of an if statement where the return <b>must</b> <b>not</b> have a semicolon. &nbsp;See
        the section on if statements for examples.
        <br />
        <br />
        <div id='Comments'>
          <b>Comments </b>
        </div>
        <b>
          <br />
          <br />
        </b>
        Comments are written using the # character. &nbsp;Examples&nbsp;are:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          &nbsp;#&nbsp;This is a comment line
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x = 2; # assign a value to x
        </i>
      </p>
      <p>
        <div id='Variable Declaration'>
          <b>Variable Declaration</b>
        </div>
        <b> </b>
      </p>
      <p>
        Variables are declared in a straight forward manner with the type followed by the name.
        Allowable types are <b>int</b>, <b>double</b>, <b>boolean</b> and <b>string</b>. Examples
        variable declarations are shown below:
      </p>
      <p style={{ marginLeft: 20 }}>
        <i>
          int x;
          <br />
          double y;
          <br />
          boolean b;
          <br />
          string s;
        </i>
      </p>
      <p style={{ marginLeft: 10 }}>
        It is also allowable to give an initial value as part of a variable declaration.
        <br />
        For example:
      </p>
      <p style={{ marginLeft: 20 }}>
        <i>
          int x = 1;
          <br />
          double y = 10.5;
          <br />
          boolean b = true;
          <br />
          string s = "Hello";
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='Variable Assignment'>
          <b>Variable Assignment</b>
        </div>
        <b> </b>
      </p>
      <p>
        Variables cannot be assigned during declaration. Assignment is done by specifying the
        variable to be assigned followed by an equals sign and an appropriate value. Examples are
        shown below using the variables declared above:
      </p>
      <p style={{ marginLeft: 20 }}>
        <i>
          x = 5;
          <br />y = 3.14;
          <br />b = false;
          <br />b = true;
          <br />s = "Hello";{' '}
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='IF Statements'>
          <b>If Statements</b>
        </div>
        <b> </b>
      </p>
      <p style={{ marginLeft: 0 }}>
        An if statement has the form:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          if(expression)
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>statement; </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        <i>O</i>ptionally, there can be an else in which case the form is
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          if(expression)
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          statement;
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          else
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>statement; </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        If statement examples:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          if(x &gt;&nbsp;100.)
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>result = -1; </i>
      </p>
      <p style={{ marginLeft: 0 }}>or</p>
      <p style={{ marginLeft: 20 }}>
        <i>
          if(x &gt;&nbsp;100.)
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          result = -1;
          <br />
          else
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>result = -2; </i>
      </p>
      <p style={{ marginLeft: 0 }}>or</p>
      <p style={{ marginLeft: 10 }}>
        <i>
          if(x &gt;&nbsp;100.)
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return 1<br />
          else
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return 2;
          <br />
          <br />
        </i>
        Note that in the above example, the first return statement does not terminate with a
        semicolon. &nbsp;This should be done<i> </i>in cases where the first block of code ends with
        a return statement.
      </p>
      <p style={{ marginLeft: 0 }}>or</p>
      <p style={{ marginLeft: 10 }}>
        <i>
          if(x &gt; 100.) {'{'}
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          result = 1;
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          return result
          <br />
          {'}'} else {'{'}
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          return 2;
          <br />
          {'}'}
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='Expressions'>
          <b>Expressions</b>
        </div>
        <b> </b>
      </p>
      <p style={{ marginLeft: 0 }}>
        In the above if statement examples, the expressions were literals. However, they can be more
        complex. They can relate two variables such as <i>if(x &gt;&nbsp;y)</i> and they can contain
        logical symbols (e.g. &amp;&amp;). The complete list of expression symbols is shown{' '}
        <a href='#expressions-extra'>here</a>
        .<br />
        <br />
        Use of these expressions is not limited to if statements. For example a return statement
        could simply be
        <i>return x &gt; y</i>.
      </p>
      <p style={{ marginLeft: 0 }}>
        <b>Looping </b>
      </p>
      <p style={{ marginLeft: 0 }}>
        Looping is accomplished using a <b>while </b>construct which takes the form:
      </p>
      <p style={{ marginLeft: 20 }}>
        <i>
          while( expression&nbsp;)
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;statement{' '}
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>An example is:</p>
      <p style={{ marginLeft: 20 }}>
        while(count &lt;= 10)
        <br />
        {'{'}
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;val = val * count;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;count = count + 1;
        <br />
        {'}'}
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='Accessing Entity Value'>
          <b>Accessing Values In An Entity</b>
        </div>
        <b> </b>
      </p>
      <p style={{ marginLeft: 0 }}>
        A very powerful feature of the scripting language is the ability to access values from an
        entity. &nbsp;For this you can use the <b>key</b> keyword. &nbsp;It takes the form:
      </p>
      <p style={{ marginLeft: 10 }}>
        <i>key[Column Name] </i>where Column Name is the name specified in the metadata editor when
        the column was created.
      </p>
      <p style={{ marginLeft: 0 }}>
        For instance, supposed you have a column named <i>Market Value </i>and you wish to use it in
        your calculation. You could access it via the following:
      </p>
      <p style={{ marginLeft: 10 }}>
        <i>
          double mktVal;
          <br />
          mkVal = key[Market Value];{' '}
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        When writing the script you can press the control-space key combination in the editor to
        popup a list of column names to choose from. If you have begun to type the name, the list
        will show only those columns that match what has already been typed. Additionally, typing
        while the list is displayed will continue to narrow down the list of matching column names.
        &nbsp;Hitting the enter key will copy the name of the column into the script editor.
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='String Manipulation'>
          <b>String Manipulation</b>
        </div>
        <b> </b>
      </p>
      <p style={{ marginLeft: 0 }}>
        Strings may be concatenated together using the plus sign. &nbsp;For example:
      </p>
      <p style={{ marginLeft: 10 }}>
        <i>
          &nbsp;&nbsp;&nbsp;&nbsp;string s;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;s = "First"&nbsp;+&nbsp;"&nbsp;"&nbsp;+&nbsp;"Second";
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        It is also possible to access a portion of a string using the substr command which takes the
        form:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          substr(sourceString,startIndex,endIndex);
          <br />
          <br />
        </i>
        Indices should start at 0 and go to 1 less than the source string's length.
        <i> </i>There should be no spaces inside the parentheses.
        <br />
        <br />
        Examples:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          string s;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;string y;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;y = substr(s,0,2);
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;int start = 1;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;int end = 3;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;y = substr(s,start,end);
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        It is possible that the source string refer to a value retrieved using the <b>key</b>{' '}
        keyword, however this must be done in multiple steps by first placing the string in a string
        variable and then writing the substr command:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;string s = key[ValueKey];
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>y = substr(s,start,end); </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='Math Functions'>
          <b>Math Functions</b>
        </div>
        <b> </b>
      </p>
      <p style={{ marginLeft: 0 }}>
        All math functions found in java.lang.Math are available for use in custom scripts. The
        syntax is similar to that of native java. For example:
        <i>
          <br />
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>y = Math.min(a, b);</i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          y = Math.random();
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          y = Math.sin(a);
          <br />
          <br />
        </i>
        are all valid uses of the math functions.<i> </i>When a math function is desired, type Math
        followed by a period and a portion of a function name. Next press Ctrl-Space to see a list
        of matching functions. If no function name is used, then all functions will be displayed.
        Additionally, typing while the list is displayed will continue to narrow down the list of
        matching function names. &nbsp;Hitting the enter key will copy the name of the function into
        the script editor.
        <br />
        <br />
        The
        <b>key[] </b>syntax cannot be used directly inside of a call to a Math function. This would
        need to be separated into two statements. The first to get the value for the key and the
        second to call the Math function using that value:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;p = key[Price];
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d = Math.sin(p);
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='IncrDecr'>Increment and Decrement Operators</div>
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        aScript supports prefix increment and decrement operators.
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        For example: ++x or --x
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Print'>The print statement</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        aScript supports print statements in a similar fomat to java. &nbsp;Examples are
      </p>
      <ul
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <li>print("Hello");</li>
        <li>print("The value of x is: " + x);</li>
      </ul>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        print statements should only be used in development for debugging purposes.
      </p>
      <p style={{ marginLeft: 0 }}>
        <div id='return'>
          <b>The return Statement</b>
        </div>
        <b> </b>
      </p>
      <p style={{ marginLeft: 0 }}>
        Scripts should return a value. This is done via the return statement. It takes the form of:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>return expression;</i>
      </p>
      <p style={{ marginLeft: 0 }}>Examples are:</p>
      <p style={{ marginLeft: 20 }}>
        <i>
          return 1;
          <br />
          return 10.2;
          <br />
          return true;
          <br />
          return key[Mkt Value] *&nbsp;1.1;
          <br />
          return "Test";
        </i>
      </p>
      <p style={{ marginLeft: 0 }}>
        &nbsp;A special case of the return statement is a script which contains only one line.
        &nbsp;If that is the case, the return and the semicolon may be omitted.
        <br />
        <br />
        Examples are:
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <i>
          key[Mkt Value] *&nbsp;2
          <br />
        </i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>22./7.</i>&nbsp;{/*StartFragment*/}
      </p>
      <h3
        style={{
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          letterSpacing: 'normal',
          textTransform: 'none',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Accessing Java'>Accessing Java Code</div>
      </h3>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        In aScript it is possible to instantiate and execute methods for both built in java classes
        (e.g. String, HashMap) as well as java classes defined in your own project. The content
        assistant will help in choosing methods and determining what types of values are required
        for a given method.
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Import Statement'>The Import Statement</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        To access a java class, the first thing required is an import statement. &nbsp;It has the
        form:
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        import<span className='Apple-converted-space'>&nbsp;</span>
        <em>
          java_class<span className='Apple-converted-space'>&nbsp;</span>
        </em>
        as<span className='Apple-converted-space'>&nbsp;</span>
        <em>script_type</em>
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        where java_class is the fully qualified name of the java class to be imported and
        script_type is how you will refer to the class in your aScript code.&nbsp;
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        Examples:
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        # Import ArrayList as script type List
        <br />
        import java.util.ArrayList as List;
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        # Import HashMap as HM
        <br />
        import java.util.HashMap as HM;
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        You can also use java generics syntax to specify that an import should be a parameterized
        one.
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        # Import ArrayList that holds Integers
        <br />
        import java.util.ArrayList&lt;Integer&gt; as IntList;
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        In the above example, instances of IntList would be parameterized as holding a List of
        Integers.
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Instantiate a java variable'>Instantiating an imported variable</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        One an import statement has been used, declaring an instance of variable is similar to
        declaring an instance of a built in type like int or double. Using the above imports as a
        reference, variables could be declared as:
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        # Instantiate a List
        <br />
        List l;
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        # Instantiate a HashMap &nbsp;
        <br />
        HM map;
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        # Instantiate a ArrayList&lt;Integer&gt;
        <br />
        IntList il;
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Content Assistant for java'>Using the content assistant</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        The content assistant can be used to display methods on instances of &nbsp;java import
        objects. &nbsp;Simply type the name of the instance followed by a "." then hit ctrl-space.
        All public methods will be displayed showing both the types of the parameters as well as the
        return type of the method. If there is text following the "." then the list of methods will
        be filtered such that only methods begining with the text are displayed. Selecting a method
        from the list will insert it into the aScript editor. &nbsp;When the method is inserted into
        the list, there will be placeholders for each parameter. The placeholders will be shown in
        different colors to indicate that they need to be replaced with real values. For instance
        the HashMap's put method would show as:
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        map.put(
        <em>
          <strong>
            <Typography style={{ backgroundColor: 'rgb(51, 153, 255)', width: 'fit-content' }}>
              Object
            </Typography>
          </strong>
        </em>
        ,<span className='Apple-converted-space'>&nbsp;</span>
        <em>
          <strong>
            <Typography style={{ backgroundColor: 'rgb(128, 128, 128)', width: 'fit-content' }}>
              Object
            </Typography>
          </strong>
        </em>
        )
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        The first parameter is highlighted to allow immediate typing over it's value. &nbsp;To
        modify the second parameter, the mouse can be single clicked on the text or the cursor key
        can be used to cursor over to it. It will then be highlighted and can be modified.
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='AccessingValueContainer'>Accessing the ValueContainer in a ValueComputer</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        When writing custom java code that will be called from an ascript based ValueComputer, it
        may be convenient to have access to the ValueContainer that is normally passed to a
        ValueComputer. This can be done by specifying the <b>this</b> keyword as a parameter in the
        ascript method invocation. This will only work in the case where the ascript is being used
        to generate a ValueComputer. For example, the following ascript:
        <br />
        <br />
        import com.util.Test as Test;
        <br />
        Test x;
        <br />
        x.someMethod(this);
        <br />
        <br />
        can be used to call the java method Test.someMethod() and will pass the ValueContainer as a
        parameter to it.
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='PassingValueKey'>Passing a ValueKey as a parameter to a java method</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Calling Static Method'>
          If a ValueKey is a parameter to a java method, then the <b>vkey</b> keyword can be used to
          lookup the ValueKey and pass the result to the method. For example, the following aScript
          would call a method com.util.Lookup.lookup(ValueKey key) with the ValueKey representing
          Mkt Val:
        </div>
        <br />
        <br />
        <div id='Calling Static Method'> </div>import com.util.Lookup as Lookup;
        <br />
        Lookup l;
        <br />
        return l.lookup(vkey[Mkt Val]);
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Calling Static Method'>Calling a static method</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        A static method is called in a similar fashion to how it is done in java. The class name is
        specified followed by the name of the method and any parameters. However, instead of
        specifying the java class name, you specify the script name as it has been imported.
        <br />
        For example, to write a script which accesses the static java method Integer.parseInt(String
        s) you could write:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;import java.lang.Integer as Int;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return Int.parseInt("1000");
        <br />
      </p>
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Casting'>Casting</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        aScript supports a limited form of casting. Below are several examples:
        <br />
        <br />
        <i>
          import java.lang.Double as Double;
          <br />
          <br /># Cast using native aScript double
          <br />
          double d = 5.5;
          <br />
          int x = (int) d;
          <br />
          <br /># Casting result of a call to a java method
          <br />x = (int) Double.parseDouble("20.2");
          <br />
          <br /># Casting more than one value
          <br />x = (int) 1.5 + (int) Double.parseDouble("100");
        </i>
      </p>
      <p>
        You cannot cast the parameters inside a method call or cast an expression (e.g. (int) (1.2 +
        5.7). &nbsp;In cases such as these, the items to be cast will need to be broken down and
        casted separately, then put back into the expression.
      </p>
      <h3>
        <div id='Cell Computers'>Cell</div> Computers
      </h3>
      <p style={{ marginLeft: 0 }}>
        If the script is being used to generate a Cell&nbsp;Computer then the keyword <b>total </b>
        may also be used to access values.
        <span>
          The keyword total in the context of a Cell Computer, represents a handle to the root of
          the report. It is used in a similar way to the keyword <b>key</b> in that it takes a
          bracketed characteristic name. So for instance, assuming a characteristic called
          MarketVal, a CellComputer that wished to compute % MarketVal could be written as:
          <Typography style={{ fontSize: '12pt' }}>
            <br />
            <br />
            &nbsp;&nbsp;
          </Typography>
          <i>
            return key[MarketVal]/total[MarketVal];
            <br />
            <br />
          </i>
          Additionally, when writing a script to be used as a CellComputer, you can specify a
          modifier for the <b>key </b>and
          <b>total </b>keywords. The modifiers are expressed as a "." after the ending bracket
          followed by one of the following choices:&nbsp;port, bench, diff, bench2 and diff2. For
          instance, the aScript necessary to have a CellComputer return MarketVal's diff value would
          be:{' '}
          <i>
            <br />
            <br />
            &nbsp;&nbsp;return key[MarketVal].diff;
            <br />
            <br />
          </i>
          Note that if you specifiy a modifier explicitly, then that modifier will be used in all
          cases essentially fixing the modifier. &nbsp;If the modifier is not explicitly specified,
          then the modifier will be implied by the characteristic being calculated. For instance, if
          it's a bench characteristic then the modifier is bench.
        </span>
        &nbsp;
        <br />
        <br />
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='RIntegration'>
          <b>Integration with the R Statistical Package</b>
        </div>
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        aScript has a straightforward way of integrating with the R computing environment.
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        The following statements exist in order to facilitate moving data from SS&amp;C Workspace
        Analyzer into R and vice versa.
      </p>
      <table style={{ borderWidth: '2px' }} width='100%' cellPadding={0} cellSpacing={0}>
        <tbody>
          <tr>
            <td width={119}>
              &nbsp;<b>Statement</b>
            </td>
            <td width={195}>
              &nbsp;<b>Usage</b>
            </td>
          </tr>
          <tr>
            <td width={119}>&nbsp;RAssign</td>
            <td width={195}>
              &nbsp;This is used to assign values from SS&amp;C Workspace Analyzer into a variable
              to be used in R
            </td>
          </tr>
          <tr>
            <td width={119}>&nbsp;RBegin</td>
            <td>&nbsp;This signifies the beginning of an R code block.</td>
          </tr>
          <tr>
            <td width={119}>&nbsp;REnd</td>
            <td>&nbsp;This signifies the end of an R code block.</td>
          </tr>
          <tr>
            <td width={119}>&nbsp;ArmAssign</td>
            <td>
              &nbsp;This take the result of a variable from an R code block and assigns it to be the
              result of the post-processor.
            </td>
          </tr>
        </tbody>
      </table>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        The following is an example of how to generated fitted values in a post-processor using R.
        It assumes there are two values in the SS&amp;C Workspace Analyzer framework with keys Price
        and Pricing Date.
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          backgroundColor: '#ffffcc',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontVariant: 'normal',
          lineHeight: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <br /># Assign values from SS&amp;C Workspace Analyzer into variables x and y to be used by
        R.
        <br />
        RAssign("x", key[Price];
        <br />
        RAssign("y", key[Pricing Date];
        <br />
        <br /># Write the R code using x and y and storing the result in z.
        <br />
        RBegin
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;z &lt;- lm(x ~y)$fitted.values
        <br />
        REnd
        <br />
        <br /># Assign the value of z to be return from this post-processor
        <br />
        ArmAssign("z");
      </p>
      <h3>
        <div id='Aggregators'>Aggregators</div>
      </h3>
      <p>
        aScript can be used to generate aggregators as well as value and cell computers. aScript
        aggregation adds several new methods and variables.&nbsp;
      </p>
      <h4>
        <div id='Agg Vars'>Aggregation Variables</div>
      </h4>
      <p>
        Two variables that can be declared in an aScript aggregator are <strong>AggNodes </strong>
        and
        <strong>AggRow</strong>. AggNodes represents the collection of child items being aggregated.
        AggRow represents a single row that makes up AggNodes. &nbsp;At the lowest level of
        aggregation AggRow would represent an actual entity. &nbsp;At all other levels it represents
        a node in the report.
        {/*StartFragment*/}
      </p>
      <h4>
        <div id='Agg Methods'>Aggregation Methods</div>
      </h4>
      <p>
        There are two types of aggregation methods in aScript. &nbsp;Those that are standalone and
        those that are instance methods for AggNodes and AggRow types. An example of a standalone
        method would be <i>getNodes</i>(). An example of an instance method would be
        <i>row.getAggDoubleValue()</i>. The complete list of aScript standalone and instance
        aggregation methods can be found <a href='#agg-extra'>here</a>.
      </p>
      <h4>
        <div id='Example Aggs'>Example Aggregators</div>
      </h4>
      <p>
        The example below shows the aScript necessary to write an aggregator to that will sum double
        values.
      </p>
      <p style={{ backgroundColor: '#ffffcc' }}>
        AggNodes nodes;
        <br />
        AggRow row;
        <br />
        double sum; &nbsp;
        <br />
        sum = 0;
        <br />
        nodes = getNodes();
        <br />
        while(nodes.hasNext()) {'{'} &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;row = nodes.next(); &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sum = sum + row.getAggDoubleValue();
        <br />
        {'}'}
        <br />
        return sum;
      </p>
      <p>
        The example below shows the&nbsp;aScript&nbsp;necessary to perform a market value weighted
        aggregation.
      </p>
      <p style={{ backgroundColor: '#ffffcc' }}>
        double productSum;
        <br />
        double weightSum;
        <br />
        double value;
        <br />
        double weight; &nbsp;
        <br />
        AggNodes aggNodes;
        <br />
        AggRow aggRow; &nbsp;
        <br />
        if(hasNoAggData()) {'{'}&nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return null; {'}'}
        <br />
        else {'{'} &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# Initialize the product and weight &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;productSum = 0.0; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;weightSum = 0.0; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;aggNodes = getNodes(); &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;while(aggNodes.hasNext()) {'{'} &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;aggRow = aggNodes.next(); &nbsp;
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# Get the value from the row
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if(aggRow.getAggDoubleValue() ==
        null ) {'{'} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;value
        = 0; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}
        else {'{'} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;value
        = aggRow.getAggDoubleValue(); &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# Get the market value to
        be used as a weight. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if(aggRow.get[Mkt Value]
        == null ) {'{'} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;weight
        = 0; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {'}'}
        else {'{'} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;weight
        = aggRow.get[Mkt Value] ; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {'}'}
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;productSum =
        productSum + (value)*(weight); &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;weightSum =
        weightSum + (weight); &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}
        <br />
        {'}'}
        &nbsp;
        <br />
        return productSum/weightSum; &nbsp;
      </p>
      {/*StartFragment*/}
      <h4
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          textAlign: 'start',
          orphans: 2,
          widows: 2,
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <div id='Required ValueKeys'>Required ValueKeys</div>
      </h4>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        Any ValueKeys that are accessed from within an aggregator need to be mentioned at the top of
        the script using the 'requires ValueKey[
        <em>key_name</em>]' construct. &nbsp;The exception are those ValueKeys that are accessed
        from within an isAggregatingDataSetEntities() clause. Data set entities represent individual
        data records grouped within a lowest level bucket of the reporting hierarchy. &nbsp;When
        data set entities are being aggregated, values for all ValueKeys are accessible to the
        aggregator.
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        The example below shows an aggregator that uses an&nbsp;isAggregatingDataSetEntities()
        clause. &nbsp;It gets values for three ValueKeys but only uses one requires statment since
        the other two are accessed from within an&nbsp;isAggregatingDataSetEntities() clause.
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          backgroundColor: '#ffffcc',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontVariant: 'normal',
          lineHeight: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        requires ValueKey[ValueKey3];
        <br />
        <br /># Variable Declaration&nbsp;
        <br />
        AggNodes nodes;
        <br />
        AggRow row;
        <br />
        <br />
        double sum;
        <br />
        sum = 0.0;
        <br />
        <br /># Get the nodes being aggregated
        <br />
        nodes = getNodes();
        <br />
        <br /># Loop over nodes
        <br />
        while(nodes.hasNext()) {'{'} &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;row = nodes.next(); &nbsp;
        <br />
        &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if(isAggregatingDataSetEntities())
        {'{'} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# This is being executed within
        an isAggregatingDataSetEntities() clause.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; # No need to add any required values
        since all values in each entity are &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;# automatically accesible.
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; sum = &nbsp;sum + row.get[ValueKey1] +
        row.get[ValueKey2];
        <br />
        &nbsp; &nbsp; &nbsp;{'}'} else {'{'}
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; # This is being executed outside an
        isAggregatingDataSetEntities() clause.
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;# For that reason, ValueKey3 must be mentioned in a
        requires ValueKey[]
        <br />
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; # construct at the top of the script. &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sum = sum +
        row.getAggDoubleValue() * row.get[ValueKey3];
        <br />
        &nbsp; &nbsp; &nbsp;{'}'}
        <br />
        {'}'}
        <br />
        <br /># Return aggregated value
        <br />
        return sum;
      </p>
      <p
        style={{
          fontSize: 'medium',
          textIndent: 0,
          fontFamily: 'Times New Roman',
          orphans: 2,
          widows: 2,
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 'normal',
          fontVariant: 'normal',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'rgb(0, 0, 0)',
          wordSpacing: 0,
          whiteSpace: 'normal',
        }}
      >
        <b>
          <br />
        </b>
        {/*EndFragment*/}
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>

      <div id='agg-extra'>
        <Typography variant='h1' style={{ textAlign: 'center', width: '55%' }}>
          Aggregation Methods
        </Typography>
        <table
          cellSpacing={1}
          style={{ width: 650, borderWidth: '1px', alignSelf: 'center' }}
          width='99%'
          cellPadding={1}
        >
          <tbody>
            <tr>
              <th scope='col' style={{ textAlign: 'center' }}>
                <strong>Method</strong>
              </th>
              <th scope='col' style={{ textAlign: 'center' }}>
                <strong>Description</strong>
              </th>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>getNodes()</td>
              <td>
                Returns an <em>AggNodes </em>instance that represents what is being aggregated
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>getEntities()</td>
              <td>
                Like getNodes(), except calling getEntities() signifies that this is a leaf
                aggregator. A leaf aggregator is much more expensive that a normal aggregator.
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>getAggDataField()</td>
              <td>Returns the ValueKey being aggregated.</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>hasNoAggData()</td>
              <td>Returns true if there are no nodes to be aggregated.</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>isAggRoot()</td>
              <td>Returns true if this node is the root node of the report tree.</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>isAggLeaf()</td>
              <td>Returns true if this node is a leaf of grouping tree.</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>isAggregatingDataSetEntities()</td>
              <td>
                Returns true if this aggregation is being done for DataSetEntities. It will always
                return false for LeafAggregators.
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>
                ValueKey[<em>key_name</em>]
              </td>
              <td>
                Returns a ValueKey&nbsp;associated with a given characteristic. &nbsp;It is suitable
                for comparison with the result of a call to getAggDataField()
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>AggNodes.hasNext() &nbsp;</td>
              <td>Use to iterate over the child nodes being aggregated</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left' }}>AggNodes.next()</td>
              <td>Returns an AggRow object that contains values for one row of the aggregation.</td>
            </tr>
            <tr>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                AggRow.get[<em>key_name</em>]
              </td>
              <td>Returns the value in an AggRow associated with a specific characteristic.</td>
            </tr>
            <tr>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                AggRow.getAggDoubleValue()
              </td>
              <td>
                Returns the value in an AggRow associated with the characteristic being aggregated
                as a double.&nbsp;
              </td>
            </tr>
            <tr>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>AggRow.getAggIntValue()</td>
              <td>
                Returns the value in an&nbsp;AggRow&nbsp;associated with the characteristic being
                aggregated as an int.
              </td>
            </tr>
            <tr>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                AggRow.getAggStringValue()
              </td>
              <td>
                Returns the value in an&nbsp;AggRow&nbsp;associated with the characteristic being
                aggregated as a string
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;&nbsp;</p>
      </div>

      <div id='expressions-extra'>
        <Typography variant='h1' style={{ alignSelf: 'center' }}>
          aScript Expressions
        </Typography>
        <table width='100%' cellPadding={0} cellSpacing={0} style={{ borderWidth: '1px' }}>
          <tbody>
            <tr>
              <td width={121}>
                <b>&nbsp;Symbol </b>
              </td>
              <td width={194}>
                <b>&nbsp;Meaning </b>
              </td>
              <td>
                <b>&nbsp;Example</b>
              </td>
            </tr>
            <tr>
              <td width={121}>=</td>
              <td width={194}>Assignment</td>
              <td>x = 5;</td>
            </tr>
            <tr>
              <td width={121}>+</td>
              <td width={194}>Addition</td>
              <td>if(x&nbsp;+&nbsp;y&nbsp;&gt;&nbsp;5)</td>
            </tr>
            <tr>
              <td width={121}>-</td>
              <td width={194}>Subtraction</td>
              <td>if(x - y &lt;&nbsp;10)</td>
            </tr>
            <tr>
              <td width={121}>*</td>
              <td width={194}>Multiplication</td>
              <td>if(x * y &lt;&nbsp;20)</td>
            </tr>
            <tr>
              <td width={121}>/</td>
              <td width={194}>Division</td>
              <td>if(x / y &gt;&nbsp;5)</td>
            </tr>
            <tr>
              <td width={121}>%</td>
              <td width={194}>Remainder</td>
              <td>if(x % y &gt;&nbsp;0)</td>
            </tr>
            <tr>
              <td width={121}>&lt;</td>
              <td width={194}>Less than</td>
              <td>if(x &lt; y)</td>
            </tr>
            <tr>
              <td width={121}>&gt;</td>
              <td width={194}>Greater than</td>
              <td>if(x &gt; y)</td>
            </tr>
            <tr>
              <td width={121}>&gt;=</td>
              <td width={194}>Greater than or equal</td>
              <td>if( x &gt;= y)</td>
            </tr>
            <tr>
              <td width={121}>&lt;=</td>
              <td width={194}>Less than or equal</td>
              <td>if(x&nbsp;&lt;= y)</td>
            </tr>
            <tr>
              <td width={121}>&nbsp;||</td>
              <td width={194}>Conditional or</td>
              <td>if(x &gt;&nbsp;y ||&nbsp;x &gt;&nbsp;z)</td>
            </tr>
            <tr>
              <td width={121}>&nbsp;&amp;&amp;</td>
              <td width={194}>Conditional and</td>
              <td>if(x &gt;&nbsp;y &amp;&amp;&nbsp;x &gt; z)</td>
            </tr>
            <tr>
              <td width={121}>&nbsp;!</td>
              <td width={194}>Not operation</td>
              <td>if(!(x &gt;&nbsp;5))</td>
            </tr>
            <tr>
              <td width={121}>&nbsp;|</td>
              <td width={194}>Inclusive or</td>
              <td>if(x | y)</td>
            </tr>
            <tr>
              <td width={121}>&nbsp;^</td>
              <td width={194}>Exclusive or</td>
              <td>if(x ^ y)</td>
            </tr>
            <tr>
              <td width={121}>&amp;</td>
              <td width={194}>Bitwise and</td>
              <td>if(x &amp;&nbsp;y)</td>
            </tr>
            <tr>
              <td width={121}>==, !=</td>
              <td width={194}>Relational expression</td>
              <td>if(a == b), if(a != b)</td>
            </tr>
            <tr>
              <td width={121}>~</td>
              <td width={194}>Bitwise complement</td>
              <td>if(~x &gt;&nbsp;0)</td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
      </div>
    </div>
  );
}
