/*
  Also see:
    src\components\security\routing\AuthCfg.tsx
*/

//////////////////////////////////////////////////////////////////////
// PLEASE ... when making changes to this file:                     //
//  - update backstage/src/main/env/.env.ui                         //
//  - update backstage/src/main/jib/usr/local/algo/stadium/.env.ui  //
//////////////////////////////////////////////////////////////////////

export const toBool: (val: any) => boolean = (val: any) => {
  const lower = ('' + val).trim().toLowerCase();
  if (true === val || 'true' === lower) {
    return true;
  } else if (false === val || 'false' === lower) {
    return false;
  }
  return false;
};

export const devUser = (window as any)._env_?.REACT_APP_DEV_USER || process.env.REACT_APP_DEV_USER;
export const devPass = (window as any)._env_?.REACT_APP_DEV_PASS || process.env.REACT_APP_DEV_PASS;

export const idleCountdownInSeconds: number =
  (window as any)._env_?.REACT_APP_IDLE_COUNTDOWN_SECONDS ||
  process.env.REACT_APP_IDLE_COUNTDOWN_SECONDS ||
  60;

export const idleTimeoutInMinutes: number =
  (window as any)._env_?.REACT_APP_IDLE_TIMEOUT_MINUTES ||
  process.env.REACT_APP_IDLE_TIMEOUT_MINUTES ||
  60;

export const refreshPermissionsSeconds: number =
  (window as any)._env_?.REACT_APP_REFRESH_PERMISSIONS_SECONDS ||
  process.env.REACT_APP_REFRESH_PERMISSIONS_SECONDS ||
  30;

export const isOidcClientEnabled =
  toBool((window as any)._env_?.REACT_APP_OIDC_CLIENT_ENABLED) ||
  toBool(process.env.REACT_APP_OIDC_CLIENT_ENABLED);

export const isPortalEnabled =
  toBool((window as any)._env_?.REACT_APP_PORTAL_ENABLED) ||
  toBool(process.env.REACT_APP_PORTAL_ENABLED);

export const WS_MAX_PENDING_REQUESTS =
  (window as any)._env_?.REACT_APP_WS_MAX_PENDING_REQUESTS ||
  process.env.REACT_APP_WS_MAX_PENDING_REQUESTS ||
  4;

export const devEnvironment =
  toBool((window as any)._env_?.REACT_APP_DEV_ENVIRONMENT) ||
  toBool(process.env.REACT_APP_DEV_ENVIRONMENT);

export const logLevel = process.env.REACT_APP_OIDC_CLIENT_LOG_LEVEL;

export const publicUrl = process.env.REACT_APP_PUBLIC_URL;

export const baseUrl = (window as any)._env_?.REACT_APP_REST_URL || process.env.REACT_APP_REST_URL;

export const wsBaseUrl = (window as any)._env_?.REACT_APP_WS_URL || process.env.REACT_APP_WS_URL;

export const rmuiBaseUrl =
  (window as any)._env_?.REACT_APP_RMUI_URL || process.env.REACT_APP_RMUI_URL;

export const autoRecover =
  toBool((window as any)._env_?.REACT_APP_AUTO_RECOVER) ||
  toBool(process.env.REACT_APP_AUTO_RECOVER);

export const includePathsInCSVHeaders =
  toBool((window as any)._env_?.REACT_APP_INCLUDE_PATHS_IN_CSV_HEADERS) ||
  toBool(process.env.REACT_APP_INCLUDE_PATHS_IN_CSV_HEADERS);

export const subscribeTopic = '/topic/reports/';
export const publishTopic = '/app/reports/';

export const subscribeCSVTopic = '/topic/csv/';
export const publishCSVTopic = '/app/csv/';

export const subscribePendingUpdateTopic = '/topic/pendingUpdate/';

export const subscribeEditTopic = '/topic/edit/';
export const publishEditTopic = '/app/edit/';

export const subscribeProgressTopic = '/topic/progress/';

export const CUSTOM_GROUPING_DEBUG = false;
