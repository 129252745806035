import { Select, Tooltip, Typography } from '@material-ui/core';
import { ChangeEvent, FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ScenariosConfig } from '../../../model/scenariosConfig';
import { setScenarioConfigProperty } from '../../../redux/ActionCreators';
import { DRILLTHROUGH_SETTING_TOOLTIP } from '../../../shared/constants';
import MenuItems, { Item } from '../ui-elements/MenuItems';

interface BaseProps {
  name: string;
  id: keyof ScenariosConfig;
  items?: Item[];
  defaultValue?: unknown;
  scenariosConfig: ScenariosConfig;
  disabled?: boolean;
}

const mapDispatchToProps = { setScenarioConfigProperty };
const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const ScenariosDropdown: FC<Props> = props => (
  <Tooltip title={props.disabled ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
    <div
      style={{
        height: '48px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography>{props.name}</Typography>
      <Select
        variant='outlined'
        value={props.scenariosConfig[props.id] ?? props.defaultValue ?? ''}
        onChange={(e: ChangeEvent<{ value: ScenariosConfig[typeof props.id] }>) => {
          props.setScenarioConfigProperty(props.id, e.target.value);
        }}
        style={{
          width: '35%',
          height: '40px',
          marginTop: '5px',
        }}
        disabled={props.disabled}
      >
        {MenuItems(props.items)}
      </Select>
    </div>
  </Tooltip>
);

export default connector(ScenariosDropdown);
