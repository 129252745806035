import { FC } from 'react';
import MainModal from '../main/MainModal';

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const ClearSandboxModal: FC<Props> = props => (
  <div className='clear-sandbox-modal'>
    <MainModal
      headerText='Clear Sandbox'
      open={props.isOpen}
      onRequestSubmit={props.onSubmit}
      onRequestClose={props.onClose}
      submitColor='secondary'
    >
      You are about to clear all sandbox data changes. Are you sure you want to do this?
    </MainModal>
  </div>
);

export default ClearSandboxModal;
