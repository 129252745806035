import FoliaPredicate from '../../../lang/folia/model/predicate';
import GroupingNodeController from '../../../model/custom-grouping/groupingNodeController';
import {
  CharacteristicGroupingStrategy,
  GroupingStrategy,
  GroupingStrategyType,
  PredicateGroupingStrategy,
  RemainderGroupingStrategy,
} from '../../../model/custom-grouping/groupingStrategy';

export type StrategyDesignerViewState =
  | PredicateStrategyViewState
  | CharacteristicStrategyViewState
  | RemainderStrategyViewState;

interface BaseViewState {
  type: GroupingStrategyType;
  nodeId: string;
}

export interface PredicateStrategyViewState extends BaseViewState {
  type: GroupingStrategyType.PREDICATE;
  predicate: FoliaPredicate | null;
}

export interface CharacteristicStrategyViewState extends BaseViewState {
  type: GroupingStrategyType.CHARACTERISTIC;
  characteristicId: number | null;
  breakpoints: string[] | null;
}

export interface RemainderStrategyViewState extends BaseViewState {
  type: GroupingStrategyType.REMAINDER;
}

/**
 * Create view state, populating from existing strategy if applicable
 */
export const createInitialViewState = (
  strategyType: GroupingStrategyType,
  node: GroupingNodeController,
): StrategyDesignerViewState => {
  const existingStrategy = node.groupingStrategy;

  switch (strategyType) {
    case GroupingStrategyType.PREDICATE:
      return createInitialPredicateViewState(
        node.id,
        existingStrategy?.type === strategyType ? existingStrategy : null,
      );
    case GroupingStrategyType.CHARACTERISTIC:
      return createInitialCharacteristicViewState(
        node.id,
        existingStrategy?.type === strategyType ? existingStrategy : null,
      );
    case GroupingStrategyType.REMAINDER:
      return createInitialRemainderViewState(node.id);
  }
};

const createInitialPredicateViewState = (
  nodeId: string,
  strategy: PredicateGroupingStrategy | null,
): PredicateStrategyViewState => ({
  type: GroupingStrategyType.PREDICATE,
  nodeId,
  predicate: strategy?.predicate ?? null,
});

const createInitialCharacteristicViewState = (
  nodeId: string,
  strategy: CharacteristicGroupingStrategy | null,
): CharacteristicStrategyViewState => ({
  type: GroupingStrategyType.CHARACTERISTIC,
  nodeId,
  characteristicId: strategy?.groupings?.[0]?.characteristicId ?? null,
  breakpoints: strategy?.groupings?.[0]?.breakpoints ?? null,
});

const createInitialRemainderViewState = (nodeId: string): RemainderStrategyViewState => ({
  type: GroupingStrategyType.REMAINDER,
  nodeId,
});

/**
 *  Transform view state to model objects
 */
export const createGroupingStrategy = (viewState: StrategyDesignerViewState): GroupingStrategy => {
  switch (viewState.type) {
    case GroupingStrategyType.PREDICATE:
      return createPredicateGroupingStrategy(viewState);
    case GroupingStrategyType.CHARACTERISTIC:
      return createCharacteristicGroupingStrategy(viewState);
    case GroupingStrategyType.REMAINDER:
      return createRemainderGroupingStrategy(viewState);
  }
};

const createPredicateGroupingStrategy = ({
  type,
  predicate,
}: PredicateStrategyViewState): PredicateGroupingStrategy => ({
  type,
  predicate,
});

const createCharacteristicGroupingStrategy = ({
  type,
  characteristicId,
  breakpoints,
}: CharacteristicStrategyViewState): CharacteristicGroupingStrategy => ({
  type,
  groupings: [
    {
      characteristicId,
      breakpoints,
    },
  ],
});

const createRemainderGroupingStrategy = ({
  type,
}: RemainderStrategyViewState): RemainderGroupingStrategy => ({
  type,
});

/**
 * Define exactly how saving a grouping strategy affects the model
 */
export const getStrategyUpdateOperation = (
  viewState: StrategyDesignerViewState,
  selectedIds: string[],
) => (modelController: GroupingNodeController) => {
  const nodePath = modelController.getPath(selectedIds[0]);

  return modelController.setGroupingStrategy(nodePath, createGroupingStrategy(viewState));
};
