import { Tooltip } from '@material-ui/core';
import {
  Bookmark as BookmarkIcon,
  FolderOpen as FolderOpenIcon,
  ListAlt as ListAltIcon,
  MoreVert,
} from '@material-ui/icons';
import { CascadingMenu, CascadingMenuItem } from 'algo-react-dataviz';
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import { NodeType } from '../../shared/constants';
import { FolderListRow } from '../../shared/dataTypes';

interface BaseProps {
  data: FolderListRow;
  disabled?: boolean;
  menuItems?: CascadingMenuItem[];
  isDefaultWorkspace?: boolean;
}

const mapStateToProps = (state: AppState) => ({
  selectedFolderItem: state.drawers.folderDrawer.selectedFolderItem,
});
const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const IconCell: React.FunctionComponent<Props> = props => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        cursor: props.disabled ? 'inherit' : 'pointer',
        color: props.disabled ? '#bfbfbf' : 'inherit',
        display: 'flex',
        alignItems: 'center',
        background: props.data.id === props.selectedFolderItem?.id ? '#e6e6e6' : '#fff',
      }}
    >
      {props.data.type === NodeType.FOLDER ? (
        <FolderOpenIcon style={{ verticalAlign: 'top' }} />
      ) : (
        <ListAltIcon style={{ verticalAlign: 'top' }} />
      )}
      <Tooltip title={props.data.name} aria-label='add'>
        <span
          style={{
            paddingLeft: '5px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            flex: 1,
          }}
        >
          {props.data.name}
        </span>
      </Tooltip>
      {props.isDefaultWorkspace && <BookmarkIcon className='default-workspace-bookmark' />}
      {!!props.menuItems?.length && props.data.name !== '/' && (
        <CascadingMenu
          items={props.menuItems}
          showMenu={hover}
          icon={<MoreVert fontSize='small' />}
          style={{ display: 'inline-flex', alignContent: 'center', height: '20px', width: '22px' }}
        />
      )}
    </div>
  );
};

export default connector(IconCell);
