import { Button, Checkbox, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Node } from '../../shared/dataTypes';
import { deDupeArray } from '../../shared/utils';
import SearchField from './SearchField';

interface Props {
  adHocPortfolios: Node<string>;
  selectedAdHocKeys?: string[];
  setSelectedAdHocKeys?: (k: string[]) => void;
}

const AdhocPortfolioTree: FC<Props> = props => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <>
      <div className='search-controls'>
        <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Button
          onClick={() => {
            props.setSelectedAdHocKeys(
              deDupeArray([
                ...props.selectedAdHocKeys,
                ...props.adHocPortfolios.children
                  .filter(
                    port =>
                      searchTerm === '' ||
                      port?.name.toLowerCase().includes(searchTerm.toLowerCase()),
                  )
                  .map(port => port.name),
              ]),
            );
          }}
        >
          Select All
        </Button>
      </div>
      <div className='portfolio-flat-list'>
        {props.adHocPortfolios?.children
          ?.filter(
            node =>
              searchTerm === '' || node?.name.toLowerCase().includes(searchTerm.toLowerCase()),
          )
          .map(node => {
            return (
              <div key={node.id} className='portfolio-flat-list-item'>
                <Checkbox
                  color='primary'
                  checked={props.selectedAdHocKeys?.includes(node.name)}
                  onChange={e => {
                    props.setSelectedAdHocKeys(
                      e.target.checked
                        ? [...props.selectedAdHocKeys, node.name]
                        : props.selectedAdHocKeys.filter(item => item !== node.name),
                    );
                  }}
                />
                <Typography>{node.name}</Typography>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default AdhocPortfolioTree;
