import { Paper, Typography } from '@material-ui/core';
import { FC } from 'react';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import { Sandbox } from '../../shared/dataTypes';
import Report from '../report/Report';
import { ReportContext } from '../report/reportContext';

interface Props {
  showPlaceholder: boolean;
  reportGenerationNotAllowedReasons: string[];
  drillThrough: boolean;
  sandbox?: Sandbox | null;
}

const ReportPreview: FC<Props> = props =>
  props.showPlaceholder ? (
    <Paper
      style={{
        flexGrow: 1,
        margin: '6px 6px 2px',
        padding: '20px',
        borderRadius: '4px 4px 0px 0px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography component='div' style={{ fontSize: '16px' }}>
        Your report preview will be shown here.
        {props.reportGenerationNotAllowedReasons.length === 0 ? (
          <div>Click Generate Preview to generate.</div>
        ) : (
          props.reportGenerationNotAllowedReasons.map((reason, index) => (
            <div key={index}>{reason}</div>
          ))
        )}
      </Typography>
    </Paper>
  ) : (
    <Report
      context={ReportContext.DESIGNER}
      sequenceId={DESIGNER_SEQUENCE_ID}
      drillThrough={props.drillThrough}
      sandbox={props.sandbox}
    />
  );

export default ReportPreview;
