import { FileCopy } from '@material-ui/icons';
import { LIGHT_BORDER, RoundedButton } from 'algo-react-dataviz';
import { CSSProperties, FC } from 'react';
import './user-info-footer.scss';

interface Props {
  onCreateClick: () => void;
  onResetClick?: () => void;
  style?: CSSProperties;
}

const InfoPanelFooter: FC<Props> = props => (
  <div
    className='info-panel-footer-container'
    style={{
      borderTop: LIGHT_BORDER,
    }}
  >
    {props.onResetClick && (
      <RoundedButton
        className='info-panel-footer-button'
        variant='text'
        onClick={props.onResetClick}
        color='primary'
      >
        Reset User Password
      </RoundedButton>
    )}
    <RoundedButton
      className='info-panel-footer-button'
      variant='outlined'
      color='primary'
      onClick={props.onCreateClick}
      startIcon={<FileCopy />}
    >
      Use as Template
    </RoundedButton>
  </div>
);

export default InfoPanelFooter;
