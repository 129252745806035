import { FC, useEffect, useRef } from 'react';
import NewWindow from 'react-new-window';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { setIsRemoteConsoleOpen, setRemoteConsoleFocus } from '../../redux/ui/actionCreators';
import { NotificationLevel } from '../../shared/constants';
import { clientUuid } from '../../shared/utils';
import './remote-console.scss';
import RemoteConsole from './RemoteConsole';

const RemoteConsoleWindow: FC = () => {
  const dispatch = useDispatch();
  const windowRef = useRef(null);

  const isRemoteConsoleOpen = useSelector((state: AppState) => state.ui?.isRemoteConsoleOpen);

  useEffect(() => {
    dispatch(setRemoteConsoleFocus(windowRef?.current?.window.focus));
  }, [isRemoteConsoleOpen, windowRef, dispatch]);
  return (
    isRemoteConsoleOpen && (
      // popup: 1 = popup, popup: 0 = new tab
      <NewWindow
        ref={windowRef}
        features={{ height: 500, width: 800, popup: 0 }}
        name={`remote_console-${clientUuid}`}
        title='Workspace Analyzer Remote Console'
        onUnload={() => dispatch(setIsRemoteConsoleOpen(false))}
        onBlock={() =>
          dispatch(enqueueSnackbar(NotificationLevel.WARN, 'Remote console popup blocked'))
        }
        copyStyles
      >
        <RemoteConsole />
      </NewWindow>
    )
  );
};

export default RemoteConsoleWindow;
