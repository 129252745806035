import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { switchToSandbox } from '../../redux/WorkspaceActionCreators';

interface Props {
  sequenceId: number;
}

const SandboxChipClose: FC<Props> = props => {
  const dispatch = useDispatch();

  return (
    <IconButton
      className='sd-sandbox-chip-action sd-sandbox-chip-close'
      component='span'
      size='small'
      onClick={() => {
        dispatch(switchToSandbox(props.sequenceId, null));
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default SandboxChipClose;
