export enum CharacteristicDrawerTab {
  PROPERTIES = 'PROPERTIES',
  RISK_COMPUTATION = 'RISK_COMPUTATION',
  DESCRIPTION = 'DESCRIPTION',
}

export const getLabel = (tab: CharacteristicDrawerTab) =>
  ({
    [CharacteristicDrawerTab.PROPERTIES]: 'Properties',
    [CharacteristicDrawerTab.RISK_COMPUTATION]: 'Risk Computation',
    [CharacteristicDrawerTab.DESCRIPTION]: 'Description',
  }[tab]);
