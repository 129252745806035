import { Checkbox, FormControlLabel, FormGroup, FormLabel, Typography } from '@material-ui/core';
import { DateContexts, FullDateContextItem } from 'algo-react-dataviz';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import { isAwa } from '../../shared/utils';

interface BaseProps {
  items: DateContexts;
  selectedItems: FullDateContextItem[];
  setSelectedItems: (dates: FullDateContextItem[]) => void;
}

const mapStateToProps = (state: AppState) => ({ isAwa: isAwa(state) });
const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const CheckboxRuns: FC<Props> = props =>
  props.isAwa && (
    <div className='runs'>
      <Typography style={{ marginBottom: 9 }}>Available Runs</Typography>
      {Object.entries(props.items).map((e, i) => (
        <div key={i}>
          <FormLabel>{e[0]}</FormLabel>

          <FormGroup>
            {e[1].map((item, j) => (
              <FormControlLabel
                key={j}
                label={item.id || e[0]}
                control={
                  <Checkbox
                    color='primary'
                    checked={props.selectedItems.some(d => d.date === e[0] && d.id === item.id)}
                    onChange={({ target }) =>
                      props.setSelectedItems(
                        target.checked
                          ? [...props.selectedItems, { date: e[0], id: item.id }]
                          : props.selectedItems.filter(d => d.date !== e[0] || d.id !== item.id),
                      )
                    }
                  />
                }
              />
            ))}
          </FormGroup>
        </div>
      ))}
    </div>
  );

export default connector(CheckboxRuns);
