import { TextField } from '@material-ui/core';
import { DrawerHeader } from 'algo-react-dataviz';
import axios, { AxiosResponse } from 'axios';
import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeFolderDrawer, updateDefaultWorkspace } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { setWorkspacePathAndId } from '../../redux/WorkspaceActionCreators';
import { DrawerType } from '../../shared/constants';
import { Node } from '../../shared/dataTypes';
import { getDrawerItemType, getEntityApiPath, markFolders } from '../../shared/utils';
import FolderList from '../folder-list/FolderList';
import { baseUrl } from '../shared/environment';
import FolderDrawerFooter from './FolderDrawerFooter';
import ResizableDrawerWrapper from './ResizableDrawerWrapper';

export const SaveAsNameContext = createContext<{
  saveAsName: string;
  setSaveAsName: (saveAsName: string) => void;
}>({
  saveAsName: '',
  setSaveAsName: () => {},
});

const mapStateToProps = (state: AppState) => {
  const { type, previousType, active } = state.drawers.folderDrawer;

  return {
    type,
    active,
    workspacePath: state.workspace.data?.path,
    defaultWorkspace: state.user.userInfo?.userPreferences?.defaultWorkspace,

    itemType: getDrawerItemType(type),

    isTypeSave: [DrawerType.SAVE_REPORT, DrawerType.SAVE_WORKSPACE].includes(type),

    isTypeReadOnly: [
      DrawerType.OPEN_REPORT_IN_WORKSPACE,
      DrawerType.OPEN_REPORT_IN_DESIGNER,
      DrawerType.REPLACE_REPORT,
      DrawerType.OPEN_WORKSPACE,
      DrawerType.APPEND_WORKSPACE,
      DrawerType.EXPORT_REPORTS,
      DrawerType.EXPORT_WORKSPACES,
      DrawerType.SELECT_DEFAULT_WORKSPACE,
    ].includes(type),

    excludeLegacy: [
      // Legacy reports cannot be opened in the designer.
      DrawerType.OPEN_REPORT_IN_DESIGNER,
      // Legacy reports & workspaces cannot be saved over.
      DrawerType.SAVE_REPORT,
      DrawerType.SAVE_WORKSPACE,
      DrawerType.REPLACE_REPORT,
      // Legacy reports & workspaces cannot be import/export targets.
      DrawerType.IMPORT_REPORT_TO_FOLDER,
      DrawerType.IMPORT_WORKSPACE_TO_FOLDER,
      DrawerType.EXPORT_REPORTS,
      DrawerType.EXPORT_WORKSPACES,
    ].includes(type),

    // If the drawer is closed, we want to derive this from the previous
    // state, so that it can't switch sides during the closing animation.
    anchor: {
      REPORT: 'right' as const,
      WORKSPACE: 'left' as const,
    }[getDrawerItemType(active ? type : previousType)],

    headerText: getHeaderText(type),
  };
};
const mapDispatchToProps = { closeFolderDrawer, setWorkspacePathAndId, updateDefaultWorkspace };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

// Destructuring props to make ESLint happy re:useEffect
const FolderDrawer: FC<Props> = ({
  type,
  active,
  closeFolderDrawer,
  setWorkspacePathAndId,
  workspacePath,
  defaultWorkspace,
  updateDefaultWorkspace,
  itemType,
  isTypeSave,
  isTypeReadOnly,
  excludeLegacy,
  anchor,
  headerText,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Node<string>>(null);
  const [errMess, setErrMess] = useState<string>();
  const [saveAsName, setSaveAsName] = useState<string>('');
  const markAndSetData = useCallback(
    (data: Node<string>) => {
      const markedData = markFolders(data);
      setData(
        excludeLegacy
          ? {
              ...markedData,
              children: markedData.children.filter(c => c.props && !c.props.isLegacy),
            }
          : markedData,
      );
    },
    [excludeLegacy],
  );

  const loadData = useCallback(
    (response?: AxiosResponse<Node<string>>, path?: string) => {
      if (response) {
        // User has just renamed or deleted something.
        if (path === workspacePath) {
          setWorkspacePathAndId(null, null);
        }
        if (path === defaultWorkspace) {
          updateDefaultWorkspace(null);
        }
        markAndSetData(response.data);
      } else {
        // Initial load
        setIsLoading(true);
        axios
          .get<Node<string>>(`${baseUrl}api/${getEntityApiPath(itemType)}`, {
            params: { forRead: isTypeReadOnly },
          })
          .then(response => {
            markAndSetData(response.data);
            setIsLoading(false);
          })
          .catch(error => {
            console.error(error);
            setErrMess(`Failed to load report list. ${error}`);
          });
      }
    },
    [
      markAndSetData,
      workspacePath,
      setWorkspacePathAndId,
      defaultWorkspace,
      updateDefaultWorkspace,
      isTypeReadOnly,
      itemType,
    ],
  );

  useEffect(() => {
    if (active) {
      // Folder drawer has just been opened. Load reports or workspaces.
      loadData();
    } else {
      // Folder drawer has just been closed.
      setIsLoading(true);
      setData(null);
      setErrMess('');
      setSaveAsName('');
    }
  }, [active, loadData]);

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor,
        onClose: () => closeFolderDrawer(),
        open: active,
        id: 'folder-drawer',
        className: {
          REPORT: 'right-drawer',
          WORKSPACE: 'left-drawer',
        }[itemType],
      }}
      id={itemType}
    >
      <DrawerHeader headerText={headerText} onXClick={() => closeFolderDrawer()} />
      {isTypeSave && (
        <TextField
          style={{ margin: '10px' }}
          label='Save As'
          variant='outlined'
          size='small'
          onChange={e => setSaveAsName(e.target.value)}
          value={saveAsName}
        />
      )}
      <SaveAsNameContext.Provider
        value={{
          saveAsName,
          setSaveAsName,
        }}
      >
        <FolderList type={type} data={{ data, isLoading, errMess }} loadData={loadData} />
        <FolderDrawerFooter type={type} data={data} />
      </SaveAsNameContext.Provider>
    </ResizableDrawerWrapper>
  );
};

const getHeaderText = (type: DrawerType): string => {
  switch (type) {
    case DrawerType.OPEN_REPORT_IN_WORKSPACE:
    case DrawerType.OPEN_REPORT_IN_DESIGNER:
      return 'Open Report';
    case DrawerType.REPLACE_REPORT:
      return 'Replace Report';
    case DrawerType.OPEN_WORKSPACE:
      return 'Open Workspace';
    case DrawerType.SAVE_REPORT:
      return 'Save Report';
    case DrawerType.SAVE_WORKSPACE:
      return 'Save Workspace';
    case DrawerType.APPEND_WORKSPACE:
      return 'Append Workspace';
    case DrawerType.IMPORT_REPORT_TO_FOLDER:
    case DrawerType.IMPORT_WORKSPACE_TO_FOLDER:
      return 'Select Folder For Import';
    case DrawerType.EXPORT_REPORTS:
      return 'Export Report(s)';
    case DrawerType.EXPORT_WORKSPACES:
      return 'Export Workspace(s)';
    case DrawerType.SELECT_DEFAULT_WORKSPACE:
      return 'Select Default Workspace';
  }
};

export default connector(FolderDrawer);
