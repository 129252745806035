import { Typography } from '@material-ui/core';
import { FC, ReactNode } from 'react';

export interface Props {
  id: string;
  header: ReactNode;
  content: ReactNode;
}

const FoliaSyntaxGuideSection: FC<Props> = ({ header, content }) => (
  <div className='folia-syntax-guide-section'>
    <Typography className='folia-syntax-guide-section-header' variant='h4' gutterBottom>
      {header}
    </Typography>
    <div className='folia-syntax-guide-section-content'>{content}</div>
  </div>
);

export default FoliaSyntaxGuideSection;
