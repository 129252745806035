export enum WAServerCategory {
  ATC = 'atc',
  PS = 'partition',
  RS = 'report-server',
  DS = 'data-server',
}

export const getCategoryLabel = (category: WAServerCategory) =>
  ({
    [WAServerCategory.ATC]: 'ATC',
    [WAServerCategory.PS]: 'Partition',
    [WAServerCategory.RS]: 'Report Server',
    [WAServerCategory.DS]: 'Data Server',
  }[category]);

export type ThreadDump = {
  address: string;
  dump: string;
  port: string;
  serviceName: string;
};

export type ServerStatus = {
  address: string;
  status: string;
  port: string;
  serviceName: string;
};

// returned by GET /iwaClusterStatus REST endpoint
export type WAClusterInfo = Partial<
  {
    [category in WAServerCategory]: WAServerInfo[];
  }
>;

export type WAServerInfo = {
  address: string;
  statusPort: number;
  serviceName: string;
  port: number;
  category: WAServerCategory;
  statusHRef: string;
  lastUpdate: number;
  online: boolean;
  stats: CommonStats & (PartitionStats | ReportServerStats);
};

export type ServerEnable = {
  address: string;
  port: number;
  enabled: boolean;
};

export type CommonStats = {
  '% Memory Used': string;
  Enabled: string;
  'JMX Port': string;
  'Max Memory (K)': string;
  'Memory Used (K)': string;
  'Peak Memory Used (K)': string;
};

export type PartitionStats = {
  '# DataSets': string;
  '# Pending Cache Messages': string;
  '# Pending Slices': string;
  '# Pending Slice Updates': string;
  '# Slices': string;
  'Portfolio timeout': string;
  Refresh: string;
};

export type ReportServerStats = {
  '# Pending': string;
  '# Pending Refreshes': string;
  '# Pending Updates': string;
  '# References': string;
  '# Reports': string;
  '# Trees': string;
  'Report timeout': string;
};

export type Entry<V = any> = [string, V];

export const compareByKey = ([key1]: Entry, [key2]: Entry) => (key1 < key2 ? -1 : 1);

export enum RefreshInterval {
  VERY_SLOW = 30,
  SLOW = 10,
  AVERAGE = 5,
  FAST = 2,
  VERY_FAST = 1,
}

export const getRefreshIntervals = () => [
  RefreshInterval.VERY_SLOW,
  RefreshInterval.SLOW,
  RefreshInterval.AVERAGE,
  RefreshInterval.FAST,
  RefreshInterval.VERY_FAST,
];

export const getLabel = (interval: RefreshInterval) =>
  ({
    [RefreshInterval.VERY_SLOW]: 'Very Slow',
    [RefreshInterval.SLOW]: 'Slow',
    [RefreshInterval.AVERAGE]: 'Average',
    [RefreshInterval.FAST]: 'Fast',
    [RefreshInterval.VERY_FAST]: 'Very Fast',
  }[interval]);
