import { DrawerHeader } from 'algo-react-dataviz';
import axios from 'axios';
import { FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { NotificationLevel } from '../../shared/constants';
import { Node } from '../../shared/dataTypes';
import { baseUrl } from '../shared/environment';
import './ad-hoc-portfolio-drawer.scss';
import AdHocPortfolioDrawerItem from './AdHocPortfolioDrawerItem';
import AdHocPortfolioUploadButton from './AdHocPortfolioUploadButton';
import DeleteAdHocPortfolioDialog from './DeleteAdHocPortfolioDialog';
import RenameAdHocPortfolioDialog from './RenameAdHocPortfolioDialog';
import ResizableDrawerWrapper from './ResizableDrawerWrapper';

interface BaseProps {
  open: boolean;
  close: () => void;
}

const mapStateToProps = (state: AppState) => ({
  selectedDateContext: state.user.selectedDateContext,
});

const mapDispatchToProps = { enqueueSnackbar };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const AdHocPortfolioDrawer: FC<Props> = props => {
  const [portfolios, setPortfolios] = useState<string[]>();
  const [itemToRename, setItemToRename] = useState<string>();
  const [itemToDelete, setItemToDelete] = useState<string>();

  const { date, id } = props.selectedDateContext || {};
  const { enqueueSnackbar } = props;
  const loadPortfolioHierarchy = useCallback(
    () =>
      axios
        .get<Node<string>[]>(`${baseUrl}api/portfolioHierarchy`, { params: { date, id } })
        .then(response => setPortfolios(response.data[1].children?.map(c => c.id)))
        .catch((error: Error) => {
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load ad hoc portfolios from server: ${error.message}`,
          );
        }),
    [date, id, enqueueSnackbar],
  );

  // Load portfolio list when drawer is opened
  const { open } = props;
  useEffect(() => {
    if (open) {
      loadPortfolioHierarchy();
    } else {
      setPortfolios(undefined);
    }
  }, [open, loadPortfolioHierarchy]);

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: props.open,
        className: 'right-drawer ad-hoc-portfolio-drawer',
        onClose: props.close,
      }}
      id='AdhocPortfolioDrawer'
    >
      <DrawerHeader headerText='Manage Ad Hoc Portfolios' onXClick={props.close} />

      <div className='item-list'>
        {portfolios?.map(p => (
          <AdHocPortfolioDrawerItem
            key={p}
            label={p}
            rename={() => setItemToRename(p)}
            delete={() => setItemToDelete(p)}
          />
        ))}
      </div>

      <RenameAdHocPortfolioDialog
        reloadPortfolios={loadPortfolioHierarchy}
        itemToRename={itemToRename}
        clearItem={() => setItemToRename(undefined)}
      />

      <DeleteAdHocPortfolioDialog
        reloadPortfolios={loadPortfolioHierarchy}
        itemToDelete={itemToDelete}
        clearItem={() => setItemToDelete(undefined)}
      />

      <div className='ad-hoc-portfolio-drawer-footer'>
        <AdHocPortfolioUploadButton reload={loadPortfolioHierarchy} />
      </div>
    </ResizableDrawerWrapper>
  );
};

export default connector(AdHocPortfolioDrawer);
