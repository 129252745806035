import { FC, useCallback } from 'react';
import {
  CodeMarkup as Code,
  FoliaSyntaxGuideSection,
  FoliaSyntaxGuideSectionProps,
  HighlightMarkup as Highlight,
  StrongMarkup as Strong,
} from './layout';

const sectionProps: FoliaSyntaxGuideSectionProps[] = [
  {
    id: 'key-expressions',
    header: (
      <span>
        <Code>key</Code> expressions
      </span>
    ),
    content: (
      <ul>
        <li>
          To refer to the value of a certain characteristic, use the keyword <Code>key[_____]</Code>
          , inserting the characteristic's name — for example,{' '}
          <Code>
            key[<Highlight>Number Of Records</Highlight>]
          </Code>
          .
        </li>
      </ul>
    ),
  },

  {
    id: 'comparisons',
    header: 'Comparisons',
    content: (
      <ul>
        <li>
          Key expressions can be compared with several different datatypes — strings, numbers, and
          booleans (<Code>true</Code>/<Code>false</Code>) — to evaluate whether a characteristic's
          value meets the criteria you specify. The following is an exhaustive list of examples for
          all types of valid comparisons.
        </li>
        <li>
          <ul>
            <li>
              <Strong>Equality</Strong> —{' '}
              <Code>
                key[Instrument Currency] <Highlight>==</Highlight> "USD"
              </Code>
            </li>
            <li>
              <Strong>Inequality</Strong> —{' '}
              <Code>
                key[Instrument Industry Sector] <Highlight>!=</Highlight> "Utilities"
              </Code>
            </li>
            <li>
              <Strong>Less than</Strong> —{' '}
              <Code>
                key[Active Kurtosis] <Highlight>&lt;</Highlight> 0
              </Code>
            </li>
            <li>
              <Strong>Greater than</Strong> —{' '}
              <Code>
                key[Stress Test Mark to Market] <Highlight>&gt;</Highlight> 1012.5
              </Code>
            </li>
            <li>
              <Strong>At most</Strong> —{' '}
              <Code>
                key[Number of Records] <Highlight>&lt;=</Highlight> 100
              </Code>
            </li>
            <li>
              <Strong>At least</Strong> —{' '}
              <Code>
                key[Position Units] <Highlight>&gt;=</Highlight> 0`
              </Code>
            </li>
          </ul>
        </li>

        <li>
          A string characteristic can be compared using a wildcard,{' '}
          <Code>
            <Highlight>*</Highlight>
          </Code>
          . For example, the query
        </li>
        <li>
          <ul>
            <li>
              <Code>
                key[Industry Sector] == "Consumer<Highlight>*</Highlight>"
              </Code>
            </li>
          </ul>
        </li>
        <li>
          matches both <Code>"Consumer, Cyclical"</Code> and <Code>"Consumer, Non-cyclical"</Code>.
        </li>

        <li>
          Additionally, a characteristic can be compared with an array of values using the{' '}
          <Code>IN</Code>
          operator and curly braces (<Code>&#123;</Code>/<Code>&#125;</Code>
          ). This comparison is satisfied if the characteristic's value matches any of the array
          items.
        </li>
        <li>
          <ul>
            <li>
              <Code>
                key[Instrument Currency] <Highlight>IN &#123;"USD", "CAD"&#125;</Highlight>
              </Code>
            </li>
          </ul>
        </li>
      </ul>
    ),
  },

  {
    id: 'logical-operators',
    header: 'Logical Operators',
    content: (
      <ul>
        <li>
          Individual comparisons can be combined with the logical operators <Code>NOT</Code>,{' '}
          <Code>AND</Code> &amp; <Code>OR</Code> to design more complicated predicates.
        </li>
        <li>
          <ul>
            <li>
              <Code>
                <Highlight>NOT</Highlight> key[Instrument Currency] IN &#123;"EUR", "GBP"&#125;
              </Code>
            </li>
            <li>
              <Code>
                key[Industry Sector] == "Utilities" <Highlight>AND</Highlight> key[Position Units]
                &gt; 1500
              </Code>
            </li>
            <li>
              <Code>
                key[Instrument Currency] != "CAD" <Highlight>OR</Highlight> key[Number of Records]
                &gt;= 75000
              </Code>
            </li>
          </ul>
        </li>

        <li>Logical operations can be grouped with parentheses to control precedence.</li>
        <li>
          <ul>
            <li style={{ lineHeight: '0.5rem' }}>
              <Code>
                <Highlight>(</Highlight>key[Number of Records] &gt;= 75000 OR key[Position Units]
                &gt; 1500
                <Highlight>)</Highlight>
              </Code>
            </li>
            <li>
              <Code>AND key[Instrument Currency] == "USD"</Code>
            </li>
          </ul>
        </li>

        <li>
          The operators{' '}
          <Code>
            <Highlight>&amp;&amp;</Highlight>
          </Code>{' '}
          and{' '}
          <Code>
            <Highlight>||</Highlight>
          </Code>{' '}
          are available as aliases for <Code>AND</Code> and <Code>OR</Code>, respectively.
        </li>
      </ul>
    ),
  },

  {
    id: 'autocomplete',
    header: 'Autocomplete',
    content: (
      <ul>
        <li>
          After typing as far as <Code>key[</Code>, an autocomplete dropdown appears suggesting
          available characteristics to complete the key expression. As you type further, the list
          will be automatically filtered to characteristics that match the text. The list is
          navigated with the <Code>↑</Code> and <Code>↓</Code> arrow keys, and you confirm the
          selection with <Code>enter</Code> or <Code>tab</Code>. The <Code>escape</Code> key closes
          autocomplete; to reopen it, press <Code>ctrl + space</Code> while inside a key
          expression's brackets.
        </li>
      </ul>
    ),
  },
];

const FoliaSyntaxGuide: FC = () => {
  const createSection = useCallback(
    (sectionProps: FoliaSyntaxGuideSectionProps) => (
      <FoliaSyntaxGuideSection key={sectionProps.id} {...sectionProps} />
    ),
    [],
  );

  return <div className='folia-syntax-guide'>{sectionProps.map(createSection)}</div>;
};

export default FoliaSyntaxGuide;
