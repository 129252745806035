import { Typography } from '@material-ui/core';
import { LIGHT_BORDER, RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { NotificationLevel } from '../../shared/constants';
import { downloadURI } from '../report/helpers/exportUtils';
import { baseUrl } from '../shared/environment';
import { ServerEnable, ServerStatus, ThreadDump, WAClusterInfo, WAServerInfo } from './model';
import './remote-console-header.scss';

interface Props {
  remoteConsoleInfo: WAClusterInfo;
  selectedServers: WAServerInfo[];
  toggleServers: (servers: ServerEnable[]) => void;
  refreshInfo: () => void;
}

const RemoteConsoleToolbar: FC<Props> = props => {
  const dispatch = useDispatch();

  const toggleServers = (toggle: boolean) => {
    props.toggleServers(
      props.selectedServers.map(cluster => ({
        port: cluster.port,
        address: cluster.address,
        enabled: toggle,
      })),
    );
  };

  return (
    <div className='remote-console-toolbar' style={{ borderBottom: LIGHT_BORDER }}>
      <Typography variant='h1' className='header-label'>
        Remote Console
      </Typography>
      <div className='remote-console-toolbar-buttons'>
        <RoundedButton
          color='primary'
          variant='contained'
          onClick={() => toggleServers(true)}
          disabled={
            !props.selectedServers.length ||
            !props.selectedServers.some(selectedServer =>
              [
                ...props.remoteConsoleInfo['partition'],
                ...props.remoteConsoleInfo['report-server'],
              ].some(
                cluster =>
                  cluster.serviceName === selectedServer.serviceName &&
                  cluster.stats?.Enabled === 'false',
              ),
            )
          }
        >
          Enable
        </RoundedButton>
        <RoundedButton
          color='secondary'
          variant='contained'
          onClick={() => toggleServers(false)}
          disabled={
            !props.selectedServers.length ||
            !props.selectedServers.some(selectedServer =>
              [
                ...props.remoteConsoleInfo['partition'],
                ...props.remoteConsoleInfo['report-server'],
              ].some(
                cluster =>
                  cluster.serviceName === selectedServer.serviceName &&
                  cluster.stats?.Enabled === 'true',
              ),
            )
          }
        >
          Disable
        </RoundedButton>
        <RoundedButton
          color='primary'
          variant='contained'
          onClick={() =>
            axios
              .post(`${baseUrl}api/requestServerGC`, {
                servers: props.selectedServers.map(cluster => ({
                  port: cluster.port,
                  address: cluster.address,
                })),
              })
              .then(() => props.refreshInfo())
              .catch(error =>
                dispatch(
                  enqueueSnackbar(
                    NotificationLevel.ERROR,
                    `Warning: Could not request GC:  ${error}`,
                  ),
                ),
              )
          }
          disabled={!props.selectedServers.length}
        >
          Request GC
        </RoundedButton>
        <RoundedButton
          color='primary'
          variant='contained'
          onClick={() =>
            axios
              .post<ThreadDump[]>(`${baseUrl}api/serverThreadDumps`, {
                servers: props.selectedServers.map(cluster => ({
                  port: cluster.port,
                  address: cluster.address,
                })),
              })
              .then(res => {
                res.data.forEach(dump => {
                  const data = new Blob([dump.dump], {
                    type: 'text/plain',
                    endings: 'native',
                  });
                  downloadURI(
                    window.URL.createObjectURL(data),
                    `${dump.serviceName}-${dump.address}-${dump.port}-dump.txt`,
                  );
                });
              })
              .catch(error =>
                dispatch(
                  enqueueSnackbar(
                    NotificationLevel.ERROR,
                    `Warning: Could not retrieve thread dump:  ${error}`,
                  ),
                ),
              )
          }
          disabled={!props.selectedServers.length}
        >
          Thread Dump
        </RoundedButton>
        <RoundedButton
          color='primary'
          variant='contained'
          onClick={() =>
            axios
              .post<ServerStatus[]>(`${baseUrl}api/serverStatus`, {
                servers: props.selectedServers.map(cluster => ({
                  port: cluster.port,
                  address: cluster.address,
                })),
              })
              .then(res => {
                res.data.forEach(status => {
                  const data = new Blob([status.status], {
                    type: 'text/plain',
                    endings: 'native',
                  });
                  downloadURI(
                    window.URL.createObjectURL(data),
                    `${status.serviceName}-${status.address}-${status.port}-status.txt`,
                  );
                });
              })
              .catch(error =>
                dispatch(
                  enqueueSnackbar(
                    NotificationLevel.ERROR,
                    `Warning: Could not retrieve thread dump:  ${error}`,
                  ),
                ),
              )
          }
          disabled={!props.selectedServers.length}
        >
          Get Status
        </RoundedButton>
      </div>
    </div>
  );
};
export default RemoteConsoleToolbar;
