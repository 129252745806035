import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import { CascadingMenuItem, CascadingMenuRoot } from 'algo-react-dataviz';
import { FC, useState } from 'react';
import { draggableIconStyles } from '../components/designer-panel/FavouriteButton';
import './overflow-icon-menu.scss';

interface Props {
  parentHover: boolean;
  items: CascadingMenuItem[];
}

const OverflowIconMenu: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const opacityClass = anchorEl
    ? 'visible'
    : props.parentHover && props.items.length > 0
    ? 'dimmed'
    : 'hidden';

  return (
    <>
      <IconButton
        className={`overflow-icon-menu ${opacityClass}`}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreVert style={draggableIconStyles} />
      </IconButton>
      <CascadingMenuRoot anchorEl={anchorEl} setAnchorEl={setAnchorEl} items={props.items} />
    </>
  );
};

export default OverflowIconMenu;
