import { TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { requestCreateGroupLike, setCreateGroupDrawerOpen } from '../../redux/adminActionCreators';
import { AppState } from '../../redux/configureStore';
import AdminDrawer from './AdminDrawer';

const mapStateToProps = (state: AppState) => ({ open: state.admin.createGroupDrawerOpen });
const mapDispatchToProps = { requestCreateGroupLike, setCreateGroupDrawerOpen };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const CreateNewGroup: FC<Props> = props => {
  const [newGroupName, setNewGroupName] = useState('');

  return (
    <AdminDrawer
      open={props.open}
      setOpen={props.setCreateGroupDrawerOpen}
      title='Create New Role'
      buttonText='Create Role'
      onApplyClick={() => props.requestCreateGroupLike(newGroupName)}
      valid={newGroupName.length > 0}
    >
      <TextField
        label='New Role Name'
        value={newGroupName}
        onChange={e => setNewGroupName(e.target.value)}
        variant='outlined'
        size='small'
        fullWidth
      />
    </AdminDrawer>
  );
};

export default connector(CreateNewGroup);
