import { FC, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import {
  closeChangeWorkspaceModal,
  confirmChangeWorkspace,
} from '../../../redux/ui/actionCreators';
import { ChangeWorkspaceModalStatus } from '../../../redux/ui/changeWorkspaceModalStatus';
import MainModal from '../MainModal';

const mapStateToProps = (state: AppState) => ({
  status: state.ui.changeWorkspaceModalStatus,
});

const mapDispatchToProps = {
  confirmChangeWorkspace,
  closeChangeWorkspaceModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const getHeaderText = (status: ChangeWorkspaceModalStatus) =>
  ({
    [ChangeWorkspaceModalStatus.CLOSE]: 'Close Workspace',
    [ChangeWorkspaceModalStatus.CREATE_NEW]: 'Create New Workspace',
    [ChangeWorkspaceModalStatus.OPEN]: 'Open Workspace',
  }[status]);

const getContent = (status: ChangeWorkspaceModalStatus): ReactNode =>
  ({
    [ChangeWorkspaceModalStatus.CLOSE]: 'Are you sure you want to close this workspace?',
    [ChangeWorkspaceModalStatus.CREATE_NEW]: (
      <>
        Opening a workspace will close the existing one.
        <br />
        Are you sure you want to do that?
      </>
    ),
    [ChangeWorkspaceModalStatus.OPEN]: (
      <>
        Opening a new workspace will close the existing one.
        <br />
        Are you sure you want to do that?
      </>
    ),
  }[status]);

const ChangeWorkspaceModal: FC<Props> = props =>
  props.status !== null && (
    <MainModal
      open={props.status !== null}
      headerText={getHeaderText(props.status)}
      onRequestSubmit={() => props.confirmChangeWorkspace()}
      onRequestClose={props.closeChangeWorkspaceModal}
    >
      {getContent(props.status)}
    </MainModal>
  );
export default connector(ChangeWorkspaceModal);
