import { Box, Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { Formik, FormikErrors } from 'formik';

import AlgoLogo from '../../assets/awa-logo.svg';
import { isOidcClientEnabled } from '../shared/environment';
import './login.scss';
export interface LoginValues {
  username: string;
  password: string;
}

interface Props {
  login: (username: string, password: string) => void;
}

const Login: FC<Props> = ({ login }) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <Formik<LoginValues>
      initialValues={{ username: '', password: '' }}
      validate={({ username, password }): FormikErrors<LoginValues> => ({
        ...(!username ? { username: 'Required' } : {}),
        ...(!password ? { password: 'Required' } : {}),
      })}
      onSubmit={(values, { setSubmitting }) => {
        login(values.username, values.password);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Container className='login'>
          <BannerContainer>
            <img
              src={AlgoLogo}
              style={{ width: '641px' }}
              alt='SS&C Algorithmics | Workspace Analyzer'
            />
            <Break />
            <CopyrightText>
              Licensed Materials – Property of SS&C Technologies, Inc.©<br></br>
              Copyright SS&C Technologies, Inc. 2012, 2022. SS&C, Algo™ and Algorithmics™ are
              trademarks or registered trademarks of SS&C. Java and all Java-based trademarks and
              logos are trademarks or registered trademarks of Oracle and/or its affiliates.
              <br></br>Other product and service names might be trademarks of SS&C or other
              companies.
            </CopyrightText>
          </BannerContainer>

          <LoginContainer>
            {isOidcClientEnabled ? (
              <div />
            ) : (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <Grid xs={12} item>
                  <Box m={1}>
                    <StyledTextField
                      type='text'
                      name='username'
                      placeholder='User name'
                      variant='outlined'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      error={errors.username && touched.username}
                      helperText={errors.username && touched.username ? errors.username : ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <PersonIcon color={'disabled'} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <p>{errors.username && touched.username && errors.username}</p> */}
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box m={1}>
                    <StyledTextField
                      type={showPass ? 'text' : 'password'}
                      name='password'
                      placeholder='Password'
                      variant='outlined'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password && touched.password}
                      helperText={errors.password && touched.password ? errors.password : ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position='end'
                            onClick={() => setShowPass(!showPass)}
                            style={{ cursor: 'pointer' }}
                            data-testid='passwordHide'
                          >
                            {showPass ? (
                              <VisibilityOff color={'disabled'} />
                            ) : (
                              <Visibility color={'disabled'} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} item>
                  <Box m={1}>
                    <Button
                      className='login-button'
                      name='login'
                      type='submit'
                      disabled={
                        isSubmitting || values.username.length === 0 || values.password.length === 0
                      }
                      color='primary'
                      variant='contained'
                    >
                      Login
                    </Button>
                  </Box>
                </Grid>
              </form>
            )}
          </LoginContainer>
        </Container>
      )}
    </Formik>
  );
};

//TODO move these styles to login.scss
const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const BannerContainer = styled(Container)`
  background: linear-gradient(to right, #006ca3 0%, #225165 100%);
  width: 60%;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
`;
const LoginContainer = styled(Container)`
  background-color: #f7f5f7;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledTextField = styled(TextField)`
  background-color: #ffffff;
  border-radius: 6px;
  width: 300px;
`;

const Break = styled.hr`
  background-color: #ffffff;
  width: 90%;
`;

const CopyrightText = styled.p`
  color: #ffffff;
  font-size: 1em;
  width: 88%;
  margin: 0 auto;
  padding: 25px;
`;

export default Login;
