import { FC, useEffect, useState } from 'react';
import { CartesianGrid, XAxis, YAxis, Line, Tooltip, Legend, LineChart, Label } from 'recharts';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { paletteC50 as colors } from '../shared/colorPalettes';
import { XYChartType } from '../shared/constants';
import { DataConverters } from '../shared/dataConverters';
import { useChartOpacity } from './helpers/chartingHelpers';

function CustomTooltip(props: any) {
  const { active } = props;

  if (active) {
    const { payload } = props;
    return (
      <div style={{ background: 'white' }}>
        {payload[0].payload[props.labelColumnName] && (
          <div>{payload[0].payload[props.labelColumnName]}</div>
        )}
        {payload.map(p => (
          <div key={`${p.name}`}>{`${p.name} : ${p.payload[`${p.dataKey}Formatted`]}`}</div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

const XYLineChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.XY_LINE_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const isInverted = props.isInverted;
  const headers = reportConvertedData.headers;
  const lineChartDataSeries: any[] = reportConvertedData.rows;

  const linePlots = lineChartDataSeries.map((series, index) => (
    // We don't really turn off animation in the <Line> but it fixes an issue where dots don't get drawn on rerender when they should.
    // See https://github.com/recharts/recharts/issues/804

    <Line
      dataKey={series.name}
      key={series.name + '-' + index}
      type={
        props.XYChartType === XYChartType.XY_SPLINE_LINE ||
        props.XYChartType === XYChartType.XY_SPLINE_LINE_ONLY
          ? 'monotone'
          : 'linear'
      }
      dot={
        props.XYChartType === XYChartType.XY_LINE_ONLY ||
        props.XYChartType === XYChartType.XY_SPLINE_LINE_ONLY
          ? false
          : true
      }
      isAnimationActive={false}
      name={series.name}
      data={series.data}
      yAxisId={index}
      fill={colors[index]}
      stroke={colors[index]}
      strokeOpacity={opacity[series.name] || 1}
      fillOpacity={opacity[series.name] || 1}
      strokeWidth={props.XYChartType === XYChartType.XY_DOTS_ONLY ? '0px' : '2px'}
    />
  ));

  const lineChartDataSeriesAxis = lineChartDataSeries.map((series, index) =>
    isInverted ? (
      <XAxis
        xAxisId={index}
        key={`${series.name}-${index}-y`}
        type='number'
        dataKey={`${headers[index + 1]}`}
        domain={['auto', 'auto']}
        orientation={index % 2 === 0 ? 'bottom' : 'top'}
        name={`${headers[index + 1]}`}
      >
        <Label value={`${headers[index + 1]}`} offset={12} position='bottom' />
      </XAxis>
    ) : (
      <YAxis
        yAxisId={index}
        key={`${series.name}-${index}-y`}
        type='number'
        dataKey={`${headers[index + 1]}`}
        domain={['auto', 'auto']}
        orientation={index % 2 === 0 ? 'left' : 'right'}
        name={`${headers[index + 1]}`}
      >
        <Label value={`${headers[index + 1]}`} offset={12} position='left' angle={90} />
      </YAxis>
    ),
  );

  return (
    <LineChart
      width={props.width}
      height={props.height}
      margin={{
        left: 50,
        bottom: 50,
      }}
      layout={isInverted ? 'vertical' : 'horizontal'}
      onClick={event =>
        event &&
        props.onElementClick(
          event.activePayload[0].payload[`${event.activePayload[0].name}rowcol`],
        )(event)
      }
    >
      <Legend
        align='left'
        onMouseEnter={onChartEnter(reportConvertedData.headers)}
        onMouseLeave={onChartLeave}
      />

      <CartesianGrid />

      {isInverted ? (
        <YAxis type='number' dataKey={`${headers[0]}`} reversed name={`${headers[0]}`}>
          <Label value={`${headers[0]}`} offset={0} position='left' angle={90} />
        </YAxis>
      ) : (
        <XAxis type='number' dataKey={`${headers[0]}`} name={`${headers[0]}`}>
          <Label value={`${headers[0]}`} offset={0} position='bottom' />
        </XAxis>
      )}
      {lineChartDataSeriesAxis}

      <Tooltip
        cursor={{ strokeDasharray: '3 3' }}
        content={<CustomTooltip labelColumnName={reportConvertedData.groupingHeader.name} />}
      />

      {linePlots}
    </LineChart>
  );
};

export default XYLineChartViz;
