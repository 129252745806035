import { DrawerFooter, DrawerHeader, RoundedButton } from 'algo-react-dataviz';
import { FC } from 'react';
import ResizableDrawerWrapper from '../drawers/ResizableDrawerWrapper';

interface Props {
  open?: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  buttonText: string;
  onApplyClick: () => void;
  valid: boolean;
}

const AdminDrawer: FC<Props> = props => (
  <>
    <RoundedButton variant='contained' color='primary' onClick={() => props.setOpen(true)}>
      {props.title}
    </RoundedButton>
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: props.open,
        className: 'right-drawer',
      }}
      id='AdminDrawer'
    >
      <DrawerHeader headerText={props.title} onXClick={() => props.setOpen(false)} />
      <div className='admin-drawer-content'>{props.children}</div>
      <DrawerFooter
        applyButtonText={props.buttonText}
        onApplyClick={() => {
          props.onApplyClick();
          props.setOpen(false);
        }}
        onCancelClick={() => props.setOpen(false)}
        applyDisabled={!props.valid}
      />
    </ResizableDrawerWrapper>
  </>
);

export default AdminDrawer;
