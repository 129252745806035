import { Dialog, DialogContent, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useGetRequestClient from '../../../api/useGetRequestClient';
import useAScriptState from '../../../lang/ascript/useAScriptState';
import {
  AScriptEditorLanguage,
  aScriptEditorLanguages as editorLanguages,
  DEFAULT_ASCRIPT_EDITOR_LANGUAGE,
  EditorLanguage,
  getLabel,
} from '../../../lang/editorLanguage';
import { getInitialContents, isEditorOpen } from '../../../redux/aScriptEditor/selectors';
import CopyToClipboardButton from '../../../shared/CopyToClipboardButton';
import useClipboard from '../../../shared/useClipboard';
import { baseUrl } from '../../shared/environment';
import UnfoldAccordion from '../../shared/UnfoldAccordion';
import CodeEditor from '../CodeEditor';
import './ascript-editor-modal.scss';
import AscriptEditorFooter from './AscriptEditorFooter';
import AscriptEditorHeader from './AscriptEditorHeader';
import CloseConfirmationModal from './CloseConfirmationModal';

export enum CompileStatus {
  COMPILING,
  IDLE,
}

const AscriptEditorModal: FC = () => {
  const open = useSelector(isEditorOpen);
  const [currentLanguage, setCurrentLanguage] = useState<AScriptEditorLanguage>(
    DEFAULT_ASCRIPT_EDITOR_LANGUAGE,
  );
  const initialContents = useSelector(getInitialContents);
  const clipboard = useClipboard();

  const [compileStatus, setCompileStatus] = useState(CompileStatus.IDLE);
  const [closeModalOpen, setCloseModalOpen] = useState(false);

  // valid java is tracked in order to erase ascript when the ascript is invalid
  const [validJava, setValidJava] = useState('');

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorExpanded, setErrorExpanded] = useState(true);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { editorModel, validation } = useAScriptState();

  const { data: hasEditPermission, getData } = useGetRequestClient<boolean>({
    url: `${baseUrl}api/canWriteaScript`,
  });

  useEffect(() => {
    // reset error/success state when re-opening editor
    if (open) {
      getData();
      setErrorMessage(null);
      setSuccessMessage(null);
    }
  }, [open, getData]);

  // Populate editors with initial contents, if any exists
  // (e.g. opening existing code)
  const allModelsExist = editorModel.isInitialized() && editorModel.api.doModelsExist();
  useEffect(() => {
    if (!allModelsExist) {
      return;
    }

    setValidJava(initialContents[EditorLanguage.JAVA]);
    editorModel.api.setEditorContents(initialContents);
  }, [initialContents, allModelsExist, editorModel]);

  const isCompiling = compileStatus === CompileStatus.COMPILING;

  return (
    <Dialog fullWidth maxWidth='md' {...{ open }} onClose={() => setCloseModalOpen(true)}>
      <AscriptEditorHeader {...{ hasEditPermission, isCompiling }} />
      <DialogContent className='editor-dialog-content'>
        <div className='editor-top-bar'>
          <div className='editor-language-tabs'>
            <Tabs
              value={currentLanguage}
              onChange={(_event, language: AScriptEditorLanguage) => setCurrentLanguage(language)}
              indicatorColor='primary'
              textColor='primary'
            >
              {editorLanguages.map(language => (
                <Tab
                  className='editor-tab'
                  key={language}
                  value={language}
                  label={getLabel(language)}
                  disabled={isCompiling}
                />
              ))}
            </Tabs>
          </div>

          <CopyToClipboardButton
            {...{ clipboard }}
            getText={() => editorModel.api.getValue(currentLanguage) ?? ''}
            buttonProps={{ className: 'editor-copy-button' }}
          />
        </div>

        <CodeEditor<AScriptEditorLanguage>
          {...{
            editorModel,
            initialContents,
            currentLanguage,
            hasEditPermission,
          }}
          validation={{
            [EditorLanguage.ASCRIPT]: validation,
          }}
          editorOptions={{
            wordWrap: currentLanguage === EditorLanguage.JAVA ? 'on' : 'off',
          }}
          height='60vh'
        />

        <div className='editor-popups'>
          {errorMessage && (
            <UnfoldAccordion
              expanded={errorExpanded}
              setExpanded={expanded => setErrorExpanded(expanded)}
              summaryChildren={
                <div className='editor-error-summary'>
                  <WarningIcon color='secondary' />
                  <Typography className='editor-popup-text' variant='h2' color='secondary'>
                    Compile Error
                  </Typography>
                </div>
              }
            >
              <Typography className='editor-popup-text' variant='h2' color='secondary'>
                {errorMessage}
              </Typography>
            </UnfoldAccordion>
          )}

          {successMessage && (
            <div className='editor-success-container'>
              <div className='editor-success-message'>
                <CheckCircleIcon color='primary' />
                <Typography className='editor-popup-text' variant='h2' color='primary'>
                  {successMessage}
                </Typography>
              </div>
              <IconButton onClick={() => setSuccessMessage(null)} color='primary'>
                <CancelIcon />
              </IconButton>
            </div>
          )}
        </div>
      </DialogContent>

      <AscriptEditorFooter
        {...{
          isCompiling,
          currentLanguage,
          setCloseModalOpen,
          setSuccessMessage,
          setErrorMessage,
          setCompileStatus,
          setValidJava,
          validJava,
          editorModel,
          hasEditPermission,
        }}
      />
      <CloseConfirmationModal open={closeModalOpen} setOpen={open => setCloseModalOpen(open)} />
    </Dialog>
  );
};

export default AscriptEditorModal;
