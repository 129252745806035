import { IconButton, InputAdornment, Popover, TextField, Typography } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import { FC } from 'react';
import './proxy-selector.scss';

interface Props {
  label: string;
  placeholder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClear?: () => void;
  anchorEl: null | HTMLElement;
  setAnchorEl: (anchorEl: null | HTMLElement) => void;
  disabled?: boolean;
}

const ProxySelector: FC<Props> = props => (
  <div className='proxy-selector'>
    <Typography variant='h3'>{props.label}</Typography>
    <TextField
      value={props.value}
      onChange={props.onChange}
      fullWidth
      size='small'
      variant='outlined'
      onClick={e => !props.anchorEl && props.setAnchorEl(e.currentTarget)}
      placeholder={props.placeholder}
      disabled={props.disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search fontSize='small' />
          </InputAdornment>
        ),
        endAdornment: props.onClear && (
          <InputAdornment position='end'>
            <IconButton onClick={props.onClear}>
              <Clear fontSize='small' />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    <Popover
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClose={() => props.setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      disableAutoFocus
      disableEnforceFocus
      className='proxy-selector-popover'
      PaperProps={{
        // prevents blurring on textfield
        onMouseDown: e => e.preventDefault(),
        onClick: e => e.preventDefault(),
        onChange: e => e.preventDefault(),
      }}
    >
      {props.children}
    </Popover>
  </div>
);

export default ProxySelector;
