import { chainComparators, Comparator, compareStrings } from './comparators';
import { Characteristic } from './dataTypes';
import { isGroupingLayer } from './utils';

export type CharComparator = Comparator<Characteristic>;

const DEFAULT_NAME = 'Untitled';

const compareByIsGroupingLayer: CharComparator = (a, b) =>
  isGroupingLayer(a) && isGroupingLayer(b)
    ? a.charId - b.charId
    : isGroupingLayer(a) || isGroupingLayer(b)
    ? isGroupingLayer(a)
      ? -1
      : 1
    : 0;

const compareByName: CharComparator = (a, b) =>
  compareStrings((a.name ?? DEFAULT_NAME).toLowerCase(), (b.name ?? DEFAULT_NAME).toLowerCase());

const compareByModifier: CharComparator = (a, b) => a.modifier - b.modifier;

export const processSearchString = (value?: string | null) =>
  (value ?? DEFAULT_NAME).toLowerCase().replace(/\s/g, '');

const createStartsWithComparator = (searchTerm: string): CharComparator => (a, b) => {
  const [aStartsWith, bStartsWith] = [a, b].map(({ name }) =>
    processSearchString(name ?? DEFAULT_NAME).startsWith(processSearchString(searchTerm)),
  );

  return aStartsWith === bStartsWith ? 0 : aStartsWith ? -1 : 1;
};

export const compareChars = chainComparators(
  compareByIsGroupingLayer,
  compareByName,
  compareByModifier,
);

export const createCharSearchComparator = (searchTerm?: string) =>
  chainComparators(
    ...[...(searchTerm ? [createStartsWithComparator(searchTerm)] : []), compareChars],
  );
