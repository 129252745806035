import { Sandbox } from '../../shared/dataTypes';
import { BookBenchmark, Mode } from './state';

export enum PositionDrawerActionType {
  OPEN = 'positionDrawer/open',
  CLOSE = 'positionDrawer/close',
}

export const openPositionDrawer = (
  sandbox: Sandbox,
  sequenceId: number,
  mode: Mode,
  positionToEdit?: BookBenchmark,
) =>
  ({
    type: PositionDrawerActionType.OPEN,
    payload: { sandbox, sequenceId, mode, positionToEdit },
  } as const);

export const closePositionDrawer = () => ({ type: PositionDrawerActionType.CLOSE } as const);

export type PositionDrawerAction =
  | ReturnType<typeof openPositionDrawer>
  | ReturnType<typeof closePositionDrawer>;
