import { Badge, Fab } from '@material-ui/core';
import { Check as CheckIcon, SvgIconComponent } from '@material-ui/icons';
import { FC } from 'react';
import MultilineTooltip from '../../shared/MultilineTooltip';

export interface Props {
  tooltipContent: string[];
  icon?: SvgIconComponent;
  onClick: () => void;
  disabled?: boolean;
  showBadge?: boolean;
}

const FoliaEditorFab: FC<Props> = ({
  tooltipContent,
  icon: Icon = CheckIcon,
  onClick,
  disabled = false,
  showBadge = false,
}) => (
  <div className='folia-editor-fab'>
    <Badge
      className='folia-editor-fab-badge'
      color='secondary'
      variant='dot'
      overlap='circular'
      invisible={!showBadge}
    >
      <MultilineTooltip lines={tooltipContent} placement='top-end' arrow>
        <span>
          <Fab
            size='small'
            color={disabled ? 'default' : 'primary'}
            {...{
              onClick,
              disabled,
            }}
          >
            <Icon />
          </Fab>
        </span>
      </MultilineTooltip>
    </Badge>
  </div>
);

export default FoliaEditorFab;
