import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import { FC, useState } from 'react';

interface Props {
  applyButtonText: string;

  onDeleteClick: () => void;
  onCancelClick: () => void;
  onBackClick: () => void;
  onApplyClick: () => void;

  deleteDisabled?: boolean;
  cancelDisabled?: boolean;
  backDisabled?: boolean;
  applyDisabled?: boolean;
}

const PositionDrawerFooter: FC<Props> = props => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <div className='footer'>
        {props.onDeleteClick && (
          <RoundedButton
            onClick={() => setDialogOpen(true)}
            color='secondary'
            disabled={props.deleteDisabled}
          >
            Delete All
          </RoundedButton>
        )}

        <RoundedButton
          onClick={props.onCancelClick}
          variant='outlined'
          color='primary'
          disabled={props.cancelDisabled}
        >
          Cancel
        </RoundedButton>

        <RoundedButton
          onClick={props.onBackClick}
          variant='outlined'
          color='primary'
          disabled={props.backDisabled}
        >
          Back
        </RoundedButton>

        <RoundedButton
          variant='contained'
          color='primary'
          onClick={props.onApplyClick}
          disabled={props.applyDisabled}
        >
          {props.applyButtonText}
        </RoundedButton>
      </div>
      <Dialog open={dialogOpen}>
        <DialogTitle>Delete All</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete all?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <RoundedButton variant='outlined' onClick={() => setDialogOpen(false)}>
            Cancel
          </RoundedButton>
          <RoundedButton
            variant='contained'
            color='primary'
            onClick={() => {
              props.onDeleteClick();
              setDialogOpen(false);
            }}
          >
            OK
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PositionDrawerFooter;
