import { Tooltip, TooltipProps } from '@material-ui/core';
import { FC } from 'react';

export interface Props extends Omit<TooltipProps, 'title'> {
  lines: string[];
}

const MultilineTooltip: FC<Props> = ({ lines, ...restProps }) => (
  <Tooltip
    {...restProps}
    title={
      lines.length === 0 ? '' : <div style={{ whiteSpace: 'pre-line' }}>{lines.join('\n')}</div>
    }
  />
);

export default MultilineTooltip;
