import { CascadingMenuItem, NodeType } from 'algo-react-dataviz';
import { Sandbox, Sandboxes } from '../../shared/dataTypes';

interface SandboxMenuItemsProps {
  menuId: string;
  sandboxes: Sandboxes;
  selectedSandbox: Sandbox | null;
  onCreate: () => void;
  onSelect: (sandboxPath: string) => void;
  rootLabel?: string;
  disabled?: boolean;
}

export const createSandboxMenuItem = ({
  menuId,
  sandboxes,
  selectedSandbox,
  onCreate,
  onSelect,
  rootLabel,
  disabled = false,
}: SandboxMenuItemsProps): CascadingMenuItem => {
  const baseMenuItem: Omit<CascadingMenuItem, 'onClick'> = {
    id: `sb-${menuId}-create`,
    text: rootLabel ?? sandboxes?.public.name ?? 'Sandboxes',
  };

  return disabled
    ? {
        ...baseMenuItem,
        disabled,
        onClick: () => {},
      }
    : {
        id: `sb-${menuId}`,
        text: rootLabel ?? sandboxes.public.name,
        children: [
          {
            id: `sb-${menuId}-create`,
            text: 'Create New',
            onClick: onCreate,
            disabled,
          },
          {
            id: `sb-${menuId}-private`,
            text: 'Private',
            children: !sandboxes.private.length
              ? [{ id: 'sb-no_private_sandboxes', text: 'None Defined', onClick: () => {} }]
              : createPrivateSandboxMenuItems({
                  menuId,
                  sandboxPaths: sandboxes.private,
                  selectedSandboxPath: selectedSandbox?.path ?? null,
                  onClick: onSelect,
                  disabled,
                }),
          },
          {
            id: `sb-${menuId}-public`,
            text: 'Public',
            children: !sandboxes.public.children?.length
              ? [{ id: 'sb-no_public_sandboxes', text: 'None Defined', onClick: () => {} }]
              : sandboxes.public.children.map(child =>
                  createPublicSandboxMenuItems({
                    menuId,
                    tree: child,
                    selectedSandboxPath: selectedSandbox?.path ?? null,
                    onClick: onSelect,
                    disabled,
                  }),
                ),
          },
        ],
      };
};

interface PrivateMenuItemsProps {
  menuId: string;
  sandboxPaths: string[];
  selectedSandboxPath: string | null;
  onClick: (sandboxPath: string) => void;
  disabled?: boolean;
}

const createPrivateSandboxMenuItems = ({
  menuId,
  sandboxPaths,
  selectedSandboxPath,
  onClick,
  disabled = false,
}: PrivateMenuItemsProps): CascadingMenuItem[] =>
  sandboxPaths.map(path => ({
    id: `sb-${menuId}-private-${path}`,
    text: path,
    onClick: () => onClick(path),
    disabled,
    selected: path === selectedSandboxPath,
  }));

interface PublicMenuItemsProps {
  menuId: string;
  tree: Sandboxes['public'];
  selectedSandboxPath: string | null;
  onClick: (sandboxPath: string) => void;
  disabled?: boolean;
}

const createPublicSandboxMenuItems = ({
  menuId,
  tree,
  selectedSandboxPath,
  onClick,
  disabled = false,
}: PublicMenuItemsProps): CascadingMenuItem => {
  const baseMenuItem: Omit<CascadingMenuItem, 'children' | 'onClick'> = {
    id: `sb-${menuId}-public-${tree.id}`,
    text: decodeURIComponent(tree.name),
    disabled,
  };

  switch (tree.type) {
    case NodeType.FOLDER: {
      return {
        ...baseMenuItem,
        children: tree.children.map(child =>
          createPublicSandboxMenuItems({
            menuId,
            tree: child,
            selectedSandboxPath,
            onClick,
            disabled,
          }),
        ),
      };
    }

    case NodeType.SANDBOX: {
      const path = tree.props?.path;

      return {
        ...baseMenuItem,
        onClick: () => onClick(path),
        selected: path === selectedSandboxPath,
      };
    }
  }
};
