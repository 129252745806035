import { EditorLanguage } from '../../lang/editorLanguage';
import StartColumnCollector from '../errors/StartColumnCollector';
import LanguageService from '../services/LanguageService';
import { AScriptLexer } from './antlr/AScriptLexer';
import { AScriptParser } from './antlr/AScriptParser';
import AScriptState from './AScriptState';
import AScriptToken from './AScriptToken';
import AScriptTokenFactory from './AScriptTokenFactory';
import AScriptTokensProvider from './AScriptTokensProvider';

class AScriptLanguageService extends LanguageService<
  EditorLanguage.ASCRIPT,
  AScriptState,
  AScriptLexer,
  AScriptParser
> {
  static readonly LANGUAGE_ID = EditorLanguage.ASCRIPT;

  constructor(debugLogging = false) {
    super(
      AScriptLanguageService.LANGUAGE_ID,
      AScriptParser.VOCABULARY,
      AScriptState,
      new AScriptTokenFactory(),
      AScriptTokensProvider,
      AScriptLexer,
      AScriptParser,
      debugLogging,
    );
  }

  tokenizeLine = (input: string, state: AScriptState) => {
    const errorCollector = new StartColumnCollector();
    const lexer = this.createLexer(input);
    lexer.addErrorListener(errorCollector);

    const setMode = (mode: string) => {
      state.setMode(mode);
      lexer.mode(state.getModeIndex());
    };

    setMode(state.getMode());

    const lexedTokens: AScriptToken[] = [];
    while (true) {
      const token = this.tokenFactory.fromAntlrToken(lexer.nextToken());
      if (this.debugLogging) {
        console.log(`token = ${token}`);
      }

      if (token === null || token.ruleName === 'EOF') {
        break;
      }

      // We consolidate `R_BLOCK_CHAR` tokens manually due to lexer
      // quirks and to avoid doing lookahead on every character.
      // Currently does not preserve `token.text` for R code.
      if (
        token.ruleName !== 'R_BLOCK_CHAR' ||
        lexedTokens[lexedTokens.length - 1]?.ruleName !== 'R_BLOCK_CHAR'
      ) {
        lexedTokens.push(token);
      }

      // Handle ANTLR lexer modes
      switch (token.ruleName) {
        case 'KW_IMPORT': {
          setMode('IMPORT');
          break;
        }

        case 'KW_R_BLOCK_BEGIN': {
          setMode('R_BLOCK');
          break;
        }

        case 'IMPORT_SEMICOLON':
        case 'KW_R_BLOCK_END': {
          setMode('DEFAULT_MODE');
          break;
        }

        default: {
          break;
        }
      }
    }

    const tokens = [
      ...lexedTokens,
      ...errorCollector.getErrors().map(this.tokenFactory.createErrorToken),
    ].sort((a, b) => a.startIndex - b.startIndex);

    return tokens;
  };
}

export default AScriptLanguageService;
