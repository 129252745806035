import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';
import MainModal from '../components/main/MainModal';

const WorkspaceErrorBoundary: FC<FallbackProps> = props => (
  <MainModal
    open={true}
    headerText='Workspace Error'
    onRequestSubmit={props.resetErrorBoundary}
    confirmButtonText='OK'
  >
    Something went wrong rendering this workspace: {props.error.message}
  </MainModal>
);

export default WorkspaceErrorBoundary;
