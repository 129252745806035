import { Tab, Tabs } from '@material-ui/core';
import { DrawerFooter } from 'algo-react-dataviz';
import axios from 'axios';
import { Formik } from 'formik';
import { FC, ReactNode, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EntityTypes } from '../../../model/metadata/entityTypes';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { setEntityTypes } from '../../../redux/metadata/actions';
import { CharacteristicDrawerType, NotificationLevel } from '../../../shared/constants';
import { baseUrl } from '../../shared/environment';
import { CharacteristicDrawerForm } from './characteristicDrawerForm';
import { CharacteristicDrawerStatus } from './characteristicDrawerFormHelpers';
import CharacteristicDrawerTabContent from './CharacteristicDrawerTabContent';
import { CharacteristicDrawerTab, getLabel } from './characteristicDrawerTabs';

interface BaseProps {
  closeDrawer: () => void;
  onSubmit: (values: CharacteristicDrawerForm) => void;
  initialValues: CharacteristicDrawerForm;
  characteristicDrawerType: CharacteristicDrawerType;
  canWrite: boolean;
  children?: ReactNode;
}

const mapDispatchToProps = { enqueueSnackbar, setEntityTypes };

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const CharacteristicDrawerContent: FC<Props> = props => {
  const [tab, setTab] = useState(CharacteristicDrawerTab.PROPERTIES);
  const { enqueueSnackbar, setEntityTypes } = props;
  useEffect(() => {
    axios
      .get<EntityTypes>(`${baseUrl}api/entityTypes`)
      .then(res => setEntityTypes(res.data))
      .catch(error =>
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve entity types: ${error}`,
        ),
      );
  }, [enqueueSnackbar, setEntityTypes]);

  return (
    <>
      <Tabs
        value={tab}
        variant='fullWidth'
        onChange={(_e, newValue) => setTab(newValue)}
        indicatorColor='primary'
      >
        {[
          CharacteristicDrawerTab.PROPERTIES,
          CharacteristicDrawerTab.RISK_COMPUTATION,
          CharacteristicDrawerTab.DESCRIPTION,
        ].map(tab => (
          <Tab value={tab} key={tab} label={getLabel(tab)} />
        ))}
      </Tabs>

      <Formik<CharacteristicDrawerForm>
        initialValues={props.initialValues}
        enableReinitialize // Reset the form when initialValues changes
        onSubmit={props.onSubmit}
      >
        {formProps => (
          <form className='form'>
            <div className='tab-content'>
              <CharacteristicDrawerTabContent
                currentTab={tab}
                {...{ formProps }}
                characteristicDrawerType={props.characteristicDrawerType}
              />
            </div>
            <DrawerFooter
              applyButtonText='Apply'
              onApplyClick={formProps.handleSubmit}
              onCancelClick={props.closeDrawer}
              applyDisabled={
                !props.canWrite ||
                !formProps.isValid ||
                formProps.isSubmitting ||
                !formProps.values.valueKey.length ||
                [CharacteristicDrawerStatus.VALIDATING, CharacteristicDrawerStatus.TYPING].includes(
                  formProps.status,
                )
              }
              cancelDisabled={formProps.isSubmitting}
            >
              {props.children}
            </DrawerFooter>
          </form>
        )}
      </Formik>
    </>
  );
};

export default connector(CharacteristicDrawerContent);
