import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import InfoPanelField from './InfoPanelField';

const mapStateToProps = (state: AppState) => ({ selectedGroup: state.admin.selectedGroup });

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector>;

const GroupInfoPanel: FC<Props> = props => (
  <div style={{ flex: 1, padding: '16px' }}>
    <InfoPanelField label='Role Name' value={props.selectedGroup || ''} />
  </div>
);

export default connector(GroupInfoPanel);
