import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../redux/ActionCreators';
import { NotificationLevel } from '../shared/constants';
import {
  ApiData,
  ApiRequestStatus,
  createDefaultApiData,
  createFailureApiData,
  createRequestApiData,
  createSuccessApiData,
} from './apiData';

// Ensure function parameters are memoized properly to
// prevent unnecessary re-evaluations of `useEffect` and
// `useCallback` — memoize them with `useCallback` or
// define them outside the component calling this hook.
export interface GetRequestParameters<T, U = T> {
  url: string;
  deferRequest?: boolean;
  transform?: (data: T) => U;
  dispatchErrorMessage?: boolean;
  memoize?: boolean;

  errorTemplate?: (message: string) => string;
  onSuccess?: (data: U) => void;
  onFailure?: (error: Error) => void;
}

export interface GetRequestClient<T> {
  data: T | null;
  apiData: ApiData;
  getData: () => void;
  clearData: () => void;
}

const IDENTITY_TRANSFORM = <T, U = T>(data: T) => (data as unknown) as U;
const DEFAULT_ERROR_TEMPLATE = (message: string) => `API error: ${message}`;
const useGetRequestClient = <T, U = T>({
  url,
  deferRequest = false,
  transform = IDENTITY_TRANSFORM,
  dispatchErrorMessage = true,
  memoize = false,
  errorTemplate = DEFAULT_ERROR_TEMPLATE,
  onSuccess,
  onFailure,
}: GetRequestParameters<T, U>): GetRequestClient<U> => {
  const dispatch = useDispatch();
  const [data, setData] = useState<U | null>(null);
  const [apiData, setApiData] = useState(createDefaultApiData());

  const getData = useCallback(() => {
    setApiData(createRequestApiData());

    const requestTime = Date.now();
    axios
      .get<T>(url)
      .then(({ data }) => {
        setApiData(createSuccessApiData(Date.now() - requestTime));

        const transformedData = transform(data);
        setData(transformedData);
        onSuccess?.(transformedData);
      })
      .catch((error: Error) => {
        setApiData(createFailureApiData(Date.now() - requestTime, error));
        if (dispatchErrorMessage) {
          dispatch(enqueueSnackbar(NotificationLevel.ERROR, errorTemplate(error.message)));
        }
        onFailure?.(error);
      });
  }, [dispatch, url, transform, dispatchErrorMessage, errorTemplate, onSuccess, onFailure]);

  const clearData = useCallback(() => {
    setData(null);
    setApiData(createDefaultApiData());
  }, []);

  useEffect(() => {
    if (!deferRequest && apiData.status === ApiRequestStatus.PRISTINE) {
      getData();
    }
  }, [deferRequest, apiData.status, getData]);

  // Reset data state on unmount, unless told to memoize
  useEffect(
    () => () => {
      if (!memoize) {
        clearData();
      }
    },
    [memoize, clearData],
  );

  return {
    data,
    apiData,
    getData,
    clearData,
  };
};

export default useGetRequestClient;
