import Axios from 'axios';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { deleteGroups, selectGroup } from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';
import { NotificationLevel } from '../../../shared/constants';
import { AdminTableItem } from '../../../shared/dataTypes';
import { baseUrl } from '../../shared/environment';
import AdminTable from '../AdminTable';

interface Data extends AdminTableItem {
  groupName: string;
  members: number;
}

const columns: { key: keyof Data; displayName: string; id?: boolean }[] = [
  { key: 'groupName', displayName: 'Role Name', id: true },
  { key: 'members', displayName: 'Members' },
];

const mapStateToProps = (state: AppState) => ({
  selectedGroup: state.admin.selectedGroup,
  users: state.admin.users,
  groups: state.admin.groups,
});
const mapDispatchToProps = { selectGroup, deleteGroups, enqueueSnackbar };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const GroupsPanel: FC<Props> = props => (
  <AdminTable
    columns={columns}
    style={{ gridArea: 'groups' }}
    className='right-border-table'
    data={props.groups.map<Data>(group => ({
      groupName: group,
      members: props.users.flatMap(user => user.groups).filter(value => value === group).length,
    }))}
    selectedItem={props.selectedGroup}
    onSelect={props.selectGroup}
    deleteDialogType='Role'
    onDelete={selectedGroups => {
      Axios.delete(`${baseUrl}api/userGroup`, {
        params: {
          groupNames: selectedGroups.join(','),
        },
      })
        .then(() => props.deleteGroups(selectedGroups))
        .catch(error =>
          props.enqueueSnackbar(NotificationLevel.ERROR, `Failed to delete role: ${error.message}`),
        );
    }}
  />
);

export default connector(GroupsPanel);
