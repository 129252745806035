import axios from 'axios';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GroupingStrategyType } from '../../../model/custom-grouping/groupingStrategy';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppState, useAppSelector } from '../../../redux/configureStore';
import { NotificationLevel } from '../../../shared/constants';
import { Characteristic } from '../../../shared/dataTypes';
import { resolveDefaultReportDefinition } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import useCustomGroupingState from '../context/useCustomGroupingState';
import CharacteristicStrategyDesigner from './characteristic-strategy/CharacteristicStrategyDesigner';
import PredicateStrategyDesigner from './predicate-strategy/PredicateStrategyDesigner';

const GroupingStrategyDesignerContent: FC = () => {
  const { designerStrategyType, hasDesignerViewState } = useCustomGroupingState();
  const [allChars, setAllChars] = useState<Characteristic[]>([]);
  const dispatch = useDispatch();

  const reportingType = useAppSelector(
    (state: AppState) =>
      state.report.reportDefinition[-1]?.reportableType ??
      resolveDefaultReportDefinition(state).reportableType,
  );

  useEffect(() => {
    axios
      .get<Characteristic[]>(
        `${baseUrl}api/availableCharacteristics?reportableType=${reportingType}`,
      )
      .then(response => setAllChars(response.data))
      .catch((error: Error) =>
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load characteristics from server: ${error.message}`,
          ),
        ),
      );
  }, [reportingType, dispatch]);

  const shouldRenderContent = useMemo(() => designerStrategyType !== null && hasDesignerViewState, [
    designerStrategyType,
    hasDesignerViewState,
  ]);

  const content = useMemo(() => {
    if (!shouldRenderContent) {
      return <div className='gsd-content' />;
    }

    switch (designerStrategyType) {
      case GroupingStrategyType.PREDICATE:
        return <PredicateStrategyDesigner />;
      case GroupingStrategyType.CHARACTERISTIC:
        return <CharacteristicStrategyDesigner {...{ allChars }} />;
      case GroupingStrategyType.REMAINDER:
        return <div className='gsd-content gsd-remainder' />;
    }
  }, [shouldRenderContent, designerStrategyType, allChars]);

  return content;
};

export default GroupingStrategyDesignerContent;
