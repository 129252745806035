import {
  CreateNewFolderOutlined as CreateNewFolderIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { FC, useMemo } from 'react';
import { getLabel, groupingStrategyTypes } from '../../../model/custom-grouping/groupingStrategy';
import { useAppSelector } from '../../../redux/configureStore';
import { getDrawerStatus } from '../../../redux/custom-grouping/selectors';
import { CustomGroupingDrawerStatus } from '../../../redux/custom-grouping/state';
import {
  ContentPasteOutlined as ContentPasteIcon,
  DriveFileRenameOutlineOutlined as DriveFileRenameOutlineIcon,
  FolderCopyOutlined as FolderCopyIcon,
  MoveDown as MoveDownIcon,
  MoveUp as MoveUpIcon,
} from '../../mui-v5-icons';
import { ActionBar, ActionBarItemProps } from '../../shared/action-bar';
import useCustomGroupingState from '../context/useCustomGroupingState';

const HEADER_LABEL = 'Grouping Tree';
const CustomGroupingActionBar: FC = () => {
  const readOnly = useAppSelector(getDrawerStatus) === CustomGroupingDrawerStatus.VIEW;

  const {
    hasModelController,
    modelController,
    updateGroupingTree,
    createNode,
    selectedIds,
    copiedNodes,
    copyNodes,
    intendedRenameNodeId,
    intendRename,
  } = useCustomGroupingState();

  const buttonProps: ActionBarItemProps[] = useMemo(
    () => [
      {
        label: 'Add New Folder',
        icon: CreateNewFolderIcon,
        items: !hasModelController
          ? []
          : groupingStrategyTypes.map(strategyType => ({
              label: getLabel(strategyType),
              onClick: () => {
                createNode(strategyType);
              },
              disabled: !modelController.allowCreateNode(selectedIds, strategyType),
            })),
        disabled:
          readOnly ||
          intendedRenameNodeId !== null ||
          !hasModelController ||
          !modelController.allowCreateAnyNode(selectedIds),
      },
      {
        label: 'Rename',
        icon: DriveFileRenameOutlineIcon,
        onClick: intendRename,
        disabled:
          readOnly ||
          !hasModelController ||
          intendedRenameNodeId !== null ||
          !modelController.allowRename(selectedIds),
      },
      {
        label: 'Move Up',
        icon: MoveUpIcon,
        onClick: () => {
          updateGroupingTree(g => g.onMoveUp(selectedIds));
        },
        disabled:
          readOnly ||
          !hasModelController ||
          intendedRenameNodeId !== null ||
          !modelController.allowMoveUp(selectedIds),
      },
      {
        label: 'Move Down',
        icon: MoveDownIcon,
        onClick: () => {
          updateGroupingTree(g => g.onMoveDown(selectedIds));
        },
        disabled:
          readOnly ||
          !hasModelController ||
          intendedRenameNodeId !== null ||
          !modelController.allowMoveDown(selectedIds),
      },
      {
        label: 'Delete',
        icon: DeleteIcon,
        onClick: () => {
          updateGroupingTree(g => g.onDelete(selectedIds));
        },
        disabled:
          readOnly ||
          !hasModelController ||
          intendedRenameNodeId !== null ||
          !modelController.allowDelete(selectedIds),
      },
      {
        label: 'Copy',
        icon: FolderCopyIcon,
        onClick: () => {
          copyNodes(modelController.onCopy(selectedIds));
        },
        disabled:
          readOnly ||
          !hasModelController ||
          intendedRenameNodeId !== null ||
          !modelController.allowCopy(selectedIds),
      },
      {
        label: 'Paste',
        icon: ContentPasteIcon,
        onClick: () => {
          updateGroupingTree(g => g.onPaste(selectedIds, copiedNodes));
        },
        disabled:
          readOnly ||
          !hasModelController ||
          copiedNodes.length === 0 ||
          !modelController.allowPaste(selectedIds, copiedNodes),
      },
    ],
    [
      hasModelController,
      modelController,
      updateGroupingTree,
      createNode,
      selectedIds,
      copiedNodes,
      copyNodes,
      intendedRenameNodeId,
      intendRename,
      readOnly,
    ],
  );

  return (
    <ActionBar
      id='custom-grouping'
      headerLabel={HEADER_LABEL}
      borderBottom
      {...{
        buttonProps,
      }}
    />
  );
};

export default CustomGroupingActionBar;
