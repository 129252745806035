import StompJs, { IFrame } from '@stomp/stompjs';
import { ReportRequestStatus, ReportUpdateHighlightMode } from 'algo-react-dataviz';
import { eventChannel } from 'redux-saga';
import { call, cancelled, put, take } from 'redux-saga/effects';
import { exportToCSV } from '../components/report/helpers/exportUtils';
import {
  autoRecover,
  subscribeCSVTopic,
  subscribeProgressTopic,
  subscribeEditTopic,
  subscribePendingUpdateTopic,
  subscribeTopic,
} from '../components/shared/environment';
import { NotificationLevel } from '../shared/constants';
import { CSVData, ReportRawData } from '../shared/dataTypes';
import { clientUuid } from '../shared/utils';
import { enqueueSnackbar, setGlobalOfflineFlag } from './ActionCreators';
import * as ActionTypes from './ActionTypes';
import { addReportPendingRequest, updateReportDataFromServer } from './ReportActionCreators';
import { updateProgressFromServer } from './progress/actionCreators';
import { sesesionExpiredWarningToggle } from './UserProfileActionCreators';
import { regenerateTopLevelReports } from './WorkspaceActionCreators';

const socketState = {
  connected: false,
};

export const wsConnected = (): boolean => socketState.connected;

function initWebsocket(sock: StompJs.Client) {
  return eventChannel(emitter => {
    function onMessage(message) {
      const reportData: ReportRawData = JSON.parse(message.body);

      if (reportData.errMessage === 'UNAUTHORIZED') {
        emitter(sesesionExpiredWarningToggle());
      } else {
        emitter(updateReportDataFromServer(reportData));
      }
    }

    function onCSVMessage(message) {
      const csvData: CSVData = JSON.parse(message.body);

      csvData.errMessage
        ? emitter(enqueueSnackbar(NotificationLevel.ERROR, csvData.errMessage))
        : emitter(exportToCSV(csvData.sequenceId, csvData.rowHeaders, csvData.colHeaders));
    }

    function onEditMessage(message) {
      const { errMessage } = JSON.parse(message.body);

      errMessage && emitter(enqueueSnackbar(NotificationLevel.ERROR, errMessage));
    }

    const onPendingUpdateMessage = message => {
      const { requestId, sequenceId, action } = JSON.parse(message.body);

      if (action === 'pendingBegin') {
        emitter(
          addReportPendingRequest(
            sequenceId,
            requestId,
            ReportRequestStatus.PENDING,
            ReportUpdateHighlightMode.CELL_CHANGE,
          ),
        );
      } else if (action === 'pendingEnd') {
        emitter({
          type: ActionTypes.REMOVE_PENDING_OPERATION,
          payload: { sequenceId, requestId },
        });
      }
    };

    const onProgressMessage = message =>
      emitter(updateProgressFromServer(JSON.parse(message.body)));

    const onConnect = () => {
      sock.subscribe(`${subscribeTopic}${clientUuid}`, onMessage);
      sock.subscribe(`${subscribeCSVTopic}${clientUuid}`, onCSVMessage);
      sock.subscribe(`${subscribePendingUpdateTopic}${clientUuid}`, onPendingUpdateMessage);
      sock.subscribe(`${subscribeEditTopic}${clientUuid}`, onEditMessage);

      sock.subscribe(`${subscribeProgressTopic}${clientUuid}`, onProgressMessage);
      emitter(setGlobalOfflineFlag(false));
      // emitter(enqueueSnackbar(NotificationLevel.INFO, `Connected to server`));
      socketState.connected = true;
      console.log(`Connected to server`);
      if (autoRecover) {
        emitter(regenerateTopLevelReports());
      }
    };

    const onClose = (errType: string) => (evt: CloseEvent) => {
      emitter(setGlobalOfflineFlag(true));
      if (socketState.connected) {
        // emitter(enqueueSnackbar(NotificationLevel.WARN, `${errType}`));
        console.warn(`${errType}`, evt.reason);
        socketState.connected = false;
      }
    };

    const onStompError = (errType: string) => (error: IFrame) => {
      if (socketState.connected) {
        // emitter(enqueueSnackbar(NotificationLevel.WARN, `${errType}`));
        console.warn(`${errType}`, error.body);
      }
    };

    const onWsError = (errType: string) => (evt: Event) => {
      if (socketState.connected) {
        // emitter(enqueueSnackbar(NotificationLevel.WARN, `${errType}`));
        console.warn(`${errType}`, evt);
      }
    };

    sock.onConnect = onConnect;
    sock.onStompError = onStompError('Stomp error');
    sock.onWebSocketError = onWsError('WS error');
    sock.onDisconnect = onStompError('Disconnected from server');
    sock.onWebSocketClose = onClose('WS closed');

    // this will connect and subscribe to the websocket
    sock.activate();

    return () => {
      // this will unsubscribe and disconnect the websocket
      sock.deactivate();
    };
  });
}

const wsSagas = (sock: StompJs.Client) => {
  return function* wsSagas() {
    const channel = yield call(initWebsocket, sock);
    try {
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  };
};

export default wsSagas;
