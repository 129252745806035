import Axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { FolderType, NotificationLevel } from '../../../shared/constants';
import { Node, SandboxRestResponse } from '../../../shared/dataTypes';
import { baseUrl } from '../../shared/environment';
import UnfoldAccordion from '../../shared/UnfoldAccordion';
import './access-permissions-panel.scss';
import AccessPermissionsList from './AccessPermissionsList';

interface BaseProps {
  hidden: boolean;
}

const mapDispatchToProps = { enqueueSnackbar };
const connector = connect(undefined, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & BaseProps;

const AccessPermissionsPanel: FC<Props> = props => {
  const [workspaceFolders, setWorkspaceFolders] = useState<string[]>([]);
  const [reportFolders, setReportFolders] = useState<string[]>([]);
  const [sandboxes, setSandboxes] = useState<string[]>([]);

  const { enqueueSnackbar } = props;
  useEffect(() => {
    let componentIsMounted = true;

    const restCall = (path: string, set: (value: string[]) => void, exclude?: string) =>
      Axios.get<Node<string>>(`${baseUrl}api/${path}`, {
        params: { forRead: true, bypassPermissionCheck: true },
      })
        .then(
          response =>
            componentIsMounted &&
            set(response.data.children?.filter(c => c.name !== exclude).map(c => c.name)),
        )
        .catch(error =>
          enqueueSnackbar(NotificationLevel.ERROR, `Warning: Unable to retrieve ${path}: ${error}`),
        );

    restCall('workspaceFolders', setWorkspaceFolders, 'Legacy Workspaces');
    restCall('reportFolders', setReportFolders, 'Legacy Reports');

    Axios.get<SandboxRestResponse>(`${baseUrl}api/sandboxes`)
      .then(
        response =>
          componentIsMounted && setSandboxes(response.data.public.children?.map(c => c.name)),
      )
      .catch(error =>
        enqueueSnackbar(NotificationLevel.ERROR, `Warning: Unable to retrieve sandboxes: ${error}`),
      );

    return () => {
      componentIsMounted = false;
    };
  }, [enqueueSnackbar]);

  return (
    <div hidden={props.hidden}>
      <UnfoldAccordion summary='Workspace Access' defaultExpanded>
        <AccessPermissionsList
          folderType={FolderType.WORKSPACE}
          allPermissionsProperty='workspacePermissions'
          list={workspaceFolders}
        />
      </UnfoldAccordion>

      <UnfoldAccordion summary='Report Access'>
        <AccessPermissionsList
          folderType={FolderType.REPORT}
          allPermissionsProperty='reportPermissions'
          list={reportFolders}
        />
      </UnfoldAccordion>

      {sandboxes && (
        <UnfoldAccordion summary='Sandbox Access'>
          <AccessPermissionsList
            folderType={FolderType.SANDBOX}
            allPermissionsProperty='sandboxPermissions'
            list={sandboxes}
          />
        </UnfoldAccordion>
      )}
    </div>
  );
};

export default connector(AccessPermissionsPanel);
