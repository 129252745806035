import { IconButton, Menu, MenuItem } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { FC, useState } from 'react';
import NewWindow from 'react-new-window';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { NotificationLevel } from '../../../shared/constants';
import { clientUuid } from '../../../shared/utils';
import AscriptHelp from './ascript-help/AscriptHelp';

const EDITOR_DOCS_URL = 'https://code.visualstudio.com/docs/editor/codebasics';

const AscriptEditorHeaderButtons: FC = () => {
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLElement | null>(null);
  const [openHelp, setOpenHelp] = useState(false);

  const dispatch = useDispatch();

  return (
    <div className='editor-header-buttons'>
      {openHelp && (
        <NewWindow
          features={{ height: 500, width: 800, popup: 0 }}
          name={`aScript-Documentation-${clientUuid}`}
          title='aScript Documentation'
          onUnload={() => setOpenHelp(false)}
          onBlock={() => dispatch(enqueueSnackbar(NotificationLevel.WARN, 'popup blocked'))}
        >
          <AscriptHelp />
        </NewWindow>
      )}

      <IconButton
        onClick={e => {
          setHelpAnchorEl(e.currentTarget);
        }}
        color='primary'
      >
        <HelpIcon />
      </IconButton>

      <Menu
        anchorEl={helpAnchorEl}
        open={!!helpAnchorEl}
        onClose={() => {
          setHelpAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenHelp(true);
          }}
        >
          aScript Documentation
        </MenuItem>

        <MenuItem
          onClick={() => {
            setHelpAnchorEl(null);
            const newWindow = window.open(EDITOR_DOCS_URL, '_blank');
            newWindow.opener = null;
          }}
        >
          Editor Help
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AscriptEditorHeaderButtons;
