import { Typography } from '@material-ui/core';
import { CSSProperties, FC } from 'react';

interface Props {
  title: string;
  style?: CSSProperties;
  className?: string;
}

const Panel: FC<Props> = props => (
  <div style={{ overflowY: 'auto', ...props.style }} className={props.className}>
    <div style={{ display: 'grid', alignItems: 'center', height: '48px', paddingLeft: '12px' }}>
      <Typography variant='h2'>{props.title}</Typography>
    </div>
    {props.children}
  </div>
);

export default Panel;
