import Axios from 'axios';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { deleteUsers, selectUser } from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';
import { NotificationLevel } from '../../../shared/constants';
import { AdminTableItem } from '../../../shared/dataTypes';
import { baseUrl } from '../../shared/environment';
import AdminTable from '../AdminTable';

interface Data extends AdminTableItem {
  displayName: string;
  userName: string;
}

const columns: { key: keyof Data; displayName: string; id?: boolean; sortKey?: boolean }[] = [
  { key: 'displayName', displayName: 'Name' },
  { key: 'userName', displayName: 'Username', id: true, sortKey: true },
];

const mapStateToProps = (state: AppState) => ({
  users: state.admin.users,
  selectedUser: state.admin.selectedUser,
});
const mapDispatchToProps = { selectUser, deleteUsers, enqueueSnackbar };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const UsersPanel: FC<Props> = props => (
  <AdminTable
    style={{ gridArea: 'users' }}
    className='right-border-table'
    columns={columns}
    data={props.users.map<Data>(u => ({
      displayName: `${u.firstName} ${u.lastName}`,
      userName: u.userName,
    }))}
    deleteDialogType='User'
    onDelete={selectedUsers => {
      Axios.delete(`${baseUrl}api/user`, { params: { userNames: selectedUsers.join(',') } })
        .then(() => props.deleteUsers(selectedUsers))
        .catch(error =>
          props.enqueueSnackbar(NotificationLevel.ERROR, `Failed to delete user: ${error.message}`),
        );
    }}
    selectedItem={props.selectedUser}
    onSelect={props.selectUser}
  />
);

export default connector(UsersPanel);
