import { FC } from 'react';
import { HorizontalPosition } from '../../shared/constants';
import { ReportDefinition, UISlot } from '../../shared/dataTypes';
import { getSelectedVisualization, VISUALIZATIONS_WITH_LAYOUT } from '../../shared/utils';
import SlotComponent from '../slots/Slot';

interface BaseProps {
  reportDefinition: ReportDefinition;
  filteredUiSlots: UISlot[];
  updateReport: (slotDetails: UISlot, changedValue: string) => void;
}

const UiSlotsRow: FC<BaseProps> = props => {
  if (props.filteredUiSlots.length && props.filteredUiSlots.some(({ enabled }) => enabled)) {
    const leftSlot = findUiSlot(props.filteredUiSlots, HorizontalPosition.LEFT);
    const middleSlot = findUiSlot(props.filteredUiSlots, HorizontalPosition.CENTER);
    const rightSlot = findUiSlot(props.filteredUiSlots, HorizontalPosition.RIGHT);
    const reportVisualizationType = getSelectedVisualization(props.reportDefinition);

    return (
      <div
        className='margin-horiz hide-in-pdf'
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, max-content))',
          gridGap: '.5rem',
        }}
      >
        {leftSlot &&
          leftSlot.enabled &&
          visualizationAllowedForSlot(reportVisualizationType, leftSlot) && (
            <div>
              <SlotComponent slotDetails={leftSlot} onChange={props.updateReport} />
            </div>
          )}
        {middleSlot &&
          middleSlot.enabled &&
          visualizationAllowedForSlot(reportVisualizationType, middleSlot) && (
            <div className='hide-in-pdf'>
              <SlotComponent slotDetails={middleSlot} onChange={props.updateReport} />
            </div>
          )}
        {rightSlot &&
          rightSlot.enabled &&
          visualizationAllowedForSlot(reportVisualizationType, rightSlot) && (
            <div className='hide-in-pdf'>
              <SlotComponent slotDetails={rightSlot} onChange={props.updateReport} />
            </div>
          )}
      </div>
    );
  } else return null;
};

const findUiSlot = (filteredUiSlots: UISlot[], horizontalPositionToFind: HorizontalPosition) => {
  if (filteredUiSlots) {
    return filteredUiSlots.find(
      ({ horizontalPosition }) => horizontalPosition === horizontalPositionToFind,
    );
  }
};

const visualizationAllowedForSlot = (visualizationType: string, slot: UISlot): boolean => {
  if (slot?.complicationDetails?.type === 'ChartLayoutComplication') {
    return !!VISUALIZATIONS_WITH_LAYOUT.find(t => t === visualizationType);
  }

  return true;
};

export default UiSlotsRow;
