import { SingleDateRunPicker } from 'algo-react-dataviz';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDateContexts,
  setBenchmark,
  setBenchmarkDateContextRun,
} from '../../../redux/ActionCreators';
import { Benchmark } from '../../../shared/dataTypes';
import { isAwa } from '../../../shared/utils';
import { getRunExists } from '../../report-dates/selectors';
import useDateContexts from '../../report-dates/useDateContexts';

interface Props {
  benchmark: Benchmark;
  index: number;
}

const BenchmarkDatePicker: FC<Props> = props => {
  const dispatch = useDispatch();
  const { dateContexts, defaultDate } = useDateContexts();
  const showRuns = useSelector(isAwa);
  const runExists = useSelector(getRunExists);

  return (
    <SingleDateRunPicker
      disabled={props.benchmark.reportDateType !== 'custom'}
      selectedDateContext={props.benchmark.dateContextOption}
      onClick={() => dispatch(fetchDateContexts())}
      onApply={({ date, id }) => {
        dispatch(setBenchmark(props.index, 'dateContextOption', { date, id: '' }));
        dispatch(setBenchmarkDateContextRun(props.index, id));
      }}
      variant='outlined'
      size='small'
      {...{ dateContexts, defaultDate, showRuns, runExists }}
    />
  );
};

export default BenchmarkDatePicker;
