import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Characteristic } from '../../../shared/dataTypes';
import DraggableElement from './DraggableElement';

const Clone = styled(DraggableElement)`
  ~ div {
    transform: none !important;
  }
`;

interface Props {
  char: Characteristic;
  index: number;
  menuMode: boolean;
  droppableId?: string;
  horizontal?: boolean;
  remove?: () => void;
  editor?: (target: EventTarget & HTMLButtonElement) => void;
}

const CharacteristicDraggable: FC<Props> = props => (
  <Draggable draggableId={props.char.draggableId} index={props.index}>
    {(provided, snapshot) => (
      <>
        <DraggableElement
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          isDragging={snapshot.isDragging}
          isOnMenu={props.menuMode}
          droppableId={props.droppableId}
          direction={props.horizontal ? 'horizontal' : 'vertical'}
          remove={props.remove}
          editor={props.editor}
          char={props.char}
        />

        {props.menuMode && snapshot.isDragging && (
          <Clone isDragging={false} isOnMenu direction='vertical' char={props.char} />
        )}
      </>
    )}
  </Draggable>
);

export default CharacteristicDraggable;
