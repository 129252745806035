import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { logLevel, publicUrl } from '../../shared/environment';
import { AUTH_CONFIG, JWT_USER_ID, uriLoginCallback } from './AuthCfg';

export default class AuthService {
  userManager: UserManager;
  cfg: UserManagerSettings;

  constructor(
    setupLoggedInUser: (token: string, username: string, oidcLogout?: () => void) => void,
  ) {
    this.cfg = {
      ...AUTH_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    };
    this.userManager = new UserManager(this.cfg);
    this.userManager.getUser();
    const loadedPre = (user: User) => {
      const jwt = this.parseJwt(user.id_token);
      const jwtUserId = jwt[JWT_USER_ID];
      setupLoggedInUser(user.id_token, jwtUserId, this.oidcLogout);
    };
    this.userManager.getUser().then(user => {
      if (null !== user) {
        loadedPre(user);
      }
    });
    // Logger
    Log.logger = console;
    Log.level = logLevel ? Log[logLevel] || Log.WARN : Log.WARN;
    this.userManager.events.addUserLoaded(user => {
      loadedPre(user);
      if (window.location.href.indexOf(uriLoginCallback.path) !== -1) {
        this.navigateToScreen(user.state);
      }
    });
    this.userManager.events.addUserUnloaded(() => {
      if (window.location.href.indexOf(uriLoginCallback.path) !== -1) {
        this.navigateToScreen('/');
      }
    });
    this.userManager.events.addSilentRenewError(e => {
      Log.debug('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired(() => {
      Log.debug('token expired');
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.userManager.signinRedirectCallback();
  };

  getUser = async () => {
    const user = await this.userManager.getUser();
    if (!user) {
      return await this.userManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    //localStorage.setItem('redirectUri', window.location.pathname);
    this.userManager.signinRedirect({ state: window.location.pathname + window.location.search });
  };

  navigateToScreen = (url: string) => {
    window.location.replace(url);
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(`oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`),
    );
    return !!oidcStorage && !!oidcStorage.id_token;
  };

  signinSilent = () => {
    this.userManager
      .signinSilent()
      .then(user => {
        Log.debug('oidc signed in', user);
      })
      .catch(err => {
        Log.error(err);
      });
  };
  signinSilentCallback = () => {
    this.userManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.userManager.createSigninRequest();
  };

  oidcLogout = () => {
    Log.debug('oidc logout');
    this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
    });
    this.userManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.userManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(publicUrl);
    });
    this.userManager.clearStaleState();
  };
}
