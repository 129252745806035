import { Button, Dialog, DialogActions } from '@material-ui/core';
import { DrawerHeader } from 'algo-react-dataviz';
import { FC, useState } from 'react';
import './discrete-breakpoint-modal.scss';
import DiscreteBreakpoints from './DiscreteBreakpoints';

interface Props {
  initialValue: string[];
  onApplyClick: (breakpoints: string[]) => void;
  onCancelClick: () => void;
}

const DiscreteBreakpointModal: FC<Props> = props => {
  const [breakpoints, setBreakpoints] = useState<string[]>(props.initialValue || []);

  return (
    <Dialog open={true} className='discrete-breakpoint-modal'>
      <DrawerHeader headerText='Breakpoint Groupings' onXClick={props.onCancelClick} />

      <DiscreteBreakpoints
        onAddBreakpoint={() => setBreakpoints([...breakpoints, ''])}
        onChangeBreakpoint={(index, value: string) => {
          setBreakpoints([...breakpoints.slice(0, index), value, ...breakpoints.slice(index + 1)]);
        }}
        onDeleteBreakpoint={index => {
          setBreakpoints([...breakpoints.slice(0, index), ...breakpoints.slice(index + 1)]);
        }}
        breakpoints={breakpoints}
      />

      <DialogActions>
        <Button
          disabled={breakpoints.length === 0}
          onClick={() => setBreakpoints([])}
          color='primary'
          variant='text'
        >
          Clear All
        </Button>
        <Button
          onClick={() =>
            props.onApplyClick(breakpoints.map(b => b.trim()).filter(b => b.length > 0))
          }
          color='primary'
          variant='contained'
        >
          Apply
        </Button>
        <Button onClick={() => props.onCancelClick()} variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DiscreteBreakpointModal;
