import { Button } from '@material-ui/core';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import Axios, { AxiosResponse } from 'axios';
import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { DrawerType, NodeType, NotificationLevel } from '../../shared/constants';
import { Node } from '../../shared/dataTypes';
import { getEndpoint } from '../../shared/utils';
import { baseUrl } from '../shared/environment';
import FilePrompt from './FilePrompt';

interface BaseProps {
  drawerType: DrawerType;
  loadData?: (response?: AxiosResponse<Node<string>>) => void;
  canCreateFolderUnderRoot: boolean;
}

const mapStateToProps = (state: AppState) => ({
  selectedFolderItem: state.drawers.folderDrawer.selectedFolderItem,
});
const mapDispatchToProps = { enqueueSnackbar };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const NewFolderButton: FC<Props> = props => {
  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);

  const createNewFolder = async (name: string) => {
    setNewFolderDialogOpen(false);

    try {
      await Axios.post<Node<string>>(
        `${baseUrl}api/${getEndpoint(NodeType.FOLDER, props.drawerType, 'create')}`,
        {
          path:
            (props.selectedFolderItem.props.path?.concat('/') || '') +
            encodeURIComponent(name) +
            '/',
        },
      );
      props.loadData();
    } catch (e) {
      console.error(e);
      props.enqueueSnackbar(
        NotificationLevel.ERROR,
        e.response.status === 409
          ? 'An item with that name already exists.'
          : decodeURIComponent(e.message),
      );
    }
  };

  return (
    <>
      <Button
        variant='text'
        onClick={() => setNewFolderDialogOpen(true)}
        disabled={
          !(props.selectedFolderItem?.type === NodeType.FOLDER) ||
          (props.selectedFolderItem?.name === '/' && !props.canCreateFolderUnderRoot)
        }
        style={{ alignSelf: 'flex-end' }}
        startIcon={<AddCircleOutlineOutlined />}
      >
        New Folder
      </Button>
      <FilePrompt
        open={newFolderDialogOpen}
        handleClose={() => setNewFolderDialogOpen(false)}
        handleSave={createNewFolder}
        title='Create New Folder'
      >
        Enter a name for the new folder:
      </FilePrompt>
    </>
  );
};

export default connector(NewFolderButton);
