import { Reducer } from 'redux';
import { createTrivialCustomGrouping } from '../../model/custom-grouping/customGrouping';
import { CustomGroupingAction, CustomGroupingActionType } from './actions';
import { createDefaultState, CustomGroupingDrawerStatus, CustomGroupingState } from './state';

const reducer: Reducer<CustomGroupingState, CustomGroupingAction> = (
  state = createDefaultState(),
  action,
) => {
  switch (action.type) {
    case CustomGroupingActionType.OPEN_CREATE_DRAWER: {
      return {
        saveModalOpen: false,
        drawerStatus: CustomGroupingDrawerStatus.CREATE,
        syntaxGuideOpen: false,
        grouping: createTrivialCustomGrouping(),
      };
    }

    case CustomGroupingActionType.OPEN_EDIT_DRAWER: {
      return {
        saveModalOpen: false,
        drawerStatus: CustomGroupingDrawerStatus.EDIT,
        syntaxGuideOpen: false,
        grouping: action.payload,
      };
    }

    case CustomGroupingActionType.OPEN_VIEW_DRAWER: {
      return {
        saveModalOpen: false,
        drawerStatus: CustomGroupingDrawerStatus.VIEW,
        syntaxGuideOpen: false,
        grouping: action.payload,
      };
    }

    case CustomGroupingActionType.CLOSE_DRAWER: {
      return createDefaultState();
    }

    case CustomGroupingActionType.SET_SYNTAX_GUIDE_OPEN: {
      return {
        ...state,
        syntaxGuideOpen: action.payload,
      };
    }

    case CustomGroupingActionType.SET_GROUPING_TREE: {
      return {
        ...state,
        grouping: {
          ...state.grouping,
          rootNode: action.payload,
        },
      };
    }

    case CustomGroupingActionType.SET_SAVE_MODAL_OPEN: {
      return {
        ...state,
        saveModalOpen: action.payload,
      };
    }

    case CustomGroupingActionType.SET_GROUPING_FOR_SAVE: {
      return {
        ...state,
        saveModalOpen: true,
        grouping: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default reducer;
