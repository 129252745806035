import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { getFilterOptions, regenerateIfAuto, setNot } from '../../../../redux/ActionCreators';
import { useAppSelector } from '../../../../redux/configureStore';
import { getFilter } from '../../../../redux/report/selectors';
import { FilterOptions } from '../../../../shared/dataTypes';
import './set-not-checkbox.scss';

interface Props {
  charId: number;
  setFilterOptions: (filterOptions: FilterOptions) => void;
}

const SetNotCheckbox: FC<Props> = props => {
  const dispatch = useDispatch();
  const filter = useAppSelector(getFilter)(props.charId);

  return (
    <FormControlLabel
      control={
        <Checkbox
          color='primary'
          checked={filter.not || false}
          onChange={e => {
            dispatch(setNot(props.charId, e.target.checked));
            dispatch(getFilterOptions(props.setFilterOptions));
            dispatch(regenerateIfAuto());
          }}
        />
      }
      label='Not'
      className='set-not-checkbox'
    />
  );
};

export default SetNotCheckbox;
