import { Toolbar as MuiToolbar } from '@material-ui/core';
import { FC, lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect, ConnectedProps } from 'react-redux';
import { clearWorkspace } from '../../redux/WorkspaceActionCreators';
import WorkspaceErrorBoundary from '../../shared/WorkspaceErrorBoundary';
import Toolbar from './Toolbar/Toolbar';

const CanvasComponent = lazy(() => import('../canvas/Canvas'));

const mapDispatchToProps = {
  clearWorkspace,
};

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const WorkspaceView: FC<Props> = props => (
  <>
    <MuiToolbar style={{ minHeight: '56px' }} />
    <Toolbar />
    {/* 56px is height of the MuiToolbar */}
    {/* 56px is width of the NavRail */}
    <div style={{ marginTop: '56px', marginLeft: '56px', padding: '1rem' }}>
      <ErrorBoundary FallbackComponent={WorkspaceErrorBoundary} onReset={props.clearWorkspace}>
        <Suspense fallback={<div>Loading ...</div>}>
          <CanvasComponent />
        </Suspense>
      </ErrorBoundary>
    </div>
  </>
);

export default connector(WorkspaceView);
