import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  requestSetUserFirstLastName,
  setUserFirstName,
  setUserLastName,
} from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';
import InfoPanelField from './InfoPanelField';
import Panel from './Panel';
import './user-info-panel.scss';

const mapStateToProps = (state: AppState) => ({
  user: state.admin.users.find(u => u.userName === state.admin.selectedUser),
});

const mapDispatchToProps = { setUserFirstName, setUserLastName, requestSetUserFirstLastName };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const UserInfoPanel: FC<Props> = props => (
  <Panel title='User Info' className='user-info-panel'>
    {props.user && (
      <>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div id='user-info-body'>
            <InfoPanelField
              label='First Name'
              value={props.user.firstName || ''}
              onChange={props.setUserFirstName}
              onBlur={props.requestSetUserFirstLastName}
            />
            <InfoPanelField
              label='Last Name'
              value={props.user.lastName || ''}
              onChange={props.setUserLastName}
              onBlur={props.requestSetUserFirstLastName}
            />
            <InfoPanelField label='Username' value={props.user.userName || ''} />
          </div>
        </div>
      </>
    )}
  </Panel>
);

export default connector(UserInfoPanel);
