import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomGrouping } from '../../../model/custom-grouping/customGrouping';
import useCustomGroupingApi from '../../../model/custom-grouping/useCustomGroupingApi';
import { useAppSelector } from '../../../redux/configureStore';
import { getCustomGrouping } from '../../../redux/custom-grouping/selectors';
import useDebouncedState from '../../../shared/useDebouncedState';

export interface CustomGroupingInfo extends Pick<CustomGrouping, 'name' | 'description'> {}

export interface CustomGroupingInfoState {
  initialNameValue: string;
  nameValue: string;
  debouncedNameValue: string;
  validating: boolean;
  isNameValid: boolean;
  descriptionValue: string;

  setNameValue: (name: string) => void;
  setDescriptionValue: (description: string) => void;

  getCustomGroupingInfo: () => CustomGroupingInfo;
}

const DEBOUNCE_DELAY = 150; // ms
const useCustomGroupingInfoState = (): CustomGroupingInfoState => {
  const grouping = useAppSelector(getCustomGrouping);
  const initialNameValue = grouping?.name ?? '';
  const initialDescriptionValue = grouping?.description ?? '';
  const { hasCustomGroupingWithName: hasCustomGrouping } = useCustomGroupingApi();
  const [isNameValid, setIsNameValid] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(initialDescriptionValue);
  const [validating, setValidating] = useState(false);

  const {
    state: nameValue,
    debouncedState: debouncedNameValue,
    setState: setNameValue,
  } = useDebouncedState(initialNameValue, DEBOUNCE_DELAY);

  useEffect(() => {
    setDescriptionValue(initialDescriptionValue);
  }, [grouping?.description, initialDescriptionValue]);

  // updates name value for opening add drawer
  // fixes name field for creating a new custom grouping and then opening new drawer
  useEffect(() => {
    setNameValue(initialNameValue);
  }, [grouping?.name, initialNameValue, setNameValue]);

  const needsApiCheck = useCallback(
    (nameValue: string) => {
      const name = nameValue.trim();
      return !!name && name !== initialNameValue;
    },
    [initialNameValue],
  );

  useEffect(() => {
    if (!needsApiCheck(nameValue)) {
      setIsNameValid(!!nameValue.trim());
    }
  }, [needsApiCheck, nameValue]);

  useEffect(() => {
    if (needsApiCheck(debouncedNameValue)) {
      setValidating(true);
      hasCustomGrouping(debouncedNameValue.trim()).then(nameExists => {
        setIsNameValid(!nameExists);
        setValidating(false);
      });
    }
  }, [needsApiCheck, hasCustomGrouping, debouncedNameValue]);

  const getCustomGroupingInfo = useCallback(
    (): CustomGroupingInfo => ({
      name: debouncedNameValue.trim() || null,
      description: descriptionValue.trim() || null,
    }),
    [debouncedNameValue, descriptionValue],
  );

  return useMemo(
    () => ({
      initialNameValue,
      nameValue,
      // fixes race condition on hasCustomGrouping promise which sets isNameValid to false after creating new grouping
      isNameValid: isNameValid || (nameValue && initialNameValue === nameValue),
      descriptionValue,
      setNameValue,
      setDescriptionValue,
      getCustomGroupingInfo,
      validating,
      debouncedNameValue,
    }),
    [
      initialNameValue,
      nameValue,
      isNameValid,
      descriptionValue,
      setNameValue,
      setDescriptionValue,
      getCustomGroupingInfo,
      validating,
      debouncedNameValue,
    ],
  );
};

export default useCustomGroupingInfoState;
