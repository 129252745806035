import { RadioGroup, Typography } from '@material-ui/core';

import { FC } from 'react';
import SettingsRadio from './settings-panel/SettingsRadio';

interface Props {
  label: string;
  choices: { id: string; label: string }[];
  value: string;
  onChange: (event: any) => void;
}

const LabeledChoices: FC<Props> = props => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <Typography>{props.label}</Typography>
      <RadioGroup onChange={e => props.onChange(e)} value={props.value}>
        <div style={{ display: 'flex' }}>
          {props.choices.map(choice => {
            return <SettingsRadio id={choice.id} label={choice.label} />;
          })}
        </div>
      </RadioGroup>
    </div>
  );
};

export default LabeledChoices;
