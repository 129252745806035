import { TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toDateString } from '../../../model/armantaDate';
import { regenerateIfAuto, setSelectedFilterOptions } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { getFilter } from '../../../redux/report/selectors';
import { DataType } from '../../../shared/constants';
import {
  Characteristic,
  FilterOptions,
  OptionDisplayMap,
  OptionTypes,
} from '../../../shared/dataTypes';
import { checkValidation, minMaxValidator } from '../../../shared/utils';
import SetNotCheckbox from '../ui-elements/form/SetNotCheckbox';
import './min-max-filter.scss';
interface Props {
  char: Characteristic;
  options?: OptionDisplayMap[];
  setFilterOptions: (filterOptions: FilterOptions) => void;
}

const MinMaxFilter: FC<Props> = props => {
  const dispatch = useDispatch();

  const filter = useAppSelector(getFilter)(props.char.charId);

  const [minVal, maxVal] = (filter?.selectedOptions || ['', '']) as OptionTypes[];
  const [hasChanged, setHasChanged] = useState(false);
  const minValidationErrMsg = checkValidation(props.char.dataType, minVal, true);
  const maxValidationErrMsg = checkValidation(props.char.dataType, maxVal, true);
  const minMaxValidationErrMsg = minMaxValidator(props.char.dataType, minVal, maxVal);
  const minErrorMsg = minValidationErrMsg || (maxValidationErrMsg ? null : minMaxValidationErrMsg);
  const maxErrorMsg = maxValidationErrMsg || (minValidationErrMsg ? null : minMaxValidationErrMsg);

  const handleEnterPressed = ({ key }) => {
    key === 'Enter' && handleUpdatingFilter();
  };

  const handleChange = (type: string) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const filterHasValue = !!e.target.value;
    const filterIsValid = checkValidation(props.char.dataType, e.target.value, true) === null;
    filterHasValue && filterIsValid && setHasChanged(true);
    dispatch(
      setSelectedFilterOptions(
        props.char.charId,
        type === 'max' ? [minVal, e.target.value] : [e.target.value, maxVal],
      ),
    );
  };

  const handleOnBlur = (type: string) => (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    dispatch(
      setSelectedFilterOptions(
        props.char.charId,
        type === 'max' ? [minVal, e.target.value] : [e.target.value, maxVal],
      ),
    );
    handleUpdatingFilter();
  };

  const handleUpdatingFilter = () => {
    const isValid =
      minValidationErrMsg === null &&
      maxValidationErrMsg === null &&
      minMaxValidationErrMsg === null;

    if (hasChanged && isValid) {
      dispatch(regenerateIfAuto());
    }
  };

  return (
    <>
      <div className='min-max-filter-container'>
        {props.char.dataType === DataType.DATE ? (
          <TextField
            error={minErrorMsg !== null}
            label={minErrorMsg}
            variant='outlined'
            size='small'
            type='date'
            defaultValue={toDateString(minVal)}
            onBlur={handleOnBlur('min')}
            onChange={handleChange('min')}
            onKeyPress={handleEnterPressed}
            inputProps={{ style: { fontSize: '10px' } }}
          />
        ) : (
          <TextField
            error={minErrorMsg !== null}
            label={minErrorMsg}
            defaultValue={minVal}
            onBlur={handleOnBlur('min')}
            onChange={handleChange('min')}
            onKeyPress={handleEnterPressed}
            variant='outlined'
            size='small'
          />
        )}
        <div className='min-max-filter-middle'>{'<= X <='}</div>
        {props.char.dataType === DataType.DATE ? (
          <TextField
            error={maxErrorMsg !== null}
            label={maxErrorMsg}
            variant='outlined'
            size='small'
            type='date'
            defaultValue={toDateString(maxVal)}
            onBlur={handleOnBlur('max')}
            onChange={handleChange('max')}
            onKeyPress={handleEnterPressed}
            inputProps={{ style: { fontSize: '10px' } }}
          />
        ) : (
          <TextField
            error={maxErrorMsg !== null}
            label={maxErrorMsg}
            defaultValue={maxVal}
            onBlur={handleOnBlur('max')}
            onChange={handleChange('max')}
            onKeyPress={handleEnterPressed}
            variant='outlined'
            size='small'
          />
        )}
      </div>
      <div
        style={{
          padding: '0 8px 8px',
          width: '100%',
        }}
      >
        <SetNotCheckbox charId={props.char.charId} setFilterOptions={props.setFilterOptions} />
      </div>
    </>
  );
};

export default MinMaxFilter;
