import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { FC } from 'react';

export interface ActionBarButtonProps {
  label: string;
  icon: SvgIconComponent;
  iconProps?: IconButtonProps;
  onClick: () => void;
  disabled?: boolean;
}

const ActionBarButton: FC<ActionBarButtonProps> = ({
  label,
  icon: Icon,
  iconProps = {},
  onClick,
  disabled,
}) => (
  <Tooltip title={label}>
    <span>
      <IconButton
        className='action-bar-button'
        aria-label={label}
        {...{
          onClick,
          disabled,
        }}
        {...iconProps}
      >
        <Icon />
      </IconButton>
    </span>
  </Tooltip>
);

export default ActionBarButton;
