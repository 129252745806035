import { IconButton, Typography } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import { FC } from 'react';
import { TimeGrouping } from './EditTimeBucketModal';
import TimestepGroupingSpec from './TimestepGroupingSpec';

interface Props {
  timeGroupings: TimeGrouping[];
  onChange: (timeGrouping: TimeGrouping, index: number) => void;
  onDeleteGrouping: (index: number) => void;
  onChangeGroupingName: (index: number, name: string) => void;
}

const TimestepGroupings: FC<Props> = props => {
  return (
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
      <Typography className='section-header'>Timestep Groupings</Typography>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <table className='timestep-groupings-table' style={{ width: '100%' }}>
          <tbody>
            {props.timeGroupings.map((grouping, index) => (
              <tr key={index}>
                <td>
                  <input
                    type='text'
                    value={grouping.name}
                    onChange={e => props.onChangeGroupingName(index, e.target.value)}
                  />
                </td>
                <td>
                  <TimestepGroupingSpec
                    timeGrouping={grouping}
                    onChange={(timeGrouping: TimeGrouping) => {
                      props.onChange(timeGrouping, index);
                    }}
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => props.onDeleteGrouping(index)}
                    disabled={false}
                    color='secondary'
                    size='small'
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimestepGroupings;
