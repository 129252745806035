import { Button, TextField, Typography } from '@material-ui/core';
import { ChangeEventHandler, FC, MouseEventHandler } from 'react';

interface Props {
  instrumentName: string;
  instrumentWeightPct?: number;
  onWeightChange: ChangeEventHandler<HTMLInputElement>;
  onRemoveClick: MouseEventHandler<HTMLButtonElement>;
}

const SelectedInstrument: FC<Props> = props => (
  <div className='selected-instrument'>
    <Typography variant='body2' className='instrument-name'>
      {props.instrumentName}
    </Typography>
    <TextField
      variant='outlined'
      size='small'
      type='number'
      value={props.instrumentWeightPct || ''}
      onChange={props.onWeightChange}
    />
    <Typography>%</Typography>
    <Button variant='text' color='secondary' size='small' onClick={props.onRemoveClick}>
      Remove
    </Button>
  </div>
);

export default SelectedInstrument;
