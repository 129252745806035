import {
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { setClassName, setSuperclass } from '../../../redux/aScriptEditor/actions';
import {
  getClassName,
  getEditorInterfaceType,
  getSuperclass,
} from '../../../redux/aScriptEditor/selectors';
import { NotificationLevel } from '../../../shared/constants';
import { baseUrl } from '../../shared/environment';
import AscriptEditorHeaderButtons from './AscriptEditorHeaderButtons';
import {
  EditorInterfaceType,
  EditorSuperclasses,
  extractClassIdentifiers,
} from './model/editorInterfaceType';

const SUPERCLASS_PLACEHOLDER = 'Loading...';
interface Props {
  isCompiling: boolean;
  hasEditPermission: boolean;
}

const AscriptEditorHeader: FC<Props> = props => {
  const [localClassName, setLocalClassName] = useState('');
  const [error, setErorr] = useState(false);
  const [superclasses, setSuperclasses] = useState<EditorSuperclasses | null>(null);
  const interfaceType = useSelector(getEditorInterfaceType);
  const superclassName = useSelector(getSuperclass);
  const className = useSelector(getClassName);
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalClassName(className);
  }, [className]);

  useEffect(() => {
    axios
      .get<EditorSuperclasses>(`${baseUrl}api/ascriptModes`)
      .then(response => {
        setSuperclasses(response.data);
      })
      .catch(error =>
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve custom compilation modes: ${error}`,
          ),
        ),
      );
  }, [dispatch]);

  const getSuperclassMenuItems = (interfaceType: EditorInterfaceType) => [
    interfaceType,
    ...(superclasses === null ? [SUPERCLASS_PLACEHOLDER] : superclasses[interfaceType] ?? []),
  ];

  const validateClassname = () => {
    const classIdentifiers = extractClassIdentifiers(localClassName);
    axios
      .get<{
        isPrecompiled: boolean;
        isDynamic: boolean;
        proposedName: string;
        generatedName: string;
      }>(`${baseUrl}api/validateaScriptClassname`, {
        params: {
          className: encodeURIComponent(classIdentifiers.className),
          packageName: encodeURIComponent(classIdentifiers.packageName),
        },
      })
      .then(response => {
        setErorr(response.data.isDynamic || response.data.isPrecompiled);
        dispatch(setClassName(response.data.generatedName));
      })
      .catch(_error => {
        setErorr(true);
      });
  };

  const readOnly = props.isCompiling;

  return (
    <DialogTitle className='editor-header' disableTypography>
      <div className='editor-header-left'>
        <TextField
          className='editor-class-container'
          label='Class Name'
          value={localClassName || ''}
          variant='standard'
          error={!localClassName || error}
          color='primary'
          inputProps={{ readOnly }}
          disabled={!props.hasEditPermission}
          onChange={e => {
            setLocalClassName(e.target.value);
          }}
          onBlur={() => {
            !readOnly && validateClassname();
          }}
        />
        <FormControl style={{ marginLeft: '20px' }}>
          <InputLabel id='demo-controlled-open-select-label'>Type</InputLabel>
          <Select
            inputProps={{ readOnly }}
            disabled={!props.hasEditPermission}
            value={superclassName || interfaceType}
            onChange={e => {
              const value = `${e.target.value}`;
              if (value === SUPERCLASS_PLACEHOLDER) {
                return;
              }

              dispatch(
                setSuperclass(
                  ([
                    EditorInterfaceType.AGGREGATOR,
                    EditorInterfaceType.CELL_COMPUTER,
                    EditorInterfaceType.VALUE_COMPUTER,
                  ] as string[]).includes(value)
                    ? null
                    : value,
                ),
              );
            }}
          >
            {getSuperclassMenuItems(interfaceType).map(type => (
              <MenuItem value={type} key={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <AscriptEditorHeaderButtons />
    </DialogTitle>
  );
};

export default AscriptEditorHeader;
