/**
 * Return a list of reasons that report generation is not allowed.
 * If report generation is allowed, return an empty array.
 * */
import { FullDateContextItem } from 'algo-react-dataviz';
import { ReportDefinition } from '../../shared/dataTypes';
import { GroupingLayerId } from './drag-and-drop/groupingLayerId';

const getReportGenerationNotAllowedReasons = (
  reportDefinition: ReportDefinition,
  workspaceDateContext: FullDateContextItem,
) => {
  const returnable: string[] = [];

  if (!reportDefinition) {
    returnable.push('A minimum of one characteristic is required.');
    return returnable;
  }

  if (reportDefinition.chars.length === 0)
    returnable.push('A minimum of one characteristic is required.');
  if (
    reportDefinition.settings?.numberOfHeaderChars >= reportDefinition.chars.length &&
    reportDefinition.settings?.numberOfHeaderChars > 0
  ) {
    const rowsOrColumnsLabel = reportDefinition.verticalChars.some(
      ({ layerId }) => layerId === GroupingLayerId.CHARACTERISTIC,
    )
      ? 'rows'
      : 'columns';

    returnable.push(
      `Number of header characteristics must be smaller than number of all ${rowsOrColumnsLabel}.`,
    );
  }

  if (
    !workspaceDateContext &&
    (!reportDefinition.dateContext || reportDefinition.dateContext.type === 'default')
  ) {
    returnable.push('A date context must be selected.');
  }

  return returnable;
};

export default getReportGenerationNotAllowedReasons;
