import { IRange, languages } from 'monaco-editor';
import { Characteristic } from '../../../shared/dataTypes';

// This provider applies only to a specified editor model,
// because different instances may require different keys.
const createKeyCompletionProvider = (
  modelPath: string,
  characteristics: Characteristic[],
): languages.CompletionItemProvider => {
  const createDependencyProposals = (range: IRange): languages.CompletionItem[] =>
    characteristics.map(char => ({
      label: char.name,
      kind: languages.CompletionItemKind.Variable,
      detail: char.valueKey.substring(char.valueKey.lastIndexOf('.') + 1),
      documentation: char.valueKey,
      insertText: `${char.name}]`,
      range,
      insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
    }));

  return {
    triggerCharacters: ['['],

    provideCompletionItems: (model, position, _context, _token) => {
      if (model.uri.path !== modelPath) {
        return {
          suggestions: [],
        };
      }

      const contentUntilPosition = model.getValueInRange({
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });

      const matchPattern = /(?:key|total|ValueKey|get)\[(?<content>[^\]]*)$/;
      const match = contentUntilPosition.match(matchPattern);
      if (!match) {
        return {
          suggestions: [],
        };
      }

      return {
        suggestions: createDependencyProposals({
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: position.column - match.groups.content.length,
          endColumn: position.column,
        }),
      };
    },
  };
};

export default createKeyCompletionProvider;
