import deepEqual from 'deep-equal';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { CustomGrouping, isAdHoc } from '../../../model/custom-grouping/customGrouping';
import GroupingNodeController from '../../../model/custom-grouping/groupingNodeController';
import { GroupingStrategyType } from '../../../model/custom-grouping/groupingStrategy';
import { createTrivialErrors, isValid } from '../../../model/custom-grouping/groupingTreeErrors';
import useCustomGroupingApi from '../../../model/custom-grouping/useCustomGroupingApi';
import { useAppSelector } from '../../../redux/configureStore';
import { setGroupingTree, setSyntaxGuideOpen } from '../../../redux/custom-grouping/actions';
import { getCustomGrouping, isDrawerOpen } from '../../../redux/custom-grouping/selectors';
import { ValidationStatus } from '../../../shared/validation/model';
import useStrategyDesignerValidation from '../grouping-strategy-designer/useStrategyDesignerValidation';
import {
  createInitialViewState as createInitialDesignerViewState,
  getStrategyUpdateOperation,
  StrategyDesignerViewState,
} from '../grouping-strategy-designer/viewState';
import useCustomGroupingInfoState from '../info-view/useCustomGroupingInfoState';
import { CustomGroupingStateContext } from './useCustomGroupingState';

const CustomGroupingStateProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const isOpen = useAppSelector(isDrawerOpen);
  const username = useAppSelector(state => state.user.username);
  const api = useCustomGroupingApi();

  // `<GroupingTree />` state
  const grouping = useAppSelector(getCustomGrouping);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [intendedRenameNodeId, setIntendedRenameNodeId] = useState<string | null>(null);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const [copiedNodes, setCopiedNodes] = useState<GroupingNodeController[]>([]);
  const [hasModelController, setHasModelController] = useState(false);
  const modelControllerRef = useRef<GroupingNodeController | null>(null);
  const [isTreeValid, setIsTreeValid] = useState(false);
  const [groupingTreeErrors, setGroupingTreeErrors] = useState(createTrivialErrors());

  const setModelControllerRef = useCallback(
    (grouping: CustomGrouping) => {
      const originalIds = modelControllerRef.current?.getAllIds() ?? [];

      modelControllerRef.current =
        grouping === null ? null : GroupingNodeController.create(grouping.rootNode);
      setHasModelController(grouping !== null);

      const newSelectedIds =
        grouping === null
          ? []
          : modelControllerRef.current.getAllIds().length === 1
          ? [modelControllerRef.current.getAllIds()[0]]
          : selectedIds.filter(modelControllerRef.current.hasNode);

      // Hacky way to control update conditions -- `getAllIds` will no longer
      // satisfy `deepEqual` when the order changes, even if all IDs + selected
      // IDs are the same.
      if (
        !deepEqual(modelControllerRef.current?.getAllIds() ?? [], originalIds, { strict: true })
      ) {
        setSelectedIds(newSelectedIds);
      }

      const newGroupingTreeErrors =
        grouping === null ? createTrivialErrors() : modelControllerRef.current.getErrors();
      if (!deepEqual(groupingTreeErrors, newGroupingTreeErrors, { strict: true })) {
        setGroupingTreeErrors(newGroupingTreeErrors);
      }
    },
    [selectedIds, groupingTreeErrors],
  );

  // Validate tree state
  useEffect(() => {
    setIsTreeValid(hasModelController && isValid(groupingTreeErrors));
  }, [hasModelController, groupingTreeErrors]);

  // `<CustomGroupingInfoView />` state
  const infoState = useCustomGroupingInfoState();
  const { isNameValid, getCustomGroupingInfo } = infoState;

  // `<GroupingStrategyDesigner />` state
  const [designerStrategyType, setDesignerStrategyType] = useState<GroupingStrategyType | null>(
    null,
  );

  // TODO: Shouldn't be necessary
  const newStrategyTypeRef = useRef<GroupingStrategyType | null>(null);

  const [hasDesignerViewState, setHasDesignerViewState] = useState(false);
  const designerViewStateRef = useRef<StrategyDesignerViewState | null>(null);
  const setDesignerViewStateRef = useCallback((viewState: StrategyDesignerViewState | null) => {
    designerViewStateRef.current = viewState;
    setHasDesignerViewState(viewState !== null);
  }, []);

  const [isDesignerViewStateSynced, setIsDesignerViewStateSynced] = useState(true);
  const {
    validationStatus: designerValidationStatus,
    validate: validateDesigner,
    reset: resetDesignerValidation,
  } = useStrategyDesignerValidation();

  // Reset all state upon drawer close
  useEffect(() => {
    if (!isOpen) {
      setSelectedIds([]);
      setIntendedRenameNodeId(null);
      setCopiedNodes([]);
      setHasModelController(false);
      modelControllerRef.current = null;
      setIsTreeValid(false);
      setGroupingTreeErrors(createTrivialErrors());
      setDesignerStrategyType(null);
      newStrategyTypeRef.current = null;
      setDesignerViewStateRef(null);
      setIsDesignerViewStateSynced(true);
      resetDesignerValidation();
    }
  }, [isOpen, setDesignerViewStateRef, resetDesignerValidation]);

  // Sync model controller when Custom Grouping updates
  useEffect(() => {
    setModelControllerRef(grouping);
  }, [grouping, setModelControllerRef]);

  const updateGroupingTree = useCallback(
    (operation: (modelController: GroupingNodeController) => GroupingNodeController) => {
      if (hasModelController) {
        dispatch(setGroupingTree(operation(modelControllerRef.current).serialize()));
      }
    },
    [dispatch, hasModelController],
  );

  const setSelectedId = useCallback((id: string) => {
    setSelectedIds([id]);
  }, []);

  const toggleId = useCallback((toggledId: string) => {
    setSelectedIds(ids =>
      [...ids, toggledId].filter(id => id !== toggledId || !ids.includes(toggledId)),
    );
  }, []);

  const createNode = useCallback(
    (strategyType: GroupingStrategyType) => {
      if (hasModelController) {
        updateGroupingTree(g => g.onCreateNode(selectedIds[0], strategyType));
        // must expand so node exists
        setExpandedIds(Array.from(new Set([...expandedIds, selectedIds[0]])));

        const timeout = setTimeout(() => {
          // hacky way to wait for rows to update
          const node = modelControllerRef.current.getNodeById(selectedIds[0]);
          const targetID = node.children[node.children.length - 1].id;
          newStrategyTypeRef.current = strategyType;
          setSelectedId(targetID);

          if (strategyType !== GroupingStrategyType.CHARACTERISTIC) {
            setIntendedRenameNodeId(targetID);
          }

          clearTimeout(timeout);
        }, 100);
      }
    },
    [
      hasModelController,
      updateGroupingTree,
      selectedIds,
      setSelectedId,
      expandedIds,
      setExpandedIds,
    ],
  );

  const intendRename = useCallback(() => {
    if (hasModelController) {
      setIntendedRenameNodeId(selectedIds[0]);
    }
  }, [hasModelController, selectedIds]);

  const confirmRename = useCallback(
    (intendedName: string) => {
      if (hasModelController) {
        setIntendedRenameNodeId(null);

        const name = intendedName.trim();
        if (name !== '') {
          updateGroupingTree(g => g.onRename(selectedIds, name));
        }
      }
    },
    [hasModelController, updateGroupingTree, selectedIds],
  );

  const cancelRename = useCallback(() => {
    if (hasModelController) {
      setIntendedRenameNodeId(null);
    }
  }, [hasModelController]);

  const createDesignerViewState = useCallback(
    (strategyType: GroupingStrategyType | null) =>
      strategyType === null || !hasModelController || selectedIds.length !== 1
        ? null
        : createInitialDesignerViewState(
            strategyType,
            modelControllerRef.current.getNodeById(selectedIds[0]),
          ),
    [hasModelController, selectedIds],
  );

  const getInitialStrategyType = useCallback(
    () =>
      !hasModelController ||
      selectedIds.length !== 1 ||
      modelControllerRef.current.isRootNode(selectedIds[0])
        ? null
        : newStrategyTypeRef.current ??
          modelControllerRef.current.getNodeById(selectedIds[0]).groupingStrategy?.type ??
          GroupingStrategyType.PREDICATE,
    [hasModelController, selectedIds],
  );

  // If selection changes to a single node with a strategy
  // defined, switch the designer to that tab.
  useEffect(() => {
    const strategyType = getInitialStrategyType();

    // TODO: Hacky way to force `<GroupingStrategyDesigner />` rerender
    if (
      newStrategyTypeRef.current === null &&
      designerViewStateRef.current !== null &&
      selectedIds[0] !== designerViewStateRef.current?.nodeId
    ) {
      setSelectedIds([...selectedIds]);
    }

    if (newStrategyTypeRef.current) {
      newStrategyTypeRef.current = null;
    }

    setDesignerViewStateRef(createDesignerViewState(strategyType));
    setDesignerStrategyType(strategyType);
    setIsDesignerViewStateSynced(true);
    resetDesignerValidation();
    dispatch(setSyntaxGuideOpen(false));
  }, [
    dispatch,
    hasModelController,
    selectedIds,
    getInitialStrategyType,
    setDesignerViewStateRef,
    createDesignerViewState,
    resetDesignerValidation,
  ]);

  const updateDesignerViewState = useCallback(
    <ViewState extends StrategyDesignerViewState = StrategyDesignerViewState>(
      operation: (viewState: ViewState) => ViewState,
    ) => {
      if (hasDesignerViewState) {
        setIsDesignerViewStateSynced(false);
        setDesignerViewStateRef(operation(designerViewStateRef.current as ViewState));
        validateDesigner(designerViewStateRef.current);
      }
    },
    [hasDesignerViewState, setDesignerViewStateRef, validateDesigner],
  );

  // Upon successful designer validation, update the selected node
  useEffect(() => {
    if (
      hasModelController &&
      hasDesignerViewState &&
      designerValidationStatus === ValidationStatus.VALID &&
      !isDesignerViewStateSynced &&
      selectedIds.length === 1
    ) {
      updateGroupingTree(getStrategyUpdateOperation(designerViewStateRef.current, selectedIds));
      setIsDesignerViewStateSynced(true);
    }
  }, [
    hasModelController,
    hasDesignerViewState,
    designerValidationStatus,
    isDesignerViewStateSynced,
    selectedIds,
    updateGroupingTree,
  ]);

  const ownerMatches = useCallback(() => grouping?.owner === username, [grouping?.owner, username]);

  const allowApply = useCallback(() => hasModelController && isTreeValid, [
    hasModelController,
    isTreeValid,
  ]);

  const allowCreate = useCallback(() => hasModelController && isNameValid && isTreeValid, [
    hasModelController,
    isNameValid,
    isTreeValid,
  ]);

  const createCustomGrouping = useCallback(
    async () =>
      api.createCustomGrouping({
        ...grouping,
        ...getCustomGroupingInfo(),
        owner: username,
        id: v4(),
      }),
    [api, getCustomGroupingInfo, grouping, username],
  );

  const allowUpdate = useCallback(
    () =>
      hasModelController && isTreeValid && (isAdHoc(grouping) || (isNameValid && ownerMatches())),
    [hasModelController, grouping, isNameValid, isTreeValid, ownerMatches],
  );

  const updateCustomGrouping = useCallback(
    async () =>
      api.updateCustomGrouping({
        ...grouping,
        ...getCustomGroupingInfo(),
      }),
    [api, getCustomGroupingInfo, grouping],
  );

  const allowDelete = useCallback(
    () => hasModelController && (ownerMatches() || grouping === null || isAdHoc(grouping)),
    [hasModelController, ownerMatches, grouping],
  );

  const deleteCustomGrouping = useCallback(
    async () => (grouping?.id ? api.deleteCustomGrouping(grouping?.id) : Promise.resolve(false)),
    [api, grouping?.id],
  );

  return (
    <CustomGroupingStateContext.Provider
      value={{
        grouping,
        hasModelController,
        modelController: modelControllerRef.current,
        isTreeValid,
        groupingTreeErrors,
        updateGroupingTree,
        createNode,
        expandedIds,
        setExpandedIds,
        selectedIds,
        setSelectedId,
        toggleId,
        copiedNodes,
        copyNodes: setCopiedNodes,
        infoState,
        designerStrategyType,
        hasDesignerViewState,
        isDesignerViewStateSynced,
        designerViewState: designerViewStateRef.current,
        updateDesignerViewState,
        intendedRenameNodeId,
        intendRename,
        confirmRename,
        cancelRename,
        allowApply,
        allowCreate,
        allowUpdate,
        allowDelete,
        createCustomGrouping,
        updateCustomGrouping,
        deleteCustomGrouping,
      }}
    >
      {children}
    </CustomGroupingStateContext.Provider>
  );
};

export default CustomGroupingStateProvider;
