import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { mapData } from 'algo-react-dataviz';
import { FC, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { getDefaultContext } from '../../../redux/position-drawer/selectors';
import { useGetPortfolios } from '../portfolioDrawerHelpers';
import PortfolioTree from '../PortfolioTree';
import SearchField from '../SearchField';
import { getDrawerDateContext } from './PositionDrawer';
import { PositionDrawerContext } from './reducer';

const SelectPortfolioOrBenchmark: FC = () => {
  const [expandedAccordion, setExpandedAccordion] = useState<1 | 2>(1);
  const [benchmarkSearchTerm, setBenchmarkSearchTerm] = useState('');

  const { state, dispatch: localDispatch } = useContext(PositionDrawerContext);

  const dateContext = useAppSelector(reduxState => getDrawerDateContext(reduxState, state));
  const defaultDateContext = useAppSelector(getDefaultContext);
  const dispatch = useDispatch();

  const dispatchEnqueueSnackbar = useCallback(
    (type, message) => dispatch(enqueueSnackbar(type, message)),
    [dispatch],
  );

  const { portfolioHierarchy, benchmarkPortfolios } = useGetPortfolios(
    { type: 'asOf', dates: [dateContext] },
    defaultDateContext,
    dispatchEnqueueSnackbar,
    true,
  );

  return (
    <>
      <Accordion
        expanded={expandedAccordion === 1}
        onChange={(_e, isExpanded) => setExpandedAccordion(isExpanded ? 1 : 2)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Portfolios</Typography>
        </AccordionSummary>

        <AccordionDetails>
          {portfolioHierarchy ? (
            <PortfolioTree
              {...{ portfolioHierarchy }}
              selectionMode='single'
              onItemSelectionChanged={newSelection =>
                // Allow selection of leaf nodes only
                mapData(portfolioHierarchy, 0).some(p => p.id === newSelection[0] && !p.children) &&
                localDispatch({
                  type: 'setSelectedPortfolioOrBenchmark',
                  selectedPortfolioOrBenchmark: { type: 'portfolio', id: newSelection[0] },
                })
              }
              selectedKeys={
                state.selectedPortfolioOrBenchmark?.type === 'portfolio'
                  ? [state.selectedPortfolioOrBenchmark.id]
                  : []
              }
            />
          ) : (
            <CircularProgress />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedAccordion === 2}
        onChange={(_e, isExpanded) => setExpandedAccordion(isExpanded ? 2 : 1)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography>Benchmarks</Typography>
        </AccordionSummary>

        <AccordionDetails className='benchmark-accordion-details'>
          <SearchField searchTerm={benchmarkSearchTerm} setSearchTerm={setBenchmarkSearchTerm} />
          <div className='benchmark-list'>
            {benchmarkPortfolios
              ?.filter(id => id.toUpperCase().includes(benchmarkSearchTerm.toUpperCase()))
              .map((id, key) => (
                <div
                  {...{ key }}
                  className={`benchmark-item ${
                    state.selectedPortfolioOrBenchmark?.type === 'benchmark' &&
                    state.selectedPortfolioOrBenchmark.id === id
                      ? 'benchmark-item-selected'
                      : ''
                  }`}
                  onClick={() =>
                    localDispatch({
                      type: 'setSelectedPortfolioOrBenchmark',
                      selectedPortfolioOrBenchmark: { type: 'benchmark', id },
                    })
                  }
                >
                  <Typography>{id}</Typography>
                </div>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SelectPortfolioOrBenchmark;
