import { FC } from 'react';
import { AuthConsumer } from './AuthProvider';

export const Logout: FC = () => (
  <AuthConsumer>
    {({ oidcLogout: logout }) => {
      logout();
      return <span>loading</span>;
    }}
  </AuthConsumer>
);
