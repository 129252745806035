import { CustomGrouping, isAdHoc } from '../../model/custom-grouping/customGrouping';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import { AppState } from '../configureStore';
import { isDesignerOpen } from '../ui/selectors';
import { CustomGroupingDrawerStatus } from './state';

export const getDrawerStatus = (state: AppState) => state.customGrouping.drawerStatus;
export const isDrawerOpen = (state: AppState) => state.customGrouping.drawerStatus !== null;
export const isAdHocDrawer = (state: AppState) =>
  isDrawerOpen(state) && state.customGrouping.grouping?.name === null;

export const isSyntaxGuideOpen = (state: AppState) => state.customGrouping.syntaxGuideOpen;

export const getCustomGrouping = (state: AppState) => state.customGrouping.grouping;
export const isCustomGroupingInReport = (grouping: CustomGrouping | null) => (state: AppState) =>
  grouping !== null &&
  isDesignerOpen(state) &&
  (isAdHoc(grouping) ||
    [
      ...state.report.reportDefinition[DESIGNER_SEQUENCE_ID]?.verticalChars,
      ...state.report.reportDefinition[DESIGNER_SEQUENCE_ID]?.horizontalChars,
    ].some(layerDefinition => layerDefinition.customGrouping?.id === grouping?.id));

export const showCustomGroupingInfo = (state: AppState) =>
  [CustomGroupingDrawerStatus.EDIT, CustomGroupingDrawerStatus.VIEW].includes(
    state.customGrouping.drawerStatus,
  ) && !isAdHocDrawer(state);

export const canUserEditGrouping = (state: AppState) => (owner: string | null) =>
  [state.user.userInfo.username, null].includes(owner);

export const isSaveModalOpen = (state: AppState) => state.customGrouping.saveModalOpen;
