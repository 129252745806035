import { CustomGrouping, GroupingNode } from '../../model/custom-grouping/customGrouping';

export enum CustomGroupingActionType {
  OPEN_CREATE_DRAWER = 'custom-grouping/openCreateDrawer',
  OPEN_EDIT_DRAWER = 'custom-grouping/openEditDrawer',
  OPEN_VIEW_DRAWER = 'custom-grouping/openViewDrawer',
  CLOSE_DRAWER = 'custom-grouping/closeDrawer',

  SET_SYNTAX_GUIDE_OPEN = 'custom-grouping/setSyntaxGuideOpen',

  SET_GROUPING_TREE = 'custom-grouping/setGroupingTree',
  SET_SAVE_MODAL_OPEN = 'custom-grouping/setSaveModalOpen',
  SET_GROUPING_FOR_SAVE = 'custom-grouping/setGroupingForSave',
}

export type CustomGroupingAction =
  | ReturnType<typeof openCreateDrawer>
  | ReturnType<typeof openEditDrawer>
  | ReturnType<typeof openViewDrawer>
  | ReturnType<typeof closeDrawer>
  | ReturnType<typeof setSyntaxGuideOpen>
  | ReturnType<typeof setSaveModalOpen>
  | ReturnType<typeof setGroupingForSave>
  | ReturnType<typeof setGroupingTree>;

export const openCreateDrawer = () =>
  ({
    type: CustomGroupingActionType.OPEN_CREATE_DRAWER,
  } as const);

export const openEditDrawer = (grouping: CustomGrouping) =>
  ({
    type: CustomGroupingActionType.OPEN_EDIT_DRAWER,
    payload: grouping,
  } as const);

export const setSaveModalOpen = (saveModalOpen: boolean) =>
  ({
    type: CustomGroupingActionType.SET_SAVE_MODAL_OPEN,
    payload: saveModalOpen,
  } as const);

export const openViewDrawer = (grouping: CustomGrouping) =>
  ({
    type: CustomGroupingActionType.OPEN_VIEW_DRAWER,
    payload: grouping,
  } as const);

export const setGroupingForSave = (grouping: CustomGrouping) =>
  ({
    type: CustomGroupingActionType.SET_GROUPING_FOR_SAVE,
    payload: grouping,
  } as const);

export const closeDrawer = () =>
  ({
    type: CustomGroupingActionType.CLOSE_DRAWER,
  } as const);

export const setSyntaxGuideOpen = (open: boolean) =>
  ({
    type: CustomGroupingActionType.SET_SYNTAX_GUIDE_OPEN,
    payload: open,
  } as const);

export const setGroupingTree = (tree: GroupingNode | null) =>
  ({
    type: CustomGroupingActionType.SET_GROUPING_TREE,
    payload: tree,
  } as const);
