import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { requestSetContextPermissionRule } from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';

const mapStateToProps = (state: AppState) => {
  if (!state.admin.allPermissions) return { isLoading: true };
  else
    return {
      rules: state.admin.allPermissions.contextPermissions.filter(
        c => c.groupName === state.admin.selectedGroup,
      ),
      canModify: state.user.userInfo?.serverConfigs?.contextPermissions.canModify,
    };
};
const mapDispatchToProps = { requestSetContextPermissionRule };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ContextPermissionsPanel: FC<Props> = props => {
  const { fullAccess, base, intraday, intradayWildcard, restrictToPublished, authorizedToPublish } =
    props.rules?.[0] || {};

  const [wildcard, setWildcard] = useState<string>();

  const { groupName } = props.rules?.[0] || {};

  useEffect(() => {
    setWildcard(undefined);
  }, [groupName]);

  const getWildcardValue = () => {
    if (!fullAccess && intraday) {
      if (wildcard === undefined) return intradayWildcard || '';
      else return wildcard;
    } else return '';
  };

  const isRestrictToPublishedChecked = () => {
    if (fullAccess) return false;
    else if (!base && !intraday) return false;
    else return restrictToPublished || false;
  };

  const isRestrictToPublishEnabled = () => {
    if (fullAccess) return false;
    else if (!base && !intraday) return false;
    else return true;
  };

  return props.isLoading ? (
    <div style={{ flex: 1, alignSelf: 'center', marginTop: '20px' }}>
      <CircularProgress />
    </div>
  ) : props.rules.length > 1 ? (
    <Typography style={{ padding: '5px' }}>
      This role has more than one context permission rule. Context permissions cannot be viewed or
      edited in Stadium for roles with more than one rule.
    </Typography>
  ) : (
    <>
      {props.canModify ? (
        <div>
          <FormGroup style={{ paddingTop: '12px' }}>
            <FormControlLabel
              style={{ paddingLeft: '12px' }}
              control={
                <Switch
                  color='primary'
                  checked={fullAccess || false}
                  onChange={() =>
                    props.requestSetContextPermissionRule({ fullAccess: !fullAccess })
                  }
                />
              }
              label='Full Access'
            />
            <FormControlLabel
              style={{ paddingLeft: '24px' }}
              control={
                <Checkbox
                  color='primary'
                  disabled={fullAccess}
                  checked={base || fullAccess || false}
                  onChange={() => props.requestSetContextPermissionRule({ base: !base })}
                />
              }
              label='Base'
            />
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                style={{ paddingLeft: '24px' }}
                control={
                  <Checkbox
                    color='primary'
                    disabled={fullAccess}
                    checked={intraday || fullAccess || false}
                    onChange={() => props.requestSetContextPermissionRule({ intraday: !intraday })}
                  />
                }
                label='Intraday with Wildcard'
              />
              <TextField
                size='small'
                variant='outlined'
                disabled={fullAccess || !intraday}
                value={getWildcardValue()}
                onChange={e => setWildcard(e.target.value)}
                onBlur={() => props.requestSetContextPermissionRule({ intradayWildcard: wildcard })}
                onKeyDown={e =>
                  e.key === 'Enter' &&
                  props.requestSetContextPermissionRule({ intradayWildcard: wildcard })
                }
              />
            </div>
            <FormControlLabel
              style={{ paddingLeft: '24px' }}
              control={
                <Checkbox
                  color='primary'
                  disabled={!isRestrictToPublishEnabled()}
                  checked={isRestrictToPublishedChecked()}
                  onChange={() =>
                    props.requestSetContextPermissionRule({
                      restrictToPublished: !restrictToPublished,
                    })
                  }
                />
              }
              label='Restrict to Published Only'
            />
          </FormGroup>
          <hr style={{ width: '100%' }} />

          <FormControlLabel
            style={{ paddingLeft: '12px' }}
            control={
              <Checkbox
                color='primary'
                checked={authorizedToPublish || false}
                onChange={() =>
                  props.requestSetContextPermissionRule({
                    authorizedToPublish: !authorizedToPublish,
                  })
                }
              />
            }
            label='Authorized to publish contexts'
          />
        </div>
      ) : (
        <InputLabel variant='filled'>Context permissioning is disabled</InputLabel>
      )}
    </>
  );
};
export default connector(ContextPermissionsPanel);
