import { IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/configureStore';
import './position-instrument.scss';
import { Position, PositionDrawerContext, PositionInstrument } from './reducer';

type MenuOption = { id: keyof Position; display: string };

interface Props {
  instrument: PositionInstrument;
}

const PositionInstrumentItem: FC<Props> = props => {
  const [type, setType] = useState<keyof Position>(
    props.instrument.isFund ? 'fundUnits' : 'positionUnits',
  );

  useEffect(() => {
    const populated = populatedProperty(props.instrument);
    if (populated && type !== populated) setType(populated);
  }, [props.instrument, type]);

  const { state, dispatch } = useContext(PositionDrawerContext);
  const { currencies } = useAppSelector(state => state.user.userInfo.serverConfigs) || {};
  const userCcy = useAppSelector(state => state.user?.userInfo?.userPreferences?.selectedCurrency);

  const typeOptions: MenuOption[] = props.instrument.isFund
    ? [
        { id: 'fundUnits', display: 'Fund Units' },
        { id: 'fundWeight', display: 'Fund Weight' },
      ]
    : [
        { id: 'positionUnits', display: 'Position Units' },
        state.selectedPortfolioOrBenchmark?.type === 'portfolio'
          ? { id: 'marketValue', display: 'Market Value' }
          : { id: 'weight', display: 'Weight' },
      ];

  return (
    <div className='position-instrument'>
      <div className='header'>
        <Typography variant='body1'>{props.instrument.name}</Typography>
        <IconButton
          onClick={() =>
            dispatch({
              type: 'removeSelectedInstrument',
              instrumentId: props.instrument.id,
            })
          }
        >
          <Close color='error' />
        </IconButton>
      </div>

      <div className='selectors'>
        <div className='value'>
          <TextField
            variant='outlined'
            size='small'
            value={props.instrument[type] || ''}
            onChange={e =>
              dispatch({
                type: 'changeInstrument',
                instrumentId: props.instrument.id,
                propertyName: type,
                propertyValue: e.target.value,
              })
            }
          />
          {['weight', 'fundWeight'].includes(type) && (
            <Typography className='percent'>%</Typography>
          )}
        </div>

        <TextField
          variant='outlined'
          size='small'
          className='type'
          select
          value={type}
          onChange={e => {
            dispatch({ type: 'clearInstrument', instrumentId: props.instrument.id, userCcy });
            setType(e.target.value as keyof Position);
          }}
        >
          {typeOptions?.map(option => (
            <MenuItem value={option.id} key={option.id}>
              {option.display}
            </MenuItem>
          ))}
        </TextField>

        {type === 'marketValue' ? (
          <TextField
            variant='outlined'
            size='small'
            className='currency'
            select
            value={props.instrument.currency || ''}
            onChange={e =>
              dispatch({
                type: 'changeInstrument',
                instrumentId: props.instrument.id,
                propertyName: 'currency',
                propertyValue: e.target.value,
              })
            }
          >
            {currencies.map(c => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <div className='currency' />
        )}
      </div>
    </div>
  );
};

const populatedProperty = (pos: PositionInstrument) => {
  const options: (keyof Position)[] = [
    'positionUnits',
    'fundUnits',
    'marketValue',
    'weight',
    'fundWeight',
  ];

  return options.find(o => pos[o]);
};

export default PositionInstrumentItem;
