import { Tab, Tabs } from '@material-ui/core';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openGroupDrawerLike } from '../../redux/adminActionCreators';
import UnfoldAccordion from '../shared/UnfoldAccordion';
import './groups-tabs.scss';
import AccessPermissionsPanel from './panels/AccessPermissionsPanel';
import ComponentPermissions from './panels/ComponentPermissions';
import ContextPermissionsPanel from './panels/ContextPermissionsPanel';
import GroupInfoPanel from './panels/GroupInfoPanel';
import GroupMembersPanel from './panels/GroupMembersPanel';
import InfoPanelFooter from './panels/InfoPanelFooter';
import PortfolioPermissions from './panels/PortfolioPermissions';

enum TabValues {
  GENERAL = 'General',
  PERMISSIONS = 'Permissions',
  ACCESS = 'Access',
}

const GroupsTabs: FC = () => {
  const [tab, setTab] = useState(TabValues.GENERAL);
  const dispatch = useDispatch();

  return (
    <div className='groups-tabs'>
      <Tabs
        value={tab}
        indicatorColor='primary'
        textColor='primary'
        onChange={(_e, n) => setTab(n)}
      >
        <Tab label='General' value={TabValues.GENERAL} />
        <Tab label='Permissions' value={TabValues.PERMISSIONS} />
        <Tab label='Access' value={TabValues.ACCESS} />
      </Tabs>
      <div className='tab-body'>
        <div hidden={tab !== TabValues.GENERAL}>
          <UnfoldAccordion summary='Role Info'>
            <GroupInfoPanel />
          </UnfoldAccordion>

          <UnfoldAccordion summary='Role Members' defaultExpanded>
            <GroupMembersPanel />
          </UnfoldAccordion>
        </div>
        <div hidden={tab !== TabValues.PERMISSIONS}>
          <UnfoldAccordion summary='Context Permissions'>
            <ContextPermissionsPanel />
          </UnfoldAccordion>

          <UnfoldAccordion summary='Portfolio Permissions' defaultExpanded>
            <PortfolioPermissions />
          </UnfoldAccordion>

          <UnfoldAccordion summary='Component Permissions'>
            <ComponentPermissions />
          </UnfoldAccordion>
        </div>
        <AccessPermissionsPanel hidden={tab !== TabValues.ACCESS} />
      </div>
      <InfoPanelFooter onCreateClick={() => dispatch(openGroupDrawerLike())} />
    </div>
  );
};

export default GroupsTabs;
