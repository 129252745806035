import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeEditor } from '../../../redux/aScriptEditor/actions';
import MainModal from '../../main/MainModal';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const CloseConfirmationModal: FC<Props> = props => {
  const dispatch = useDispatch();
  const requestClose = useCallback(() => dispatch(closeEditor()), [dispatch]);

  return (
    <MainModal
      open={props.open}
      headerText={'Leaving aScript editor'}
      onRequestSubmit={() => {
        requestClose();
        props.setOpen(false);
      }}
      onRequestClose={() => {
        props.setOpen(false);
      }}
    >
      Are you sure you want to leave? Edits will be discarded.
    </MainModal>
  );
};
export default CloseConfirmationModal;
