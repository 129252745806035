import Close from '@material-ui/icons/Close';
import { ReportRawData, ReportRequestStatus, ReportUpdateHighlightMode } from 'algo-react-dataviz';
import { FC, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect, ConnectedProps } from 'react-redux';
import { ResponsiveContainer } from 'recharts';
import { AppState } from '../../redux/configureStore';
import {
  resetLayoutComplication,
  sendReportUpdateMessage,
  setComplicationSelection,
  setLayoutComplication,
} from '../../redux/ReportActionCreators';
import { fetchSandboxes } from '../../redux/UserProfileActionCreators';
import { DESIGNER_SEQUENCE_ID, VerticalPosition } from '../../shared/constants';
import { Sandbox, UISlot } from '../../shared/dataTypes';
import ReportErrorBoundary from '../../shared/ReportErrorBoundary';
import {
  getSelectedVisualization,
  nextRequestId,
  VISUALIZATIONS_WITH_LAYOUT,
} from '../../shared/utils';
import BottomTagRow from './BottomTagRow';
import CloseButton from './CloseButton';
import './Report.scss';
import { ReportContext } from './reportContext';
import ReportError from './ReportError';
import ReportProgress from './ReportProgress';
import TopTagRow from './TopTagRow';
import TopUiSlotRow from './TopUiSlotRow';
import UiSlotsRow from './UiSlotsRow';
import Visualization from './Visualization';

interface BaseProps {
  context: ReportContext;
  sequenceId: number;
  parentSequenceId?: number;
  live?: boolean;
  detailList?: boolean;
  drillThrough: boolean;
  parentSelectedElements?: string[];
  actualParentSelectedElements?: string[];
  sandbox?: Sandbox;
  fetchDetailList?: (sequenceId?: any, parentSelectedElements?: any) => void;
  showCloseReportModal?: (sequenceId: number, reportTitle: string) => void;
}

const mapStateToProps = (state: AppState, ownProps: BaseProps) => {
  const {
    sequenceId,
    parentSequenceId,
    live,
    parentSelectedElements,
    actualParentSelectedElements,
  } = ownProps;

  return {
    reportDefinition: state.report.reportDefinition?.[sequenceId],
    progress: state.progress[sequenceId],
    reportRawData: state.report.reportData?.[sequenceId]?.raw,
    parentSelectedElements:
      (parentSequenceId || parentSequenceId === 0) && parentSequenceId !== DESIGNER_SEQUENCE_ID
        ? live
          ? actualParentSelectedElements
          : parentSelectedElements
        : null,
  };
};

const mapDispatchToProps = {
  sendReportUpdateMessage,
  setComplicationSelection,
  setLayoutComplication,
  resetLayoutComplication,
  fetchSandboxes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const Report: FC<Props> = props => {
  const { fetchSandboxes } = props;
  useEffect(() => fetchSandboxes(), [fetchSandboxes]);

  const updateReport = (slotDetails: UISlot, changedValue: string) => {
    const selectedOption: string = changedValue.split('selectedOption,')[1];
    const {
      reportDefinition: { legacyReport },
      resetLayoutComplication,
      sendReportUpdateMessage,
      sequenceId,
      setComplicationSelection,
      setLayoutComplication,
    } = props;

    if (slotDetails.complicationDetails.type === 'VisualizationComplication') {
      if (VISUALIZATIONS_WITH_LAYOUT.includes(selectedOption)) {
        // dispatch setting of a Layout complication
        setLayoutComplication(sequenceId);
      } else resetLayoutComplication(sequenceId);
    }

    setComplicationSelection(sequenceId, slotDetails.complicationDetails.id, selectedOption);

    if (slotDetails.complicationDetails.editRequiresServer) {
      const requestId = `seq-${sequenceId}-req-${nextRequestId()}`;
      sendReportUpdateMessage(
        { sequenceId, requestId, legacyReport },
        slotDetails.complicationDetails.highlightMode,
        ReportRequestStatus.REFRESHING,
      );
    }
  };

  const handleCloseReport = () =>
    props.showCloseReportModal(
      props.sequenceId,
      props.reportDefinition?.reportTitle || 'this drill through',
    );

  const regenerate = () => {
    const { sequenceId, parentSelectedElements, sendReportUpdateMessage } = props;
    const requestId = `seq-${sequenceId}-req-${nextRequestId()}`;
    sendReportUpdateMessage(
      { sequenceId, requestId, parentSelectedElements, adhoc: true },
      ReportUpdateHighlightMode.CELL_CHANGE,
      ReportRequestStatus.REGENERATING,
    );
  };

  // Defining this here so it can be conditionally wrapped below
  const visualization = (
    <Visualization
      context={props.context}
      sequenceId={props.sequenceId}
      fetchDetailList={props.fetchDetailList}
    />
  );

  return (
    <div className='report'>
      {props.context === ReportContext.CARD && (
        <Close
          style={{
            height: '16px',
            position: 'absolute',
            right: ' 0.25rem',
            top: '0.25rem',
            cursor: 'pointer',
            color: '#8d8d8d',
          }}
          className='hide-in-pdf'
          onClick={handleCloseReport}
        />
      )}
      <TopTagRow
        context={props.context}
        detailList={props.detailList}
        drillThrough={props.drillThrough}
        sandbox={props.sandbox}
        sequenceId={props.sequenceId}
      />
      <TopUiSlotRow
        sequenceId={props.sequenceId}
        reportDefinition={props.reportDefinition}
        sandbox={props.sandbox}
        detailList={props.detailList}
        drillThrough={props.drillThrough}
        context={props.context}
        regenerate={regenerate}
        updateReport={updateReport}
        handleCloseReport={handleCloseReport}
      />
      {hasError(props.reportRawData) ? (
        <ReportError
          sequenceId={props.sequenceId}
          handleCloseReport={props.context === ReportContext.CARD && handleCloseReport}
          errMessage={props.reportRawData.errMessage}
          errCode={props.reportRawData.errCode}
          stackTrace={props.reportRawData.stackTrace}
        />
      ) : (
        <>
          {!props.reportRawData || props.reportRawData.isLoading ? (
            <div className='report-loading margin-horiz hide-in-pdf'>
              {props.context === ReportContext.CARD && <CloseButton onClick={handleCloseReport} />}
              <ReportProgress sequenceId={props.sequenceId} />
            </div>
          ) : (
            <>
              <div className='margin-horiz' style={{ flexGrow: 1, minHeight: 0 }}>
                <ErrorBoundary
                  FallbackComponent={ReportErrorBoundary}
                  resetKeys={[props.reportDefinition, props.reportRawData]}
                >
                  {getSelectedVisualization(props.reportDefinition) === 'DX_TABLE' ? (
                    visualization
                  ) : (
                    <ResponsiveContainer className='nonDraggable' minWidth='100%' minHeight='100px'>
                      {visualization}
                    </ResponsiveContainer>
                  )}
                </ErrorBoundary>
              </div>
              <UiSlotsRow
                reportDefinition={props.reportDefinition}
                updateReport={updateReport}
                filteredUiSlots={(props.reportDefinition?.slotComplications || []).filter(
                  ({ verticalPosition }) => verticalPosition === VerticalPosition.BOTTOM,
                )}
              />
            </>
          )}
        </>
      )}

      {props.context === ReportContext.CARD && (
        <BottomTagRow
          detailList={props.detailList}
          drillThrough={props.drillThrough}
          live={props.live}
          sequenceId={props.sequenceId}
          actualParentSelectedElements={props.actualParentSelectedElements}
        />
      )}
    </div>
  );
};

const hasError = (report: ReportRawData) => !!(report?.errCode || report?.errMessage);

export default connector(Report);
