import { IconButton, Tooltip } from '@material-ui/core';
import { HelpOutline as HelpIcon } from '@material-ui/icons';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../redux/configureStore';
import { setSyntaxGuideOpen } from '../../../../redux/custom-grouping/actions';
import { isSyntaxGuideOpen } from '../../../../redux/custom-grouping/selectors';
import './folia-syntax-guide-button.scss';

const FoliaSyntaxGuideButton: FC = () => {
  const dispatch = useDispatch();
  const open = useAppSelector(isSyntaxGuideOpen);

  const onClick = useCallback(() => {
    dispatch(setSyntaxGuideOpen(!open));
  }, [dispatch, open]);

  return (
    <Tooltip title='Language guide' placement='right' arrow>
      <IconButton className='folia-syntax-guide-button' {...{ onClick }}>
        <HelpIcon className='folia-syntax-guide-button-icon' />
      </IconButton>
    </Tooltip>
  );
};

export default FoliaSyntaxGuideButton;
