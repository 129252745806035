import { useCallback, useEffect, useMemo } from 'react';
import { ApiRequestStatus } from '../../../api/apiData';
import useGetRequestClient from '../../../api/useGetRequestClient';
import { baseUrl } from '../../../components/shared/environment';
import { Characteristic } from '../../../shared/dataTypes';
import { EditorLanguage } from '../../editorLanguage';
import EditorModelService from '../../EditorModelService';
import useMemoizedMonaco from '../../memoized-monaco/useMemoizedMonaco';
import CompletionProviderService from './completionProviderService';
import { CompletionProviderType } from './completionProviderType';
import createKeyCompletionProvider from './keyCompletionProvider';
import useCompletionProviderService from './useCompletionProviderService';

const useKeyCompletion = (modelId: string, language: EditorLanguage) => {
  const monaco = useMemoizedMonaco();
  const service = useCompletionProviderService(language);
  const id = useMemo(() => CompletionProviderService.getId(CompletionProviderType.KEY, modelId), [
    modelId,
  ]);

  const { data: characteristics, apiData } = useGetRequestClient<Characteristic[]>({
    url: `${baseUrl}api/availableCharacteristics`,

    // TODO: Ensure consistency when custom grouping is implemented
    transform: useCallback(chars => chars.filter(char => !char.isGroupingLayer), []),
  });

  useEffect(() => {
    if (!monaco || !service) {
      return;
    }

    if (
      [ApiRequestStatus.PRISTINE, ApiRequestStatus.COMPLETE].includes(apiData.status) &&
      characteristics?.length
    ) {
      service.registerProvider(
        id,
        createKeyCompletionProvider(EditorModelService.getPath(modelId, language), characteristics),
      );
    } else {
      service.clearProvider(id);
    }

    return () => {
      service?.clearProvider(id);
    };
  }, [monaco, modelId, language, id, service, apiData.status, characteristics]);
};

export default useKeyCompletion;
