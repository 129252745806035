import { FC, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchPermissions } from '../../redux/adminActionCreators';
import GroupsTabs from './GroupsTabs';
import GroupsPanel from './panels/GroupsPanel';

const mapDispatchToProps = { fetchPermissions };

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

// Destructuring props to make useEffect ESLint happy
const Groups: FC<Props> = ({ fetchPermissions }) => {
  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <div
      style={{
        flex: 1,
        display: 'grid',
        gridTemplateAreas: '"groups tabs"',
        gridTemplateColumns: '2fr minmax(auto, 1fr)',
        minHeight: 0,
      }}
    >
      <GroupsPanel />
      <GroupsTabs />
    </div>
  );
};

export default connector(Groups);
