import { Fade, Popover } from '@material-ui/core';
import { FC, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../../redux/configureStore';
import { isSyntaxGuideOpen } from '../../../../redux/custom-grouping/selectors';
import './folia-syntax-guide-anchor.scss';
import FoliaSyntaxGuide from './FoliaSyntaxGuide';

const FoliaSyntaxGuideAnchor: FC = () => {
  const syntaxGuideOpen = useAppSelector(isSyntaxGuideOpen);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setAnchorEl(syntaxGuideOpen ? ref.current : null);
  }, [syntaxGuideOpen]);

  return (
    <>
      <div className='folia-syntax-guide-anchor' {...{ ref }} />

      <Popover
        PaperProps={{ variant: 'outlined' }}
        TransitionComponent={Fade}
        className='folia-syntax-guide-popover'
        disableAutoFocus
        disableEnforceFocus
        disablePortal
        open={!!anchorEl}
        {...{ anchorEl }}
        anchorReference='anchorEl'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className='folia-syntax-guide-popover-content'>
          <FoliaSyntaxGuide />
        </div>
      </Popover>
    </>
  );
};

export default FoliaSyntaxGuideAnchor;
