import { IconButton, Tooltip } from '@material-ui/core';
import { Category as CategoryIcon } from '@material-ui/icons';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openCreateDrawer } from '../../../redux/custom-grouping/actions';

const AddCustomGroupingButton: FC = () => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(openCreateDrawer());
  }, [dispatch]);

  return (
    <Tooltip title='Add Custom Grouping'>
      <IconButton aria-label='Add Custom Grouping' {...{ onClick }}>
        <CategoryIcon style={{ height: '18px', fontSize: '18px', color: '#00677F' }} />
      </IconButton>
    </Tooltip>
  );
};

export default AddCustomGroupingButton;
