import { MenuItem, MenuItemProps } from '@material-ui/core';
import { ReactNode } from 'react';

export interface ItemProps {
  value: string | number;
  label?: ReactNode;
  key?: string | number;
  muiProps?: MenuItemProps<'li', { button?: true }>;
  // (cf. https://github.com/mui-org/material-ui/issues/16245, fixed in latest)
}

export type Item = string | number | ItemProps;

// This isn't a component, but rather returns an array of elements,
// because options for mui's `<Select />` must be direct children.
const MenuItems = (items: Item[] = []) => {
  const process = (item: Item) =>
    typeof item === 'string' || typeof item === 'number' ? { value: item } : item;

  const createComponent = ({ value, label = value, key = value, muiProps = {} }: ItemProps) => (
    <MenuItem {...muiProps} {...{ value, key }}>
      {label}
    </MenuItem>
  );

  return (items ?? []).map(item => createComponent(process(item)));
};

export default MenuItems;
