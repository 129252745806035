import { RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { saveMetadata } from '../../../redux/metadata/actionCreators';
import { setCharToCopy, setSelectedChar } from '../../../redux/metadata/actions';
import { getMetadataSandboxPath } from '../../../redux/metadata/selectors';
import { CharacteristicDrawerType, NotificationLevel } from '../../../shared/constants';
import ResizableDrawerWrapper from '../../drawers/ResizableDrawerWrapper';
import { baseUrl } from '../../shared/environment';
import './characteristic-drawer.scss';
import CharacteristicDrawerContent from './CharacteristicDrawerContent';
import {
  CharacteristicDrawerForm,
  createInitialRiskComputationForm,
  Metadata,
  PropertiesForm,
  RiskComputationForm,
} from './characteristicDrawerForm';
import CharacteristicDrawerHeader from './CharacteristicDrawerHeader';

const mapStateToProps = (state: AppState) => ({
  selectedChar: state.metadata.selectedChar,
  sandboxPath: getMetadataSandboxPath(state),
});

const mapDispatchToProps = { setSelectedChar, saveMetadata, enqueueSnackbar, setCharToCopy };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const EditCharacteristicDrawer: FC<Props> = props => {
  const [riskComputationValues, setRiskComputationValues] = useState<RiskComputationForm | null>();
  const [propertiesValues, setPropertiesValues] = useState<PropertiesForm | null>();
  const [metadataWritePermission, setMetadataWritePermission] = useState(false);

  const { selectedChar, sandboxPath, enqueueSnackbar } = props;
  useEffect(() => {
    if (!selectedChar) return;
    // setting to null so we don't open drawer with old metadata form content
    setPropertiesValues(null);
    setRiskComputationValues(null);
    axios
      .get<Metadata>(`${baseUrl}api/metadata`, {
        params: {
          valueKey: selectedChar,
          sandboxPath,
        },
      })
      .then(response => {
        const { computeContext, ...properties } = response.data;
        setPropertiesValues(properties);
        setRiskComputationValues(computeContext || createInitialRiskComputationForm());
      })
      .catch((error: Error) =>
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to retrieve metadata properties: ${error.message}`,
        ),
      );

    axios
      .get<boolean>(`${baseUrl}api/canWriteMetadata`)
      .then(response => setMetadataWritePermission(response.data))
      .catch((error: Error) => {
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to retrieve metadata write permission: ${error.message}`,
        );
      });
  }, [setPropertiesValues, setRiskComputationValues, selectedChar, sandboxPath, enqueueSnackbar]);

  const initialValues: CharacteristicDrawerForm = {
    ...propertiesValues,
    ...riskComputationValues,
  };

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: !!selectedChar && !!propertiesValues && !!riskComputationValues,
        onClose: () => props.setSelectedChar(undefined),
        className: 'characteristic-drawer',
      }}
      id='EditCharacteristicDrawer'
      defaultWidth={480}
    >
      <CharacteristicDrawerHeader
        headerText={propertiesValues?.colName}
        onXClick={() => props.setSelectedChar(undefined)}
      />

      <CharacteristicDrawerContent
        {...{ initialValues }}
        closeDrawer={() => props.setSelectedChar(undefined)}
        canWrite={metadataWritePermission}
        onSubmit={values => {
          props.saveMetadata(values, 'PUT');
          props.setSelectedChar(undefined);
        }}
        characteristicDrawerType={CharacteristicDrawerType.EDIT}
      >
        {metadataWritePermission && (
          <RoundedButton
            color='primary'
            onClick={() => {
              props.setSelectedChar(undefined);
              props.setCharToCopy(initialValues);
            }}
          >
            Create a Copy
          </RoundedButton>
        )}
      </CharacteristicDrawerContent>
    </ResizableDrawerWrapper>
  );
};

export default connector(EditCharacteristicDrawer);
