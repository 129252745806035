import { DrawerProps } from '@material-ui/core';
import { ResizableDrawer } from 'algo-react-dataviz';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestSetDrawerWidths } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';

interface Props {
  drawerProps: DrawerProps;
  defaultWidth?: number;
  id: string;
}

const ResizableDrawerWrapper: FC<Props> = props => {
  const dispatch = useDispatch();
  const savedDrawerWidth = useSelector(
    (state: AppState) => state.user?.userInfo?.userPreferences?.drawerWidths?.[props.id],
  );

  return (
    <ResizableDrawer
      defaultWidth={savedDrawerWidth || props.defaultWidth || 368}
      drawerProps={props.drawerProps}
      minimumWidth={props.defaultWidth}
      onResizeDone={width => dispatch(requestSetDrawerWidths(props.id, width))}
    >
      {props.children}
    </ResizableDrawer>
  );
};

export default ResizableDrawerWrapper;
