import { FormControlLabel, Switch } from '@material-ui/core';
import { LIGHT_BORDER, RoundedButton } from 'algo-react-dataviz';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateReportPreview, setIsAutoGenerate } from '../../redux/ActionCreators';
import { useAppSelector } from '../../redux/configureStore';
import { sendWebSocketMessage } from '../../redux/ReportActionCreators';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import DesignerPanelReportButton from './DesignerPanelReportButton';

interface Props {
  canGenerate: boolean;
  generateReportPreview: () => void;
  handleCancel: () => void;
  hidden?: boolean;
}

const DesignerPanelFooter: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const [disableGenerate, setDisableGenerate] = useState(false);

  const shouldRegenerate = useAppSelector(
    state => state.reportDesigner.panelControl.shouldRegenerate,
  );

  const isAutoGenerate = useAppSelector(state => state.reportDesigner.panelControl.isAutoGenerate);

  useEffect(() => {
    if (shouldRegenerate) {
      setDisableGenerate(true);
      setTimeout(() => {
        setDisableGenerate(false);
      }, 5000);
    }
  }, [shouldRegenerate]);

  const cancelReportGeneration = () =>
    dispatch(
      sendWebSocketMessage(
        { requestType: 'cancelReportGeneration', sequenceId: DESIGNER_SEQUENCE_ID },
        null,
        (error: Error) => console.error('Failed to cancel report generation: ' + error.message),
      ),
    );

  return (
    <div
      style={{
        gridArea: 'footer',
        backgroundColor: '#fff',
        display: 'grid',
        gridTemplateColumns: 'auto auto 1fr auto auto',
        gridGap: '12px',
        alignItems: 'center',
        borderTop: LIGHT_BORDER,
      }}
      hidden={props.hidden}
    >
      <div
        style={{
          alignSelf: 'stretch',
          paddingLeft: '12px',
          backgroundColor: '#fafcfd',
          borderRight: LIGHT_BORDER,
          display: 'grid',
        }}
      >
        <FormControlLabel
          control={
            <Switch
              color='primary'
              checked={isAutoGenerate}
              onChange={e => {
                dispatch(setIsAutoGenerate(e.target.checked));
                if (!e.target.checked) {
                  cancelReportGeneration();
                }
              }}
            />
          }
          label='Auto-Generate'
          style={{ marginBottom: 0 }}
        />
      </div>
      <RoundedButton
        disabled={isAutoGenerate || !props.canGenerate || disableGenerate}
        color='primary'
        onClick={() => {
          dispatch(generateReportPreview());
        }}
      >
        Generate Preview
      </RoundedButton>
      <RoundedButton
        onClick={props.handleCancel}
        variant='outlined'
        color='primary'
        style={{ justifySelf: 'end' }}
      >
        Cancel
      </RoundedButton>
      <DesignerPanelReportButton />
    </div>
  );
};

export default DesignerPanelFooter;
