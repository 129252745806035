export enum OptionsTab {
  SCENARIOS = 'SCENARIOS',
  SETTINGS = 'SETTINGS',
  BENCHMARKS = 'BENCHMARKS',
}

export const getLabel = (tab: OptionsTab) =>
  ({
    [OptionsTab.SCENARIOS]: 'Scenarios',
    [OptionsTab.SETTINGS]: 'Settings',
    [OptionsTab.BENCHMARKS]: 'Benchmarks',
  }[tab]);
