/**
 * A function to wrap file upload callback logic into the creation
 * & destruction of an on-demand virtual DOM element.
 *
 * @example Stateful file access
 * ```
 * const [file, setFile] = useState<File | null>(null);
 * const uploadFile = createUploadTrigger(setFile, () => setFile(null));
 * ```
 */
const createUploadTrigger = (onUpload: (file: File) => void, onFailure?: () => void) => {
  const onChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0] ?? null;
    if (file !== null) {
      onUpload(file);
    } else {
      onFailure?.();
    }

    target.removeEventListener('change', onChange);
    target.remove();
  };

  const uploadFile = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.addEventListener('change', onChange);
    inputElement.click();
  };

  return uploadFile;
};

export default createUploadTrigger;
