import { AppThunk } from '../configureStore';
import { ProgressData } from './state';

export enum ProgressActionType {
  UPDATE_PROGRESS = 'progress/update',
  END_PROGRESS = 'progress/end',
  CHANGE_SEQUENCE_ID = 'progress/changeSequenceId',
}

export type ProgressAction =
  | ReturnType<typeof updateProgress>
  | ReturnType<typeof endProgress>
  | ReturnType<typeof changeSequenceId>;

const updateProgress = (progress: ProgressData[]) =>
  ({
    type: ProgressActionType.UPDATE_PROGRESS,
    payload: { sequenceId: progress[0].sequenceId, progress },
  } as const);

const endProgress = (sequenceId: number) =>
  ({ type: ProgressActionType.END_PROGRESS, payload: sequenceId } as const);

export const updateProgressFromServer = (progress: ProgressData[]): AppThunk => dispatch => {
  if (progress[0].end) {
    dispatch(endProgress(progress[0].sequenceId));
  } else {
    dispatch(updateProgress(progress));
  }
};

export const changeSequenceId = (oldSequenceId: number, sequenceId: number) =>
  ({
    type: ProgressActionType.CHANGE_SEQUENCE_ID,
    payload: { oldSequenceId, sequenceId },
  } as const);
