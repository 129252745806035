import { Checkbox, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Flavor } from '../../../model/flavor';
import {
  createCurvatureScalingOptions,
  createEquityMaturityOptions,
  ExcludeFxRiskOption,
  Settings,
} from '../../../model/settings';
import { enqueueSnackbar, setSetting } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { getDisableCustomSettings } from '../../../redux/designer/panel/selectors';
import {
  DESIGNER_SEQUENCE_ID,
  DRILLTHROUGH_SETTING_TOOLTIP,
  NotificationLevel,
} from '../../../shared/constants';
import { getCustomGroupingValue, hasAnyFlavor, hasFlavor, isAwa } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import Setting from './Setting';
import SettingsGroup from './SettingGroup';
import SettingsRadio from './SettingsRadio';

interface BaseProps {
  hidden: boolean;
}

const mapStateToProps = (state: AppState) => {
  const disableCustomSettings = getDisableCustomSettings(state);

  const settings: Settings = disableCustomSettings
    ? {
        ...state.reportDesigner.settings,
        excludeFxRisk: getCustomGroupingValue(state, DESIGNER_SEQUENCE_ID, 'settings')
          .excludeFxRisk,
        excludeFxRiskOption: getCustomGroupingValue(state, DESIGNER_SEQUENCE_ID, 'settings')
          .excludeFxRiskOption,
        reportInNativeCurrency: getCustomGroupingValue(state, DESIGNER_SEQUENCE_ID, 'settings')
          .reportInNativeCurrency,
        rebalanceToCash: getCustomGroupingValue(state, DESIGNER_SEQUENCE_ID, 'settings')
          .rebalanceToCash,
        showFundConstituents: getCustomGroupingValue(state, DESIGNER_SEQUENCE_ID, 'settings')
          .showFundConstituents,
      }
    : state.reportDesigner.settings;
  return {
    settings,
    isAwa: isAwa(state),
    hasFlavor: hasFlavor(state),
    hasAnyFlavor: hasAnyFlavor(state),
    disableCustomSettings,
  };
};
const mapDispatchToProps = { setSetting, enqueueSnackbar };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const SettingsPanel: FC<Props> = props => {
  const [jurisdictionOptions, setJurisdictionOptions] = useState<string[] | null>(null);
  const [tradeAttributionOptions, setTradeAttributionOptions] = useState<string[] | null>(null);

  // Destructure props to be used in `useEffect`
  const { hidden, hasFlavor, enqueueSnackbar } = props;

  // Load FRTB-specific data
  useEffect(() => {
    if (hidden || !hasFlavor(Flavor.FRTB)) {
      return;
    }

    if (jurisdictionOptions === null)
      axios
        .get<string[]>(`${baseUrl}api/jurisdictions`)
        .then(({ data }) => {
          setJurisdictionOptions(data);
        })
        .catch(({ message }: Error) => {
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load FRTB jurisdictions from server: ${message}`,
          );
        });
  }, [hidden, hasFlavor, enqueueSnackbar, jurisdictionOptions]);

  // Load SACCR-specific data
  useEffect(() => {
    if (hidden || !hasFlavor(Flavor.SACCR)) {
      return;
    }

    if (tradeAttributionOptions === null)
      axios
        .get<string[]>(`${baseUrl}api/tradeAttributionCalculationMethods`)
        .then(({ data }) => {
          setTradeAttributionOptions(data);
        })
        .catch(({ message }: Error) => {
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load SACCR trade attribution calculation methods from server: ${message}`,
          );
        });
  }, [hidden, hasFlavor, enqueueSnackbar, tradeAttributionOptions]);

  return (
    <div
      hidden={props.hidden}
      style={{
        flexGrow: 1,
        overflow: 'auto',
        padding: '12px',
        paddingTop: 0,
      }}
    >
      {props.isAwa && (
        <>
          <SettingsGroup name='Risk Settings' hidden={!props.hasAnyFlavor(Flavor.MR, Flavor.ALMLR)}>
            <Tooltip title={props.disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
              <div>
                <Setting
                  name='Exclude FX Risk'
                  type='switch'
                  id='excludeFxRisk'
                  customSettings={props.settings}
                  disabled={props.disableCustomSettings}
                >
                  <RadioGroup
                    value={props.settings.excludeFxRiskOption}
                    onChange={e =>
                      props.setSetting('excludeFxRiskOption', e.target.value as ExcludeFxRiskOption)
                    }
                    style={{ paddingBottom: '10px' }}
                  >
                    <SettingsRadio
                      id='entire'
                      label='For entire report'
                      disabled={props.disableCustomSettings}
                    />
                    <SettingsRadio
                      id='portfolio'
                      label='For portfolio only'
                      disabled={props.disableCustomSettings}
                    />
                    <SettingsRadio
                      id='benchmark'
                      label='For benchmark only'
                      disabled={props.disableCustomSettings}
                    />
                  </RadioGroup>
                </Setting>
              </div>
            </Tooltip>
            <Tooltip title={props.disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
              <div>
                <Setting
                  name='Report in Native Currency'
                  type='checkbox'
                  id='reportInNativeCurrency'
                  customSettings={props.settings}
                  disabled={props.disableCustomSettings}
                />
              </div>
            </Tooltip>
            <Tooltip title={props.disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
              <div>
                <Setting
                  name='Rebalance to Cash'
                  type='checkbox'
                  id='rebalanceToCash'
                  customSettings={props.settings}
                  disabled={props.disableCustomSettings}
                />
              </div>
            </Tooltip>
            <Tooltip title={props.disableCustomSettings ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
              <div>
                <Setting
                  name='Show Fund Constituents'
                  type='checkbox'
                  id='showFundConstituents'
                  customSettings={props.settings}
                  disabled={props.disableCustomSettings}
                />
              </div>
            </Tooltip>
          </SettingsGroup>

          <SettingsGroup name='FRTB Settings' hidden={!props.hasFlavor(Flavor.FRTB)}>
            <Setting
              name='Jurisdiction'
              type='dropdown'
              id='jurisdiction'
              items={jurisdictionOptions}
            />
            <Setting
              name='Default Risk Charge Equity Maturity'
              type='dropdown'
              id='defaultRiskChargeEquityMaturity'
              items={createEquityMaturityOptions()}
            />
            <Setting
              name='SA FX Curvature Scaling'
              type='dropdown'
              id='saFxCurvatureScaling'
              items={createCurvatureScalingOptions()}
            />
            <Setting
              name='All Positions for IMA & SA'
              type='checkbox'
              id='includePositionsInImaSa'
            />
          </SettingsGroup>

          <SettingsGroup name='SACCR Settings' hidden={!props.hasFlavor(Flavor.SACCR)}>
            <Setting
              name='Trade Attribution Calculation'
              type='dropdown'
              id='tradeAttributionCalculation'
              items={tradeAttributionOptions}
            />
          </SettingsGroup>
          <SettingsGroup name='CVA Settings' hidden={!props.hasFlavor(Flavor.CVA)}>
            <Setting name='EAD Type' type='dropdown' id='eadType' items={['SA-CCR', 'OEM']} />
          </SettingsGroup>
        </>
      )}

      <SettingsGroup name='Display Settings'>
        <Setting name='Manual Grouping Override' type='checkbox' id='manualGroupingOverride' />
        <Setting
          name='Show Grand Total Row Always'
          type='checkbox'
          id='showGrandTotalRow'
          disabled={props.settings.constrainedRows > 0}
        />
        <Setting name='Show Total Column' type='checkbox' id='showTotalColumn' />
        <Setting name='Show Number of Buckets' type='checkbox' id='showNumberOfBuckets' />
        <Setting name='Total Columns On the Right' type='checkbox' id='totalColumnsOnRight' />
        <Setting name='Number of Header Characteristics' type='numeric' id='numberOfHeaderChars' />
      </SettingsGroup>

      <SettingsGroup name='Advanced Settings'>
        <Setting name='Remove Duplicate Nodes' type='checkbox' id='removeDuplicateNodes' />
        <Setting name='Remove Empty Buckets' type='checkbox' id='removeEmptyBuckets' />
        <Setting name='Include N/A Bucket' type='checkbox' id='includeNaBucket' />
        <Setting name='Constrained Rows' type='numeric' id='constrainedRows' />

        <Setting name='Drill-Through Inheritance' type='switch' id='drillThroughInheritance'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              color='primary'
              checked={props.settings.reportingCurrency}
              onChange={e => props.setSetting('reportingCurrency', e.target.checked)}
              style={{ paddingLeft: 0 }}
            />
            <Typography>Reporting Currency</Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              color='primary'
              checked={props.settings.scenarioSettings}
              onChange={e => props.setSetting('scenarioSettings', e.target.checked)}
              style={{ paddingLeft: 0 }}
            />
            <Typography>Custom Settings</Typography>
          </div>
        </Setting>
      </SettingsGroup>

      <SettingsGroup
        name='Default Settings'
        style={{
          borderBottom: 'none',
        }}
      >
        <Setting name='Set Report as Live' type='checkbox' id='liveReport' />
      </SettingsGroup>
    </div>
  );
};

export default connector(SettingsPanel);
