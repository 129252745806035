import { FC } from 'react';
import { DateContext, Sandbox } from '../../shared/dataTypes';
import './sandbox.scss';
import SandboxChipClose from './SandboxChipClose';
import SandboxChipMenu from './SandboxChipMenu';
import sandboxIcon from './sand_box.svg';

interface Props {
  sandbox: Sandbox;
  sequenceId: number;
  dateContext: DateContext;
  action?: 'menu' | 'close' | null;
}

const SandboxChip: FC<Props> = props =>
  !props.dateContext ? null : ( // Another render with a dateContext is coming soon
    <div
      className={['sd-sandbox-chip', ...(props.action ? ['sd-sandbox-chip-action'] : [])].join(' ')}
    >
      <img className='sandbox-icon' src={sandboxIcon} alt='sandbox' />{' '}
      <span className='label'>{decodeURIComponent(props.sandbox.path)}</span>
      {props.action === 'menu' && (
        <SandboxChipMenu
          sandbox={props.sandbox}
          sequenceId={props.sequenceId}
          dateContext={props.dateContext}
        />
      )}
      {props.action === 'close' && <SandboxChipClose sequenceId={props.sequenceId} />}
    </div>
  );

export default SandboxChip;
