import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchCurrencies, updateCurrency } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';

const useCurrencies = () => {
  const currencies = useSelector(
    (state: AppState) => state.user.userInfo.serverConfigs?.currencies ?? [],
  );

  const selectedCurrency = useSelector(
    (state: AppState) => state.user.userInfo.userPreferences?.selectedCurrency ?? null,
  );

  const urlParams = new URLSearchParams(useLocation().search);
  const urlCcy = urlParams.get('ccy');

  const dispatch = useDispatch();

  return useMemo(
    () => ({
      currencies,
      selectedCurrency: urlCcy || selectedCurrency,
      fetchCurrencies: () => dispatch(fetchCurrencies()),
      updateCurrency: (currency: string) => dispatch(updateCurrency(currency)),
    }),
    [currencies, urlCcy, selectedCurrency, dispatch],
  );
};

export default useCurrencies;
