import { ReportRequestStatus, ReportUpdateHighlightMode } from 'algo-react-dataviz';
import axios, { AxiosResponse } from 'axios';
import { Action, AnyAction } from 'redux';
import { v4 } from 'uuid';
import {
  getSelectedPortfolios,
  hasSelectedPortfolios,
} from '../components/drawers/portfolioDrawerHelpers';
import { readerUtil } from '../components/report/helpers/importUtils';
import { baseUrl } from '../components/shared/environment';
import {
  ADHOC_REPORT_FOLDER,
  defaultReportDefinition,
  DESIGNER_SEQUENCE_ID,
  METADATA_SEQUENCE_ID,
  NotificationLevel,
} from '../shared/constants';
import {
  ImportToFolderData,
  ParentToChildRelationship,
  ReportDefinition,
  ReportRawData,
  Sandbox,
  SelectedEntities,
  SelectedPortfolios,
  SerializedWorkspace,
  SerializedWorkspaceDefinition,
  WorkspaceData,
  WorkspacePayload,
  WorkspaceTab,
} from '../shared/dataTypes';
import {
  extractWorkspacePayloadAttr,
  findTabId,
  getCommonRequestProperties,
  getIsPrivateSandbox,
  getNumPendingRequests,
  getReportWorkspacePayload,
  isReservedSequenceId,
  lookupWorkspaceReports,
  maxTabId,
  moveItem,
  nextRequestId,
  nextSequenceId,
} from '../shared/utils';
import {
  closeFolderDrawer,
  enqueueSnackbar,
  getErrorMessage,
  updateMetadataSandbox,
} from './ActionCreators';
import * as ActionTypes from './ActionTypes';
import { AppThunk, sendStompMessage } from './configureStore';
import { DesignerSource } from './designer/panel/designerSource';
import {
  addReportPendingRequest,
  importReportDefinitions,
  regenerateReport,
  reportFailed,
  reportLoading,
  saveReport,
  sendReportUpdateMessage,
  setReportDefinition,
  triggerReportRegen,
  updateReportData,
  updateReportPaths,
} from './ReportActionCreators';
import { openWorkspacePage } from './ui/actionCreators';
import { fetchSandboxes } from './UserProfileActionCreators';

export const lookupTopLevelReports = (workspace: WorkspaceData): WorkspacePayload[] =>
  Object.values(workspace.tabs).flatMap(tab =>
    Object.values(tab.reports).filter(
      report =>
        (!report.parentSequenceId && report.parentSequenceId !== 0) ||
        report.parentSequenceId === DESIGNER_SEQUENCE_ID,
    ),
  );

const buildParentToChildRelationship = (workspace: WorkspaceData): ParentToChildRelationship => {
  const parentToChildRelationship: ParentToChildRelationship = {};

  Object.values(workspace.tabs).forEach(tab =>
    Object.values(tab.reports).forEach(report => {
      if (
        (report.parentSequenceId || report.parentSequenceId === 0) &&
        report.parentSequenceId !== DESIGNER_SEQUENCE_ID
      ) {
        const children: number[] = parentToChildRelationship[report.parentSequenceId] || [];
        children.push(report.sequenceId);
        parentToChildRelationship[report.parentSequenceId] = children;
      }
    }),
  );

  return parentToChildRelationship;
};

const reindexWorkspace = (
  workspaceData: WorkspaceData,
  nextTabId: number,
  seqIdMappings?: { [from: number]: number },
): { [tabId: number]: WorkspaceTab } => {
  const updatedTabs = {} as { [tabId: number]: WorkspaceTab };

  Object.values(workspaceData.tabs).forEach(tab => {
    const reindexedReports = {} as { [sequenceId: number]: WorkspacePayload };
    Object.values(tab.reports).forEach(report => {
      const newSequenceId = nextSequenceId();
      if (seqIdMappings) {
        seqIdMappings[report.sequenceId] = newSequenceId;
      }
      reindexedReports[newSequenceId] = { ...report, sequenceId: newSequenceId };
    });
    updatedTabs[nextTabId++] = { ...tab, reports: reindexedReports };
  });

  return updatedTabs;
};
const regenerateWorkspace = (
  isDrillThrough: boolean,
  workspaceData: WorkspaceData,
  parentSequenceId: number,
  selectedElements: string[],
): AppThunk => (dispatch, getState) => {
  const topLevelReports = lookupTopLevelReports(workspaceData);
  if (isDrillThrough) {
    // this is a drill through to another workspace
    // we need to update sequence ids of the incoming workspace to aling with the existing one

    // at this point, we can set top level reports of the workspace being appended as being children of the parent report and drill throughs and live
    Object.values(workspaceData.tabs).forEach(tab => {
      Object.values(tab.reports).forEach(report => {
        if (
          (!report.parentSequenceId && report.parentSequenceId !== 0) ||
          report.parentSequenceId === DESIGNER_SEQUENCE_ID
        ) {
          report.parentSequenceId = parentSequenceId;
          report.drillThrough = true;
          report.live = true;
        }
      });
    });
  }

  const parentToChildRelationship = buildParentToChildRelationship(workspaceData);

  dispatch(updateWorkspace(workspaceData, isDrillThrough));
  // 1. identify a list of top level reports that need to be generated
  // 2. identify if any of the reports in the workspace are children to the above
  // 3. make sure that children generation is requested AFTER the parent is done generating
  // note, there is one exception to the "top level reports" definition above
  // specifically, if we drill through to another workspace (parentSequenceId and selectedElements are defined)
  // then top level reports are from the perspective of the new workspace despite the fact that
  // those top level reports are effectively drill through targets from wherever we drill through to the new workspace
  // this is also the reason why parentSelectedElements is set to selectedElements in the generate request below

  // dispatch parentToChildRelationship to the redux store so the info is available when results with top level report data come back from the server

  dispatch({
    type: ActionTypes.SET_PARENT_TO_CHILD_RELATIONSHIP,
    payload: { parentToChildRelationship, isAppendMode: isDrillThrough },
  });

  dispatch(setPersistWorkspacePortfolioSelection(hasSelectedPortfolios(workspaceData)));

  // send report generation request for top level reports
  let reportDefinition: ReportDefinition = null;
  topLevelReports.forEach(async r => {
    if (r.legacyReport && r.adhoc) {
      const reportRawData: ReportRawData = {
        sequenceId: r.sequenceId,
        errMessage: 'Legacy adhoc reports are not supported.',
        isLoading: false,
        name: null,
        type: null,
        data: null,
        date: new Date(),
        headers: null,
      };
      dispatch(updateReportData(reportRawData));
      return;
    }

    try {
      const response = await axios.get<ReportDefinition | string>(
        `${baseUrl}api/reportDefinition`,
        {
          params: { path: r.reportPath, isLegacyReport: r.legacyReport },
        },
      );

      reportDefinition = response.data as ReportDefinition;
    } catch (error) {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.WARN,
          `Warning: Unable to open report: ${getErrorMessage(error)}`,
        ),
      );
      const reportRawData: ReportRawData = {
        sequenceId: r.sequenceId,
        errMessage: getErrorMessage(error),
        isLoading: false,
        name: r.reportPath?.split('/').pop(),
        type: null,
        data: null,
        date: new Date(),
        headers: null,
      };
      dispatch(updateReportData(reportRawData));
      return;
    }

    if (!reportDefinition) {
      const reportRawData: ReportRawData = {
        sequenceId: r.sequenceId,
        errMessage: r.reportPath,
        errCode: 404,
        isLoading: false,
        name: r.reportPath?.split('/').pop(),
        type: null,
        data: null,
        date: new Date(),
        headers: null,
      };
      dispatch(updateReportData(reportRawData));
      return;
    }

    dispatch(setReportDefinition(r.sequenceId, reportDefinition));

    const requestId = `seq-${r.sequenceId}-req-${nextRequestId()}`;

    dispatch(
      addReportPendingRequest(
        r.sequenceId,
        requestId,
        ReportRequestStatus.PENDING,
        ReportUpdateHighlightMode.REPORT_CHANGE,
      ),
    );

    sendStompMessage(
      {
        ...r,
        requestType: 'generate',
        parentSelectedElements: selectedElements,
        requestId,
        ...getCommonRequestProperties(getState()),
        reportDefinition,
      },
      getState().user.tk || localStorage.getItem('id_token'),
      getNumPendingRequests(getState().report.reportData),
    )
      .then(() => {
        handleRequestTimerSetup(
          dispatch,
          requestId,
          r.sequenceId,
          getState().user.userInfo.serverConfigs.commTimeout,
        );
      })
      .catch((error: Error) => {
        dispatch(reportFailed(r.sequenceId, error.message));
      });
  });
};

export const setPersistWorkspacePortfolioSelection = (persistPortfolioSelection: boolean) =>
  ({
    type: ActionTypes.SET_PERSIST_WORKSPACE_PORTFOLIO_SELECTION,
    payload: persistPortfolioSelection,
  } as const);

export const appendSelectedWorkspace = (): AppThunk => (dispatch, getState) => {
  const { selectedFolderItem } = getState().drawers.folderDrawer || {};
  dispatch(fetchAndAppendWorkspaceTabs(selectedFolderItem?.props?.path));
};

export const fetchAndAppendWorkspaceTabs = (workspacePath: string): AppThunk => (
  dispatch,
  getState,
) => {
  axios
    .get(`${baseUrl}api/workspaceDefinition`, {
      params: { path: workspacePath },
    })
    .then(response => {
      const workspaceData: WorkspaceData = response.data;

      // add new tabs and put them in correct indexes
      let currentMaxTabId = maxTabId(getState().workspace.data.tabs);
      let newTabs = { ...getState().workspace.data.tabs };
      const insertTabsLength = Object.keys(workspaceData.tabs).length;

      Object.values(workspaceData.tabs).forEach(tab => {
        currentMaxTabId++;
        newTabs[currentMaxTabId] = tab;
      });
      workspaceData.tabs = newTabs;

      const seqIdMappings = {} as { [from: number]: number };
      workspaceData.tabs = reindexWorkspace(
        workspaceData,
        maxTabId(workspaceData.tabs) + 1,
        seqIdMappings,
      );

      // and then update parent ids to the newly assigned sequenceIds (if any)
      if (Object.keys(seqIdMappings).length) {
        Object.values(workspaceData.tabs).forEach(tab =>
          Object.values(tab.reports).forEach(report => {
            if (
              (report.parentSequenceId || report.parentSequenceId === 0) &&
              report.parentSequenceId !== DESIGNER_SEQUENCE_ID
            ) {
              report.parentSequenceId = seqIdMappings[report.parentSequenceId];
            }
          }),
        );
      }

      dispatch(regenerateWorkspace(false, workspaceData, undefined, undefined));

      // change tab to left most appended tab
      dispatch(
        changeSelectedTab(
          Number(
            Object.keys(workspaceData.tabs)[
              Object.keys(workspaceData.tabs).length - insertTabsLength
            ],
          ),
        ),
      );
    });
};

export const fetchSelectedWorkspace = (shouldOpenPage = true): AppThunk => (dispatch, getState) => {
  const { selectedFolderItem, sequenceId, selectedElements } =
    getState().drawers.folderDrawer || {};
  dispatch(fetchWorkspace(selectedFolderItem?.props?.path, sequenceId, selectedElements));

  if (shouldOpenPage) {
    dispatch(openWorkspacePage());
  }
};

export const fetchWorkspace = (
  workspacePath: string,
  parentSequenceId: number,
  selectedElements: string[],
): AppThunk => (dispatch, getState) => {
  const isDrillThrough =
    (parentSequenceId || parentSequenceId === 0) &&
    parentSequenceId !== DESIGNER_SEQUENCE_ID &&
    !!selectedElements;
  if (!isDrillThrough) {
    dispatch(updateWorkspaceStatus(true, null));
  }
  axios
    .get(`${baseUrl}api/workspaceDefinition`, {
      params: { path: workspacePath },
    })
    .then(response => {
      const workspaceData: WorkspaceData = response.data;
      // We need to walk the incoming workspace and assign sequenceIds to each
      // visualization in the workspace as well as generate new tab ids
      // We also need to keep track of the old sequenceIds and their replacemement in seqIdMappings
      // so we can replace parent to child relationships in a second pass

      const seqIdMappings = {} as { [from: number]: number };
      // compute next available tab id from the current workspace (max tab id from current workspace + 1 ) if drill through, or 1 if opening workspace
      let nextTabId = isDrillThrough ? maxTabId(getState().workspace.data.tabs) + 1 : 1;
      workspaceData.tabs = reindexWorkspace(workspaceData, nextTabId, seqIdMappings);

      // and then update parent ids to the newly assigned sequenceIds (if any)
      if (Object.keys(seqIdMappings).length) {
        Object.values(workspaceData.tabs).forEach(tab =>
          Object.values(tab.reports).forEach(report => {
            if (
              (report.parentSequenceId || report.parentSequenceId === 0) &&
              report.parentSequenceId !== DESIGNER_SEQUENCE_ID
            ) {
              report.parentSequenceId = seqIdMappings[report.parentSequenceId];
            }
          }),
        );
      }
      dispatch(
        regenerateWorkspace(
          isDrillThrough,
          {
            ...workspaceData,
            ...getSelectedPortfolios(getState(), null, workspaceData),
          },
          parentSequenceId,
          selectedElements,
        ),
      );
    })
    .catch((error: Error) => {
      dispatch(
        updateWorkspaceStatus(
          false,
          `Workspace with path ${workspacePath} failed to load: ${error.message}`,
        ),
      );

      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `An error occurred when loading workspace ${workspacePath}`,
        ),
      );
    });
};

export const importWorkspace = (serializedWorkspaceDefinition: SerializedWorkspaceDefinition) => ({
  type: ActionTypes.IMPORT_WORKSPACE,
  payload: serializedWorkspaceDefinition,
});

export const addSelectedReportToWorkspace = (): AppThunk => (dispatch, getState) => {
  const { selectedFolderItem, drillThrough, sequenceId, selectedElements, newTab } =
    getState().drawers.folderDrawer || {};

  dispatch(
    addReportToWorkspace(
      selectedFolderItem?.props?.path,
      drillThrough,
      false,
      sequenceId,
      selectedFolderItem?.props?.isLegacy,
      false,
      selectedElements,
      newTab,
    ),
  );
};

export const replaceReportInWorkspace = (): AppThunk => async (dispatch, getState) => {
  // Stash sequenceId and path in consts because the state goes away when we call closeFolderDrawer
  const {
    sequenceId,
    selectedFolderItem: {
      props: { path },
    },
  } = getState().drawers.folderDrawer;
  dispatch(closeFolderDrawer());

  try {
    const response = await axios.get<ReportDefinition>(`${baseUrl}api/reportDefinition`, {
      params: {
        path,
        isLegacyReport: !!getState().report.reportDefinition[sequenceId].legacyReport,
      },
    });

    dispatch(setReportDefinition(sequenceId, response.data));
    dispatch(regenerateReport(sequenceId));
    dispatch(reportLoading(sequenceId));
  } catch (error) {
    dispatch(
      enqueueSnackbar(
        NotificationLevel.WARN,
        `Warning: Unable to open report: ${getErrorMessage(error)}`,
      ),
    );
  }
};

export const importWorkspacesIntoSelectedFolder = (
  showImportModal: (data: any) => void,
  onTaskComplete: () => void,
): AppThunk => async (dispatch, getState) => {
  readerUtil(
    getState().workspace.selectedWorkspaceImportFile,
    result => {
      try {
        doImport(
          dispatch,
          getState,
          parseWorkspaces(result.toString()).map(row => ({
            workspace: row[0],
            reports: row[1],
          })),
          'importWorkspaceConflicts',
          'importWorkspaces',
          showImportModal,
          onTaskComplete,
        );
      } catch (error) {
        dispatch(
          enqueueSnackbar(NotificationLevel.ERROR, `File cannot be imported: ${error.message}`),
        );
        onTaskComplete();
      }
    },
    error => {
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, `File cannot be imported: ${error.message}`),
      );
      onTaskComplete();
    },
  );
};

export const importReportsIntoSelectedFolder = (
  showImportModal: (reports: any) => void,
  onTaskComplete: () => void,
): AppThunk => (dispatch, getState) => {
  readerUtil(
    getState().workspace.selectedReportImportFile,
    result => {
      try {
        const data = parseReports(result.toString());

        doImport(
          dispatch,
          getState,
          data,
          'importReportConflicts',
          'importReports',
          showImportModal,
          onTaskComplete,
        );
      } catch (error) {
        dispatch(
          enqueueSnackbar(NotificationLevel.ERROR, `File cannot be imported: ${error.message}`),
        );
        onTaskComplete();
      }
    },
    error => {
      dispatch(
        enqueueSnackbar(NotificationLevel.ERROR, `File cannot be imported: ${error.message}`),
      );
      onTaskComplete();
    },
  );
};

const doImport = async (
  dispatch,
  getState,
  dataToBeImported: ImportToFolderData,
  conflictEndpoint: string,
  importEndpoint: string,
  showImportModal: (data: ImportToFolderData) => void,
  onTaskComplete: () => void,
) => {
  const { selectedFolderItem } = getState().drawers.folderDrawer || {};

  const response = await axios.post<boolean>(`${baseUrl}api/${conflictEndpoint}`, {
    target: selectedFolderItem.props.path,
    data: dataToBeImported,
  });

  if (response.data) {
    // There are conflicts. Need user input.
    showImportModal(dataToBeImported);
    return;
  }

  // No conflicts. Make call to allow the import to proceed.
  axios
    .post<boolean>(`${baseUrl}api/${importEndpoint}`, {
      target: selectedFolderItem.props.path,
      overrideExisting: true,
      data: dataToBeImported,
    })
    .then(() => {
      dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Import complete'));
    })
    .catch(error => {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, getErrorMessage(error)));
    });
  onTaskComplete();
};

const parseReports = (reportsStr: string): ReportDefinition[] => {
  const reportsJSON = JSON.parse(reportsStr.toString());

  if (Array.isArray(reportsJSON)) {
    // Checking for dateContext in an effort to assure that the parsed structure is a report definition.
    const reports = reportsJSON.filter(report => report.dateContext);
    if (reports.length > 0) {
      return reports;
    } else {
      throw new Error('File contains no reports.');
    }
  } else {
    // Check if the import file was generated usuing the 'Export to File' option.
    if (reportsJSON.reportableType) {
      return [reportsJSON];
    }
    throw new Error('Bad file format.');
  }
};

const parseWorkspaces = (workspaceStr: string): SerializedWorkspace[] => {
  const serializedWorkspaces = JSON.parse(workspaceStr.toString());

  return Array.isArray(serializedWorkspaces) &&
    serializedWorkspaces.length > 0 &&
    Object.keys(serializedWorkspaces[0]).includes('parentToChildRelationship')
    ? [serializedWorkspaces]
    : serializedWorkspaces;
};

export const addReportToWorkspace = (
  nodeId: string,
  drillThrough: boolean,
  detailList: boolean,
  parentSequenceId: number,
  legacyReport: boolean,
  adhoc: boolean,
  parentSelectedElements?: string[],
  toNewTab?: boolean,
  reportDefinition?: ReportDefinition,
  adHocSequenceId?: number,
  hasChanges?: boolean,
): AppThunk => async (dispatch, getState) => {
  const sequenceId = adHocSequenceId || nextSequenceId();
  const { source, sourceSequenceId } = getState().reportDesigner?.panelControl || {};
  const sandbox = getReportWorkspacePayload(
    source === DesignerSource.CLONE_FROM_WORKSPACE ? sourceSequenceId : parentSequenceId,
    getState(),
  )?.sandbox;

  const workspacePayload: WorkspacePayload = {
    layerId: v4(),
    sequenceId,
    reportPath: nodeId,
    legacyReport,
    drillThrough,
    detailList,
    adhoc,
    live: drillThrough || detailList,
    parentSequenceId,
    parentSelectedElements,
    sandbox,
    hasChanges,
  };

  if (reportDefinition) {
    dispatch(setReportDefinition(sequenceId, reportDefinition));
  } else if (!detailList) {
    let response: AxiosResponse<ReportDefinition | string>;

    try {
      response = await axios.get<ReportDefinition | string>(`${baseUrl}api/reportDefinition`, {
        params: { path: nodeId, isLegacyReport: legacyReport },
      });

      reportDefinition = response.data as ReportDefinition;
    } catch (error) {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.WARN,
          `Warning: Unable to open report: ${getErrorMessage(error)}`,
        ),
      );
      const reportRawData: ReportRawData = {
        sequenceId: workspacePayload.sequenceId,
        errMessage: getErrorMessage(error),
        isLoading: false,
        name: workspacePayload.reportPath.split('/').pop(),
        type: null,
        data: null,
        date: new Date(),
        headers: null,
      };
      dispatch(updateReportData(reportRawData));
      return;
    }

    dispatch(setReportDefinition(sequenceId, reportDefinition));
  } else if (detailList) {
    // Adding a detail list. Dispatch a default report definition to allow
    // for operations like changing column widths which require a definition.
    dispatch(setReportDefinition(sequenceId, defaultReportDefinition));
  }
  dispatch({
    type: ActionTypes.ADD_REPORT_TO_WORKSPACE,
    payload: {
      workspacePayload,
      parentSequenceId,
      toNewTab,
    },
  });

  dispatch(setScrollPending(sequenceId));

  if ((parentSequenceId && parentSequenceId !== DESIGNER_SEQUENCE_ID) || parentSequenceId === 0) {
    // we are adding a child report
    // we need to update parent to child map
    const parentToChildRelationship: ParentToChildRelationship = {};

    parentToChildRelationship[parentSequenceId] = [workspacePayload.sequenceId];
    // dispatch parentToChildRelationship to the redux store so the info is available when results with top level report data come back from the server
    dispatch({
      type: ActionTypes.SET_PARENT_TO_CHILD_RELATIONSHIP,
      payload: { parentToChildRelationship, isAppendMode: true },
    });
  }

  // Don't generate the report if there is an adHocSequenceId. This means we already have the report
  // generated and only need to add the report to the main window.
  if (!adHocSequenceId) {
    const requestId = `seq-${workspacePayload.sequenceId}-req-${nextRequestId()}`;

    dispatch(
      addReportPendingRequest(
        workspacePayload.sequenceId,
        requestId,
        ReportRequestStatus.PENDING,
        ReportUpdateHighlightMode.REPORT_CHANGE,
      ),
    );

    sendStompMessage(
      {
        ...workspacePayload,
        requestType: 'generate',
        requestId,
        ...getCommonRequestProperties(getState()),
        reportDefinition,
      },
      getState().user.tk || localStorage.getItem('id_token'),
      getNumPendingRequests(getState().report.reportData),
    )
      .then(() => {
        handleRequestTimerSetup(
          dispatch,
          requestId,
          workspacePayload.sequenceId,
          getState().user.userInfo.serverConfigs.commTimeout,
        );
      })
      .catch(error => {
        dispatch(reportFailed(sequenceId, error.message));
      });
  }
};

export const setScrollPending = (sequenceId?: number) => ({
  type: ActionTypes.SET_SCROLL_PENDING,
  payload: sequenceId,
});

export const goToParent = (sequenceId: number): AppThunk => (dispatch, getState) => {
  const parentSequenceId = extractWorkspacePayloadAttr('parentSequenceId', sequenceId, getState());
  dispatch(changeSelectedTab(findTabId(parentSequenceId, getState().workspace.data)));
  dispatch(setScrollPending(parentSequenceId));
};

const handleRequestTimerSetup = (dispatch, requestId, sequenceId, rpcTimeout) => {
  const timerId = window.setTimeout(() => {
    dispatch({
      type: ActionTypes.REMOVE_PENDING_OPERATION,
      payload: {
        requestIds: [requestId],
        sequenceId,
        timerId,
      },
    });
    dispatch(
      reportFailed(
        sequenceId,
        `Report operation with request id ${requestId} is taking longer than expected.`,
      ),
    );
  }, rpcTimeout + 10000);

  dispatch({
    type: ActionTypes.ADD_REQUEST_TIMER,
    payload: {
      requestId,
      sequenceId,
      timerId,
    },
  });
};

export const removeReportFromWorkspace = (sequenceId: number): AppThunk => (dispatch, getState) => {
  // if sequenceId is DESIGNER_SEQUENCE_ID that means we are disposing the report preview which is not in the workspace
  // so no need to look at the workspace and children in this case
  if (!isReservedSequenceId(sequenceId)) {
    // here we need to recursively delete children of the report being closed (if any)
    // 1. get a list of all children from parentToChildRelationshipz
    getState()
      .workspace.parentToChildRelationship[sequenceId] // 2. if children found, iterate over the list and for each recursively dispatch removeReportFromWorkspace
      ?.forEach(childSequenceId => dispatch(removeReportFromWorkspace(childSequenceId)));
    // 3. then proceed to the deletion of the report associated with sequenceId as follow:

    // remove report from workspace
    dispatch({
      type: ActionTypes.REMOVE_REPORT_FROM_WORKSPACE,
      payload: { sequenceId },
    });
  }

  // delete report related data
  dispatch(deleteReportData(sequenceId));

  // send message to server to clean-up report reference
  sendStompMessage(
    {
      requestType: 'release',
      sequenceId,
    },
    getState().user.tk || localStorage.getItem('id_token'),
  ).catch((error: Error) => {
    console.error(
      'Failed to release report reference for report with sequence id ' +
        sequenceId +
        ': ' +
        error.message,
    );
  });
};

export const saveWorkspace = (path: string, method: 'post' | 'put'): AppThunk => (
  dispatch,
  getState,
) => {
  const reports = Object.values(getState().workspace.data.tabs).flatMap(tab =>
    Object.values(tab.reports).filter(report => !report.detailList),
  );

  // If a report in the workspace has been modified then save it to an internal location.
  // If it's already an adhoc report then it is saved with a new path (the server will
  // remove the old adhoc report). This is to avoid sharing an adhoc report between
  // workspaces. Note that legacy reports should never be turned into adhoc reports.
  reports.forEach(workspacePayload => {
    const def = getState().report.reportDefinition[workspacePayload.sequenceId];
    if (
      def &&
      !def.legacyReport &&
      (!def.path || workspacePayload.hasChanges || def.path?.startsWith(ADHOC_REPORT_FOLDER))
    ) {
      const newReportPath = ADHOC_REPORT_FOLDER + '/' + v4();

      // Update the path in the workspace structure.
      dispatch(
        setWorkspaceReport({
          ...workspacePayload,
          reportPath: newReportPath,
          hasChanges: false,
        }),
      );

      // Save the report into the database with the new path.
      dispatch(saveReport(newReportPath, workspacePayload.sequenceId, 'put', true));

      // Update the report definition with the new path.
      dispatch(setReportDefinition(workspacePayload.sequenceId, { ...def, path: newReportPath }));
    }
  });

  axios({
    method,
    url: `${baseUrl}api/workspaceDefinition`,
    data: { ...getState().workspace.data, path },
    params: {
      persistPortfolioSelection: !!getState().workspace.persistPortfolioSelection,
    },
  })
    .then(({ data }) => {
      dispatch(
        enqueueSnackbar(NotificationLevel.SUCCESS, `Workspace ${decodeURIComponent(path)} saved`),
      );
      dispatch(setWorkspacePathAndId(data.path, data.id));
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          error.response.status === 409
            ? `An item with that name already exists: ${decodeURIComponent(path)}`
            : `Warning: Unable to save workspace: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const setWorkspacePathAndId = (path: string, id: string) => ({
  type: ActionTypes.SET_WORKSPACE_PATH_AND_ID,
  payload: { path, id },
});

export const updateWorkspaceStatus = (isLoading?: boolean, errMessage?: string) => ({
  type: ActionTypes.UPDATE_WORKSPACE_STATUS,
  payload: {
    isLoading,
    errMessage,
  },
});

export const updateWorkspace = (workspace: WorkspaceData, isDrillThrough: boolean): AppThunk => (
  dispatch,
  getState,
) => {
  let preExistingWorkspaceReports: WorkspacePayload[] = [];

  if (!isDrillThrough) {
    // this is NOT a drill through to another workspace
    // we need to release all "pre-existing" reports
    // look up all "pre-existing" reports first
    preExistingWorkspaceReports = lookupWorkspaceReports(getState().workspace.data);
  }

  // dispatch the new workspace being loaded (which will remove the previous one)
  dispatch({
    type: ActionTypes.UPDATE_WORKSPACE,
    payload: {
      newWorkspace: workspace,
      isDrillThrough,
    },
  });

  // now we are ready to dispose the "pre-existing" reports since the old workspace is gone
  if (!isDrillThrough) {
    // here we need to check if there is already an workspace loaded
    // if it is already loaded, release visualizations associated with it
    preExistingWorkspaceReports.forEach(wp => {
      // delete report related data
      dispatch(deleteReportData(wp.sequenceId));
      // send message to server to clean-up report reference
      sendStompMessage(
        {
          requestType: 'release',
          sequenceId: wp.sequenceId,
        },
        getState().user.tk || localStorage.getItem('id_token'),
      ).catch((error: Error) => {
        console.error(
          'Failed to release report reference for report with sequence id ' +
            wp.sequenceId +
            ': ' +
            error.message,
        );
      });
    });
  }
};

export const getWorkspacePayload = dispatch => (
  sequenceId: number,
  workspaceData: WorkspaceData,
): WorkspacePayload => {
  const tabId = findTabId(sequenceId, workspaceData);

  const ret: WorkspacePayload = workspaceData?.tabs[tabId]?.reports[sequenceId];

  if (!ret) {
    dispatch(
      enqueueSnackbar(
        NotificationLevel.WARN,
        `Workspace payload for sequenceId ${sequenceId} not found`,
      ),
    );
  }

  return ret;
};

export const changeSelectedTab = (tabIndex: number) => ({
  type: ActionTypes.CHANGE_SELECTED_TAB,
  payload: { tabIndex },
});

export const setSelectedReportImportFile = (reportImportFile: File) => ({
  type: ActionTypes.SET_SELECTED_REPORT_IMPORT_FILE,
  payload: reportImportFile,
});

export const setSelectedWorkspaceImportFile = (workspaceImportFile: File) => ({
  type: ActionTypes.SET_SELECTED_WORKSPACE_IMPORT_FILE,
  payload: workspaceImportFile,
});

export const addNewTab = (): Action => ({
  type: ActionTypes.ADD_NEW_TAB,
});

export const renameTab = (tabId: number, name: string) => ({
  type: ActionTypes.RENAME_TAB,
  payload: { tabId, name },
});

export const removeTab = (tabId: number): AppThunk => (dispatch, getState) => {
  const tabToBeRemoved = getState().workspace.data.tabs[tabId];
  if (tabToBeRemoved) {
    Object.values(tabToBeRemoved.reports).forEach(report =>
      dispatch(removeReportFromWorkspace(report.sequenceId)),
    );
    dispatch({
      type: ActionTypes.REMOVE_TAB,
      payload: { tabId },
    });
  }
};

export const regenerateTopLevelReports = (): AppThunk => (dispatch, getState) => {
  const topLevelReports = lookupTopLevelReports(getState().workspace.data);

  topLevelReports.forEach(report => {
    dispatch(
      sendReportUpdateMessage(
        {
          sequenceId: report.sequenceId,
          requestId: `seq-${report.sequenceId}-req-${nextRequestId()}`,
          parentSelectedElements: report.parentSelectedElements,
          adhoc: true,
        },
        ReportUpdateHighlightMode.CELL_CHANGE,
        ReportRequestStatus.RECONNECTING,
      ),
    );
  });
};

export const clearTab = (tabId: number): AppThunk => (dispatch, getState) => {
  const tabToBeCleared = getState().workspace.data.tabs[tabId];

  Object.values(tabToBeCleared.reports).forEach(report =>
    dispatch(removeReportFromWorkspace(report.sequenceId)),
  );

  if (tabToBeCleared) {
    dispatch({
      type: ActionTypes.CLEAR_TAB,
      payload: { tabId },
    });
  }
};

export const rearrangeTabs = (sourceIndex: number, taregtIndex: number): AppThunk => (
  dispatch,
  getState,
) => {
  const tabs = moveItem(
    Object.values(getState().workspace.data.tabs),
    sourceIndex,
    taregtIndex,
  ).reduce((accum, tab, index) => {
    // convert to an object of WorkspaceTab type
    accum[(index + 1).toString()] = tab;
    return accum;
  }, {});
  dispatch({
    type: ActionTypes.SET_TABS,
    payload: tabs,
  });
};

export const clearWorkspace = (): AppThunk => (dispatch, getState) => {
  const reports = lookupWorkspaceReports(getState().workspace.data).map(wp => wp.sequenceId);
  dispatch({
    type: ActionTypes.CLEAR_WORKSPACE,
  });

  reports.forEach(sequenceId => {
    // delete report related data
    dispatch(deleteReportData(sequenceId));

    // send message to server to clean-up report reference
    sendStompMessage(
      {
        requestType: 'release',
        sequenceId,
      },
      getState().user.tk || localStorage.getItem('id_token'),
    ).catch((error: Error) => {
      console.error(
        'Failed to release report reference for report with sequence id ' +
          sequenceId +
          ': ' +
          error.message,
      );
    });
  });
};

export const setWorkspaceReportPaths = (oldPath: string, newPath: string): AppThunk => (
  dispatch,
  getState,
) => {
  const { tabs } = getState().workspace.data;
  Object.keys(tabs).forEach(tabId => {
    Object.keys(tabs[tabId].reports).forEach(sequenceId => {
      const wp: WorkspacePayload = tabs[tabId].reports[sequenceId];
      if (wp.reportPath.startsWith(oldPath))
        dispatch(
          setWorkspaceReportPath(Number(sequenceId), wp.reportPath.replace(oldPath, newPath)),
        );
    });
  });

  dispatch(updateReportPaths(oldPath, newPath));
};

export const setWorkspaceReportPath = (sequenceId: number, reportPath: string): AnyAction => ({
  type: ActionTypes.UPDATE_WORKSPACE_REPORT_PATH,
  payload: { sequenceId, reportPath },
});

export const setWorkspaceReport = (wp: WorkspacePayload) =>
  ({
    type: ActionTypes.SET_WORKSPACE_REPORT,
    payload: wp,
  } as const);

export const setWorkspaceReportHasChanges = (sequenceId: number, hasChanges: boolean) =>
  ({
    type: ActionTypes.SET_WORKSPACE_REPORT_HAS_CHANGES,
    payload: { sequenceId, hasChanges },
  } as const);

export const deleteReportData = (sequenceId: number): AnyAction => ({
  type: ActionTypes.DELETE_REPORT_DATA,
  payload: sequenceId,
});

export const getSelectedElements = dispatch => (
  sequenceId: number,
  workspaceData: WorkspaceData,
): string[] => {
  const workspacePayload = getWorkspacePayload(dispatch)(sequenceId, workspaceData);

  return workspacePayload ? workspacePayload.selectedElements : [];
};

export const updateWorkspaceLayout = (tabId: number, layouts: ReactGridLayout.Layouts) => ({
  type: ActionTypes.UPDATE_WORKSPACE_LAYOUT,
  payload: { tabId, layouts },
});

export const setWorkspacePortfolioSelection = (ids: SelectedPortfolios): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch({ type: ActionTypes.SET_WORKSPACE_PORTFOLIO_SELECTION, payload: ids });

  // Walk reports in the tab with tabId and trigger a report regeneration for
  // top level reports only (children will update as a result of parent update)
  triggerReportRegen(getState().workspace.data, {}, dispatch);
};

export const setSelectedWorkspaceEntities = (entities: SelectedEntities): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch({ type: ActionTypes.SET_SELECTED_WORKSPACE_ENTITIES, payload: entities });
  triggerReportRegen(getState().workspace.data, {}, dispatch);
};

const handleSandboxChildrenUpdates = (sequenceId: number, sandbox: string): AppThunk => (
  dispatch,
  getState,
) => {
  const parent = getState().workspace.parentToChildRelationship[sequenceId];
  if (parent?.length) {
    parent.forEach((child: number) => {
      dispatch(setWorkspaceDefinitionSandbox(child, sandbox));
    });
  }
};

export const setWorkspaceDefinitionSandbox = (
  sequenceId: number,
  sandboxPath: string,
  isPrivate?: Boolean,
): AppThunk => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SET_WORKSPACE_SANDBOX,
    payload: {
      tabId: findTabId(sequenceId, getState().workspace.data),
      sequenceId,
      sandbox: {
        path: sandboxPath,
        prvSandbox: isPrivate ? isPrivate : getIsPrivateSandbox(getState(), sandboxPath),
      },
    },
  });
};

export const createNewSandbox = (sequenceId: number, privateSandboxes: string[]): AppThunk => (
  dispatch,
  getState,
) => {
  const sandboxEndpoint = `${baseUrl}api/createPrivateSandbox`;
  let id = 1;
  getState().user.userInfo.userPreferences.sandboxes.private.forEach(() => {
    if (privateSandboxes.includes(`Sandbox ${id}`)) {
      id++;
    }
  });
  return axios
    .post(sandboxEndpoint, null, {
      params: { id },
    })
    .then(r => {
      if (sequenceId === METADATA_SEQUENCE_ID) {
        dispatch(updateMetadataSandbox(r.data));
      } else {
        dispatch(setWorkspaceReportHasChanges(sequenceId, true));
        dispatch(setWorkspaceDefinitionSandbox(sequenceId, r.data, true));
        dispatch(regenerateSandbox(sequenceId, r.data, true));
      }

      dispatch(fetchSandboxes());
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to create sandbox: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const deleteSandbox = (sequenceId: number, sandboxPath: string): AppThunk => (
  dispatch,
  getState,
) => {
  const sandboxEndpoint = `${baseUrl}api/deleteSandbox`;
  dispatch(handleSandboxChildrenUpdates(sequenceId, null));
  dispatch(setWorkspaceDefinitionSandbox(sequenceId, null, false));
  return axios
    .post(sandboxEndpoint, null, {
      params: { path: sandboxPath, prvSandbox: getIsPrivateSandbox(getState(), sandboxPath) },
    })
    .then(r => {
      dispatch(regenerateSandbox(sequenceId, null, getIsPrivateSandbox(getState(), sandboxPath)));
      dispatch(fetchSandboxes());
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to delete sandbox: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const renameSandbox = (
  sequenceId: number,
  sandboxPath: string,
  newName: string,
): AppThunk => (dispatch, getState) => {
  const sandboxEndpoint = `${baseUrl}api/renameSandbox`;
  return axios
    .post(sandboxEndpoint, null, {
      params: {
        path: sandboxPath,
        newName,
        prvSandbox: getIsPrivateSandbox(getState(), sandboxPath),
      },
    })
    .then(r => {
      dispatch(fetchSandboxes());
      dispatch(handleSandboxChildrenUpdates(sequenceId, r.data));
      dispatch(setWorkspaceDefinitionSandbox(sequenceId, r.data));
      dispatch(regenerateSandbox(sequenceId, r.data, getIsPrivateSandbox(getState(), sandboxPath)));
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to rename sandbox: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const clearPrivateSandbox = (sandbox: Sandbox): AppThunk => (dispatch, getState) => {
  const sandboxEndpoint = `${baseUrl}api/clearSandboxEdits`;
  return axios
    .post(sandboxEndpoint, null, {
      params: { path: sandbox.path, prvSandbox: getIsPrivateSandbox(getState(), sandbox.path) },
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to clear sandbox: ${getErrorMessage(error)}`,
        ),
      );
    });
};

export const switchToSandbox = (sequenceId: number, sandboxPath: string | null): AppThunk => (
  dispatch,
  getState,
) => {
  if (sequenceId === METADATA_SEQUENCE_ID) {
    dispatch(updateMetadataSandbox(sandboxPath));
  } else {
    setWorkspaceReportHasChanges(sequenceId, true);
    dispatch(handleSandboxChildrenUpdates(sequenceId, sandboxPath));
    dispatch(setWorkspaceDefinitionSandbox(sequenceId, sandboxPath));
    dispatch(
      regenerateSandbox(sequenceId, sandboxPath, getIsPrivateSandbox(getState(), sandboxPath)),
    );
  }
};

const regenerateSandbox = (sequenceId: number, sandbox: string, prvSandbox: boolean): AppThunk => (
  dispatch,
  getState,
) => {
  const requestId = `seq-${sequenceId}-req-${nextRequestId()}`;
  const workspacePayload = getReportWorkspacePayload(sequenceId, getState());
  dispatch(
    addReportPendingRequest(
      sequenceId,
      requestId,
      ReportRequestStatus.REGENERATING,
      ReportUpdateHighlightMode.REPORT_CHANGE,
    ),
  );

  sendStompMessage(
    {
      requestType: 'generate',
      requestId,
      adhoc: workspacePayload.adhoc,
      sequenceId,
      legacyReport: workspacePayload.legacyReport,
      ...getCommonRequestProperties(getState()),
      reportDefinition: {
        ...getState().report.reportDefinition[sequenceId],
      },
      sandbox: {
        path: sandbox,
        prvSandbox,
      },
    },
    getState().user.tk || localStorage.getItem('id_token'),
  );
};

export const importWorkspaceFile = (workspaceFile: SerializedWorkspace): AppThunk => (
  dispatch,
  getState,
) => {
  const [workspaceData, reports] = workspaceFile;
  dispatch(importReportDefinitions(reports));
  dispatch(importWorkspace(workspaceData));

  const preExistingWorkspaceReports = lookupWorkspaceReports(getState().workspace.data);
  preExistingWorkspaceReports.forEach(wp => {
    sendStompMessage(
      {
        requestType: 'release',
        sequenceId: wp.sequenceId,
      },
      getState().user.tk || localStorage.getItem('id_token'),
    ).catch((error: Error) => {
      console.error(
        'Failed to release report reference for report with sequence id ' +
          wp.sequenceId +
          ': ' +
          error.message,
      );
    });
  });

  const topLevelReports = lookupTopLevelReports(workspaceData.data);
  topLevelReports.forEach(report => {
    const reportDefinition = reports[report.sequenceId];
    const requestId = `seq-${report.sequenceId}-req-${nextRequestId()}`;
    dispatch(
      addReportPendingRequest(
        report.sequenceId,
        requestId,
        ReportRequestStatus.PENDING,
        ReportUpdateHighlightMode.REPORT_CHANGE,
      ),
    );

    sendStompMessage(
      {
        ...reportDefinition,
        sequenceId: report.sequenceId,
        adhoc: true,
        requestType: 'generate',
        legacyReport: reportDefinition.legacyReport,
        parentSelectedElements: null,
        requestId,
        ...getCommonRequestProperties(getState()),
        reportDefinition,
      },
      getState().user.tk || localStorage.getItem('id_token'),
    )
      .then(() => {
        handleRequestTimerSetup(
          dispatch,
          requestId,
          report.sequenceId,
          getState().user.userInfo.serverConfigs.commTimeout,
        );
      })
      .catch((error: Error) => {
        dispatch(reportFailed(report.sequenceId, error.message));
      });
  });
};
