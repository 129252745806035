import { Typography } from '@material-ui/core';
import { DrawerFooter, DrawerHeader } from 'algo-react-dataviz';
import { FC, ReactElement } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { DateContext, Node } from '../../shared/dataTypes';
import AdHocPortfolioUploadButton from './AdHocPortfolioUploadButton';
import './portfolio-drawer.scss';
import PortfolioSelection from './PortfolioSelection';
import ResizableDrawerWrapper from './ResizableDrawerWrapper';

interface BaseProps {
  headerText: string;
  drawerOpen: boolean;
  closeDrawer: () => void;
  checkboxes: boolean;
  sandbox?: string;
  onApplyClick: (data: Node<string>) => void;
  setAsDefault: () => void;
  resetToDefault: () => void;
  resetToDefaultDisabled?: boolean;
  dateContext: DateContext;
  cellComponent?: React.ComponentType<any>;

  getOptions?: () => void;
  portfolioHierarchy: Node<string>;
  adHocPortfolios?: Node<string>;
  benchmarkPortfolios?: string[];

  selectedKeys?: string[];
  setSelectedKeys?: (k: string[]) => void;
  selectedAdHocKeys?: string[];
  setSelectedAdHocKeys?: (k: string[]) => void;
  selectedBenchmarkKeys?: string[];
  setSelectedBenchmarkKeys?: (k: string[]) => void;
  className?: string;
  extraButton?: ReactElement;
}

const mapDispatchToProps = { enqueueSnackbar };

const mapStateToProps = (state: AppState) => ({
  defaultDateContext: state.user.selectedDateContext,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const PortfolioDrawer: FC<Props> = props => {
  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: props.drawerOpen,
        onClose: props.closeDrawer,
        className: `portfolio-drawer ${props.className || ''}`,
      }}
      id='PortfolioDrawer'
    >
      <DrawerHeader headerText={props.headerText} onXClick={props.closeDrawer} />
      {props.dateContext ? (
        <PortfolioSelection
          portfolioHierarchy={props.portfolioHierarchy}
          adHocPortfolios={props.adHocPortfolios}
          benchmarkPortfolios={props.benchmarkPortfolios}
          checkboxes={props.checkboxes}
          cellComponent={props.cellComponent}
          selectedKeys={props.selectedKeys}
          setSelectedKeys={props.setSelectedKeys}
          selectedAdHocKeys={props.selectedAdHocKeys}
          setSelectedAdHocKeys={props.setSelectedAdHocKeys}
          selectedBenchmarkKeys={props.selectedBenchmarkKeys}
          setSelectedBenchmarkKeys={props.setSelectedBenchmarkKeys}
          setAsDefault={props.setAsDefault}
          resetToDefault={props.resetToDefault}
          resetToDefaultDisabled={props.resetToDefaultDisabled}
        />
      ) : (
        <Typography className='content-container no-date-context'>
          Please select a date context to show available portfolios.
        </Typography>
      )}
      <DrawerFooter
        applyButtonText='Apply'
        onApplyClick={() => props.onApplyClick(props.portfolioHierarchy)}
        overflowApplyButtonProps={[
          {
            label: 'Reset to default',
            onClick: props.resetToDefault,
            disabled: props.resetToDefaultDisabled,
          },
          {
            label: 'Set as default',
            onClick: props.setAsDefault,
          },
        ]}
        onCancelClick={props.closeDrawer}
        applyDisabled={
          props.checkboxes &&
          ![props.selectedKeys, props.selectedAdHocKeys, props.selectedBenchmarkKeys].some(
            a => a?.length > 0,
          )
        }
      >
        {props.extraButton || (
          <AdHocPortfolioUploadButton variant='text' reload={props.getOptions} />
        )}
      </DrawerFooter>
    </ResizableDrawerWrapper>
  );
};

export default connector(PortfolioDrawer);
