import { Button, Typography } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';

interface Props {
  timePoints: string[];
  onClick: (timePointStart: string, timePointEnd: string) => void;
}

const Timesteps: FC<Props> = props => {
  const [selectionState, setSelectionState] = useState<{ start: number; end: number }>({
    start: -1,
    end: -1,
  });
  const [dragging, setDragging] = useState(false);

  useEffect(() => document.addEventListener('mouseup', () => setDragging(false)), []);

  const mergeSelectionState = (index: number) =>
    setSelectionState({
      start: selectionState.start < index ? selectionState.start : index,
      end: index > selectionState.end ? index : selectionState.end,
    });

  return (
    <div
      style={{
        width: '173px',
        borderRight: 'thin solid #bbb',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography className='section-header'>Timesteps</Typography>
      <div style={{ flex: 1, overflow: 'auto' }}>
        {props.timePoints.map((i, index) => (
          <Typography
            key={index}
            onClick={e => e.shiftKey && mergeSelectionState(index)}
            style={{
              userSelect: 'none',
              paddingLeft: '37px',
              background:
                index >= selectionState.start && index <= selectionState.end ? '#e5f1f9' : 'white',
            }}
            onMouseDown={e => {
              if (!e.shiftKey) {
                setSelectionState({ start: index, end: index });
                setDragging(true);
              }
            }}
            onMouseOver={() => dragging && mergeSelectionState(index)}
          >
            {i}
          </Typography>
        ))}
      </div>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          props.onClick(
            props.timePoints[selectionState.start],
            props.timePoints[selectionState.end],
          );
        }}
        disabled={selectionState.start === selectionState.end}
      >
        Group
      </Button>
    </div>
  );
};

export default Timesteps;
