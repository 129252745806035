import { Typography } from '@material-ui/core';
import { DrawerHeader } from 'algo-react-dataviz';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux/configureStore';
import { closeDrawer } from '../../../redux/custom-grouping/actions';
import { getDrawerStatus } from '../../../redux/custom-grouping/selectors';
import { CustomGroupingDrawerStatus } from '../../../redux/custom-grouping/state';
import useCustomGroupingState from '../context/useCustomGroupingState';

const getHeaderText = (() => {
  const headerTexts: Record<CustomGroupingDrawerStatus, string> = {
    [CustomGroupingDrawerStatus.CREATE]: 'Add Custom Grouping',
    [CustomGroupingDrawerStatus.EDIT]: 'Edit Custom Grouping',
    [CustomGroupingDrawerStatus.VIEW]: 'View Custom Grouping',
  };

  return (status: CustomGroupingDrawerStatus) => headerTexts[status];
})();

const CustomGroupingDrawerHeader: FC = () => {
  const status = useAppSelector(getDrawerStatus);
  const dispatch = useDispatch();

  const { grouping } = useCustomGroupingState();
  const ownerInfo =
    status === CustomGroupingDrawerStatus.VIEW ? `(owner: ${grouping?.owner})` : null;

  return (
    <DrawerHeader
      headerText={getHeaderText(status)}
      onXClick={() => {
        dispatch(closeDrawer());
      }}
    >
      {ownerInfo && (
        <Typography color='textSecondary' variant='body1'>
          {ownerInfo}
        </Typography>
      )}
    </DrawerHeader>
  );
};

export default CustomGroupingDrawerHeader;
