import useValidation, { ValidationProps } from './useValidation';

export interface BooleanValidationProps<Value>
  extends Pick<ValidationProps<Value, boolean>, 'validate' | 'debounceDelay'> {}

const isResultValid = (result: boolean | null) => !!result;
const useBooleanValidation = <Value>(props: BooleanValidationProps<Value>) =>
  useValidation({
    ...props,
    isResultValid,
  });

export default useBooleanValidation;
