import {
  Checkbox,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Delete, Search } from '@material-ui/icons';
import { RoundedButton } from 'algo-react-dataviz';
import { CSSProperties, PropsWithChildren, useState } from 'react';
import { useAppSelector } from '../../redux/configureStore';
import useGetRequestClient from '../../api/useGetRequestClient';
import { AdminTableItem } from '../../shared/dataTypes';
import { baseUrl } from '../shared/environment';
import './admin-table.scss';
import DeleteDialog from './panels/DeleteDialog';

interface Props<T extends AdminTableItem> {
  columns: { key: keyof T; displayName?: string; id?: boolean; sortKey?: boolean }[];
  data: T[];
  selectedItem?: string;
  onSelect?: (itemId: string) => void;
  checkAll?: (filteredIds: string[]) => void;
  checkAllChecked?: boolean;
  onDelete?: (itemIds: string[]) => void;
  deleteDialogType?: string;
  style?: CSSProperties;
  className?: string;
}

function AdminTable<T extends AdminTableItem>(props: PropsWithChildren<Props<T>>) {
  const theme = useTheme();
  const idColumn = props.columns.find(c => c.id)?.key;
  const sortKey = props.columns.find(c => c.sortKey)?.key as string;
  const [checked, setChecked] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const username = useAppSelector(state => state.user.userInfo.username);
  const deleteTitle = `Remove ${props.deleteDialogType}${checked.length !== 1 ? 's' : ''}`;

  const { data: systemUsernames } = useGetRequestClient<string[]>({
    url: `${baseUrl}api/systemUsers`,
  });

  // Data rows that meet the current search term
  const filteredSortedData = props.data
    .filter(dataRow =>
      Object.values(dataRow).reduce(
        (acc, cur) =>
          acc || (typeof cur === 'string' && cur.toLowerCase().includes(searchTerm.toLowerCase())),
        false,
      ),
    )
    .sort((a, b) => (sortKey ? a[sortKey].localeCompare(b[sortKey]) : undefined));

  const checkAll = () => {
    const filteredIds = filteredSortedData.map(dataRow => `${dataRow[idColumn]}`);
    if (props.checkAll) props.checkAll(filteredIds);
    else if (checked.length === props.data.length) setChecked([]);
    else setChecked(Array.from(new Set([...checked, ...filteredIds])));
  };

  const getIndeterminate = () => {
    const numChecked = props.data.filter(d => d.checked).length;

    if (numChecked === 0) {
      // Checkboxes are uncontrolled
      return checked.length > 0 && checked.length < props.data.length;
    } else {
      // Checkboxes are controlled by parent
      return numChecked > 0 && numChecked < props.data.length;
    }
  };

  return (
    <div className={`${props.className || ''} admin-table fixed-panel-wrapper`} style={props.style}>
      <div className='fixed-panel'>
        <div
          className='fixed-region'
          style={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateAreas: `"select-all delete . search"`,
            gridTemplateColumns: 'auto auto 3fr minmax(200px, 1fr)',
          }}
        >
          <div style={{ gridArea: 'select-all', display: 'flex', alignItems: 'center' }}>
            <Checkbox
              color='primary'
              indeterminate={getIndeterminate()}
              checked={
                props.checkAll ? props.checkAllChecked : checked.length === props.data.length
              }
              onChange={checkAll}
            />

            <Typography>
              Selected ({checked.length || props.data.filter(d => d.checked).length})
            </Typography>
          </div>

          {props.onDelete && (
            <div>
              <RoundedButton
                className='action-icon-button'
                style={{ gridArea: 'delete' }}
                disabled={!checked.length}
                onClick={() => setDeleteDialogOpen(true)}
              >
                <Delete className={!!checked.length ? 'enabled' : ''} />
                <Typography>{deleteTitle}</Typography>
              </RoundedButton>
            </div>
          )}

          <DeleteDialog
            open={deleteDialogOpen}
            title={deleteTitle}
            onDeleteClick={() => {
              setDeleteDialogOpen(false);
              props.onDelete(checked);
              setChecked([]);
            }}
            onCancelClick={() => {
              setDeleteDialogOpen(false);
            }}
            checked={checked}
            deleteDialogType={props.deleteDialogType}
          />
          <TextField
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className='search-field'
            variant='outlined'
            size='small'
            style={{ gridArea: 'search', marginRight: '16px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search style={{ color: '#999' }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className='fixed-scrollable-region'>
          <table className='table' style={{ fontFamily: theme.typography.fontFamily }}>
            {props.columns.some(c => c.displayName) && (
              <thead>
                <tr>
                  <th className='checkbox sticky-th'>{/* Space above checkboxes */}</th>
                  {props.columns.map((column, i) => (
                    <th className='sticky-th' key={i}>
                      {column.displayName}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {filteredSortedData.map((dataRow, i) => {
                const itemId: string = `${dataRow[idColumn]}`;
                return (
                  <tr key={i} className={itemId === props.selectedItem ? 'selected' : undefined}>
                    <Tooltip
                      title={
                        systemUsernames?.includes(itemId)
                          ? 'System User'
                          : itemId === username
                          ? 'Current User'
                          : ''
                      }
                    >
                      <td className='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={dataRow.checked || checked.includes(itemId)}
                          disabled={systemUsernames?.includes(itemId) || itemId === username}
                          onChange={e =>
                            dataRow.onChange
                              ? dataRow.onChange(e.target.checked)
                              : setChecked(
                                  e.target.checked
                                    ? checked.concat(itemId)
                                    : checked.filter(c => c !== itemId),
                                )
                          }
                        />
                      </td>
                    </Tooltip>

                    {props.columns.map((column, j) => (
                      <td
                        key={j}
                        onClick={() => props.onSelect?.(itemId)}
                        style={{ cursor: props.onSelect ? 'pointer' : 'revert' }}
                      >
                        {dataRow[column.key]}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AdminTable;
