import { IconButton, Tooltip } from '@material-ui/core';
import { Edit as EditIcon, Visibility as VisibilityIcon } from '@material-ui/icons';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CustomGroupingDto, transformFromDto } from '../../model/custom-grouping/customGrouping';
import useCustomGroupingApi from '../../model/custom-grouping/useCustomGroupingApi';
import { AppState, useAppSelector } from '../../redux/configureStore';
import { openEditDrawer, openViewDrawer } from '../../redux/custom-grouping/actions';
import { CustomGroupingDefinition } from '../../shared/dataTypes';
import { draggableIconStyles } from './FavouriteButton';

interface Props {
  hover: boolean;
  id: string; // TODO: Redundant prop (cf. SD-2534)
  customGrouping?: CustomGroupingDefinition | CustomGroupingDto; // always `AdHocCustomGrouping`
  owner?: string;
  canEditCustomGrouping: boolean;
}

const CustomGroupingEditButton: FC<Props> = props => {
  const dispatch = useDispatch();

  const username = useAppSelector((state: AppState) => state.user.userInfo.username);
  const { retrieveCustomGrouping } = useCustomGroupingApi();

  const { id, customGrouping } = props;

  const onClick: MouseEventHandler<HTMLElement> = useCallback(
    async _event => {
      // TODO: SD-2534
      if (customGrouping && 'rootNode' in customGrouping && customGrouping.rootNode) {
        // Ad hoc custom grouping is already part of the char - no rest call needed
        dispatch(openEditDrawer(customGrouping));
      } else if (customGrouping?.name === null) {
        dispatch(openEditDrawer(transformFromDto(customGrouping as CustomGroupingDto)));
      } else {
        retrieveCustomGrouping(id).then(retrievedGrouping => {
          if (retrievedGrouping === null) {
            return;
          }

          if (username === retrievedGrouping.owner) {
            dispatch(openEditDrawer(retrievedGrouping));
          } else {
            dispatch(openViewDrawer(retrievedGrouping));
          }
        });
      }
    },
    [dispatch, retrieveCustomGrouping, id, customGrouping, username],
  );

  return (
    <IconButton
      style={{ opacity: props.hover ? 1 : 0 }}
      aria-label={props.canEditCustomGrouping ? 'Edit' : 'View'}
      {...{ onClick }}
      size='small'
    >
      {props.canEditCustomGrouping ? (
        <EditIcon style={draggableIconStyles} />
      ) : (
        <Tooltip title={`Owner: ${customGrouping?.owner || props.owner}`}>
          <VisibilityIcon style={draggableIconStyles} />
        </Tooltip>
      )}
    </IconButton>
  );
};

export default CustomGroupingEditButton;
