import { TextField } from '@material-ui/core';
import { FC, useState } from 'react';

interface Props {
  label: string;
  value: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
}

const InfoPanelField: FC<Props> = props => {
  const [changePending, setChangePending] = useState(false);
  return (
    <TextField
      label={props.label}
      variant='outlined'
      size='small'
      disabled={!props.onBlur}
      value={props.value}
      onChange={e => {
        setChangePending(true);
        props.onChange(e.target.value);
      }}
      onBlur={e => {
        if (changePending) {
          setChangePending(false);
          props.onBlur();
        }
      }}
      fullWidth
    />
  );
};

export default InfoPanelField;
