import { FormControlLabel, Switch } from '@material-ui/core';
import { FC } from 'react';

interface Props {
  label: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
}

const ComponentPermission: FC<Props> = props => (
  <FormControlLabel
    label={props.label}
    control={
      <Switch
        color='primary'
        size='small'
        checked={props.checked || false}
        onChange={(_e, checked) => props.onChange(checked)}
      />
    }
  />
);

export default ComponentPermission;
