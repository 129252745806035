import { Typography } from '@material-ui/core';
import { FC } from 'react';
import './remote-console-header.scss';

const RemoteConsoleHeader: FC = () => (
  <div className='remote-console-header'>
    <Typography variant='h1' className='algo-logo'>
      SS&C Algorithmics | Workspace Analyzer
    </Typography>
  </div>
);
export default RemoteConsoleHeader;
