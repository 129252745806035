import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { createPortal } from 'react-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { enqueueSnackbar, openFolderDrawer } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import {
  openDrillThroughReportInDesigner,
  toggleReportRows,
} from '../../redux/ReportActionCreators';
import { carbonColors } from '../../shared/colorPalettes';
import { DrawerType, NotificationLevel, RowField } from '../../shared/constants';
import { UISlot } from '../../shared/dataTypes';
import {
  extractWorkspacePayloadAttr,
  getElementsNChildren,
  getElementsTreeDepth,
} from '../../shared/utils';
interface BaseProps {
  id: string;
  sequenceId: number;
  benchAgainstPriorDay?: (
    slotDetails: UISlot,
    menuSelection: string,
    rowColSelection: string[],
  ) => void;
  fetchDetailList: (sequenceId: number, parentSelectedElements: string[]) => void;
  onShow?: any;
  onHide?: any;
  showDetailList?: boolean;
  showBenchAgainstPriorDay?: boolean;
  data?: {
    headers: any[];
    rows: any[];
  };
  contextMenuSlots?: UISlot[];
}

const mapStateToProps = (state: AppState, ownProps: BaseProps) => ({
  selectedElements: extractWorkspacePayloadAttr('selectedElements', ownProps.sequenceId, state),
  expandedRowIds: state.report.reportDefinition[ownProps.sequenceId].expandedRowIds,
  reportData: state.report.reportData[ownProps.sequenceId].raw.data,
});

const mapDispatchToProps = {
  openFolderDrawer,
  openDrillThroughReportInDesigner,
  toggleReportRows,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const Wrapper = styled.div`
  .react-contextmenu {
    min-width: 160px;
    padding: 0;
    margin: 2px 0 0;
    font-size: 16px;
    text-align: left;
    background-color: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;

    &--visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .react-contextmenu-item {
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: ${carbonColors.gray100};
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;

    &--active,
    &--selected {
      background-color: ${carbonColors.gray20};
      text-decoration: none;
    }

    &--divider {
      background-color: ${carbonColors.gray50};
      height: 1px;
      padding: 0;
    }

    &--disabled,
    &--disabled:hover {
      color: ${carbonColors.gray50};
    }

    &.react-contextmenu-submenu {
      padding: 0;

      > .react-contextmenu-item:after {
        display: inline-block;
        position: absolute;
        right: 7px;
        border-style: solid;
        border-width: 1px 1px 0 0;
        border-color: ${carbonColors.gray50};
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        top: 50%;
        transform: rotate(45deg) translate(-50%);
      }
    }
  }
`;

const ContextMenuComponent: React.FC<Props> = props => {
  const {
    id,
    sequenceId,
    selectedElements,
    benchAgainstPriorDay,
    fetchDetailList,
    openFolderDrawer,
    toggleReportRows,
    onShow,
    onHide,
    showDetailList = true,
    showBenchAgainstPriorDay = true,
    data,
    contextMenuSlots,
    expandedRowIds,
    reportData,
  } = props;

  const disabled = !selectedElements?.length;

  const dispatch = useDispatch();

  // TODO This should not be necessary - context menu does not need all the data
  const benchAgainstPriorDayIsDisabled = () => {
    //if disabled becomes true, function immediately returns true
    let disabled = false;
    for (let selectedElement of selectedElements) {
      const [selectedRowHash, selectedColHash] = selectedElement.split('x');

      const selectedRowCellIndex = data.rows.findIndex(
        row => row.data[0][RowField.ROW_HASH] === parseInt(selectedRowHash),
      );

      //function below to get the index in data to find header (may be different due to bench with diff indicators)
      if (!data.rows[selectedRowCellIndex]) break;
      const selectedColumnDataIndex = data.rows[selectedRowCellIndex].data.findIndex(
        colArray => colArray[RowField.COL_HASH] === parseInt(selectedColHash),
      );
      if (!data.headers[selectedColumnDataIndex]) break;
      const selectedHeaderName = data.headers[selectedColumnDataIndex].name;
      const selectedColumnKey = data.headers[selectedColumnDataIndex].columnKeyValue;
      const columnContainsDiffValues =
        data.rows[selectedRowCellIndex].data[selectedColumnDataIndex][RowField.DIFF_VAL] !== null;

      const columnHasAlreadyBeenBenched = data.headers.find(
        ({ columnKeyValue, containsDiff }) => selectedColumnKey === columnKeyValue && containsDiff,
      );

      if (
        selectedHeaderName === 'Grouping' ||
        columnContainsDiffValues ||
        columnHasAlreadyBeenBenched
      ) {
        disabled = true;
        break;
      }
    }
    return disabled;
  };

  const contextItems = [];

  function generateUISlotContextMenuOption(menuSlot: UISlot) {
    const { complicationDetails } = menuSlot;
    const { options } = complicationDetails;

    const availOptions = [];
    if (options) {
      Object.keys(options).forEach(attr => {
        const text = options[attr];
        const onClick = () => {
          processMenuChoice(menuSlot, text, selectedElements);
        };

        availOptions.push({
          text,
          onClick,
        });
      });
    }

    let id = 0;
    contextItems.push(
      <SubMenu
        disabled={disabled || shouldDisable(menuSlot)}
        key={'key' + id++}
        title={complicationDetails.textBefore}
      >
        {availOptions.map(option => (
          <MenuItem key={'key' + id++} onClick={option['onClick']}>
            {option['text']}
          </MenuItem>
        ))}
      </SubMenu>,
    );
  }

  /**
   * This functon does validation on a given menu choice.
   * It does this by looking at the callbackIdentifier for
   * the menu choice and validating appropriately.
   * @param menuSlot
   */
  const shouldDisable = menuSlot => {
    const identifier = menuSlot.complicationDetails.callbackIdentifier;

    let shouldDisable = false;

    switch (identifier) {
      case 'benchAgainst':
        shouldDisable = benchAgainstPriorDayIsDisabled();
        break;

      default:
        shouldDisable = false;
    }

    return shouldDisable;
  };

  /**
   *
   * @param menuSlot Menu selection handler
   * @param text The text of the menu choice
   * @param selectedElements An array of strings of the for RxC
   */
  const processMenuChoice = (menuSlot: UISlot, text: string, selectedElements: string[]) => {
    const identifier = menuSlot.complicationDetails.callbackIdentifier;

    switch (identifier) {
      case 'benchAgainst':
        benchAgainstPriorDay(menuSlot, text, selectedElements);
        break;

      default:
        dispatch(
          enqueueSnackbar(NotificationLevel.WARN, `Unknown menu item identifier: ${identifier}`),
        );
        break;
    }
  };

  contextMenuSlots &&
    contextMenuSlots.forEach(menuSlot =>
      contextItems.push(generateUISlotContextMenuOption(menuSlot)),
    );

  const selectedElementsChildren = getElementsNChildren(reportData, selectedElements, Infinity);

  return createPortal(
    <Wrapper>
      <ContextMenu id={id} onShow={onShow} onHide={onHide}>
        {showBenchAgainstPriorDay && contextItems}

        {showDetailList && (
          <MenuItem
            onClick={() => fetchDetailList(sequenceId, selectedElements)}
            disabled={disabled}
          >
            Detail List
          </MenuItem>
        )}
        <SubMenu title={<span>Drill Through</span>} disabled={disabled}>
          <MenuItem
            onClick={() =>
              openFolderDrawer(DrawerType.OPEN_WORKSPACE, {
                selectedElements,
                sequenceId,
                newTab: true,
                drillThrough: true,
              })
            }
          >
            To Other Workspace
          </MenuItem>
          <SubMenu title={<span>To Other Report</span>}>
            <MenuItem
              onClick={() =>
                openFolderDrawer(DrawerType.OPEN_REPORT_IN_WORKSPACE, {
                  selectedElements,
                  sequenceId,
                  newTab: false,
                  drillThrough: true,
                })
              }
            >
              In Same Page
            </MenuItem>
            <MenuItem
              onClick={() =>
                openFolderDrawer(DrawerType.OPEN_REPORT_IN_WORKSPACE, {
                  selectedElements,
                  sequenceId,
                  newTab: true,
                  drillThrough: true,
                })
              }
            >
              In New Page
            </MenuItem>
          </SubMenu>
          <SubMenu title={<span>To New Report</span>}>
            <MenuItem onClick={() => props.openDrillThroughReportInDesigner(sequenceId, false)}>
              In Same Page
            </MenuItem>
            <MenuItem onClick={() => props.openDrillThroughReportInDesigner(sequenceId, true)}>
              In New Page
            </MenuItem>
          </SubMenu>
        </SubMenu>
        <MenuItem divider />

        <SubMenu title={<span>Expand</span>}>
          <MenuItem
            onClick={() => toggleReportRows(sequenceId, reportData, selectedElements, true)}
            disabled={
              !expandedRowIds || selectedElementsChildren.every(id => expandedRowIds.includes(id))
            }
          >
            All
          </MenuItem>
          {Array.from(new Array(getElementsTreeDepth(reportData, selectedElements))).map(
            (_, index) => (
              <MenuItem
                key={index}
                disabled={
                  !expandedRowIds ||
                  getElementsNChildren(reportData, selectedElements, index + 1).every(id =>
                    expandedRowIds.includes(id),
                  )
                }
                onClick={() =>
                  toggleReportRows(sequenceId, reportData, selectedElements, true, index + 1)
                }
              >
                {`${index + 1}`}
              </MenuItem>
            ),
          )}
        </SubMenu>

        <MenuItem
          onClick={() => toggleReportRows(sequenceId, reportData, selectedElements, false)}
          disabled={
            !!expandedRowIds && selectedElementsChildren.every(id => !expandedRowIds.includes(id))
          }
        >
          Collapse All
        </MenuItem>
      </ContextMenu>
    </Wrapper>,
    document.getElementById('portal'),
  );
};

export default connector(ContextMenuComponent);
