import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import axios from 'axios';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { baseUrl } from '../shared/environment';
import { NotificationLevel } from '../../shared/constants';
import { RoundedButton } from 'algo-react-dataviz';

interface BaseProps {
  reloadPortfolios: () => void;
  itemToDelete: string;
  clearItem: () => void;
}

const mapDispatchToProps = { enqueueSnackbar };
const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const DeleteAdHocPortfolioDialog: FC<Props> = props => (
  <Dialog
    open={!!props.itemToDelete}
    onClose={props.clearItem}
    className='ad-hoc-portfolio-drawer-dialog'
  >
    <DialogTitle>Delete Portfolio</DialogTitle>

    <DialogContent>
      <Typography>
        Are you sure you want to delete{' '}
        <span style={{ fontWeight: 'bold' }}>{props.itemToDelete}</span>?
      </Typography>
    </DialogContent>

    <DialogActions>
      <RoundedButton variant='outlined' autoFocus onClick={props.clearItem} color='primary'>
        Cancel
      </RoundedButton>
      <RoundedButton
        variant='contained'
        onClick={() => {
          axios
            .delete(`${baseUrl}api/deleteAdhocPortfolio`, {
              params: { portfolioName: props.itemToDelete },
            })
            .catch(() =>
              props.enqueueSnackbar(
                NotificationLevel.ERROR,
                'An error occurred while deleting the portfolio.',
              ),
            )
            .finally(props.reloadPortfolios);

          props.clearItem();
        }}
        color='primary'
      >
        OK
      </RoundedButton>
    </DialogActions>
  </Dialog>
);

export default connector(DeleteAdHocPortfolioDialog);
