import { Tooltip, Typography } from '@material-ui/core';
import {
  FolderOpenOutlined as FolderOpenIcon,
  FolderSpecialOutlined as FolderSpecialIcon,
  SvgIconComponent,
} from '@material-ui/icons';
import { ColumnCellTemplateData } from 'devextreme/ui/tree_list';
import { FC } from 'react';
import { getLabel, GroupingStrategyType } from '../../../model/custom-grouping/groupingStrategy';
import { getMessage } from '../../../model/custom-grouping/groupingTreeErrors';
import {
  RuleFolderOutlined as RuleFolderIcon,
  SourceOutlined as SourceIcon,
} from '../../mui-v5-icons';
import MultilineTooltip from '../../shared/MultilineTooltip';
import useCustomGroupingState from '../context/useCustomGroupingState';
import { GroupingTreeRowDatum } from './dx-model';
import GroupingTreeRowRenameField from './GroupingTreeRowRenameField';

interface Props {
  data: ColumnCellTemplateData<GroupingTreeRowDatum, string>;
  index: number;
}

const getFolderIcon = (() => {
  const folderIcons: Record<GroupingStrategyType, SvgIconComponent> = {
    [GroupingStrategyType.PREDICATE]: RuleFolderIcon,
    [GroupingStrategyType.CHARACTERISTIC]: FolderSpecialIcon,
    [GroupingStrategyType.REMAINDER]: SourceIcon,
  };

  return ({ groupingStrategy }: GroupingTreeRowDatum) =>
    folderIcons[groupingStrategy?.type] || FolderOpenIcon;
})();

const getTooltip = ({ groupingStrategy }: GroupingTreeRowDatum) =>
  groupingStrategy === null ? 'Folder' : getLabel(groupingStrategy.type);

// As far as DX is concerned, these are cells -- but
// we have only one column, so one of these per row.
const GroupingTreeRow: FC<Props> = ({ data: { data: node } }) => {
  const {
    intendedRenameNodeId,
    groupingTreeErrors: {
      nodeErrors: { [node.id]: errors = [] },
    },
  } = useCustomGroupingState();
  const FolderIcon = getFolderIcon(node);

  return (
    <div className='grouping-tree-row'>
      <Tooltip title={getTooltip(node)} arrow placement='left'>
        <FolderIcon fontSize='small' />
      </Tooltip>
      <Typography className='grouping-tree-row-sizer' variant='subtitle2'>
        x
      </Typography>

      {node.id === intendedRenameNodeId ? (
        <GroupingTreeRowRenameField initialName={node.name} />
      ) : (
        <MultilineTooltip lines={errors.map(getMessage)}>
          <Typography
            className={`grouping-tree-row-label ${errors.length >= 1 ? 'error' : ''}`}
            variant='subtitle2'
          >
            {node.name}
          </Typography>
        </MultilineTooltip>
      )}
    </div>
  );
};

export default GroupingTreeRow;
