import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Check as CheckIcon, SettingsOutlined as SettingsOutlinedIcon } from '@material-ui/icons';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  regenerateIfAuto,
  removeFilter,
  setNot,
  updateFilterType,
} from '../../../../redux/ActionCreators';
import { useAppSelector } from '../../../../redux/configureStore';
import { getFilter } from '../../../../redux/report/selectors';
import { Characteristic, FilterType } from '../../../../shared/dataTypes';
import './draggable-filters.scss';

interface Props {
  char: Characteristic;
}

const menuItems: { id: FilterType; printName: string }[] = [
  { id: 'EQUALS', printName: 'Equals' },
  { id: 'LIST', printName: 'Checkbox' },
  { id: 'MINMAX', printName: 'Min/Max' },
  { id: 'REMOVE', printName: 'Remove' },
];

const DraggableFiltersSettingsIcon: FC<Props> = props => {
  const dispatch = useDispatch();
  const filter = useAppSelector(getFilter)(props.char.charId);

  const [anchorEl, setAnchorEl] = useState(null);

  const [activeMenuItem, setActiveMenuItem] = useState(filter?.type);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterUpdate = (id: FilterType) => {
    dispatch(setNot(props.char.charId, false));
    if (id === 'REMOVE') {
      dispatch(removeFilter(props.char.charId));
      dispatch(regenerateIfAuto());
    } else {
      dispatch(updateFilterType(props.char.charId, id));
    }
  };

  const handleChange = (id: FilterType) => {
    handleClose();
    setActiveMenuItem(id);
    handleFilterUpdate(id);
    dispatch(regenerateIfAuto());
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        aria-controls='draggable-filters-menu'
        aria-haspopup='true'
        aria-label='Filter Options Menu'
        style={{
          padding: '8px',
        }}
      >
        <SettingsOutlinedIcon
          style={{
            color: '#00677F',
            fontSize: '16px',
          }}
        />
      </IconButton>
      <Menu
        className='draggable-filters-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <span className='filter-type'>Filter Type</span>
        {menuItems.map(({ id, printName }) => {
          return (
            <MenuItem
              key={id}
              className='draggable-filters-menu-item'
              onClick={() => handleChange(id)}
            >
              {printName}
              {activeMenuItem === id && (
                <span>
                  <CheckIcon style={{ fontSize: '12px' }} />
                </span>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default DraggableFiltersSettingsIcon;
