import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { RoundedButton } from 'algo-react-dataviz';
import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import { Node } from '../../shared/dataTypes';
import { searchPortfolioHierarchyForId } from '../../shared/utils';
import AdhocPortfolioTree from './AdhocPortfolioTree';
import BenchmarkPortfolioTree from './BenchmarkPortfolioTree';
import PortfolioSelectionControls from './PortfolioSelectionControls';
import PortfolioTree from './PortfolioTree';

interface BaseProps {
  portfolioHierarchy: Node<string>;
  checkboxes: boolean;
  adHocPortfolios?: Node<string>;
  benchmarkPortfolios?: Array<string>;
  cellComponent?: React.ComponentType<any>;
  selectedKeys?: string[];
  setSelectedKeys?: (k: string[]) => void;
  selectedAdHocKeys?: string[];
  setSelectedAdHocKeys?: (k: string[]) => void;
  selectedBenchmarkKeys?: string[];
  setSelectedBenchmarkKeys?: (k: string[]) => void;
  setAsDefault: () => void;
  resetToDefault: () => void;
  resetToDefaultDisabled?: boolean;
  hidden?: boolean;
}

const mapStateToProps = (state: AppState) => ({
  showBenchmarkReplication: state.user?.userInfo?.serverConfigs?.showBenchmarkReplication,
});

const getLengthOfPortfolios = (portfolioHierarchy: Node<string>): number => {
  if (portfolioHierarchy.children === null) {
    // is leaf
    return 1;
  } else {
    return portfolioHierarchy.children.reduce((acc, cur) => acc + getLengthOfPortfolios(cur), 0);
  }
};

const getLengthOfSelectedPortfolios = (
  portfolioHierarchy: Node<string>,
  selectedKeys: string[],
): number => {
  return selectedKeys.filter(item => searchPortfolioHierarchyForId(portfolioHierarchy, item))
    .length;
};

// ensure that we are only returning the count of selected benchmarks that exist currently
const getLengthOfSelectedBenchmarks = (
  benchmarkPortfolios: Array<string>,
  selectedBenchmarkKeys: string[],
): number => {
  return selectedBenchmarkKeys.filter(item => benchmarkPortfolios.includes(item)).length;
};

const connector = connect(mapStateToProps, undefined);
type Props = ConnectedProps<typeof connector> & BaseProps;

const PortfolioSelection: FC<Props> = props => {
  const [expandedAccordion, setExpandedAccordion] = useState<1 | 2 | 3>(1);
  return props.portfolioHierarchy ? (
    <div className='content-container' hidden={props.hidden}>
      <Accordion
        expanded={expandedAccordion === 1}
        onChange={(_e, isExpanded) => setExpandedAccordion(isExpanded ? 1 : 2)}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          className='accordion-summary-with-button'
        >
          <Typography variant='h2'>Portfolio</Typography>

          {expandedAccordion === 1 && props.selectedKeys?.length > 0 ? (
            <RoundedButton
              onClick={e => {
                props.setSelectedKeys([]);

                // Without this, the accordion summary also receives a click event, and collapses
                // the accordion.
                e.stopPropagation();
              }}
              color='primary'
            >
              Clear Selections
            </RoundedButton>
          ) : (
            props.selectedKeys?.length > 0 && (
              <Typography style={{ marginLeft: 'auto' }} variant='body2'>
                ({getLengthOfSelectedPortfolios(props.portfolioHierarchy, props.selectedKeys)} of{' '}
                {getLengthOfPortfolios(props.portfolioHierarchy)})
              </Typography>
            )
          )}
        </AccordionSummary>
        <AccordionDetails>
          <PortfolioTree
            portfolioHierarchy={props.portfolioHierarchy}
            selectionMode={props.checkboxes ? 'multiple' : 'single'}
            onItemSelectionChanged={props.setSelectedKeys}
            selectedKeys={props.selectedKeys}
            cellComponent={props.cellComponent}
          />
        </AccordionDetails>
      </Accordion>
      {props.checkboxes && props.adHocPortfolios?.children && (
        <Accordion
          expanded={expandedAccordion === 2}
          onChange={(_e, isExpanded) => setExpandedAccordion(isExpanded ? 2 : 1)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className='accordion-summary-with-button'
          >
            <Typography variant='h2'>Ad Hoc Portfolios</Typography>

            {expandedAccordion === 2 && props.selectedAdHocKeys.length > 0 ? (
              <RoundedButton
                onClick={e => {
                  props.setSelectedAdHocKeys([]);
                  e.stopPropagation();
                }}
                color='primary'
              >
                Clear Selections
              </RoundedButton>
            ) : (
              props.selectedAdHocKeys?.length > 0 && (
                <Typography style={{ marginLeft: 'auto' }} variant='body2'>
                  ({props.selectedAdHocKeys.length} of {props.adHocPortfolios.children.length})
                </Typography>
              )
            )}
          </AccordionSummary>

          <AccordionDetails className='flat-list-portfolios'>
            <AdhocPortfolioTree
              adHocPortfolios={props.adHocPortfolios}
              selectedAdHocKeys={props.selectedAdHocKeys}
              setSelectedAdHocKeys={props.setSelectedAdHocKeys}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {props.showBenchmarkReplication && props.checkboxes && props.benchmarkPortfolios?.length > 0 && (
        <Accordion
          expanded={expandedAccordion === 3}
          onChange={(_e, isExpanded) => setExpandedAccordion(isExpanded ? 3 : 1)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            className='accordion-summary-with-button'
          >
            <Typography variant='h2'>Benchmark Portfolios</Typography>

            {expandedAccordion === 3 && props.selectedBenchmarkKeys.length > 0 ? (
              <RoundedButton
                onClick={e => {
                  props.setSelectedBenchmarkKeys([]);
                  e.stopPropagation();
                }}
                color='primary'
              >
                Clear Selections
              </RoundedButton>
            ) : (
              props.selectedBenchmarkKeys?.length > 0 && (
                <Typography style={{ marginLeft: 'auto' }} variant='body2'>
                  (
                  {getLengthOfSelectedBenchmarks(
                    props.benchmarkPortfolios,
                    props.selectedBenchmarkKeys,
                  ) +
                    ' of ' +
                    props.benchmarkPortfolios.length}
                  )
                </Typography>
              )
            )}
          </AccordionSummary>
          <AccordionDetails className='flat-list-portfolios'>
            <BenchmarkPortfolioTree
              benchmarkPortfolios={props.benchmarkPortfolios}
              selectedBenchmarkKeys={props.selectedBenchmarkKeys}
              setSelectedBenchmarkKeys={props.setSelectedBenchmarkKeys}
            />
          </AccordionDetails>
        </Accordion>
      )}

      <PortfolioSelectionControls />
    </div>
  ) : (
    <div style={{ flexGrow: 1, alignSelf: 'center', paddingTop: '100px' }} hidden={props.hidden}>
      <CircularProgress size={40} />
    </div>
  );
};

export default connector(PortfolioSelection);
