import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  openUserDrawerLike,
  requestDeleteUsers,
  requestPasswordChange,
} from '../../redux/adminActionCreators';
import { AppState } from '../../redux/configureStore';
import { canAdminChangePassword } from '../../shared/utils';
import InfoPanelFooter from './panels/InfoPanelFooter';
import ResetPasswordDialog from './panels/ResetPasswordDialog';

const mapStateToProps = (state: AppState) => ({
  displayResetUserPassword: canAdminChangePassword(state),
});
const mapDispatchToProps = { requestDeleteUsers, openUserDrawerLike, requestPasswordChange };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const UserButtons: FC<Props> = props => {
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  return (
    <>
      <InfoPanelFooter
        onCreateClick={props.openUserDrawerLike}
        {...(props.displayResetUserPassword
          ? { onResetClick: () => setResetDialogOpen(true) }
          : {})}
        style={{ gridArea: 'buttons' }}
      />

      <ResetPasswordDialog
        open={resetDialogOpen}
        onUpdateClick={newPassword => {
          setResetDialogOpen(false);
          requestPasswordChange(newPassword);
        }}
        onCancelClick={() => setResetDialogOpen(false)}
      />
    </>
  );
};

export default connector(UserButtons);
