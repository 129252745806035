import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { baseUrl } from '../shared/environment';
import { NotificationLevel } from '../../shared/constants';
import { GrouperProps } from './GrouperModal';

const mapDispatchToProps = {
  enqueueSnackbar,
};

interface HistogramGrouperDataType {
  numberOfBuckets: number;
}

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & GrouperProps<HistogramGrouperDataType>;

const HistogramGrouperModal: FC<Props> = props => {
  const [defaultNumberOfBuckets, setDefaultNumberOfBuckets] = useState(20);
  const [numberOfBuckets, setNumberOfBuckets] = useState(
    props.initialValue?.numberOfBuckets || undefined,
  );

  useEffect(() => {
    if (!numberOfBuckets) {
      axios
        .get<number>(`${baseUrl}api/defaultNumHistogramBuckets`)
        .then(r => setDefaultNumberOfBuckets(r.data))
        .catch((error: Error) => {
          props.enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to retrieve default number of histogram buckets: ${error.message}`,
          );
          setDefaultNumberOfBuckets(1);
        });
    }
  }, [props, numberOfBuckets, setDefaultNumberOfBuckets]);

  return (
    <Dialog open={true} onClose={props.onCancelClick}>
      <DialogTitle>Editor: Histogram Grouper</DialogTitle>

      <DialogContent>
        <div
          style={{
            alignItems: 'center',
            height: 50,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Number of Buckets</Typography>
          <TextField
            type='number'
            variant='outlined'
            InputProps={{ style: { height: '32px', width: '10ch' } }}
            inputProps={{ min: 1 }}
            value={numberOfBuckets || defaultNumberOfBuckets}
            onChange={e => {
              setNumberOfBuckets(Number(e.target.value));
            }}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={() =>
            props.onApplyClick({ numberOfBuckets: numberOfBuckets || defaultNumberOfBuckets })
          }
        >
          Apply
        </Button>
        <Button onClick={() => props.onCancelClick()} variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connector(HistogramGrouperModal);
