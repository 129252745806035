import { Chip } from '@material-ui/core';
import { Modifier } from 'algo-react-dataviz';
import { FC } from 'react';

interface Props {
  modifier: Modifier;
}

const ModifierChip: FC<Props> = ({ modifier }) => {
  if (!modifier) {
    return null;
  }
  return (
    <>
      {[
        { modifier: Modifier.BENCH, label: 'Bench 1', backgroundColor: '#e0e27c' },
        { modifier: Modifier.DIFF, label: 'Diff 1', backgroundColor: '#9cdbd9' },
        { modifier: Modifier.BENCH2, label: 'Bench 2', backgroundColor: '#e0e27c' },
        { modifier: Modifier.DIFF2, label: 'Diff 2', backgroundColor: '#9cdbd9' },
      ].map(
        c =>
          modifier === c.modifier && (
            <Chip
              key={c.modifier}
              label={c.label}
              style={{ height: '16px', fontSize: '11px', backgroundColor: c.backgroundColor }}
            />
          ),
      )}
    </>
  );
};

export default ModifierChip;
