// Generated from src/lang/folia/FoliaLexer.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class FoliaLexer extends Lexer {
	public static readonly KW_KEY = 1;
	public static readonly KEY_EXPRESSION = 2;
	public static readonly KEY_ACCESSOR = 3;
	public static readonly BOOLEAN_LITERAL = 4;
	public static readonly DOUBLE_LITERAL = 5;
	public static readonly INT_LITERAL = 6;
	public static readonly STRING_LITERAL = 7;
	public static readonly NULL_LITERAL = 8;
	public static readonly DOUBLE_ARRAY_LITERAL = 9;
	public static readonly INT_ARRAY_LITERAL = 10;
	public static readonly STRING_ARRAY_LITERAL = 11;
	public static readonly PERIOD = 12;
	public static readonly COMMA = 13;
	public static readonly DOUBLE_QUOTE = 14;
	public static readonly L_PAREN = 15;
	public static readonly R_PAREN = 16;
	public static readonly L_BRACKET = 17;
	public static readonly R_BRACKET = 18;
	public static readonly L_BRACE = 19;
	public static readonly R_BRACE = 20;
	public static readonly LOGICAL_NOT = 21;
	public static readonly LOGICAL_AND = 22;
	public static readonly LOGICAL_OR = 23;
	public static readonly COMP_EQ = 24;
	public static readonly COMP_NEQ = 25;
	public static readonly COMP_LT = 26;
	public static readonly COMP_GT = 27;
	public static readonly COMP_LEQ = 28;
	public static readonly COMP_GEQ = 29;
	public static readonly COMP_IN = 30;
	public static readonly WS = 31;
	public static readonly UNRECOGNIZED = 32;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"KW_KEY", "KEY_EXPRESSION", "KEY_ACCESSOR", "BOOLEAN_LITERAL", "DOUBLE_LITERAL", 
		"INT_LITERAL", "STRING_LITERAL", "NULL_LITERAL", "DOUBLE_ARRAY_LITERAL", 
		"INT_ARRAY_LITERAL", "STRING_ARRAY_LITERAL", "DIGIT", "PERIOD", "COMMA", 
		"DOUBLE_QUOTE", "L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", "L_BRACE", 
		"R_BRACE", "NOT_R_PAREN", "NOT_R_BRACKET", "NOT_R_BRACE", "NOT_DOUBLE_QUOTE", 
		"NOT_NEW_LINE", "LOGICAL_NOT", "LOGICAL_AND", "LOGICAL_OR", "COMP_EQ", 
		"COMP_NEQ", "COMP_LT", "COMP_GT", "COMP_LEQ", "COMP_GEQ", "COMP_IN", "WS", 
		"UNRECOGNIZED",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'key'", undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, "'.'", "','", "'\"'", 
		"'('", "')'", "'['", "']'", "'{'", "'}'", undefined, undefined, undefined, 
		"'=='", "'!='", "'<'", "'>'", "'<='", "'>='",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "KW_KEY", "KEY_EXPRESSION", "KEY_ACCESSOR", "BOOLEAN_LITERAL", 
		"DOUBLE_LITERAL", "INT_LITERAL", "STRING_LITERAL", "NULL_LITERAL", "DOUBLE_ARRAY_LITERAL", 
		"INT_ARRAY_LITERAL", "STRING_ARRAY_LITERAL", "PERIOD", "COMMA", "DOUBLE_QUOTE", 
		"L_PAREN", "R_PAREN", "L_BRACKET", "R_BRACKET", "L_BRACE", "R_BRACE", 
		"LOGICAL_NOT", "LOGICAL_AND", "LOGICAL_OR", "COMP_EQ", "COMP_NEQ", "COMP_LT", 
		"COMP_GT", "COMP_LEQ", "COMP_GEQ", "COMP_IN", "WS", "UNRECOGNIZED",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(FoliaLexer._LITERAL_NAMES, FoliaLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return FoliaLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(FoliaLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "FoliaLexer.g4"; }

	// @Override
	public get ruleNames(): string[] { return FoliaLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return FoliaLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return FoliaLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return FoliaLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\"\u0135\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		"\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t" +
		"\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x03\x02\x03\x02\x03\x02\x03" +
		"\x02\x03\x03\x03\x03\x03\x03\x03\x04\x03\x04\x06\x04Y\n\x04\r\x04\x0E" +
		"\x04Z\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
		"\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05" +
		"\x03\x05\x03\x05\x03\x05\x05\x05q\n\x05\x03\x06\x06\x06t\n\x06\r\x06\x0E" +
		"\x06u\x03\x06\x03\x06\x07\x06z\n\x06\f\x06\x0E\x06}\v\x06\x03\x06\x07" +
		"\x06\x80\n\x06\f\x06\x0E\x06\x83\v\x06\x03\x06\x03\x06\x06\x06\x87\n\x06" +
		"\r\x06\x0E\x06\x88\x05\x06\x8B\n\x06\x03\x07\x06\x07\x8E\n\x07\r\x07\x0E" +
		"\x07\x8F\x03\b\x03\b\x07\b\x94\n\b\f\b\x0E\b\x97\v\b\x03\b\x03\b\x03\t" +
		"\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x03\t\x05\t\xA3\n\t\x03\n\x03\n\x03" +
		"\n\x03\n\x07\n\xA9\n\n\f\n\x0E\n\xAC\v\n\x03\n\x03\n\x07\n\xB0\n\n\f\n" +
		"\x0E\n\xB3\v\n\x03\n\x03\n\x03\v\x03\v\x03\v\x03\v\x07\v\xBB\n\v\f\v\x0E" +
		"\v\xBE\v\v\x03\v\x03\v\x07\v\xC2\n\v\f\v\x0E\v\xC5\v\v\x03\v\x03\v\x03" +
		"\f\x03\f\x03\f\x03\f\x07\f\xCD\n\f\f\f\x0E\f\xD0\v\f\x03\f\x03\f\x07\f" +
		"\xD4\n\f\f\f\x0E\f\xD7\v\f\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03" +
		"\x0F\x03\x0F\x03\x10\x03\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03\x13\x03" +
		"\x13\x03\x14\x03\x14\x03\x15\x03\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03" +
		"\x18\x03\x18\x03\x19\x03\x19\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03" +
		"\x1C\x03\x1C\x03\x1C\x03\x1C\x03\x1C\x05\x1C\xFF\n\x1C\x03\x1D\x03\x1D" +
		"\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x05\x1D\u0109\n\x1D\x03" +
		"\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x05\x1E\u0111\n\x1E\x03\x1F" +
		"\x03\x1F\x03\x1F\x03 \x03 \x03 \x03!\x03!\x03\"\x03\"\x03#\x03#\x03#\x03" +
		"$\x03$\x03$\x03%\x03%\x03%\x03%\x05%\u0127\n%\x03&\x03&\x05&\u012B\n&" +
		"\x03&\x06&\u012E\n&\r&\x0E&\u012F\x03&\x03&\x03\'\x03\'\x02\x02\x02(\x03" +
		"\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b\x0F\x02\t" +
		"\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19\x02\x02\x1B\x02\x0E\x1D\x02" +
		"\x0F\x1F\x02\x10!\x02\x11#\x02\x12%\x02\x13\'\x02\x14)\x02\x15+\x02\x16" +
		"-\x02\x02/\x02\x021\x02\x023\x02\x025\x02\x027\x02\x179\x02\x18;\x02\x19" +
		"=\x02\x1A?\x02\x1BA\x02\x1CC\x02\x1DE\x02\x1EG\x02\x1FI\x02 K\x02!M\x02" +
		"\"\x03\x02\t\x03\x022;\x03\x02++\x03\x02__\x03\x02\x7F\x7F\x03\x02$$\x03" +
		"\x02\f\f\x04\x02\v\v\"\"\x02\u0149\x02\x03\x03\x02\x02\x02\x02\x05\x03" +
		"\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02" +
		"\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02" +
		"\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02" +
		"\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02" +
		"\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02" +
		"\x02\'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x027" +
		"\x03\x02\x02\x02\x029\x03\x02\x02\x02\x02;\x03\x02\x02\x02\x02=\x03\x02" +
		"\x02\x02\x02?\x03\x02\x02\x02\x02A\x03\x02\x02\x02\x02C\x03\x02\x02\x02" +
		"\x02E\x03\x02\x02\x02\x02G\x03\x02\x02\x02\x02I\x03\x02\x02\x02\x02K\x03" +
		"\x02\x02\x02\x02M\x03\x02\x02\x02\x03O\x03\x02\x02\x02\x05S\x03\x02\x02" +
		"\x02\x07V\x03\x02\x02\x02\tp\x03\x02\x02\x02\v\x8A\x03\x02\x02\x02\r\x8D" +
		"\x03\x02\x02\x02\x0F\x91\x03\x02\x02\x02\x11\xA2\x03\x02\x02\x02\x13\xA4" +
		"\x03\x02\x02\x02\x15\xB6\x03\x02\x02\x02\x17\xC8\x03\x02\x02\x02\x19\xDA" +
		"\x03\x02\x02\x02\x1B\xDC\x03\x02\x02\x02\x1D\xDE\x03\x02\x02\x02\x1F\xE0" +
		"\x03\x02\x02\x02!\xE2\x03\x02\x02\x02#\xE4\x03\x02\x02\x02%\xE6\x03\x02" +
		"\x02\x02\'\xE8\x03\x02\x02\x02)\xEA\x03\x02\x02\x02+\xEC\x03\x02\x02\x02" +
		"-\xEE\x03\x02\x02\x02/\xF0\x03\x02\x02\x021\xF2\x03\x02\x02\x023\xF4\x03" +
		"\x02\x02\x025\xF6\x03\x02\x02\x027\xFE\x03\x02\x02\x029\u0108\x03\x02" +
		"\x02\x02;\u0110\x03\x02\x02\x02=\u0112\x03\x02\x02\x02?\u0115\x03\x02" +
		"\x02\x02A\u0118\x03\x02\x02\x02C\u011A\x03\x02\x02\x02E\u011C\x03\x02" +
		"\x02\x02G\u011F\x03\x02\x02\x02I\u0126\x03\x02\x02\x02K\u012D\x03\x02" +
		"\x02\x02M\u0133\x03\x02\x02\x02OP\x07m\x02\x02PQ\x07g\x02\x02QR\x07{\x02" +
		"\x02R\x04\x03\x02\x02\x02ST\x05\x03\x02\x02TU\x05\x07\x04\x02U\x06\x03" +
		"\x02\x02\x02VX\x05%\x13\x02WY\x05/\x18\x02XW\x03\x02\x02\x02YZ\x03\x02" +
		"\x02\x02ZX\x03\x02\x02\x02Z[\x03\x02\x02\x02[\\\x03\x02\x02\x02\\]\x05" +
		"\'\x14\x02]\b\x03\x02\x02\x02^_\x07V\x02\x02_`\x07T\x02\x02`a\x07W\x02" +
		"\x02aq\x07G\x02\x02bc\x07v\x02\x02cd\x07t\x02\x02de\x07w\x02\x02eq\x07" +
		"g\x02\x02fg\x07H\x02\x02gh\x07C\x02\x02hi\x07N\x02\x02ij\x07U\x02\x02" +
		"jq\x07G\x02\x02kl\x07h\x02\x02lm\x07c\x02\x02mn\x07n\x02\x02no\x07u\x02" +
		"\x02oq\x07g\x02\x02p^\x03\x02\x02\x02pb\x03\x02\x02\x02pf\x03\x02\x02" +
		"\x02pk\x03\x02\x02\x02q\n\x03\x02\x02\x02rt\x05\x19\r\x02sr\x03\x02\x02" +
		"\x02tu\x03\x02\x02\x02us\x03\x02\x02\x02uv\x03\x02\x02\x02vw\x03\x02\x02" +
		"\x02w{\x05\x1B\x0E\x02xz\x05\x19\r\x02yx\x03\x02\x02\x02z}\x03\x02\x02" +
		"\x02{y\x03\x02\x02\x02{|\x03\x02\x02\x02|\x8B\x03\x02\x02\x02}{\x03\x02" +
		"\x02\x02~\x80\x05\x19\r\x02\x7F~\x03\x02\x02\x02\x80\x83\x03\x02\x02\x02" +
		"\x81\x7F\x03\x02\x02\x02\x81\x82\x03\x02\x02\x02\x82\x84\x03\x02\x02\x02" +
		"\x83\x81\x03\x02\x02\x02\x84\x86\x05\x1B\x0E\x02\x85\x87\x05\x19\r\x02" +
		"\x86\x85\x03\x02\x02\x02\x87\x88\x03\x02\x02\x02\x88\x86\x03\x02\x02\x02" +
		"\x88\x89\x03\x02\x02\x02\x89\x8B\x03\x02\x02\x02\x8As\x03\x02\x02\x02" +
		"\x8A\x81\x03\x02\x02\x02\x8B\f\x03\x02\x02\x02\x8C\x8E\x05\x19\r\x02\x8D" +
		"\x8C\x03\x02\x02\x02\x8E\x8F\x03\x02\x02\x02\x8F\x8D\x03\x02\x02\x02\x8F" +
		"\x90\x03\x02\x02\x02\x90\x0E\x03\x02\x02\x02\x91\x95\x05\x1F\x10\x02\x92" +
		"\x94\x053\x1A\x02\x93\x92\x03\x02\x02\x02\x94\x97\x03\x02\x02\x02\x95" +
		"\x93\x03\x02\x02\x02\x95\x96\x03\x02\x02\x02\x96\x98\x03\x02\x02\x02\x97" +
		"\x95\x03\x02\x02\x02\x98\x99\x05\x1F\x10\x02\x99\x10\x03\x02\x02\x02\x9A" +
		"\x9B\x07P\x02\x02\x9B\x9C\x07W\x02\x02\x9C\x9D\x07N\x02\x02\x9D\xA3\x07" +
		"N\x02\x02\x9E\x9F\x07p\x02\x02\x9F\xA0\x07w\x02\x02\xA0\xA1\x07n\x02\x02" +
		"\xA1\xA3\x07n\x02\x02\xA2\x9A\x03\x02\x02\x02\xA2\x9E\x03\x02\x02\x02" +
		"\xA3\x12\x03\x02\x02\x02\xA4\xA5\x05)\x15\x02\xA5\xB1\x05\v\x06\x02\xA6" +
		"\xAA\x05\x1D\x0F\x02\xA7\xA9\x05K&\x02\xA8\xA7\x03\x02\x02\x02\xA9\xAC" +
		"\x03\x02\x02\x02\xAA\xA8\x03\x02\x02\x02\xAA\xAB\x03\x02\x02\x02\xAB\xAD" +
		"\x03\x02\x02\x02\xAC\xAA\x03\x02\x02\x02\xAD\xAE\x05\v\x06\x02\xAE\xB0" +
		"\x03\x02\x02\x02\xAF\xA6\x03\x02\x02\x02\xB0\xB3\x03\x02\x02\x02\xB1\xAF" +
		"\x03\x02\x02\x02\xB1\xB2\x03\x02\x02\x02\xB2\xB4\x03\x02\x02\x02\xB3\xB1" +
		"\x03\x02\x02\x02\xB4\xB5\x05+\x16\x02\xB5\x14\x03\x02\x02\x02\xB6\xB7" +
		"\x05)\x15\x02\xB7\xC3\x05\r\x07\x02\xB8\xBC\x05\x1D\x0F\x02\xB9\xBB\x05" +
		"K&\x02\xBA\xB9\x03\x02\x02\x02\xBB\xBE\x03\x02\x02\x02\xBC\xBA\x03\x02" +
		"\x02\x02\xBC\xBD\x03\x02\x02\x02\xBD\xBF\x03\x02\x02\x02\xBE\xBC\x03\x02" +
		"\x02\x02\xBF\xC0\x05\r\x07\x02\xC0\xC2\x03\x02\x02\x02\xC1\xB8\x03\x02" +
		"\x02\x02\xC2\xC5\x03\x02\x02\x02\xC3\xC1\x03\x02\x02\x02\xC3\xC4\x03\x02" +
		"\x02\x02\xC4\xC6\x03\x02\x02\x02\xC5\xC3\x03\x02\x02\x02\xC6\xC7\x05+" +
		"\x16\x02\xC7\x16\x03\x02\x02\x02\xC8\xC9\x05)\x15\x02\xC9\xD5\x05\x0F" +
		"\b\x02\xCA\xCE\x05\x1D\x0F\x02\xCB\xCD\x05K&\x02\xCC\xCB\x03\x02\x02\x02" +
		"\xCD\xD0\x03\x02\x02\x02\xCE\xCC\x03\x02\x02\x02\xCE\xCF\x03\x02\x02\x02" +
		"\xCF\xD1\x03\x02\x02\x02\xD0\xCE\x03\x02\x02\x02\xD1\xD2\x05\x0F\b\x02" +
		"\xD2\xD4\x03\x02\x02\x02\xD3\xCA\x03\x02\x02\x02\xD4\xD7\x03\x02\x02\x02" +
		"\xD5\xD3\x03\x02\x02\x02\xD5\xD6\x03\x02\x02\x02\xD6\xD8\x03\x02\x02\x02" +
		"\xD7\xD5\x03\x02\x02\x02\xD8\xD9\x05+\x16\x02\xD9\x18\x03\x02\x02\x02" +
		"\xDA\xDB\t\x02\x02\x02\xDB\x1A\x03\x02\x02\x02\xDC\xDD\x070\x02\x02\xDD" +
		"\x1C\x03\x02\x02\x02\xDE\xDF\x07.\x02\x02\xDF\x1E\x03\x02\x02\x02\xE0" +
		"\xE1\x07$\x02\x02\xE1 \x03\x02\x02\x02\xE2\xE3\x07*\x02\x02\xE3\"\x03" +
		"\x02\x02\x02\xE4\xE5\x07+\x02\x02\xE5$\x03\x02\x02\x02\xE6\xE7\x07]\x02" +
		"\x02\xE7&\x03\x02\x02\x02\xE8\xE9\x07_\x02\x02\xE9(\x03\x02\x02\x02\xEA" +
		"\xEB\x07}\x02\x02\xEB*\x03\x02\x02\x02\xEC\xED\x07\x7F\x02\x02\xED,\x03" +
		"\x02\x02\x02\xEE\xEF\n\x03\x02\x02\xEF.\x03\x02\x02\x02\xF0\xF1\n\x04" +
		"\x02\x02\xF10\x03\x02\x02\x02\xF2\xF3\n\x05\x02\x02\xF32\x03\x02\x02\x02" +
		"\xF4\xF5\n\x06\x02\x02\xF54\x03\x02\x02\x02\xF6\xF7\n\x07\x02\x02\xF7" +
		"6\x03\x02\x02\x02\xF8\xF9\x07P\x02\x02\xF9\xFA\x07Q\x02\x02\xFA\xFF\x07" +
		"V\x02\x02\xFB\xFC\x07p\x02\x02\xFC\xFD\x07q\x02\x02\xFD\xFF\x07v\x02\x02" +
		"\xFE\xF8\x03\x02\x02\x02\xFE\xFB\x03\x02\x02\x02\xFF8\x03\x02\x02\x02" +
		"\u0100\u0101\x07C\x02\x02\u0101\u0102\x07P\x02\x02\u0102\u0109\x07F\x02" +
		"\x02\u0103\u0104\x07c\x02\x02\u0104\u0105\x07p\x02\x02\u0105\u0109\x07" +
		"f\x02\x02\u0106\u0107\x07(\x02\x02\u0107\u0109\x07(\x02\x02\u0108\u0100" +
		"\x03\x02\x02\x02\u0108\u0103\x03\x02\x02\x02\u0108\u0106\x03\x02\x02\x02" +
		"\u0109:\x03\x02\x02\x02\u010A\u010B\x07Q\x02\x02\u010B\u0111\x07T\x02" +
		"\x02\u010C\u010D\x07q\x02\x02\u010D\u0111\x07t\x02\x02\u010E\u010F\x07" +
		"~\x02\x02\u010F\u0111\x07~\x02\x02\u0110\u010A\x03\x02\x02\x02\u0110\u010C" +
		"\x03\x02\x02\x02\u0110\u010E\x03\x02\x02\x02\u0111<\x03\x02\x02\x02\u0112" +
		"\u0113\x07?\x02\x02\u0113\u0114\x07?\x02\x02\u0114>\x03\x02\x02\x02\u0115" +
		"\u0116\x07#\x02\x02\u0116\u0117\x07?\x02\x02\u0117@\x03\x02\x02\x02\u0118" +
		"\u0119\x07>\x02\x02\u0119B\x03\x02\x02\x02\u011A\u011B\x07@\x02\x02\u011B" +
		"D\x03\x02\x02\x02\u011C\u011D\x07>\x02\x02\u011D\u011E\x07?\x02\x02\u011E" +
		"F\x03\x02\x02\x02\u011F\u0120\x07@\x02\x02\u0120\u0121\x07?\x02\x02\u0121" +
		"H\x03\x02\x02\x02\u0122\u0123\x07K\x02\x02\u0123\u0127\x07P\x02\x02\u0124" +
		"\u0125\x07k\x02\x02\u0125\u0127\x07p\x02\x02\u0126\u0122\x03\x02\x02\x02" +
		"\u0126\u0124\x03\x02\x02\x02\u0127J\x03\x02\x02\x02\u0128\u012E\t\b\x02" +
		"\x02\u0129\u012B\x07\x0F\x02\x02\u012A\u0129\x03\x02\x02\x02\u012A\u012B" +
		"\x03\x02\x02\x02\u012B\u012C\x03\x02\x02\x02\u012C\u012E\x07\f\x02\x02" +
		"\u012D\u0128\x03\x02\x02\x02\u012D\u012A\x03\x02\x02\x02\u012E\u012F\x03" +
		"\x02\x02\x02\u012F\u012D\x03\x02\x02\x02\u012F\u0130\x03\x02\x02\x02\u0130" +
		"\u0131\x03\x02\x02\x02\u0131\u0132\b&\x02\x02\u0132L\x03\x02\x02\x02\u0133" +
		"\u0134\v\x02\x02\x02\u0134N\x03\x02\x02\x02\x1A\x02Zpu{\x81\x88\x8A\x8F" +
		"\x95\xA2\xAA\xB1\xBC\xC3\xCE\xD5\xFE\u0108\u0110\u0126\u012A\u012D\u012F" +
		"\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!FoliaLexer.__ATN) {
			FoliaLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(FoliaLexer._serializedATN));
		}

		return FoliaLexer.__ATN;
	}

}

