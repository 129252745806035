import { debounce } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * A hook to store both live & debounced values of a state variable.
 * Setting the live value will call a debounced state change for the
 * other value, but both values are available at all times.
 */
const useDebouncedState = <T>(initialValue: T, delay: number) => {
  const [state, _setState] = useState(initialValue);
  const [debouncedState, _setDebouncedState] = useState(initialValue);

  useEffect(() => {
    _setDebouncedState(initialValue);
    _setState(initialValue);
  }, [initialValue]);

  const setDebouncedState = useMemo(() => debounce(_setDebouncedState, delay), [delay]);
  const setState = useCallback(
    (value: T) => {
      _setState(value);
      setDebouncedState(value);
    },
    [setDebouncedState],
  );

  const resetState = useCallback(() => {
    setDebouncedState.clear();
    _setState(initialValue);
    _setDebouncedState(initialValue);
  }, [initialValue, setDebouncedState]);

  const unmountDebounce = useCallback(() => {
    setDebouncedState.clear();
  }, [setDebouncedState]);

  return {
    state,
    debouncedState,
    setState,
    resetState,
    unmountDebounce,
  };
};

export default useDebouncedState;
