import { IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import LaunchIcon from '@material-ui/icons/Launch';
import axios from 'axios';
import { FormikProps } from 'formik';
import { Dispatch, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createDefaultAScriptContents as createDefaultContents,
  EditorLanguage,
} from '../../../lang/editorLanguage';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { openEditor } from '../../../redux/aScriptEditor/actions';
import { NotificationLevel } from '../../../shared/constants';
import { CharacteristicDrawerForm } from '../../metadata/characteristic-drawer/characteristicDrawerForm';
import { baseUrl } from '../../shared/environment';
import {
  EditorInterfaceType,
  extractClassIdentifiers,
  getDefaultPackageName,
  getFormikKey,
} from './model/editorInterfaceType';
import OpenDuplicateConfirmationModal from './OpenDuplicateConfirmationModal';

export interface AScriptUsage {
  className: string;
  name: string;
  precompiled: boolean;
}
export interface Props {
  interfaceType: EditorInterfaceType;
  formProps: FormikProps<CharacteristicDrawerForm>;
}

const openCode = async (
  className: string,
  interfaceType: EditorInterfaceType,
  dispatch: Dispatch<any>,
  formProps: FormikProps<CharacteristicDrawerForm>,
) => {
  axios
    .get<{
      ascript: string;
      java: string;
      superclassName: string | null;
    }>(`${baseUrl}api/ascript`, {
      params: {
        className: encodeURIComponent(className),
      },
    })
    .then(({ data: { ascript, java, superclassName } }) => {
      // ascript and java found, open editor with updated content
      dispatch(
        openEditor(
          interfaceType,
          className,
          superclassName,
          {
            [EditorLanguage.JAVA]: decodeURIComponent(java),
            [EditorLanguage.ASCRIPT]: decodeURIComponent(ascript),
          },
          formProps,
        ),
      );
    })
    .catch(error => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to get ascript and java: ${error}`,
        ),
      );
    });
};

const OpenEditorButton: FC<Props> = props => {
  const dispatch = useDispatch();
  const formikKey = getFormikKey(props.interfaceType);
  const fieldValue = (props.formProps.values[formikKey] as string) ?? '';
  const Icon = fieldValue.length ? CreateIcon : LaunchIcon;
  const characteristicName = props.formProps.values.colName ?? '';
  const disabled = !characteristicName.length && !fieldValue.length;
  const [classNameToEdit, setClassNameToEdit] = useState('');
  const [aScriptUsage, setAScriptUsage] = useState<AScriptUsage[] | null>(null);

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={() => {
          const classIdentifiers = fieldValue.startsWith('dynamic.')
            ? extractClassIdentifiers(fieldValue)
            : {
                packageName: getDefaultPackageName(props.interfaceType),
                className: fieldValue.length ? fieldValue : characteristicName,
              };
          axios
            .get<{
              isPrecompiled: boolean;
              isDynamic: boolean;
              proposedName: string;
              generatedName: string;
            }>(`${baseUrl}api/validateaScriptClassname`, {
              params: {
                className: encodeURIComponent(classIdentifiers.className),
                packageName: encodeURIComponent(classIdentifiers.packageName),
              },
            })
            .then(response => {
              if (response.data.isPrecompiled) {
                // is pre-compiled
                dispatch(
                  enqueueSnackbar(
                    NotificationLevel.WARN,
                    `Warning: Unable to view pre-compiled script:`,
                  ),
                );
              } else if (response.data.isDynamic) {
                // code already exists, use the generated name that the server created
                const fullClassName =
                  response.data.generatedName ||
                  `${classIdentifiers.packageName}.${classIdentifiers.className}`;
                axios
                  .get<AScriptUsage[]>(`${baseUrl}api/ascriptUsageByClassName`, {
                    params: {
                      className: encodeURIComponent(fullClassName),
                      superclassName: props.interfaceType,
                    },
                  })
                  .then(response => {
                    const filteredResponse = response.data.filter(
                      obj => obj.name !== props.formProps.values.colName,
                    );
                    if (filteredResponse.length) {
                      setClassNameToEdit(fullClassName);
                      setAScriptUsage(filteredResponse);
                    } else {
                      openCode(fullClassName, props.interfaceType, dispatch, props.formProps);
                    }
                  });
              } else {
                // no code, open brand new
                dispatch(
                  openEditor(
                    props.interfaceType,
                    response.data.proposedName,
                    null,
                    createDefaultContents(),
                    props.formProps,
                  ),
                );
              }
            });
        }}
      >
        <Icon color={disabled ? 'disabled' : 'primary'} />
      </IconButton>
      <OpenDuplicateConfirmationModal
        open={!!classNameToEdit.length}
        setClose={() => setClassNameToEdit('')}
        onSubmit={() => {
          openCode(classNameToEdit, props.interfaceType, dispatch, props.formProps);
        }}
        aScriptUsage={aScriptUsage}
      />
    </>
  );
};

export default OpenEditorButton;
