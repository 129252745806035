import { FullDateContextItem } from 'algo-react-dataviz';
import { createContext, Dispatch } from 'react';
import { InstrumentOrFund } from '../../../shared/dataTypes';

export interface Position {
  positionUnits?: number;
  fundUnits?: number;
  marketValue?: number;
  weight?: number;
  fundWeight?: number;
  currency?: string;
}

export type PositionInstrument = InstrumentOrFund & Position;
export type PortfolioOrBenchmark = { type: 'portfolio' | 'benchmark'; id: string };

export interface PositionDrawerLocalState {
  selectedPortfolioOrBenchmark?: PortfolioOrBenchmark;
  selectedInstruments: PositionInstrument[];
  dateContext?: FullDateContextItem;
}

export const defaultState: PositionDrawerLocalState = { selectedInstruments: [] };

type ChangeInstrumentSpecific<K extends keyof PositionInstrument> = {
  type: 'changeInstrument';
  instrumentId: string;
  propertyName: K;
  propertyValue: PositionInstrument[K];
};

type Action =
  | { type: 'changeDateContext'; date: FullDateContextItem }
  | {
      type: 'setSelectedPortfolioOrBenchmark';
      selectedPortfolioOrBenchmark: PortfolioOrBenchmark;
    }
  | { type: 'setSelectedInstruments'; instruments: PositionInstrument[] }
  | { type: 'addSelectedInstrument'; instrument: InstrumentOrFund }
  | { type: 'removeSelectedInstrument'; instrumentId: string }
  | { type: 'clearInstrument'; instrumentId: string; userCcy: string }
  | { type: 'clearState' };

export const reducer = <K extends keyof PositionInstrument>(
  state: PositionDrawerLocalState,
  action: Action | ChangeInstrumentSpecific<K>,
): PositionDrawerLocalState => {
  switch (action.type) {
    case 'changeDateContext':
      return { ...state, dateContext: action.date };
    case 'setSelectedPortfolioOrBenchmark':
      return { ...state, selectedPortfolioOrBenchmark: action.selectedPortfolioOrBenchmark };
    case 'setSelectedInstruments':
      return { ...state, selectedInstruments: action.instruments };
    case 'addSelectedInstrument':
      return { ...state, selectedInstruments: [...state.selectedInstruments, action.instrument] };
    case 'removeSelectedInstrument':
      return {
        ...state,
        selectedInstruments: state.selectedInstruments.filter(s => s.id !== action.instrumentId),
      };
    case 'changeInstrument':
      return {
        ...state,
        selectedInstruments: state.selectedInstruments.map(s =>
          s.id === action.instrumentId ? { ...s, [action.propertyName]: action.propertyValue } : s,
        ),
      };
    case 'clearInstrument': {
      return {
        ...state,
        selectedInstruments: state.selectedInstruments.map(s =>
          s.id === action.instrumentId
            ? {
                ...s,
                positionUnits: undefined,
                fundUnits: undefined,
                marketValue: undefined,
                weight: undefined,
                fundWeight: undefined,
                currency: action.userCcy,
              }
            : s,
        ),
      };
    }
    case 'clearState':
      return defaultState;
    default:
      throw new Error('Invalid action type.');
  }
};

export const PositionDrawerContext = createContext<{
  state: PositionDrawerLocalState;
  dispatch: Dispatch<Action | ChangeInstrumentSpecific<keyof PositionInstrument>>;
}>({ state: defaultState, dispatch: () => {} });
