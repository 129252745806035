import styled from 'styled-components';

//TODO have nothing in this file and delete it
export const InputWithItalicPlaceholder = styled.input`
  flex: 1;
  border: none;
  margin-left: 10px;
  background-color: #f7f5f7;
  font: 400 12px 'Source Sans Pro';
  ::placeholder {
    font: italic 400 12px 'Source Sans Pro';
    color: #555550;
  }
`;
