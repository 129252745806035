import { Typography } from '@material-ui/core';
import { LIGHT_BORDER } from 'algo-react-dataviz';

export default function AscriptHelpLeft() {
  return (
    <div className='ascript-help-left' style={{ borderRight: LIGHT_BORDER }}>
      <h3>
        <a href='#Top'>
          <b>
            <Typography>aScript Help</Typography>
          </b>
        </a>
        <b>
          <Typography> Topics</Typography>
        </b>
      </h3>
      <h3 style={{ marginLeft: 0 }}>
        <a href='#Overview'>
          <Typography style={{ fontSize: '10pt' }}>Writing aScript </Typography>
        </a>
        <Typography style={{ fontSize: '10pt' }}>
          <br />
          <a href='#Structure'>Structure </a>
          <br />
          <a href='#Comments'>Comments </a>
          <br />
        </Typography>
        <u>
          <a href='#Variable Declaration'>
            <Typography style={{ fontSize: '10pt' }}>Variable Declaration</Typography>
          </a>
        </u>
        <Typography style={{ fontSize: '10pt' }}>
          <br />
        </Typography>
        <a href='#Variable Assignment'>
          <Typography style={{ fontSize: '10pt' }}>Variable Assignment </Typography>
        </a>
        <Typography style={{ fontSize: '10pt' }}>
          <br />
          <a href='#IF Statements'>If Statements </a>
          <br />
          <a href='#Expressions'>Expressions </a>
          <br />
          <a href='#Accessing Entity Value'>Accessing A Value In An Entity </a>
          <br />
          <a href='#String Manipulation'>String Manipulation </a>
          <br />
          <a href='#Math Functions'>MathFunctions </a>
          <br />
          <a href='#IncrDecr'>Increment and Decrement Operators </a>
          <br />
          <a href='#Print'>The print statement </a>
          <br />
          <a href='#return'>The return statement </a>
          <br />
          <a href='#Accessing Java'>Accessing Java Code </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Import Statement'>The Import Statement </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Instantiate a java variable'>Instantiating an imported variable </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Content Assistant for java'>Using the content assistant </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Typography>
        <a href='#AccessingValueContainer'>
          <Typography style={{ fontSize: '10pt' }}>
            Accessing the ValueContainer in a ValueComputer
          </Typography>
        </a>
        <Typography style={{ fontSize: '10pt' }}>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Typography>
        <a href='#PassingValueKey'>
          <Typography style={{ fontSize: '10pt' }}>
            Passing a Value Key as a parameter to a java method
          </Typography>
        </a>
        <Typography style={{ fontSize: '10pt' }} />
        <Typography style={{ fontSize: '10pt' }}>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Casting'>Casting</a>
          <br />
          <a href='#Cell Computers'>Cell Computers </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Typography>
        <u>
          <a href='#RIntegration'>
            <Typography color='primary' style={{ fontSize: '10pt' }}>
              Integration with the R Statistical Package
            </Typography>
          </a>
        </u>
        <Typography style={{ fontSize: '10pt' }}>
          <br />
          <a href='#Aggregators'>Aggregators </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Agg Vars'>Aggregation Variables </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Agg Methods'>Aggregation Methods </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Example Aggs'>Example Aggregators </a>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='#Required ValueKeys'>Required ValueKeys</a>
          <br />
        </Typography>
      </h3>
    </div>
  );
}
