import { CharacteristicDrawerTab } from './characteristicDrawerTabs';

export type AccordionId =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17';

export type CharacteristicExpandedAccordion = Partial<
  {
    [K in AccordionId]: boolean;
  }
>;

export const getAccordionIds = (tab: CharacteristicDrawerTab): AccordionId[] | null => {
  switch (tab) {
    case CharacteristicDrawerTab.PROPERTIES:
      return ['1', '2', '3', '4', '5', '6', '7', '8'];

    case CharacteristicDrawerTab.RISK_COMPUTATION:
      return ['9', '10', '11', '12', '13', '14', '15', '16', '17'];

    case CharacteristicDrawerTab.DESCRIPTION:
    default:
      return null;
  }
};
