import { FC } from 'react';
import AssignedGroupsPanel from './panels/AssignedGroupsPanel';
import UserInfoPanel from './panels/UserInfoPanel';
import UsersPanel from './panels/UsersPanel';
import UserButtons from './UserButtons';

const Users: FC = () => {
  return (
    <div
      style={{
        flex: 1,
        display: 'grid',
        gridTemplateAreas: `
          "users user"
          "users divider"
          "users groups"
          "users buttons"
        `,
        gridTemplateRows: 'auto auto 1fr auto',
        gridTemplateColumns: '2fr 1fr',
        minHeight: 0,
      }}
    >
      <UsersPanel />
      <UserInfoPanel />
      <hr className='divider' />
      <AssignedGroupsPanel />
      <UserButtons />
    </div>
  );
};

export default Users;
