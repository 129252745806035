import { FormControlLabel, Radio } from '@material-ui/core';
import { CSSProperties, FC } from 'react';

interface Props {
  value: string | number;
  label: string;
  style?: CSSProperties;
  disabled?: boolean;
}

const RadioOption: FC<Props> = props => (
  <FormControlLabel
    value={props.value}
    label={props.label}
    control={<Radio color='primary' />}
    labelPlacement='end'
    style={props.style}
    disabled={props.disabled}
  />
);

export default RadioOption;
