import { Typography } from '@material-ui/core';
import { FC } from 'react';
import MainModal from '../../main/MainModal';
import { AScriptUsage } from './OpenEditorButton';

export interface Props {
  open: boolean;
  setClose: () => void;
  onSubmit: () => void;
  aScriptUsage: AScriptUsage[] | null;
}

const OpenDuplicateConfirmationModal: FC<Props> = props => (
  <MainModal
    open={props.open}
    headerText='Custom Class'
    confirmButtonText='Continue'
    onRequestSubmit={() => {
      props.onSubmit();
      props.setClose();
    }}
    onRequestClose={() => {
      props.setClose();
    }}
  >
    <div>
      <Typography style={{ paddingBottom: '15px' }}>This class is also being used by:</Typography>
      {props.aScriptUsage?.map(obj => (
        <Typography variant='h3'>{obj.name}</Typography>
      ))}
      <Typography style={{ paddingTop: '15px' }}>
        Modifying this class will affect these other characteristics.
      </Typography>
      <Typography>You may continue or have this class renamed so that it is unique.</Typography>
    </div>
  </MainModal>
);
export default OpenDuplicateConfirmationModal;
