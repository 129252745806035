import { FullDateContextItem } from 'algo-react-dataviz';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { AppState } from '../../redux/configureStore';
import { NotificationLevel } from '../../shared/constants';
import {
  DateContext,
  Node,
  ReportDateContextType,
  SelectedPortfolios,
  WorkspaceData,
} from '../../shared/dataTypes';
import {
  getDateContextForPortfolioDrawer,
  getDateContextStringWithUnderscore,
} from '../../shared/utils';
import { baseUrl } from '../shared/environment';

export const PORTFOLIO_HIERARCHY_ROOT_ID = 'rootFolderId';
export const AD_HOC_PORTFOLIOS_ROOT_ID = 'root';

export const getPortfolioHierarchy = (date: string, id: string, sandbox?: string) =>
  axios.get<Node<string[]>>(`${baseUrl}api/portfolioHierarchy`, { params: { date, id, sandbox } });

export const getBenchmarks = (
  dateContext: string,
  dateType: ReportDateContextType,
  includeAdHocPortfolios: boolean,
) =>
  axios.get<string[]>(`${baseUrl}api/benchmarks`, {
    params: { dateContext, dateType, includeAdHocPortfolios },
  });

export const hasSelectedPortfolios = ({
  selectedPortfolioNodeIds,
  selectedAdHocPortfolioNames,
  selectedBenchmarkPortfolioNames,
}: SelectedPortfolios = {}) =>
  selectedPortfolioNodeIds?.length >= 1 ||
  selectedAdHocPortfolioNames?.length >= 1 ||
  selectedBenchmarkPortfolioNames?.length >= 1;

export const countSelectedPortfolios = ({
  selectedPortfolioNodeIds,
  selectedAdHocPortfolioNames,
  selectedBenchmarkPortfolioNames,
}: SelectedPortfolios = {}) =>
  (selectedPortfolioNodeIds?.length ?? 0) +
  (selectedAdHocPortfolioNames?.length ?? 0) +
  (selectedBenchmarkPortfolioNames?.length ?? 0);

/**
 * Portfolio selection precedence
 * 1) Report-scope selection (skipped if `sequenceId` is `null`)
 * 2) Workspace-scope selection
 * 3) Active session selection (set from home page)
 * 4) User default selection
 * 5) None (all values `undefined`)
 */
export const getSelectedPortfolios = (
  state: AppState,
  sequenceId: number | null,
  workspace: WorkspaceData | null = state.workspace.data,
): SelectedPortfolios => {
  const reportPortfolioSelection = (sequenceId && state.report.reportDefinition[sequenceId]) || {};
  if (hasSelectedPortfolios(reportPortfolioSelection)) {
    return reportPortfolioSelection;
  }

  const workspacePortfolioSelection = workspace ?? {};
  if (hasSelectedPortfolios(workspacePortfolioSelection)) {
    return workspacePortfolioSelection;
  }

  const activeSessionPortfolioSelection =
    state.user.userInfo.userPreferences.activePortfolioSelection ?? {};
  if (hasSelectedPortfolios(activeSessionPortfolioSelection)) {
    return activeSessionPortfolioSelection;
  }

  return state.user.userInfo.userPreferences.defaultPortfolioSelection ?? {};
};

export const processPortfolioTreeSelection = (
  selection: string[],
  portfolioHierarchy: Node<string>,
  includeNewNodes = true,
): string[] => {
  if (!selection?.length) {
    return [];
  }
  const treeSelection = new Set(selection);

  // build the new tree selection
  // root node is special, so we skip it
  portfolioHierarchy.children?.forEach(node =>
    // buildNewTreeSelection will mutate treeSelection set in place
    buildNewTreeSelection(treeSelection, node, includeNewNodes),
  );

  return Array.from(treeSelection);
};

// this method mutates treeSelection set to roll up or down node selection based on includeNewNodes
const buildNewTreeSelection = (
  treeSelection: Set<string>,
  node: Node<string>,
  includeNewNodes: boolean,
): boolean | void => {
  const fqName = node.id;
  if (includeNewNodes) {
    // switching to include new portfolios
    // roll up all children selected to just parent selected
    if (!node.children) {
      // this is a leaf. simply return if it is currently selected or not (so we can compute it's parent state)
      return treeSelection.has(fqName);
    } else {
      const allChildrenSelected = node.children
        .map(child => buildNewTreeSelection(treeSelection, child, includeNewNodes))
        .every(isSelected => isSelected);
      if (allChildrenSelected) {
        // unselect children, select this node and return true
        node.children.forEach(child => treeSelection.delete(child.id));
        treeSelection.add(fqName);
        return true;
      } else {
        // unselect this node and return false
        treeSelection.delete(fqName);
        return false;
      }
    }
  } else {
    // switching to NOT include new portfolios
    // roll down selected parents to just children selected
    if (treeSelection.has(fqName) && node.children) {
      // this node is a selected parent, unselect it and select its children
      treeSelection.delete(fqName);
      node.children.forEach(child => treeSelection.add(child.id));
    }

    if (node.children) {
      node.children.forEach(child => buildNewTreeSelection(treeSelection, child, includeNewNodes));
    }
  }
};

export const useGetPortfolios = (
  dateContext: DateContext,
  defaultDateContext: FullDateContextItem,
  enqueueSnackbar: (type: NotificationLevel, message: string) => void,
  drawerOpen: boolean,
  selectedPortfolioNodeIds?: string[],
  selectedAdHocPortfolioNames?: string[],
  selectedBenchmarkPortfolioNames?: string[],
  onPortfolioHierarchyLoad?: (data: Node<string>) => void,
  sandbox?: string,
) => {
  const [portfolioHierarchy, setPortfolioHierarchy] = useState<Node<string>>();
  const [adHocPortfolios, setAdHocPortfolios] = useState<Node<string>>();
  const [benchmarkPortfolios, setBenchmarkPortfolios] = useState<string[]>();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [selectedAdHocKeys, setSelectedAdHocKeys] = useState<string[]>([]);
  const [selectedBenchmarkKeys, setSelectedBenchmarkKeys] = useState<string[]>([]);

  // Destructure a bunch of things to avoid putting objects in the dependency array
  const { type } = dateContext || {};
  const { date, id } =
    (dateContext &&
      defaultDateContext &&
      getDateContextForPortfolioDrawer(defaultDateContext, dateContext)) ||
    {};

  const dateContextString =
    dateContext &&
    defaultDateContext &&
    getDateContextStringWithUnderscore(dateContext, defaultDateContext);

  // Create these booleans, to have the minimal amount of information in the dependency array.
  // True if anything is selected in the saved state.
  const someSelected = selectedPortfolioNodeIds?.length > 0;

  // True if all options have loaded
  const optionsLoaded = !!(portfolioHierarchy && adHocPortfolios && benchmarkPortfolios);

  const getOptions = useCallback(() => {
    getPortfolioHierarchy(date, id, sandbox)
      .then(response => {
        setPortfolioHierarchy(response.data[0]);
        setAdHocPortfolios(response.data[1]);

        if (!someSelected) {
          // SD-1781 When nothing is selected, it means everything is selected, so check
          // everything
          setSelectedKeys(
            getAllKeys(response.data[0]).filter(k => k !== PORTFOLIO_HIERARCHY_ROOT_ID),
          );
        }

        onPortfolioHierarchyLoad?.(response.data[0]);
      })
      .catch((error: Error) => {
        enqueueSnackbar(NotificationLevel.ERROR, `Failed to load portfolios: ${error.message}`);
      });

    getBenchmarks(dateContextString, type, false)
      .then(response => {
        setBenchmarkPortfolios(response.data);
      })
      .catch((error: Error) => {
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to load benchmark portfolios from server: ${error.message}`,
        );
      });
  }, [
    type,
    date,
    id,
    dateContextString,
    someSelected,
    onPortfolioHierarchyLoad,
    sandbox,
    enqueueSnackbar,
  ]);

  const loadOptions = useCallback(() => {
    if (type && !optionsLoaded) {
      getOptions();
    }
  }, [type, optionsLoaded, getOptions]);

  useEffect(() => {
    if (drawerOpen) {
      loadOptions();
      setSelectedKeys(selectedPortfolioNodeIds || []);
      setSelectedAdHocKeys(selectedAdHocPortfolioNames || []);
      setSelectedBenchmarkKeys(selectedBenchmarkPortfolioNames || []);
    } else {
      setPortfolioHierarchy(undefined);
      setAdHocPortfolios(undefined);
      setBenchmarkPortfolios(undefined);
      setSelectedKeys([]);
      setSelectedAdHocKeys([]);
      setSelectedBenchmarkKeys([]);
    }
  }, [
    drawerOpen,
    loadOptions,
    selectedPortfolioNodeIds,
    selectedAdHocPortfolioNames,
    selectedBenchmarkPortfolioNames,
  ]);

  return {
    portfolioHierarchy,
    adHocPortfolios,
    benchmarkPortfolios,
    selectedKeys,
    setSelectedKeys,
    selectedAdHocKeys,
    setSelectedAdHocKeys,
    selectedBenchmarkKeys,
    setSelectedBenchmarkKeys,
    getOptions,
  };
};

const getAllKeys = (data: Node<string>): string[] =>
  data.children ? [data.id, ...data.children.flatMap(getAllKeys)] : data.id;
