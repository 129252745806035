import { DialogActions } from '@material-ui/core';
import { LIGHT_BORDER, RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AScriptEditorLanguage, EditorLanguage } from '../../../lang/editorLanguage';
import { EditorModel } from '../../../lang/useEditorModel';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { closeEditor } from '../../../redux/aScriptEditor/actions';
import {
  getClassName,
  getEditorInterfaceType,
  getFormProps,
  getSuperclass,
} from '../../../redux/aScriptEditor/selectors';
import { NotificationLevel } from '../../../shared/constants';
import { validateSingleField } from '../../metadata/characteristic-drawer/characteristicDrawerFormHelpers';
import { baseUrl } from '../../shared/environment';
import { CompileStatus } from './AscriptEditorModal';
import { getFormikKey } from './model/editorInterfaceType';

interface Props {
  editorModel: EditorModel<AScriptEditorLanguage>;
  isCompiling: boolean;
  currentLanguage: EditorLanguage;
  validJava: string;
  setCloseModalOpen: (toggle: boolean) => void;
  setSuccessMessage: (message: string) => void;
  setErrorMessage: (message: string) => void;
  setCompileStatus: (compileStatus: CompileStatus) => void;
  setValidJava: (java: string) => void;
  hasEditPermission: boolean;
}

const AscriptEditorFooter: FC<Props> = props => {
  const className = useSelector(getClassName);
  const superclassName = useSelector(getSuperclass);
  const interfaceName = useSelector(getEditorInterfaceType);
  const formProps = useSelector(getFormProps);

  const dispatch = useDispatch();

  const compile = (publish: boolean) => {
    if (!props.editorModel.isInitialized()) {
      return;
    }

    const editorContents = props.editorModel.api.getEditorContents();
    props.setSuccessMessage(null);
    props.setErrorMessage(null);
    props.setCompileStatus(CompileStatus.COMPILING);
    axios
      .post<{
        java: string;
        log: string;
        status: boolean;
      }>(`${baseUrl}api/compileAScript`, {
        className: className,
        interfaceName: interfaceName,
        superClassname: superclassName,
        publish,
        ...(publish
          ? {
              aScript: editorContents[EditorLanguage.ASCRIPT],
              java: editorContents[EditorLanguage.JAVA],
            }
          : {
              [props.currentLanguage]: editorContents[props.currentLanguage],
            }),
      })
      .then(response => {
        const java = decodeURIComponent(response.data.java);
        if (props.currentLanguage === EditorLanguage.JAVA && java !== props.validJava) {
          props.editorModel.api.setValue(EditorLanguage.ASCRIPT, '');
        }

        props.editorModel.api.setValue(EditorLanguage.JAVA, java);
        props.editorModel.api.clearAllMarkers();

        if (publish && response.data.status) {
          // only display log if there's a warning and changes were made to java
          if (
            response.data.log !== 'Compilation Successful.' &&
            props.editorModel.api.getEditorContents()[EditorLanguage.JAVA] !== props.validJava
          ) {
            dispatch(enqueueSnackbar(NotificationLevel.WARN, response.data.log));
          }
          dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, `Compiled and saved successfully`));
          dispatch(closeEditor());

          // validate/update formik field
          const formikKey = getFormikKey(interfaceName);
          validateSingleField(formikKey, className, formProps);
          formProps.setFieldValue(formikKey, className, false);
          formikKey === 'reportAggregator' &&
            formProps.setFieldValue('reportAggregationType', undefined, false);
        } else {
          (response.data.status ? props.setSuccessMessage : props.setErrorMessage)(
            response.data.log,
          );
        }
        props.setValidJava(java);
        props.setCompileStatus(CompileStatus.IDLE);
      })
      .catch(error => {
        props.setCompileStatus(CompileStatus.IDLE);
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to compile ascript and java: ${error}`,
          ),
        );
      });
  };

  return (
    <DialogActions style={{ borderTop: LIGHT_BORDER }} className='editor-bottom-row'>
      <RoundedButton
        className='editor-bottom-row-button'
        onClick={() => compile(false)}
        color='primary'
        variant='outlined'
        disabled={props.isCompiling || !className || !props.hasEditPermission}
      >
        Compile
      </RoundedButton>

      <div>
        <RoundedButton
          className='editor-bottom-row-button'
          onClick={() => props.setCloseModalOpen(true)}
          color='secondary'
          variant='outlined'
          disabled={props.isCompiling}
        >
          Cancel
        </RoundedButton>
        <RoundedButton
          className='editor-bottom-row-button'
          onClick={() => {
            compile(true);
          }}
          color='primary'
          variant='contained'
          disabled={props.isCompiling || !className || !props.hasEditPermission}
        >
          Apply
        </RoundedButton>
      </div>
    </DialogActions>
  );
};

export default AscriptEditorFooter;
