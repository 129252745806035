import { IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { AccountTree, FolderOpen, Tune } from '@material-ui/icons';
import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { copyPreviousStatesIntoTabs } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { isDesignerDrillThrough } from '../../../redux/designer/panel/selectors';
import {
  resetLayoutComplication,
  setLayoutComplication,
  setReportableTypeAndRegenerate,
  setReportDefinitionCurrencyAndRegenerate,
  setReportDefinitionDateContext,
  setReportDefinitionVisualizations,
  setReportTitle,
} from '../../../redux/ReportActionCreators';
import { DESIGNER_SEQUENCE_ID, DRILLTHROUGH_SETTING_TOOLTIP } from '../../../shared/constants';
import { RepTypesIdName } from '../../../shared/dataTypes';
import {
  getReportDefinitionCurrency,
  hasSaccr,
  showPortfolioSelector,
  visualizationChoicesForSelection,
  visualizationsList,
  VISUALIZATIONS_WITH_LAYOUT,
} from '../../../shared/utils';
import ReportEntitySelectionDrawer from '../../drawers/entity-selection-drawer/ReportEntitySelectionDrawer';
import ReportDesignerDatePicker from '../../report-dates/ReportDesignerDatePicker';
import DesignerPortfolioDrawer from '../DesignerPortfolioDrawer';
import OptionsDrawer from '../options-drawer/OptionsDrawer';
import './designer-panel-header.scss';
import DesignerPanelHeaderSelect from './DesignerPanelHeaderSelect';

interface BaseProps {
  visualizationType: string;
  reportableTypes: RepTypesIdName[];
}

const mapStateToProps = (state: AppState) => {
  const { reportTitle, reportableType, dateContext, settings } =
    state.report.reportDefinition[DESIGNER_SEQUENCE_ID] ?? {};
  const isDrillThrough = isDesignerDrillThrough(state);
  return {
    reportTitle,
    reportableType,
    dateContext,
    currency: getReportDefinitionCurrency(state, DESIGNER_SEQUENCE_ID),
    currencies: state.user.userInfo.serverConfigs?.currencies,
    isDrillThrough,
    hasSaccr: hasSaccr(state),
    showPortfolioSelector: showPortfolioSelector(state),
    disableCurrency:
      isDrillThrough && settings?.drillThroughInheritance && settings?.reportingCurrency,
  };
};

const mapDispatchToProps = {
  setReportTitle,
  setLayoutComplication,
  resetLayoutComplication,
  setReportDefinitionVisualizations,
  setReportableTypeAndRegenerate,
  setReportDefinitionDateContext,
  setReportDefinitionCurrencyAndRegenerate,
  copyPreviousStatesIntoTabs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const DesignerPanelHeader: FC<Props> = props => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isPortfoliosOpen, setIsPortfoliosOpen] = useState(false);
  const [isEntitiesOpen, setIsEntitiesOpen] = useState(false);

  return (
    <>
      <div id='report-designer-header'>
        <div className='header-label-container'>
          <Typography variant='h1' className='header-label'>
            Report Designer
          </Typography>
        </div>

        <div className='header-report-name-container'>
          <TextField
            className='header-report-name-field'
            label='Report Name'
            value={props.reportTitle || ''}
            onChange={event => props.setReportTitle(event.target.value)}
          />
        </div>

        <DesignerPanelHeaderSelect
          label='Reporting Type'
          value={
            props.reportableTypes.findIndex(v => v.id === props.reportableType) === -1
              ? ''
              : props.reportableType
          }
          onChange={e => {
            props.setReportableTypeAndRegenerate(e.target.value as number | '');
          }}
          menuItems={props.reportableTypes}
        />

        <ReportDesignerDatePicker />

        <Tooltip title={props.disableCurrency ? DRILLTHROUGH_SETTING_TOOLTIP : ''}>
          <div>
            <DesignerPanelHeaderSelect
              label='Reporting Currency'
              value={props.currency}
              onChange={e => {
                props.setReportDefinitionCurrencyAndRegenerate(
                  DESIGNER_SEQUENCE_ID,
                  e.target.value,
                );
              }}
              menuItems={[{ id: 'default', name: 'Default' }].concat(
                props.currencies.map(c => ({ id: c, name: c })),
              )}
              disabled={props.disableCurrency}
            />
          </div>
        </Tooltip>

        <DesignerPanelHeaderSelect
          label='Visualization Type'
          value={props.visualizationType}
          onChange={e => {
            if (VISUALIZATIONS_WITH_LAYOUT.includes(e.target.value))
              props.setLayoutComplication(DESIGNER_SEQUENCE_ID);
            else props.resetLayoutComplication(DESIGNER_SEQUENCE_ID);

            props.setReportDefinitionVisualizations(
              DESIGNER_SEQUENCE_ID,
              visualizationChoicesForSelection(e.target.value),
            );
          }}
          menuItems={visualizationsList}
        />

        <div className='header-icon-container'>
          {props.showPortfolioSelector && (
            <IconButton
              onClick={() => setIsPortfoliosOpen(true)}
              disabled={props.isDrillThrough}
              className='header-icon-button'
            >
              <FolderOpen
                color={props.isDrillThrough ? 'disabled' : 'primary'}
                className='header-icon'
              />
            </IconButton>
          )}

          {props.hasSaccr && (
            <IconButton
              onClick={() => setIsEntitiesOpen(true)}
              disabled={props.isDrillThrough}
              className='header-icon-button'
            >
              <AccountTree
                color={props.isDrillThrough ? 'disabled' : 'primary'}
                className='header-icon'
              />
            </IconButton>
          )}
        </div>

        <div className='header-icon-container'>
          <IconButton
            onClick={() => {
              props.copyPreviousStatesIntoTabs();
              setIsOptionsOpen(true);
            }}
            className='header-icon-button'
          >
            <Tune color='primary' className='header-icon' />
          </IconButton>
        </div>
      </div>

      <OptionsDrawer drawerOpen={isOptionsOpen} closeDrawer={() => setIsOptionsOpen(false)} />
      <DesignerPortfolioDrawer
        drawerOpen={isPortfoliosOpen}
        closeDrawer={() => setIsPortfoliosOpen(false)}
      />
      <ReportEntitySelectionDrawer
        drawerOpen={isEntitiesOpen}
        closeDrawer={() => setIsEntitiesOpen(false)}
      />
    </>
  );
};

export default connector(DesignerPanelHeader);
