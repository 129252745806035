import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateDateContext } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';

const useUrlDateContext = () => {
  const defaultDateContext = useSelector(
    (state: AppState) => state.user.userInfo?.serverConfigs?.defaultDateContext,
  );

  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(useLocation().search);
  const urlDate = urlParams.get('date');
  const urlRun = urlParams.get('run');

  useEffect(() => {
    const dateContext = urlDate ? { date: urlDate, id: urlRun ?? '' } : defaultDateContext;

    if (dateContext) {
      dispatch(updateDateContext(dateContext));
    }
  }, [defaultDateContext, dispatch, urlDate, urlRun]);
};

export default useUrlDateContext;
