import { getReportWorkspacePayload } from '../../../shared/utils';
import { AppState } from '../../configureStore';
import { DesignerSource } from './designerSource';

export const isDesignerDrillThrough = (state: AppState) =>
  state.reportDesigner.panelControl.source === DesignerSource.OPEN_WITH_DRILL_THROUGH ||
  isReportDrillThrough(state, state.reportDesigner.panelControl.sourceSequenceId);

const isReportDrillThrough = (state: AppState, sequenceId: number) =>
  !!getReportWorkspacePayload(sequenceId, state)?.drillThrough;

export const getDisableCustomSettings = (state: AppState) =>
  isDesignerDrillThrough(state) &&
  state.reportDesigner.settings?.scenarioSettings &&
  state.reportDesigner.settings?.drillThroughInheritance;
