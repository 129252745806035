import { LIGHT_BORDER } from 'algo-react-dataviz';
import { CSSProperties, FC } from 'react';
import SettingsHeader from './SettingsHeader';

interface Props {
  name?: string;
  hidden?: boolean;
  style?: CSSProperties;
}

const SettingsGroup: FC<Props> = props =>
  props.hidden ? null : (
    <div style={{ padding: '22px 0', borderBottom: LIGHT_BORDER, ...props.style }}>
      {props.name && <SettingsHeader name={props.name} />}
      {props.children}
    </div>
  );

export default SettingsGroup;
