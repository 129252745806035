import { FC } from 'react';
import './grouping-strategy-designer.scss';
import GroupingStrategyDesignerContent from './GroupingStrategyDesignerContent';

// TODO: No longer necessary, but has stylesheet impact
const GroupingStrategyDesigner: FC = () => (
  <div className='gsd'>
    <GroupingStrategyDesignerContent />
  </div>
);

export default GroupingStrategyDesigner;
