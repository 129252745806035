import { Close } from '@material-ui/icons';
import { FC } from 'react';

interface Props {
  onClick: () => void;
}

const CloseButton: FC<Props> = props => (
  <Close
    style={{
      height: '16px',
      position: 'absolute',
      right: ' 0.25rem',
      top: '0.25rem',
      cursor: 'pointer',
      color: '#8d8d8d',
    }}
    onClick={props.onClick}
  />
);

export default CloseButton;
