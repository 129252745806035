import { Typography } from '@material-ui/core';
import { FC, useEffect, useMemo, useState } from 'react';
import useFoliaState from '../../../../lang/folia/useFoliaState';
import { useAppSelector } from '../../../../redux/configureStore';
import { getDrawerStatus } from '../../../../redux/custom-grouping/selectors';
import { CustomGroupingDrawerStatus } from '../../../../redux/custom-grouping/state';
import { ValidationStatus } from '../../../../shared/validation/model';
import FoliaEditor from '../../../editor/folia/FoliaEditor';
import { Props as FoliaEditorFabProps } from '../../../editor/folia/FoliaEditorFab';
import useCustomGroupingState from '../../context/useCustomGroupingState';
import { PredicateStrategyViewState } from '../viewState';
import './predicate-strategy-designer.scss';

const PredicateStrategyDesigner: FC = () => {
  const readOnly = useAppSelector(getDrawerStatus) === CustomGroupingDrawerStatus.VIEW;
  const [isSaved, setIsSaved] = useState(true);

  const {
    designerViewState,
    updateDesignerViewState,
  } = useCustomGroupingState<PredicateStrategyViewState>();

  const initialContent = designerViewState.predicate?.code ?? '';
  const state = useFoliaState({
    id: `predicate-designer-${designerViewState?.nodeId}`,
    initialContent,
  });

  const {
    validation: { status: validationStatus },
    getPredicate,
  } = state;

  const content = getPredicate()?.code ?? '';
  useEffect(() => {
    setIsSaved(content === initialContent);
  }, [content, initialContent]);

  const fabProps: FoliaEditorFabProps = useMemo(
    () => ({
      tooltipContent: [
        ...(validationStatus === ValidationStatus.VALID ? ['Apply to node'] : []),
        ...(validationStatus === ValidationStatus.INVALID ? ['Invalid syntax'] : []),
        ...(isSaved ? [] : ['You have unsaved changes']),
      ],
      onClick: () => {
        if (validationStatus === ValidationStatus.VALID) {
          updateDesignerViewState(viewState => ({
            ...viewState,
            predicate: getPredicate(),
          }));
        }
      },
      disabled:
        readOnly || ![ValidationStatus.PRISTINE, ValidationStatus.VALID].includes(validationStatus),
      getPredicate,
      showBadge: !isSaved,
    }),
    [validationStatus, isSaved, getPredicate, updateDesignerViewState, readOnly],
  );

  return (
    <div className='gsd-content gsd-predicate'>
      <Typography className='gsd-predicate-title'>Enter a logical expression</Typography>
      <FoliaEditor id='gsd-predicate' {...{ state, fabProps, readOnly }} />
    </div>
  );
};

export default PredicateStrategyDesigner;
