import { ReactiveDataGrid } from 'algo-react-dataviz';
import ActivePieChartViz from '../charting/ActivePieChartViz';
import AreaChartViz from '../charting/AreaChartViz';
import BarChartViz from '../charting/BarChartViz';
import BrushedBarChartViz from '../charting/BrushBarChartViz';
import BulletGraphViz from '../charting/BulletGraphViz';
import ComposedChartViz from '../charting/ComposedChartViz';
import LineChartViz from '../charting/LineChartViz';
import MixedBarChartViz from '../charting/MixedBarChartViz';
import PieChartViz from '../charting/PieChartViz';
import RadialBarChartViz from '../charting/RadialBarChartViz';
// import ReactiveMapChartViz from '../charting/ReactiveMapChartViz';
import ScatterChartViz from '../charting/ScatterChartViz';
import TreeMapViz from '../charting/TreeMapViz';
import TwoLevelPieChartViz from '../charting/TwoLevelPieChartViz';
import XYLineChartViz from '../charting/XYLineChartViz';
import XYStackedBarChartViz from '../charting/XYStackedBarChartViz';

export const visualizationImpls = {
  DX_TABLE: ReactiveDataGrid,
  // MAP_CHART: ReactiveMapChartViz, // not production-ready
  BAR_CHART: BarChartViz,
  STACKED_BAR_CHART: BarChartViz,
  MIXED_BAR_CHART: MixedBarChartViz,
  BRUSH_BAR_CHART: BrushedBarChartViz,
  STACKED_BRUSH_BAR_CHART: BrushedBarChartViz,
  LINE_GRAPH: LineChartViz,
  XY_LINE_CHART: XYLineChartViz,
  XY_SPLINE_LINE_CHART: XYLineChartViz,
  XY_SPLINE_LINE_ONLY_CHART: XYLineChartViz,
  XY_LINE_NO_SYMBOL_CHART: XYLineChartViz,
  XY_SYMBOL_NO_LINE_CHART: XYLineChartViz,
  XY_STACKED_BAR_CHART: XYStackedBarChartViz,
  XY_BAR_CHART: XYStackedBarChartViz,
  PIE_CHART: PieChartViz,
  AREA_CHART: AreaChartViz,
  ACTIVE_PIE_CHART: ActivePieChartViz,
  TWO_LEVEL_PIE_CHART: TwoLevelPieChartViz,
  TREE_MAP: TreeMapViz,
  SCATTER_CHART: ScatterChartViz,
  BUBBLE_CHART: ScatterChartViz,
  COMPOSED_CHART: ComposedChartViz,
  RADIAL_BAR_CHART: RadialBarChartViz,
  BULLET_GRAPH: BulletGraphViz,
};
