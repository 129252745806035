import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import { baseUrl } from '../../shared/environment';
import { DateContext } from '../../../shared/dataTypes';
import { getDateContextStringWithUnderscore } from '../../../shared/utils';

interface BaseProps {
  selectedOwnEntities: string[];
  setSelectedOwnEntities: (selected: string[]) => void;
  dateContext?: DateContext;
}

const mapStateToProps = (state: AppState) => ({ userDateContext: state.user.selectedDateContext });
const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const OwnEntity: FC<Props> = props => {
  const [allOwnEntities, setAllOwnEntities] = useState<string[]>([]);

  // Populate list of allOwnEntities on first render (also when date context changes)
  const { dateContext, userDateContext } = props;
  useEffect(() => {
    axios
      .get<string[]>(`${baseUrl}api/ownEntities`, {
        params: {
          dateType: dateContext?.type || 'default',
          dateContexts:
            !dateContext || dateContext.type === 'default'
              ? userDateContext.date
              : getDateContextStringWithUnderscore(dateContext),
        },
      })
      .then(result => setAllOwnEntities(result.data));
  }, [dateContext, userDateContext]);

  return (
    <FormGroup className='own-entity'>
      {allOwnEntities.map((a, i) => (
        <FormControlLabel
          className='item-label'
          key={i}
          label={a}
          control={
            <Checkbox
              color='primary'
              checked={props.selectedOwnEntities.includes(a)}
              onChange={e =>
                props.setSelectedOwnEntities(
                  e.target.checked
                    ? [...props.selectedOwnEntities, a]
                    : props.selectedOwnEntities.filter(s => s !== a),
                )
              }
            />
          }
        />
      ))}
    </FormGroup>
  );
};

export default connector(OwnEntity);
