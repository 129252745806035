import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
} from '@material-ui/core';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { baseUrl } from '../shared/environment';
import { NotificationLevel } from '../../shared/constants';
import { GrouperProps } from './GrouperModal';
import MenuItems from './ui-elements/MenuItems';

const mapDispatchToProps = {
  enqueueSnackbar,
};

interface SESScenarioGrouperDataType {
  sesType: string;
}

const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & GrouperProps<SESScenarioGrouperDataType>;

const SESScenarioGrouperModal: FC<Props> = props => {
  const [sesType, setSESType] = useState(props.initialValue?.sesType);
  const [allSESTypes, setAllSESTypes] = useState<string[]>([]);

  const { enqueueSnackbar } = props;

  useEffect(() => {
    let componentIsMounted = true;
    axios
      .get<string[]>(`${baseUrl}api/sesScenarioGrouperTypes`)
      .then(r => {
        if (componentIsMounted) {
          setAllSESTypes(r.data);
          if (!sesType) {
            setSESType(r.data[0]);
          }
        }
      })
      .catch((error: Error) => {
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to SES scenario grouper types: ${error.message}`,
        );
        if (componentIsMounted) {
          setAllSESTypes([]);
        }
      });

    return () => {
      componentIsMounted = false;
    };
  }, [enqueueSnackbar, sesType]);

  return (
    <Dialog open={true} onClose={props.onCancelClick}>
      <DialogTitle>Editor: SES Scenario Grouper</DialogTitle>

      <DialogContent>
        <div
          style={{
            alignItems: 'center',
            height: 50,
            width: 300,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Select
            variant='outlined'
            value={allSESTypes.includes(sesType) ? sesType : ''}
            onChange={e => setSESType(e.target.value as string)}
            style={{ width: '100%', height: '40px', marginTop: '5px' }}
          >
            {MenuItems(allSESTypes)}
          </Select>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          onClick={() => props.onApplyClick({ sesType: sesType })}
        >
          Apply
        </Button>
        <Button onClick={() => props.onCancelClick()} variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connector(SESScenarioGrouperModal);
