import { Paper, Typography } from '@material-ui/core';
import { CSSProperties, FC } from 'react';
import { Droppable, DroppableId } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Characteristic } from '../../../shared/dataTypes';
import EmptyDroppable from './EmptyDroppable';
import PopulatedDroppable from './PopulatedDroppable';

const PaperContainer = styled(Paper)`
  padding: 11px 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden; // https://stackoverflow.com/a/35609992/400765
`;

const InnerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden; // https://stackoverflow.com/a/35609992/400765
`;

type Props = {
  id: DroppableId;
  title?: string;
  chars: Characteristic[];
  // Set to 'horizontal' to make draggables align horizontally
  direction?: 'horizontal' | 'vertical';
  remove?: (droppableId: string, draggableId: string) => void;
  editor?: (
    droppableId: string,
    draggableId: string,
    editor: string,
    target: EventTarget & HTMLButtonElement,
  ) => void;
  style?: CSSProperties;
  hidden?: boolean;
};

const CharacteristicsDroppable: FC<Props> = props => (
  <PaperContainer
    style={{ margin: '6px', borderRadius: '4px 4px 0px 0px', ...props.style }}
    hidden={props.hidden}
  >
    {props.title && (
      <Typography variant='h2' style={{ marginBottom: '0.5em', color: '#555550' }}>
        {props.title}
      </Typography>
    )}
    <Droppable droppableId={props.id} direction={props.direction}>
      {(provided, snapshot) => (
        <InnerContainer>
          {props.chars.length > 0 ? (
            <PopulatedDroppable
              id={props.id}
              provided={provided}
              direction={props.direction}
              chars={props.chars}
              remove={props.remove}
              editor={props.editor}
              isDraggingOver={snapshot.isDraggingOver}
            />
          ) : (
            <EmptyDroppable
              id={props.id}
              provided={provided}
              isDraggingOver={snapshot.isDraggingOver}
            />
          )}
        </InnerContainer>
      )}
    </Droppable>
    {props.children}
  </PaperContainer>
);

export default CharacteristicsDroppable;
