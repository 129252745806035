import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import { FC } from 'react';

interface Props {
  open: boolean;
  title: string;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  checked: string[];
  deleteDialogType?: string;
}

const DeleteDialog: FC<Props> = props => (
  <Dialog open={props.open} onClose={props.onCancelClick}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to delete the following {props.deleteDialogType?.toLowerCase()}
        {props.checked.length > 1 ? 's' : ''}?
      </Typography>
      {props.checked.map(value => (
        <Typography key={value} style={{ fontWeight: 600 }}>
          {value}
        </Typography>
      ))}
      <Typography>This action cannot be undone.</Typography>
    </DialogContent>
    <DialogActions>
      <RoundedButton onClick={props.onCancelClick} color='secondary' variant='outlined'>
        Cancel
      </RoundedButton>
      <RoundedButton onClick={props.onDeleteClick} color='secondary' variant='contained'>
        Delete
      </RoundedButton>
    </DialogActions>
  </Dialog>
);

export default DeleteDialog;
