import { Flavor } from '../../../model/flavor';
import { AppState } from '../../../redux/configureStore';
import { Merge } from '../../../shared/dataTypes';
import { hasAnyFlavor, hasFlavor, isAwa } from '../../../shared/utils';

export type Metadata = Merge<PropertiesForm, { computeContext: RiskComputationForm }>;
export interface CharacteristicDrawerForm extends PropertiesForm, RiskComputationForm {}

export interface PropertiesForm {
  colName: string;
  entityType: number;
  valueKey: string;
  dynamic: boolean;
  keyTranslation: string;
  colType: number;
  numberFormatString: string;
  divisor: number;
  justification: string;
  computer: string;
  reportAggregator: string;
  reportAggregationType: number;
  postProcessor: string;
  scalable: boolean;
  ccySensitive: boolean;
  showInReportCcy: boolean;
  benchName: string;
  diffName: string;
  benchmarkCopy: string;
  benchmarkVisibility: string;
  colChooserVisibility: number;
  editable: boolean;
  editability: string;
  editProcessorClass: string;
  autoFillType: number;
  reportFormatter: string;
  breakdownClass: string;
  bucketOrderDescending: string;
  predefinedFilter: string;
  visibleColumn: boolean;
  columnClasses: string;
  relatedEntities: string;
  description: string; // on its own tab
  dataEntryComponent: string;
}

export interface RiskComputationForm {
  inputMappingHeader: string;
  cubeAttribute: string;
  confidenceLevel: number;
  smoothingMethodology: string;
  // these keys are present during certain smoothingMethodology
  // Triangle && Gaussian
  numScenarios?: number;
  // Gaussian
  numStdDeviations?: number;
  // Flexible Kernel
  smoothingSimulations?: number;
  // User Defined Weight
  userDefinedWeight?: number;

  parentScenario: string;
  setName: string;
  startingIndex: number;
  range: number;
  windowIncrement: number;
  pValueScenarioSet: string;
  aggregationMethod: string;
  riskMeasure: string;
  drcCubeName: string;
  isDealAllocationUserDefinedField: boolean;
  activeMeasure: boolean;
  currencyKey: string;
  scaleKey: string;
  scaleCubeAttribute: string;
  ancestorValue: string;
  baseKey: string;
  baseHierarchyFile: string;
  portfolioHierarchyHistoricValueMap: string;
  dataEntryComponentValues: string;
}

export const resolveInitialFormValues = (state: AppState): CharacteristicDrawerForm => ({
  ...createInitialRiskComputationForm(),
  ...resolveInitialPropertiesForm(state),
});

export const resolveInitialPropertiesForm = (state: AppState): PropertiesForm => ({
  ...(isAwa(state)
    ? hasAnyFlavor(state)(...[Flavor.ALMLR, Flavor.MR, Flavor.FRTB])
      ? createInitialMRALMRFRTBPropertiesForm()
      : hasFlavor(state)(Flavor.SACCR)
      ? createInitialSACCRPropertiesForm()
      : createInitialPropertiesForm()
    : createInitialPropertiesForm()),
});

const createInitialPropertiesForm = (): PropertiesForm => ({
  colName: '',
  entityType: 3, // 3 is other
  valueKey: '',
  dynamic: true,
  keyTranslation: '',
  colType: 5,
  numberFormatString: '',
  divisor: 1,
  justification: 'Default',
  computer: '',
  reportAggregator: '',
  reportAggregationType: null,
  postProcessor: '',
  scalable: false,
  ccySensitive: false,
  showInReportCcy: false,
  benchName: '',
  diffName: '',
  benchmarkCopy: 'Default',
  benchmarkVisibility: 'Default',
  colChooserVisibility: 0,
  editable: false,
  editability: 'Single',
  editProcessorClass: '',
  autoFillType: 2, // translates to default
  reportFormatter: '',
  breakdownClass: '',
  bucketOrderDescending: 'false', // translates to Ascending
  predefinedFilter: '',
  visibleColumn: true,
  columnClasses: '',
  relatedEntities: '',
  description: '',
  dataEntryComponent: '',
});

const createInitialSACCRPropertiesForm = (): PropertiesForm => ({
  ...createInitialPropertiesForm(),
  entityType: 121, // 121 is Deal
});

const createInitialMRALMRFRTBPropertiesForm = (): PropertiesForm => ({
  ...createInitialPropertiesForm(),
  entityType: 22, // 22 is Position
});

export const createInitialRiskComputationForm = (): RiskComputationForm => ({
  inputMappingHeader: '',
  cubeAttribute: '',
  confidenceLevel: 97.5,
  smoothingMethodology: 'Quantile',
  parentScenario: '',
  setName: '',
  startingIndex: 0,
  range: 0,
  windowIncrement: 0,
  pValueScenarioSet: '',
  aggregationMethod: '',
  riskMeasure: '',
  drcCubeName: '',
  isDealAllocationUserDefinedField: false,
  activeMeasure: false,
  currencyKey: '',
  scaleKey: '',
  scaleCubeAttribute: '',
  ancestorValue: '',
  baseKey: '',
  baseHierarchyFile: '',
  portfolioHierarchyHistoricValueMap: '',
  dataEntryComponentValues: '',
  numScenarios: 5,
  numStdDeviations: 2,
  smoothingSimulations: 0,
  userDefinedWeight: 0,
});
