import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar, getErrorMessage } from '../../redux/ActionCreators';
import { baseUrl } from '../shared/environment';
import { DrawerType, NotificationLevel } from '../../shared/constants';
import { RoundedButton } from 'algo-react-dataviz';

interface BaseProps {
  open: boolean;
  onBackdropClick?: () => void;
  headerText: string;
  importTarget: string;
  importType: DrawerType;
  importData: any;
  onCloseRequest: () => void;
}

const mapDispatchToProps = { enqueueSnackbar };
const connector = connect(undefined, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & BaseProps;

const ImportConflictModal: FC<Props> = props => {
  const finishImport = (overrideExisting: boolean) => () => {
    const endPoint =
      props.importType === DrawerType.IMPORT_REPORT_TO_FOLDER
        ? 'importReports'
        : 'importWorkspaces';

    axios
      .post<boolean>(`${baseUrl}api/${endPoint}`, {
        target: props.importTarget,
        data: props.importData,
        overrideExisting,
      })
      .then(() => {
        props.enqueueSnackbar(NotificationLevel.SUCCESS, 'Import complete');
      })
      .catch(error => props.enqueueSnackbar(NotificationLevel.ERROR, getErrorMessage(error)));
    props.onCloseRequest();
  };

  const type = props.importType === DrawerType.IMPORT_REPORT_TO_FOLDER ? 'reports' : 'workspaces';

  return (
    <Dialog open={props.open} onBackdropClick={props.onBackdropClick}>
      <DialogTitle>{props.headerText}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          One or more imported {type} have conflicting names. Would you want to overwrite or rename?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <RoundedButton variant='contained' color='primary' onClick={finishImport(true)}>
          Overwrite
        </RoundedButton>
        <RoundedButton variant='contained' color='primary' onClick={finishImport(false)}>
          Rename
        </RoundedButton>
        <RoundedButton variant='outlined' color='primary' onClick={props.onCloseRequest}>
          Cancel
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

export default connector(ImportConflictModal);
