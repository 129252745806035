import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import BulletGraph from 'react-bullet-graph-react16';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { DataConverters } from '../shared/dataConverters';

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  align-content: center;
  grid-gap: 20px;

  .Graph {
    & > div {
      &:nth-of-type(5) {
        background-color: #fff !important;
        opacity: 1 !important;
      }
      &:nth-of-type(6) {
        opacity: 1 !important;
      }
    }
    & p {
      margin-top: 2px;
    }
  }
`;

const Legend = styled.div`
  justify-self: flex-end;
  display: grid;
  grid-gap: 8px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;

  > span {
    width: 14px;
    height: 10px;
    margin-right: 8px;
    border: 1px solid black;
  }
`;

const BulletGraphViz: FC<ReportProps> = props => {
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.BULLET_GRAPH(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const bulletValues = reportConvertedData.rows[0];

  return (
    <Wrapper>
      <Legend>
        <LegendItem>
          <span style={{ background: 'white' }} />
          {bulletValues.metric}
        </LegendItem>
        <LegendItem>
          <span style={{ background: 'black' }} />
          {bulletValues.bench}
        </LegendItem>
      </Legend>
      <BulletGraph
        title=''
        textLabel=''
        scaleMin={bulletValues.scaleMin}
        scaleMax={bulletValues.scaleMax}
        performanceVal={bulletValues.performanceVal} // line
        symbolMarker={bulletValues.symbolMarker} // thick
        badVal={bulletValues.badVal} // left max
        satisfactoryVal={bulletValues.satisfactoryVal} // middle max
        height={props.width / 20}
        width={props.width}
        badColor={'#30b0ff'} // left
        satisfactoryColor={'#0072c3'} // middle
        goodColor={'#003d73'} // right
        isActiveColor={false}
        opacity={1}
      />
    </Wrapper>
  );
};

export default BulletGraphViz;
