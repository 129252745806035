import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { GrouperProps } from './GrouperModal';
import SettingsRadio from './settings-panel/SettingsRadio';

interface PortfolioHierarchyGrouperDataType {
  displayFunds: boolean;
}

const PorfolioHierarchyGrouperModal: FC<
  GrouperProps<PortfolioHierarchyGrouperDataType>
> = props => {
  const [displayFunds, setDisplayFunds] = useState(props.initialValue?.displayFunds);

  return (
    <Dialog open={true} onClose={props.onCancelClick}>
      <DialogTitle>Editor: Portfolio Hierarchy Grouper</DialogTitle>

      <DialogContent>
        <div
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flexStart',
          }}
        >
          <Typography>Display Funds</Typography>
          <RadioGroup
            value={displayFunds ? 'true' : 'false'}
            onChange={e => {
              setDisplayFunds(e.target.value === 'true' ? true : false);
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <SettingsRadio id='true' label='Yes' />
              <SettingsRadio id='false' label='No' />
            </div>
          </RadioGroup>
        </div>
      </DialogContent>
      {
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            onClick={() => props.onApplyClick({ displayFunds: displayFunds })}
          >
            Apply
          </Button>
          <Button onClick={() => props.onCancelClick()} variant='outlined'>
            Cancel
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
};

export default PorfolioHierarchyGrouperModal;
