import { debounce } from '@material-ui/core';
import { editor } from 'monaco-editor';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Debounced } from '../shared/dataTypes';
import { ValidationStatus } from '../shared/validation/model';
import { EditorLanguage } from './editorLanguage';
import { useLanguageServices } from './services/useLanguageServices';
import { EditorModel } from './useEditorModel';

export interface LanguageValidationProps {
  editorModel: EditorModel;
  language: EditorLanguage;
  skipValidation?: ((input: string) => boolean) | null;
  delay?: number;
}

export interface LanguageValidationApi {
  status: ValidationStatus;
  errors: editor.IMarkerData[];
  validate: (input: string | null) => void;
  onChange: (input?: string | null) => void;
  resetValidation: () => void;
  clearDebounce: () => void;
}

export const LANGUAGES_WITH_VALIDATION = [EditorLanguage.ASCRIPT, EditorLanguage.FOLIA];

const DEFAULT_DEBOUNCE_DELAY = 250; // ms
const useLanguageValidation = ({
  editorModel,
  language,
  skipValidation = null,
  delay = DEFAULT_DEBOUNCE_DELAY,
}: LanguageValidationProps): LanguageValidationApi => {
  const { [language]: languageService } = useLanguageServices();
  const [status, setStatus] = useState(ValidationStatus.PRISTINE);
  const [errors, setErrors] = useState<editor.IMarkerData[]>([]);

  const isInitialized = editorModel.isInitialized();

  useEffect(() => {
    if (isInitialized) {
      editorModel.api.setMarkers(language, errors);
    }
  }, [isInitialized, editorModel, language, errors]);

  const [debouncedValidate, setDebouncedValidate] = useState<Debounced<
    (input: string | null) => void
  > | null>(null);

  const clearDebounce = useCallback(() => {
    debouncedValidate?.clear();
  }, [debouncedValidate]);

  const resetValidation = useCallback(() => {
    if (status !== ValidationStatus.PRISTINE) {
      setStatus(ValidationStatus.PRISTINE);
      setErrors([]);
    }

    clearDebounce();
  }, [status, clearDebounce]);

  const validate = useCallback(
    (input: string | null) => {
      if (
        !LANGUAGES_WITH_VALIDATION.includes(language) ||
        !isInitialized ||
        input === null ||
        skipValidation?.(input)
      ) {
        return;
      }

      if (input === '') {
        setStatus(ValidationStatus.PRISTINE);
        setErrors([]);
        return;
      }

      setStatus(ValidationStatus.IN_PROGRESS);
      setErrors([]);

      const errors = languageService.validate(input);
      setStatus(errors.length === 0 ? ValidationStatus.VALID : ValidationStatus.INVALID);
      setErrors(errors);
    },
    [isInitialized, language, skipValidation, languageService],
  );

  useEffect(() => {
    setDebouncedValidate(() =>
      debounce((input: string | null) => {
        validate(input);
      }, delay),
    );
  }, [validate, delay]);

  const onChange = useCallback(
    (input: string | null = null) => {
      // Blank editor — reset state
      if (!input) {
        setStatus(ValidationStatus.PRISTINE);
        setErrors([]);
        clearDebounce();
        return;
      }

      // Simple text input — known to be valid
      if (skipValidation?.(input)) {
        setStatus(ValidationStatus.VALID);
        setErrors([]);
        clearDebounce();
        return;
      }

      // All other input — queue validation
      if (status !== ValidationStatus.WAITING) {
        setStatus(ValidationStatus.WAITING);
      }

      debouncedValidate?.(input);
    },
    [skipValidation, status, debouncedValidate, clearDebounce],
  );

  return useMemo(
    () => ({
      validate,
      onChange,
      resetValidation,
      clearDebounce,
      status,
      errors,
    }),
    [validate, onChange, resetValidation, clearDebounce, status, errors],
  );
};

export default useLanguageValidation;
