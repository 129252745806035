import { Chip } from '@material-ui/core';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import { Sandbox } from '../../shared/dataTypes';
import { lookupLatestPendingRequest } from '../../shared/utils';
import SandboxChip from '../sandboxes/SandboxChip';

interface BaseProps {
  detailList: boolean;
  drillThrough: boolean;
  sandbox: Sandbox;
  sequenceId: number;
}

const mapStateToProps = (state: AppState, props: BaseProps) => ({
  pendingRequests: state.report?.reportData?.[props.sequenceId]?.pendingRequests,
  dateContext: state.report.reportDefinition?.[props.sequenceId]?.dateContext,
  reportStatus: state.report.reportData?.[props.sequenceId]?.reportStatus,
  sandboxes: state.user.userInfo.userPreferences?.sandboxes,
  disconnected: state.report.disconnected,
});

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const StatusTags: FC<Props> = props => {
  const reportStatus = props.disconnected ? 'Disconnected' : props.reportStatus || '';
  const pendingRequestStatus = lookupLatestPendingRequest(props.pendingRequests);
  return (
    <div
      style={{
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'flex-start',
        marginLeft: '-.25rem',
        width: 'max-content',
      }}
    >
      {props.sandbox?.path && (
        <SandboxChip
          sandbox={props.sandbox}
          action={!props.detailList && !props.drillThrough ? 'menu' : null}
          sequenceId={props.sequenceId}
          dateContext={props.dateContext}
        />
      )}
      {pendingRequestStatus && (
        <Chip
          label={`${pendingRequestStatus}...`}
          style={{
            backgroundColor: '#ffd6e8',
            color: '#9f1853',
            padding: '0 10px',
            height: '1.5rem',
          }}
        />
      )}
      {reportStatus && (
        <Chip
          label={reportStatus}
          style={{ backgroundColor: '#ffd7d9', padding: '0 10px', height: '1.5rem' }}
        />
      )}
    </div>
  );
};

export default connector(StatusTags);
