import { AppState } from '../configureStore';
import { ContentPage } from './contentPage';

export const getActivePage = (state: AppState) => state.ui.activePage;

export const isContentReady = (state: AppState) => state.user.loggedIn && state.user.hasUserInfo;

export const isHomeOpen = (state: AppState) => getActivePage(state) === ContentPage.HOME;
export const isWorkspaceOpen = (state: AppState) => getActivePage(state) === ContentPage.WORKSPACE;
export const isDesignerOpen = (state: AppState) => getActivePage(state) === ContentPage.DESIGNER;
export const isAdminOpen = (state: AppState) => getActivePage(state) === ContentPage.ADMIN;
