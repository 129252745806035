import { CustomGrouping } from '../../model/custom-grouping/customGrouping';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import { regenerateIfAuto } from '../ActionCreators';
import { AppThunk } from '../configureStore';
import { setReportDefinition } from '../ReportActionCreators';
import { isDesignerOpen } from '../ui/selectors';

export const removeCustomGroupingFromReports = (grouping: CustomGrouping | null): AppThunk => (
  dispatch,
  getState,
) => {
  if (isDesignerOpen(getState())) {
    const def = getState().report.reportDefinition[DESIGNER_SEQUENCE_ID];
    dispatch(
      setReportDefinition(DESIGNER_SEQUENCE_ID, {
        ...def,
        verticalChars: def.verticalChars.filter(
          layerdef => layerdef.customGrouping?.id !== grouping?.id,
        ),
        horizontalChars: def.horizontalChars.filter(
          layerdef => layerdef.customGrouping?.id !== grouping?.id,
        ),
      }),
    );
    dispatch(regenerateIfAuto());
  }
};
