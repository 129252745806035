import { Switch, Typography } from '@material-ui/core';
import { DrawerHeader } from 'algo-react-dataviz';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { publishDateContexts } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { ContextPermissions } from '../../shared/dataTypes';
import { getDateContextsArray } from '../../shared/utils';
import './manage-contexts-drawer.scss';
import ResizableDrawerWrapper from './ResizableDrawerWrapper';

interface BaseProps {
  drawerOpen: boolean;
  contextPermissions: ContextPermissions;
  closeDrawer: () => void;
}
const mapStateToProps = (state: AppState) => ({
  dateContexts: state?.user?.userInfo?.serverConfigs?.dateContexts,
});

const mapDispatchToProps = { publishDateContexts };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const ManageContextsDrawers: FC<Props> = props => {
  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: props.drawerOpen,
        onClose: props.closeDrawer,
        className: 'right-drawer manage-date-contexts-drawer',
      }}
      id='ManageContextsDrawer'
    >
      <div className='fixed-panel-wrapper' style={{ borderRadius: '4px 4px 0px 0px' }}>
        <div className='fixed-panel'>
          <DrawerHeader headerText='Manage Contexts' onXClick={props.closeDrawer} />

          <div className='fixed-scrollable-region'>
            {!props.dateContexts ? (
              <p>No date contexts published</p>
            ) : (
              <table style={{ width: '100%' }}>
                <thead>
                  <tr className='sticky-th'>
                    <th>
                      <Typography>Report Date</Typography>
                    </th>
                    <th>
                      <Typography>Run</Typography>
                    </th>
                    <th>
                      <Typography>Published</Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getDateContextsArray(props.dateContexts).map((d, i) => (
                    <tr key={i}>
                      <td style={{ verticalAlign: 'middle' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography>{d.date}</Typography>
                        </div>
                      </td>
                      <td>{d.id && <Typography>{d.id}</Typography>}</td>
                      <td key={i} style={{ textAlign: 'center' }}>
                        <Switch
                          color='primary'
                          checked={d.published}
                          disabled={!props.contextPermissions?.publish}
                          onChange={() => props.publishDateContexts(d.date, d.id, !d.published)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </ResizableDrawerWrapper>
  );
};

export default connector(ManageContextsDrawers);
