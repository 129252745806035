import TreeList, { Column } from 'devextreme-react/tree-list';
import { RowClickEvent } from 'devextreme/ui/tree_list';
import { FC, useCallback } from 'react';
import { CustomGrouping } from '../../../model/custom-grouping/customGrouping';
import useCustomGroupingState from '../context/useCustomGroupingState';
import { GroupingTreeRowDatum } from './dx-model';
import GroupingTreeRow from './GroupingTreeRow';

interface Props {
  grouping: CustomGrouping;
}

const shouldMultiselect: (event: MouseEvent) => boolean = (() => {
  const platform = navigator.platform ?? '';

  if (/^win/i.test(platform)) {
    return event => event.ctrlKey;
  } else if (/^mac/i.test(platform)) {
    return event => event.metaKey;
  } else {
    return event => event.ctrlKey || event.metaKey;
  }
})();

const GroupingTreeView: FC<Props> = ({ grouping }) => {
  const {
    selectedIds,
    setSelectedId,
    toggleId,
    intendedRenameNodeId,
    expandedIds,
    setExpandedIds,
  } = useCustomGroupingState();

  const onRowClick = useCallback(
    ({ key: id, event }: RowClickEvent<GroupingTreeRowDatum, string>) => {
      // Don't toggle selection if this is an expand/collapse or rename click
      if (
        intendedRenameNodeId !== null ||
        [
          'dx-treelist-collapsed',
          'dx-treelist-expanded',
          'grouping-tree-row-rename-field-input',
        ].some(className => event.target.parentElement.classList.contains(className))
      ) {
        return;
      }

      if (shouldMultiselect(event)) {
        toggleId(id);
      } else {
        setSelectedId(id);
      }
    },
    [setSelectedId, toggleId, intendedRenameNodeId],
  );

  return (
    <TreeList<GroupingTreeRowDatum, string>
      className={`grouping-tree ${intendedRenameNodeId !== null ? 'no-hover' : ''}`}
      height='100%'
      id='groupingTreeList'
      // We lie to DX because its types are wrong.
      dataSource={[grouping.rootNode] as GroupingTreeRowDatum[]}
      dataStructure='tree'
      keyExpr='id'
      itemsExpr='children'
      autoExpandAll
      showColumnHeaders={false}
      sorting={{ mode: 'none' }}
      selection={{
        mode: 'multiple',
        allowSelectAll: false,
      }}
      onExpandedRowKeysChange={setExpandedIds}
      expandedRowKeys={expandedIds}
      selectedRowKeys={selectedIds}
      {...{ onRowClick }}
    >
      <Column dataField='name' cellComponent={GroupingTreeRow} />
    </TreeList>
  );
};

export default GroupingTreeView;
