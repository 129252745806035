import { DatePickerDay, DatePickerMonth } from 'algo-react-dataviz';
import moment, { Moment } from 'moment';
import { FC } from 'react';
import { CalendarDay, DayPickerSingleDateController } from 'react-dates';

interface Props {
  dates: string[];
  onDatesChange: (dates: string[]) => void;
  initDate?: string;
  runExists: (m: Moment) => boolean;
}

const MultiDatePicker: FC<Props> = props => {
  const onDatesChange = (m: Moment) =>
    props.onDatesChange(
      props.dates.find(pd => moment(pd).isSame(m, 'day'))
        ? props.dates.filter(d => !moment(d).isSame(m, 'day'))
        : props.dates.concat(m.format('YYYY-MM-DD')),
    );

  return (
    <DayPickerSingleDateController
      date={null}
      onDateChange={onDatesChange}
      focused
      onFocusChange={() => {}}
      initialVisibleMonth={() => moment(props.initDate)}
      renderDayContents={m => <DatePickerDay moment={m} runExists={props.runExists(m)} />}
      renderCalendarDay={p => {
        return (
          <CalendarDay
            {...p}
            daySize={24}
            modifiers={
              props.dates?.some(d => moment(d).isSame(p.day, 'day'))
                ? new Set(Array.from(p.modifiers || []).concat('selected'))
                : p.modifiers
            }
          />
        );
      }}
      hideKeyboardShortcutsPanel
      renderMonthElement={p => (
        <DatePickerMonth
          {...p}
          currentDate={props.dates?.[0]?.length === 10 ? props.dates?.[0] : props.initDate}
        />
      )}
    />
  );
};

export default MultiDatePicker;
