import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { UnfoldLess, UnfoldMore } from '@material-ui/icons';
import { FC, useState, ReactNode } from 'react';

interface Props {
  summary?: string;
  summaryChildren?: ReactNode;
  defaultExpanded?: boolean;
  expandedClassName?: string;
  setExpanded?: (expanded: boolean) => void;
  expanded?: boolean;
}

const UnfoldAccordion: FC<Props> = props => {
  const [localExpanded, setLocalExpanded] = useState(props.defaultExpanded || false);
  const expanded = props.expanded ?? localExpanded;
  return (
    <Accordion
      expanded={expanded}
      onChange={() => (props.setExpanded ?? setLocalExpanded)(!expanded)}
      className={(expanded && props.expandedClassName) || ''}
    >
      <AccordionSummary expandIcon={expanded ? <UnfoldLess /> : <UnfoldMore />}>
        {props.summaryChildren ?? (
          <Typography variant={expanded ? 'h2' : 'body1'}>{props.summary}</Typography>
        )}
      </AccordionSummary>

      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};

export default UnfoldAccordion;
