import { CreateNewFolderRounded as CreateNewFolderIcon } from '@material-ui/icons';
import { FC } from 'react';
import QuickAction from '../../quick-action-group/QuickAction';

// TODO: keep this w/ styling & generalize
const EmptyView: FC = () => (
  <div className='grouping-tree-empty'>
    <QuickAction label='Create a new grouping tree' icon={CreateNewFolderIcon} onClick={() => {}} />
  </div>
);

export default EmptyView;
