export enum ApiRequestStatus {
  PRISTINE = 'PRISTINE',
  COMPLETE = 'COMPLETE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

export interface ApiData {
  status: ApiRequestStatus;
  fetchTime: number | null; // ms
  error: Error | null;
}

export const createDefaultApiData = (): ApiData => ({
  status: ApiRequestStatus.PRISTINE,
  fetchTime: null,
  error: null,
});

export const createRequestApiData = (): ApiData => ({
  status: ApiRequestStatus.LOADING,
  fetchTime: null,
  error: null,
});

export const createSuccessApiData = (fetchTime: number): ApiData => ({
  status: ApiRequestStatus.COMPLETE,
  fetchTime,
  error: null,
});

export const createFailureApiData = (fetchTime: number, error: Error): ApiData => ({
  status: ApiRequestStatus.ERROR,
  fetchTime,
  error,
});
