import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { openFolderDrawer, updateDefaultWorkspace } from '../../redux/ActionCreators';
import { useAppSelector } from '../../redux/configureStore';
import { DrawerType } from '../../shared/constants';
import ControlledTextInput, { ControlledTextInputProps } from './ControlledTextInput';

interface Props extends Pick<ControlledTextInputProps, 'disabled' | 'variant' | 'size'> {
  hideLabel?: boolean;
}

const LABEL = 'Default Workspace';
const PLACEHOLDER = 'None selected';

const DefaultWorkspaceSelect: FC<Props> = props => {
  const dispatch = useDispatch();
  const defaultWorkspace = useAppSelector(
    state => state.user.userInfo.userPreferences?.defaultWorkspace ?? null,
  );

  return (
    <ControlledTextInput
      label={!props.hideLabel && LABEL}
      value={defaultWorkspace === null ? PLACEHOLDER : decodeURIComponent(defaultWorkspace)}
      onClick={() => dispatch(openFolderDrawer(DrawerType.SELECT_DEFAULT_WORKSPACE))}
      onAdornmentClick={event => {
        event.stopPropagation();
        dispatch(updateDefaultWorkspace(null));
      }}
      disabled={props.disabled}
      variant={props.variant}
      size={props.size}
    />
  );
};

export default DefaultWorkspaceSelect;
