import { TableStateProvider } from 'algo-react-dataviz';
import { FC } from 'react';
import MetadataTable from './metadata-table/MetadataTable';
import './metadata.scss';
import MetadataHeader from './MetadataHeader';

const Metadata: FC = () => (
  <TableStateProvider>
    <div className='page-content page-content-metadata'>
      <MetadataHeader />

      <div className='table-container'>
        <MetadataTable />
      </div>
    </div>
  </TableStateProvider>
);

export default Metadata;
