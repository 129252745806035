import { FormGroup } from '@material-ui/core';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { requestSetComponentPermission } from '../../../redux/adminActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import ComponentPermission from './ComponentPermission';

const ComponentPermissions: FC = () => {
  const dispatch = useDispatch();
  const permissions = useAppSelector(
    state => state.admin.componentPermissions?.[state.admin.selectedGroup],
  );
  return (
    <FormGroup className='component-permissions'>
      <ComponentPermission
        label='Administrative Settings'
        checked={permissions?.allowAdmin}
        onChange={checked => dispatch(requestSetComponentPermission('allowAdmin', checked))}
      />
      <ComponentPermission
        label='Metadata View'
        checked={permissions?.allowViewMetadata}
        onChange={checked => dispatch(requestSetComponentPermission('allowViewMetadata', checked))}
      />
      <ComponentPermission
        label='Metadata Edit'
        checked={permissions?.allowMetadata}
        onChange={checked => dispatch(requestSetComponentPermission('allowMetadata', checked))}
      />
      <ComponentPermission
        label='aScript Edit'
        checked={permissions?.allowAscript}
        onChange={checked => dispatch(requestSetComponentPermission('allowAscript', checked))}
      />
      <ComponentPermission
        label='Manage Ad Hoc'
        checked={permissions?.allowManageAdhoc}
        onChange={checked => dispatch(requestSetComponentPermission('allowManageAdhoc', checked))}
      />
      <ComponentPermission
        label='Legacy Reports'
        checked={permissions?.allowLegacyReports}
        onChange={checked => dispatch(requestSetComponentPermission('allowLegacyReports', checked))}
      />
      <ComponentPermission
        label='Create Folder Under Root'
        checked={permissions?.allowCreateFolderUnderRoot}
        onChange={checked =>
          dispatch(requestSetComponentPermission('allowCreateFolderUnderRoot', checked))
        }
      />
      <ComponentPermission
        label='Remote Console'
        checked={permissions?.allowRemoteConsole}
        onChange={checked => dispatch(requestSetComponentPermission('allowRemoteConsole', checked))}
      />
      <ComponentPermission
        label='Characteristic Edit'
        checked={permissions?.allowModifyEditableCharacterstics}
        onChange={checked =>
          dispatch(requestSetComponentPermission('allowModifyEditableCharacterstics', checked))
        }
      />
    </FormGroup>
  );
};

export default ComponentPermissions;
