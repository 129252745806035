import { RoundedButton } from 'algo-react-dataviz';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeReportEntitiesDrawer, generateIfAuto } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import {
  regenerateEntitiesDrawerReport,
  removeCurrentReportEntities,
  removeSelectedReportEntities,
  setCurrentReportEntities,
  setSelectedReportEntities,
} from '../../../redux/ReportActionCreators';
import { DESIGNER_SEQUENCE_ID } from '../../../shared/constants';
import { SelectedEntities } from '../../../shared/dataTypes';
import EntitySelectionDrawer from './EntitySelectionDrawer';

interface DesignerProps {
  drawerOpen?: boolean;
  closeDrawer?: () => void;
}

interface WorkspaceReportProps {
  drawerOpen?: never;
  closeDrawer?: never;
}

const mapStateToProps = (state: AppState, props: DesignerProps | WorkspaceReportProps) => {
  const sequenceId = props.closeDrawer
    ? DESIGNER_SEQUENCE_ID
    : state.drawers.reportEntitiesDrawer.sequenceId;

  return {
    open: props.drawerOpen || state.drawers.reportEntitiesDrawer.open,
    dateContext: state.report.reportDefinition[sequenceId]?.dateContext,
    initialSelectedEntities:
      state.report.reportDefinition[sequenceId]?.selectedEntities ||
      state.workspace.data.selectedEntities,
  };
};

const mapDispatchToProps = {
  closeReportEntitiesDrawer,
  setSelectedReportEntities,
  setCurrentReportEntities,
  regenerateEntitiesDrawerReport,
  removeSelectedReportEntities,
  removeCurrentReportEntities,
  generateIfAuto,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & (DesignerProps | WorkspaceReportProps);

const ReportEntitySelectionDrawer: FC<Props> = props => {
  const [closeDrawer, setEntities, regenerate, remove] = props.closeDrawer
    ? [
        // Designer report
        props.closeDrawer,
        (entities: SelectedEntities) =>
          props.setSelectedReportEntities(DESIGNER_SEQUENCE_ID, entities),
        () => props.generateIfAuto(),
        () => props.removeSelectedReportEntities(DESIGNER_SEQUENCE_ID),
      ]
    : [
        // Workspace report
        props.closeReportEntitiesDrawer,
        props.setCurrentReportEntities,
        props.regenerateEntitiesDrawerReport,
        props.removeCurrentReportEntities,
      ];

  return (
    <EntitySelectionDrawer
      open={props.open}
      close={closeDrawer}
      initialSelectedEntities={props.initialSelectedEntities}
      dateContext={props.dateContext}
      onApplyClick={selectedEntities => {
        setEntities(selectedEntities);
        regenerate();
        closeDrawer();
      }}
      extraButton={
        <RoundedButton
          color='primary'
          onClick={() => {
            remove();
            regenerate();
            closeDrawer();
          }}
        >
          Restore Default
        </RoundedButton>
      }
    />
  );
};

export default connector(ReportEntitySelectionDrawer);
