import {
  Cell,
  ColumnWidth,
  Modifier,
  ReportPrecisionUpdate,
  ReportRequestStatus,
  ReportUpdateHighlightMode,
  RowField,
} from 'algo-react-dataviz';
import axios, { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { baseUrl } from '../components/shared/environment';
import { createDesignerWorkspacePayload } from '../model/designerWorkspacePayload';
import {
  DESIGNER_SEQUENCE_ID,
  INITIAL_PENDING_STATUS,
  NotificationLevel,
} from '../shared/constants';
import {
  AdHocCustomGroupingDefinition,
  AdhocReportRequest,
  CancelReportGeneration,
  ChangeReportSequenceIdRequest,
  DateContext,
  EditDetail,
  EditRequest,
  GenerateReportRequest,
  LayerDefinition,
  Node,
  ParentToChildRelationship,
  RegenerateReportRequest,
  ReportDefinition,
  ReportDefinitions,
  ReportRawData,
  ReportReleaseRequest,
  ReportRequest,
  ReportRequestBase,
  SelectedEntities,
  SelectedPortfolios,
  Sort,
  WorkspaceData,
} from '../shared/dataTypes';
import {
  applyReportPrecisionUpdate,
  convertDefToDto,
  getCommonRequestProperties,
  getElementsNChildren,
  getNumPendingRequests,
  getReportWorkspacePayload,
  isReservedSequenceId,
  nextRequestId,
  nextSequenceId,
  resolveDefaultReportDefinition,
} from '../shared/utils';
import {
  addAdHocReportToWorkspace,
  enqueueSnackbar,
  generateIfAuto,
  getErrorMessage,
  handleRequestTimerSetup,
  setDesignerWorkspacePayload,
  setIsAutoGenerate,
  setOriginalDetachedReport,
  setReportDesignerBenchmarks,
  setReportDesignerScenarioConfig,
  setReportDesignerSettings,
} from './ActionCreators';
import * as ActionTypes from './ActionTypes';
import { AppState, AppThunk, sendNextQueuedMessage, sendStompMessage } from './configureStore';
import { creatingNewReport, DesignerSource } from './designer/panel/designerSource';
import { DesignerPanelState } from './designer/panel/state';
import { changeSequenceId } from './progress/actionCreators';
import { closeDesigner, openDesignerPage } from './ui/actionCreators';
import { ContentPage } from './ui/contentPage';
import { isDesignerOpen } from './ui/selectors';
import {
  addReportToWorkspace,
  deleteReportData,
  getSelectedElements,
  getWorkspacePayload,
  setWorkspaceDefinitionSandbox,
  setWorkspaceReportHasChanges,
  setWorkspaceReportPath,
} from './WorkspaceActionCreators';

export const saveReport = (
  path: string,
  sequenceId: number,
  method: 'post' | 'put',
  dontAlertSuccess?: boolean,
): AppThunk => async (dispatch, getState) => {
  const reportDefinition: ReportDefinition = getState().report.reportDefinition[sequenceId];

  let response: AxiosResponse<ReportDefinition>;
  try {
    response = await axios({
      method,
      url: `${baseUrl}api/reportDefinition`,
      data: {
        ...convertDefToDto(reportDefinition),
        path,
      },
    });

    // Only clear workspace changes for this report when reportdesigner is closed
    isDesignerOpen(getState()) || dispatch(setWorkspaceReportHasChanges(sequenceId, false));

    dontAlertSuccess ||
      dispatch(
        enqueueSnackbar(
          NotificationLevel.SUCCESS,
          `Report ${path
            .split('/')
            .map(p => decodeURIComponent(p))
            .join('/')} saved`,
        ),
      );
    dispatch(setReportDefinition(sequenceId, response.data));

    // only update WorkspacePath when reportdesigner is closed
    isDesignerOpen(getState()) || dispatch(setWorkspaceReportPath(sequenceId, path));
  } catch (error) {
    dispatch(
      enqueueSnackbar(
        NotificationLevel.ERROR,
        error.response?.status === 409
          ? `An item with that name already exists: ${decodeURIComponent(path)}`
          : `Unable to save report ${reportDefinition.reportTitle}: ${getErrorMessage(error)}`,
      ),
    );
  }
};

export const sendWebSocketMessage = (
  message:
    | ReportRequest
    | EditRequest
    | ReportReleaseRequest
    | ChangeReportSequenceIdRequest
    | CancelReportGeneration,
  numPending?: number,
  errorHandler?: (error: Error) => void,
): AppThunk => async (dispatch, getState) => {
  sendStompMessage(
    message,
    getState().user.tk || localStorage.getItem('id_token'),
    numPending,
  ).catch((error: Error) => errorHandler && errorHandler(error));
};

export const updateAndSendSortDefinition = (
  sequenceId: number,
  sortDefinition: Sort[],
): AppThunk => (dispatch, getState) => {
  dispatch(setReportDefinitionSort(sequenceId, sortDefinition));
  sendStompMessage(
    {
      requestType: 'modifySort',
      sequenceId,
      ...getCommonRequestProperties(getState()),
      reportDefinition: { ...getState().report.reportDefinition[sequenceId], sort: sortDefinition },
    },
    getState().user.tk || localStorage.getItem('id_token'),
  );
};

export const updateAndSendPrecision = (
  sequenceId: number,
  update: ReportPrecisionUpdate,
): AppThunk => (dispatch, getState) => {
  dispatch(setReportDefinitionPrecision(sequenceId, update));
  sendStompMessage(
    {
      requestType: 'modifyPrecision',
      sequenceId,
      ...getCommonRequestProperties(getState()),
      reportDefinition: applyReportPrecisionUpdate(
        getState().report.reportDefinition[sequenceId],
        update,
      ),
    },
    getState().user.tk || localStorage.getItem('id_token'),
  );
};

export const triggerChildUpdate = (
  sequenceId: number,
  liveOnly?: boolean,
  newParentSelectedElements?: string[],
) => (dispatch, getState: () => AppState) => {
  const parentToChildRelationship: ParentToChildRelationship = getState().workspace
    .parentToChildRelationship;

  if (parentToChildRelationship) {
    const childrenToUpdate: number[] = parentToChildRelationship[sequenceId];

    if (childrenToUpdate && childrenToUpdate.length > 0) {
      const parentSandbox = getWorkspacePayload(dispatch)(sequenceId, getState().workspace.data)
        ?.sandbox;
      childrenToUpdate.forEach(async childSequenceId => {
        const workspacePayload = getWorkspacePayload(dispatch)(
          childSequenceId,
          getState().workspace.data,
        );
        if (
          parentSandbox?.path !== workspacePayload.sandbox?.path &&
          parentSandbox?.prvSandbox !== workspacePayload.sandbox?.prvSandbox
        ) {
          dispatch(setWorkspaceDefinitionSandbox(childSequenceId, parentSandbox?.path));
        }
        if (!parentSandbox?.path && workspacePayload.sandbox?.path) {
          dispatch(setWorkspaceDefinitionSandbox(childSequenceId, null));
        }

        if (liveOnly && !workspacePayload.live) {
          // this is a request to trigger child update only for live child reports
          // this report is not a live child report
          // skipping
          return;
        }

        const requestId = `seq-${childSequenceId}-req-${nextRequestId()}`;

        const parentFailed = getState().report.reportData?.[workspacePayload.parentSequenceId]?.raw
          ?.errMessage;
        if (parentFailed) {
          dispatch(removePendingOperation(childSequenceId, [INITIAL_PENDING_STATUS]));
          dispatch(
            reportFailed(childSequenceId, 'Unable to generate child report due to parent failure.'),
          );
          return;
        }

        const parentSelectedElements = liveOnly
          ? newParentSelectedElements
          : workspacePayload.live
          ? getSelectedElements(dispatch)(sequenceId, getState().workspace.data)
          : workspacePayload.parentSelectedElements;

        let reportDefinition: ReportDefinition = getState().report.reportDefinition[
          childSequenceId
        ];

        const isLegacyAdhocDrillThrough =
          workspacePayload.legacyReport &&
          !workspacePayload.reportPath &&
          !workspacePayload.detailList;

        if (!reportDefinition && !workspacePayload.detailList) {
          if (isLegacyAdhocDrillThrough) {
            dispatch(
              reportFailed(
                childSequenceId,
                'Legacy ad hoc drill-through reports are not supported.',
              ),
            );
            return;
          }
          try {
            const response: AxiosResponse<ReportDefinition | string> = await axios.get<
              ReportDefinition | string
            >(`${baseUrl}api/reportDefinition`, {
              params: {
                path: workspacePayload.reportPath,
                isLegacyReport: workspacePayload.legacyReport,
              },
            });

            if (response.data === '') {
              dispatch(
                enqueueSnackbar(
                  NotificationLevel.WARN,
                  `Warning: Unable to retrieve report definition for: ${workspacePayload.reportPath}`,
                ),
              );
              return;
            }

            reportDefinition = response.data as ReportDefinition;
          } catch (error) {
            dispatch(
              enqueueSnackbar(
                NotificationLevel.WARN,
                `Warning: Error trying to retrieve report definition for ${
                  workspacePayload.reportPath
                }: ${getErrorMessage(error)}`,
              ),
            );
            return;
          }
          dispatch(setReportDefinition(childSequenceId, reportDefinition));
        }
        // send a message to trigger report regeneration due to parent update
        // TODO the message format needs to be updated (right now ReportDetails is expected)
        if (!isLegacyAdhocDrillThrough) {
          // dispatch a report refreshing message
          dispatch(
            addReportPendingRequest(
              childSequenceId,
              requestId,
              ReportRequestStatus.REFRESHING,
              ReportUpdateHighlightMode.REPORT_CHANGE,
            ),
          );

          sendStompMessage(
            {
              requestType: 'generate',
              ...workspacePayload,
              sandbox: parentSandbox,
              parentSelectedElements,
              requestId,
              ...getCommonRequestProperties(getState()),
              reportDefinition,
            },
            getState().user.tk || localStorage.getItem('id_token'),
            getNumPendingRequests(getState().report.reportData),
          ).catch((error: Error) => {
            dispatch(reportFailed(childSequenceId, error.message));

            const descendants = getAllDescendants(childSequenceId, parentToChildRelationship, []);

            descendants.forEach(id => {
              dispatch(reportFailed(id, error.message));
            });
          });
        }
      });
    }
  }
};

// returns all children, grand children etc for the the report with sequenceId based on parentToChild relationship
const getAllDescendants = (id: number, parentToChild: ParentToChildRelationship, acc: number[]) => {
  const children: number[] = parentToChild[id];

  if (children) {
    acc = acc.concat(children);

    children.forEach(i => {
      if (parentToChild[i]) {
        acc = getAllDescendants(i, parentToChild, acc);
      }
    });
  }

  return acc;
};

export const addReportPendingRequest = (
  sequenceId: number,
  requestId: string,
  requestStatus: ReportRequestStatus,
  highlightMode: ReportUpdateHighlightMode,
  rowIndex?: number,
  colIndex?: number,
) => ({
  type: ActionTypes.UPDATE_REPORT_STATUS,
  payload: {
    sequenceId,
    requestId,
    requestStatus,
    highlightMode,
    rowIndex,
    colIndex,
  },
});

export const reportLoading = (sequenceId: number): AnyAction => ({
  type: ActionTypes.REPORT_LOADING,
  payload: sequenceId,
});

export const reportFailed = (sequenceId: number, errMessage: string) =>
  updateReportData({
    sequenceId,
    errMessage,
    isLoading: false,
    name: null,
    type: null,
    data: null,
    date: null,
    headers: null,
  });

export const triggerReportRegen = (
  data: WorkspaceData,
  reportSettings,
  dispatch,
  predicateFunc: (seqId: number) => boolean = seqId => true,
) => {
  if (!data) {
    return;
  }
  Object.values(data.tabs).forEach(tab =>
    Object.values(tab.reports).forEach(report => {
      if (!report.parentSequenceId || report.parentSequenceId === DESIGNER_SEQUENCE_ID) {
        predicateFunc(report.sequenceId) &&
          dispatch(
            sendReportUpdateMessage(
              {
                ...report,
                requestId: `seq-${report.sequenceId}-req-${nextRequestId()}`,
                ...reportSettings,
              },
              ReportUpdateHighlightMode.CELL_CHANGE,
              ReportRequestStatus.UPDATING,
            ),
          );
      }
    }),
  );
};

export const regenerateReport = (sequenceId: number): AppThunk => dispatch =>
  dispatch(
    sendReportUpdateMessage(
      { sequenceId, requestId: `seq-${sequenceId}-req-${nextRequestId()}` },
      ReportUpdateHighlightMode.REPORT_CHANGE,
      ReportRequestStatus.REGENERATING,
    ),
  );

export const regeneratePortfolioDrawerReport = (): AppThunk => (dispatch, getState) =>
  dispatch(regenerateReport(getState().drawers.reportPortfolioDrawer.sequenceId));

export const regenerateEntitiesDrawerReport = (): AppThunk => (dispatch, getState) =>
  dispatch(regenerateReport(getState().drawers.reportEntitiesDrawer.sequenceId));

export const sendReportUpdateMessage = (
  request: AdhocReportRequest | GenerateReportRequest | RegenerateReportRequest,
  highlightMode: ReportUpdateHighlightMode,
  requestStatus: ReportRequestStatus,
  isMetadataRequest = false,
): AppThunk => async (dispatch, getState) => {
  const { requestId, sequenceId }: ReportRequestBase = request;
  const reportDefinition = getState().report.reportDefinition[sequenceId];
  const reportRequest: ReportRequest = {
    ...getReportWorkspacePayload(sequenceId, getState()),
    ...request,
    ...getCommonRequestProperties(getState(), isMetadataRequest),
    reportDefinition: reportDefinition.legacyReport
      ? reportDefinition
      : convertDefToDto(reportDefinition),
  };

  if (!isReservedSequenceId(sequenceId)) {
    const wp = getReportWorkspacePayload(sequenceId, getState());
    let parentSelectedElements;

    if (wp?.detailList || wp?.drillThrough) {
      if (wp.live) {
        // We need to find out the parent current selection
        const parentWP = getReportWorkspacePayload(wp.parentSequenceId, getState());
        parentSelectedElements = parentWP.selectedElements;
      } else {
        parentSelectedElements = wp.parentSelectedElements;
      }
    }
    reportRequest.parentSelectedElements = parentSelectedElements;
  }
  // register pending operation
  dispatch(addReportPendingRequest(sequenceId, requestId, requestStatus, highlightMode));

  dispatch(reportLoading(sequenceId));

  sendStompMessage(
    {
      ...reportRequest,
      requestType: requestStatus === ReportRequestStatus.REGENERATING ? 'regenerate' : 'generate',
    },
    getState().user.tk || localStorage.getItem('id_token'),
    getNumPendingRequests(getState().report.reportData),
  )
    .then(() => {
      // Setup a timeout handler
      handleRequestTimerSetup(
        dispatch,
        requestId,
        sequenceId,
        getState().user.userInfo.serverConfigs.commTimeout,
      );
    })
    .catch((error: Error) => {
      dispatch(reportFailed(sequenceId, error.message));
    });
};

export const applyCellEdit = (
  sequenceId: number,
  requestId: string,
  editDetail: EditDetail,
): AppThunk => (dispatch, getState) => {
  // send a message to trigger edit (will eventually cause the report to update)
  sendStompMessage(
    {
      requestType: 'edit',
      reportEdit: {
        requestId,
        sequenceId,
        editDetails: editDetail,
        ...getCommonRequestProperties(getState()),
      },
    },
    getState().user.tk || localStorage.getItem('id_token'),
    getNumPendingRequests(getState().report.reportData),
  )
    .then(() => {
      // register edit pending operation
      dispatch(
        addReportPendingRequest(
          sequenceId,
          requestId,
          ReportRequestStatus.PENDING,
          ReportUpdateHighlightMode.CELL_CHANGE,
          editDetail.rowIndex,
          editDetail.colIndex,
        ),
      );

      // dispatch action to update cell value with the edited one
      dispatch(updateCell(sequenceId, requestId, editDetail));
    })
    .catch((error: Error) => {
      console.error('Failed to apply edit. Reason: ' + error.message);
    });
};

export const cancelReportGeneration = (sequenceId: number): AppThunk => (dispatch, getState) => {
  const payload =
    sequenceId === DESIGNER_SEQUENCE_ID
      ? { sequenceId }
      : getWorkspacePayload(dispatch)(sequenceId, getState().workspace.data);

  sendStompMessage(
    {
      requestType: 'cancelReportGeneration',
      ...payload,
    },
    getState().user.tk || localStorage.getItem('id_token'),
    getNumPendingRequests(getState().report.reportData),
  );
};

const updateCell = (sequenceId: number, requestId: string, editDetail: EditDetail): AnyAction => ({
  type: ActionTypes.UPDATE_CELL,
  payload: { sequenceId, requestId, editDetail },
});

const removePendingOperation = (sequenceId: number, requestIds: string[]): AppThunk => (
  dispatch,
  getState,
) => {
  const reportData = getState().report.reportData[sequenceId];
  requestIds.forEach(requestId => {
    if (reportData?.pendingRequests?.[requestId]) {
      dispatch({
        type: ActionTypes.REMOVE_PENDING_OPERATION,
        payload: { sequenceId, requestId },
      });
    }
  });
  // Deliver the next queued up message if there is one.
  sendNextQueuedMessage(getState().user.tk || localStorage.getItem('id_token'));
};

export const updateReportData = (reportData: ReportRawData): AppThunk => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_REPORT_DATA, payload: reportData });

  // Don't update the report's children if the report is still loading.
  if (!reportData.isLoading) {
    dispatch(triggerChildUpdate(reportData.sequenceId));
  }
};

export const updateReportDataFromServer = (reportData: ReportRawData): AppThunk => (
  dispatch,
  getState,
) => {
  let sequenceId: number;
  if (reportData.sequenceId !== DESIGNER_SEQUENCE_ID) {
    // This is a response to a request made in the workspace, or for the metadata report
    // (sequenceId !== DESIGNER_SEQUENCE_ID). The report to be updated can be found using
    // the sequenceId from the payload.
    sequenceId = reportData.sequenceId;

    if (sequenceId === getState().reportDesigner.panelControl.sourceSequenceId)
      // In addition to being in the workspace, the report is currently being edited.
      // updateReportData needs to be called twice - once for the copy of the report that's in
      // the designer, which is done here, and once for the copy of the report that's in the
      // workspace, which is done below.
      dispatch(updateReportData({ ...reportData, sequenceId: DESIGNER_SEQUENCE_ID }));
  } else {
    // This is a response to a request made in the designer (sequenceId === DESIGNER_SEQUENCE_ID). The
    // report to be updated might still be in the designer or it might be in the workspace. It
    // can be found using requestIds[0] (if present).
    if (reportData.requestIds?.[0]) {
      sequenceId = Number(
        Object.keys(getState().report.reportData).find(s =>
          Object.keys(getState().report.reportData[s].pendingRequests || {}).includes(
            reportData.requestIds[0],
          ),
        ),
      );
    } else {
      // Note: there is no requestIds in a data response from a sort request (no correlating session context available)
      sequenceId = DESIGNER_SEQUENCE_ID;
    }
  }

  if (reportData?.preserveReportData) {
    if (reportData.errMessage) {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, reportData.errMessage));
    }
  } else {
    dispatch(updateReportData({ ...reportData, sequenceId }));
  }

  // Note: there is no requestIds in a data response from a sort request (no correlating session context available)
  if (reportData.requestIds?.[0]) {
    dispatch(removePendingOperation(sequenceId, reportData.requestIds));
  }
};

const movePendingRequests = (destinationSequenceId: number) =>
  ({
    type: ActionTypes.MOVE_PENDING_REQUESTS,
    payload: { destinationSequenceId },
  } as const);

export const updateReportDataFromDesigner = (sequenceId: number): AppThunk => (
  dispatch,
  getState,
) => {
  const reportData = getState().report.reportData[DESIGNER_SEQUENCE_ID];

  if (reportData) {
    dispatch(
      updateReportData({
        ...reportData.raw,
        sequenceId,
        isLoading: !!Object.keys(reportData?.pendingRequests || {}).length,
      }),
    );

    dispatch(movePendingRequests(sequenceId));
  } else {
    dispatch(regenerateReport(sequenceId));
  }
};

export const setReportableType = (reportableType: number | '') => ({
  type: ActionTypes.SET_REPORTABLE_TYPE,
  payload: {
    sequenceId: DESIGNER_SEQUENCE_ID,
    reportableType,
  },
});

export const setReportableTypeAndRegenerate = (
  reportableType: number | '',
): AppThunk => dispatch => {
  dispatch(setReportableType(reportableType));
  dispatch(generateIfAuto());
};

export const setReportDefinitionCharacteristics = (
  chars: { charId: number; modifier: Modifier }[],
  verticalChars: LayerDefinition[],
  horizontalChars: LayerDefinition[],
): AppThunk => (dispatch, getState) =>
  dispatch({
    type: ActionTypes.SET_REPORT_DEFINITION_CHARACTERISTICS,
    payload: {
      sequenceId: DESIGNER_SEQUENCE_ID,
      chars: chars.map(c => {
        const res = getState().report.reportDefinition[DESIGNER_SEQUENCE_ID].chars.find(
          char => c.charId === char.charId && c.modifier === char.modifier,
        );
        return res ? { ...c, nickname: res.nickname } : c;
      }),
      verticalChars,
      horizontalChars,
    },
  } as const);

export const toggleDesignerDateContextRun = (id: string, date?: string) => ({
  type: ActionTypes.TOGGLE_DESIGNER_DATE_CONTEXT_RUN,
  payload: { id, date },
});

export const setReportDefinitionDateContext = (sequenceId: number, dateContext: DateContext) => ({
  type: ActionTypes.SET_REPORT_DEFINITION_DATE_CONTEXT,
  payload: { sequenceId, dateContext },
});

export const setReportDefinitionDateContextAndRegenerate = (
  sequenceId: number,
  dateContext: DateContext,
): AppThunk => dispatch => {
  dispatch(setReportDefinitionDateContext(sequenceId, dateContext));
  dispatch(generateIfAuto());
};

export const setReportDefinitionCurrency = (sequenceId: number, currency: string) => ({
  type: ActionTypes.SET_REPORT_DEFINITION_CURRENCY,
  payload: { sequenceId, currency },
});

export const setReportDefinitionCurrencyAndRegenerate = (
  sequenceId: number,
  currency: string,
): AppThunk => dispatch => {
  dispatch(setReportDefinitionCurrency(sequenceId, currency));
  dispatch(generateIfAuto());
};

export const setReportDefinitionSort = (sequenceId: number, sort: Sort[]) =>
  ({
    type: ActionTypes.SET_REPORT_DEFINITION_SORT,
    payload: { sequenceId, sort },
  } as const);

export const setReportDefinitionPrecision = (sequenceId: number, update: ReportPrecisionUpdate) =>
  ({
    type: ActionTypes.SET_REPORT_DEFINITION_PRECISION,
    payload: { sequenceId, update },
  } as const);

export const setReportTitle = (reportTitle: string) => ({
  type: ActionTypes.SET_REPORT_TITLE,
  payload: {
    sequenceId: DESIGNER_SEQUENCE_ID,
    reportTitle,
  },
});

export const setReportDefinition = (sequenceId: number, reportDefinition: ReportDefinition) => ({
  type: ActionTypes.SET_REPORT_DEFINITION,
  payload: {
    sequenceId,
    reportDefinition,
  },
});

export const setComplicationSelection = (
  sequenceId: number,
  complicationId: string,
  selectedOption: string,
) => ({
  type: ActionTypes.SET_COMPLICATION_SELECTION,
  payload: {
    sequenceId,
    complicationId,
    selectedOption,
  },
});

export const resetDesigner = () =>
  ({
    type: ActionTypes.RESET_DESIGNER,
  } as const);

export const initializeDesigner = (
  source: DesignerSource,
  initialProps: Partial<DesignerPanelState> = {},
) =>
  ({
    type: ActionTypes.INITIALIZE_DESIGNER,
    payload: {
      ...initialProps,
      source,
    } as Partial<DesignerPanelState>,
  } as const);

/**
 * Open a new, blank report in the designer. Unattached to the current workspace
 * until saved.
 *
 * @param shouldOpenPage - Whether to follow-through & navigate to the designer.
 *
 * @see {@link loadBlankReportIntoDesigner}
 */
export const openNewReport = (shouldOpenPage = true): AppThunk => dispatch => {
  dispatch(loadBlankReportIntoDesigner());
  // set default workspace payload designer
  dispatch(initializeDesigner(DesignerSource.CREATE_BLANK));

  if (shouldOpenPage) {
    dispatch(openDesignerPage());
  }
};

/**
 * Open a report in the designer. The report must be loaded in the current workspace.
 *
 * @param sequenceId - The ID of the report to open (relative to the workspace).
 * @param newFrom - If true, a new report will be cloned from the specified report.
 *   Otherwise, the report itself will be opened.
 * @param shouldOpenPage - Whether to follow-through & navigate to the designer.
 *
 * @see {@link loadReportIntoDesigner}
 * @see {@link cloneReportIntoDesigner}
 */
export const openWorkspaceReportInDesigner = (
  sequenceId: number,
  newFrom: boolean,
  shouldOpenPage = true,
): AppThunk => (dispatch, getState) => {
  dispatch(newFrom ? cloneReportIntoDesigner(sequenceId) : loadReportIntoDesigner(sequenceId));

  const rawData = getState().report.reportData?.[sequenceId]?.raw;
  if (rawData && !rawData.isLoading) {
    dispatch(
      updateReportData({
        ...rawData,
        sequenceId: DESIGNER_SEQUENCE_ID,
      }),
    );
  } else {
    dispatch(generateIfAuto());
  }

  dispatch(setDesignerWorkspacePayload(getReportWorkspacePayload(sequenceId, getState())));
  dispatch(setReportDesignerState());
  dispatch(
    initializeDesigner(
      newFrom ? DesignerSource.CLONE_FROM_WORKSPACE : DesignerSource.OPEN_FROM_WORKSPACE,
      { sourceSequenceId: sequenceId },
    ),
  );

  if (shouldOpenPage) {
    dispatch(openDesignerPage());
  }
};

/**
 * Open a report from the current workspace into the designer, with drill-through
 * functionality.
 *
 * @param sequenceId - The ID of the report to open (relative to the workspace).
 * @param newTab - Whether the report should be associated to a new workspace tab
 *   when saved, as opposed to the currently active one.
 * @param shouldOpenPage - Whether to follow-through & navigate to the designer.
 *
 * @see {@link loadDrillThroughReportIntoDesigner}
 */
export const openDrillThroughReportInDesigner = (
  sequenceId: number,
  newTab: boolean,
  shouldOpenPage = true,
): AppThunk => (dispatch, getState) => {
  dispatch(loadDrillThroughReportIntoDesigner(sequenceId));
  dispatch(setDesignerWorkspacePayload(getReportWorkspacePayload(sequenceId, getState())));
  dispatch(
    initializeDesigner(DesignerSource.OPEN_WITH_DRILL_THROUGH, {
      drillThrough: {
        parentSequenceId: sequenceId,
        parentSelectedElements: getState().workspace.data.tabs[
          getState().workspace.selectedTabIndex
        ].reports[sequenceId].selectedElements,
        newTab,
      },
    }),
  );

  if (shouldOpenPage) {
    dispatch(openDesignerPage());
  }
};

/**
 * Open a report in the designer. The report will be detached from the current workspace --
 * opened directly from the server into the designer, unattached to the workspace until
 * explicitly saved.
 *
 * @param path - The path specifying the report within the directory structure.
 * @param isLegacy - Whether the report is a legacy report (i.e. originating from Theatre).
 *   Legacy reports **cannot** be opened this way -- this argument is required as a sanity
 *   check to prevent attempting such an action.
 * @param shouldOpenPage - Whether to follow-through & navigate to the designer.
 * @returns Whether the report has been loaded successfully.
 *
 * @see {@link loadPersistedReportIntoDesigner}
 */
export const openDetachedReportInDesigner = (
  path: string,
  isLegacy: boolean,
  shouldOpenPage = true,
): AppThunk<Promise<boolean>> => async dispatch => {
  if (isLegacy) {
    dispatch(
      enqueueSnackbar(
        NotificationLevel.WARN,
        `Warning: Unable to open legacy report (${path}) in designer`,
      ),
    );

    return false;
  }

  const successful = await dispatch(loadPersistedReportIntoDesigner(path, isLegacy as false));
  if (successful) {
    dispatch(setIsAutoGenerate(false));
    dispatch(initializeDesigner(DesignerSource.OPEN_DETACHED));
    dispatch(setDesignerWorkspacePayload(createDesignerWorkspacePayload()));
    if (shouldOpenPage) {
      dispatch(openDesignerPage());
    }
  }

  return successful;
};

export const loadBlankReportIntoDesigner = (): AppThunk => (dispatch, getState) => {
  dispatch(setReportDefinition(DESIGNER_SEQUENCE_ID, resolveDefaultReportDefinition(getState())));
  dispatch(setReportDesignerState());
  dispatch(setDesignerWorkspacePayload(createDesignerWorkspacePayload()));
};

export const loadReportIntoDesigner = (sequenceId: number): AppThunk => (dispatch, getState) => {
  dispatch(
    setReportDefinition(DESIGNER_SEQUENCE_ID, getState().report.reportDefinition[sequenceId]),
  );
};

export const cloneReportIntoDesigner = (sequenceId: number): AppThunk => (dispatch, getState) => {
  dispatch(
    setReportDefinition(DESIGNER_SEQUENCE_ID, {
      ...getState().report.reportDefinition[sequenceId],
      path: undefined,
      id: undefined,
    }),
  );
};

export const loadDrillThroughReportIntoDesigner = (sequenceId: number): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(
    setReportDefinition(
      DESIGNER_SEQUENCE_ID,
      buildDrillThroughDefinition(
        getState().report.reportDefinition[sequenceId],
        resolveDefaultReportDefinition(getState()),
      ),
    ),
  );
  dispatch(setReportDesignerState());
};

const buildDrillThroughDefinition = (
  sourceDefinition: ReportDefinition,
  defaultDefinition: ReportDefinition,
): ReportDefinition => {
  // If this setting is false, inheritance is disabled altogether.
  if (!defaultDefinition.settings?.drillThroughInheritance) {
    return defaultDefinition;
  }

  // Each property is conditionally inherited, depending on whether the
  // corresponding values in `defaultDefinition.settings` are set.
  return {
    ...defaultDefinition,

    ...(defaultDefinition.settings?.reportingCurrency
      ? { currency: sourceDefinition.currency }
      : {}),

    ...(defaultDefinition.settings?.scenarioSettings
      ? { scenariosConfig: sourceDefinition.scenariosConfig }
      : {}),
  };
};

export const loadPersistedReportIntoDesigner = (
  path: string,
  isLegacy: false, // Legacy reports are categorically unsupported by the designer
): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    const response = await axios.get<ReportDefinition>(`${baseUrl}api/reportDefinition`, {
      params: {
        path,
        isLegacyReport: isLegacy,
      },
    });
    dispatch(setOriginalDetachedReport(response.data));
    dispatch(setReportDefinition(DESIGNER_SEQUENCE_ID, response.data));
    return true;
  } catch (error) {
    dispatch(
      enqueueSnackbar(
        NotificationLevel.WARN,
        `Warning: Unable to open report: ${getErrorMessage(error)}`,
      ),
    );

    return false;
  }
};

export const openSelectedReportAsDetached = (): AppThunk => (dispatch, getState) => {
  const { selectedFolderItem } = getState().drawers.folderDrawer;
  if (selectedFolderItem === null) {
    return;
  }

  const { path, isLegacy } = selectedFolderItem.props;
  dispatch(openDetachedReportInDesigner(path, isLegacy));
};

// Copy Scenarios and Settings from DESIGNER_SEQUENCE_ID into reportDesigner state
export const setReportDesignerState = (): AppThunk => (dispatch, getState) => {
  const { scenariosConfig, settings, benchmarks } = getState().report.reportDefinition[
    DESIGNER_SEQUENCE_ID
  ];
  dispatch(setReportDesignerScenarioConfig(scenariosConfig));
  dispatch(setReportDesignerSettings(settings));
  dispatch(setReportDesignerBenchmarks(benchmarks));
};

export const setReportDefinitionVisualizations = (
  sequenceId: number,
  choicesAndSelection: { options: {}; selectedOption: string },
) => ({
  type: ActionTypes.SET_REPORT_DEFINITION_VISUALIZATIONS,
  payload: { sequenceId, choicesAndSelection },
});

export const resetLayoutComplication = (sequenceId: number) => ({
  type: ActionTypes.RESET_LAYOUT_COMPLICATION,
  payload: sequenceId,
});

export const setLayoutComplication = (sequenceId: number) => ({
  type: ActionTypes.SET_LAYOUT_COMPLICATION,
  payload: sequenceId,
});

// When the user clicks Apply on the scenarios tab of report designer
export const updateReportDefinitionScenariosConfig = (): AppThunk => (dispatch, getState) =>
  dispatch({
    type: ActionTypes.UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG,
    payload: getState().reportDesigner.scenariosConfig,
  });

// When the user clicks Apply on the settings tab of report designer
export const updateReportDefinitionSettings = (): AppThunk => (dispatch, getState) =>
  dispatch({
    type: ActionTypes.UPDATE_REPORT_DEFINITION_SETTINGS,
    payload: getState().reportDesigner.settings,
  });

// When the user clicks Apply on the settings tab of report designer
export const updateReportDefinitionBenchmarks = (): AppThunk => (dispatch, getState) =>
  dispatch({
    type: ActionTypes.UPDATE_REPORT_DEFINITION_BENCHMARKS,
    payload: getState().reportDesigner.benchmarks,
  });

export const setNickname = (charId: number, modifier: number, nickname?: string) =>
  ({ type: ActionTypes.SET_NICKNAME, payload: { charId, modifier, nickname } } as const);

export const clearNickname = (charId: number, modifier: number): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(setNickname(charId, modifier));

  if (getState().reportDesigner.panelControl.isAutoGenerate) {
    sendStompMessage(
      {
        requestType: 'modifyNickname',
        sequenceId: DESIGNER_SEQUENCE_ID,
        ...getCommonRequestProperties(getState()),
        reportDefinition: getState().report.reportDefinition[DESIGNER_SEQUENCE_ID],
      },
      getState().user.tk || localStorage.getItem('id_token'),
    );
  }
};

export const setCurrentCharNickname = (nickname?: string): AppThunk => (dispatch, getState) => {
  dispatch(
    setNickname(
      getState().reportDesigner.panelControl.charToNickname.charId,
      getState().reportDesigner.panelControl.charToNickname.modifier,
      nickname,
    ),
  );

  if (getState().reportDesigner.panelControl.isAutoGenerate) {
    sendStompMessage(
      {
        requestType: 'modifyNickname',
        sequenceId: DESIGNER_SEQUENCE_ID,
        ...getCommonRequestProperties(getState()),
        reportDefinition: getState().report.reportDefinition[DESIGNER_SEQUENCE_ID],
      },
      getState().user.tk || localStorage.getItem('id_token'),
    );
  }
};

// When the user modifies Grouping characteristic in report designer we automatically switch to manualGroupingOverride
export const setReportDefinitionManualGroupingOverride = (): AppThunk => (dispatch, getState) =>
  dispatch({
    type: ActionTypes.UPDATE_REPORT_DEFINITION_SETTINGS,
    payload: { ...getState().reportDesigner.settings, manualGroupingOverride: true },
  });

export const updateReportPaths = (oldPath: string, newPath: string) => ({
  type: ActionTypes.UPDATE_REPORT_PATHS,
  payload: { oldPath, newPath },
});

export const importReport = (reportDefinition: ReportDefinition): AppThunk => dispatch => {
  dispatch(
    addReportToWorkspace(
      null,
      false,
      false,
      DESIGNER_SEQUENCE_ID,
      false,
      true,
      [],
      false,
      reportDefinition,
      null,
    ),
  );
};

export const importReportDefinitions = (reportDefinitions: ReportDefinitions) => ({
  type: ActionTypes.IMPORT_REPORT_DEFINITIONS,
  payload: reportDefinitions,
});

export const setReportPortfolioNodes = (sequenceId: number, nodes: SelectedPortfolios) => ({
  type: ActionTypes.SET_REPORT_PORTFOLIO_NODES,
  payload: { sequenceId, nodes },
});

export const setCurrentReportPortfolioNodes = (nodes: SelectedPortfolios): AppThunk => (
  dispatch,
  getState,
) => dispatch(setReportPortfolioNodes(getState().drawers.reportPortfolioDrawer.sequenceId, nodes));

export const setSelectedReportEntities = (
  sequenceId: number,
  selectedEntities: SelectedEntities,
) => ({
  type: ActionTypes.SET_SELECTED_REPORT_ENTITIES,
  payload: { sequenceId, selectedEntities },
});

export const setCurrentReportEntities = (selectedEntities: SelectedEntities): AppThunk => (
  dispatch,
  getState,
) =>
  dispatch(
    setSelectedReportEntities(getState().drawers.reportEntitiesDrawer.sequenceId, selectedEntities),
  );

export const removeReportPortfolioNodes = (sequenceId: number) =>
  ({ type: ActionTypes.REMOVE_REPORT_PORTFOLIO_NODES, payload: { sequenceId } } as const);

export const removeCurrentReportPortfolioNodes = (): AppThunk => (dispatch, getState) =>
  dispatch(removeReportPortfolioNodes(getState().drawers.reportPortfolioDrawer.sequenceId));

export const removeSelectedReportEntities = (sequenceId: number) =>
  ({ type: ActionTypes.REMOVE_SELECTED_REPORT_ENTITIES, payload: { sequenceId } } as const);

export const removeCurrentReportEntities = (): AppThunk => (dispatch, getState) =>
  dispatch(removeSelectedReportEntities(getState().drawers.reportEntitiesDrawer.sequenceId));

export const addAndSaveReport = (): AppThunk => (dispatch, getState) => {
  const { isAutoGenerate, source, sourceSequenceId } = getState().reportDesigner.panelControl;
  const reportDefinition = getState().report.reportDefinition?.[DESIGNER_SEQUENCE_ID];
  const isNewReport = creatingNewReport(source);
  const sequenceId = isNewReport ? nextSequenceId() : sourceSequenceId;

  if (isAutoGenerate) {
    // Since auto generate is enabled, the report is already generated or generating.
    dispatch(setReportDefinition(sequenceId, reportDefinition));
    dispatch(updateReportDataFromDesigner(sequenceId));

    if (isNewReport) {
      // this is a newly created report being added to the workspace
      dispatch(addAdHocReportToWorkspace(sequenceId));
    }
  } else {
    if (isNewReport) {
      // this is a newly created report being added to the workspace
      dispatch(addAdHocReportToWorkspace(null));
    } else {
      // this is an existing workspace report being modified
      dispatch(setReportDefinition(sequenceId, reportDefinition));
      dispatch(
        sendReportUpdateMessage(
          {
            sequenceId,
            requestId: `seq-${sequenceId}-req-${nextRequestId()}`,
            legacyReport: false,
            adhoc: true,
          },
          ReportUpdateHighlightMode.REPORT_CHANGE,
          ReportRequestStatus.GENERATE,
        ),
      );
    }
  }
  dispatch(setOriginalDetachedReport());
  dispatch(closeDesigner(ContentPage.WORKSPACE));
  dispatch(changeSequenceId(DESIGNER_SEQUENCE_ID, sequenceId));

  // Send a message to the server so that the report associated with a
  // sequence id of DESIGNER_SEQUENCE_ID is now the report associated with the real sequence id.
  dispatch(
    sendWebSocketMessage(
      { requestType: 'changeSequenceId', oldSequenceId: DESIGNER_SEQUENCE_ID, sequenceId },
      null,
      (error: Error) =>
        console.error('Failed to reassign sequence id ' + sourceSequenceId + ': ' + error.message),
    ),
  );

  dispatch(deleteReportData(DESIGNER_SEQUENCE_ID));
};

export const setDxTableColumnWidths = (
  sequenceId: number,
  columnWidths?: ColumnWidth[],
): AppThunk => (dispatch, getState) => {
  dispatch(
    setReportDefinition(sequenceId, {
      ...getState().report.reportDefinition[sequenceId],
      columnWidths,
    }),
  );
};

export const setExpandedRowIds = (sequenceId: number, expandedRowIds: string[]): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(
    setReportDefinition(sequenceId, {
      ...getState().report.reportDefinition[sequenceId],
      expandedRowIds,
    }),
  );
};

export const toggleReportRows = (
  sequenceId: number,
  data: Node<Cell[]>,
  selectedElements: string[],
  toggle: boolean,
  n = Infinity,
): AppThunk => (dispatch, getState) => {
  const selectedRowIds = getElementsNChildren(data, selectedElements, n);
  const expandedRowIds = getState().report.reportDefinition[sequenceId].expandedRowIds;
  const expandIds = toggle
    ? [...selectedRowIds, ...expandedRowIds]
    : (
        expandedRowIds ||
        getElementsNChildren(
          // get every rowId and then filter it if expandedRowIds is null
          data,
          [String(data.children[0].payload?.[0]?.[RowField.ROW_HASH])],
          Infinity,
        )
      ).filter(id => !selectedRowIds.includes(id));
  dispatch(setExpandedRowIds(sequenceId, Array.from(new Set(expandIds))));
};

export const updateWithAdHocGrouping = (
  customGrouping: AdHocCustomGroupingDefinition,
  removePrevious: boolean,
) =>
  ({
    type: ActionTypes.UPDATE_WITH_AD_HOC_GROUPING,
    payload: { customGrouping, removePrevious },
  } as const);
