import { createDefaultSettings, Settings } from '../model/settings';
import * as ActionTypes from './ActionTypes';

export const settings = (state: Settings = createDefaultSettings(), action): Settings => {
  switch (action.type) {
    case ActionTypes.SET_REPORT_DESIGNER_SETTINGS:
      return { ...action.payload.settings };
    case ActionTypes.SET_SETTING:
      if (
        (action.payload.propertyName === 'numberOfHeaderChars' ||
          action.payload.propertyName === 'constrainedRows') &&
        action.payload.propertyValue < 0
      )
        return { ...state, [action.payload.propertyName]: 0 };
      else
        return {
          ...state,
          [action.payload.propertyName]: action.payload.propertyValue,
        };
    case ActionTypes.COPY_SETTINGS:
      return action.payload || createDefaultSettings();
    default:
      return state;
  }
};
