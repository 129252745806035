import { FormControl, RadioGroup, Select, Typography } from '@material-ui/core';
import { LIGHT_BORDER } from 'algo-react-dataviz';
import axios from 'axios';
import { DropDownBox, TreeView } from 'devextreme-react';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { v4 } from 'uuid';
import {
  enqueueSnackbar,
  setBenchmark,
  setBenchmarkDateContextRun,
  setSetting,
} from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { fetchSandboxes } from '../../../redux/UserProfileActionCreators';
import { DESIGNER_SEQUENCE_ID, NotificationLevel } from '../../../shared/constants';
import { Benchmark, DataSourceItemForDx, Portfolio, Sandboxes } from '../../../shared/dataTypes';
import { isAwa } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';
import SettingsRadio from '../settings-panel/SettingsRadio';
import MenuItems from '../ui-elements/MenuItems';
import BenchmarkDatePicker from './BenchmarkDatePicker';

interface BaseProps {
  hidden: boolean;
  benchmark: Benchmark;
  index: number;
  benchmarkSets: string[];
}

const mapStateToProps = (state: AppState) => ({
  benchmarkSet: state.reportDesigner.settings.benchmarkSet,
  reportableType: state.report.reportDefinition[DESIGNER_SEQUENCE_ID].reportableType,
  sandboxes: state.user.userInfo.userPreferences?.sandboxes,
  isAwa: isAwa(state),
});

const mapDispatchToProps = { setBenchmark, setSetting, setBenchmarkDateContextRun, fetchSandboxes };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

interface SandboxDataSource extends DataSourceItemForDx<string> {
  path: string;
  type: string;
}

const SingleBenchmarkPanel: FC<Props> = ({
  benchmark,
  benchmarkSets,
  index,
  benchmarkSet,
  setBenchmark,
  setSetting,
  setBenchmarkDateContextRun,
  hidden,
  reportableType,
  sandboxes,
  fetchSandboxes,
  isAwa,
}) => {
  const [benchmarkRelativeDates, setBenchmarkRelativeDates] = useState<string[]>([]);
  const [availablePortfolios, setAvailablePortfolios] = useState<Portfolio[]>([]);
  const [sandboxTreeOpened, setSandboxTreeOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!hidden) {
      if (isAwa)
        axios
          .get<Portfolio[]>(`${baseUrl}api/portfolios`, { params: { reportableType } })
          .then(response => setAvailablePortfolios(response.data))
          .catch((error: Error) =>
            enqueueSnackbar(
              NotificationLevel.ERROR,
              `Failed to load benchmark dates from server: ${error.message}`,
            ),
          );

      axios
        .get<string[]>(`${baseUrl}api/benchmarkRelativeDates`, {
          params: { reportableType },
        })
        .then(response => {
          setBenchmarkRelativeDates(response.data);
        })
        .catch((error: Error) =>
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load benchmark dates from server: ${error.message}`,
          ),
        );

      fetchSandboxes();
    }
  }, [hidden, reportableType, fetchSandboxes, isAwa]);

  const getSandboxDataSource = (): SandboxDataSource[] => {
    const dataSource: SandboxDataSource[] = [];

    if (sandboxes?.private) {
      sandboxes.private.forEach((sandbox, index) => {
        dataSource.splice(index, 0, {
          name: sandbox,
          id: v4(),
          parentId: undefined,
          path: sandbox,
          type: 'sandbox',
        });
      });
    }

    if (sandboxes?.public) {
      buildPublicSandboxDataSource(sandboxes.public, undefined, undefined, dataSource);
    }

    return dataSource;
  };

  const buildPublicSandboxDataSource = (
    sandbox: Sandboxes['public'],
    sandboxId: string,
    parentSandboxId: string,
    dataSource: SandboxDataSource[],
  ) => {
    if (sandboxId != null) {
      dataSource.push({
        id: sandboxId,
        parentId: parentSandboxId,
        name: sandbox.name,
        path: sandbox?.props?.path,
        type: sandbox.type,
      });
    }
    if (sandbox.children) {
      sandbox.children.map(s => buildPublicSandboxDataSource(s, v4(), sandboxId, dataSource));
    }
  };

  const sandboxDS = getSandboxDataSource();

  return (
    <div style={{ padding: '12px' }} hidden={hidden}>
      <Typography variant='h3' style={{ padding: '0px 0 10px', borderBottom: LIGHT_BORDER }}>
        Data Set
      </Typography>
      <RadioGroup
        value={benchmark.dataSetType}
        onChange={e => {
          setBenchmark(index, 'dataSetType', e.target.value as 'same' | 'custom' | 'hierarchy');
          if (e.target.value === 'hierarchy') {
            // Dataset type of hierarchy implies report date and benchmark have values of "same"
            setBenchmark(index, 'reportDateType', 'same');
            setBenchmark(index, 'sandboxType', 'sameSandbox');
          }
        }}
      >
        <SettingsRadio id='same' label='Same' />

        {isAwa && (
          <>
            <div className='radio-select'>
              <SettingsRadio id='hierarchy' label='Hierarchy' />
              <FormControl size='small'>
                <Select
                  disabled={benchmark.dataSetType !== 'hierarchy'}
                  variant='outlined'
                  value={(benchmarkSets.length && benchmarkSet) || ''}
                  onChange={e => setSetting('benchmarkSet', e.target.value as string)}
                >
                  {MenuItems(benchmarkSets)}
                </Select>
              </FormControl>
            </div>

            <div className='radio-select'>
              <SettingsRadio id='custom' label='Custom' />
              <FormControl size='small'>
                <Select
                  disabled={benchmark.dataSetType !== 'custom'}
                  id='custom'
                  color='primary'
                  variant='outlined'
                  value={
                    availablePortfolios.find(p => p.id.toString() === benchmark.dataSetPortfolio)
                      ? benchmark.dataSetPortfolio
                      : ''
                  }
                  onChange={e => setBenchmark(index, 'dataSetPortfolio', e.target.value.toString())}
                >
                  {MenuItems(
                    availablePortfolios?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })),
                  )}
                </Select>
              </FormControl>
            </div>
          </>
        )}
      </RadioGroup>

      <Typography
        variant='h3'
        style={{ marginTop: '20px', padding: '0px 0 10px', borderBottom: LIGHT_BORDER }}
      >
        Report Date
      </Typography>
      <RadioGroup
        value={benchmark.reportDateType}
        onChange={e =>
          setBenchmark(index, 'reportDateType', e.target.value as 'same' | 'custom' | 'relative')
        }
      >
        <SettingsRadio id='same' label='Same Date' />
        <div className='radio-select'>
          <SettingsRadio
            id='custom'
            label='Custom'
            disabled={benchmark.dataSetType === 'hierarchy'}
          />
          <BenchmarkDatePicker {...{ benchmark, index }} />
        </div>
        <div className='radio-select'>
          <SettingsRadio
            id='relative'
            label='Relative'
            disabled={benchmark.dataSetType === 'hierarchy'}
          />
          <FormControl size='small'>
            <Select
              disabled={benchmark.reportDateType !== 'relative'}
              id='relative'
              color='primary'
              variant='outlined'
              value={
                benchmarkRelativeDates.findIndex(s => s === benchmark.relativeDate) === -1
                  ? ''
                  : benchmark.relativeDate
              }
              onChange={e => setBenchmark(index, 'relativeDate', e.target.value as string)}
            >
              {MenuItems(benchmarkRelativeDates)}
            </Select>
          </FormControl>
        </div>
      </RadioGroup>

      <RadioGroup
        value={benchmark.sandboxType}
        onChange={e =>
          setBenchmark(
            index,
            'sandboxType',
            e.target.value as 'noSandbox' | 'sameSandbox' | 'setSandbox',
          )
        }
      >
        <Typography
          variant='h3'
          style={{ marginTop: '20px', padding: '0px 0 10px', borderBottom: LIGHT_BORDER }}
        >
          Sandbox
        </Typography>
        <SettingsRadio
          id='noSandbox'
          label='None'
          disabled={benchmark.dataSetType === 'hierarchy'}
        />
        <SettingsRadio id='sameSandbox' label='Same' />

        <div className='radio-select'>
          <SettingsRadio
            id='setSandbox'
            label='Set'
            disabled={benchmark.dataSetType === 'hierarchy'}
          />
          <DropDownBox
            disabled={benchmark.sandboxType !== 'setSandbox'}
            opened={sandboxTreeOpened}
            value={benchmark.sandbox}
            onOptionChanged={e => {
              if (e.name === 'opened') setSandboxTreeOpened(e.value === true);
            }}
            // cant figure out why value isn't displaying
            // using placeholder instead to display value
            placeholder={benchmark.sandbox}
            contentRender={() => (
              <TreeView
                dataSource={sandboxDS}
                dataStructure='plain'
                keyExpr='id'
                parentIdExpr='parentId'
                noDataText='No data to display'
                selectionMode='single'
                displayExpr='name'
                selectByClick
                onItemClick={e => {
                  if (e?.itemData?.type === 'sandbox') {
                    setSandboxTreeOpened(false);
                    setBenchmark(index, 'sandbox', e.itemData.path as string);
                  }
                }}
              />
            )}
          />
        </div>
      </RadioGroup>
    </div>
  );
};

export default connector(SingleBenchmarkPanel);
