import { Typography } from '@material-ui/core';

import { UISlot } from '../../shared/dataTypes';

function DivSlot(props: { slotDetails: UISlot }) {
  const { text } = props.slotDetails.complicationDetails;
  return <Typography variant='h2'>{text}</Typography>;
}

export default DivSlot;
