import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { baseUrl } from '../../components/shared/environment';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { NotificationLevel } from '../../shared/constants';
import {
  CustomGrouping,
  CustomGroupingDto,
  transformFromDto,
  transformToDto,
} from './customGrouping';

export interface CustomGroupingApi {
  createCustomGrouping: (grouping: CustomGrouping) => Promise<boolean>;
  retrieveCustomGrouping: (id: string) => Promise<CustomGrouping | null>;
  updateCustomGrouping: (grouping: CustomGrouping) => Promise<boolean>;
  deleteCustomGrouping: (id: string) => Promise<boolean>;
  hasCustomGroupingWithName: (name: string) => Promise<boolean | null>;
}

const CRUD_URL = `${baseUrl}api/customGrouping`;
const EXISTENCE_URL = `${baseUrl}api/customGroupingExistence`;
const useCustomGroupingApi = (shouldDispatchErrors = true): CustomGroupingApi => {
  const dispatch = useDispatch();

  const onError = useCallback(
    <T>(methodVerb: string, returnValue: T) => (error: Error) => {
      if (shouldDispatchErrors) {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to ${methodVerb} custom grouping: ${error.message}`,
          ),
        );
      }

      return returnValue;
    },
    [dispatch, shouldDispatchErrors],
  );

  const createCustomGrouping = useCallback(
    async (grouping: CustomGrouping) =>
      axios
        .post(CRUD_URL, transformToDto(grouping))
        .then(() => true)
        .catch(onError('create', false)),
    [onError],
  );

  const retrieveCustomGrouping = useCallback(
    async (id: string) =>
      axios
        .get<CustomGroupingDto>(CRUD_URL, {
          params: { id },
        })
        .then(({ data }) => transformFromDto(data))
        .catch<null>(onError('retrieve', null)),
    [onError],
  );

  const updateCustomGrouping = useCallback(
    async (grouping: CustomGrouping) =>
      axios
        .put(CRUD_URL, transformToDto(grouping))
        .then(() => true)
        .catch(onError('update', false)),
    [onError],
  );

  const deleteCustomGrouping = useCallback(
    async (id: string) =>
      axios
        .delete(CRUD_URL, {
          params: { id },
        })
        .then(() => true)
        .catch(onError('delete', false)),
    [onError],
  );

  const hasCustomGroupingWithName = useCallback(
    async (name: string) =>
      axios
        .get<boolean>(EXISTENCE_URL, {
          params: { name: encodeURIComponent(name) },
        })
        .then(({ data }) => data)
        .catch<null>(onError('determine existence of', null)),
    [onError],
  );

  return useMemo(
    () => ({
      createCustomGrouping,
      retrieveCustomGrouping,
      updateCustomGrouping,
      deleteCustomGrouping,
      hasCustomGroupingWithName,
    }),
    [
      createCustomGrouping,
      retrieveCustomGrouping,
      updateCustomGrouping,
      deleteCustomGrouping,
      hasCustomGroupingWithName,
    ],
  );
};

export default useCustomGroupingApi;
