import { CircularProgress, LinearProgress, Typography } from '@material-ui/core';
import { FC, useState } from 'react';
import { useAppSelector } from '../../redux/configureStore';
import { ProgressData } from '../../redux/progress/state';

interface Props {
  sequenceId: number;
}

const ReportProgress: FC<Props> = props => {
  const [hover, setHover] = useState(false);
  const progress: ProgressData[] =
    useAppSelector(({ progress }) => progress[props.sequenceId]) ||
    getDefaultProgressData(props.sequenceId);

  return (
    <div
      className={`report-loading-inner${hover ? '' : ' circular-progress-container'}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover ? (
        progress?.map((progressItem, progressIndex) => {
          const stackItem = progressItem.stack[progressItem.stack.length - 1];
          return (
            <div
              key={`${progressIndex}_${progressItem.name}`}
              className={`progress-item${progressItem.name?.startsWith('PS') ? ' ps' : ''}`}
            >
              <LinearProgress
                variant={stackItem.maxPoints < 0 ? 'indeterminate' : 'determinate'}
                value={(100 * stackItem.currentPoints) / stackItem.maxPoints}
              />
              <Typography className='progress-label'>
                {progressItem.name}
                {stackItem.progressName && `: ${stackItem.progressName}`}
                {stackItem.phaseName && `: ${stackItem.phaseName}`}
              </Typography>
            </div>
          );
        })
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

const getDefaultProgressData = (sequenceId: number): ProgressData[] => [
  {
    end: false,
    name: 'Operation in progress...',
    sequenceId,
    stack: [{ currentPoints: 0, maxPoints: 1 }],
  },
];

export default ReportProgress;
