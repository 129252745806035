import { Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core';
import { RoundedButton } from 'algo-react-dataviz';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setCharToNickname } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { setCurrentCharNickname } from '../../redux/ReportActionCreators';
import './nickname-dialog.scss';

const mapStateToProps = (state: AppState) => ({
  charToNickname: state.reportDesigner.panelControl.charToNickname,
});

const mapDispatchToProps = { setCharToNickname, setCurrentCharNickname };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const NicknameDialog: FC<Props> = props => {
  const [enteredText, setEnteredText] = useState('');

  useEffect(() => !props.charToNickname && setEnteredText(''), [props.charToNickname]);

  return (
    <Dialog
      open={!!props.charToNickname}
      onClose={props.setCharToNickname}
      className='nickname-dialog'
    >
      {props.charToNickname && (
        <>
          <Typography variant='h2' className='dialog-title'>
            {props.charToNickname.name}
          </Typography>
          <DialogContent>
            <TextField
              label='Nickname'
              variant='outlined'
              size='small'
              value={enteredText}
              onChange={e => setEnteredText(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <RoundedButton
              // TS disapproves here if you remove the "() =>" and "()"
              onClick={() => props.setCharToNickname()}
              color='primary'
              variant='outlined'
            >
              Cancel
            </RoundedButton>
            <RoundedButton
              onClick={() => {
                props.setCurrentCharNickname(enteredText);
                props.setCharToNickname();
              }}
              color='primary'
              variant='contained'
              disabled={enteredText.trim().length === 0}
            >
              Apply
            </RoundedButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default connector(NicknameDialog);
