import { Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { NotificationLevel } from '../../../shared/constants';
import {
  Fund,
  InstrumentOrFund,
  ReportDateContextType,
  Sandbox,
  ServerInstrument,
} from '../../../shared/dataTypes';
import { useDebounce } from '../../../shared/utils';
import { PositionInstrument } from '../position-drawer/reducer';
import { getProxyData } from './ProxyDrawer';
import ProxySelector from './ProxySelector';

interface Props {
  label: string;
  placeholder: string;
  disabled: boolean;
  selectedInstruments: PositionInstrument[];
  onChange: (instrument: InstrumentOrFund, checked: boolean) => void;
  sandbox: Sandbox;
  dateContextString: string;
  dateContextType: ReportDateContextType;
  includeFunds?: boolean;
}

const InstrumentSelector: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<InstrumentOrFund[]>();
  const [loading, setLoading] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();

  const dispatchEnqueueSnackbar = useCallback(
    (type: NotificationLevel, message: string) => dispatch(enqueueSnackbar(type, message)),
    [dispatch],
  );

  const { sandbox, dateContextString, dateContextType, includeFunds } = props;

  // Debounced proxyInstruments call
  useEffect(() => {
    if (debouncedSearchTerm) {
      getProxyData(
        'proxyInstruments',
        debouncedSearchTerm,
        { sandbox, dateContextString, dateContextType, includeFunds },
        dispatchEnqueueSnackbar,
      )
        .then(response =>
          setSearchResults(
            response.data
              .map(e =>
                isFund(e)
                  ? { id: e.fundId, name: e.fundName, isFund: true }
                  : { ...e, id: e.instrumentId, name: e.instrumentName, isFund: false },
              )
              .filter((_x, i) => i < 500),
          ),
        )
        .finally(() => setLoading(false));
    }
  }, [
    debouncedSearchTerm,
    sandbox,
    dateContextString,
    dateContextType,
    includeFunds,
    setSearchResults,
    dispatchEnqueueSnackbar,
  ]);

  return (
    <ProxySelector
      label={props.label}
      placeholder={props.placeholder}
      value={searchTerm}
      onChange={e => {
        setLoading(true);
        setSearchTerm(e.target.value);
      }}
      anchorEl={searchTerm ? anchorEl : null}
      {...{ setAnchorEl }}
      disabled={props.disabled}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        searchResults?.map((instrument, i) => (
          <FormControlLabel
            key={i}
            label={instrument.name}
            className='instrument'
            control={
              <Checkbox
                color='primary'
                size='small'
                checked={props.selectedInstruments.some(s => s.id === instrument.id)}
                onChange={e => props.onChange(instrument, e.target.checked)}
              />
            }
          />
        ))
      )}
    </ProxySelector>
  );
};

const isFund = (e: ServerInstrument | Fund): e is Fund => e.isFund;

export default InstrumentSelector;
