import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Typography,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { GrouperProps } from './GrouperModal';
import LabeledChoices from './LabeledChoices';
import MenuItems from './ui-elements/MenuItems';

interface HierarchyGrouperDataType {
  allLevels: boolean;
  longFormatName: boolean;
  hierarchy: string;
}

const HierarchyGrouperModal: FC<GrouperProps<HierarchyGrouperDataType>> = props => {
  const [longFormatName, setLongFormatName] = useState<boolean>(
    props.initialValue?.longFormatName !== undefined ? props.initialValue?.longFormatName : true,
  );
  const [allLevels, setAllLevels] = useState<boolean>(
    props.initialValue?.allLevels !== undefined ? props.initialValue?.allLevels : true,
  );
  const [hierarchy, setHierarchy] = useState(props.initialValue?.hierarchy || props.grouperData[0]);

  return (
    <Dialog open={true} onClose={props.onCancelClick}>
      <DialogTitle>Editor: Hierarchy Grouper</DialogTitle>

      <DialogContent>
        <LabeledChoices
          label='Name is in'
          value={longFormatName ? 'true' : 'false'}
          onChange={e => setLongFormatName(e.target.value === 'true' ? true : false)}
          choices={[
            { id: 'false', label: 'short format' },
            { id: 'true', label: 'long format' },
          ]}
        />
        <LabeledChoices
          label='Type of hierarchy'
          value={allLevels ? 'true' : 'false'}
          onChange={e => setAllLevels(e.target.value === 'true' ? true : false)}
          choices={[
            { id: 'true', label: 'all values' },
            { id: 'false', label: 'next level' },
          ]}
        />

        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Typography>Select Hierarchy</Typography>
          <Select
            variant='outlined'
            value={hierarchy || props.grouperData[0]}
            onChange={(event: React.ChangeEvent<{ value: string }>) =>
              setHierarchy(event.target.value)
            }
            disabled={false}
            style={{ height: '32px', width: '250px', margin: '6px' }}
            multiple={false}
          >
            {MenuItems(props.grouperData)}
          </Select>
        </div>
      </DialogContent>
      {
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            onClick={() => props.onApplyClick({ longFormatName, allLevels, hierarchy })}
          >
            Apply
          </Button>
          <Button onClick={props.onCancelClick} variant='outlined'>
            Cancel
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
};

export default HierarchyGrouperModal;
