import { ButtonProps } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { RoundedButton } from 'algo-react-dataviz';
import { FC } from 'react';
import { ClipboardApi } from './useClipboard';

interface Props {
  clipboard: ClipboardApi;
  getText: () => string;

  ButtonComponent?: FC<ButtonProps>;
  buttonProps?: ButtonProps;
  label?: string;
  showIcon?: boolean;
  disabled?: boolean;
}

const CopyToClipboardButton: FC<Props> = ({
  clipboard,
  getText,
  ButtonComponent = RoundedButton,
  buttonProps = {},
  label = 'Copy to Clipboard',
  showIcon = true,
  disabled = false,
}) => (
  <ButtonComponent
    onClick={() => {
      clipboard.copy(getText());
    }}
    color='primary'
    variant='text'
    disabled={disabled || !clipboard.isAvailable || clipboard.inProgress}
    startIcon={showIcon ? <CopyIcon style={{ fontSize: '1rem' }} /> : null}
    {...buttonProps}
  >
    {label}
  </ButtonComponent>
);

export default CopyToClipboardButton;
