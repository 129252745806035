import { IconButton, Menu, Tooltip } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { FC, MouseEventHandler, useCallback, useState } from 'react';
import ActionBarDropdownItem, { ActionBarDropdownItemProps } from './ActionBarDropdownItem';

export interface ActionBarDropdownProps {
  label: string;
  icon: SvgIconComponent;
  items: ActionBarDropdownItemProps[];
  disabled?: boolean;
}

const ActionBarDropdown: FC<ActionBarDropdownProps> = ({ label, icon: Icon, items, disabled }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu: MouseEventHandler<HTMLElement> = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const createMenuItem = useCallback(
    ({ onClick, ...rest }: ActionBarDropdownItemProps) => (
      <ActionBarDropdownItem
        key={rest.label}
        onClick={() => {
          onClick();
          closeMenu();
        }}
        {...rest}
      />
    ),
    [closeMenu],
  );

  return (
    <>
      <Tooltip title={label}>
        <span>
          <IconButton
            className='action-bar-dropdown'
            aria-label={label}
            onClick={openMenu}
            {...{ disabled }}
          >
            <Icon />
          </IconButton>
        </span>
      </Tooltip>

      <Menu
        {...{ anchorEl }}
        open={!!anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items.map(createMenuItem)}
      </Menu>
    </>
  );
};

export default ActionBarDropdown;
