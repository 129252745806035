import { Select, Typography } from '@material-ui/core';
import {
  DateContexts,
  DatePickerButtons,
  FullDateContextItem,
  getDefaultDateContextRun,
  RadioRuns,
  ReportDatesPopover,
  SingleDatePicker,
} from 'algo-react-dataviz';
import moment from 'moment';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { fetchDateContexts } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { isDesignerDrillThrough } from '../../redux/designer/panel/selectors';
import {
  setReportDefinitionDateContextAndRegenerate,
  toggleDesignerDateContextRun,
} from '../../redux/ReportActionCreators';
import { DESIGNER_SEQUENCE_ID } from '../../shared/constants';
import { BetweenDates, DateContext, ReportDateContextType } from '../../shared/dataTypes';
import { deDupeArray, getDateContextDisplayString, isAwa } from '../../shared/utils';
import MenuItems from '../designer-panel/ui-elements/MenuItems';
import CheckboxRuns from './CheckboxRuns';
import DateRangePicker from './DateRangePicker';
import MultiDatePicker from './MultiDatePicker';
import { getRunExists } from './selectors';

const mapStateToProps = (state: AppState) => ({
  dateContext: state.report.reportDefinition?.[DESIGNER_SEQUENCE_ID].dateContext,
  dateContexts: state.user.userInfo?.serverConfigs?.dateContexts,
  workspaceDateContext: state.user.selectedDateContext,
  isDrillThrough: isDesignerDrillThrough(state),
  isAwa: isAwa(state),
});

const mapDispatchToProps = {
  setReportDefinitionDateContextAndRegenerate,
  toggleDesignerDateContextRun,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ReportDesignerDatePicker: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [localStateDateContext, setLocalStateDateContext] = useState<DateContext>(
    DEFAULT_DATE_CONTEXT,
  );
  const [dateDisplay, setDateDisplay] = useState<string[]>([]);

  const runExists = useSelector(getRunExists);
  const selectorIsAwa = useSelector(isAwa);
  const dispatch = useDispatch();

  const { dateContext } = props;
  useEffect(
    () =>
      anchorEl
        ? setLocalStateDateContext(dateContext)
        : setLocalStateDateContext(DEFAULT_DATE_CONTEXT),
    [anchorEl, dateContext],
  );

  const rangeRunItems: string[] =
    localStateDateContext.type === 'between' &&
    localStateDateContext.dates?.[0].date &&
    localStateDateContext.dates?.[1].date
      ? deDupeArray(
          Object.keys(props.dateContexts)
            .filter(
              d =>
                moment(d).isSameOrAfter(moment(localStateDateContext.dates[0].date)) &&
                moment(d).isSameOrBefore(moment(localStateDateContext.dates[1].date)),
            )
            .flatMap(d => props.dateContexts[d])
            .map(d => d.id),
        ).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
      : [];

  const getDateDisplay = (dates: FullDateContextItem[]): string[] =>
    dateDisplay.length > 0 ? dateDisplay : dates?.map(d => d.date) || [];

  return (
    <ReportDatesPopover
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onClick={() => dispatch(fetchDateContexts())}
      textFieldValue={getDateContextDisplayString(props.dateContext || { type: 'default' })}
      disabled={props.isDrillThrough}
    >
      <div className='calendar'>
        <div className='select-container'>
          <Typography>Type: </Typography>
          <Select
            value={localStateDateContext.type || ''}
            onChange={(e: ChangeEvent<{ value: ReportDateContextType }>) =>
              setLocalStateDateContext({ type: e.target.value })
            }
            style={{ flex: 1, marginLeft: '10px', backgroundColor: 'white', height: '24px' }}
            variant='outlined'
          >
            {MenuItems([
              { value: 'default', label: 'Default' },
              { value: 'asOf', label: 'As of' },
              { value: 'between', label: 'Range' },
              { value: 'specific', label: 'Specific' },
            ])}
          </Select>
        </div>

        {localStateDateContext.type === 'default' && (
          <SingleDatePicker disabled date={props.workspaceDateContext?.date} {...{ runExists }} />
        )}

        {localStateDateContext.type === 'asOf' && (
          <SingleDatePicker
            date={localStateDateContext.dates?.[0]?.date}
            initDate={props.workspaceDateContext?.date}
            {...{ runExists }}
            onDateChange={newDate => {
              const id = getDefaultDateContextRun(props.dateContexts[newDate]);
              setLocalStateDateContext({
                ...localStateDateContext,
                dates: [{ date: newDate, id }],
              });
            }}
          />
        )}

        {localStateDateContext.type === 'between' && (
          <DateRangePicker
            dates={[localStateDateContext.dates?.[0].date, localStateDateContext.dates?.[1]?.date]}
            initDate={props.workspaceDateContext?.date}
            {...{ runExists }}
            onDatesChange={newDates =>
              setLocalStateDateContext({
                ...localStateDateContext,
                dates: [{ date: newDates[0] }, { date: newDates[1] }],
              })
            }
          />
        )}

        {localStateDateContext.type === 'specific' && (
          <MultiDatePicker
            dates={getDateDisplay(localStateDateContext.dates)}
            initDate={props.workspaceDateContext?.date}
            {...{ runExists }}
            onDatesChange={newDates => {
              setDateDisplay(newDates);

              // Make sure the only runs that stay selected are the ones in the displayed dates
              setLocalStateDateContext({
                ...localStateDateContext,
                dates: props.isAwa
                  ? localStateDateContext.dates?.filter(item => newDates.includes(item.date))
                  : newDates.map(item => {
                      return { date: item };
                    }),
              });
            }}
          />
        )}
      </div>

      {localStateDateContext.type === 'default' && selectorIsAwa && (
        <RadioRuns
          disabled
          items={props.dateContexts?.[props.workspaceDateContext?.date]?.map(d => d.id) || []}
          selectedDate={props.workspaceDateContext?.date}
          selectedRun={props.workspaceDateContext?.id}
        />
      )}

      {localStateDateContext.type === 'asOf' && selectorIsAwa && (
        <RadioRuns
          items={props.dateContexts[localStateDateContext.dates?.[0].date]?.map(d => d.id) || []}
          selectedDate={localStateDateContext.dates?.[0].date}
          selectedRun={localStateDateContext.dates?.[0].id}
          setSelectedRun={id =>
            setLocalStateDateContext({
              type: 'asOf',
              dates: [{ date: localStateDateContext.dates?.[0].date, id }],
            })
          }
        />
      )}

      {localStateDateContext.type === 'between' && selectorIsAwa && (
        <RadioRuns
          disabled={false}
          items={rangeRunItems}
          selectedDate={localStateDateContext.dates?.map(d => d.date).join(' to ')}
          selectedRun={localStateDateContext.dates?.[0].id}
          setSelectedRun={id =>
            setLocalStateDateContext({
              type: 'between',
              dates: localStateDateContext.dates?.map(d => ({ ...d, id })) as BetweenDates,
            })
          }
        />
      )}

      {localStateDateContext.type === 'specific' && (
        <CheckboxRuns
          items={
            Object.fromEntries(
              Object.entries(props.dateContexts).filter(d =>
                getDateDisplay(localStateDateContext.dates).some(l => l === d[0]),
              ),
            ) as DateContexts
          }
          selectedItems={localStateDateContext.dates || []}
          setSelectedItems={dates => setLocalStateDateContext({ ...localStateDateContext, dates })}
        />
      )}
      <DatePickerButtons
        disableApply={
          localStateDateContext.type === 'between'
            ? (localStateDateContext.dates || [])[1]?.date === undefined
            : localStateDateContext.type !== 'default' &&
              (localStateDateContext.dates || []).length === 0
        }
        onApply={() => {
          props.setReportDefinitionDateContextAndRegenerate(
            DESIGNER_SEQUENCE_ID,
            localStateDateContext,
          );
          setAnchorEl(null);
        }}
        onCancel={() => setAnchorEl(null)}
      />
    </ReportDatesPopover>
  );
};

const DEFAULT_DATE_CONTEXT: DateContext = { type: 'default' };

export default connector(ReportDesignerDatePicker);
