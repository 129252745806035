import { FC, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Label,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { paletteC50 as colors } from '../shared/colorPalettes';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { median } from '../shared/utils';

function CustomTooltip(props: any) {
  const { active } = props;

  if (active) {
    const { payload } = props;
    return (
      <div style={{ background: 'white' }}>
        {payload[0].payload[props.labelColumnName] && (
          <div>{payload[0].payload[props.labelColumnName]}</div>
        )}
        {payload.map(p => (
          <div key={`${p.name}`}>{`${p.name} : ${p.payload[`${p.dataKey}Formatted`]}`}</div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

const ScatterChartViz: FC<ReportProps> = props => {
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.SCATTER_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const headers = reportConvertedData.headers;
  const groupingHeader: { name: string; index: number } = reportConvertedData.groupingHeader;

  if (headers.length < 3 && props.bubble) {
    // one of the dimension columns is missing
    return (
      <div style={{ color: 'red' }}>
        Missing dimension for bubble chart (expecting 3 number columns beside grouping)
      </div>
    );
  }

  const scatterChartDataSeries: any[] = reportConvertedData.rows;

  if (!scatterChartDataSeries[0]) {
    return <div style={{ color: 'red' }}>Invalid data format. No data series found.</div>;
  }

  const xMedian =
    props.bubble && median(scatterChartDataSeries[0].data.map(row => row[headers[0]]));
  const yMedian = median(scatterChartDataSeries[0].data.map(row => row[headers[1]]));

  const scatterPlots = scatterChartDataSeries?.map((series, index) => (
    <Scatter
      key={series.name}
      name={series.name}
      data={series.data}
      fill={colors[index] + '66'} // https://stackoverflow.com/a/8254129 for alpha suffixes
      shape={props.bubble ? 'circle' : 'square'}
      stroke={colors[index]}
      strokeWidth='2px'
      onClick={event => event && props.onElementClick(event.rowcol)(event)}
    />
  ));

  return (
    <ScatterChart
      width={props.width}
      height={props.height}
      margin={{
        left: 30,
      }}
    >
      <CartesianGrid />
      <XAxis type='number' dataKey={`${headers[0]}`} name={`${headers[0]}`}>
        <Label value={`${headers[0]}`} offset={0} position='bottom' />
      </XAxis>
      <YAxis type='number' dataKey={`${headers[1]}`} name={`${headers[1]}`}>
        <Label value={`${headers[1]}`} offset={12} position='left' angle={90} />
      </YAxis>
      {props.bubble && (
        <ZAxis
          type='number'
          dataKey={`${headers[2]}`}
          range={[0, 2000]}
          name={`${headers[2]}`}
          // scale='log'
        />
      )}
      <Tooltip
        cursor={{ strokeDasharray: '3 3' }}
        content={<CustomTooltip labelColumnName={groupingHeader.name} />}
      />
      {/* TODO: shall we display reference lines for each series of data (if more than one)?? */}
      {props.bubble && (
        <ReferenceLine x={xMedian} strokeDasharray='3 3' stroke='red'>
          <Label value={`${headers[0]} median`} position='top' />
        </ReferenceLine>
      )}
      {props.bubble && (
        <ReferenceLine y={yMedian} strokeDasharray='3 3' stroke='red'>
          <Label value={`${headers[1]} median`} position='insideTopRight' />
        </ReferenceLine>
      )}
      {scatterPlots}
    </ScatterChart>
  );
};

export default ScatterChartViz;
