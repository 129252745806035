import { InputAdornment, Popover, TextField, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import {
  createCharSearchComparator,
  processSearchString,
} from '../../../../shared/charComparators';
import { Characteristic } from '../../../../shared/dataTypes';
import { isCharCustomGrouping, isGroupingLayer } from '../../../../shared/utils';
import MenuItems from '../../../designer-panel/ui-elements/MenuItems';
import './characteristic-strategy-designer.scss';

const MAX_CHARS_IN_STATE = 300;
const SCROLL_INCREMENT = 200;

interface Props {
  setSelectedChar: (char: Characteristic) => void;
  allChars: Characteristic[];
  searchTerm: string;
  debouncedSearchTerm: string;
  setSearchTerm: (search: string) => void;
  readOnly: boolean;
}

const CustomGroupingCharacteristicDropdown: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const { searchTerm, debouncedSearchTerm, setSearchTerm } = props;
  useEffect(() => {
    setArrayPos(0);
  }, [searchTerm]);

  const [arrayPos, setArrayPos] = useState(0);

  const { allChars } = props;
  const filteredChars = useMemo(
    () =>
      allChars
        ?.filter(
          char =>
            !isGroupingLayer(char) &&
            !isCharCustomGrouping(char) &&
            char.classes?.includes('All') &&
            (!debouncedSearchTerm ||
              processSearchString(char.name).includes(processSearchString(debouncedSearchTerm))),
        )
        .map(char => ({ ...char, draggableId: v4() })) // still using draggable for a UUID
        .sort(createCharSearchComparator(debouncedSearchTerm)),
    [allChars, debouncedSearchTerm],
  );

  const inStateChars = filteredChars?.filter(
    (_c, i) => i >= arrayPos && i < arrayPos + MAX_CHARS_IN_STATE,
  );

  const handleScrollAtTop = () => {
    if (arrayPos === 0) {
      // There are no more chars above to load. Do nothing.
    } else {
      // If inStateChars[0] does not exist, it means we are in an intermediate render that
      // will throw an error if we try to run the below code, and another render with
      // existing inStateChars[0] is about to happen.
      if (inStateChars[0]) {
        const selector = `CSD-${inStateChars[0].draggableId}`;

        window.setTimeout(() => {
          setArrayPos(Math.max(arrayPos - SCROLL_INCREMENT, 0));
          document.getElementById(selector).scrollIntoView();
          // Reset some UI elements that become slightly off as a result of the previous line
          document.getElementById('CSD-Paper').scrollIntoView();
        }, 100);
      }
    }
  };

  const handleScrollAtBottom = () => {
    if (arrayPos + MAX_CHARS_IN_STATE >= filteredChars.length) {
      // There are no more chars below to load. Do nothing.
    } else {
      const selector = `CSD-${inStateChars[inStateChars.length - 1].draggableId}`;
      window.setTimeout(() => {
        setArrayPos(
          Math.min(arrayPos + SCROLL_INCREMENT, filteredChars.length - MAX_CHARS_IN_STATE),
        );
        document.getElementById(selector).scrollIntoView(false);
      }, 100);
    }
  };

  return (
    <>
      <TextField
        {...{ ref }}
        className='gsd-characteristic-dropdown'
        variant='outlined'
        size='small'
        fullWidth
        placeholder='Search for a characteristic'
        value={searchTerm}
        disabled={props.readOnly}
        onChange={event => {
          if (!props.readOnly) {
            setSearchTerm(event.target.value);

            if (anchorEl === null) {
              setAnchorEl(ref.current);
            }
          }
        }}
        onClick={() => {
          if (!props.readOnly && anchorEl === null) {
            setAnchorEl(ref.current);
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search style={{ color: '#999' }} />
            </InputAdornment>
          ),
        }}
      />

      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableAutoFocus
        disableEnforceFocus
        PaperProps={{
          id: 'CSD-Paper',
          onScroll: e => {
            const el = e.target as HTMLElement;
            if (el.scrollTop === 0) handleScrollAtTop();
            else if (el.scrollTop + el.clientHeight === el.scrollHeight) handleScrollAtBottom();
          },
          style: {
            width: `${anchorEl?.clientWidth || '100'}px`,
            maxHeight: '150px',
          },
        }}
      >
        {inStateChars?.length > 0 ? (
          MenuItems(
            inStateChars.reduce(
              (prev, curr) => [
                ...prev,
                {
                  label: curr.name,
                  value: curr.charId,
                  key: curr.draggableId,
                  disabled: props.readOnly,
                  muiProps: {
                    onClick: () => {
                      if (!props.readOnly) {
                        setSearchTerm(curr.name);
                        props.setSelectedChar(curr);
                        setAnchorEl(null);
                      }
                    },
                    id: `CSD-${curr.draggableId}`,
                  },
                },
              ],
              [],
            ),
          )
        ) : (
          <Typography className='no-matches'>No matches</Typography>
        )}
      </Popover>
    </>
  );
};
export default CustomGroupingCharacteristicDropdown;
