import axios from 'axios';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import usePrevious from '../../hooks/usePrevious';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import { NotificationLevel } from '../../shared/constants';
import { baseUrl } from '../shared/environment';
import { RefreshInterval, ServerEnable, WAClusterInfo, WAServerInfo } from './model';
import RemoteConsoleBar from './RemoteConsoleBar';
import RemoteConsoleClusterInfo from './RemoteConsoleClusterInfo';
import RemoteConsoleHeader from './RemoteConsoleHeader';
import RemoteConsoleToolbar from './RemoteConsoleToolbar';

const RemoteConsole: FC = () => {
  const [remoteConsoleInfo, setRemoteConsoleInfo] = useState<WAClusterInfo | null>(null);
  const [refreshInterval, setRefreshInterval] = useState(RefreshInterval.FAST);
  const [lastRefresh, setLastRefresh] = useState<Date | null>(null);
  const [progress, setProgress] = useState(0);
  const [offline, setOffline] = useState(false);
  const dispatch = useDispatch();

  const [selectedServers, setSelectedServers] = useState<WAServerInfo[]>([]);

  const progressTimer = useRef<NodeJS.Timeout | null>(null);

  const previous = usePrevious({ refreshInterval });

  const previousRefreshInterval = previous?.refreshInterval;

  const refreshInfo = () => {
    axios
      .get<WAClusterInfo>(`${baseUrl}api/waStats`)
      .then(response => {
        setRemoteConsoleInfo(response.data);
        setLastRefresh(new Date());
        setOffline(false);
      })
      .catch(() => setOffline(true));
    setProgress(0);
  };

  useEffect(() => {
    previousRefreshInterval !== refreshInterval && setProgress(0);
    progressTimer && clearTimeout(progressTimer.current);

    const refreshProgress = () => {
      if (progress === refreshInterval) {
        refreshInfo();
      } else {
        setProgress(progress => progress + 1);
      }
    };

    progressTimer.current = setTimeout(refreshProgress, 1000);
    return () => progressTimer?.current && clearTimeout(progressTimer.current);
  }, [refreshInterval, progress, previousRefreshInterval]);

  const toggleServers = (servers: ServerEnable[]) => {
    axios
      .post(`${baseUrl}api/enableServer`, {
        servers,
      })
      .then(() => refreshInfo())
      .catch(error =>
        dispatch(
          enqueueSnackbar(NotificationLevel.ERROR, `Warning: Could not enable server:  ${error}`),
        ),
      );
  };

  return (
    <>
      <RemoteConsoleHeader />
      <RemoteConsoleToolbar
        {...{ selectedServers, toggleServers, remoteConsoleInfo, refreshInfo }}
      />
      <RemoteConsoleBar
        {...{
          lastRefresh,
          refreshInterval,
          progress,
          offline,
          setRefreshInterval,
        }}
      />

      <div className='remote-console'>
        <RemoteConsoleClusterInfo
          {...{ remoteConsoleInfo, selectedServers, setSelectedServers, toggleServers }}
        />
      </div>
    </>
  );
};

export default RemoteConsole;
