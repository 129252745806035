import { Monaco } from '@monaco-editor/react';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  allEditorLanguages,
  createDefaultContents,
  EditorContents,
  EditorLanguage,
} from './editorLanguage';
import EditorModelService, { EditorModelApi } from './EditorModelService';
import { THEME_ID } from './theme/aggregate-theme';

export interface EditorModel<Language extends EditorLanguage = EditorLanguage> {
  id: string;
  initialize: (monacoInstance: Monaco, initialContents: EditorContents<Language>) => void;
  isInitialized: () => boolean;
  getPath: (language: EditorLanguage) => string;
  api: EditorModelApi;
}

const useEditorModel = (languages = allEditorLanguages): EditorModel => {
  const [api, setApi] = useState<EditorModelApi | null>(null);
  const [id] = useState(uuid());

  const initialize = useCallback(
    (monaco: Monaco, initialContents: EditorContents = createDefaultContents(languages)) => {
      setApi(new EditorModelService(id, monaco, languages, initialContents));

      // Must be reset for each instance
      monaco.editor.setTheme(THEME_ID);
    },
    [id, languages],
  );

  const isInitialized = useCallback(() => api !== null, [api]);

  const getPath = useCallback(
    (language: EditorLanguage) => EditorModelService.getPath(id, language),
    [id],
  );

  return useMemo(
    () => ({
      id,
      initialize,
      isInitialized,
      getPath,
      api,
    }),
    [id, initialize, isInitialized, getPath, api],
  );
};

export default useEditorModel;
