import { DialogTitle } from '@material-ui/core';

interface Props {
  title: string;
}

const UserPromptTitleComponent: React.FunctionComponent<Props> = ({ title }) => (
  <DialogTitle
    style={{
      marginBottom: '8px',
      background: 'linear-gradient(to right, #006ca3 0%, #225165 100%)',
      color: '#ffffff',
    }}
  >
    {title}
  </DialogTitle>
);

export default UserPromptTitleComponent;
