import MenuIcon from '@material-ui/icons/Menu';
import { CascadingMenu } from 'algo-react-dataviz';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMetadataSandbox } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { getMetadataSandbox } from '../../redux/metadata/selectors';
import { fetchSandboxes } from '../../redux/UserProfileActionCreators';
import { createNewSandbox } from '../../redux/WorkspaceActionCreators';
import { METADATA_SEQUENCE_ID } from '../../shared/constants';
import { createSandboxMenuItem } from '../sandboxes/sandbox-menu-items';

const MetadataMenu: FC = () => {
  const sandboxes = useSelector(
    (state: AppState) => state.user.userInfo.userPreferences?.sandboxes,
  );
  const selectedSandbox = useSelector(getMetadataSandbox);
  const dispatch = useDispatch();

  return (
    <CascadingMenu
      className='metadata-header-button'
      onOpen={() => dispatch(fetchSandboxes())}
      showMenu
      icon={<MenuIcon className='metadata-header-icon' />}
      items={[
        createSandboxMenuItem({
          menuId: 'metadata',
          disabled: !sandboxes,
          sandboxes,
          selectedSandbox,
          onCreate: () => dispatch(createNewSandbox(METADATA_SEQUENCE_ID, sandboxes.private)),
          onSelect: path => {
            dispatch(updateMetadataSandbox(path));
          },
        }),
      ]}
    />
  );
};

export default MetadataMenu;
