import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import { setSelectedWorkspaceEntities } from '../../../redux/WorkspaceActionCreators';
import EntitySelectionDrawer from './EntitySelectionDrawer';

interface BaseProps {
  open: boolean;
  close: () => void;
}

const mapStateToProps = (state: AppState) => ({
  initialSelectedEntities: state.workspace.data?.selectedEntities,
});
const mapDispatchToProps = { setSelectedWorkspaceEntities };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const WorkspaceEntitySelectionDrawer: FC<Props> = props => (
  <EntitySelectionDrawer
    open={props.open}
    close={props.close}
    initialSelectedEntities={props.initialSelectedEntities}
    onApplyClick={selectedEntities => {
      props.setSelectedWorkspaceEntities(selectedEntities);
      props.close();
    }}
  />
);

export default connector(WorkspaceEntitySelectionDrawer);
