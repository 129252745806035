import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateDefaultWorkspace } from '../../redux/ActionCreators';
import { useAppSelector } from '../../redux/configureStore';
import HomepagePortfolioDrawer from '../drawers/HomePagePortfolioDrawer';
import { countSelectedPortfolios, hasSelectedPortfolios } from '../drawers/portfolioDrawerHelpers';
import ControlledTextInput, { ControlledTextInputProps } from './ControlledTextInput';

interface Props extends Pick<ControlledTextInputProps, 'disabled' | 'variant' | 'size'> {
  hideLabel?: boolean;
}

const LABEL = 'Portfolio Selection';
const PLACEHOLDER = 'None selected';

const PortfolioSelectionInput: FC<Props> = props => {
  const dispatch = useDispatch();
  const portfolioSelection = useAppSelector(state => {
    const selection = state.user.userInfo.userPreferences?.activePortfolioSelection ?? {};

    return hasSelectedPortfolios(selection) ? selection : null;
  });
  const [portfolioDrawerOpen, setPortfolioDrawerOpen] = useState(false);

  return (
    <>
      <ControlledTextInput
        label={!props.hideLabel && LABEL}
        value={
          portfolioSelection === null
            ? PLACEHOLDER
            : `${countSelectedPortfolios(portfolioSelection)} selected`
        }
        onClick={() => {
          setPortfolioDrawerOpen(true);
        }}
        onAdornmentClick={event => {
          event.stopPropagation();
          dispatch(updateDefaultWorkspace(null));
        }}
        disabled={props.disabled}
        variant={props.variant}
        size={props.size}
      />
      <HomepagePortfolioDrawer
        open={portfolioDrawerOpen}
        closeDrawer={() => {
          setPortfolioDrawerOpen(false);
        }}
      />
    </>
  );
};

export default PortfolioSelectionInput;
