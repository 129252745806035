export enum ValidationStatus {
  PRISTINE = 'pristine',
  WAITING = 'waiting',
  IN_PROGRESS = 'in-progress',
  VALID = 'valid',
  INVALID = 'invalid',
}

export const validationStatuses = [
  ValidationStatus.PRISTINE,
  ValidationStatus.WAITING,
  ValidationStatus.IN_PROGRESS,
  ValidationStatus.VALID,
  ValidationStatus.INVALID,
];

export interface ValidationState<Result> {
  validationStatus: ValidationStatus;
  result: Result | null;
}
