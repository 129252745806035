import { IconButton, Tooltip } from '@material-ui/core';
import { CloudUpload, ControlPointDuplicate } from '@material-ui/icons';
import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { NotificationLevel } from '../../../shared/constants';
import createUploadTrigger from '../../../shared/createUploadTrigger';
import { readerUtil } from '../../report/helpers/importUtils';
import { PositionDrawerContext, PositionInstrument } from './reducer';

interface Props {
  onDuplicateClick: () => void;
}

const InstrumentsStepHeaderButtons: FC<Props> = props => {
  const dispatch = useDispatch();
  const { dispatch: localDispatch } = useContext(PositionDrawerContext);

  enum FileType {
    POSITION = 'Position,ID,Instrument,Position Units,Market Value,MV Currency',
    FUND = 'Position,FundID,Fund,Fund Units,Fund Weight',
    UNIT_BASED_BENCHMARK = 'Position,ID,Instrument,Benchmark Units',
    WEIGHT_BASED_BENCHMARK = 'Position,ID,Instrument,Benchmark Weight',
  }

  const onClick = createUploadTrigger(file => {
    if (file.type !== 'text/csv') {
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Invalid file type: ${file.type}`));
    } else {
      readerUtil(
        file,
        result => {
          const headerRow = result
            .toString()
            .split(/\r?\n/)
            .filter((_row, i) => i === 0)[0];
          const isFileType = Object.values(FileType).includes(headerRow as FileType);
          if (isFileType) {
            localDispatch({
              type: 'setSelectedInstruments',
              instruments: result
                .toString()
                .split(/\r?\n/)
                .filter((_row, i) => i !== 0)
                .map<PositionInstrument>(r => {
                  const row = r.split(',');

                  switch (headerRow) {
                    case FileType.POSITION:
                      return {
                        id: row[1],
                        name: row[2],
                        isFund: false,
                        positionUnits: readNumber(row[3]),
                        fundUnits: 0,
                        marketValue: readNumber(row[4]),
                        weight: 0,
                        fundWeight: 0,
                        currency: row[5],
                      };
                    case FileType.UNIT_BASED_BENCHMARK: {
                      return {
                        id: row[1],
                        name: row[2],
                        isFund: false,
                        positionUnits: readNumber(row[3]),
                        fundUnits: 0,
                        marketValue: 0,
                        weight: 0,
                        fundWeight: 0,
                        currency: '',
                      };
                    }
                    case FileType.WEIGHT_BASED_BENCHMARK: {
                      return {
                        id: row[1],
                        name: row[2],
                        isFund: false,
                        positionUnits: 0,
                        fundUnits: 0,
                        marketValue: 0,
                        weight: readNumber(row[3]),
                        fundWeight: 0,
                        currency: '',
                      };
                    }
                    case FileType.FUND: {
                      return {
                        id: row[1],
                        name: row[2],
                        isFund: true,
                        positionUnits: 0,
                        fundUnits: readNumber(row[3]),
                        marketValue: 0,
                        weight: 0,
                        fundWeight: readNumber(row[4]),
                        currency: '',
                      };
                    }
                  }

                  // Should never get here because isFileType is true meaning that one of the cases will be matched.
                  return null;
                }),
            });
          } else {
            dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Bad file format`));
          }
        },
        e => dispatch(enqueueSnackbar(NotificationLevel.ERROR, `Error importing file: ${e}`)),
      );
    }
  });

  return (
    <>
      <Tooltip title='Duplicate Positions'>
        <IconButton onClick={props.onDuplicateClick}>
          <ControlPointDuplicate />
        </IconButton>
      </Tooltip>

      <Tooltip title='File Upload'>
        <IconButton {...{ onClick }}>
          <CloudUpload />
        </IconButton>
      </Tooltip>
    </>
  );
};

const readNumber = (csvCell: string): number | undefined =>
  csvCell || csvCell === '0' ? Number(csvCell) : undefined;

export default InstrumentsStepHeaderButtons;
