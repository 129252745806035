import { FC } from 'react';
import { TimeGrouping } from './EditTimeBucketModal';

interface Props {
  timeGrouping: TimeGrouping;
  onChange: (timeGrouping: TimeGrouping) => void;
}

const TimestepGroupingSpec: FC<Props> = props => {
  const { left, right, leftInclusive, rightInclusive } = props.timeGrouping;
  const leftSymbol = leftInclusive ? '[' : '(';
  const rightSymbol = rightInclusive ? ']' : ')';
  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => props.onChange({ ...props.timeGrouping, leftInclusive: !leftInclusive })}
      >
        {leftSymbol}
      </div>
      <div className='range'>
        <div>{left}</div>
        <div>-</div>
        <div>{right}</div>
      </div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => props.onChange({ ...props.timeGrouping, rightInclusive: !rightInclusive })}
      >
        {rightSymbol}
      </div>
    </>
  );
};

export default TimestepGroupingSpec;
