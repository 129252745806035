import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Category as CategoryIcon, Edit as EditIcon, Label as LabelIcon } from '@material-ui/icons';
import { CascadingMenuItem } from 'algo-react-dataviz';
import { FC, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { CustomGroupingDto, transformFromDto } from '../../../model/custom-grouping/customGrouping';
import useCustomGroupingApi from '../../../model/custom-grouping/useCustomGroupingApi';
import { enqueueSnackbar, setCharToNickname } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { setGroupingForSave } from '../../../redux/custom-grouping/actions';
import { canUserEditGrouping } from '../../../redux/custom-grouping/selectors';
import { setSelectedChar } from '../../../redux/metadata/actions';
import { clearNickname } from '../../../redux/ReportActionCreators';
import { DESIGNER_SEQUENCE_ID } from '../../../shared/constants';
import { Characteristic } from '../../../shared/dataTypes';
import OverflowIconMenu from '../../../shared/OverflowIconMenu';
import { isCharCustomGrouping, supportsDiscreteBreakpoints } from '../../../shared/utils';
import { customGrouperImpls } from '../customGrouperImpls';
import CustomGroupingEditButton from '../CustomGroupingEditButton';
import FavouriteButton, { draggableIconStyles } from '../FavouriteButton';
import DraggableFilter from '../ui-elements/DraggableFilters/DraggableFilter';
import ModifierChip from '../ui-elements/ModifierChip';
import { CHARACTERISTICS_ID, FILTER_CHARS } from './beautifulDndHelpers';
import './draggable-element.scss';
import { GroupingLayerId } from './groupingLayerId';

interface BaseProps {
  isDragging: boolean;
  isOnMenu: boolean;
  droppableId?: string;
  direction: 'horizontal' | 'vertical';
  innerRef?: (element?: HTMLElement | null) => any;
  remove?: () => void;
  editor?: (target: EventTarget) => void;

  // TODO: This type is wrong -- it's a `Characteristic` for an element
  // on the Characteristic list, but it's a `LayerDefinition` for a
  // chip on horizontal/vertical grouping.
  // (cf. SD-2534, SD-2545)
  char: Characteristic; // TODO: SD-2534
}

const mapStateToProps = (state: AppState, props: BaseProps) => {
  return {
    nickname:
      props.droppableId === CHARACTERISTICS_ID &&
      state.report.reportDefinition[DESIGNER_SEQUENCE_ID]?.chars?.find(
        c => c.charId === props.char.charId && c.modifier === props.char.modifier,
      )?.nickname,
    canEditCustomGrouping:
      canUserEditGrouping(state)(props.char.owner ?? null) &&
      canUserEditGrouping(state)(props.char.customGrouping?.owner ?? null),
    allowMetadata: state.user.userComponentPermission.allowMetadata,
  };
};
const mapDispatchToProps = {
  setCharToNickname,
  clearNickname,
  setSelectedChar,
  enqueueSnackbar,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const DraggableElement: FC<Props> = props => {
  const [hover, setHover] = useState(false);
  const showViewAction = !!props.char.valueKey;
  const showRemoveAction = !props.isOnMenu;
  const showNicknameActions = props.droppableId === CHARACTERISTICS_ID;
  const isCustomGrouping = isCharCustomGrouping(props.char);
  const { retrieveCustomGrouping } = useCustomGroupingApi();
  const dispatch = useDispatch();

  const cascadingMenuItems: CascadingMenuItem[] = [
    ...(showViewAction
      ? [
          {
            id: 'View Metadata',
            text: 'View Metadata',
            onClick: () => props.setSelectedChar(props.char.valueKey),
            disabled: !props.allowMetadata,
          },
        ]
      : []),
    ...(showRemoveAction
      ? [
          {
            id: 'Remove',
            text: 'Remove',
            onClick: props.remove,
            disabled: props.char.charId === GroupingLayerId.CHARACTERISTIC,
          },
        ]
      : []),
    ...(showNicknameActions
      ? [
          {
            id: 'Set Nickname',
            text: 'Set Nickname',
            onClick: () => props.setCharToNickname(props.char),
            disabled: false,
          },
          {
            id: 'Clear Nickname',
            text: 'Clear Nickname',
            onClick: () => props.clearNickname(props.char.charId, props.char.modifier),
            disabled: !props.nickname,
          },
        ]
      : []),
    ...(isCustomGrouping
      ? [
          {
            id: 'Save As',
            text: 'Save As',
            onClick: () => {
              const customGrouping = props.char.customGrouping;
              // this logic is copied over from CustomGroupingEditButton but slightly altered
              if (customGrouping && 'rootNode' in customGrouping && customGrouping.rootNode) {
                // Ad hoc custom grouping is already part of the char - no rest call needed
                dispatch(setGroupingForSave(customGrouping));
              } else if (customGrouping?.name === null) {
                dispatch(setGroupingForSave(transformFromDto(customGrouping as CustomGroupingDto)));
              } else {
                retrieveCustomGrouping(props.char.id).then(retrievedGrouping => {
                  if (retrievedGrouping === null) {
                    return;
                  }
                  dispatch(setGroupingForSave(retrievedGrouping));
                });
              }
            },
            disabled: false,
          },
        ]
      : []),
  ];

  // TODO include types for all the extra props that react-beautiful-dnd passes into this
  // component and have to be passed down to the div below. (they're in this rest object)
  const {
    isDragging,
    isOnMenu,
    droppableId,
    direction,
    innerRef,
    remove,
    editor,
    char,
    setCharToNickname,
    nickname,
    clearNickname,
    setSelectedChar,

    // Don't spread these props onto an HTML element
    canEditCustomGrouping,
    enqueueSnackbar,

    ...rest
  } = props;

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={props.innerRef}
      className={`draggable-element ${getClassName(
        hover,
        isDragging,
        isOnMenu,
        direction,
        droppableId === FILTER_CHARS,
        isCustomGrouping,
      )}`}
      {...rest}
    >
      {props.droppableId === FILTER_CHARS ? (
        <DraggableFilter char={props.char} />
      ) : (
        <Tooltip enterDelay={1000} title={props.char.name}>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              padding: '3px',
              overflow: 'hidden',
            }}
          >
            {props.nickname && <LabelIcon />}
            {isCustomGrouping && <CategoryIcon style={{ margin: '0 2px 0 4px' }} />}
            <Typography
              className='draggable-element-label'
              variant='body2'
              style={{
                flex: 1,
                overflow: 'hidden',
                padding: '3px',
                color: '#555550',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {props.nickname ||
                props.char.name ||
                (isCustomGrouping ? 'Untitled Custom Grouping' : 'Untitled')}
            </Typography>
            <ModifierChip modifier={props.char.modifier} />
            {isCustomGrouping && (
              <CustomGroupingEditButton
                hover={hover}
                id={props.char.id}
                customGrouping={props.char.customGrouping} //TODO SD-2534
                canEditCustomGrouping={props.canEditCustomGrouping}
                owner={props.char.owner}
              />
            )}
            {props.isOnMenu && (
              <FavouriteButton
                hover={hover}
                id={props.char.charId}
                modifier={props.char.modifier}
                {...(isCustomGrouping ? { name: props.char.name } : {})}
              />
            )}
            <>
              {props.editor &&
              (customGrouperImpls[props.char.grouperEditor] ||
                supportsDiscreteBreakpoints(props.char.dataType)) ? (
                <IconButton
                  style={{ opacity: hover ? 1 : 0 }}
                  aria-label='GrouperEditor'
                  onClick={e => props.editor(e.currentTarget)}
                  size='small'
                >
                  <EditIcon style={draggableIconStyles} />
                </IconButton>
              ) : null}
              <OverflowIconMenu items={cascadingMenuItems} parentHover={hover} />
            </>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

// TODO: `clsx` package (cf. SD-2523)
const getClassName = (
  hover: boolean,
  isDragging: boolean,
  isOnMenu: boolean,
  direction: 'horizontal' | 'vertical',
  isOnFilters?: boolean,
  isCustomGroupingChip?: boolean,
) => {
  if (isOnFilters) {
    return 'filter';
  }
  if (isDragging || !isOnMenu) {
    // Item is being dragged or has been dragged.
    return `blue ${direction || 'vertical'} ${isCustomGroupingChip ? 'custom-grouping' : ''}`;
  } else {
    // Item is on menu.
    return `on-menu ${hover ? 'blue' : ''} ${isCustomGroupingChip ? 'custom-grouping' : ''}`;
  }
};

export default connector(DraggableElement);
