import {
  IconButton,
  Slide,
  Toolbar as WorkspaceToolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import { AccountTree, Add, FolderOpen, Menu } from '@material-ui/icons';
import { CascadingMenu } from 'algo-react-dataviz';
import equal from 'deep-equal';
import { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { openFolderDrawer } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import { intendChangeWorkspace } from '../../../redux/ui/actionCreators';
import { ChangeWorkspaceModalStatus } from '../../../redux/ui/changeWorkspaceModalStatus';
import { defaultWorkspace } from '../../../redux/workspace';
import { saveWorkspace } from '../../../redux/WorkspaceActionCreators';
import { DrawerType } from '../../../shared/constants';
import createUploadTrigger from '../../../shared/createUploadTrigger';
import { hasSaccr, hasSaccrOnly, showPortfolioSelector } from '../../../shared/utils';
import CurrencyDropdown from '../../currency-dropdown/CurrencyDropdown';
import BenchmarkPortfolioDrawer from '../../drawers/BenchmarkPortfolioDrawer';
import ReportEntitySelectionDrawer from '../../drawers/entity-selection-drawer/ReportEntitySelectionDrawer';
import WorkspaceEntitySelectionDrawer from '../../drawers/entity-selection-drawer/WorkspaceEntitySelectionDrawer';
import PositionDrawer from '../../drawers/position-drawer/PositionDrawer';
import ProxyDrawer from '../../drawers/proxy-drawer/ProxyDrawer';
import ReportPortfolioDrawer from '../../drawers/ReportPortfolioDrawer';
import WorkspacePortfolioDrawer from '../../drawers/WorkspacePortfolioDrawer';
import WorkspaceDatePicker from '../../report-dates/WorkspaceDatePicker';
import { copyWorkspaceDefToJson, exportWorkspaceDefToJson } from '../../report/helpers/exportUtils';
import {
  importReportData,
  importReportDataFromClipboard,
  importWorkspaceData,
  importWorkspaceDataFromClipboard,
} from '../../report/helpers/importUtils';
import './toolbar.scss';

const mapDispatchToProps = {
  openFolderDrawer,
  saveWorkspace,
  intendChangeWorkspace,
  exportWorkspaceDefToJson,
  importWorkspaceData,
  importReportData,
  importReportDataFromClipboard,
  copyWorkspaceDefToJson,
  importWorkspaceDataFromClipboard,
};

const mapStateToProps = (state: AppState) => ({
  hasOpenWorkspace: !equal(state.workspace.data, defaultWorkspace),
  workspacePath: state.workspace.data?.path,

  showPortfolioSelector: showPortfolioSelector(state),
  hasSaccr: hasSaccr(state),
  hasSaccrOnly: hasSaccrOnly(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Toolbar: React.FC<Props> = props => {
  const [portfolioDrawerOpen, setPortfolioDrawerOpen] = useState(false);
  const [entitySelectionDrawerOpen, setEntitySelectionDrawerOpen] = useState(false);

  const uploadWorkspaceFile = createUploadTrigger(props.importWorkspaceData);
  const uploadReportFile = createUploadTrigger(props.importReportData);

  const trigger = useScrollTrigger();

  return (
    <>
      <Slide appear={false} direction='down' in={!trigger}>
        <WorkspaceToolbar className='toolbar'>
          <div className='toolbar-group'>
            <CascadingMenu
              showMenu
              className='toolbar-menu-icon'
              icon={<Menu className='button-style' />}
              items={[
                {
                  id: 'OpenWorkspace',
                  text: 'Open Workspace',
                  onClick: () => props.intendChangeWorkspace(ChangeWorkspaceModalStatus.OPEN),
                },
                {
                  id: 'CloseWorkspace',
                  text: 'Close Workspace',
                  onClick: () => props.intendChangeWorkspace(ChangeWorkspaceModalStatus.CLOSE),
                  disabled: !props.hasOpenWorkspace,
                },
                {
                  id: 'SaveWorkspace',
                  text: 'Save Workspace',
                  onClick: () => {
                    if (!props.workspacePath) {
                      props.openFolderDrawer(DrawerType.SAVE_WORKSPACE);
                    } else {
                      props.saveWorkspace(encodeURIComponent(props.workspacePath), 'put');
                    }
                  },
                },
                {
                  id: 'SaveWorkspaceAs',
                  text: 'Save Workspace As',
                  onClick: () => props.openFolderDrawer(DrawerType.SAVE_WORKSPACE),
                },
                {
                  id: 'Import/Export',
                  text: 'Import/Export Workspace',
                  children: [
                    {
                      id: 'ExportWorkspace',
                      text: 'Export to File',
                      onClick: props.exportWorkspaceDefToJson,
                      disabled: !props.hasOpenWorkspace,
                    },
                    {
                      id: 'ImportWorkspace',
                      text: 'Import from File',
                      onClick: uploadWorkspaceFile,
                    },
                    {
                      id: 'CopyWorkspace',
                      text: 'Copy to Clipboard',
                      onClick: props.copyWorkspaceDefToJson,
                      disabled: !navigator.clipboard || !props.hasOpenWorkspace,
                    },
                    {
                      id: 'PasteWorkspace',
                      text: 'Paste from Clipboard',
                      onClick: props.importWorkspaceDataFromClipboard,
                      disabled: !navigator.clipboard,
                    },
                  ],
                },
              ]}
            />
            <Typography variant='h1' className='workspace-path'>
              {props.workspacePath
                ? decodeURIComponent(
                    props.workspacePath
                      ?.split('/')
                      .pop()
                      .replace(/%/g, '%25')
                      .replace(/\+/g, '%20'),
                  )
                : ''}
            </Typography>
          </div>
          <div className='toolbar-group'>
            <WorkspaceDatePicker />

            <CurrencyDropdown className='workspace-toolbar-currency-dropdown' />

            {props.showPortfolioSelector && (
              <IconButton
                onClick={() => setPortfolioDrawerOpen(!portfolioDrawerOpen)}
                className='button-zero-padding'
              >
                <FolderOpen className='button-style' />
              </IconButton>
            )}

            {props.hasSaccr && (
              <IconButton
                onClick={() => setEntitySelectionDrawerOpen(true)}
                className='button-zero-padding'
              >
                <AccountTree className='button-style' />
              </IconButton>
            )}
            <CascadingMenu
              showMenu
              className='toolbar-menu-icon'
              icon={<Add className='button-style' />}
              items={[
                {
                  id: 'OpenReport',
                  text: 'Open Report',
                  onClick: () => props.openFolderDrawer(DrawerType.OPEN_REPORT_IN_WORKSPACE),
                },
                {
                  id: 'ImportReport',
                  text: 'Import from File',
                  onClick: uploadReportFile,
                },
                {
                  id: 'PasteReport',
                  text: 'Paste from Clipboard',
                  onClick: props.importReportDataFromClipboard,
                  disabled: !navigator.clipboard,
                },
              ]}
            />
          </div>
        </WorkspaceToolbar>
      </Slide>

      <WorkspacePortfolioDrawer
        open={portfolioDrawerOpen}
        close={() => setPortfolioDrawerOpen(false)}
      />
      <WorkspaceEntitySelectionDrawer
        open={entitySelectionDrawerOpen}
        close={() => setEntitySelectionDrawerOpen(false)}
      />
      <ReportPortfolioDrawer />
      <ReportEntitySelectionDrawer />
      <BenchmarkPortfolioDrawer />
      <ProxyDrawer />
      <PositionDrawer />
    </>
  );
};

export default connector(Toolbar);
