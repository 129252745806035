import { AppState } from '../configureStore';

export const getEditorInterfaceType = (state: AppState) => state.aScriptEditor.interfaceType;
export const getSuperclass = (state: AppState) => state.aScriptEditor.superclassName;
export const getInitialContents = (state: AppState) => state.aScriptEditor.initialContents;

// this is not final, just a place holder for getting the classname
export const getClassName = (state: AppState) => state.aScriptEditor.className;

export const isEditorOpen = (state: AppState) => state.aScriptEditor.interfaceType !== null;
export const getFormProps = (state: AppState) => state.aScriptEditor.formProps;
