import { InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { requestCreateUserLike, setCreateUserDrawerOpen } from '../../redux/adminActionCreators';
import { AppState } from '../../redux/configureStore';
import AdminDrawer from './AdminDrawer';

const mapStateToProps = (state: AppState) => ({ open: state.admin.createUserDrawerOpen });
const mapDispatchToProps = { requestCreateUserLike, setCreateUserDrawerOpen };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const CreateNewUser: FC<Props> = props => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPass, setShowPass] = useState(false);
  const [showRetypePass, setShowRetypePass] = useState(false);

  const { open } = props;
  useEffect(() => {
    setFirstName('');
    setLastName('');
    setUserName('');
    setPassword1('');
    setPassword2('');
    setShowPass(false);
    setShowRetypePass(false);
  }, [open]);

  return (
    <AdminDrawer
      open={props.open}
      setOpen={props.setCreateUserDrawerOpen}
      title='Create New User'
      buttonText='Create User'
      onApplyClick={() => props.requestCreateUserLike(userName, firstName, lastName, password1)}
      valid={
        firstName.length > 0 &&
        lastName.length > 0 &&
        userName.length > 0 &&
        password1.length > 0 &&
        password1 === password2
      }
    >
      {[
        { label: 'First Name', value: firstName, onChange: setFirstName },
        { label: 'Last Name', value: lastName, onChange: setLastName },
        { label: 'Username', value: userName, onChange: setUserName },
        {
          label: 'Password',
          value: password1,
          onChange: setPassword1,
          type: showPass ? 'text' : 'password',
          adornmentChange: setShowPass,
          showAdornmentValue: showPass,
        },
        {
          label: 'Re-Type Password',
          value: password2,
          onChange: setPassword2,
          type: showRetypePass ? 'text' : 'password',
          adornmentChange: setShowRetypePass,
          showAdornmentValue: showRetypePass,
          error: password1 !== password2,
          helperText: password1 !== password2 ? 'Password fields do not match' : undefined,
        },
      ].map((k, i) => (
        <TextField
          key={i}
          label={k.label}
          value={k.value}
          type={k.type}
          error={k.error}
          helperText={k.helperText}
          onChange={e => {
            k.onChange(e.target.value);
          }}
          variant='outlined'
          size='small'
          fullWidth
          style={{ marginBottom: '16px' }}
          autoComplete='new-password'
          InputProps={
            k.adornmentChange
              ? {
                  endAdornment: (
                    <InputAdornment
                      position='start'
                      onClick={() => k.adornmentChange(!k.showAdornmentValue)}
                      style={{ cursor: 'pointer' }}
                    >
                      {k.showAdornmentValue ? (
                        <VisibilityOff color='disabled' />
                      ) : (
                        <Visibility color='disabled' />
                      )}
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        />
      ))}
    </AdminDrawer>
  );
};

export default connector(CreateNewUser);
