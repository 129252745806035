import { FullDateContextItem } from 'algo-react-dataviz';
import { AppState } from '../configureStore';

// Position Drawer requires a single FullDateContextItem. This function gets that from
// the report definition, using the latest if there are multiple.
export const getPositionDrawerReportContext = (state: AppState): FullDateContextItem => {
  const { dateContext } =
    state.report.reportDefinition[state.drawers.positionDrawer.sequenceId] || {};

  if (!dateContext) return;

  switch (dateContext.type) {
    case 'default':
      return getDefaultContext(state);
    case 'asOf':
      return dateContext.dates[0];
    case 'between':
      return dateContext.dates[1];
    case 'specific':
      return {
        date: dateContext.dates.reduce(
          (acc, cur) => (cur.date > acc ? cur.date : acc),
          '0000-00-00',
        ),
      };
  }
};

export const getDefaultContext = (state: AppState) => state.user.selectedDateContext;
