import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setupLoggedInUser } from '../../../redux/UserProfileActionCreators';
import AuthService from './AuthService';

const AuthContext = React.createContext<AuthService>(null);
export const AuthConsumer = AuthContext.Consumer;

const mapDispatchToProps = { setupLoggedInUser };
const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const AuthProvider: FC<Props> = props => {
  const [authService, setAuthService] = useState<AuthService>();

  const { setupLoggedInUser } = props;

  useEffect(() => setAuthService(new AuthService(setupLoggedInUser)), [setupLoggedInUser]);

  return authService ? (
    <AuthContext.Provider value={authService}>{props.children}</AuthContext.Provider>
  ) : null;
};

export default connector(AuthProvider);
