import { GroupingStrategyType } from '../../../model/custom-grouping/groupingStrategy';
import useBooleanValidation from '../../../shared/validation/useBooleanValidation';
import { StrategyDesignerViewState } from './viewState';

const validate = async (viewState: StrategyDesignerViewState) => {
  switch (viewState.type) {
    case GroupingStrategyType.PREDICATE: {
      return true;
    }

    case GroupingStrategyType.CHARACTERISTIC: {
      return true;
    }

    case GroupingStrategyType.REMAINDER: {
      return true;
    }
  }
};

// TODO: No longer necessary; remove with `useReducer` refactor
// const DEBOUNCE_DELAY = 0; // ms // TODO: shorten
const useStrategyDesignerValidation = () =>
  useBooleanValidation({
    validate,
    // debounceDelay: DEBOUNCE_DELAY,
  });

export default useStrategyDesignerValidation;
