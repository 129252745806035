import axios from 'axios';
import {
  CharacteristicDrawerForm,
  createInitialRiskComputationForm,
} from '../../components/metadata/characteristic-drawer/characteristicDrawerForm';
import { baseUrl } from '../../components/shared/environment';
import { NotificationLevel } from '../../shared/constants';
import { enqueueSnackbar } from '../ActionCreators';
import { AppThunk } from '../configureStore';
import { getMetadataSandboxPath } from './selectors';

export const saveMetadata = (
  values: CharacteristicDrawerForm,
  method: 'POST' | 'PUT',
): AppThunk => (dispatch, getState) => {
  const { date, id: runId } = getState().user.selectedDateContext || {};

  // pull out risk computation keys and insert them into a single object
  const riskContextKeys = Object.keys(createInitialRiskComputationForm());
  const metadata: any = Object.entries<CharacteristicDrawerForm[keyof CharacteristicDrawerForm]>(
    values as {},
  ).reduce(
    (obj, [key, value]) =>
      riskContextKeys.includes(key)
        ? {
            ...obj,
            computeContext: {
              ...obj.computeContext,
              [key]: value,
            },
          }
        : {
            ...obj,
            [key]: value,
          },
    { computeContext: {} },
  );

  axios({
    method,
    url: `${baseUrl}api/metadata`,
    data: [
      ['false', 'true'].includes(metadata.bucketOrderDescending)
        ? { ...metadata, bucketOrderDescending: metadata.bucketOrderDescending === 'true' }
        : metadata,
    ],
    params: {
      date,
      runId,
      sandboxPath: getMetadataSandboxPath(getState()),
    },
  }).catch((error: Error) =>
    dispatch(
      enqueueSnackbar(
        NotificationLevel.ERROR,
        `Failed to apply metadata properties: ${error.message}`,
      ),
    ),
  );
};
