export type Comparator<T> = (a: T, b: T) => number;

const createTrivialComparator = <T>(): Comparator<T> => (_a, _b) => 0;

export const chainComparators = <T>(...comparators: Comparator<T>[]): Comparator<T> =>
  comparators.reduceRight(
    (acc, next) => (a, b) => next(a, b) || acc(a, b),
    createTrivialComparator<T>(),
  );

export const compareStrings: Comparator<string> = (a, b) => (a > b ? 1 : a < b ? -1 : 0);
