import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { NoteAdd } from '@material-ui/icons';
import { RoundedButton } from 'algo-react-dataviz';
import axios from 'axios';
import { FC, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { enqueueSnackbar, getErrorMessage } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { NotificationLevel } from '../../shared/constants';
import { baseUrl } from '../shared/environment';

interface BaseProps {
  reload: () => void;
  variant?: 'text' | 'outlined' | 'contained';
}

const mapStateToProps = (state: AppState) => ({
  allowManageAdhoc: state.user?.userComponentPermission?.allowManageAdhoc,
});

const mapDispatchToProps = { enqueueSnackbar };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const AdHocPortfolioUploadButton: FC<Props> = props => {
  const inputFile = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [filename, setFilename] = useState<string>('');

  return props.allowManageAdhoc ? (
    <>
      <RoundedButton
        variant={props.variant ?? 'contained'}
        color='primary'
        size='small'
        className='upload-portfolio'
        onClick={() => inputFile.current.click()}
      >
        <NoteAdd />
        Upload
      </RoundedButton>

      <input
        type='file'
        ref={inputFile}
        hidden
        onChange={e => setSelectedFile(e.target?.files[0])}
      />

      <Dialog open={!!selectedFile} className='ad-hoc-portfolio-drawer-dialog'>
        <DialogTitle>Upload Ad Hoc Portfolio</DialogTitle>

        <DialogContent>
          <TextField
            variant='outlined'
            fullWidth
            label='Portfolio Name'
            size='small'
            value={filename}
            onChange={e => setFilename(e.target.value)}
          />
          <div className='selected-file-name'>{selectedFile?.name}</div>
        </DialogContent>

        <DialogActions>
          <RoundedButton
            onClick={() => setSelectedFile(undefined)}
            color='primary'
            variant='outlined'
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            color='primary'
            variant='contained'
            disabled={!filename}
            onClick={() => {
              const formData = new FormData();
              formData.append('File', selectedFile);
              formData.append('portfolioName', filename);

              axios
                .post(`${baseUrl}api/uploadPortfolio`, formData)
                .catch(e => props.enqueueSnackbar(NotificationLevel.ERROR, getErrorMessage(e)))
                .finally(props.reload);

              setSelectedFile(undefined);
            }}
          >
            OK
          </RoundedButton>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default connector(AdHocPortfolioUploadButton);
