import { Modal } from '@material-ui/core';
import { CSSProperties, FC } from 'react';

interface Props {
  isOpen: boolean;
  close: () => void;
  style?: CSSProperties;
  className?: string;
}

const FullScreenModal: FC<Props> = props => (
  <Modal
    open={props.isOpen}
    onEscapeKeyDown={props.close}
    disableBackdropClick={true}
    BackdropProps={{ invisible: true }}
    className={props.className}
    style={{ zIndex: 1299 }}
  >
    <div
      style={{
        height: 'calc(100% - 56px)',
        width: 'calc(100% - 56px)',
        left: '56px',
        backgroundColor: '#f7f5f7',
        position: 'absolute',
        top: '56px',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  </Modal>
);

export default FullScreenModal;
