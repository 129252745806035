import { Client, StompConfig } from '@stomp/stompjs';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { Action, AnyAction, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  devEnvironment,
  publishCSVTopic,
  publishEditTopic,
  publishTopic,
  wsBaseUrl,
  WS_MAX_PENDING_REQUESTS,
} from '../components/shared/environment';
import {
  CancelReportGeneration,
  ChangeReportSequenceIdRequest,
  CSVRequest,
  QueuedMessage,
  ReportEditRequest,
  ReportPrecisionUpdateRequest,
  ReportReleaseRequest,
  ReportRequest,
  ReportSortRequest,
  ReportNicknameRequest,
  EditRequest,
} from '../shared/dataTypes';
import { clientUuid } from '../shared/utils';
import * as ActionTypes from './ActionTypes';
import admin from './admin';
import aScriptEditorReducer from './aScriptEditor/reducer';
import { benchmarkPortfolioDrawer } from './benchmarkPortfolioDrawer';
import { benchmarks } from './benchmarks';
import customGroupingReducer from './custom-grouping/reducer';
import designerPanelReducer from './designer/panel/reducer';
import { designerWorkspacePayload } from './designerWorkspacePayload';
import { folderDrawer } from './folderDrawer';
import metadataReducer from './metadata/reducer';
import { notifications } from './notifications';
import positionReducer from './position-drawer/reducer';
import progressReducer from './progress/reducer';
import { proxyDrawer } from './proxyDrawer';
import { report } from './report';
import { reportEntitiesDrawer } from './reportEntitiesDrawer';
import { reportPortfolioDrawer } from './reportPortfolioDrawer';
import wsSagas from './reportSaga';
import { scenariosConfig } from './scenariosConfig';
import { settings } from './settings';
import { sidePanel } from './sidePanel';
import uiReducer from './ui/reducer';
import { user } from './user';
import { workspace } from './workspace';

export const sagaMiddleware = createSagaMiddleware();

let sock: Client = null;

const WS_SEND_RETRIES = 5;
const WS_SEND_BACKOFF = 100;

const queuedMessages: QueuedMessage[] = [];

export const sendStompMessage = (
  message:
    | ReportRequest
    | EditRequest
    | ReportReleaseRequest
    | ChangeReportSequenceIdRequest
    | ReportSortRequest
    | ReportPrecisionUpdateRequest
    | ReportNicknameRequest
    | CancelReportGeneration,
  tk: string,
  numPending?: number,
) =>
  new Promise<void>((resolve, reject) => {
    if (numPending && numPending > WS_MAX_PENDING_REQUESTS) {
      // more than max pending messages in flight. queue this one up.
      queuedMessages.push({ message, resolve, reject });
    } else {
      // less than max pending messages in flight. try to send out immediately.
      // not clear if the try catch here is necessary (it boils down to how sock.publish works in case of error)
      // two-zero-four-five filed to follow-up on this
      try {
        const topic = message.requestType === 'edit' ? publishEditTopic : publishTopic;

        sendWSMessage(`${topic}${clientUuid}`, message, tk);
        resolve();
      } catch (e) {
        reject(e);
      }
    }
  });

export const sendCSVWSMessage = (
  message: CSVRequest,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  sendWSMessage(`${publishCSVTopic}${clientUuid}`, message, tk, retriesLeft);
};

export const sendEditMessage = (
  message: EditRequest,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  sendWSMessage(`${publishEditTopic}${clientUuid}`, message, tk, retriesLeft);
};

const sendWSMessage = (
  destination: string,
  message:
    | ReportRequest
    | ReportEditRequest
    | ReportReleaseRequest
    | ChangeReportSequenceIdRequest
    | ReportSortRequest
    | ReportPrecisionUpdateRequest
    | ReportNicknameRequest
    | EditRequest
    | CSVRequest
    | CancelReportGeneration,
  tk: string,
  retriesLeft = WS_SEND_RETRIES,
) => {
  if (sock.connected) {
    sock.publish({
      destination,
      body: JSON.stringify({ ...message, token: tk }),
    });
  } else {
    console.warn('Socket not connected');
    if (retriesLeft > 0) {
      const backoffDuration = WS_SEND_BACKOFF * (WS_SEND_RETRIES - retriesLeft + 1);
      console.warn(`Will retry in ${backoffDuration}ms ...`);
      window.setTimeout(() => {
        sendWSMessage(destination, message, tk, --retriesLeft);
      }, backoffDuration);
    } else {
      console.error('Socket disconnected when trying to send message');
    }
  }
};

export const sendNextQueuedMessage = (tk: string) => {
  const queuedMsg: QueuedMessage = queuedMessages.shift();

  if (queuedMsg) {
    // not clear if the try catch here is necessary (it boils down to how sock.publish works in case of error)
    // two-zero-four-five filed to follow-up on this
    try {
      sendWSMessage(`${publishTopic}${clientUuid}`, queuedMsg.message, tk);
      queuedMsg.resolve();
    } catch (e) {
      queuedMsg.reject(e);
    }
  }
};

export const connectWS = () => {
  const stompConfig: StompConfig = {
    brokerURL: `${wsBaseUrl}stomp-backstage`,
    // connectHeaders: {
    //   Authentication: `Bearer ${localStorage.getItem('token')}`
    // },
    // debug: function(str) {
    //   console.log(str);
    // },
    reconnectDelay: 5000,
    heartbeatIncoming: 10000,
    heartbeatOutgoing: 0,
  };

  // first make sure the socket is not already connected
  // if it is, deactivate it first
  if (sock && sock.active) {
    sock.deactivate();
  }

  sock = new Client(stompConfig);
  sagaMiddleware.run(wsSagas(sock));
};

export const disconnectWS = () => {
  if (sock?.active) {
    sock.deactivate();
  } else {
    console.warn('Socket alread inactive when tried to disconnect');
  }
};

const appReducer = combineReducers({
  admin,
  drawers: combineReducers({
    folderDrawer,
    benchmarkPortfolioDrawer,
    reportPortfolioDrawer,
    reportEntitiesDrawer,
    proxyDrawer,
    positionDrawer: positionReducer,
  }),
  workspace,
  report,
  reportDesigner: combineReducers({
    panelControl: designerPanelReducer,
    scenariosConfig,
    settings,
    benchmarks,
    designerWorkspacePayload,
  }),
  sidePanel,
  user,
  notifications,
  metadata: metadataReducer,
  progress: progressReducer,
  ui: uiReducer,
  aScriptEditor: aScriptEditorReducer,
  customGrouping: customGroupingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ActionTypes.USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

/*
By adding actionSanitizer and stateSanitizer, we clear out large payloads from redux tools since it is slowing development.
More info below:
Application state or actions payloads are too large making Redux DevTools serialization slow and consuming a lot of memory.
See https://git.io/fpcP5 on how to configure it.
*/

const actionSanitizer = (action: AnyAction) => {
  return action.payload?.data
    ? { ...action, payload: { data: '<<LARGE_RESPONSE_OBJECT>>' } }
    : action;
};

const stateSanitizer = (state: AppState) =>
  state.report.reportData
    ? { ...state, report: { reportData: '<<LARGE_RESPONSE_OBJECT>>' } }
    : state;

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export const ConfigureStore = () => {
  const composeEnhancers =
    (reduxDevTools && reduxDevTools({ actionSanitizer, stateSanitizer })) || compose;
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware, ...(devEnvironment ? [logger] : []))),
  );
};

export type AppState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export type AppThunkDispatch = ThunkDispatch<AppState, undefined, Action>;
