import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  checkAllGroupMembers,
  requestAddUsersToSelectedGroup,
  requestRemoveUsersFromSelectedGroup,
} from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';
import { User } from '../../../shared/dataTypes';
import AdminTable from '../AdminTable';

const mapStateToProps = (state: AppState) => ({
  users: state.admin.users,
  selectedGroup: state.admin.selectedGroup,
});
const mapDispatchToProps = {
  requestAddUsersToSelectedGroup,
  requestRemoveUsersFromSelectedGroup,
  checkAllGroupMembers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const GroupMembersPanel: FC<Props> = props => {
  const allChecked = !props.users?.some(u => !u.groups.includes(props.selectedGroup));

  return (
    <AdminTable
      columns={[{ key: 'userName' }]}
      data={props.users?.sort(sortFunction).map(u => ({
        userName: u.userName,
        checked: u.groups.includes(props.selectedGroup),
        onChange: (checked: boolean) =>
          checked
            ? props.requestAddUsersToSelectedGroup([u.userName])
            : props.requestRemoveUsersFromSelectedGroup([u.userName]),
      }))}
      checkAll={() => {
        const userNames = props.users.map(u => u.userName);
        if (allChecked) props.requestRemoveUsersFromSelectedGroup(userNames);
        else props.requestAddUsersToSelectedGroup(userNames);
      }}
      checkAllChecked={allChecked}
    />
  );
};

const sortFunction = (a: User, b: User) =>
  a.includedGroupWhenSelected && !b.includedGroupWhenSelected ? -1 : 1;

export default connector(GroupMembersPanel);
