import { useCallback, useEffect, useMemo } from 'react';
import useKeyCompletion from '../ascript/completion-providers/useKeyCompletion';
import { EditorLanguage } from '../editorLanguage';
import { useLanguageServices } from '../services/useLanguageServices';
import useEditorModel, { EditorModel } from '../useEditorModel';
import useLanguageValidation, { LanguageValidationApi } from '../useLanguageValidation';
import FoliaPredicate from './model/predicate';

export interface FoliaEditorStateProps {
  id: string;
  initialContent?: string;

  // Must be memoized with `useCallback(___, [])`
  simpleTextTemplate?: ((input: string) => string) | null;
}

export interface FoliaEditorState {
  id: string;
  editorModel: EditorModel<EditorLanguage.FOLIA>;
  initialContent: string;
  validation: LanguageValidationApi;
  reset: () => void;
  getPredicate: () => FoliaPredicate | null;
}

const SIMPLE_TEXT_REGEX = /^[\w ]*$/;
const isSimpleText = (input: string) => SIMPLE_TEXT_REGEX.test(input);
const LANGUAGES = [EditorLanguage.FOLIA];

const useFoliaState = ({
  id,
  initialContent = '',
  simpleTextTemplate = null,
}: FoliaEditorStateProps): FoliaEditorState => {
  const { [EditorLanguage.FOLIA]: languageService } = useLanguageServices();
  const editorModel = useEditorModel(LANGUAGES);
  const isInitialized = editorModel.isInitialized();
  useKeyCompletion(editorModel.id, EditorLanguage.FOLIA);

  const validation = useLanguageValidation({
    editorModel,
    language: EditorLanguage.FOLIA,
    skipValidation: simpleTextTemplate === null ? null : isSimpleText,
  });

  const { validate, resetValidation } = validation;

  useEffect(() => {
    // `id` is always truthy -- we're manually forcing this effect on `id` change
    if (id && isInitialized) {
      editorModel.api.setValue(EditorLanguage.FOLIA, initialContent);
      validate(initialContent);
    }
  }, [id, isInitialized, editorModel, validate, initialContent]);

  const reset = useCallback(() => {
    if (isInitialized) {
      editorModel.api.setValue(EditorLanguage.FOLIA, '');
      resetValidation();
    }
  }, [isInitialized, editorModel, resetValidation]);

  const getPredicate = useCallback(() => {
    if (!isInitialized) {
      return null;
    }

    const editorValue = editorModel.api.getValue(EditorLanguage.FOLIA);

    return !editorValue
      ? null
      : languageService.getPredicate(
          !!simpleTextTemplate && isSimpleText(editorValue)
            ? simpleTextTemplate(editorValue)
            : editorValue,
        );
  }, [isInitialized, editorModel, languageService, simpleTextTemplate]);

  return useMemo(
    () => ({
      id,
      editorModel,
      initialContent,
      validation,
      reset,
      getPredicate,
    }),
    [id, editorModel, initialContent, validation, reset, getPredicate],
  );
};

export default useFoliaState;
