import { Tooltip, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Characteristic } from '../../../../shared/dataTypes';
import FilterForm from '../../filters-panel/FilterForm';
import ModifierChip from '../ModifierChip';
import DraggableFilterSettingsIcon from './DraggableFiltersSettingsIcon';

interface Props {
  char: Characteristic;
}

const DraggableFilter: FC<Props> = ({ char }) => (
  <Tooltip enterDelay={1000} title={char.name}>
    <div
      className='draggable-filters'
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        borderRadius: '4px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: '8px',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '4px',
          borderBottom: '1px solid #dedede',
        }}
      >
        <Typography
          variant='body2'
          style={{
            fontSize: '12px',
            color: '#262626',
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {char.name}
        </Typography>
        <span style={{ marginLeft: '10px' }}>
          <ModifierChip modifier={char.modifier} />
        </span>
        <DraggableFilterSettingsIcon char={char} />
      </div>
      <FilterForm char={char} />
    </div>
  </Tooltip>
);

export default DraggableFilter;
