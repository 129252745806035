import { TextField } from '@material-ui/core';
import { ChangeEventHandler, FC, useCallback } from 'react';
import { useAppSelector } from '../../../redux/configureStore';
import { canUserEditGrouping } from '../../../redux/custom-grouping/selectors';
import useCustomGroupingState from '../context/useCustomGroupingState';
import './custom-grouping-info-view.scss';

interface Props {
  isSaving?: boolean;
}

const CustomGroupingInfoView: FC<Props> = props => {
  const {
    infoState: {
      isNameValid,
      nameValue,
      descriptionValue,
      setNameValue,
      setDescriptionValue,
      validating,
      debouncedNameValue,
    },
  } = useCustomGroupingState();

  const { grouping } = useCustomGroupingState();
  const canEdit = useAppSelector(canUserEditGrouping)(grouping?.owner ?? null) || props.isSaving;

  const nameError =
    (!isNameValid && !!nameValue.trim() && !validating && debouncedNameValue === nameValue) ||
    (props.isSaving && grouping?.name === nameValue);

  const onNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setNameValue(event.target.value);
    },
    [setNameValue],
  );

  const onDescriptionChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setDescriptionValue(event.target.value);
    },
    [setDescriptionValue],
  );

  return (
    <div className='custom-grouping-info-view'>
      <TextField
        id='custom-grouping-name'
        label='Custom Grouping Name'
        size='small'
        variant='outlined'
        value={nameValue}
        onChange={onNameChange}
        error={nameError}
        helperText={nameError ? 'A custom grouping with this name already exists' : ''}
        style={{
          marginBottom: '1rem',
        }}
        disabled={!canEdit}
      />
      <TextField
        id='custom-grouping-description'
        label='Description'
        size='small'
        variant='outlined'
        value={descriptionValue}
        onChange={onDescriptionChange}
        disabled={!canEdit}
      />
    </div>
  );
};

export default CustomGroupingInfoView;
