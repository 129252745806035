import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { DroppableProvided } from 'react-beautiful-dnd';

interface Props {
  id: string;
  provided: DroppableProvided;
  isDraggingOver: boolean;
}

const EmptyDroppable: FC<Props> = props => (
  <div
    {...props.provided.droppableProps}
    ref={props.provided.innerRef}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      border: '2px dashed rgb(0, 103, 127,.25)',
      backgroundColor: props.isDraggingOver && '#e6f3f9',
    }}
  >
    <Typography variant='body2' style={{ color: 'rgb(0, 103, 127,.5)', textAlign: 'center' }}>
      Drag characteristics here
    </Typography>
    <div style={{ display: 'none' }}>{props.provided.placeholder}</div>
  </div>
);

export default EmptyDroppable;
