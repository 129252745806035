import { debounce } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Debounced } from '../dataTypes';
import { ValidationState, ValidationStatus } from './model';

export interface ValidationProps<Value, Result> {
  validate: (value: Value) => Promise<Result>;
  isResultValid: (result: Result | null) => boolean;
  debounceDelay?: number | null;
}

export interface ValidationApi<Value, Result> extends ValidationState<Result> {
  validate: (value: Value) => void;
  reset: () => void;
  clearDebounce: () => void;
}

const DEFAULT_DEBOUNCE_DELAY = null;
const useValidation = <Value, Result>({
  validate: _validate,
  isResultValid,
  debounceDelay = DEFAULT_DEBOUNCE_DELAY,
}: ValidationProps<Value, Result>): ValidationApi<Value, Result> => {
  const [validationStatus, setValidationStatus] = useState(ValidationStatus.PRISTINE);
  const [result, setResult] = useState<Result | null>(null);
  const [validateDebounced, setValidateDebounced] = useState<Debounced<
    (value: Value) => void
  > | null>(null);

  const isDebounced = useMemo(() => ![null, 0].includes(debounceDelay), [debounceDelay]);

  const validateImmediately = useCallback(
    async (value: Value) => {
      setValidationStatus(ValidationStatus.IN_PROGRESS);
      setResult(null);

      const result = await _validate(value);
      setValidationStatus(
        isResultValid(result) ? ValidationStatus.VALID : ValidationStatus.INVALID,
      );
      setResult(result);
    },
    [_validate, isResultValid],
  );

  useEffect(() => {
    if (isDebounced) {
      setValidateDebounced(() =>
        debounce((value: Value) => {
          validateImmediately(value);
        }, debounceDelay),
      );
    }
  }, [validateImmediately, isDebounced, debounceDelay]);

  const clearDebounce = useCallback(() => {
    validateDebounced?.clear();
  }, [validateDebounced]);

  const reset = useCallback(() => {
    setValidationStatus(ValidationStatus.PRISTINE);
    setResult(null);
    clearDebounce();
  }, [clearDebounce]);

  const validate = useCallback(
    (value: Value) => {
      if (!isDebounced) {
        validateImmediately(value);
        return;
      }

      setValidationStatus(ValidationStatus.WAITING);
      validateDebounced?.(value);
    },
    [validateImmediately, isDebounced, validateDebounced],
  );

  return useMemo(
    () => ({
      validationStatus,
      result,
      validate,
      reset,
      clearDebounce,
    }),
    [validationStatus, result, validate, reset, clearDebounce],
  );
};

export default useValidation;
