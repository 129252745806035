import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setOriginalDetachedReport } from '../../../redux/ActionCreators';
import { AppState } from '../../../redux/configureStore';
import {
  cancelCloseDesigner,
  closeDesigner,
  confirmCloseDesigner,
  openAdminPage,
} from '../../../redux/ui/actionCreators';
import { removeReportFromWorkspace } from '../../../redux/WorkspaceActionCreators';
import { DESIGNER_SEQUENCE_ID } from '../../../shared/constants';
import MainModal from '../MainModal';

const mapStateToProps = (state: AppState) => ({
  open: state.ui.closeDesignerModalOpen,
  intendedPage: state.ui.intendedPage,
});

const mapDispatchToProps = {
  removeReportFromWorkspace,
  openAdminPage,
  confirmCloseDesigner,
  cancelCloseDesigner,
  closeDesigner,
  setOriginalDetachedReport,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const CloseDesignerModal: FC<Props> = props => (
  <MainModal
    open={props.open}
    headerText='Confirm Cancel'
    cancelButtonText='No'
    onRequestSubmit={() => {
      props.setOriginalDetachedReport();
      props.closeDesigner(props.intendedPage);
      props.removeReportFromWorkspace(DESIGNER_SEQUENCE_ID);
      props.confirmCloseDesigner();
    }}
    onRequestClose={() => {
      props.cancelCloseDesigner();
    }}
    onBackdropClick={() => {
      props.cancelCloseDesigner();
    }}
  >
    Are you sure you want to close Report Designer? If you made any changes, they will be lost.
  </MainModal>
);

export default connector(CloseDesignerModal);
