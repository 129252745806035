import { Link, Typography } from '@material-ui/core';
import { LinkOff } from '@material-ui/icons';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { openFolderDrawer } from '../../redux/ActionCreators';
import { DrawerType } from '../../shared/constants';
import CloseButton from './CloseButton';
import './report-error.scss';

interface Props {
  sequenceId: number;
  handleCloseReport?: () => void;
  errMessage?: string;
  errCode?: number;
  stackTrace?: string;
}

const ReportError: FC<Props> = props => {
  const dispatch = useDispatch();

  return (
    <div className='report-error hide-in-pdf'>
      {props.handleCloseReport && <CloseButton onClick={props.handleCloseReport} />}

      {isStyledError(props.errCode) ? (
        <div className='styled-error margin-horiz'>
          <div className='main'>
            <LinkOff color='error' fontSize='large' />
            <Typography variant='h2'>Report Not Found</Typography>
            <Typography>
              <Link
                onClick={() =>
                  dispatch(
                    openFolderDrawer(DrawerType.REPLACE_REPORT, { sequenceId: props.sequenceId }),
                  )
                }
              >
                Locate report
              </Link>{' '}
              to resolve the issue.
            </Typography>
          </div>
          <div className='footer'>
            <Typography variant='caption'>Original Report Path:</Typography>
            <Typography>{props.errMessage?.replace('/', ' > ')}</Typography>
          </div>
        </div>
      ) : (
        <div className='unstyled-error margin-horiz'>
          <Typography color='error'>{props.errMessage}</Typography>
          <Typography color='error'>{props.stackTrace}</Typography>
        </div>
      )}
    </div>
  );
};

// Error codes for which we have styled errors rather than just red error messages
const isStyledError = (errCode: number) => [404].includes(errCode);

export default ReportError;
