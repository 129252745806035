import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  requestAddUsersToGroup,
  requestRemoveUsersFromGroup,
} from '../../../redux/adminActionCreators';
import { AppState } from '../../../redux/configureStore';
import Panel from './Panel';

const mapStateToProps = (state: AppState) => ({
  user: state.admin.users.find(u => u.userName === state.admin.selectedUser),
  groups: state.admin.groups,
});
const mapDispatchToProps = { requestAddUsersToGroup, requestRemoveUsersFromGroup };

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const AssignedGroupsPanel: FC<Props> = props => (
  <Panel title='Roles' className='assigned-groups-panel' style={{ gridArea: 'groups' }}>
    {props.groups.length ? (
      <FormGroup>
        {props.groups.map((groupName, i) => {
          const switchOn = props.user?.groups.includes(groupName) || false;
          return (
            <FormControlLabel
              key={i}
              control={
                <div className='checkbox-container'>
                  {props.user?.groupsLoading?.includes(groupName) ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Checkbox
                      color='primary'
                      checked={switchOn}
                      onChange={() =>
                        switchOn
                          ? props.requestRemoveUsersFromGroup([props.user.userName], groupName)
                          : props.requestAddUsersToGroup([props.user.userName], groupName)
                      }
                    />
                  )}
                </div>
              }
              label={groupName}
            />
          );
        })}
      </FormGroup>
    ) : (
      <Typography style={{ padding: '4px' }}>No groups defined.</Typography>
    )}
  </Panel>
);

export default connector(AssignedGroupsPanel);
