import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar,
  setActivePortfolioSelection,
  setDefaultPortfolioSelection,
} from '../../redux/ActionCreators';
import { useAppSelector } from '../../redux/configureStore';
import PortfolioDrawer from './PortfolioDrawer';
import {
  getSelectedPortfolios,
  processPortfolioTreeSelection,
  useGetPortfolios,
} from './portfolioDrawerHelpers';

interface Props {
  open: boolean;
  closeDrawer: () => void;
}

const HomepagePortfolioDrawer: FC<Props> = props => {
  const dispatch = useDispatch();
  const defaultDateContext = useAppSelector(state => state.user.selectedDateContext);
  const defaultPortfolioSelection = useAppSelector(
    state => state.user.userInfo.userPreferences.defaultPortfolioSelection ?? {},
  );

  const {
    selectedPortfolioNodeIds,
    selectedAdHocPortfolioNames,
    selectedBenchmarkPortfolioNames,
  } = useAppSelector(state => getSelectedPortfolios(state, null));

  const {
    portfolioHierarchy,
    adHocPortfolios,
    benchmarkPortfolios,
    selectedKeys,
    setSelectedKeys,
    selectedAdHocKeys,
    setSelectedAdHocKeys,
    selectedBenchmarkKeys,
    setSelectedBenchmarkKeys,
    getOptions,
  } = useGetPortfolios(
    { type: 'asOf', dates: [defaultDateContext] },
    defaultDateContext,
    useCallback(
      (...args: Parameters<typeof enqueueSnackbar>) => {
        dispatch(enqueueSnackbar(...args));
      },
      [dispatch],
    ),
    props.open,
    selectedPortfolioNodeIds,
    selectedAdHocPortfolioNames,
    selectedBenchmarkPortfolioNames,
  );

  return (
    <PortfolioDrawer
      headerText='Select Your Portfolio'
      drawerOpen={props.open}
      closeDrawer={props.closeDrawer}
      checkboxes
      onApplyClick={() => {
        // transform selectedKeys based on portfolioHierarchy
        const processedSelectedKeys = processPortfolioTreeSelection(
          selectedKeys,
          portfolioHierarchy,
        );

        dispatch(
          setActivePortfolioSelection({
            selectedPortfolioNodeIds: processedSelectedKeys,
            selectedAdHocPortfolioNames: selectedAdHocKeys,
            selectedBenchmarkPortfolioNames: selectedBenchmarkKeys,
          }),
        );
        props.closeDrawer();
      }}
      setAsDefault={() => {
        dispatch(
          setDefaultPortfolioSelection({
            selectedPortfolioNodeIds: processPortfolioTreeSelection(
              selectedKeys,
              portfolioHierarchy,
            ),
            selectedAdHocPortfolioNames: selectedAdHocKeys,
            selectedBenchmarkPortfolioNames: selectedBenchmarkKeys,
          }),
        );
      }}
      resetToDefault={() => {
        const {
          selectedPortfolioNodeIds,
          selectedAdHocPortfolioNames,
          selectedBenchmarkPortfolioNames,
        } = defaultPortfolioSelection;

        setSelectedKeys(selectedPortfolioNodeIds ?? []);
        setSelectedAdHocKeys(selectedAdHocPortfolioNames ?? []);
        setSelectedBenchmarkKeys(selectedBenchmarkPortfolioNames ?? []);
      }}
      resetToDefaultDisabled={
        defaultPortfolioSelection.selectedPortfolioNodeIds?.length === 0 &&
        defaultPortfolioSelection.selectedAdHocPortfolioNames?.length === 0 &&
        defaultPortfolioSelection.selectedBenchmarkPortfolioNames?.length === 0
      }
      dateContext={{ type: 'asOf', dates: [defaultDateContext] }}
      getOptions={getOptions}
      portfolioHierarchy={portfolioHierarchy}
      adHocPortfolios={adHocPortfolios}
      benchmarkPortfolios={benchmarkPortfolios}
      selectedKeys={selectedKeys}
      setSelectedKeys={setSelectedKeys}
      selectedAdHocKeys={selectedAdHocKeys}
      setSelectedAdHocKeys={setSelectedAdHocKeys}
      selectedBenchmarkKeys={selectedBenchmarkKeys}
      setSelectedBenchmarkKeys={setSelectedBenchmarkKeys}
      className='right-drawer'
    />
  );
};

export default HomepagePortfolioDrawer;
