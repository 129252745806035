import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import { saveMetadata } from '../../../redux/metadata/actionCreators';
import { setCharToCopy } from '../../../redux/metadata/actions';
import { CharacteristicDrawerType } from '../../../shared/constants';
import ResizableDrawerWrapper from '../../drawers/ResizableDrawerWrapper';
import CharacteristicDrawerContent from './CharacteristicDrawerContent';
import { resolveInitialFormValues } from './characteristicDrawerForm';
import CharacteristicDrawerHeader from './CharacteristicDrawerHeader';

const CopyCharacteristicDrawer: FC = () => {
  const initialFormValues = useSelector(resolveInitialFormValues);
  const charToCopy = useSelector((state: AppState) => state.metadata.charToCopy);
  const dispatch = useDispatch();
  const close = () => dispatch(setCharToCopy(undefined));

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: !!charToCopy,
        onClose: close,
        className: 'characteristic-drawer',
      }}
      id='CopyCharacteristicDrawer'
      defaultWidth={480}
    >
      <CharacteristicDrawerHeader headerText='Copy Characteristic' onXClick={close} />

      {
        // Formik crashes if undefined gets passed to initialValues
        charToCopy && (
          <CharacteristicDrawerContent
            initialValues={initialFormValues}
            closeDrawer={close}
            canWrite // This component does not show for users who have canWrite === false
            onSubmit={values => {
              dispatch(saveMetadata(values, 'POST'));
              close();
            }}
            characteristicDrawerType={CharacteristicDrawerType.COPY}
          />
        )
      }
    </ResizableDrawerWrapper>
  );
};

export default CopyCharacteristicDrawer;
