import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CascadingMenu, CascadingMenuItem } from 'algo-react-dataviz';
import axios from 'axios';
import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  getErrorMessage,
  openBenchmarkPortfolioDrawer,
  openProxyDrawer,
} from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { openPositionDrawer } from '../../redux/position-drawer/actions';
import { BookBenchmark } from '../../redux/position-drawer/state';
import {
  clearPrivateSandbox,
  deleteSandbox,
  renameSandbox,
  switchToSandbox,
} from '../../redux/WorkspaceActionCreators';
import { NotificationLevel } from '../../shared/constants';
import { DateContext, Sandbox } from '../../shared/dataTypes';
import { getDateContextString, isAwa as _isAwa } from '../../shared/utils';
import { baseUrl } from '../shared/environment';
import ClearSandboxModal from './ClearSandboxModal';
import DeleteSandboxModal from './DeleteSandboxModal';
import RenameSandboxModal from './RenameSandboxModal';

interface Props {
  sandbox: Sandbox;
  sequenceId: number;
  dateContext: DateContext;
}

const SandboxChipMenu: FC<Props> = props => {
  const isAwa = useSelector(_isAwa);
  const sandboxes = useSelector(
    (state: AppState) => state.user.userInfo.userPreferences?.sandboxes,
  );
  const dateContexts = useSelector((state: AppState) =>
    getDateContextString(props.dateContext, state.user.selectedDateContext),
  );
  const dispatch = useDispatch();

  const [proxyNames, setProxyNames] = useState<string[]>([]);
  const [positions, setPositions] = useState<BookBenchmark[]>([]);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [clearModalOpen, setClearModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch `proxyNames` and `positionBooksAndBenchmarks` on menu open
  const { type: dateType } = props.dateContext ?? {};
  const { path: sandboxPath, prvSandbox: isPrivateSandbox } = props.sandbox ?? {};
  const onOpen = useCallback(() => {
    if (!dateType || !isAwa) {
      return;
    }

    axios
      .get<string[]>(`${baseUrl}api/proxyNames`, {
        params: {
          sandboxPath,
          isPrivateSandbox,
          dateType,
          dateContexts,
        },
      })
      .then(({ data }) => setProxyNames(data))
      .catch((error: Error) => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Error retrieving proxy data: ${getErrorMessage(error)}`,
          ),
        );
      });

    axios
      .get<BookBenchmark[]>(`${baseUrl}api/positionBooksAndBenchmarks`, {
        params: { sandboxPath, dateType, dateContexts },
      })
      .then(({ data }) => setPositions(data))
      .catch((error: Error) => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Error retrieving proxy data: ${getErrorMessage(error)}`,
          ),
        );
      });
  }, [isAwa, dateContexts, dispatch, dateType, sandboxPath, isPrivateSandbox]);

  const menuItems: CascadingMenuItem[] = [
    {
      id: 'renameSandbox',
      text: 'Rename',
      onClick: () => setRenameModalOpen(true),
    },
    ...(!isAwa
      ? []
      : [
          {
            id: 'editSandbox',
            text: 'Edit',
            children: [
              {
                id: 'changeSandboxBenchmarkPortfolio',
                text: 'Change Benchmark Portfolio',
                disabled:
                  !['asOf', 'default'].includes(props.dateContext.type) &&
                  (props.dateContext.type !== 'specific' || props.dateContext?.dates?.length !== 1),
                onClick: () => dispatch(openBenchmarkPortfolioDrawer(props.sequenceId)),
              },
              {
                id: 'editWhatIfPositionIn',
                text: 'Edit What-if Positions in',
                disabled: positions.length === 0,
                children: positions.map((p, i) => ({
                  id: `editPosition${i}`,
                  text: p.name.split('/').pop(),
                  onClick: () =>
                    dispatch(openPositionDrawer(props.sandbox, props.sequenceId, 'edit', p)),
                  tooltip: p.name,
                })),
              },
              {
                id: 'setNewSandboxProxyPosition',
                text: 'Set New Proxy Position',
                onClick: () => dispatch(openProxyDrawer(props.sequenceId)),
              },
              {
                id: 'editSandboxProxyPositions',
                text: 'Edit Existing Proxy Positions',
                disabled: proxyNames.length === 0,
                children: proxyNames.map(proxyName => ({
                  id: `editSandboxProxy${proxyName}`,
                  text: proxyName,
                  onClick: () => dispatch(openProxyDrawer(props.sequenceId, proxyName)),
                })),
              },
            ],
          },
        ]),
    {
      id: 'add',
      text: 'Add',
      children: [
        {
          id: 'instrumentsOrFunds',
          text: 'Instruments or Funds',
          onClick: () => dispatch(openPositionDrawer(props.sandbox, props.sequenceId, 'add')),
        },
      ],
    },
    {
      id: 'clearSandbox',
      text: 'Clear',
      onClick: () => setClearModalOpen(true),
    },
    {
      id: 'closeSandbox',
      text: 'Close',
      onClick: () => dispatch(switchToSandbox(props.sequenceId, null)),
    },
    {
      id: 'deleteSandbox',
      text: 'Delete',
      onClick: () => setDeleteModalOpen(true),
    },
  ];

  return (
    <>
      <span className='sd-sandbox-chip-action'>
        <CascadingMenu
          showMenu
          icon={<ArrowDropDownIcon fontSize='small' />}
          items={menuItems}
          {...{ onOpen }}
        />
      </span>

      <RenameSandboxModal
        isOpen={renameModalOpen}
        sandboxes={sandboxes}
        onSubmit={(newValue: string) => {
          dispatch(renameSandbox(props.sequenceId, props.sandbox.path, newValue));
          setRenameModalOpen(false);
        }}
        onClose={() => setRenameModalOpen(false)}
      />

      <ClearSandboxModal
        isOpen={clearModalOpen}
        onSubmit={() => {
          setClearModalOpen(false);
          dispatch(clearPrivateSandbox(props.sandbox));
        }}
        onClose={() => setClearModalOpen(false)}
      />

      <DeleteSandboxModal
        isOpen={deleteModalOpen}
        onSubmit={() => {
          setDeleteModalOpen(false);
          dispatch(deleteSandbox(props.sequenceId, props.sandbox.path));
        }}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default SandboxChipMenu;
