import { MenuItem, TextField } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import { charOptionsToLabels, getTextFieldFormProps } from './characteristicDrawerFormHelpers';

interface Props {
  formProps: FormikProps<CharacteristicDrawerForm>;
}

const ColumnClassesInput: FC<Props> = props => {
  const initialFormValues = useSelector(resolveInitialFormValues);
  const { value, onChange, ...rest } = getTextFieldFormProps(
    props.formProps,
    initialFormValues,
  )('columnClasses');
  return (
    <TextField
      {...rest}
      select
      name='columnClasses'
      label='Column Classes'
      className='full-width-container'
      SelectProps={{
        multiple: true,
        value: String(value).split(','),
        onChange: (event: any) =>
          props.formProps.setFieldValue('columnClasses', event.target.value.join(','), false),
      }}
    >
      {(charOptionsToLabels['columnClasses'] || []).map((obj, i) => (
        <MenuItem value={obj.value} key={obj.key}>
          {obj.key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ColumnClassesInput;
