import { Token as AntlrToken, Vocabulary } from 'antlr4ts';
import { EditorLanguage } from './editorLanguage';
import Token from './Token';

abstract class TokenFactory<LanguageId extends EditorLanguage> {
  static readonly EOL_RULE_NAME = 'EOL';
  static readonly EOF_RULE_NAME = 'EOF';
  static readonly ERROR_RULE_NAME = 'ERROR';
  static createTokenName = (languageId: string, ruleName: string) => `${languageId}__${ruleName}`;

  constructor(private readonly languageId: LanguageId, private readonly vocabulary: Vocabulary) {}

  createErrorToken = (startColumn: number) =>
    new Token(this.languageId, TokenFactory.ERROR_RULE_NAME, null, startColumn);

  fromAntlrToken = (token: AntlrToken | null): Token<LanguageId> | null =>
    token === null
      ? null
      : new Token(
          this.languageId,
          this.vocabulary.getSymbolicName(token.type),
          token.text ?? null,
          token.charPositionInLine,
        );
}

export default TokenFactory;
