import { TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FC } from 'react';
import './currency-dropdown.scss';
import useCurrencies from './useCurrencies';

interface Props {
  hideLabel?: boolean;
  className?: string;
}

const LABEL = 'Currency';

const CurrencyDropdown: FC<Props> = props => {
  const { currencies, selectedCurrency, fetchCurrencies, updateCurrency } = useCurrencies();

  if (!selectedCurrency) {
    return null;
  }

  const handleCurrencyChange = (_event: React.ChangeEvent<{}>, value: string) => {
    if (value !== null) {
      updateCurrency(value);
    }
  };

  return (
    <div className={`currency-dropdown general-dropdown ${props.className ?? ''}`}>
      {!props.hideLabel && <Typography className='option-typography'>{LABEL}</Typography>}
      <Autocomplete
        className='currency-autocomplete'
        classes={{ paper: 'currency-dropdown-paper' }}
        value={selectedCurrency}
        options={currencies}
        onOpen={fetchCurrencies}
        onChange={handleCurrencyChange}
        renderInput={params =>
          selectedCurrency && (
            <Tooltip title={selectedCurrency}>
              <TextField className='currency-textfield' {...params} />
            </Tooltip>
          )
        }
        blurOnSelect
        disableClearable
      />
    </div>
  );
};

export default CurrencyDropdown;
