import { Column, TreeList } from 'devextreme-react/tree-list';
import React, { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { requestSetExpandedPortfolios } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { Node } from '../../shared/dataTypes';
import {
  filterDataSource,
  getPortfoliosAtLevel,
  mapData,
  searchPortfolioHierarchyForId,
} from '../../shared/utils';
import { PORTFOLIO_HIERARCHY_ROOT_ID } from './portfolioDrawerHelpers';
import SearchField from './SearchField';

interface BaseProps {
  portfolioHierarchy: Node<string> | 'error';
  selectionMode: 'single' | 'multiple' | 'none';
  onItemSelectionChanged: (e: string[]) => void;
  selectedKeys: string[];
  cellComponent?: React.ComponentType<any>;
}

const mapStateToProps = (state: AppState) => ({
  expandedPortfolios: state.user.userInfo.userPreferences.expandedPortfolios,
});
const mapDispatchToProps = { requestSetExpandedPortfolios };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const PortfolioTree: FC<Props> = props => {
  const [searchTerm, setSearchTerm] = useState('');

  const dataSource =
    props.portfolioHierarchy === 'error'
      ? undefined
      : filterDataSource(
          mapData(props.portfolioHierarchy, 0),
          searchTerm,
          () => false,
          n => n.id,
        );

  const onSelectedRowKeysChange = (keys: string[]) =>
    props.onItemSelectionChanged(
      // SD-1087 Select only leaf nodes which match the current search
      Array.from(
        new Set(
          dataSource
            // Include children of matched key
            .filter(
              item =>
                !item.children &&
                ((keys.length === 1 && keys[0] === PORTFOLIO_HIERARCHY_ROOT_ID) ||
                  keys.find(key => item.id === key || item.id.startsWith(`${key}`))),
            )
            .map(item => item.id)

            // include keys which are filtered out by current search
            // (otherwise doing a search could change the selection)
            .concat(props.selectedKeys?.filter(key => !dataSource.find(item => item.id === key))),
        ),
      ),
    );
  return (
    <div className='portfolio-tree'>
      <div className='top-controls'>
        <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      <div className='scrolling-container-for-dx'>
        <TreeList
          dataSource={dataSource}
          dataStructure='plain'
          keyExpr='id'
          parentIdExpr='parentId'
          selection={{ mode: props.selectionMode, recursive: true }}
          onSelectedRowKeysChange={onSelectedRowKeysChange}
          showColumnHeaders={false}
          selectedRowKeys={props.selectedKeys?.filter(item =>
            searchPortfolioHierarchyForId(props.portfolioHierarchy, item),
          )}
          expandedRowKeys={
            props.portfolioHierarchy === 'error'
              ? []
              : props.expandedPortfolios?.filter(item =>
                  searchPortfolioHierarchyForId(props.portfolioHierarchy, item),
                ) ||
                getPortfoliosAtLevel(props.portfolioHierarchy, 2).filter(item =>
                  searchPortfolioHierarchyForId(props.portfolioHierarchy, item),
                )
          }
          onExpandedRowKeysChange={props.requestSetExpandedPortfolios}
        >
          <Column dataField='name' cellComponent={props.cellComponent} />
        </TreeList>
      </div>
    </div>
  );
};

export default connector(PortfolioTree);
