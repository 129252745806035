import { FC } from 'react';
import { useAppSelector } from '../../../redux/configureStore';
import { isDrawerOpen } from '../../../redux/custom-grouping/selectors';
import ResizableDrawerWrapper from '../../drawers/ResizableDrawerWrapper';
import FoliaSyntaxGuideAnchor from '../../editor/folia/syntax-guide/FoliaSyntaxGuideAnchor';
import CustomGroupingStateProvider from '../context/CustomGroupingStateProvider';
import CustomGroupingSaveModal from '../save-modal/CustomGroupingSaveModal';
import './custom-grouping-drawer.scss';
import CustomGroupingDrawerContent from './CustomGroupingDrawerContent';
import CustomGroupingDrawerFooter from './CustomGroupingDrawerFooter';
import CustomGroupingDrawerHeader from './CustomGroupingDrawerHeader';

const DRAWER_ID = 'CustomGroupingDrawer';
const CustomGroupingDrawer: FC = () => {
  const open = useAppSelector(isDrawerOpen);

  return (
    <CustomGroupingStateProvider>
      <ResizableDrawerWrapper
        id={DRAWER_ID}
        drawerProps={{
          className: 'custom-grouping-drawer',
          anchor: 'right',
          open,
        }}
        defaultWidth={515}
      >
        <FoliaSyntaxGuideAnchor />
        <CustomGroupingDrawerHeader />
        <CustomGroupingDrawerContent />
        <CustomGroupingDrawerFooter />
      </ResizableDrawerWrapper>
      <CustomGroupingSaveModal />
    </CustomGroupingStateProvider>
  );
};

export default CustomGroupingDrawer;
