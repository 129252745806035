import { Sandbox } from '../../shared/dataTypes';

export type Mode = 'add' | 'edit';

export interface BookBenchmark {
  name: string;
  type: 'book' | 'benchmark';
}

type ClosedDrawer = {
  open: false;
  sandbox?: never;
  sequenceId?: never;
  mode?: never;
  positionToEdit?: never;
};

type OpenDrawer = {
  open: true;
  sandbox: Sandbox;
  sequenceId: number;
  mode: Mode;
  positionToEdit?: BookBenchmark;
};

export type PositionDrawerState = ClosedDrawer | OpenDrawer;

export const createDefaultState = (): PositionDrawerState => ({ open: false });
