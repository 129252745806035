import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Check as CheckIcon, SettingsOutlined as SettingsOutlinedIcon } from '@material-ui/icons';
import React, { FC } from 'react';

interface Props {
  showBench: boolean;
  showDiff: boolean;
  setShowBench: (checked: boolean) => void;
  setShowDiff: (checked: boolean) => void;
}

const ReportDesignerSettingsIcon: FC<Props> = ({
  showBench,
  showDiff,
  setShowBench,
  setShowDiff,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showBenchOnClick = () => {
    handleClose();
    setShowBench(!showBench);
  };

  const showDiffOnClick = () => {
    handleClose();
    setShowDiff(!showDiff);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        aria-controls='settings-menu'
        aria-haspopup='true'
        aria-label='Toggle Settings Menu'
      >
        <SettingsOutlinedIcon style={{ color: '#00677F', fontSize: '18px' }} />
      </IconButton>
      <Menu
        id='settings-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={showBenchOnClick}
          style={{
            display: 'flex',
            width: '144px',
            justifyContent: 'space-between',
          }}
        >
          <span>Show Bench</span>
          {showBench && (
            <span>
              <CheckIcon style={{ fontSize: '12px' }} />
            </span>
          )}
        </MenuItem>
        <MenuItem
          onClick={showDiffOnClick}
          style={{
            display: 'flex',
            width: '144px',
            justifyContent: 'space-between',
          }}
        >
          <span>Show Diff</span>
          {showDiff && (
            <span>
              <CheckIcon style={{ fontSize: '12px' }} />
            </span>
          )}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ReportDesignerSettingsIcon;
