import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'algo-react-dataviz';
import { SnackbarProvider } from 'notistack';
import { Component } from 'react';
import 'react-dates/initialize';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import SecuritySelector from './components/security/SecuritySelector';
import MemoizedMonacoProvider from './lang/memoized-monaco/MemoizedMonacoProvider';
import LanguageServiceProvider from './lang/services/LanguageServiceProvider';
import { ConfigureStore } from './redux/configureStore';

const MainContainer = styled.div`
  @media (max-width: 768px) {
    position: absolute;
  }
`;

const store = ConfigureStore();

class App extends Component {
  componentDidMount() {
    console.log(
      '%cWelcome to \n%cSSC %cStadium %cSuperNova',
      'font-size:36px;color:#000;background-color:#f7f6e8',
      'font-size:36px;color:#273a96;background-color:#f7f6e8',
      'font-size:36px;color:#5aff5a;background-color:#f7f6e8',
      'font-size:36px;color:#f36c44;background-color:#f7f6e8',
    );
  }

  render() {
    return (
      <Router>
        <Provider store={store}>
          <StylesProvider injectFirst>
            <ThemeProvider {...{ theme }}>
              <CssBaseline />
              <SnackbarProvider dense preventDuplicate>
                <MemoizedMonacoProvider>
                  <LanguageServiceProvider>
                    <MainContainer>
                      <SecuritySelector />
                    </MainContainer>
                  </LanguageServiceProvider>
                </MemoizedMonacoProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </StylesProvider>
        </Provider>
      </Router>
    );
  }
}

export default App;
