import { Button, Checkbox, Typography } from '@material-ui/core';
import { FC, useState } from 'react';
import { deDupeArray } from '../../shared/utils';
import SearchField from './SearchField';

interface Props {
  benchmarkPortfolios?: Array<string>;
  selectedBenchmarkKeys?: string[];
  setSelectedBenchmarkKeys?: (k: string[]) => void;
}

const BenchmarkPortfolioTree: FC<Props> = props => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <>
      <div className='search-controls'>
        <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Button
          onClick={() => {
            props.setSelectedBenchmarkKeys(
              deDupeArray([
                ...props.selectedBenchmarkKeys,
                ...props.benchmarkPortfolios.filter(
                  name =>
                    searchTerm === '' || name.toLowerCase().includes(searchTerm.toLowerCase()),
                ),
              ]),
            );
          }}
        >
          Select All
        </Button>
      </div>
      <div className='portfolio-flat-list'>
        {props?.benchmarkPortfolios.length > 0 &&
          props.benchmarkPortfolios
            .filter(
              name => searchTerm === '' || name.toLowerCase().includes(searchTerm.toLowerCase()),
            )
            .map(name => {
              return (
                <div key={name} className='portfolio-flat-list-item'>
                  <Checkbox
                    color='primary'
                    checked={props.selectedBenchmarkKeys?.includes(name)}
                    onChange={e => {
                      props.setSelectedBenchmarkKeys(
                        e.target.checked
                          ? [...props.selectedBenchmarkKeys, name]
                          : props.selectedBenchmarkKeys.filter(item => item !== name),
                      );
                    }}
                  />
                  <Typography>{name}</Typography>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default BenchmarkPortfolioTree;
