import { FC, PropsWithChildren } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../redux/configureStore';
import { setAccordionExpanded } from '../../../redux/metadata/actions';
import UnfoldAccordion from '../../shared/UnfoldAccordion';
import { AccordionId } from './characteristicExpandedAccordion';

interface BaseProps {
  summary: string;
  accordionId: AccordionId;
  defaultExpanded?: boolean;
}

const mapStateToProps = (state: AppState, { accordionId }: BaseProps) => ({
  expanded: state.metadata.accordionsExpanded[accordionId],
});

const mapDispatchToProps = {
  setAccordionExpanded,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & PropsWithChildren<BaseProps>;

const CharacteristicAccordion: FC<Props> = props => (
  <UnfoldAccordion
    summary={props.summary}
    setExpanded={expanded => props.setAccordionExpanded(props.accordionId, expanded)}
    expanded={props.expanded ?? props.defaultExpanded}
  >
    {props.children}
  </UnfoldAccordion>
);

export default connector(CharacteristicAccordion);
