import { FC } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { saveMetadata } from '../../../redux/metadata/actionCreators';
import { CharacteristicDrawerType } from '../../../shared/constants';
import ResizableDrawerWrapper from '../../drawers/ResizableDrawerWrapper';
import CharacteristicDrawerContent from './CharacteristicDrawerContent';
import { resolveInitialFormValues } from './characteristicDrawerForm';
import CharacteristicDrawerHeader from './CharacteristicDrawerHeader';

interface BaseProps {
  open: boolean;
  close: () => void;
}

const mapDispatchToProps = { saveMetadata };
const connector = connect(undefined, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

// This component is never rendered unless the user has write permission
// for metadata (see Metadata.tsx). That is why canWrite is passed as
// true to CharacteristicDrawerContent
const AddCharacteristicDrawer: FC<Props> = props => {
  const initialFormValues = useSelector(resolveInitialFormValues);

  return (
    <ResizableDrawerWrapper
      drawerProps={{
        anchor: 'right',
        open: props.open,
        onClose: props.close,
        className: 'characteristic-drawer',
      }}
      id='AddCharacteristicDrawer'
      defaultWidth={480}
    >
      <CharacteristicDrawerHeader headerText='Create New Characteristic' onXClick={props.close} />

      <CharacteristicDrawerContent
        initialValues={initialFormValues}
        closeDrawer={props.close}
        canWrite
        onSubmit={values => {
          props.saveMetadata(values, 'POST');
          props.close();
        }}
        characteristicDrawerType={CharacteristicDrawerType.ADD}
      />
    </ResizableDrawerWrapper>
  );
};

export default connector(AddCharacteristicDrawer);
