import { FC } from 'react';
import { DroppableProvided } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Characteristic } from '../../../shared/dataTypes';
import { HORIZONTAL_ID, VERTICAL_ID } from './beautifulDndHelpers';
import CharacteristicDraggable from './CharacteristicDraggable';

interface CharListProps {
  horizontal: boolean;
  isPopulated: boolean;
  isDraggingOver: boolean;
}

const CharList = styled.div<CharListProps>`
  flex-grow: 1;
  padding: 8px;
  overflow: auto;
  ${props => props.horizontal && 'display: flex; overflow-y: hidden;'}
  ${props => props.isDraggingOver && 'background-color: #e6f3f9;'}
`;

interface Props {
  id: string;
  provided: DroppableProvided;
  direction?: 'horizontal' | 'vertical';
  chars: Characteristic[];
  remove?: (droppableId: string, draggableId: string) => void;
  editor?: (
    droppableId: string,
    draggableId: string,
    editor: string,
    target: EventTarget & HTMLButtonElement,
  ) => void;
  isDraggingOver: boolean;
}

const PopulatedDroppable: FC<Props> = props => (
  <CharList
    {...props.provided.droppableProps}
    ref={props.provided.innerRef}
    isPopulated={true}
    horizontal={props.direction === 'horizontal'}
    isDraggingOver={props.isDraggingOver}
  >
    {props.chars.map((char, index) => (
      <CharacteristicDraggable
        key={char.draggableId}
        char={char}
        index={index}
        menuMode={false}
        droppableId={props.id}
        horizontal={props.direction === 'horizontal'}
        remove={() => props.remove(props.id, char.draggableId)}
        editor={
          [VERTICAL_ID, HORIZONTAL_ID].includes(props.id)
            ? (target: EventTarget & HTMLButtonElement) => {
                props.editor(props.id, char.draggableId, char.grouperEditor, target);
              }
            : null
        }
      />
    ))}
    {props.provided.placeholder}
  </CharList>
);

export default PopulatedDroppable;
