import { Button, Typography } from '@material-ui/core';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAccordionsExpanded } from '../../../redux/metadata/actions';
import { AccordionId } from './characteristicExpandedAccordion';
interface Props {
  accordionIds: AccordionId[];
}

const CharacteristicExpandAll: FC<Props> = props => {
  const [expand, setExpand] = useState(true);
  const dispatch = useDispatch();
  return (
    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <Button
        onClick={() => {
          dispatch(
            setAccordionsExpanded(
              props.accordionIds?.reduce(
                (obj, item) => ({
                  ...obj,
                  [item]: expand,
                }),
                {},
              ),
            ),
          );
          setExpand(!expand);
        }}
        variant='text'
      >
        <Typography color='primary'>{expand ? 'Expand All' : 'Collapse All'} </Typography>
      </Button>
    </div>
  );
};

export default CharacteristicExpandAll;
