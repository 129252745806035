import axios from 'axios';
import { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeBenchmarkPortfolioDrawer, enqueueSnackbar } from '../../redux/ActionCreators';
import { AppState, sendEditMessage } from '../../redux/configureStore';
import { NotificationLevel } from '../../shared/constants';
import { BenchInfo, BenchInfoAction, EditRequest, Node } from '../../shared/dataTypes';
import {
  getDateContextForPortfolioDrawer,
  getDateContextStringWithUnderscore,
  getSelectedSandbox,
  mapData,
} from '../../shared/utils';
import { baseUrl } from '../shared/environment';
import PortfolioDrawer from './PortfolioDrawer';
import { useGetPortfolios } from './portfolioDrawerHelpers';
import PortfolioItem from './PortfolioItem';

const mapStateToProps = (state: AppState) => ({
  benchmarkPortfolioDrawerOpen: state.drawers.benchmarkPortfolioDrawer.open,
  dateContext:
    state.report.reportDefinition[state.drawers.benchmarkPortfolioDrawer.sequenceId]?.dateContext,
  defaultDateContext: state.user.selectedDateContext,
  sandbox: getSelectedSandbox(state, state.drawers.benchmarkPortfolioDrawer?.sequenceId)?.path,
  token: state.user.tk,
  reportableType:
    state.report.reportDefinition[state.drawers.benchmarkPortfolioDrawer.sequenceId]
      ?.reportableType,
});

const mapDispatchToProps = { closeBenchmarkPortfolioDrawer, enqueueSnackbar };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const BenchmarkPortfolioDrawer: FC<Props> = props => {
  const [benchmarks, setBenchmarks] = useState([]);
  const [benchInfo, benchInfoDispatch] = useReducer(benchInfoReducer, []);

  const onPortfolioHierarchyLoad = useCallback(
    (data: Node<string>) =>
      benchInfoDispatch({ type: 'initialize', data: getInitialBenchInfo(data) }),
    [],
  );

  const { portfolioHierarchy } = useGetPortfolios(
    props.dateContext,
    props.defaultDateContext,
    props.enqueueSnackbar,
    props.benchmarkPortfolioDrawerOpen,
    undefined,
    undefined,
    undefined,
    onPortfolioHierarchyLoad,
    props.sandbox,
  );

  const { benchmarkPortfolioDrawerOpen, dateContext, defaultDateContext } = props;
  useEffect(() => {
    if (benchmarkPortfolioDrawerOpen)
      axios
        .get<string[]>(`${baseUrl}api/benchmarks`, {
          params: {
            includeAdhocPortfolios: true,
            dateContext: getDateContextStringWithUnderscore(dateContext, defaultDateContext),
            dateType: dateContext.type,
          },
        })
        .then(response => setBenchmarks(response.data))
        .catch((error: Error) =>
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Failed to load benchmarks from server: ${error.message}`,
          ),
        );
    else {
      setBenchmarks([]);
      benchInfoDispatch({ type: 'clear' });
    }
  }, [benchmarkPortfolioDrawerOpen, dateContext, defaultDateContext]);

  const onApplyClick = (data: Node<string>) => {
    const initBenchInfo = getInitialBenchInfo(data);

    const editRequest: EditRequest = {
      requestType: 'edit',
      benchmarkEdit: {
        datasetType: props.reportableType,
        benchInfo: benchInfo
          .filter(b => initBenchInfo.find(i => i.path === b.path)?.benchmark !== b.benchmark)
          .concat(
            initBenchInfo
              .filter(i => !benchInfo.find(b => b.path === i.path))
              .map(b => ({ path: b.path, benchmark: null })),
          ),
        sandbox: props.sandbox,
        date: getDateContextForPortfolioDrawer(defaultDateContext, dateContext),
      },
    };

    sendEditMessage(editRequest, props.token);

    props.closeBenchmarkPortfolioDrawer();
  };

  return (
    <PortfolioDrawer
      headerText='Change Benchmark Portfolio'
      drawerOpen={props.benchmarkPortfolioDrawerOpen}
      closeDrawer={props.closeBenchmarkPortfolioDrawer}
      checkboxes={false}
      sandbox={props.sandbox}
      onApplyClick={onApplyClick}
      dateContext={props.dateContext}
      portfolioHierarchy={portfolioHierarchy}
      setAsDefault={() => {}}
      resetToDefault={() => {}}
      className='benchmark-portfolio-drawer'
      cellComponent={itemProps => (
        <PortfolioItem
          {...itemProps.data}
          onSelect={(benchmark: string) =>
            benchInfoDispatch({
              type: 'set',
              benchInfo: { benchmark, path: itemProps.data.data.id },
            })
          }
          benchmarks={benchmarks}
          selectedBenchmark={benchInfo.find(b => b.path === itemProps.data.data.id)?.benchmark}
        />
      )}
      extraButton={<></>}
    />
  );
};

const benchInfoReducer = (state: BenchInfo[], action: BenchInfoAction) => {
  switch (action.type) {
    case 'initialize':
      return action.data;

    case 'set':
      if (action.benchInfo.benchmark === 'None')
        return state.filter(b => b.path !== action.benchInfo.path);
      else if (state.find(b => b.path === action.benchInfo.path))
        return state.map(b =>
          b.path === action.benchInfo.path ? { ...b, benchmark: action.benchInfo.benchmark } : b,
        );
      else return state.concat(action.benchInfo);

    case 'clear':
      return [];

    default:
      throw new Error('Invalid action');
  }
};

const getInitialBenchInfo = (data: Node<string>): BenchInfo[] =>
  mapData(data, 0)
    .filter(d => d.props?.benchmark)
    .map(d => ({ path: d.id, benchmark: d.props.benchmark }));

export default connector(BenchmarkPortfolioDrawer);
