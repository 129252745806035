import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import { ContentPage } from '../../redux/ui/contentPage';
import { getActivePage, isContentReady } from '../../redux/ui/selectors';
import Admin from '../admin/Admin';
import DesignerPanel from '../designer-panel/DesignerPanel';
import HomePage from '../home-page/HomePage';
import Metadata from '../metadata/Metadata';
import './page-content.scss';
import WorkspaceView from './WorkspaceView';

const mapStateToProps = (state: AppState) => ({
  contentReady: isContentReady(state),
  activePage: getActivePage(state),
});

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector>;

const PageContent: FC<Props> = props => {
  if (!props.contentReady) {
    return null;
  }

  switch (props.activePage) {
    case ContentPage.HOME:
      return <HomePage />;

    case ContentPage.WORKSPACE:
      return <WorkspaceView />;

    case ContentPage.DESIGNER:
      return <DesignerPanel />;

    case ContentPage.ADMIN:
      return <Admin />;

    case ContentPage.METADATA:
      return <Metadata />;

    default:
      return null;
  }
};

export default connector(PageContent);
