import { IconButton, Menu, MenuItem, Toolbar as MuiToolbar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FC, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import AlgoLogo from '../../assets/awa-logo.svg';
import { fetchDateContexts } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { setIsAboutOpen, setIsRemoteConsoleOpen } from '../../redux/ui/actionCreators';
import ManageContextsDrawer from '../drawers/ManageContextsDrawer';
import './top-bar.scss';
import TopBarGearMenu from './TopBarGearMenu';

interface BaseProps {
  handleLogout: Function;
  handleChangePassword: Function;
}

const mapStateToProps = (state: AppState) => ({
  firstname: state.user?.userInfo?.firstname,
  contextPermissions: state.user?.userInfo?.serverConfigs?.contextPermissions,
  allowChangePassword: state.user?.userInfo?.serverConfigs?.allowChangePassword,
  allowRemoteConsole: state.user?.userComponentPermission?.allowRemoteConsole,
});

const mapDispatchToProps = {
  setIsAboutOpen,
  fetchDateContexts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const menuIdentifier = {
  WORK: 'work',
  REPORT: 'report',
  CURRENCY: 'currency',
  ABOUT: 'about',
  USER: 'user',
};

const TopBar: FC<Props> = ({
  contextPermissions,
  fetchDateContexts,
  firstname,
  allowChangePassword,
  handleChangePassword,
  handleLogout,
  setIsAboutOpen,
  allowRemoteConsole,
}) => {
  const [anchorMenuEl, setAnchorMenuEl] = useState({
    work: null,
    report: null,
    currency: null,
    about: null,
    user: null,
  });

  const dispatch = useDispatch();

  const [isManageContextsDrawerOpen, setIsManageContextsDrawerOpen] = useState(false);
  const remoteConsoleFocus = useSelector((state: AppState) => state.ui?.remoteConsoleFocus);

  const handleMenuClose = value => {
    setAnchorMenuEl(prevState => ({ ...prevState, [value]: null }));
  };

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    setAnchorMenuEl(prevState => ({ ...prevState, [value]: event.currentTarget }));
  };

  return (
    <>
      <MuiToolbar className='top-bar'>
        <img src={AlgoLogo} style={{ width: 300 }} alt='SS&C Algorithmics Workspace Analyzer' />
        <TopBarGearMenu />
        <IconButton
          aria-label='Account'
          onClick={e => {
            fetchDateContexts();
            handleMenu(e, menuIdentifier.USER);
          }}
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          aria-label='Help'
          anchorEl={anchorMenuEl.user}
          open={Boolean(anchorMenuEl.user)}
          onClose={() => handleMenuClose(menuIdentifier.USER)}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose(menuIdentifier.USER);
            }}
          >
            <Typography> Hello {firstname}</Typography>
            <AccountCircleIcon style={{ fontSize: 32, paddingLeft: 6 }} />{' '}
          </MenuItem>
          {allowChangePassword && (
            <MenuItem
              onClick={() => {
                handleChangePassword();
                handleMenuClose(menuIdentifier.USER);
              }}
            >
              <Typography aria-label='ChangePassword'>Change Password</Typography>
            </MenuItem>
          )}
          {(contextPermissions?.publish || contextPermissions?.view) && (
            <MenuItem onClick={() => setIsManageContextsDrawerOpen(true)}>
              <Typography aria-label='Manage Contexts'>Manage Contexts</Typography>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleLogout();
              handleMenuClose(menuIdentifier.USER);
            }}
          >
            <Typography aria-label='Logout'>Logout</Typography>
          </MenuItem>
        </Menu>
        <IconButton aria-label='Open about' onClick={e => handleMenu(e, menuIdentifier.ABOUT)}>
          <HelpOutlineIcon />
        </IconButton>
        <Menu
          aria-label='Help'
          anchorEl={anchorMenuEl.about}
          open={Boolean(anchorMenuEl.about)}
          onClose={() => {
            handleMenuClose(menuIdentifier.ABOUT);
          }}
        >
          <MenuItem
            onClick={() => {
              setIsAboutOpen(true);
              handleMenuClose(menuIdentifier.ABOUT);
            }}
          >
            About
          </MenuItem>
          {allowRemoteConsole && (
            <MenuItem
              onClick={() => {
                remoteConsoleFocus?.();
                dispatch(setIsRemoteConsoleOpen(true));
                handleMenuClose(menuIdentifier.ABOUT);
              }}
            >
              Remote Console
            </MenuItem>
          )}
        </Menu>
      </MuiToolbar>
      <ManageContextsDrawer
        drawerOpen={isManageContextsDrawerOpen}
        contextPermissions={contextPermissions}
        closeDrawer={() => setIsManageContextsDrawerOpen(false)}
      />
    </>
  );
};

export default connector(TopBar);
