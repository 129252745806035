import HierarchyGrouperModal from './HierarchyGrouperModal';
import HistogramGrouperModal from './HistogramGrouperModal';
import HistoricHierarchyGrouperModal from './HistoricHierarchyGrouperModal';
import PortfolioHierarchyGrouperModal from './PortfolioHierarchyGrouperModal';
import SESScenarioGrouperModal from './SESScenarioGrouperModal';
import TimeBucketGrouperModal from './TimeBucketGrouperModal';

export const customGrouperImpls = {
  PortfolioHierarchyGrouper: PortfolioHierarchyGrouperModal,
  HistogramGrouper: HistogramGrouperModal,
  HistoricHierarchyGrouper: HistoricHierarchyGrouperModal,
  TimeBucketGrouper: TimeBucketGrouperModal,
  TakerHierarchyGrouper: HierarchyGrouperModal,
  ProductSetHierarchyGrouper: HierarchyGrouperModal,
  EntityHierarchyGrouper: HierarchyGrouperModal,
  SESScenarioGrouper: SESScenarioGrouperModal,
};
