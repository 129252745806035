import { Node, NodeType } from 'algo-react-dataviz';
import axios from 'axios';
import { enqueueSnackbar } from '../../../redux/ActionCreators';
import { AppThunk } from '../../../redux/configureStore';
import { DESIGNER_SEQUENCE_ID, NotificationLevel, RowField } from '../../../shared/constants';
import { DataConverters } from '../../../shared/dataConverters';
import {
  DrawerItemType,
  ParentToChildRelationship,
  ReportDefinition,
  ReportDefinitions,
  SerializedWorkspace,
  SerializedWorkspaceDefinition,
  WorkspaceData,
} from '../../../shared/dataTypes';
import { escapeCommas } from '../../../shared/utils';
import { baseUrl } from '../../shared/environment';

export const downloadURI = (uri: string, name: string): void => {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const extractHeaders = (node: Node<string>): string => {
  const depth = computeMaxDepth(node, 0);

  const bands: string[][] = [];

  Array(depth)
    .fill('')
    .forEach(() => bands.push([]));

  extractBands(node, bands, 0, true);

  return bands.map(band => band.join()).join('\n');
};

const computeMaxDepth = (node: Node<string>, currentDepth: number): number => {
  const children = node.children;

  if (node.type === NodeType.ROOT) {
    return Math.max(...children.map(c => computeMaxDepth(c, currentDepth)), currentDepth);
  }

  if (!children?.length) {
    return currentDepth + 1;
  }

  return Math.max(...children.map(c => computeMaxDepth(c, currentDepth + 1)), currentDepth);
};

const extractBands = (
  node: Node<string>,
  bands: string[][],
  level: number,
  firstChild: boolean,
): void => {
  const children = node.children;

  if (node.type === NodeType.ROOT) {
    children.forEach((c, idx) => extractBands(c, bands, level, idx === 0));
    return;
  }

  if (!children?.length) {
    // I am a leaf, I need to add myself plus empty cells above (if not first child) and/or below me
    bands.forEach((band, idx) => {
      if (idx < level && !firstChild) {
        band.push('');
      } else if (idx === level) {
        band.push(node.name);
      } else if (idx > level) {
        band.push('');
      }
    });
    return;
  }

  // this is an intermediate node, add myself ...
  bands[level].push(node.name);

  if (!firstChild) {
    // ... and if not first child, add empty cells above me ...
    bands.forEach((band, idx) => idx < level && band.push(''));
  }

  // and then iterate over children
  children.forEach((c, idx) => extractBands(c, bands, level + 1, idx === 0));
};

export const exportToCSV = (
  sequenceId: number,
  rowHeaders: string[],
  colHeaders: string[],
): AppThunk => (_dispatch, getState) => {
  const reportRawData = getState().report.reportData[sequenceId].raw;
  const exportCsvRaw = getState().user.userInfo?.serverConfigs?.exportCsvRaw;

  const headers = colHeaders == null ? extractHeaders(reportRawData.headers) : colHeaders.join(',');

  // Handle rows
  const convertedData = DataConverters.TABLE(reportRawData);

  const rowData = convertedData.rows
    .map((row, rowIdx) => {
      return row.data
        .map((rowElement, i) => {
          if (rowHeaders != null && i === 0) {
            return convertCSVRowValue(rowHeaders[rowIdx]);
          } else {
            const cell = rowElement[exportCsvRaw ? RowField.VAL : RowField.FORMATTED_VAL] || '';
            return escapeCommas(
              cell +
                (convertedData.headers[i].containsDiff
                  ? ' (Diff: ' +
                    (rowElement[exportCsvRaw ? RowField.DIFF_VAL : RowField.FORMATTED_DIFF_VAL] ||
                      '') +
                    ')'
                  : ''),
            );
          }
        })
        .join(',');
    })
    .join('\n');

  const data = new Blob([headers + '\n' + rowData], {
    type: 'csv/plain',
  });

  downloadURI(
    window.URL.createObjectURL(data),
    getState().report.reportDefinition[sequenceId]?.reportTitle + '.csv',
  );
};

// Convert a string such that all of it's quotes are converted to consecutive
// quotes and the entire string is then quoted.
// For example:
// Instrument Industry Sector:"Consumer, Cyclical" becomes "Instrument Industry Sector:""Consumer, Cyclical"""
const convertCSVRowValue = (value: string) =>
  value.includes('"') ? '"' + value.replaceAll('"', '""') + '"' : value;

export const triggerJsonExport = async (path: string, type: DrawerItemType) =>
  axios
    .get<unknown>(`${baseUrl}api/resourceDefinitions`, {
      params: {
        path,
        type,
      },
    })
    .then(({ data }) => {
      exportToJson(data, decodeURIComponent(path.replaceAll('/', '_')));
    });

// exports an object to a json file
export const exportToJson = <T>(value: T, path: string) => {
  const data = new Blob([JSON.stringify(value)], {
    type: 'application/json',
  });
  downloadURI(window.URL.createObjectURL(data), path + '.json');
};

// exports a report definition to a json file
export const exportReportDefToJson = (sequenceId: number): AppThunk => (_dispatch, getState) => {
  const data = new Blob([convertReportDefinition(getState().report.reportDefinition[sequenceId])], {
    type: 'application/json',
  });
  downloadURI(
    window.URL.createObjectURL(data),
    getState().report.reportDefinition[sequenceId].reportTitle + '.json',
  );
};

export const exportReportDescriptorToJson = (sequenceId: number): AppThunk => (
  dispatch,
  getState,
) => {
  axios
    .put(`${baseUrl}api/reportDescriptor`, getState().report.reportDefinition[sequenceId], {
      params: {
        date: getState().user.selectedDateContext?.date,
        id: getState().user.selectedDateContext?.id,
      },
    })
    .then(result => {
      const data = new Blob([JSON.stringify(result.data)], {
        type: 'application/json',
      });
      downloadURI(
        window.URL.createObjectURL(data),
        getState().report.reportDefinition[sequenceId].reportTitle + '-rest.json',
      );
    })
    .catch(error =>
      dispatch(enqueueSnackbar(NotificationLevel.ERROR, `${error.response.data.message}`)),
    );
};

// exports a report definition as json to clipboard
export const copyReportDefToJson = (sequenceId: number): AppThunk => (dispatch, getState) => {
  navigator.clipboard.writeText(
    convertReportDefinition(getState().report.reportDefinition[sequenceId]),
  );
  dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Copied Report to Clipboard'));
};

// converts a report definition to an adhoc (with null path) and returns it as json string
const convertReportDefinition = (reportDefinition: ReportDefinition): string => {
  const def = {
    ...reportDefinition,
    path: null,
  };

  return JSON.stringify(def);
};

// exports a workspace definition to a json file
export const exportWorkspaceDefToJson = (): AppThunk => (_dispatch, getState) => {
  const { data, selectedTabIndex, parentToChildRelationship } = getState().workspace;
  const { reportDefinition } = getState().report;

  const blobData = new Blob(
    [
      convertWorkspaceDefinition(
        data,
        selectedTabIndex,
        parentToChildRelationship,
        reportDefinition,
      ),
    ],
    {
      type: 'application/json',
    },
  );

  downloadURI(
    window.URL.createObjectURL(blobData),
    data.path
      ? data.path.substring(data.path.lastIndexOf('/') + 1) + '.json'
      : 'untitled_workspace.json',
  );
};

export const copyWorkspaceDefToJson = (): AppThunk => (dispatch, getState) => {
  const { data, selectedTabIndex, parentToChildRelationship } = getState().workspace;
  const { reportDefinition } = getState().report;

  try {
    navigator.clipboard.writeText(
      convertWorkspaceDefinition(
        data,
        selectedTabIndex,
        parentToChildRelationship,
        reportDefinition,
      ),
    );
    dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Copied Workspace to Clipboard'));
  } catch (e) {
    console.error('Error exporting workspace', e);
    dispatch(enqueueSnackbar(NotificationLevel.ERROR, 'Error exporting workspace'));
  }
};

// converts a workspace definition to an adhoc (with null path) and returns it as json string
const convertWorkspaceDefinition = (
  data: WorkspaceData,
  selectedTabIndex: number,
  parentToChildRelationship: ParentToChildRelationship,
  reportDefinitions: ReportDefinitions,
): string => {
  const workspaceDefinition: SerializedWorkspaceDefinition = {
    data: { ...data, path: null },
    parentToChildRelationship,
    selectedTabIndex,
  };

  const updatedReportDefinitions: ReportDefinitions = {};

  Object.entries(reportDefinitions).forEach(([sequenceId, reportDefinition]) => {
    if (sequenceId === `${DESIGNER_SEQUENCE_ID}`) return;
    updatedReportDefinitions[parseInt(sequenceId)] = {
      ...reportDefinition,
      path: null,
    };
  });

  const dataFormat: SerializedWorkspace = [workspaceDefinition, updatedReportDefinitions];
  return JSON.stringify(dataFormat);
};
