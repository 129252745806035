import { Monaco } from '@monaco-editor/react';
import { editor, Uri } from 'monaco-editor';
import { createObject } from '../shared/utils';
import { createDefaultContents, EditorContents, EditorLanguage } from './editorLanguage';

export interface EditorModelApi {
  doModelsExist: () => boolean;

  getPath: (language: EditorLanguage) => string;

  getValue: (language: EditorLanguage) => string | null;
  setValue: (language: EditorLanguage, value: string) => void;
  getEditorContents: () => EditorContents;
  setEditorContents: (contents: EditorContents) => void;

  setMarkers: (language: EditorLanguage, markers: editor.IMarkerData[]) => void;
  clearMarkers: (language: EditorLanguage) => void;
  clearAllMarkers: () => void;
}

class EditorModelService implements EditorModelApi {
  static getPath = (modelId: string, language: EditorLanguage) => `/${modelId}/${language}`;
  private static MARKER_OWNER = 'owner';

  constructor(
    private readonly id: string,
    private monaco: Monaco,
    private languages: EditorLanguage[],
    initialContents: EditorContents<EditorLanguage> = createDefaultContents(languages),
  ) {
    // Create models if they aren't loaded already
    this.languages.forEach(language => this.initializeModel(language, initialContents[language]));
  }

  getPath = (language: EditorLanguage) => EditorModelService.getPath(this.id, language);

  private initializeModel = (language: EditorLanguage, initialValue = '') => {
    const model = this.getModel(language);
    if (model === null) {
      this.monaco.editor.createModel(initialValue, language, Uri.parse(this.getPath(language)));
    } else {
      model.setValue(initialValue);
    }
  };

  private getModels = () =>
    this.monaco.editor
      .getModels()
      .filter(model => this.languages.some(language => model.uri.path === this.getPath(language)));

  private getModel = (language: EditorLanguage) =>
    this.getModels().find(model => model.uri.path === this.getPath(language)) ?? null;

  doModelsExist = () => this.getModels().length === this.languages.length;

  getValue = (language: EditorLanguage) => this.getModel(language)?.getValue() ?? null;

  setValue = (language: EditorLanguage, value: string) => {
    this.getModel(language)?.setValue(value);
  };

  getEditorContents = (): EditorContents =>
    createObject({
      items: this.languages,
      createValue: this.getValue,
    });

  setEditorContents = (contents: EditorContents<EditorLanguage>) => {
    this.languages.forEach(language => {
      this.setValue(language, contents[language]);
    });
  };

  setMarkers = (language: EditorLanguage, markers: editor.IMarkerData[]) => {
    this.monaco.editor.setModelMarkers(
      this.getModel(language),
      EditorModelService.MARKER_OWNER,
      markers,
    );
  };

  clearMarkers = (language: EditorLanguage) => this.setMarkers(language, []);
  clearAllMarkers = () => this.languages.forEach(this.clearMarkers);
}

export default EditorModelService;
