import { Chip, LinearProgress, Select, Typography } from '@material-ui/core';
import { LIGHT_BORDER } from 'algo-react-dataviz';
import { FC } from 'react';
import MenuItems from '../designer-panel/ui-elements/MenuItems';
import { getLabel, getRefreshIntervals } from './model';
import './remote-console-bar.scss';

interface Props {
  lastRefresh: Date | null;
  refreshInterval: number;
  progress: number;
  setRefreshInterval: (interval: number) => void;
  offline: boolean;
}

const RemoteConsoleBar: FC<Props> = props => {
  const normalizeProgress = (value: number) => (value * 100) / props.refreshInterval;

  return (
    <div
      className='rconsole-bar-container'
      style={{
        borderTop: LIGHT_BORDER,
      }}
    >
      <LinearProgress variant='determinate' value={normalizeProgress(props.progress)} />

      <div className='rconsole-bar-info-container'>
        {props.offline ? (
          <Chip label='Server unreachable' className='chip' />
        ) : (
          <Typography>{`Last refresh: ${props.lastRefresh || 'never'}`}</Typography>
        )}

        <div className='rconsole-bar-settings'>
          <Typography style={{ paddingRight: '5px' }}>Refresh Rate: </Typography>

          <Select
            value={props.refreshInterval}
            onChange={e => props.setRefreshInterval(Number(e.target.value))}
            variant='standard'
            color='primary'
          >
            {MenuItems(
              getRefreshIntervals().map(interval => ({
                value: interval,
                label: <Typography color='primary'>{getLabel(interval)}</Typography>,
              })),
            )}
          </Select>
        </div>
      </div>
    </div>
  );
};
export default RemoteConsoleBar;
