import { RoundedButton } from 'algo-react-dataviz';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isAdHoc } from '../../../model/custom-grouping/customGrouping';
import { enqueueSnackbar, loadAllChars, regenerateIfAuto } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { closeDrawer, setSaveModalOpen } from '../../../redux/custom-grouping/actions';
import {
  getDrawerStatus,
  isCustomGroupingInReport,
} from '../../../redux/custom-grouping/selectors';
import { CustomGroupingDrawerStatus } from '../../../redux/custom-grouping/state';
import { updateWithAdHocGrouping } from '../../../redux/ReportActionCreators';
import { NotificationLevel } from '../../../shared/constants';
import useCustomGroupingState from '../context/useCustomGroupingState';
import CustomGroupingDeleteModal from '../delete-modal/CustomGroupingDeleteModal';
import DrawerFooter, { ButtonProps } from './DrawerFooterReimp';

enum SaveType {
  SAVE_AS,
  SAVE,
}

const CustomGroupingDrawerFooter: FC = () => {
  const dispatch = useDispatch();
  const drawerStatus = useAppSelector(getDrawerStatus);

  const [deleteModlaOpen, setDeleteModlaOpen] = useState(false);

  const {
    hasModelController,
    allowApply,
    allowUpdate,
    allowDelete,
    updateCustomGrouping,
    grouping,
  } = useCustomGroupingState();

  const customGroupingInReport = useAppSelector(isCustomGroupingInReport(grouping));

  const close = useCallback(() => {
    dispatch(closeDrawer());
  }, [dispatch]);

  const intendDelete = useCallback(() => {
    setDeleteModlaOpen(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setDeleteModlaOpen(false);
  }, []);

  const onApplySuccessful = useCallback(() => {
    dispatch(closeDrawer());
    dispatch(enqueueSnackbar(NotificationLevel.SUCCESS, 'Custom grouping updated successfully'));

    if (!isAdHoc(grouping)) {
      dispatch(loadAllChars());
    }

    if (customGroupingInReport) {
      dispatch(regenerateIfAuto());
    }
  }, [dispatch, grouping, customGroupingInReport]);

  const applyAdHocGrouping = useCallback(
    (removePrevious: boolean) => {
      dispatch(
        updateWithAdHocGrouping(
          {
            ...grouping,
            name: null,
            description: null,
            owner: null,
          },
          removePrevious,
        ),
      );

      onApplySuccessful();
    },
    [dispatch, grouping, onApplySuccessful],
  );

  const saveCustomGrouping = useCallback(
    async () =>
      updateCustomGrouping().then(isSuccessful => {
        if (isSuccessful) {
          onApplySuccessful();
        }
      }),
    [updateCustomGrouping, onApplySuccessful],
  );

  const intendSave = useCallback(
    async (saveType: SaveType) => {
      if (!isAdHoc(grouping) && saveType === SaveType.SAVE) {
        saveCustomGrouping();
      } else {
        dispatch(setSaveModalOpen(true));
      }
    },
    [dispatch, grouping, saveCustomGrouping],
  );

  const primaryButtons: ButtonProps[] = useMemo(() => {
    switch (drawerStatus) {
      case CustomGroupingDrawerStatus.CREATE: {
        return [
          {
            label: 'Save As',
            onClick: () => intendSave(SaveType.SAVE_AS),
            disabled: !hasModelController || !allowApply(),
          },
          {
            label: 'Apply',
            onClick: () => applyAdHocGrouping(false),
            disabled: !hasModelController || !allowApply(),
          },
        ];
      }

      case CustomGroupingDrawerStatus.EDIT: {
        return [
          ...(isAdHoc(grouping)
            ? []
            : [
                {
                  label: 'Save',
                  onClick: () => intendSave(SaveType.SAVE),
                  disabled: !hasModelController || !allowUpdate(),
                },
              ]),
          {
            label: 'Save As',
            onClick: () => intendSave(SaveType.SAVE_AS),
            disabled: !hasModelController || !allowApply(),
          },
          ...(isAdHoc(grouping)
            ? [
                {
                  label: 'Apply',
                  onClick: async () => {
                    if (isAdHoc(grouping)) {
                      applyAdHocGrouping(false);
                    } else {
                      saveCustomGrouping();
                    }
                  },
                  disabled: !hasModelController || !allowUpdate(),
                },
              ]
            : [
                {
                  label: 'Add as Ad Hoc',
                  onClick: async () => {
                    // remove previous one too
                    applyAdHocGrouping(true);
                  },
                  disabled: !hasModelController || !allowApply(),
                },
              ]),
        ];
      }

      case CustomGroupingDrawerStatus.VIEW: {
        return [
          {
            label: 'Save As',
            onClick: () => intendSave(SaveType.SAVE_AS),
            disabled: !hasModelController || !allowApply(),
          },
          {
            label: 'Add as Ad Hoc',
            onClick: async () => {
              applyAdHocGrouping(true);
            },
            disabled: !hasModelController || !allowApply(),
          },
        ];
      }

      default: {
        return [];
      }
    }
  }, [
    intendSave,
    applyAdHocGrouping,
    saveCustomGrouping,
    allowApply,
    allowUpdate,
    hasModelController,
    grouping,
    drawerStatus,
  ]);

  return (
    <DrawerFooter {...{ primaryButtons }} cancelButton={{ onClick: close }}>
      <CustomGroupingDeleteModal open={deleteModlaOpen} closeModal={closeDeleteModal} />

      {drawerStatus === CustomGroupingDrawerStatus.EDIT && (
        <RoundedButton
          color='secondary'
          onClick={intendDelete}
          disabled={!hasModelController || !allowDelete()}
        >
          Delete
        </RoundedButton>
      )}
    </DrawerFooter>
  );
};

export default CustomGroupingDrawerFooter;
