import { FC, useContext } from 'react';
import { useAppSelector } from '../../../redux/configureStore';
import { getDefaultContext } from '../../../redux/position-drawer/selectors';
import { Sandbox } from '../../../shared/dataTypes';
import { getDateContextString } from '../../../shared/utils';
import InstrumentSelector from '../proxy-drawer/InstrumentSelector';
import { getDrawerDateContext } from './PositionDrawer';
import PositionInstrumentItem from './PositionInstrumentItem';
import { PositionDrawerContext } from './reducer';

interface Props {
  sandbox: Sandbox;
}

const AddInstruments: FC<Props> = props => {
  const { state, dispatch } = useContext(PositionDrawerContext);

  const dateContext = useAppSelector(reduxState => getDrawerDateContext(reduxState, state));
  const defaultDateContext = useAppSelector(getDefaultContext);

  return (
    <div className='add-instruments'>
      <InstrumentSelector
        label={`Add instruments or funds to ${state.selectedPortfolioOrBenchmark.id}`}
        placeholder='Search for an instrument or fund...'
        disabled={false}
        selectedInstruments={state.selectedInstruments}
        onChange={(instrument, selected) =>
          dispatch(
            selected
              ? { type: 'addSelectedInstrument', instrument }
              : { type: 'removeSelectedInstrument', instrumentId: instrument.id },
          )
        }
        sandbox={props.sandbox}
        dateContextString={getDateContextString(
          { type: 'asOf', dates: [dateContext] },
          defaultDateContext,
        )}
        dateContextType={'asOf'}
        includeFunds
      />
      <div className='position-instrument-item-list'>
        {state.selectedInstruments.map((instrument, key) => (
          <PositionInstrumentItem {...{ instrument, key }} />
        ))}
      </div>
    </div>
  );
};

export default AddInstruments;
