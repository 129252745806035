import { FC, useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';

const AreaChartViz: FC<ReportProps> = props => {
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.AREA_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const dataKey = reportConvertedData.headers[0];

  const areaData = reportConvertedData.rows;

  const gradientOffset = () => {
    const dataMax = Math.max(...areaData.map((i: any) => i[`${dataKey}`]));
    const dataMin = Math.min(...areaData.map((i: any) => i[`${dataKey}`]));

    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };

  const off = gradientOffset();

  const isInverted = props.isInverted;

  const xAxisProps: XAxisProps = isInverted
    ? { type: 'number' }
    : { dataKey: reportConvertedData.groupingHeader.name };
  const yAxisProps: YAxisProps = isInverted
    ? { dataKey: reportConvertedData.groupingHeader.name, type: 'category' }
    : {};

  return (
    <AreaChart
      layout={isInverted ? 'vertical' : 'horizontal'}
      width={props.width}
      height={props.height}
      data={areaData}
      margin={{ left: 30 }}
      onClick={event =>
        event &&
        props.onElementClick(
          event.activePayload[0].payload[`${event.activePayload[0].name}rowcol`],
        )(event)
      }
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis {...xAxisProps} />
      <YAxis {...yAxisProps} />
      <Tooltip formatter={(value, name, props) => props.payload[`${name}Formatted`]} />
      <defs>
        <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
          <stop offset={off} stopColor='green' stopOpacity={1} />
          <stop offset={off} stopColor='red' stopOpacity={1} />
        </linearGradient>
      </defs>
      <Area type='monotone' dataKey={dataKey} stroke='#000' fill='url(#splitColor)' />
    </AreaChart>
  );
};

export default AreaChartViz;
