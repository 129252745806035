import { Dialog, FormControl, IconButton, Paper, Select } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { Modifier } from 'algo-react-dataviz';
import axios from 'axios';
import equal from 'deep-equal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DroppableId, DropResult } from 'react-beautiful-dnd';
import { connect, ConnectedProps } from 'react-redux';
import SplitPane from 'react-split-pane';
import { v4 } from 'uuid';
import {
  addFilter,
  enqueueSnackbar,
  generateReportPreview,
  loadAllChars,
  removeAllFilters,
  reorderFilter,
  setSetting,
  setShouldRegenerate,
} from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { isDesignerDrillThrough } from '../../redux/designer/panel/selectors';
import {
  resetDesigner,
  setReportableType,
  setReportDefinitionCharacteristics,
  setReportDefinitionManualGroupingOverride,
  setReportDefinitionSort,
} from '../../redux/ReportActionCreators';
import { promptCloseDesigner } from '../../redux/ui/actionCreators';
import { createCharSearchComparator, processSearchString } from '../../shared/charComparators';
import {
  CHARACTERISTIC_CHAR_ID,
  CustomGrouperDataImpls,
  DataType,
  DESIGNER_SEQUENCE_ID,
  DrawerType,
  NodeType,
  NotificationLevel,
} from '../../shared/constants';
import {
  Characteristic,
  DroppableState,
  LayerDefinition,
  Node,
  RepTypesIdName,
} from '../../shared/dataTypes';
import useDebouncedState from '../../shared/useDebouncedState';
import UserPromptTitle from '../../shared/UserPromptTitle';
import {
  charToLayerDef,
  getSelectedSandbox,
  getSelectedVisualization,
  hasLinkedGroup,
  hasNoPendingBreakpoints,
  hasNoPendingLinkedGroups,
  isCharCustomGrouping,
  isCharEqualToLayer,
  isCharsEqual,
  supportsDiscreteBreakpoints,
} from '../../shared/utils';
import TreeFolderList from '../folder-list/TreeFolderList';
import FullScreenModal from '../full-screen-modal/FullScreenModal';
import { baseUrl } from '../shared/environment';
import DesignerPanelFooter from './DesignerPanelFooter';
import DiscreteBreakpointModal from './DiscreteBreakpointModal';
import {
  AVAILABLE_CHARS,
  CHARACTERISTICS_ID,
  copy,
  defaultHorizChar,
  FILTER_CHARS,
  HORIZONTAL_ID,
  initialDroppableState,
  move,
  reorder,
  VERTICAL_ID,
} from './drag-and-drop/beautifulDndHelpers';
import CharacteristicsDroppable from './drag-and-drop/CharacteristicsDroppable';
import CharacteristicsMenu from './drag-and-drop/CharacteristicsMenu';
import { GroupingLayerId } from './drag-and-drop/groupingLayerId';
import GrouperModal from './GrouperModal';
import DesignerPanelHeader from './header/DesignerPanelHeader';
import NicknameDialog from './NicknameDialog';
import getReportGenerationNotAllowedReasons from './reportGenerationAllowed';
import ReportPreview from './ReportPreview';
import AddCustomGroupingButton from './ui-elements/AddCustomGroupingButton';
import FavoritesIcon from './ui-elements/FavoritesIcon';
import MenuItems from './ui-elements/MenuItems';
import ReportDesignerSettingsIcon from './ui-elements/ReportDesignerSettingsIcon';
import { InputWithItalicPlaceholder } from './ui-elements/StyledElements';

const GROUPING_CHAR_ID = 6;
const SEARCH_DEBOUNCE_DELAY = 400; // ms

const mapStateToProps = (state: AppState) => ({
  reportDefinition: state.report.reportDefinition?.[DESIGNER_SEQUENCE_ID],
  manualGroupingOverride: state.reportDesigner?.settings?.manualGroupingOverride,
  reportData: state.report.reportData?.[DESIGNER_SEQUENCE_ID],
  favoriteCharacteristics: state.user.userInfo?.userPreferences?.favoriteCharacteristics,
  favoriteCustomGroupings: state.user.userInfo?.userPreferences?.favoriteCustomGroupings,
  allChars: state.reportDesigner.panelControl.allChars,
  isDrillThrough: isDesignerDrillThrough(state),
  shouldRegenerate: state.reportDesigner.panelControl.shouldRegenerate,
  isAutoGenerate: state.reportDesigner.panelControl.isAutoGenerate,
  workspaceDateContext: state.user.selectedDateContext,
  sandbox: getSelectedSandbox(state, state.reportDesigner.panelControl.sourceSequenceId),
});

const mapDispatchToProps = {
  addFilter,
  promptCloseDesigner,
  enqueueSnackbar,
  generateReportPreview,
  loadAllChars,
  removeAllFilters,
  reorderFilter,
  resetDesigner,
  setReportableType,
  setReportDefinitionCharacteristics,
  setReportDefinitionSort,
  setReportDefinitionManualGroupingOverride,
  setSetting,
  setShouldRegenerate,
};
const draggableIdRemover = (draggables: Characteristic[]): Characteristic[] =>
  draggables?.map(c => ({
    ...c,
    draggableId: null,
  }));

const decodeNodes = (data: Node<string>): Node<string> => {
  if (!data.children)
    return {
      ...data,
      name: decodeURIComponent(data.name),
    };
  else {
    return {
      ...data,
      name: decodeURIComponent(data.name),
      children: data.children.map(c => decodeNodes(c)),
    };
  }
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const DesignerPanel: React.FC<Props> = ({
  promptCloseDesigner,
  enqueueSnackbar,
  setReportDefinitionCharacteristics,
  generateReportPreview,
  setReportableType,
  reportDefinition,
  reportData,
  isDrillThrough,
  favoriteCharacteristics = [],
  favoriteCustomGroupings = [],
  addFilter,
  reorderFilter,
  removeAllFilters,
  resetDesigner,
  allChars,
  loadAllChars,
  setSetting,
  isAutoGenerate,
  setReportDefinitionManualGroupingOverride,
  manualGroupingOverride,
  shouldRegenerate,
  setShouldRegenerate,
  setReportDefinitionSort,
  workspaceDateContext,
  sandbox,
}) => {
  const [classList, setClassList] = useState<string[]>([]);

  const [selectedClass, setSelectedClass] = useState('');
  const [isFavFilterActive, setFavFilterActive] = useState(false);
  const [showBench, setShowBench] = useState(false);
  const [showDiff, setShowDiff] = useState(false);

  const {
    state: searchTerm,
    debouncedState: debouncedSearchTerm,
    setState: setSearchTerm,
    resetState: clearSearchTerm,
  } = useDebouncedState('', SEARCH_DEBOUNCE_DELAY);

  const filteredChars = useMemo(
    () =>
      allChars
        .filter(
          char =>
            (isFavFilterActive ||
              showBench ||
              ![Modifier.BENCH, Modifier.BENCH2].includes(char.modifier)) &&
            (isFavFilterActive ||
              showDiff ||
              ![Modifier.DIFF, Modifier.DIFF2].includes(char.modifier)) &&
            (!char.classes || char.classes.includes(selectedClass)) &&
            (!debouncedSearchTerm ||
              processSearchString(char.name).includes(processSearchString(debouncedSearchTerm))) &&
            (!isFavFilterActive ||
              (isCharCustomGrouping(char)
                ? favoriteCustomGroupings.includes(char.name)
                : favoriteCharacteristics.some(
                    fav => fav.charId === char.charId && fav.modifier === char.modifier,
                  ))),
        )
        .sort(createCharSearchComparator(debouncedSearchTerm)),
    [
      allChars,
      selectedClass,
      debouncedSearchTerm,
      isFavFilterActive,
      showBench,
      showDiff,
      favoriteCharacteristics,
      favoriteCustomGroupings,
    ],
  );

  const {
    reportableType,
    dateContext,
    currency,
    chars,
    verticalChars,
    horizontalChars,
    filters,
    scenariosConfig,
    settings,
    benchmarks,
  } = reportDefinition || {};

  // Using useMemo because this can be an expensive calculation.
  const droppableState: DroppableState = useMemo(() => {
    const characteristicGenerator = (c: LayerDefinition) => {
      const returnable: Characteristic = {
        ...allChars.concat(defaultHorizChar).find(d => isCharEqualToLayer(d, c)),
        draggableId: v4(),
      };
      if (c.linkedGroupingPath) {
        returnable.linkedGroupingPath = c.linkedGroupingPath;
        returnable.name = c.linkedGroupingPath.split('/').pop();
      }
      if (c.grouperData) {
        returnable.grouperData = c.grouperData;
      }
      if (c.breakpoints) {
        returnable.breakpoints = c.breakpoints;
      }
      if (c.layerId === GroupingLayerId.CUSTOM_GROUPING) {
        returnable.charId = c.layerId;
        returnable.id = c.customGrouping.id;
        returnable.name = c.customGrouping.name ?? 'Ad Hoc Custom Grouping';
        returnable.customGrouping = c.customGrouping; // TODO: SD-2534
      }

      return returnable;
    };

    return allChars.length
      ? {
          chars:
            chars?.map(c => ({
              ...allChars
                .concat(defaultHorizChar)
                .find(d => d.charId === c.charId && d.modifier === c.modifier),
              draggableId: v4(),
            })) || [],
          verticalChars: verticalChars?.map(characteristicGenerator) || [],
          horizontalChars: horizontalChars?.map(characteristicGenerator) || [],
        }
      : initialDroppableState;
  }, [allChars, chars, verticalChars, horizontalChars]);

  const onFavoriteClick = isActive => () => {
    !isActive && clearSearchTerm();
    setFavFilterActive(!isActive);
  };

  const setDroppableState = useCallback(
    (ds: DroppableState) =>
      setReportDefinitionCharacteristics(
        ds.chars.map(c => ({ charId: c.charId, modifier: c.modifier, name: c.name })),
        ds.verticalChars.map(charToLayerDef),
        ds.horizontalChars.map(charToLayerDef),
      ),
    [setReportDefinitionCharacteristics],
  );

  const onGenerateReportPreview = useCallback(generateReportPreview, [generateReportPreview]);

  const onCleanup = useCallback(() => {
    // Reset Redux state
    resetDesigner();
    setDroppableState(initialDroppableState);
    removeAllFilters();
  }, [resetDesigner, setDroppableState, removeAllFilters]);

  useEffect(() => onCleanup, [onCleanup]);

  useEffect(() => {
    // User has opened the modal - retrieve column classes.
    axios
      .get<string[]>(`${baseUrl}api/columnClasses`)
      .then(response => {
        setClassList(response.data);
        setSelectedClass('All');
      })
      .catch((error: Error) =>
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to load column classes from server: ${error.message}`,
        ),
      );
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (reportableType) {
      // User has modal open and the reportable type has been set.
      // Load characteristics for the given reportable from the server.
      loadAllChars();
    }
  }, [reportableType, loadAllChars]);

  const [reportableTypes, setReportableTypes] = useState<RepTypesIdName[]>([]);

  useEffect(() => {
    // User has opened the modal, get reportable types.
    axios
      .get<RepTypesIdName[]>(`${baseUrl}api/reportableTypes`)
      .then(response => {
        setReportableTypes(response.data);
      })
      .catch((error: Error) => {
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to load reportable types from server: ${error.message}`,
        );
      });
  }, [enqueueSnackbar, setReportableType]);

  const reportPending = !!Object.keys(reportData?.pendingRequests || {}).length;
  const reportExists = !!reportData?.raw;

  const reportGenerationNotAllowedReasons = getReportGenerationNotAllowedReasons(
    reportDefinition,
    workspaceDateContext,
  );
  const reportGenerationAllowed = reportGenerationNotAllowedReasons.length === 0;
  const charsNoDraggable = draggableIdRemover(droppableState.chars);
  const verticalCharsNoDraggable = draggableIdRemover(droppableState[VERTICAL_ID]);
  const horizontalCharsNoDraggable = draggableIdRemover(droppableState[HORIZONTAL_ID]);

  const linkedGroupsAreValid =
    hasNoPendingLinkedGroups(droppableState[VERTICAL_ID]) &&
    hasNoPendingLinkedGroups(droppableState[HORIZONTAL_ID]);

  const breakpointsAreValid =
    hasNoPendingBreakpoints(droppableState[VERTICAL_ID]) &&
    hasNoPendingBreakpoints(droppableState[HORIZONTAL_ID]);

  const handleAutoGenerateOnDrop = (droppableState: DroppableState) => {
    const linkedGroupPresent =
      hasLinkedGroup(droppableState[VERTICAL_ID]) || hasLinkedGroup(droppableState[HORIZONTAL_ID]);

    // Can't call generate directly here because, there are unprocessed state changes.
    setShouldRegenerate(isAutoGenerate && (!linkedGroupPresent || linkedGroupsAreValid));
  };

  const generateIfAllowed = useCallback(() => {
    if (
      shouldRegenerate &&
      reportGenerationAllowed &&
      linkedGroupsAreValid &&
      breakpointsAreValid
    ) {
      setShouldRegenerate(false);
      onGenerateReportPreview();
    }
  }, [
    shouldRegenerate,
    setShouldRegenerate,
    onGenerateReportPreview,
    reportGenerationAllowed,
    linkedGroupsAreValid,
    breakpointsAreValid,
  ]);

  const stateAsString = JSON.stringify({
    charsNoDraggable,
    verticalCharsNoDraggable,
    horizontalCharsNoDraggable,
    filters,
    scenariosConfig,
    settings,
    benchmarks,
  });

  useEffect(generateIfAllowed, [
    stateAsString,
    isAutoGenerate,
    reportableType,
    dateContext,
    currency,
    generateIfAllowed,
  ]);

  // this function will check the new droppableState and add or remove Grouping characteristic based on the rules described
  const checkAndUpdateGroupingCharacteristic = useCallback(
    (droppableState: DroppableState): DroppableState => {
      if (manualGroupingOverride) {
        // user chose to manually override automatic grouping behavior
        // we return state as is
        return droppableState;
      }
      if (
        droppableState[HORIZONTAL_ID].filter(char => char.charId !== CHARACTERISTIC_CHAR_ID)
          .length > 0
      ) {
        // Make sure there is no Grouping in Characteristics panel if horizontal has a grouping other than Characteristics
        return removeGroupingIfPresent(droppableState);
      } else {
        // If there is no horizontal grouping other than Characteristics and there is at least one vertical grouping other than Characteristics add Grouping to Characteristics panel
        if (
          droppableState[VERTICAL_ID].filter(char => char.charId !== CHARACTERISTIC_CHAR_ID)
            .length > 0
        ) {
          return addGroupingIfNotPresent(droppableState);
        }
      }
      // none of the special cases occur, so we leave Grouping characteristic as is (present or not as the user wishes)
      return droppableState;
    },
    [manualGroupingOverride],
  );

  // if manualGroupingOverride in ReportDefinition changes, we need to check if we went to auto mode
  // if we went to auto mode, we need to run the logic to check if Grouping needs to be added or removed automatically
  useEffect(() => {
    if (!manualGroupingOverride) {
      const newDroppableState = checkAndUpdateGroupingCharacteristic(droppableState);
      if (!equal(droppableState, newDroppableState)) {
        setDroppableState(newDroppableState);
      }
    }
  }, [
    checkAndUpdateGroupingCharacteristic,
    manualGroupingOverride,
    droppableState,
    setDroppableState,
  ]);

  const hasModifier = (modifier: number) => modifier !== 0;
  const isFilterPresent = (char: Characteristic): boolean =>
    reportDefinition.filters.some(filter => filter.charId === char.charId);

  const filterIsValid = (char: Characteristic): boolean =>
    !isFilterPresent(char) && !hasModifier(char.modifier) && !char.isGroupingLayer;

  const onDragEnd: (result: DropResult) => void = result => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const sourceChars: Characteristic[] =
      source.droppableId === AVAILABLE_CHARS ? filteredChars : droppableState[source.droppableId];

    if (
      !(sourceChars[source.index].charId === GroupingLayerId.CUSTOM_GROUPING) &&
      source.droppableId !== destination.droppableId &&
      droppableState[destination.droppableId]?.find(
        ds =>
          isCharsEqual(sourceChars[source.index], ds) &&
          !(sourceChars[source.index].charId === GroupingLayerId.LINKED && ds.linkedGroupingPath),
      )
    )
      // Can't have two of the same char/modifier in the same panel.
      return;

    if (source.droppableId === AVAILABLE_CHARS && destination.droppableId === FILTER_CHARS) {
      if (filterIsValid(sourceChars[source.index])) {
        addFilter({ ...filteredChars[source.index], draggableId: v4() }, destination.index);
      }
      return;
    }

    if (source.droppableId === FILTER_CHARS && destination.droppableId === FILTER_CHARS) {
      reorderFilter(source.index, destination.index);
      return;
    }

    let newState: DroppableState = {};

    switch (source.droppableId) {
      case destination.droppableId:
        // User is reordering items within a list
        newState = {
          ...droppableState,
          [destination.droppableId]: reorder(
            droppableState[source.droppableId],
            source.index,
            destination.index,
          ),
        };
        break;
      case AVAILABLE_CHARS:
        // User is dragging something in from Available Chars list
        newState = {
          ...droppableState,
          [destination.droppableId]: copy(
            filteredChars,
            droppableState[destination.droppableId],
            source,
            destination,
          ),
        };
        // User is dragging the LINKED choice to vertical or horizontal list.
        if (filteredChars[source.index].charId === GroupingLayerId.LINKED) {
          if (
            destination.droppableId === VERTICAL_ID ||
            destination.droppableId === HORIZONTAL_ID
          ) {
            axios
              .get<Node<string>>(`${baseUrl}api/groupingFolders`)
              .then(result => {
                setGroupingFolderStructure(decodeNodes(result.data));
                setGroupingsOpen(true);
              })
              .catch(error =>
                enqueueSnackbar(
                  NotificationLevel.ERROR,
                  `Failed to load linked groupings from server: ${error.message}`,
                ),
              );
          }
        } else {
          if (
            destination.droppableId === VERTICAL_ID ||
            destination.droppableId === HORIZONTAL_ID
          ) {
            if (supportsDiscreteBreakpoints(filteredChars[source.index].dataType)) {
              setBreakpointEditorInfo({
                orientation: destination.droppableId,
                charId: filteredChars[source.index].charId,
              });
            }
          }
        }

        break;
      default:
        // User is moving something from one list to another
        const result = move(
          droppableState[source.droppableId],
          droppableState[destination.droppableId],
          source,
          destination,
        );
        newState = {
          ...droppableState,
          [source.droppableId]: result[source.droppableId],
          [destination.droppableId]: result[destination.droppableId],
        };

        // If this is a move from the characteristics list to vertical/horizontal,
        // then allow the user to set breakpoints if it's applicable for the characteristic.
        if (
          source.droppableId === CHARACTERISTICS_ID &&
          (destination.droppableId === VERTICAL_ID || destination.droppableId === HORIZONTAL_ID)
        ) {
          if (
            supportsDiscreteBreakpoints(droppableState[source.droppableId][source.index].dataType)
          ) {
            setBreakpointEditorInfo({
              orientation: destination.droppableId,
              charId: droppableState[source.droppableId][source.index].charId,
            });
          }
        }

        break;
    }

    if (newState[HORIZONTAL_ID].filter(char => char.charId !== CHARACTERISTIC_CHAR_ID).length > 0) {
      setReportDefinitionSort(DESIGNER_SEQUENCE_ID, []);
    }
    if (sourceChars[source.index].charId === GROUPING_CHAR_ID) {
      // user is operating on Grouping draggable
      // we need to go to manualGroupingOverride if not already
      // and skip automatic Grouping behavior
      if (!manualGroupingOverride) {
        setSetting('manualGroupingOverride', true);
        setReportDefinitionManualGroupingOverride();
      }
    } else {
      newState = checkAndUpdateGroupingCharacteristic(newState);
    }
    if (validateGroupingLayers(newState, destination.droppableId)) {
      handleAutoGenerateOnDrop(newState);
      setDroppableState(newState);
    }
  };

  // Returns a copy of the input droppableState with Grouping added to the
  // list of characteristics if not already present there. If already
  // present, then the original state is returned. Grouping will also not be
  // added in the case of a dual grouping report. A dual grouping report is
  // one that has more than just characteristics on the horizontal axis.
  // Grouping is not needed in this case.
  const addGroupingIfNotPresent = (droppableState: DroppableState): DroppableState => {
    const groupingCharId = 6;

    if (droppableState.chars.findIndex(char => char.charId === groupingCharId) === -1) {
      if (droppableState.horizontalChars.length === 1) {
        return {
          ...droppableState,
          chars: [
            {
              charId: groupingCharId,
              dataType: DataType.STRING,
              draggableId: v4(),
              name: 'Grouping',
              isGroupingLayer: false,
              classes: ['All'],
              modifier: Modifier.PORT,
            },
            ...droppableState.chars,
          ],
        };
      }
    }

    return droppableState;
  };

  // Returns a copy of the input droppableState with Grouping removed from the
  // list of characteristics if present there. If not
  // present, then the original state is returned.
  const removeGroupingIfPresent = (droppableState: DroppableState): DroppableState => {
    if (droppableState.chars.findIndex(char => char.charId === GROUPING_CHAR_ID) !== -1) {
      return {
        ...droppableState,
        chars: droppableState.chars.filter(c => c.charId !== GROUPING_CHAR_ID),
      };
    }

    return droppableState;
  };

  const validateGroupingLayers = (
    droppableState: DroppableState,
    droppableId: DroppableId,
  ): boolean => {
    // Validate the new state.
    const isTransposed =
      droppableState.verticalChars.findIndex(
        char => char.charId === GroupingLayerId.CHARACTERISTIC,
      ) !== -1;

    switch (droppableId) {
      case CHARACTERISTICS_ID:
        if (hasGroupingLayer(droppableState.chars)) {
          return false;
        }
        break;

      case VERTICAL_ID:
        if (isTransposed) {
          if (detailExists(droppableState.verticalChars)) {
            return false;
          }
          if (!characteristicFirstOrLast(droppableState.verticalChars)) {
            return false;
          }
        } else {
          if (!detailIsAtEnd(droppableState.verticalChars)) {
            return false;
          }
        }

        break;

      case HORIZONTAL_ID:
        if (isTransposed) {
          if (!detailIsAtEnd(droppableState.horizontalChars)) {
            return false;
          }
        } else {
          if (detailExists(droppableState.horizontalChars)) {
            return false;
          }
          if (!characteristicFirstOrLast(droppableState.horizontalChars)) {
            return false;
          }

          break;
        }
    }

    // Disallow DETAIL layer if other axis contains any grouping besides Characteristics.
    if (!isTransposed) {
      if (detailExists(droppableState.verticalChars) && droppableState.horizontalChars.length > 1) {
        return false;
      }
    } else {
      if (detailExists(droppableState.horizontalChars) && droppableState.verticalChars.length > 1) {
        return false;
      }
    }

    return true;
  };

  const detailExists = (chars: Characteristic[]) =>
    !!chars.find(char => char.charId === GroupingLayerId.DETAIL);

  const detailIsAtEnd = (chars: Characteristic[]) => {
    const detailIndex = chars.findIndex(char => char.charId === GroupingLayerId.DETAIL);
    return detailIndex === -1 || detailIndex === chars.length - 1;
  };

  const characteristicFirstOrLast = (chars: Characteristic[]) => {
    const index = chars.findIndex(char => char.charId === GroupingLayerId.CHARACTERISTIC);
    return index === 0 || index === chars.length - 1;
  };

  const hasGroupingLayer = (chars: Characteristic[]) => chars.some(char => char.isGroupingLayer);

  const removeFromDroppable = (droppableId: string, draggableId: string) => {
    let newDroppableState = {
      ...droppableState,
      [droppableId]: droppableState[droppableId].filter(c => c.draggableId !== draggableId),
    };

    const activeDroppable = droppableState[droppableId].find(c => c.draggableId === draggableId);

    if (activeDroppable?.charId === GROUPING_CHAR_ID) {
      // user is operating on Grouping draggable
      // we need to go to manualGroupingOverride if not already
      // and skip automatic Grouping behavior
      if (!manualGroupingOverride) {
        setSetting('manualGroupingOverride', true);
        setReportDefinitionManualGroupingOverride();
      }
    } else {
      newDroppableState = checkAndUpdateGroupingCharacteristic(newDroppableState);
    }

    if (reportDefinition.sort?.[0]?.charId === activeDroppable.charId) {
      setReportDefinitionSort(DESIGNER_SEQUENCE_ID, []);
    }

    setShouldRegenerate(isAutoGenerate);
    setDroppableState(newDroppableState);
  };

  const [groupingsOpen, setGroupingsOpen] = useState<boolean>(false);
  const [groupingFolderStructure, setGroupingFolderStructure] = useState<Node<string>>(null);

  const visualizationType = getSelectedVisualization(reportDefinition);
  let horizontalLabel = 'Horizontal';
  let verticalLabel = 'Vertical';
  const filterLabel = 'Filters';

  switch (visualizationType) {
    case 'BAR_CHART':
    case 'STACKED_BAR_CHART':
    case 'MIXED_BAR_CHART':
    case 'BRUSH_BAR_CHART':
    case 'SCATTER_CHART':
    case 'BUBBLE_CHART':
    case 'RADIAL_BAR_CHART': {
      horizontalLabel = 'Colors';
      verticalLabel = 'Items';
      break;
    }

    case 'TWO_LEVEL_PIE_CHART':
    case 'PIE_CHART':
    case 'ACTIVE_PIE_CHART': {
      horizontalLabel = 'Items';
      verticalLabel = 'Colors';
      break;
    }
  }

  const [modalAnchor, setModalAnchor] = useState<EventTarget & HTMLButtonElement>();
  const onModalClose = () => {
    setModalAnchor(null);
    setBreakpointEditorInfo(null);
    setGrouperEditorInfo(null);
  };

  const [grouperEditorInfo, setGrouperEditorInfo] = useState<{
    orientation: string;
    id: string;
    grouper: string;
    grouperData: string[];
  }>();

  const [breakpointEditorInfo, setBreakpointEditorInfo] = useState<{
    orientation: string;
    charId: number;
  }>();

  const editorRequest = (
    orientation: string,
    id: string,
    editorName: string,
    target: EventTarget & HTMLButtonElement,
  ) => {
    if (editorName) {
      setModalAnchor(target);
      const editor = droppableState[orientation].find(val => val.draggableId === id)?.grouperEditor;
      const dataImpl = CustomGrouperDataImpls[editor];
      if (dataImpl) {
        dataImpl()
          .then((response: { data: any }) => {
            setGrouperEditorInfo({
              orientation,
              id,
              grouper: editorName,
              grouperData: response.data,
            });
          })
          .catch((error: Error) =>
            enqueueSnackbar(
              NotificationLevel.ERROR,
              `Failed to get data for grouper: ${error.message}`,
            ),
          );
      } else {
        setGrouperEditorInfo({
          orientation,
          id,
          grouper: editorName,
          grouperData: null,
        });
      }
    } else {
      const charDetail: Characteristic = droppableState[orientation].find(
        val => val.draggableId === id,
      );

      if (supportsDiscreteBreakpoints(charDetail.dataType)) {
        setBreakpointEditorInfo({
          orientation,
          charId: charDetail.charId,
        });
      }
    }
  };

  return (
    <FullScreenModal
      isOpen
      close={() => {
        promptCloseDesigner();
      }}
      style={{
        display: 'grid',
        gridTemplateAreas: `
          "header header"
          "avail body"
          "footer footer"
          ". ." /* Workaround for Chrome bug that had filters panel overflowing modal */
          `,
        gridTemplateRows: 'auto 1fr 60px 4px',
        gridTemplateColumns: '314px 1fr',
      }}
    >
      {grouperEditorInfo && modalAnchor && (
        <GrouperModal
          onClose={onModalClose}
          anchor={modalAnchor}
          grouper={grouperEditorInfo.grouper}
          initialValue={
            droppableState[grouperEditorInfo?.orientation]?.find(
              c => c.draggableId === grouperEditorInfo.id,
            )?.grouperData
          }
          onApplyClick={e => {
            setDroppableState({
              ...droppableState,
              [grouperEditorInfo.orientation]: droppableState[
                grouperEditorInfo.orientation
              ].map(c => (c.draggableId === grouperEditorInfo.id ? { ...c, grouperData: e } : c)),
            });

            setShouldRegenerate(isAutoGenerate);
            setGrouperEditorInfo(null);
          }}
          onCancelClick={() => setGrouperEditorInfo(null)}
          grouperData={grouperEditorInfo.grouperData}
        />
      )}
      {breakpointEditorInfo && (
        <DiscreteBreakpointModal
          initialValue={
            droppableState[breakpointEditorInfo?.orientation]?.find(
              c => c.charId === breakpointEditorInfo.charId,
            )?.breakpoints
          }
          onApplyClick={breakpoints => {
            setDroppableState({
              ...droppableState,
              [breakpointEditorInfo.orientation]: droppableState[
                breakpointEditorInfo.orientation
              ].map(c =>
                c.charId === breakpointEditorInfo.charId ? { ...c, breakpoints: breakpoints } : c,
              ),
            });

            setShouldRegenerate(isAutoGenerate);
            setBreakpointEditorInfo(null);
          }}
          onCancelClick={() => {
            setDroppableState({
              ...droppableState,
              [breakpointEditorInfo.orientation]: droppableState[
                breakpointEditorInfo.orientation
              ].filter(c => c.charId !== breakpointEditorInfo.charId || c.breakpoints),
            });

            onModalClose();
            setBreakpointEditorInfo(null);
            setShouldRegenerate(false);
          }}
        />
      )}

      <NicknameDialog />

      <Dialog
        open={groupingsOpen}
        onClose={() => {
          setGroupingsOpen(false);
          setShouldRegenerate(false);
          const panel = droppableState.verticalChars.find(
            e => e.charId === GroupingLayerId.LINKED && !e.linkedGroupingPath,
          )
            ? 'verticalChars'
            : 'horizontalChars';
          setDroppableState({
            ...droppableState,
            [panel]: droppableState[panel].filter(
              c => c.charId !== GroupingLayerId.LINKED || c.linkedGroupingPath,
            ),
          });
        }}
      >
        <UserPromptTitle title='Choose linked grouping' />
        <TreeFolderList
          folderStructure={groupingFolderStructure}
          caption='Linked Groupings'
          drawerType={DrawerType.OPEN_REPORT_IN_WORKSPACE}
          onRowClick={e => {
            if (e.data.type === NodeType.FOLDER) return;
            setGroupingsOpen(false);

            const path = decodeURIComponent(e.data.props.path);

            const panel = droppableState.verticalChars.find(
              e => e.charId === GroupingLayerId.LINKED && !e.linkedGroupingPath,
            )
              ? 'verticalChars'
              : 'horizontalChars';
            setDroppableState({
              ...droppableState,
              [panel]: droppableState[panel].map(c =>
                c.charId === GroupingLayerId.LINKED && !c.linkedGroupingPath
                  ? { ...c, linkedGroupingPath: path }
                  : c,
              ),
            });
          }}
        />
      </Dialog>
      <DesignerPanelHeader
        visualizationType={visualizationType}
        reportableTypes={reportableTypes}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Paper
          style={{
            gridArea: 'avail',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControl style={{ margin: '10px', flexGrow: 1, maxWidth: '150px' }}>
              <Select
                labelId='class-select-label'
                id='class-select'
                value={selectedClass}
                onChange={(event: React.ChangeEvent<{ value: string }>) =>
                  setSelectedClass(event.target.value)
                }
              >
                {MenuItems(classList)}
              </Select>
            </FormControl>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <AddCustomGroupingButton />
              <FavoritesIcon isActive={isFavFilterActive} onClick={onFavoriteClick} />
              <ReportDesignerSettingsIcon
                showBench={showBench}
                showDiff={showDiff}
                setShowBench={setShowBench}
                setShowDiff={setShowDiff}
              />
            </div>
          </div>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '48px',
              backgroundColor: '#f7f5f7',
              marginRight: '1px',
              marginBottom: '5px',
              position: 'relative',
            }}
          >
            {searchTerm.length > 0 && (
              <IconButton
                aria-label='close'
                style={{ position: 'absolute', right: 0, top: 0, zIndex: 99 }}
                onClick={clearSearchTerm}
              >
                <Close />
              </IconButton>
            )}
            <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
              <Search height={15} style={{ marginLeft: '5px', color: '#595959' }} />
              <InputWithItalicPlaceholder
                placeholder='Search available characteristics'
                value={searchTerm}
                onChange={(e: React.ChangeEvent<{ value: string }>) =>
                  setSearchTerm(e.target.value)
                }
              />
            </FormControl>
          </Paper>
          <CharacteristicsMenu
            chars={filteredChars}
            searchTerm={debouncedSearchTerm}
            {...{ isFavFilterActive, selectedClass }}
          />
        </Paper>

        <SplitPane
          split='vertical'
          minSize={200}
          maxSize={-200}
          defaultSize={272}
          resizerStyle={{ background: '#f7f5f7' }}
          style={{ gridArea: 'body', display: 'flex', padding: '6px' }}
        >
          <SplitPane
            split='horizontal'
            minSize={100}
            maxSize={-100}
            primary='second'
            defaultSize={'43%'}
            resizerStyle={{ background: '#f7f5f7' }}
          >
            <SplitPane
              split='horizontal'
              minSize={100}
              maxSize={-100}
              pane1Style={{ flex: 5, display: 'flex' }}
              pane2Style={{ flex: 4, display: 'flex', minHeight: 0 }}
              resizerStyle={{ background: '#f7f5f7' }}
            >
              <CharacteristicsDroppable
                id={CHARACTERISTICS_ID}
                title='Characteristics'
                chars={droppableState.chars}
                remove={removeFromDroppable}
              />
              <CharacteristicsDroppable
                id={VERTICAL_ID}
                title={verticalLabel}
                chars={droppableState.verticalChars}
                remove={removeFromDroppable}
                editor={editorRequest}
              />
            </SplitPane>
            <CharacteristicsDroppable
              title={filterLabel}
              style={{ flexGrow: 1 }}
              chars={reportDefinition.filters
                .map(f => allChars.find(c => c.charId === f.charId))
                .filter(f => f)
                .map(char => ({
                  ...char,
                  // needs to be unique, but same every render, so no GUID
                  draggableId: `${char.charId}-${char.modifier}-${char.name}-filter`,
                }))}
              id={FILTER_CHARS}
            />
          </SplitPane>
          <div
            style={{
              height: '100%',
              display: 'grid',
              gridTemplateRows: '102px 1fr',
              padding: '0 4px 4px',
            }}
          >
            <CharacteristicsDroppable
              id={HORIZONTAL_ID}
              title={horizontalLabel}
              chars={droppableState.horizontalChars}
              direction={'horizontal'}
              remove={removeFromDroppable}
              editor={editorRequest}
            />
            <ReportPreview
              drillThrough={isDrillThrough}
              showPlaceholder={!reportGenerationAllowed || (!reportPending && !reportExists)}
              reportGenerationNotAllowedReasons={reportGenerationNotAllowedReasons}
              {...{ sandbox }}
            />
          </div>
        </SplitPane>
      </DragDropContext>
      <DesignerPanelFooter
        handleCancel={() => {
          promptCloseDesigner();
        }}
        generateReportPreview={onGenerateReportPreview}
        canGenerate={reportGenerationAllowed}
      />
    </FullScreenModal>
  );
};

export default connector(DesignerPanel);
