import React, { useContext } from 'react';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { closeFolderDrawer } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { saveReport } from '../../redux/ReportActionCreators';
import { setOverwriteConfirmationModalStatus } from '../../redux/ui/actionCreators';
import { OverwriteConfirmationStatus } from '../../redux/ui/overwriteConfirmationStatus';
import { saveWorkspace } from '../../redux/WorkspaceActionCreators';
import MainModal from '../main/MainModal';
import { SaveAsNameContext } from './FolderDrawer';

const mapStateToProps = (state: AppState) => ({
  status: state.ui.overwriteConfirmationModalStatus,
  folderDrawer: state.drawers.folderDrawer,
  selectedFolderPath: state.drawers.folderDrawer.selectedFolderItem?.props.path,
});

const mapDispatchToProps = {
  saveReport,
  saveWorkspace,
  closeFolderDrawer,
  setOverwriteConfirmationModalStatus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const getHeaderText = (status: OverwriteConfirmationStatus) =>
  ({
    [OverwriteConfirmationStatus.SAVE_REPORT]: 'Save Report',
    [OverwriteConfirmationStatus.SAVE_WORKSPACE]: 'Save Workspace',
  }[status]);

const getContent = (status: OverwriteConfirmationStatus) =>
  ({
    [OverwriteConfirmationStatus.SAVE_REPORT]: 'Are you sure you want overwrite this report?',
    [OverwriteConfirmationStatus.SAVE_WORKSPACE]: 'Are you sure you want overwrite this workspace?',
  }[status]);

const OverwriteConfirmationModal: FC<Props> = props => {
  const savePath = `${props.selectedFolderPath}/${encodeURIComponent(
    useContext(SaveAsNameContext).saveAsName,
  )}`;
  return (
    props.status !== null && (
      <MainModal
        open={props.status !== null}
        onRequestSubmit={() => {
          switch (props.status) {
            case OverwriteConfirmationStatus.SAVE_REPORT: {
              props.saveReport(savePath, props.folderDrawer.sequenceId, 'put');
              break;
            }

            case OverwriteConfirmationStatus.SAVE_WORKSPACE: {
              props.saveWorkspace(savePath, 'put');
              break;
            }
          }

          props.closeFolderDrawer();
          props.setOverwriteConfirmationModalStatus(null);
        }}
        onRequestClose={() => {
          props.setOverwriteConfirmationModalStatus(null);
        }}
        headerText={getHeaderText(props.status)}
        cancelButtonText='No'
      >
        {getContent(props.status)}
      </MainModal>
    )
  );
};

export default connector(OverwriteConfirmationModal);
