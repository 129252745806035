import { Typography } from '@material-ui/core';
import { FC, useCallback } from 'react';
import './action-bar.scss';
import ActionBarButton, { ActionBarButtonProps } from './ActionBarButton';
import ActionBarButtonType from './ActionBarButtonType';
import ActionBarDropdown, { ActionBarDropdownProps } from './ActionBarDropdown';

export type ActionBarItemProps =
  | (ActionBarButtonProps & { type?: ActionBarButtonType.ACTION })
  | (ActionBarDropdownProps & { type?: ActionBarButtonType.DROPDOWN });

export interface ActionBarProps {
  id: string;
  buttonProps: Array<ActionBarItemProps>;
  headerLabel?: string | null;
  divider?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
}

const ActionBar: FC<ActionBarProps> = ({
  id,
  buttonProps,
  headerLabel = null,
  divider = !!headerLabel,
  borderTop = false,
  borderBottom = false,
}) => {
  const createButton = useCallback((buttonProps: ActionBarItemProps) => {
    // We give TypeScript a little help so this prop can stay optional.
    const type =
      buttonProps.type ??
      (Object.hasOwn(buttonProps, 'onClick')
        ? ActionBarButtonType.ACTION
        : Object.hasOwn(buttonProps, 'items')
        ? ActionBarButtonType.DROPDOWN
        : null);

    switch (type) {
      case ActionBarButtonType.ACTION: {
        return (
          <ActionBarButton key={buttonProps.label} {...(buttonProps as ActionBarButtonProps)} />
        );
      }

      case ActionBarButtonType.DROPDOWN: {
        return (
          <ActionBarDropdown key={buttonProps.label} {...(buttonProps as ActionBarDropdownProps)} />
        );
      }
    }
  }, []);

  return (
    <div
      className={[
        'action-bar',
        `${id}-action-bar`,
        ...(borderTop ? ['action-bar-border-top'] : []),
        ...(borderBottom ? ['action-bar-border-bottom'] : []),
      ].join(' ')}
    >
      {headerLabel && (
        <Typography className='action-bar-label' variant='h1'>
          {headerLabel}
        </Typography>
      )}

      {divider && <div className='action-bar-divider' />}

      <div className='action-bar-button-container'>{buttonProps.map(createButton)}</div>
    </div>
  );
};

export default ActionBar;
