import moment from 'moment';

/**
 * DTO for Java class `com.armanta.analytics.ArmantaDate`. Only specifies
 * those fields serialized by the server API. Always in UTC format --
 * localization happens within components.
 *
 * (cf. `com.ssctech.algo.backstage.data.JacksonConfiguration.ArmantaDateSerializer)
 */
export interface ArmantaDate<T = string> {
  YYYY: number;
  MM: number; // 1-12
  DD: number;
  Time: number; // -1 if unset
  intraday: T | null;
}

export const isArmantaDate = (value: any): value is ArmantaDate =>
  value &&
  ['YYYY', 'MM', 'DD'].every(key => value.hasOwnProperty(key) && typeof value[key] === 'number');

const DATE_FORMAT = 'YYYY-MM-DD';
const _toDateString = ({ YYYY, MM, DD }: ArmantaDate) =>
  moment
    .utc({
      year: YYYY,
      month: MM - 1,
      day: DD,
    })
    .format(DATE_FORMAT);

/**
 * Convert a date of the form {DD: 6, MM: 10, YYYY: 2014}
 * to a string of the form '2014-10-06'.
 * Inputs not in the required form will be returned as-is.
 */
export const toDateString = <T>(value: T) => (isArmantaDate(value) ? _toDateString(value) : value);
