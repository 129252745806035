import { Select } from '@material-ui/core';
import { FC } from 'react';
import MenuItems from '../designer-panel/ui-elements/MenuItems';
import { PORTFOLIO_HIERARCHY_ROOT_ID } from './portfolioDrawerHelpers';

interface Props {
  data: {
    id: string;
    name: string;
  };
  onSelect: (id: string) => void;
  benchmarks: string[];
  selectedBenchmark: string;
}

const PortfolioItem: FC<Props> = props => (
  <div className='portfolio-item'>
    {props.data.name}
    {props.data.id !== PORTFOLIO_HIERARCHY_ROOT_ID && (
      <Select
        value={props.selectedBenchmark || 'None'}
        style={props.selectedBenchmark ? undefined : { color: 'rgba(0,0,0,0.50)' }}
      >
        {MenuItems(
          ['None', ...props.benchmarks].map(benchmark => ({
            value: benchmark,
            muiProps: {
              onClick: () => props.onSelect(benchmark),
            },
          })),
        )}
      </Select>
    )}
  </div>
);

export default PortfolioItem;
