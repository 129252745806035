import { Pilet, PiletApi } from 'piral-core';
import { createContext } from 'react';
import App from '../App';

export const PiralAppContext = createContext<PiletApi>(undefined);

export const MainPilet: Pilet = {
  name: 'main-pilet',
  setup(app: PiletApi) {
    app.registerPage('', () => (
      <PiralAppContext.Provider value={app}>
        <App />
      </PiralAppContext.Provider>
    ));
  },
  content: '',
  version: '1.0.0',
  hash: '1',
};
