import { MoreVert } from '@material-ui/icons';
import LaunchIcon from '@material-ui/icons/Launch';
import { CascadingMenu, CascadingMenuItem } from 'algo-react-dataviz';
import axios from 'axios';
import { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  enqueueSnackbar,
  openFolderDrawer,
  openReportEntitiesDrawer,
  openReportPortfolioDrawer,
  toggleSidePanel,
} from '../../redux/ActionCreators';
import { AppState, sendCSVWSMessage } from '../../redux/configureStore';
import {
  openWorkspaceReportInDesigner,
  saveReport,
  setReportDefinitionCurrency,
} from '../../redux/ReportActionCreators';
import { fetchSandboxes } from '../../redux/UserProfileActionCreators';
import {
  createNewSandbox,
  setWorkspaceReportHasChanges,
  switchToSandbox,
} from '../../redux/WorkspaceActionCreators';
import {
  ADHOC_REPORT_FOLDER,
  DrawerType,
  NodeType,
  NotificationLevel,
  VisualizationFriendlyNames,
} from '../../shared/constants';
import { UISlot } from '../../shared/dataTypes';
import {
  getReportDefinitionCurrency,
  getSelectedSandbox,
  hasSaccr,
  showPortfolioSelector,
} from '../../shared/utils';
import { visualizationIcons } from '../../shared/visualizationIcons';
import { createSandboxMenuItem } from '../sandboxes/sandbox-menu-items';
import { baseUrl, includePathsInCSVHeaders, rmuiBaseUrl } from '../shared/environment';
import {
  copyReportDefToJson,
  exportReportDefToJson,
  exportReportDescriptorToJson,
  exportToCSV,
} from './helpers/exportUtils';

interface BaseProps {
  sequenceId: number;
  detailList: boolean;
  drillThrough: boolean;
  regenerate: () => void;
  updateReport: (slotDetails: UISlot, changedValue: string) => void;
  handleCloseReport: () => void;
}

const mapStateToProps = (state: AppState, props: BaseProps) => ({
  reportDefinition: state.report.reportDefinition?.[props.sequenceId],
  disconnected: state.report.disconnected,
  currencies: state.user.userInfo.serverConfigs?.currencies,
  sandboxes: state.user.userInfo.userPreferences?.sandboxes,
  selectedSandbox: getSelectedSandbox(state, props.sequenceId),
  dateContext: state.user.selectedDateContext,
  allowExportToRest: state.user.userInfo?.serverConfigs.allowExportToRest,
  hasSaccr: hasSaccr(state),
  showPortfolioSelector: showPortfolioSelector(state),
  token: state.user.tk,
  selectedCurrency: getReportDefinitionCurrency(state, props.sequenceId),
});

const mapDispatchToProps = {
  fetchSandboxes,
  setReportDefinitionCurrency,
  toggleSidePanel,
  enqueueSnackbar,
  saveReport,
  openWorkspaceReportInDesigner,
  openFolderDrawer,
  switchToSandbox,
  createNewSandbox,
  exportToCSV,
  exportReportDefToJson,
  exportReportDescriptorToJson,
  copyReportDefToJson,
  openReportPortfolioDrawer,
  openReportEntitiesDrawer,
  setWorkspaceReportHasChanges,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const ReportMenu: FC<Props> = props => {
  const buildCurrencyOptions = (currencies: string[], disabled: boolean) => {
    let didSet: boolean = false;
    const { selectedCurrency, sequenceId, setReportDefinitionCurrency } = props;
    let opts: any[] = [
      {
        id: `seq-${sequenceId}-pop-1-7-Default`,
        text: 'Default',
        selected: selectedCurrency === 'default',
        onClick: () => {
          setReportDefinitionCurrency(sequenceId, 'default');
          props.regenerate();
        },
        disabled,
      },
      ...currencies?.map((c: string, i) => {
        let sel: boolean = false;
        if (selectedCurrency === c) {
          didSet = true;
          sel = true;
        }
        return {
          id: `seq-${sequenceId}-pop-1-7-${c}`,
          text: c,
          selected: sel,
          onClick: () => {
            setReportDefinitionCurrency(sequenceId, c);
            props.setWorkspaceReportHasChanges(sequenceId, true);
            props.regenerate();
          },
          disabled,
        };
      }),
    ];
    if (!didSet) {
      opts[0].selected = true;
    }
    return opts;
  };

  const buildTreeSelectionMenuOptions = (
    options: {
      id: string;
      type: string;
      name: string;
      props: { path?: string };
      children?: any;
    },
    selectedOption: string,
    menuSlot: UISlot,
  ) => {
    const selected =
      !options.children && options.type !== NodeType.FOLDER && selectedOption === options.id;

    const option: any = {
      id: `sb${options.id}`.replace(' ', '___'),
      text: options.name,
      children:
        options.children &&
        options.children.map(c => buildTreeSelectionMenuOptions(c, selectedOption, menuSlot)),
      selected,
    };

    if (!option.children) {
      // this is not a folder, add onClick
      const sandboxPath = options.props?.path;
      option.onClick = () =>
        !selected && props.updateReport(menuSlot, `selectedOption,${sandboxPath}`);
    }

    return option;
  };

  /**
   * Return an object of two elements that relate to how
   * to display a menu option: it's text and icon. The
   * decision is made based on the identifier of the menu slot.
   */
  const getMenuOptionInfo = (
    menuSlot: UISlot,
    option: any,
  ): { text: string; icon: JSX.Element } => {
    switch (menuSlot.complicationDetails.callbackIdentifier) {
      case 'visualization':
        return {
          text: VisualizationFriendlyNames[option],
          icon: visualizationIcons[option],
        };

      default:
        return {
          text: option,
          icon: null,
        };
    }
  };

  const openSidePanel = (triggeringMenuSlot: UISlot) => {
    // TODO revisit What If complications post MVP
    // const positions =
    //   this.props.reportConvertedData?.rows?.map(row => row.data[0]).filter(el => el[0] !== 0) || [];
    const positions = null;
    props.toggleSidePanel(
      true,
      triggeringMenuSlot,
      // This is a hack - needs to be addressed when implementing What If panel
      // next line commented out for MVP. to be addressed post MVP
      // { ...this.props.reportWorkspacePayload, requestId: null },
      positions,
    );
  };

  const processMenuChoice = (menuSlot: UISlot) => {
    const identifier = menuSlot.complicationDetails.callbackIdentifier;

    switch (identifier) {
      case 'whatIf':
        openSidePanel(menuSlot);
        break;

      default:
        props.enqueueSnackbar(
          NotificationLevel.WARN,
          `Unknown menu item identifier: ${identifier}`,
        );
        break;
    }
  };

  const generateUiSlotMenuOptions = (menuSlot: UISlot) => {
    if (!menuSlot.enabled) return;
    const { sequenceId } = props;
    if (menuSlot.controlType === 'ONCLICK_ITEM') {
      // This is a complication that has custom onClick behavior which
      // is dictated by the complication's callback identifier.
      return {
        id: `seq-${sequenceId}-${menuSlot.complicationDetails.id}`,
        text: menuSlot.complicationDetails.textBefore,
        disabled: props.disconnected,
        onClick: () => processMenuChoice(menuSlot),
      };
    }

    // This is a standard menu complication for which the onClick behavior
    // is defined by the complication type itself.
    const { selectedOption, selectedOptions, id } = menuSlot.complicationDetails;
    const options = menuSlot.complicationDetails.options;
    const menuId = `seq-${sequenceId}-${id}-MenuSlot`;
    const availableOptionItems = [];

    if (options) {
      Object.keys(options).forEach(attr => {
        const { text, icon } = getMenuOptionInfo(menuSlot, options[attr]);
        availableOptionItems.push({
          id: options[attr],
          text,
          disabled: props.disconnected,
          icon,
        });
      });
    }

    if (availableOptionItems.length === 0) {
      return;
    }

    switch (menuSlot.controlType) {
      case 'SINGLE_DROPDOWN': {
        return {
          id: menuId,
          text: menuSlot.complicationDetails.textBefore,
          children: availableOptionItems.map(it => {
            const selected = it.id === selectedOption;
            return {
              id: `${menuId}${it.id}`,
              text: `${it.text}`,
              onClick: () => !selected && props.updateReport(menuSlot, `selectedOption,${it.id}`),
              icon: it.icon,
              disabled: props.disconnected,
              selected,
            };
          }),
        };
      }
      case 'MULTI_DROPDOWN': {
        const selectedOptionsString = selectedOptions => {
          let string = 'selectedOptions,';
          selectedOptions.forEach((option, index) => {
            string += `${index !== 0 ? '|' : ''}${option}`;
          });
          return string;
        };

        const handleClick = (id, selected) => {
          let optionsToPass = selectedOptions ? [...selectedOptions] : [id];
          if (selected) {
            optionsToPass = optionsToPass.filter(option => option !== id);
          } else {
            optionsToPass.push(id);
          }
          props.updateReport(menuSlot, selectedOptionsString(optionsToPass));
        };

        return {
          id: menuId,
          text: menuSlot.complicationDetails.textBefore,
          children: availableOptionItems.map(it => {
            const selected = selectedOptions && selectedOptions.includes(parseInt(it.id));
            return {
              id: `${menuId}${it.id}`,
              text: `${it.text}`,
              onClick: () => handleClick(parseInt(it.id), selected),
              selected,
              disabled: props.disconnected,
              isMultiselect: true,
            };
          }),
        };
      }
      case 'TREE_SELECTION': {
        return buildTreeSelectionMenuOptions(options, selectedOption, menuSlot);
      }
    }
  };

  const handleRMRunRequest = () => {
    let contexts = '';

    switch (props.reportDefinition.dateContext.type) {
      case 'asOf': {
        const { date, id } = props.reportDefinition.dateContext.dates[0];
        contexts = id && id.length > 0 ? date + '_' + id : date;
        break;
      }
      case 'between':
        contexts = props.reportDefinition.dateContext.dates.map(d => d.date).join(',');
        break;
      case 'specific':
        contexts = props.reportDefinition.dateContext.dates
          .map(d => {
            const { date, id } = d;
            return id && id.length > 0 ? date + '_' + id : date;
          })
          .join(',');
        break;
      case 'default':
        const { date, id } = props.dateContext;
        contexts = id && id.length > 0 ? date + '_' + id : date;
        break;
    }

    axios
      .get<string[]>(`${baseUrl}api/riskManagerRunIds`, {
        params: { type: props.reportDefinition.dateContext.type, contexts },
      })
      .then(result => {
        window.open(
          `${rmuiBaseUrl}/index.html#runconfiguration?ids=${result.data.join(':')}`,
          '_blank',
          'noopener,noreferrer',
        );
      })
      .catch(error =>
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Failed to get run ids for risk manager: ${error.message}`,
        ),
      );
  };

  const buildMenuOptions = (): CascadingMenuItem[] => {
    const {
      currencies,
      detailList,
      drillThrough,
      reportDefinition,
      sequenceId,
      saveReport,
      openWorkspaceReportInDesigner,
      openFolderDrawer,
      disconnected,
      allowExportToRest,
    } = props;
    const openDrawer = () =>
      openFolderDrawer(DrawerType.SAVE_REPORT, {
        selectedElements: undefined,
        sequenceId,
      });

    const disableCurrencyOption =
      !reportDefinition?.chars?.length ||
      [undefined, null].includes(reportDefinition?.reportableType) ||
      disconnected ||
      (drillThrough &&
        reportDefinition?.settings.drillThroughInheritance &&
        reportDefinition?.settings.reportingCurrency);

    const menuItems: CascadingMenuItem[] = [
      {
        id: `seq-${sequenceId}-pop-1`,
        text: 'Report',
        children: [
          {
            id: `seq-${sequenceId}-pop-Edit`,
            text: 'Edit',
            onClick: () => {
              openWorkspaceReportInDesigner(sequenceId, false);
            },
            disabled:
              !reportDefinition?.chars?.length ||
              [undefined, null].includes(reportDefinition?.reportableType) ||
              disconnected,
          },
          {
            id: `seq-${sequenceId}-pop-NewFrom`,
            text: 'New From',
            onClick: () => {
              openWorkspaceReportInDesigner(sequenceId, true);
            },
            disabled:
              !reportDefinition?.chars?.length ||
              [undefined, null].includes(reportDefinition?.reportableType) ||
              disconnected,
          },
          {
            id: `seq-${sequenceId}-pop-Save`,
            text: 'Save',
            disabled: reportDefinition?.legacyReport || detailList || disconnected,
            onClick: () => {
              if (reportDefinition.path && !reportDefinition.path.startsWith(ADHOC_REPORT_FOLDER)) {
                // this non-adhoc report has been saved before, so just save it again
                saveReport(encodeURIComponent(reportDefinition.path), sequenceId, 'put');
              } else {
                // never saved as non-adhoc before, prompt user for path
                openDrawer();
              }
            },
          },
          {
            id: `seq-${sequenceId}-pop-SaveAs`,
            text: 'Save As',
            disabled: reportDefinition?.legacyReport || detailList || disconnected,
            onClick: openDrawer,
          },
          {
            id: `seq-${sequenceId}-pop-1-5`,
            text: 'Save As CSV',
            disabled: disconnected,
            onClick: () => {
              includePathsInCSVHeaders
                ? sendCSVWSMessage({ sequenceId: props.sequenceId }, props.token)
                : props.exportToCSV(props.sequenceId, null, null);
            },
          },
          {
            id: `seq-${sequenceId}-pop-1-7`,
            text: 'Currency',
            children: buildCurrencyOptions(currencies, disableCurrencyOption),
          },
          ...(props.showPortfolioSelector
            ? [
                {
                  id: `seq-${sequenceId}-pop-ChangePortfolio`,
                  text: 'Change Portfolio...',
                  onClick: () => props.openReportPortfolioDrawer(props.sequenceId),
                  disabled: drillThrough,
                },
              ]
            : []),
          ...(props.hasSaccr
            ? [
                {
                  id: `seq-${sequenceId}-pop-ChangeEntities`,
                  text: 'Change Entities...',
                  onClick: () => props.openReportEntitiesDrawer(props.sequenceId),
                  disabled: drillThrough,
                },
              ]
            : []),
          {
            id: `seq-${sequenceId}-pop-1-4`,
            text: 'Close',
            onClick: props.handleCloseReport,
          },
          {
            id: `seq-${sequenceId}-pop-1-8`,
            text: 'Export to File',
            onClick: () => props.exportReportDefToJson(props.sequenceId),
            disabled: detailList,
          },
          ...(allowExportToRest
            ? [
                {
                  id: `seq-${sequenceId}-pop-1-10`,
                  text: 'Export to Rest',
                  onClick: () => props.exportReportDescriptorToJson(props.sequenceId),
                  disabled: detailList,
                },
              ]
            : []),
          {
            id: `seq-${sequenceId}-pop-1-9`,
            text: 'Copy to Clipboard',
            onClick: () => props.copyReportDefToJson(props.sequenceId),
            disabled: detailList || !navigator.clipboard,
          },
        ],
      },
    ].concat((reportDefinition?.menuComplications || []).map(generateUiSlotMenuOptions));

    return [
      ...menuItems,
      ...(props.sandboxes
        ? [
            createSandboxMenuItem({
              menuId: `report-${props.sequenceId}`,
              sandboxes: props.sandboxes,
              selectedSandbox: props.selectedSandbox,
              onCreate: () => props.createNewSandbox(props.sequenceId, props.sandboxes.private),
              onSelect: sandboxPath => props.switchToSandbox(props.sequenceId, sandboxPath),
              disabled: detailList || drillThrough,
            }),
          ]
        : []),

      ...(rmuiBaseUrl
        ? [
            {
              id: `seq-${sequenceId}-pop-1-10`,
              text: 'Risk Manager Application',
              icon: <LaunchIcon style={{ fontSize: '12px', marginRight: '3px' }} />,
              onClick: handleRMRunRequest,
            },
          ]
        : []),
    ];
  };

  return (
    <CascadingMenu
      className='hide-in-pdf'
      items={buildMenuOptions()}
      onOpen={props.fetchSandboxes}
      showMenu
      icon={<MoreVert fontSize='small' className='hide-in-pdf' />}
      style={{ display: 'inline-flex', alignContent: 'center', height: '20px', width: '22px' }}
    />
  );
};

export default connector(ReportMenu);
