import { TextField, TextFieldProps } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { KeysOfType } from '../../../shared/dataTypes';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import {
  charOptionsToLabels,
  getTextFieldFormProps,
  isValueInDropdown,
} from './characteristicDrawerFormHelpers';
import CharacteristicDropdown from './CharacteristicDropdown';

interface Props {
  label: string;
  id: KeysOfType<CharacteristicDrawerForm, string | number>;
  formProps: FormikProps<CharacteristicDrawerForm>;
  textFieldProps?: TextFieldProps;
  displayRealValue?: boolean;
}

const CharacteristicOtherDropdown: FC<Props> = props => {
  const initialFormValues = useSelector(resolveInitialFormValues);
  const textFieldFormProps = getTextFieldFormProps(props.formProps, initialFormValues)(props.id);
  const valueIsInDropdown = isValueInDropdown(props.id, textFieldFormProps.value);
  return (
    <div className='full-width-container side-by-side'>
      <CharacteristicDropdown
        id={props.id}
        formProps={props.formProps}
        items={[...charOptionsToLabels[props.id], { value: 'Other', key: 'Other' }]}
        textFieldProps={{
          onChange: e => {
            // Keeps the field value a real value instead of "Other"
            if (e.target.value === 'Other') {
              props.formProps.setFieldValue(props.id, '', false);
            } else {
              textFieldFormProps.onChange(e);
            }
          },
          onBlur: e =>
            textFieldFormProps.onBlur(
              e.target.value === 'Other' ? { ...e, target: { ...e.target, value: '' } } : e,
            ),
          // check if current value is a dropdown value, if not display other
          value: (valueIsInDropdown && `${textFieldFormProps.value}`) || 'Other',
          label: props.label,
        }}
      />
      <TextField
        label={props.label}
        {...textFieldFormProps}
        {...props.textFieldProps}
        // display the dropdown display string or the actual value
        value={
          props.displayRealValue
            ? textFieldFormProps.value
            : (
                valueIsInDropdown &&
                charOptionsToLabels[props.id].find(obj => obj.value === textFieldFormProps.value)
              )?.key || textFieldFormProps.value
        }
      />
    </div>
  );
};

export default CharacteristicOtherDropdown;
