import { TextField } from '@material-ui/core';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toDateString } from '../../../model/armantaDate';
import { regenerateIfAuto, setSelectedFilterOptions } from '../../../redux/ActionCreators';
import { useAppSelector } from '../../../redux/configureStore';
import { getFilter } from '../../../redux/report/selectors';
import { DataType } from '../../../shared/constants';
import { Characteristic, FilterOptions, OptionDisplayMap } from '../../../shared/dataTypes';
import { checkValidation } from '../../../shared/utils';
import SetNotCheckbox from '../ui-elements/form/SetNotCheckbox';

interface Props {
  char: Characteristic;
  options: OptionDisplayMap[];
  setFilterOptions: (filterOptions: FilterOptions) => void;
}

const EqualsFilter: FC<Props> = props => {
  const dispatch = useDispatch();

  const filter = useAppSelector(getFilter)(props.char.charId);

  const equalsVal = filter?.selectedOptions?.[0];
  const [hasChanged, setHasChanged] = useState(false);
  const equalsValidationErrMsg = checkValidation(props.char.dataType, equalsVal, true);
  const hasErrors = equalsValidationErrMsg !== null;
  const handleEnterPressed = ({ key }) => {
    key === 'Enter' && handleUpdatingFilter();
  };

  const handleUpdatingFilter = () => {
    if (hasChanged && !hasErrors) {
      setHasChanged(false);
      dispatch(regenerateIfAuto());
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const filterHasValue = !!e.target.value;
    const filterIsValid = checkValidation(props.char.dataType, e.target.value, true) === null;
    filterHasValue && filterIsValid && setHasChanged(true);
    dispatch(setSelectedFilterOptions(props.char.charId, [e.target.value]));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '8px',
      }}
    >
      {props.char.dataType === DataType.DATE ? (
        <TextField
          variant='outlined'
          size='small'
          type='date'
          value={toDateString(equalsVal)}
          onBlur={handleUpdatingFilter}
          onChange={handleChange}
          onKeyPress={handleEnterPressed}
        />
      ) : (
        <TextField
          variant='outlined'
          size='small'
          onBlur={handleUpdatingFilter}
          error={equalsValidationErrMsg !== null}
          label={equalsValidationErrMsg}
          value={equalsVal || ''}
          onChange={handleChange}
          onKeyPress={handleEnterPressed}
        />
      )}

      <div
        style={{
          marginTop: '8px',
          width: '100%',
        }}
      >
        <SetNotCheckbox charId={props.char.charId} setFilterOptions={props.setFilterOptions} />
      </div>
    </div>
  );
};

export default EqualsFilter;
