import { Paper } from '@material-ui/core';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { editor } from 'monaco-editor';
import { FC, useMemo } from 'react';
import { EditorLanguage } from '../../../lang/editorLanguage';
import { FoliaEditorState } from '../../../lang/folia/useFoliaState';
import useClipboard from '../../../shared/useClipboard';
import { ActionBar, ActionBarButtonProps } from '../../shared/action-bar';
import CodeEditor from '../CodeEditor';
import ValidationLegend from '../validation-legend/ValidationLegend';
import './folia-editor.scss';
import FoliaEditorFab, { Props as FoliaEditorFabProps } from './FoliaEditorFab';
import FoliaSyntaxGuideButton from './syntax-guide/FoliaSyntaxGuideButton';

export interface Props {
  id?: string | null;
  state: FoliaEditorState;
  editorOptions?: editor.IStandaloneEditorConstructionOptions;
  fabProps?: FoliaEditorFabProps | null;
  readOnly?: boolean;
}

const FoliaEditor: FC<Props> = ({
  id = null,
  state: { editorModel, initialContent, validation, getPredicate },
  editorOptions = {},
  fabProps = null,
  readOnly = false,
}) => {
  const clipboard = useClipboard();

  const editorText = editorModel?.api?.getValue(EditorLanguage.FOLIA).trim() ?? '';
  const buttonProps: ActionBarButtonProps[] = useMemo(
    () => [
      // {
      //   label: 'Open in popout view',
      //   icon: OpenInNewIcon,
      //   onClick: () => {},
      //   disabled: true,
      // },
      {
        label: 'Copy to clipboard',
        icon: FileCopyIcon,
        onClick: () => {
          clipboard.copy(getPredicate()?.code ?? '');
        },
        disabled: !editorText || !clipboard.isAvailable() || clipboard.inProgress,
      },
    ],
    [clipboard, editorText, getPredicate],
  );

  return (
    <Paper className={['folia-editor', ...(id ? [`${id}-folia-editor`] : [])].join(' ')}>
      <div className='folia-editor-sidebar'>
        <ActionBar id='folia-editor' {...{ buttonProps }} />

        <div className='folia-editor-sidebar-bottom'>
          <FoliaSyntaxGuideButton />
          <ValidationLegend status={validation.status} />
        </div>
      </div>

      <div className='folia-editor-container'>
        <CodeEditor<EditorLanguage.FOLIA>
          {...{ editorModel }}
          currentLanguage={EditorLanguage.FOLIA}
          validation={{ [EditorLanguage.FOLIA]: validation }}
          initialContents={{ [EditorLanguage.FOLIA]: initialContent }}
          editorOptions={{
            glyphMargin: false,
            folding: false,
            lineNumbers: 'off',
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            renderLineHighlight: 'none',
            minimap: {
              enabled: false,
            },
            readOnly,
            overviewRulerBorder: false,
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            ...editorOptions,
          }}
        />
      </div>

      {fabProps && <FoliaEditorFab {...fabProps} />}
    </Paper>
  );
};

export default FoliaEditor;
