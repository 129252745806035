import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/configureStore';
import { setPersistWorkspacePortfolioSelection } from '../../redux/WorkspaceActionCreators';
import ComponentPermission from '../admin/panels/ComponentPermission';
import './portfolio-selection-controls.scss';

const PortfolioSelectionControls: FC = () => {
  const dispatch = useDispatch();
  const persistPortfolioSelection = useAppSelector(
    state => !!state.workspace.persistPortfolioSelection,
  );

  return (
    <div className='portfolio-selection-controls'>
      <div className='portfolio-selection-controls-row'>
        <ComponentPermission
          label='Persist selection with workspace'
          checked={persistPortfolioSelection}
          onChange={() => {
            dispatch(setPersistWorkspacePortfolioSelection(!persistPortfolioSelection));
          }}
        />
      </div>
    </div>
  );
};

export default PortfolioSelectionControls;
