import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import { FC } from 'react';
import './switch-accordion.scss';

interface Props {
  summary: string;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  expandedClassName?: string;
  id?: string;
}

const SwitchAccordion: FC<Props> = props => (
  <Accordion
    expanded={props.expanded}
    onChange={(_e, expanded) => props.setExpanded(expanded)}
    className={`switch-accordion ${(props.expanded && props.expandedClassName) || ''}`}
    id={props.id}
  >
    <AccordionSummary expandIcon={props.expanded ? <ToggleOn color='primary' /> : <ToggleOff />}>
      <Typography variant={props.expanded ? 'h2' : 'body1'}>{props.summary}</Typography>
    </AccordionSummary>

    <AccordionDetails>{props.children}</AccordionDetails>
  </Accordion>
);

export default SwitchAccordion;
