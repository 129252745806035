import { FC, PropsWithChildren } from 'react';
import { customGrouperImpls } from './customGrouperImpls';

export interface GrouperProps<T> {
  initialValue: T;
  onApplyClick: (data: T) => void;
  onCancelClick: () => void;
  onClose?: () => void;
  anchor?: EventTarget & HTMLButtonElement;
  grouperData: any;
}

interface GrouperModalProps {
  grouper: string;
}

function GrouperModal<T>(props: PropsWithChildren<GrouperModalProps & GrouperProps<T>>) {
  const CustomGrouperComponent: FC<GrouperProps<T>> = customGrouperImpls[props.grouper];

  const { grouper, ...compProps } = props;
  return <CustomGrouperComponent {...compProps} />;
}

export default GrouperModal;
