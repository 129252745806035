import { Typography } from '@material-ui/core';
import { FC } from 'react';
import DefaultWorkspaceSelect from '../controlled-text-input/DefaultWorkspaceSelect';
import PortfolioSelectionInput from '../controlled-text-input/PortfolioSelectionInput';
import CurrencyDropdown from '../currency-dropdown/CurrencyDropdown';
import WorkspaceDatePicker from '../report-dates/WorkspaceDatePicker';
import './home-settings.scss';

const HomeSettings: FC = () => (
  <div className='home-settings'>
    <Typography className='home-settings-label' gutterBottom>
      Settings
    </Typography>

    <div className='home-settings-content'>
      <div className='home-setting'>
        <Typography className='home-setting-label'>Reporting Date:</Typography>
        <div className='home-setting-widget'>
          <WorkspaceDatePicker hideLabel />
        </div>
      </div>

      <div className='home-setting'>
        <Typography className='home-setting-label'>Currency:</Typography>
        <div className='home-setting-widget'>
          <CurrencyDropdown hideLabel />
        </div>
      </div>

      <div className='home-setting'>
        <Typography className='home-setting-label'>Default Workspace:</Typography>
        <div className='home-setting-widget'>
          <DefaultWorkspaceSelect hideLabel />
        </div>
      </div>

      <div className='home-setting'>
        <Typography className='home-setting-label'>Portfolio Selection:</Typography>
        <div className='home-setting-widget'>
          <PortfolioSelectionInput hideLabel />
        </div>
      </div>
    </div>
  </div>
);

export default HomeSettings;
