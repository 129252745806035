import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { FC } from 'react';

interface Props {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const SearchField: FC<Props> = props => (
  <TextField
    className='search-field'
    value={props.searchTerm}
    onChange={e => props.setSearchTerm(e.target.value)}
    variant='outlined'
    size='small'
    placeholder='Search...'
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <Search style={{ color: '#999' }} />
        </InputAdornment>
      ),
    }}
  />
);

export default SearchField;
