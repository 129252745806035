import { Check as CheckIcon } from '@material-ui/icons';
import { CascadingMenuItem } from 'algo-react-dataviz';
import Axios, { AxiosResponse } from 'axios';
import { Template } from 'devextreme-react/core/template';
import { Column, TreeList } from 'devextreme-react/tree-list';
import { dxTreeListOptions } from 'devextreme/ui/tree_list';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  closeFolderDrawer,
  disableIfSelectionNotVisible,
  enqueueSnackbar,
  exportDrawerItem,
  requestSetExpandedReportFolders,
  requestSetExpandedWorkspaceFolders,
  setSelectedFolderItem,
  setSelectedWorkspaceAsDefault,
  updateDefaultWorkspace,
} from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';
import { openSelectedReportAsDetached } from '../../redux/ReportActionCreators';
import {
  addSelectedReportToWorkspace,
  appendSelectedWorkspace,
  fetchSelectedWorkspace,
  replaceReportInWorkspace,
  setWorkspaceReportPaths,
} from '../../redux/WorkspaceActionCreators';
import { DrawerType, NodeType, NotificationLevel } from '../../shared/constants';
import { FolderListRow, Node } from '../../shared/dataTypes';
import {
  filterDataSource,
  getDrawerItemType,
  getEndpoint,
  getEntityApiPath,
  mapData,
  searchDataForPath,
  treeListItemIsDisabled,
} from '../../shared/utils';
import { SaveAsNameContext } from '../drawers/FolderDrawer';
import SearchField from '../drawers/SearchField';
import IconCell from '../folder-list/IconCell';
import MainModal from '../main/MainModal';
import { baseUrl } from '../shared/environment';
import FilePrompt from './FilePrompt';
import NewFolderButton from './NewFolderButton';
import './tree-folder-list.scss';

const getNodePaths = (nodes: Node<string>): string[] =>
  nodes.props?.isLegacy // there's no point to traverse down the legacy reports
    ? []
    : [nodes.props?.path].concat(nodes.children?.flatMap(item => [...getNodePaths(item)]) || []);

interface BaseProps {
  folderStructure: Node<string>;
  onRowClick?: dxTreeListOptions['onRowClick'];
  caption: string;
  drawerType?: DrawerType;
  menu?: boolean;
  loadData?: (response?: AxiosResponse<Node<string>>, path?: string) => void;
}

const mapDispatchToProps = {
  enqueueSnackbar,
  updateDefaultWorkspace,
  setSelectedFolderItem,
  disableIfSelectionNotVisible,
  setWorkspaceReportPaths,
  addSelectedReportToWorkspace,
  openSelectedReportAsDetached,
  replaceReportInWorkspace,
  fetchSelectedWorkspace,
  appendSelectedWorkspace,
  closeFolderDrawer,
  requestSetExpandedWorkspaceFolders,
  requestSetExpandedReportFolders,
  exportDrawerItem,
  setSelectedWorkspaceAsDefault,
};

const mapStateToProps = (state: AppState, props: BaseProps) => ({
  defaultWorkspace: state.user.userInfo.userPreferences.defaultWorkspace,
  expandedFolders: [
    DrawerType.OPEN_REPORT_IN_WORKSPACE,
    DrawerType.OPEN_REPORT_IN_DESIGNER,
    DrawerType.SAVE_REPORT,
  ].includes(props.drawerType)
    ? state.user.userInfo.userPreferences.expandedReportFolders
    : state.user.userInfo.userPreferences.expandedWorkspaceFolders,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & BaseProps;

const TreeFolderList: React.FunctionComponent<Props> = props => {
  const [searchTerm, setSearchTerm] = useState('');
  const [itemToDelete, setItemToDelete] = useState<FolderListRow>();
  const [itemToRename, setItemToRename] = useState<FolderListRow>();
  const [writePaths, setWritePaths] = useState<Set<string>>(new Set());
  const setSaveAsName = useContext(SaveAsNameContext).setSaveAsName;
  const [canCreateFolderUnderRoot, setCanCreateFolderUnderRoot] = useState(false);

  const dataSource = filterDataSource(
    mapData(props.folderStructure, 0),
    searchTerm,
    () => true, // All nodes are encoded.
    n => n.props?.path,
  );

  useEffect(() => props.disableIfSelectionNotVisible(dataSource));

  const { drawerType, enqueueSnackbar } = props;

  useEffect(() => {
    if (drawerType) {
      Axios.get<boolean>(`${baseUrl}api/canCreateFolderUnderRoot`)
        .then(response => setCanCreateFolderUnderRoot(response.data))
        .catch(error =>
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to determine folder creation permission: ${error}`,
          ),
        );
    }
  }, [drawerType, enqueueSnackbar]);

  const updateWritePaths = useCallback(() => {
    if (drawerType === undefined || drawerType === null) {
      setWritePaths(new Set());
      return;
    }

    const path = getEntityApiPath(getDrawerItemType(drawerType));

    Axios.get<Node<string>>(`${baseUrl}api/${path}`, {
      params: { forRead: false, bypassPermissionCheck: false },
    })
      .then(response => {
        // we make this a Set because we must search nodePaths often
        // and Set has O(1) runtime for indexing
        setWritePaths(new Set(getNodePaths(response.data)));
      })
      .catch(error =>
        enqueueSnackbar(NotificationLevel.ERROR, `Warning: Unable to retrieve ${path}: ${error}`),
      );
  }, [drawerType, enqueueSnackbar]);

  useEffect(() => {
    updateWritePaths();
  }, [updateWritePaths]);

  const handleDoubleClick: dxTreeListOptions['onRowDblClick'] = e => {
    // TODO combine this function with onApply in FolderDrawerFooter - a lot of logic seems
    // to be duplicated.

    if (e.data.type === NodeType.FOLDER) {
      return;
    }

    switch (props.drawerType) {
      case DrawerType.OPEN_REPORT_IN_WORKSPACE:
        props.addSelectedReportToWorkspace();
        break;

      case DrawerType.OPEN_REPORT_IN_DESIGNER:
        props.openSelectedReportAsDetached();
        break;

      case DrawerType.REPLACE_REPORT:
        props.replaceReportInWorkspace();
        break;

      case DrawerType.OPEN_WORKSPACE:
        props.fetchSelectedWorkspace();
        break;

      case DrawerType.APPEND_WORKSPACE:
        props.appendSelectedWorkspace();
        break;

      case DrawerType.EXPORT_REPORTS:
      case DrawerType.EXPORT_WORKSPACES:
        props.exportDrawerItem();
        break;

      case DrawerType.SELECT_DEFAULT_WORKSPACE:
        props.setSelectedWorkspaceAsDefault();
        break;

      case DrawerType.SAVE_REPORT:
      case DrawerType.SAVE_WORKSPACE:
      default:
        break;
    }

    if (props.drawerType !== DrawerType.REPLACE_REPORT) props.closeFolderDrawer();
  };

  const deleteItem = () => {
    const path = itemToDelete.props.path + (itemToDelete.type === NodeType.FOLDER ? '/' : '');
    setItemToDelete(undefined);

    return Axios.delete(
      `${baseUrl}api/${getEndpoint(itemToDelete.type, props.drawerType, 'delete')}`,
      {
        params: {
          path: path,
          forRead: [
            DrawerType.OPEN_REPORT_IN_WORKSPACE,
            DrawerType.OPEN_REPORT_IN_DESIGNER,
            DrawerType.OPEN_WORKSPACE,
          ].includes(props.drawerType),
        },
      },
    )
      .then(response => props.loadData(response, path))
      .then(() => props.enqueueSnackbar(NotificationLevel.SUCCESS, 'Item was deleted.'))
      .catch((err: Error) => props.enqueueSnackbar(NotificationLevel.ERROR, err.message));
  };

  const renameItem = (name: string) => {
    setItemToRename(undefined);
    const { path: oldPath } = itemToRename.props;

    // If oldPath is 'dan/demo/old' and name is 'new' then newPath will be 'dan/demo/new'.
    const newPath = oldPath
      .split('/')
      .slice(0, oldPath.split('/').length - 1)
      .concat(encodeURIComponent(name))
      .join('/');

    return Axios.post(`${baseUrl}api/${getEndpoint(itemToRename.type, props.drawerType, 'move')}`, {
      oldPath: itemToRename.type === NodeType.FOLDER ? oldPath.concat('/') : oldPath,
      newPath: newPath.concat(itemToRename.type === NodeType.FOLDER ? '/' : ''),
      forRead: [
        DrawerType.OPEN_REPORT_IN_WORKSPACE,
        DrawerType.OPEN_REPORT_IN_DESIGNER,
        DrawerType.OPEN_WORKSPACE,
      ].includes(props.drawerType),
    })
      .then(props.loadData)
      .then(() => {
        props.enqueueSnackbar(NotificationLevel.SUCCESS, 'Item was renamed.');
        updateWritePaths();
        if (
          [
            DrawerType.OPEN_REPORT_IN_WORKSPACE,
            DrawerType.OPEN_REPORT_IN_DESIGNER,
            DrawerType.SAVE_REPORT,
          ].includes(props.drawerType)
        )
          props.setWorkspaceReportPaths(oldPath, newPath);
      })
      .catch(e => {
        console.error(e);
        props.enqueueSnackbar(
          NotificationLevel.ERROR,
          e.response.status === 409
            ? 'An item with that name already exists.'
            : decodeURIComponent(e.message),
        );
      });
  };

  const getIsDefaultWorkspace = (item: FolderListRow) => item.props.path === props.defaultWorkspace;

  const getMenuItems = (item: FolderListRow): CascadingMenuItem[] => {
    if (props.drawerType === undefined || !props.menu || item.props?.isLegacy) {
      return [];
    }

    const isDefaultWorkspace = getIsDefaultWorkspace(item);

    return [
      ...(writePaths.has(item.props.path)
        ? [
            { id: 'rename', text: 'Rename', onClick: () => setItemToRename(item), icon: null },
            { id: 'delete', text: 'Delete', onClick: () => setItemToDelete(item), icon: null },
          ]
        : []),

      ...(includeExportInMenu(props.drawerType)
        ? [
            {
              id: 'export',
              text: 'Export',
              onClick: () => props.exportDrawerItem(item),
              icon: null,
            },
          ]
        : []),

      ...(includeSetDefaultInMenu(props.drawerType) && item.type === NodeType.WORKSPACE
        ? [
            {
              id: 'setDefault',
              text: `${isDefaultWorkspace ? '' : 'Set '}Default Workspace`,
              icon: isDefaultWorkspace ? (
                <CheckIcon style={{ fontSize: '12px', marginRight: '3px' }} />
              ) : null,
              onClick: () =>
                props.updateDefaultWorkspace(isDefaultWorkspace ? null : item.props.path),
            },
          ]
        : []),
    ];
  };

  return (
    <>
      <MainModal
        headerText='Delete Item?'
        open={!!itemToDelete}
        onRequestSubmit={deleteItem}
        onRequestClose={() => setItemToDelete(undefined)}
      >
        Are you sure you want to delete {itemToDelete?.name}?
      </MainModal>
      <FilePrompt
        open={!!itemToRename}
        handleClose={() => setItemToRename(undefined)}
        handleSave={renameItem}
        title='Rename Item'
        placeholder={itemToRename?.name}
      >
        Enter the new name:
      </FilePrompt>
      {[DrawerType.SAVE_REPORT, DrawerType.SAVE_WORKSPACE].includes(props.drawerType) && (
        <NewFolderButton
          drawerType={props.drawerType}
          loadData={props.loadData}
          canCreateFolderUnderRoot={canCreateFolderUnderRoot}
        />
      )}
      {[
        DrawerType.OPEN_REPORT_IN_WORKSPACE,
        DrawerType.OPEN_REPORT_IN_DESIGNER,
        DrawerType.OPEN_WORKSPACE,
        DrawerType.IMPORT_REPORT_TO_FOLDER,
        DrawerType.IMPORT_WORKSPACE_TO_FOLDER,
      ].includes(props.drawerType) && (
        <SearchField searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      )}
      <TreeList
        dataSource={dataSource}
        rootValue={
          [
            DrawerType.OPEN_REPORT_IN_WORKSPACE,
            DrawerType.OPEN_REPORT_IN_DESIGNER,
            DrawerType.OPEN_WORKSPACE,
          ].includes(props.drawerType)
            ? -1
            : 0
        }
        keyExpr='id'
        parentIdExpr='parentId'
        expandedRowKeys={props.expandedFolders || []}
        onExpandedRowKeysChange={
          [
            DrawerType.OPEN_REPORT_IN_WORKSPACE,
            DrawerType.OPEN_REPORT_IN_DESIGNER,
            DrawerType.SAVE_REPORT,
          ].includes(props.drawerType)
            ? props.requestSetExpandedReportFolders
            : props.requestSetExpandedWorkspaceFolders
        }
        columnAutoWidth={true}
        onRowDblClick={handleDoubleClick}
        onRowClick={e => {
          const data: FolderListRow = e.data;
          if (typeof props.onRowClick === 'function') props.onRowClick(e);
          else if (!treeListItemIsDisabled(data, props.drawerType)) {
            if (![DrawerType.SAVE_REPORT, DrawerType.SAVE_WORKSPACE].includes(props.drawerType)) {
              props.setSelectedFolderItem(data);
              return;
            }
            if (data.type === NodeType.FOLDER) {
              props.setSelectedFolderItem(data);
            } else if ([NodeType.WORKSPACE, NodeType.TEMPLATE].includes(data.type)) {
              // set selected folder to parent of path
              const parentData: FolderListRow = searchDataForPath(
                props.folderStructure,
                data.props.path.substring(0, data.props.path.lastIndexOf('/')),
              );
              setSaveAsName(data.name);
              props.setSelectedFolderItem(parentData);
            }

            props.disableIfSelectionNotVisible(dataSource);
          }
        }}
        showColumnHeaders={false}
      >
        <Column dataField='name' caption={props.caption} cellTemplate='iconTemplate' />
        <Template
          name='iconTemplate'
          render={({ data }: { data: FolderListRow }) => (
            <IconCell
              data={data}
              isDefaultWorkspace={getIsDefaultWorkspace(data)}
              disabled={treeListItemIsDisabled(data, props.drawerType)}
              menuItems={getMenuItems(data)}
            />
          )}
        />
      </TreeList>
    </>
  );
};

const includeSetDefaultInMenu = (type: DrawerType) => {
  switch (type) {
    case DrawerType.OPEN_WORKSPACE:
    case DrawerType.APPEND_WORKSPACE:
    case DrawerType.SAVE_WORKSPACE:
      return true;

    case DrawerType.OPEN_REPORT_IN_WORKSPACE:
    case DrawerType.OPEN_REPORT_IN_DESIGNER:
    case DrawerType.SAVE_REPORT:
    case DrawerType.IMPORT_REPORT_TO_FOLDER:
    case DrawerType.IMPORT_WORKSPACE_TO_FOLDER:
    case DrawerType.EXPORT_REPORTS:
    case DrawerType.EXPORT_WORKSPACES:
      return false;
  }
};

const includeExportInMenu = (type: DrawerType) => {
  switch (type) {
    case DrawerType.OPEN_REPORT_IN_WORKSPACE:
    case DrawerType.OPEN_REPORT_IN_DESIGNER:
    case DrawerType.OPEN_WORKSPACE:
    case DrawerType.APPEND_WORKSPACE:
    case DrawerType.SAVE_REPORT:
    case DrawerType.SAVE_WORKSPACE:
      return true;

    case DrawerType.IMPORT_REPORT_TO_FOLDER:
    case DrawerType.IMPORT_WORKSPACE_TO_FOLDER:
    case DrawerType.EXPORT_REPORTS:
    case DrawerType.EXPORT_WORKSPACES:
      return false;
  }
};

export default connector(TreeFolderList);
