import { FullDateContextItem } from 'algo-react-dataviz';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDateContext } from '../../redux/ActionCreators';
import { AppState } from '../../redux/configureStore';

const useWorkspaceDateContext = () => {
  const workspaceDateContext = useSelector(
    (state: AppState) => state.user.selectedDateContext ?? null,
  );
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      workspaceDateContext,
      updateDateContext: (dateContext: FullDateContextItem) =>
        dispatch(updateDateContext(dateContext)),
    }),
    [workspaceDateContext, dispatch],
  );
};

export default useWorkspaceDateContext;
