import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../redux/configureStore';
import AdHocPortfolioDrawer from '../drawers/AdHocPortfolioDrawer';

const mapStateToProps = (state: AppState) => ({
  allowManageAdhoc: state.user?.userComponentPermission?.allowManageAdhoc,
});

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector>;

const TopBarGearMenu: FC<Props> = props => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const manageAdHocPortfoliosItem = (
    <MenuItem
      key={1}
      onClick={() => {
        setDrawerOpen(true);
        setAnchorEl(null);
      }}
    >
      Manage Ad Hoc Portfolios
    </MenuItem>
  );

  // Reason for an array is that if there's no elements inside the GearMenu,
  // the Gear shouldn't render.
  // Some menu items conditionally render based on state.
  // In the future if there's a menu item that isn't conditional,
  // Then the array would be pointless because the gear would never not render.

  const menuItems = [];

  if (props.allowManageAdhoc) {
    menuItems.push(manageAdHocPortfoliosItem);
  }

  return menuItems.length > 0 ? (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <Settings />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        {menuItems}
      </Menu>
      <AdHocPortfolioDrawer open={drawerOpen} close={() => setDrawerOpen(false)} />
    </>
  ) : null;
};
export default connector(TopBarGearMenu);
