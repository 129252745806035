import { MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { KeysOfType } from '../../../shared/dataTypes';
import { CharacteristicDrawerForm, resolveInitialFormValues } from './characteristicDrawerForm';
import { charOptionsToLabels, getTextFieldFormProps } from './characteristicDrawerFormHelpers';

interface Props {
  id: KeysOfType<CharacteristicDrawerForm, string | number>;
  items?: {
    value: string | number;
    key: string | number;
  }[];
  formProps: FormikProps<CharacteristicDrawerForm>;
  textFieldProps?: TextFieldProps;
}

const CharacteristicDropdown: FC<Props> = props => {
  const initialFormValues = useSelector(resolveInitialFormValues);

  return (
    <TextField
      {...getTextFieldFormProps(props.formProps, initialFormValues)(props.id)}
      {...(props.textFieldProps ?? {})}
      select
      name={props.id}
    >
      {(props.items || charOptionsToLabels[props.id] || []).map((obj, i) => (
        <MenuItem value={obj.value} key={obj.key}>
          {obj.key}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CharacteristicDropdown;
