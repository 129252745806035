import { FC, useEffect, useState } from 'react';
import { Cell, Legend, RadialBar, RadialBarChart, Tooltip } from 'recharts';
import { paletteT50 as colors } from '../shared/colorPalettes';
import { DataConverters } from '../shared/dataConverters';
import { ReportConvertedData, ReportProps } from '../shared/dataTypes';
import { useChartOpacity } from './helpers/chartingHelpers';

const RadialBarChartViz: FC<ReportProps> = props => {
  const [opacity, , onChartEnter, onChartLeave] = useChartOpacity({});
  const [reportConvertedData, setReportConvertedData] = useState<ReportConvertedData>();

  useEffect(() => setReportConvertedData(DataConverters.RADIAL_BAR_CHART(props.reportRawData)), [
    props.reportRawData,
  ]);

  if (props.width <= 0 || props.height <= 0 || !reportConvertedData) {
    return null;
  }

  const radialBarChartData = reportConvertedData.rows.map(el => {
    const { [reportConvertedData.groupingHeader.name]: groupingVal, ...rest } = el;
    rest.name = groupingVal;
    return rest;
  });

  const oR = (props.height < props.width * 0.8 ? props.height : props.width * 0.8) * 0.4;
  const cx = props.width / 2;
  const cy = props.height / 2;

  const iR = oR / 0.4 < 350 ? 20 : oR / 0.4 < 500 ? 25 : 30;
  const barSize = oR / 0.4 < 350 ? 10 : oR / 0.4 < 500 ? 15 : 20;

  return (
    <RadialBarChart
      width={props.width}
      height={props.height}
      cx={cx}
      cy={cy}
      innerRadius={iR}
      outerRadius={oR}
      barSize={barSize}
      data={radialBarChartData}
    >
      <Tooltip formatter={(value, name, props) => props.payload[`${name}Formatted`]} />
      <Legend
        layout='vertical'
        align='left'
        verticalAlign='middle'
        onMouseEnter={onChartEnter(
          radialBarChartData.map(el => {
            return el.name;
          }),
        )}
        onMouseLeave={onChartLeave}
      />
      {/* <Legend layout='horizontal' align='center' verticalAlign='bottom' /> */}
      <RadialBar
        label={{ position: 'insideStart', fill: '#000' }}
        background
        dataKey={reportConvertedData.headers[0]}
        onClick={event =>
          event && props.onElementClick(event[`${reportConvertedData.headers[0]}rowcol`])(event)
        }
      >
        {radialBarChartData.map((entry, index) => (
          <Cell
            key={entry}
            fill={colors[index % colors.length]}
            fillOpacity={opacity[entry.name] || 1}
          />
        ))}
      </RadialBar>
    </RadialBarChart>
  );
};

export default RadialBarChartViz;
