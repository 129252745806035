import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { DrawerHeader } from 'algo-react-dataviz';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from '../../redux/ActionCreators';
import MainModal from '../main/MainModal';
import './edit-time-bucket-modal.scss';
import { CustomTimeBucket, TimeBucketConfig } from './TimeBucketGrouperModal';
import TimestepGroupings from './TimestepGroupings';
import Timesteps from './Timesteps';

const mapDispatchToProps = { enqueueSnackbar };

const connector = connect(undefined, mapDispatchToProps);

interface Props {
  data: TimeBucketConfig;
  defaultName: string;
  timePoints: string[];
  onApplyClick: (saveAsNew: boolean, groupName: string, timeGroupings: TimeGrouping[]) => void;
  onCancelClick: () => void;
  onDeleteClick: () => void;
  customBucketEdit: CustomTimeBucket;
}

export interface TimeGrouping {
  name: string;
  left: string;
  right: string;
  leftInclusive: boolean;
  rightInclusive: boolean;
}

const EditTimeBucketModal: FC<Props> = props => {
  const [groupName, setGroupName] = useState(props.customBucketEdit?.name || props.defaultName);
  const [confirmDeleteGrouping, setConfirmDeleteGrouping] = useState(false);
  const [timeGroupings, setTimeGroupings] = useState<TimeGrouping[]>(
    props.customBucketEdit?.buckets || [],
  );

  const nextGroupingName = () => {
    const groupingNumbers = timeGroupings.map(grouping => {
      const match = grouping.name.match(/^Grouping ([0-9]+).*$/);
      return match ? Number(match[1]) : 0;
    });

    const nextNum = groupingNumbers.length > 0 ? Math.max(...groupingNumbers) + 1 : 1;

    return `Grouping ${nextNum}`;
  };

  return (
    <Dialog open className='edit-time-bucket-modal'>
      <DrawerHeader headerText='Editor: MtF Time Bucket (Custom)' onXClick={props.onCancelClick} />

      <DialogContent style={{ display: 'flex', flexDirection: 'column', padding: 0, minHeight: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h2' style={{ padding: '12px' }}>
            Group Name:
          </Typography>
          <TextField
            style={{ flex: 1, paddingRight: '35px' }}
            type='string'
            variant='outlined'
            size='small'
            value={groupName}
            onChange={e => setGroupName(e.target.value)}
          />

          <IconButton
            onClick={() => setConfirmDeleteGrouping(true)}
            disabled={props.customBucketEdit === null}
            color='secondary'
          >
            <DeleteOutline />
          </IconButton>
        </div>
        <div style={{ flex: 1, display: 'flex', border: 'thin solid #bbb', minHeight: 0 }}>
          <MainModal
            open={confirmDeleteGrouping}
            onRequestSubmit={() => {
              props.onDeleteClick();
              setConfirmDeleteGrouping(false);
              props.onCancelClick();
            }}
            onRequestClose={() => {
              setConfirmDeleteGrouping(false);
            }}
            headerText='Delete Group'
            cancelButtonText='No'
          >
            Are you sure you want to delete this custom group? This action cannot be undone.
          </MainModal>
          <Timesteps
            timePoints={props.timePoints}
            onClick={(timePointStart, timePointEnd) =>
              setTimeGroupings([
                ...timeGroupings,
                {
                  name: nextGroupingName(),
                  left: timePointStart,
                  right: timePointEnd,
                  leftInclusive: true,
                  rightInclusive: false,
                },
              ])
            }
          />
          <TimestepGroupings
            timeGroupings={timeGroupings}
            onChange={(timeGrouping: TimeGrouping, index) => {
              setTimeGroupings([
                ...timeGroupings.slice(0, index),
                timeGrouping,
                ...timeGroupings.slice(index + 1),
              ]);
            }}
            onDeleteGrouping={index => {
              setTimeGroupings([
                ...timeGroupings.slice(0, index),
                ...timeGroupings.slice(index + 1),
              ]);
            }}
            onChangeGroupingName={(index, name) => {
              setTimeGroupings([
                ...timeGroupings.slice(0, index),
                { ...timeGroupings[index], name },
                ...timeGroupings.slice(index + 1),
              ]);
            }}
          />
        </div>
      </DialogContent>

      <DialogActions style={{ padding: '8px 12px' }}>
        <div style={{ flex: 1 }}>
          <Button
            disabled={
              !props.customBucketEdit ||
              groupName.trim().length === 0 ||
              timeGroupings.length === 0 ||
              groupName === props.customBucketEdit?.name
            }
            variant='outlined'
            onClick={() => {
              props.onApplyClick(true, groupName, timeGroupings);
            }}
            className='rounded-corner-button'
          >
            Save as New
          </Button>
        </div>
        <Button
          onClick={() => {
            setGroupName(props.customBucketEdit?.name || props.defaultName);
            setTimeGroupings(props.customBucketEdit?.buckets || []);
          }}
          disabled={
            ((!props.customBucketEdit?.name && groupName === '') ||
              props.customBucketEdit?.name === groupName) &&
            ((!props.customBucketEdit?.buckets && timeGroupings.length === 0) ||
              JSON.stringify(timeGroupings) === JSON.stringify(props.customBucketEdit?.buckets))
          }
        >
          Reset
        </Button>
        <Button onClick={props.onCancelClick} variant='outlined' className='rounded-corner-button'>
          Cancel
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={
            groupName.trim().length === 0 ||
            timeGroupings.length === 0 ||
            (groupName === props.customBucketEdit?.name &&
              JSON.stringify(timeGroupings) === JSON.stringify(props.customBucketEdit?.buckets))
          }
          onClick={() => props.onApplyClick(false, groupName, timeGroupings)}
          className='rounded-corner-button'
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connector(EditTimeBucketModal);
