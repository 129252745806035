import './ascript-help.scss';
import AscriptHelpLeft from './AscriptHelpLeft';
import AscriptHelpRight from './AscriptHelpRight';

export default function AscriptHelp() {
  return (
    <div className='ascript-help-container'>
      <AscriptHelpLeft />
      <AscriptHelpRight />
    </div>
  );
}
