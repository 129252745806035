import { Tab, Tabs, Typography } from '@material-ui/core';
import { FC, useState } from 'react';
import './admin.scss';
import CreateNewGroup from './CreateNewGroup';
import CreateNewUser from './CreateNewUser';
import Groups from './Groups';
import Users from './Users';

enum TabValues {
  USERS = 'Users',
  GROUPS = 'Groups',
}

const Admin: FC = () => {
  const [tab, setTab] = useState(TabValues.USERS);

  return (
    <div className='admin page-content'>
      <div className='header'>
        <Typography variant='h1'>Administrative Settings</Typography>
      </div>

      <div className='tabs-bar'>
        <Tabs
          value={tab}
          indicatorColor='primary'
          textColor='primary'
          onChange={(_e, n) => setTab(n)}
        >
          <Tab label='Users' value={TabValues.USERS} />
          <Tab label='Roles' value={TabValues.GROUPS} />
        </Tabs>

        {tab === TabValues.USERS && <CreateNewUser />}
        {tab === TabValues.GROUPS && <CreateNewGroup />}
      </div>

      {tab === TabValues.USERS && <Users />}
      {tab === TabValues.GROUPS && <Groups />}
    </div>
  );
};

export default Admin;
