import { ChangeWorkspaceModalStatus } from './changeWorkspaceModalStatus';
import { ContentPage } from './contentPage';
import { OverwriteConfirmationStatus } from './overwriteConfirmationStatus';

export interface UiState {
  activePage: ContentPage;
  intendedPage: ContentPage | null;
  previousPage: ContentPage | null;

  overwriteConfirmationModalStatus: OverwriteConfirmationStatus | null;
  changeWorkspaceModalStatus: ChangeWorkspaceModalStatus | null;
  closeDesignerModalOpen: boolean;

  isAboutOpen: boolean;
  isRemoteConsoleOpen: boolean;
  remoteConsoleFocus: () => void | null;
}

export const DEFAULT_CONTENT_PAGE = ContentPage.HOME;
export const createDefaultUiState = (): UiState => ({
  activePage: DEFAULT_CONTENT_PAGE,
  intendedPage: null,
  previousPage: null,

  overwriteConfirmationModalStatus: null,
  changeWorkspaceModalStatus: null,
  closeDesignerModalOpen: false,

  isAboutOpen: false,
  isRemoteConsoleOpen: false,
  remoteConsoleFocus: null,
});
