import { CustomGrouping } from '../../model/custom-grouping/customGrouping';

export interface CustomGroupingState {
  drawerStatus: CustomGroupingDrawerStatus | null;
  syntaxGuideOpen: boolean;
  grouping: CustomGrouping | null;
  saveModalOpen: boolean;
}

export enum CustomGroupingDrawerStatus {
  CREATE,
  EDIT,
  VIEW,
}

export const createDefaultState = (): CustomGroupingState => ({
  drawerStatus: null,
  syntaxGuideOpen: false,
  grouping: null,
  saveModalOpen: false,
});
