import { FC } from 'react';
import useCustomGroupingState from '../context/useCustomGroupingState';
import EmptyView from './EmptyView';
import './grouping-tree-view.scss';
import GroupingTreeView from './GroupingTreeView';

const Container: FC = () => {
  const { grouping } = useCustomGroupingState();

  return (
    <div className='grouping-tree-container'>
      {grouping === null ? <EmptyView /> : <GroupingTreeView {...{ grouping }} />}
    </div>
  );
};

export default Container;
