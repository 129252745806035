import { VisualizationFriendlyNames } from '../../shared/constants';
import { UISlot } from '../../shared/dataTypes';

export function MultiSelectDropdownSlot(props: { slotDetails: UISlot; onChange: any }) {
  const options = props.slotDetails.complicationDetails.options;
  const selectedOptions = props.slotDetails.complicationDetails.selectedOptions;

  const availableOptionItems = [];
  const selectedOptionItems = [];
  if (options) {
    Object.keys(options).forEach(attr => {
      availableOptionItems.push({ id: attr, text: options[attr] });
      if (selectedOptions.indexOf(attr) !== -1) {
        selectedOptionItems.push({ id: attr, text: options[attr] });
      }
    });
  }

  if (availableOptionItems.length > 1) {
    return (
      <select
        defaultValue={selectedOptions[0]}
        onChange={event => {
          props.onChange(props.slotDetails, 'selectedOptions,' + event.target.value);
        }}
      >
        {availableOptionItems.map(it => (
          <option key={it.id} value={it.id}>
            {it.text}
          </option>
        ))}
      </select>
    );
  } else if (availableOptionItems.length === 1) {
    return <div>{selectedOptions[0].text}</div>;
  } else {
    return null;
  }
}

export function SingleSelectDropdownSlot(props: { slotDetails: UISlot; onChange: any }) {
  const options = props.slotDetails.complicationDetails.options;
  const selectedOption = props.slotDetails.complicationDetails.selectedOption;
  const isDisabled = !props.slotDetails.enabled;

  const availableOptionItems = [];
  let selectedOptionItem;
  if (options) {
    Object.keys(options).forEach(attr => {
      const text = VisualizationFriendlyNames[options[attr]]
        ? VisualizationFriendlyNames[options[attr]]
        : options[attr];
      availableOptionItems.push({ id: attr, text: text });
      if (selectedOption === attr) {
        selectedOptionItem = { id: attr, text: text };
      }
    });
  }

  if (availableOptionItems.length > 1) {
    return (
      <select
        disabled={isDisabled}
        defaultValue={selectedOption}
        onChange={event => {
          props.onChange(props.slotDetails, 'selectedOption,' + event.target.value);
        }}
      >
        {availableOptionItems.map(it => (
          <option key={it.id} value={it.id}>
            {it.text}
          </option>
        ))}
      </select>
      // <DropdownV2 id="groupByDropdown" ariaLabel="group by dropdown" label=""
      //   items={availableOptionItems}
      //   itemToString={item => item ? item.text : ''}
      //   selectedItem={selectedOptionItems[0]}
      // />

      // {/* {availableOptionItems.map(it => <DropdownItem value={it.id} itemText={it.text} />)} */}
      // </Dropdown>
    );
  } else if (availableOptionItems.length === 1) {
    return <div>{selectedOptionItem.text}</div>;
  } else {
    return null;
  }
}
