import { AppBar, Slide, useScrollTrigger } from '@material-ui/core';
import { FC } from 'react';
import TopBar from './TopBar';
interface Props {
  handleLogout: any;
  handleChangePassword: any;
  loggedIn: Boolean;
  isOpen: Boolean;
}

const HeaderComponent: FC<Props> = ({ isOpen, handleLogout, handleChangePassword }) => {
  const trigger = useScrollTrigger();
  return (
    <>
      {!isOpen ? (
        <Slide appear={false} direction='down' in={!trigger}>
          <AppBar elevation={0} aria-label='Menu' className={'stadium-app-bar'}>
            <TopBar handleChangePassword={handleChangePassword} handleLogout={handleLogout} />
          </AppBar>
        </Slide>
      ) : (
        <AppBar elevation={0} aria-label='Menu' className={'stadium-app-bar'}>
          <TopBar handleLogout={handleLogout} handleChangePassword={handleChangePassword} />
        </AppBar>
      )}
    </>
  );
};

export default HeaderComponent;
