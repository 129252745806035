import { CircularProgress } from '@material-ui/core';
import { FC } from 'react';

const ReportSettingSpinner: FC = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      margin: '5px',
    }}
  >
    <CircularProgress />
  </div>
);

export default ReportSettingSpinner;
