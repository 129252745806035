import { FC } from 'react';
import MainModal from '../main/MainModal';

interface Props {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const DeleteSandboxModal: FC<Props> = props => {
  return (
    <div className='delete-sandbox-modal'>
      <MainModal
        headerText='Delete Sandbox'
        open={props.isOpen}
        onRequestSubmit={props.onSubmit}
        onRequestClose={props.onClose}
        confirmButtonText='Delete'
        submitColor='secondary'
      >
        Are you sure you want to delete this sandbox? This action cannot be undone
      </MainModal>
    </div>
  );
};

export default DeleteSandboxModal;
