import { Radio, Typography } from '@material-ui/core';
import { FC } from 'react';

const SettingsRadio: FC<{ id: string; label: string; disabled?: boolean }> = props => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Radio
      disabled={props.disabled}
      color='primary'
      value={props.id}
      style={{ padding: '4px 9px' }}
    />
    <Typography>{props.label}</Typography>
  </div>
);

export default SettingsRadio;
