export const REPORT_LOADING = 'REPORT_LOADING';
export const UPDATE_REPORT_STATUS = 'UPDATE_REPORT_STATUS';
export const UPDATE_REPORT_DATA = 'UPDATE_REPORT_DATA';
export const MOVE_PENDING_REQUESTS = 'MOVE_PENDING_REQUESTS';
export const DELETE_REPORT_DATA = 'DELETE_REPORT_DATA';
export const ADD_REPORT_WHAT_IF_PARAMS = 'ADD_REPORT_WHAT_IF_PARAMS';
export const IMPORT_REPORT_DEFINITIONS = 'IMPORT_REPORT_DEFINITIONS';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const UPDATE_WORKSPACE_STATUS = 'UPDATE_WORKSPACE_STATUS';
export const UPDATE_WORKSPACE_REPORT_SELECTION = 'UPDATE_WORKSPACE_REPORT_SELECTION';
export const UPDATE_WORKSPACE_REPORT_PATH = 'UPDATE_WORKSPACE_REPORT_PATH';
export const SET_WORKSPACE_REPORT = 'SET_WORKSPACE_REPORT';
export const SET_WORKSPACE_REPORT_HAS_CHANGES = 'SET_WORKSPACE_REPORT_HAS_CHANGES';
export const UPDATE_REPORT_PATHS = 'UPDATE_REPORT_PATHS';
export const ADD_REPORT_TO_WORKSPACE = 'ADD_REPORT_TO_WORKSPACE';
export const REMOVE_REPORT_FROM_WORKSPACE = 'REMOVE_REPORT_FROM_WORKSPACE';
export const CLEAR_WORKSPACE = 'CLEAR_WORKSPACE';
export const SET_WORKSPACE_PORTFOLIO_SELECTION = 'SET_WORKSPACE_PORTFOLIO_SELECTION';
export const SET_SELECTED_WORKSPACE_ENTITIES = 'SET_SELECTED_WORKSPACE_ENTITIES';
export const SET_GLOBAL_OFFLINE_FLAG = 'SET_GLOBAL_OFFLINE_FLAG';
export const SET_PARENT_TO_CHILD_RELATIONSHIP = 'SET_PARENT_TO_CHILD_RELATIONSHIP';
export const SET_PERSIST_WORKSPACE_PORTFOLIO_SELECTION =
  'SET_PERSIST_WORKSPACE_PORTFOLIO_SELECTION';

// saga actions
export const REPORT_FETCH_REQUESTED = 'REPORT_FETCH_REQUESTED';
export const REPORT_FETCH_SUCCEEDED = 'REPORT_FETCH_SUCCEEDED';
export const REPORT_FETCH_FAILED = 'REPORT_FETCH_FAILED';

// Get the available currencies
export const AVAILABLE_CURRENCIES_FETCH_REQUESTED = 'AVAILABLE_CURRENCIES_FETCH_REQUESTED';
export const UPDATE_CURRENCIES = 'UPDATE_CURRENCIES';
export const UPDATE_WORKSPACE_CURRENCY = 'UPDATE_WORKSPACE_CURRENCY';
export const UPDATE_SELECTED_DATE_CONTEXT = 'UPDATE_SELECTED_DATE_CONTEXT';
export const SET_WORKSPACE_SANDBOX = 'SET_WORKSPACE_SANDBOX';

export const UPDATE_DATE_CONTEXTS = 'UPDATE_DATE_CONTEXTS';
export const UPDATE_SANDBOXES = 'UPDATE_SANDBOXES';
export const IMPORT_WORKSPACE = 'IMPORT_WORKSPACE';
export const SET_REPORT_DEFINITION = 'SET_REPORT_DEFINITION';
export const SET_REPORT_DEFINITION_VISUALIZATIONS = 'SET_REPORT_DEFINITION_VISUALIZATIONS';
export const SET_REPORT_DEFINITION_DATE_CONTEXT = 'SET_REPORT_DEFINITION_DATE_CONTEXT';
export const SET_REPORT_DEFINITION_CURRENCY = 'SET_REPORT_DEFINITION_CURRENCY';
export const SET_LAYOUT_COMPLICATION = 'SET_LAYOUT_COMPLICATION';
export const RESET_LAYOUT_COMPLICATION = 'RESET_LAYOUT_COMPLICATION';
export const SET_REPORT_DEFINITION_SORT = 'SET_REPORT_DEFINITION_SORT';
export const SET_REPORT_DEFINITION_PRECISION = 'SET_REPORT_DEFINITION_PRECISION';
export const SET_REPORT_PORTFOLIO_NODES = 'SET_REPORT_PORTFOLIO_NODES';
export const REMOVE_REPORT_PORTFOLIO_NODES = 'REMOVE_REPORT_PORTFOLIO_NODES';
export const REMOVE_SELECTED_REPORT_ENTITIES = 'REMOVE_SELECTED_REPORT_ENTITIES';
export const SET_SELECTED_REPORT_ENTITIES = 'SET_SELECTED_REPORT_ENTITIES';

export const SET_COMPLICATION_SELECTION = 'SET_COMPLICATION_SELECTION';

export const UPDATE_REPORT_DEFINITION_FILTERS = 'UPDATE_REPORT_DEFINITION_FILTERS';
export const UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG =
  'UPDATE_REPORT_DEFINITION_SCENARIOS_CONFIG';
export const UPDATE_REPORT_DEFINITION_SETTINGS = 'UPDATE_REPORT_DEFINITION_SETTINGS';
export const UPDATE_REPORT_DEFINITION_BENCHMARKS = 'UPDATE_REPORT_DEFINITION_BENCHMARKS';

export const SET_NICKNAME = 'SET_NICKNAME';

export const TOGGLE_SIDE_PANEL = 'TOGGLE_SIDE_PANEL';

export const UPDATE_CELL = 'UPDATE_CELL';

export const UPDATE_WORKSPACE_LAYOUT = 'UPDATE_WORKSPACE_LAYOUT';

export const TOGGLE_REPORT_LIVE_STATUS = 'TOGGLE_REPORT_LIVE_STATUS';

export const UPDATE_REPORT_SELECTED_ELEMENTS = 'UPDATE_REPORT_SELECTED_ELEMENTS';

export const CHANGE_SELECTED_TAB = 'CHANGE_SELECTED_TAB';

export const SET_SELECTED_REPORT_IMPORT_FILE = 'SET_SELECTED_REPORT_IMPORT_FILE';
export const SET_SELECTED_WORKSPACE_IMPORT_FILE = 'SET_SELECTED_WORKSPACE_IMPORT_FILE';

//User
export const SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const USER_INFO = 'USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SESSION_EXPIRED_WARNING_TOGGLE = 'USER_SESSION_EXPIRED_WARNING_TOGGLE';
export const SET_MANAGE_CONTEXTS_PERMISSIONS = 'SET_MANAGE_CONTEXTS_PERMISSIONS';

// User preferences
export const SET_ACTIVE_PORTFOLIO_SELECTION = 'SET_ACTIVE_PORTFOLIO_SELECTION';
export const SET_DEFAULT_PORTFOLIO_SELECTION = 'SET_DEFAULT_PORTFOLIO_SELECTION';
export const SET_EXPANDED_PORTFOLIOS = 'SET_EXPANDED_PORTFOLIOS';
export const SET_EXPANDED_ADMIN_PORTFOLIOS = 'SET_EXPANDED_ADMIN_PORTFOLIOS';
export const SET_EXPANDED_REPORT_FOLDERS = 'SET_EXPANDED_REPORT_FOLDERS';
export const SET_EXPANDED_WORKSPACE_FOLDERS = 'SET_EXPANDED_WORKSPACE_FOLDERS';
export const SET_DRAWER_WIDTHS = 'SET_DRAWER_WIDTHS';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const ADD_REQUEST_TIMER = 'ADD_REQUEST_TIMER';
export const REMOVE_PENDING_OPERATION = 'REMOVE_PENDING_OPERATION';
export const REMOVE_UNEXPIRED_REQUEST_TIMER = 'REMOVE_UNEXPIRED_REQUEST_TIMER';

export const UPDATE_USER_COMPONENT_PERMISSION = 'UPDATE_USER_COMPONENT_PERMISSION';

// Folder drawer
export const OPEN_FOLDER_DRAWER = 'OPEN_FOLDER_DRAWER';
export const CLOSE_FOLDER_DRAWER = 'CLOSE_FOLDER_DRAWER';
export const SET_SELECTED_FOLDER_ITEM = 'SET_SELECTED_FOLDER_ITEM';
export const SET_APPLY_BUTTON_DISABLED = 'SET_APPLY_BUTTON_DISABLED';

export const SAVE_WORKSPACE = 'SAVE_WORKSPACE';

export const UPDATE_DEFAULT_WORKSPACE = 'UPDATE_DEFAULT_WORKSPACE';

export const SET_WORKSPACE_PATH_AND_ID = 'SET_WORKSPACE_PATH_AND_ID';

export const SET_SCROLL_PENDING = 'SET_SCROLL_PENDING';

export const PUSH_RECENT_WORKSPACE = 'PUSH_RECENT_WORKSPACE';
export const REMOVE_RECENT_WORKSPACE = 'REMOVE_RECENT_WORKSPACE';
export const CLEAR_RECENT_WORKSPACES = 'CLEAR_RECENT_WORKSPACES';

// DX grid
export const SET_DX_TABLE_COLUMN_WIDTHS_WORKSPACE = 'SET_DX_TABLE_COLUMN_WIDTHS_WORKSPACE';
export const SET_DX_TABLE_COLUMN_WIDTHS_DESIGNER = 'SET_DX_TABLE_COLUMN_WIDTHS_DESIGNER';
export const SET_DX_EXPANDED_ROW_IDS_DESIGNER = 'SET_DX_EXPANDED_ROW_IDS_DESIGNER';
export const SET_DX_EXPANDED_ROW_IDS_WORKSPACE = 'SET_DX_EXPANDED_ROW_IDS_WORKSPACE';

export const SET_DX_HIDDEN_COLUMN_NAMES = 'SET_DX_HIDDEN_COLUMN_NAMES';
export const SET_DX_FROZEN_COLUMNS = 'SET_DX_FROZEN_COLUMNS';
export const SET_REPORT_DEFINITION_CHARACTERISTICS = 'SET_REPORT_DEFINITION_CHARACTERISTICS';
export const SET_REPORTABLE_TYPE = 'SET_REPORTABLE_TYPE';
export const SET_REPORT_TITLE = 'SET_REPORT_TITLE';

export const ADD_NEW_TAB = 'ADD_NEW_TAB';
export const REMOVE_TAB = 'REMOVE_TAB';
export const CLEAR_TAB = 'CLEAR_TAB';
export const RENAME_TAB = 'RENAME_TAB';
export const SET_TABS = 'SET_TABS';

export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';

export const ADD_FAVORITE_CUSTOM_GROUP = 'ADD_FAVORITE_CUSTOM_GROUP';
export const REMOVE_FAVORITE_CUSTOM_GROUP = 'REMOVE_FAVORITE_CUSTOM_GROUP';

// Report designer panel control
export const RESET_DESIGNER = 'RESET_DESIGNER';
export const INITIALIZE_DESIGNER = 'INITIALIZE_DESIGNER';
export const SET_SHOULD_REGENERATE = 'SET_SHOULD_REGENERATE';
export const SET_IS_AUTOGENERATE = 'SET_IS_AUTOGENERATE';
export const SET_ALL_CHARS = 'SET_ALL_CHARS';
export const SET_CHAR_TO_NICKNAME = 'SET_CHAR_TO_NICKNAME';
export const SET_ORIGINAL_DETACHED_REPORT = 'SET_ORIGINAL_DETACHED_REPORT';

// Filter panel
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const REORDER_FILTER = 'REORDER_FILTER';
export const UPDATE_FILTER_TYPE = 'UPDATE_FILTER_TYPE';
export const SET_SELECTED_FILTER_OPTIONS = 'SET_SELECTED_FILTER_OPTIONS';
export const SET_NOT = 'SET_NOT';
export const SET_NULL = 'SET_NULL';

// Designer Workspace Payload
export const SET_DESIGNER_WORKSPACE_PAYLOAD = 'SET_DESIGNER_WORKSPACE_PAYLOAD';

// Scenario panel
export const SET_SCENARIO_CONFIG_PROPERTY = 'SET_SCENARIO_CONFIG_PROPERTY';
export const SET_REPORT_DESIGNER_SCENARIO_CONFIG = 'SET_REPORT_DESIGNER_SCENARIO_CONFIG';
export const COPY_SCENARIOS_CONFIG = 'COPY_SCENARIOS_CONFIG';
export const SET_DATASOURCE_RELOAD_REQUIRED = 'SET_DATASOURCE_RELOAD_REQUIRED';
export const SET_CHECKBOX_RELOAD_REQUIRED = 'SET_CHECKBOX_RELOAD_REQUIRED';

// Settings panel
export const SET_SETTING = 'SET_SETTING';
export const SET_REPORT_DESIGNER_SETTINGS = 'SET_REPORT_DESIGNER_SETTINGS';
export const COPY_SETTINGS = 'COPY_SETTINGS';

// Benchmarks panel
export const SET_BENCHMARK = 'SET_BENCHMARK';
export const SET_REPORT_DESIGNER_BENCHMARKS = 'SET_REPORT_DESIGNER_BENCHMARKS';
export const COPY_BENCHMARKS = 'COPY_BENCHMARKS';

// Custom grouping
export const UPDATE_WITH_AD_HOC_GROUPING = 'ADD_ADHOC_GROUPING';

// Admin
export const SELECT_USER = 'SELECT_USER';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_COMPONENT_PERMISSIONS = 'SET_COMPONENT_PERMISSIONS';
export const SET_PORTFOLIO_PERMISSIONS = 'SET_PORTFOLIO_PERMISSIONS';
export const SET_GROUP_PORTFOLIO_PERMISSIONS = 'SET_GROUP_PORTFOLIO_PERMISSIONS';
export const SET_FOLDER_PERMISSION = 'SET_FOLDER_PERMISSION';
export const SET_USERS = 'SET_USERS';
export const SET_GROUPS = 'SET_GROUPS';
export const ADD_USERS_TO_GROUP = 'ADD_USERS_TO_SELECTED_GROUP';
export const REMOVE_USERS_FROM_GROUP = 'REMOVE_USERS_FROM_SELECTED_GROUP';
export const ADD_LOADING_GROUP_MEMBERS = 'ADD_LOADING_GROUP_MEMBERS';
export const REMOVE_LOADING_GROUP_MEMBERS = 'REMOVE_LOADING_GROUP_MEMBERS';
export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_GROUPS = 'DELETE_GROUPS';
export const SET_CONTEXT_PERMISSION_RULE = 'SET_CONTEXT_PERMISSION_RULE';
export const CREATE_GROUP_LIKE = 'CREATE_GROUP_LIKE';
export const CREATE_USER_LIKE = 'CREATE_USER_LIKE';
export const SET_CREATE_USER_DRAWER_OPEN = 'SET_CREATE_USER_DRAWER_OPEN';
export const SET_CREATE_GROUP_DRAWER_OPEN = 'SET_CREATE_GROUP_DRAWER_OPEN';
export const SET_CREATE_LIKE_CURRENT = 'SET_CREATE_LIKE_CURRENT';
export const SET_USER_FIRST_LAST_NAME = 'SET_USER_FIRST_LAST_NAME';

export const OPEN_BENCHMARK_PORTFOLIO_DRAWER = 'OPEN_BENCHMARK_PORTFOLIO_DRAWER';
export const CLOSE_BENCHMARK_PORTFOLIO_DRAWER = 'CLOSE_BENCHMARK_PORTFOLIO_DRAWER';
export const OPEN_REPORT_PORTFOLIO_DRAWER = 'OPEN_REPORT_PORTFOLIO_DRAWER';
export const CLOSE_REPORT_PORTFOLIO_DRAWER = 'CLOSE_REPORT_PORTFOLIO_DRAWER';
export const OPEN_REPORT_ENTITIES_DRAWER = 'OPEN_REPORT_ENTITIES_DRAWER';
export const CLOSE_REPORT_ENTITIES_DRAWER = 'CLOSE_REPORT_ENTITIES_DRAWER';
export const OPEN_PROXY_DRAWER = 'OPEN_PROXY_DRAWER';
export const CLOSE_PROXY_DRAWER = 'CLOSE_PROXY_DRAWER';

export const TOGGLE_DESIGNER_DATE_CONTEXT_RUN = 'TOGGLE_DESIGNER_DATE_CONTEXT_RUN';

// UI content page navigation
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';

export const SET_CHANGE_WORKSPACE_MODAL_STATUS = 'SET_CHANGE_WORKSPACE_MODAL_STATUS';

export const INTEND_CLOSE_DESIGNER = 'INTEND_CLOSE_DESIGNER';
export const CONFIRM_CLOSE_DESIGNER = 'CONFIRM_CLOSE_DESIGNER';
export const CANCEL_CLOSE_DESIGNER = 'CANCEL_CLOSE_DESIGNER';

export const SET_IS_ABOUT_OPEN = 'SET_IS_ABOUT_OPEN';
export const SET_IS_REMOTE_CONSOLE_OPEN = 'SET_IS_REMOTE_CONSOLE_OPEN';
export const SET_REMOTE_CONSOLE_FOCUS = 'SET_REMOTE_CONSOLE_FOCUS';

export const SET_OVERWRITE_CONFIRMATION_MODAL_STATUS = 'SET_OVERWRITE_CONFIRMATION_MODAL_STATUS';
